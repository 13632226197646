import React, { useState } from 'react';
import {
  isMobile
} from "react-device-detect";
import Aux from '../components/atom/children/index';
import pms1 from "../assets/images/landing/pms1.png";
import pms2 from "../assets/images/landing/pms2.png";
import pms3 from "../assets/images/landing/pms3.png";
import pms4 from "../assets/images/landing/pms4.png";
import Container from '../components/atom/container/index';
import Heading from '../components/atom/heading/h1';
import HeadingH2 from '../components/atom/heading/h2';
import HeadingH3 from '../components/atom/heading/h3';
import Image from '../components/atom/image';
import ViewAll from '../components/atom/link/viewAll';
import NextImage from '../components/atom/nextImage';
import NoFount from '../components/atom/notFound/index';
import Section from '../components/atom/section';
import Accord from '../components/molecules/accordian';
import PMSBox from '../components/molecules/fundSlider/pms';
import BannerWrapper from '../components/molecules/landing/bannerWrapper';
import Keypms from '../components/molecules/PMS/keypms';
import Performance from '../components/molecules/PMS/performance';
import Leader from '../components/molecules/PMS/pmsLeader';
import ApiCall from '../services/preLoginApi';

let api = new ApiCall();

const SECTION_1_IMAGES = [pms1, pms2, pms3, pms4]

let PMSGraphData = {
  "data": {
    "success": true,
    "msg": "Data Found Successfully",
    "data": {
      "ChartData": {
        "1": [
          {
            "category": "18-02-2003",
            "value": 10,
            "value2": 10
          },
          {
            "category": "19-02-2003",
            "value": 10.0426,
            "value2": 10.0534
          },
          {
            "category": "20-02-2003",
            "value": 10.0197,
            "value2": 10.0547
          },
          {
            "category": "21-02-2003",
            "value": 10.041,
            "value2": 10.0644
          },
          {
            "category": "22-02-2003",
            "value": 10.041,
            "value2": 10.0644
          },
          {
            "category": "23-02-2003",
            "value": 10.041,
            "value2": 10.0644
          },
          {
            "category": "24-02-2003",
            "value": 10.0518,
            "value2": 10.0938
          },
          {
            "category": "25-02-2003",
            "value": 9.9717,
            "value2": 9.98308
          },
          {
            "category": "26-02-2003",
            "value": 9.9206,
            "value2": 9.92021
          },
          {
            "category": "27-02-2003",
            "value": 9.9821,
            "value2": 10.0099
          },
          {
            "category": "28-02-2003",
            "value": 9.9421,
            "value2": 10.0304
          },
          {
            "category": "01-03-2003",
            "value": 9.9421,
            "value2": 10.0304
          },
          {
            "category": "02-03-2003",
            "value": 9.9421,
            "value2": 10.0304
          },
          {
            "category": "03-03-2003",
            "value": 10.0053,
            "value2": 10.0369
          },
          {
            "category": "04-03-2003",
            "value": 9.9493,
            "value2": 9.92341
          },
          {
            "category": "05-03-2003",
            "value": 9.9272,
            "value2": 9.86962
          },
          {
            "category": "06-03-2003",
            "value": 9.8933,
            "value2": 9.76168
          },
          {
            "category": "07-03-2003",
            "value": 9.7858,
            "value2": 9.62145
          },
          {
            "category": "08-03-2003",
            "value": 9.7858,
            "value2": 9.62145
          },
          {
            "category": "09-03-2003",
            "value": 9.7858,
            "value2": 9.62145
          },
          {
            "category": "10-03-2003",
            "value": 9.7188,
            "value2": 9.52915
          },
          {
            "category": "11-03-2003",
            "value": 9.7771,
            "value2": 9.58234
          },
          {
            "category": "12-03-2003",
            "value": 9.767,
            "value2": 9.4921
          },
          {
            "category": "13-03-2003",
            "value": 9.7708,
            "value2": 9.4839
          },
          {
            "category": "14-03-2003",
            "value": 9.7708,
            "value2": 9.4839
          },
          {
            "category": "15-03-2003",
            "value": 9.7708,
            "value2": 9.4839
          },
          {
            "category": "16-03-2003",
            "value": 9.7708,
            "value2": 9.4839
          },
          {
            "category": "17-03-2003",
            "value": 9.7616,
            "value2": 9.4181
          },
          {
            "category": "18-03-2003",
            "value": 9.7643,
            "value2": 9.4181
          },
          {
            "category": "19-03-2003",
            "value": 9.7876,
            "value2": 9.47673
          },
          {
            "category": "20-03-2003",
            "value": 9.9363,
            "value2": 9.62819
          },
          {
            "category": "21-03-2003",
            "value": 9.9909,
            "value2": 9.67102
          },
          {
            "category": "22-03-2003",
            "value": 10.0174,
            "value2": 9.72369
          },
          {
            "category": "23-03-2003",
            "value": 10.0236,
            "value2": 9.72369
          },
          {
            "category": "24-03-2003",
            "value": 9.8908,
            "value2": 9.53554
          },
          {
            "category": "25-03-2003",
            "value": 9.9009,
            "value2": 9.52535
          },
          {
            "category": "26-03-2003",
            "value": 9.8991,
            "value2": 9.51783
          },
          {
            "category": "27-03-2003",
            "value": 9.8859,
            "value2": 9.46594
          },
          {
            "category": "28-03-2003",
            "value": 9.8972,
            "value2": 9.45074
          },
          {
            "category": "29-03-2003",
            "value": 9.8972,
            "value2": 9.45074
          },
          {
            "category": "30-03-2003",
            "value": 9.8972,
            "value2": 9.45074
          },
          {
            "category": "31-03-2003",
            "value": 9.7785,
            "value2": 9.25171
          },
          {
            "category": "01-04-2003",
            "value": 9.8316,
            "value2": 9.34194
          },
          {
            "category": "02-04-2003",
            "value": 9.9556,
            "value2": 9.47276
          },
          {
            "category": "03-04-2003",
            "value": 10.0228,
            "value2": 9.575
          },
          {
            "category": "04-04-2003",
            "value": 10.0918,
            "value2": 9.66938
          },
          {
            "category": "05-04-2003",
            "value": 10.0918,
            "value2": 9.66938
          },
          {
            "category": "06-04-2003",
            "value": 10.0918,
            "value2": 9.66938
          },
          {
            "category": "07-04-2003",
            "value": 10.1952,
            "value2": 9.821
          },
          {
            "category": "08-04-2003",
            "value": 10.2192,
            "value2": 9.74268
          },
          {
            "category": "09-04-2003",
            "value": 10.1484,
            "value2": 9.65055
          },
          {
            "category": "10-04-2003",
            "value": 10.0598,
            "value2": 9.32632
          },
          {
            "category": "11-04-2003",
            "value": 10.0528,
            "value2": 9.25318
          },
          {
            "category": "12-04-2003",
            "value": 10.0528,
            "value2": 9.25318
          },
          {
            "category": "13-04-2003",
            "value": 10.0528,
            "value2": 9.25318
          },
          {
            "category": "14-04-2003",
            "value": 10.0528,
            "value2": 9.25318
          },
          {
            "category": "15-04-2003",
            "value": 10.0747,
            "value2": 9.27477
          },
          {
            "category": "16-04-2003",
            "value": 10.1278,
            "value2": 9.32942
          },
          {
            "category": "17-04-2003",
            "value": 9.4757,
            "value2": 9.20621
          },
          {
            "category": "18-04-2003",
            "value": 9.4757,
            "value2": 9.20621
          },
          {
            "category": "19-04-2003",
            "value": 9.4757,
            "value2": 9.20621
          },
          {
            "category": "20-04-2003",
            "value": 9.4757,
            "value2": 9.20621
          },
          {
            "category": "21-04-2003",
            "value": 9.5372,
            "value2": 9.28228
          },
          {
            "category": "22-04-2003",
            "value": 9.4897,
            "value2": 9.26095
          },
          {
            "category": "23-04-2003",
            "value": 9.4156,
            "value2": 9.17607
          },
          {
            "category": "24-04-2003",
            "value": 10.0336,
            "value2": 9.1525
          },
          {
            "category": "25-04-2003",
            "value": 9.9841,
            "value2": 9.10993
          },
          {
            "category": "26-04-2003",
            "value": 9.9841,
            "value2": 9.10993
          },
          {
            "category": "27-04-2003",
            "value": 9.9841,
            "value2": 9.10993
          },
          {
            "category": "28-04-2003",
            "value": 10.1497,
            "value2": 9.17478
          },
          {
            "category": "29-04-2003",
            "value": 10.1579,
            "value2": 9.1784
          },
          {
            "category": "30-04-2003",
            "value": 10.2295,
            "value2": 9.22218
          },
          {
            "category": "01-05-2003",
            "value": 10.2295,
            "value2": 9.22218
          },
          {
            "category": "02-05-2003",
            "value": 10.3949,
            "value2": 9.29687
          },
          {
            "category": "03-05-2003",
            "value": 10.3949,
            "value2": 9.29687
          },
          {
            "category": "04-05-2003",
            "value": 10.3949,
            "value2": 9.29687
          },
          {
            "category": "05-05-2003",
            "value": 10.4704,
            "value2": 9.41189
          },
          {
            "category": "06-05-2003",
            "value": 10.5707,
            "value2": 9.47777
          },
          {
            "category": "07-05-2003",
            "value": 10.547,
            "value2": 9.47708
          },
          {
            "category": "08-05-2003",
            "value": 10.4856,
            "value2": 9.40377
          },
          {
            "category": "09-05-2003",
            "value": 10.4958,
            "value2": 9.3884
          },
          {
            "category": "10-05-2003",
            "value": 10.4958,
            "value2": 9.3884
          },
          {
            "category": "11-05-2003",
            "value": 10.4958,
            "value2": 9.3884
          },
          {
            "category": "12-05-2003",
            "value": 10.705,
            "value2": 9.4605
          },
          {
            "category": "13-05-2003",
            "value": 10.7672,
            "value2": 9.54374
          },
          {
            "category": "14-05-2003",
            "value": 10.8577,
            "value2": 9.64468
          },
          {
            "category": "15-05-2003",
            "value": 10.9555,
            "value2": 9.77947
          },
          {
            "category": "16-05-2003",
            "value": 11.0411,
            "value2": 9.92738
          },
          {
            "category": "17-05-2003",
            "value": 11.0411,
            "value2": 9.92738
          },
          {
            "category": "18-05-2003",
            "value": 11.0411,
            "value2": 9.92738
          },
          {
            "category": "19-05-2003",
            "value": 11.017,
            "value2": 9.85476
          },
          {
            "category": "20-05-2003",
            "value": 11.168,
            "value2": 9.98359
          },
          {
            "category": "21-05-2003",
            "value": 11.2141,
            "value2": 10.0213
          },
          {
            "category": "22-05-2003",
            "value": 11.3189,
            "value2": 10.0591
          },
          {
            "category": "23-05-2003",
            "value": 11.475,
            "value2": 10.1577
          },
          {
            "category": "24-05-2003",
            "value": 11.475,
            "value2": 10.1577
          },
          {
            "category": "25-05-2003",
            "value": 11.475,
            "value2": 10.1577
          },
          {
            "category": "26-05-2003",
            "value": 11.6416,
            "value2": 10.3553
          },
          {
            "category": "27-05-2003",
            "value": 11.5695,
            "value2": 10.2845
          },
          {
            "category": "28-05-2003",
            "value": 11.6755,
            "value2": 10.396
          },
          {
            "category": "29-05-2003",
            "value": 11.7476,
            "value2": 10.4971
          },
          {
            "category": "30-05-2003",
            "value": 11.7579,
            "value2": 10.6705
          },
          {
            "category": "31-05-2003",
            "value": 11.7579,
            "value2": 10.6705
          },
          {
            "category": "01-06-2003",
            "value": 11.7623,
            "value2": 10.6705
          },
          {
            "category": "02-06-2003",
            "value": 11.6557,
            "value2": 10.7017
          },
          {
            "category": "03-06-2003",
            "value": 11.6603,
            "value2": 10.7238
          },
          {
            "category": "04-06-2003",
            "value": 11.7703,
            "value2": 10.8831
          },
          {
            "category": "05-06-2003",
            "value": 11.738,
            "value2": 10.8877
          },
          {
            "category": "06-06-2003",
            "value": 11.7699,
            "value2": 10.961
          },
          {
            "category": "07-06-2003",
            "value": 11.7699,
            "value2": 10.961
          },
          {
            "category": "08-06-2003",
            "value": 11.7699,
            "value2": 10.961
          },
          {
            "category": "09-06-2003",
            "value": 11.872,
            "value2": 11.03
          },
          {
            "category": "10-06-2003",
            "value": 11.803,
            "value2": 10.8222
          },
          {
            "category": "11-06-2003",
            "value": 11.9011,
            "value2": 10.9209
          },
          {
            "category": "12-06-2003",
            "value": 11.9991,
            "value2": 11.0363
          },
          {
            "category": "13-06-2003",
            "value": 12.0151,
            "value2": 11.0758
          },
          {
            "category": "14-06-2003",
            "value": 12.0151,
            "value2": 11.0758
          },
          {
            "category": "15-06-2003",
            "value": 12.0151,
            "value2": 11.0758
          },
          {
            "category": "16-06-2003",
            "value": 12.0058,
            "value2": 11.0564
          },
          {
            "category": "17-06-2003",
            "value": 12.231,
            "value2": 11.2989
          },
          {
            "category": "18-06-2003",
            "value": 12.2934,
            "value2": 11.3684
          },
          {
            "category": "19-06-2003",
            "value": 12.3786,
            "value2": 11.4441
          },
          {
            "category": "20-06-2003",
            "value": 12.3853,
            "value2": 11.545
          },
          {
            "category": "21-06-2003",
            "value": 12.3853,
            "value2": 11.545
          },
          {
            "category": "22-06-2003",
            "value": 12.3923,
            "value2": 11.545
          },
          {
            "category": "23-06-2003",
            "value": 12.2734,
            "value2": 11.3926
          },
          {
            "category": "24-06-2003",
            "value": 12.2337,
            "value2": 11.4173
          },
          {
            "category": "25-06-2003",
            "value": 12.4521,
            "value2": 11.6016
          },
          {
            "category": "26-06-2003",
            "value": 12.5202,
            "value2": 11.6483
          },
          {
            "category": "27-06-2003",
            "value": 12.6303,
            "value2": 11.7662
          },
          {
            "category": "28-06-2003",
            "value": 12.6303,
            "value2": 11.7662
          },
          {
            "category": "29-06-2003",
            "value": 12.6303,
            "value2": 11.7662
          },
          {
            "category": "30-06-2003",
            "value": 12.8088,
            "value2": 11.8604
          },
          {
            "category": "01-07-2003",
            "value": 12.8881,
            "value2": 11.8687
          },
          {
            "category": "02-07-2003",
            "value": 13.051,
            "value2": 11.8965
          },
          {
            "category": "03-07-2003",
            "value": 13.1575,
            "value2": 12.0296
          },
          {
            "category": "04-07-2003",
            "value": 13.1506,
            "value2": 12.0524
          },
          {
            "category": "05-07-2003",
            "value": 13.1506,
            "value2": 12.0524
          },
          {
            "category": "06-07-2003",
            "value": 13.1557,
            "value2": 12.0524
          },
          {
            "category": "07-07-2003",
            "value": 13.3119,
            "value2": 12.1479
          },
          {
            "category": "08-07-2003",
            "value": 13.2034,
            "value2": 12.0936
          },
          {
            "category": "09-07-2003",
            "value": 13.0572,
            "value2": 11.9843
          },
          {
            "category": "10-07-2003",
            "value": 16.49,
            "value2": 12.1335
          },
          {
            "category": "11-07-2003",
            "value": 16.6088,
            "value2": 12.1301
          },
          {
            "category": "12-07-2003",
            "value": 16.6088,
            "value2": 12.1301
          },
          {
            "category": "13-07-2003",
            "value": 16.6101,
            "value2": 12.1301
          },
          {
            "category": "14-07-2003",
            "value": 16.8259,
            "value2": 12.2634
          },
          {
            "category": "15-07-2003",
            "value": 16.7424,
            "value2": 12.1316
          },
          {
            "category": "16-07-2003",
            "value": 17.0027,
            "value2": 12.2889
          },
          {
            "category": "17-07-2003",
            "value": 16.7923,
            "value2": 12.0931
          },
          {
            "category": "18-07-2003",
            "value": 16.7777,
            "value2": 11.9951
          },
          {
            "category": "19-07-2003",
            "value": 16.7777,
            "value2": 11.9951
          },
          {
            "category": "20-07-2003",
            "value": 16.7882,
            "value2": 11.9951
          },
          {
            "category": "21-07-2003",
            "value": 16.4913,
            "value2": 11.7586
          },
          {
            "category": "22-07-2003",
            "value": 16.3546,
            "value2": 11.6758
          },
          {
            "category": "23-07-2003",
            "value": 16.4313,
            "value2": 11.7566
          },
          {
            "category": "24-07-2003",
            "value": 16.7254,
            "value2": 11.9862
          },
          {
            "category": "25-07-2003",
            "value": 17.0392,
            "value2": 12.1797
          },
          {
            "category": "26-07-2003",
            "value": 17.0392,
            "value2": 12.1797
          },
          {
            "category": "27-07-2003",
            "value": 17.0524,
            "value2": 12.1797
          },
          {
            "category": "28-07-2003",
            "value": 17.2477,
            "value2": 12.2682
          },
          {
            "category": "29-07-2003",
            "value": 17.339,
            "value2": 12.2969
          },
          {
            "category": "30-07-2003",
            "value": 17.5462,
            "value2": 12.443
          },
          {
            "category": "31-07-2003",
            "value": 17.4919,
            "value2": 12.428
          },
          {
            "category": "01-08-2003",
            "value": 17.7156,
            "value2": 12.5361
          },
          {
            "category": "02-08-2003",
            "value": 17.7156,
            "value2": 12.5361
          },
          {
            "category": "03-08-2003",
            "value": 17.7182,
            "value2": 12.5361
          },
          {
            "category": "04-08-2003",
            "value": 17.8332,
            "value2": 12.7547
          },
          {
            "category": "05-08-2003",
            "value": 17.5784,
            "value2": 12.5508
          },
          {
            "category": "06-08-2003",
            "value": 14.0999,
            "value2": 12.5153
          },
          {
            "category": "07-08-2003",
            "value": 14.3584,
            "value2": 12.8046
          },
          {
            "category": "08-08-2003",
            "value": 14.5293,
            "value2": 13.096
          },
          {
            "category": "09-08-2003",
            "value": 14.5293,
            "value2": 13.096
          },
          {
            "category": "10-08-2003",
            "value": 14.5293,
            "value2": 13.096
          },
          {
            "category": "11-08-2003",
            "value": 14.6436,
            "value2": 13.2766
          },
          {
            "category": "12-08-2003",
            "value": 14.5714,
            "value2": 13.2316
          },
          {
            "category": "13-08-2003",
            "value": 14.6972,
            "value2": 13.3995
          },
          {
            "category": "14-08-2003",
            "value": 14.7979,
            "value2": 13.3983
          },
          {
            "category": "15-08-2003",
            "value": 14.7979,
            "value2": 13.3983
          },
          {
            "category": "16-08-2003",
            "value": 14.7979,
            "value2": 13.3983
          },
          {
            "category": "17-08-2003",
            "value": 14.7979,
            "value2": 13.3983
          },
          {
            "category": "18-08-2003",
            "value": 15.1222,
            "value2": 13.715
          },
          {
            "category": "19-08-2003",
            "value": 15.1986,
            "value2": 13.6169
          },
          {
            "category": "20-08-2003",
            "value": 15.3381,
            "value2": 13.7047
          },
          {
            "category": "21-08-2003",
            "value": 15.475,
            "value2": 13.8723
          },
          {
            "category": "22-08-2003",
            "value": 15.6167,
            "value2": 14.0034
          },
          {
            "category": "23-08-2003",
            "value": 15.6167,
            "value2": 14.0034
          },
          {
            "category": "24-08-2003",
            "value": 15.6167,
            "value2": 14.0034
          },
          {
            "category": "25-08-2003",
            "value": 15.1795,
            "value2": 13.5257
          },
          {
            "category": "26-08-2003",
            "value": 15.7603,
            "value2": 14.1258
          },
          {
            "category": "27-08-2003",
            "value": 16.1112,
            "value2": 14.3926
          },
          {
            "category": "28-08-2003",
            "value": 16.2021,
            "value2": 14.4156
          },
          {
            "category": "29-08-2003",
            "value": 16.4741,
            "value2": 14.5699
          },
          {
            "category": "30-08-2003",
            "value": 16.4741,
            "value2": 14.5699
          },
          {
            "category": "31-08-2003",
            "value": 16.4739,
            "value2": 14.5699
          },
          {
            "category": "01-09-2003",
            "value": 16.5193,
            "value2": 14.9032
          },
          {
            "category": "02-09-2003",
            "value": 16.4999,
            "value2": 14.8937
          },
          {
            "category": "03-09-2003",
            "value": 16.1363,
            "value2": 14.6194
          },
          {
            "category": "04-09-2003",
            "value": 16.2167,
            "value2": 14.7564
          },
          {
            "category": "05-09-2003",
            "value": 16.4254,
            "value2": 14.9658
          },
          {
            "category": "06-09-2003",
            "value": 16.4254,
            "value2": 14.9658
          },
          {
            "category": "07-09-2003",
            "value": 16.4255,
            "value2": 14.9658
          },
          {
            "category": "08-09-2003",
            "value": 16.7943,
            "value2": 15.1918
          },
          {
            "category": "09-09-2003",
            "value": 16.7406,
            "value2": 15.129
          },
          {
            "category": "10-09-2003",
            "value": 16.7371,
            "value2": 15.1778
          },
          {
            "category": "11-09-2003",
            "value": 16.9279,
            "value2": 15.1649
          },
          {
            "category": "12-09-2003",
            "value": 16.5906,
            "value2": 14.8133
          },
          {
            "category": "13-09-2003",
            "value": 16.5906,
            "value2": 14.8133
          },
          {
            "category": "14-09-2003",
            "value": 16.5906,
            "value2": 14.8133
          },
          {
            "category": "15-09-2003",
            "value": 16.1516,
            "value2": 14.2373
          },
          {
            "category": "16-09-2003",
            "value": 16.4309,
            "value2": 14.5421
          },
          {
            "category": "17-09-2003",
            "value": 16.2155,
            "value2": 14.2606
          },
          {
            "category": "18-09-2003",
            "value": 15.8477,
            "value2": 13.8287
          },
          {
            "category": "19-09-2003",
            "value": 16.0121,
            "value2": 13.9792
          },
          {
            "category": "20-09-2003",
            "value": 16.0121,
            "value2": 13.9792
          },
          {
            "category": "21-09-2003",
            "value": 16.0218,
            "value2": 13.9792
          },
          {
            "category": "22-09-2003",
            "value": 15.8946,
            "value2": 13.7696
          },
          {
            "category": "23-09-2003",
            "value": 16.1706,
            "value2": 14.1074
          },
          {
            "category": "24-09-2003",
            "value": 16.6499,
            "value2": 14.4791
          },
          {
            "category": "25-09-2003",
            "value": 16.6778,
            "value2": 14.4381
          },
          {
            "category": "26-09-2003",
            "value": 17.1333,
            "value2": 14.6814
          },
          {
            "category": "27-09-2003",
            "value": 17.1333,
            "value2": 14.6814
          },
          {
            "category": "28-09-2003",
            "value": 17.1333,
            "value2": 14.6814
          },
          {
            "category": "29-09-2003",
            "value": 17.4652,
            "value2": 14.9347
          },
          {
            "category": "30-09-2003",
            "value": 17.7113,
            "value2": 15.0973
          },
          {
            "category": "01-10-2003",
            "value": 17.8241,
            "value2": 15.1283
          },
          {
            "category": "02-10-2003",
            "value": 17.8241,
            "value2": 15.1283
          },
          {
            "category": "03-10-2003",
            "value": 18.0827,
            "value2": 15.3856
          },
          {
            "category": "04-10-2003",
            "value": 18.0827,
            "value2": 15.3856
          },
          {
            "category": "05-10-2003",
            "value": 18.0827,
            "value2": 15.3856
          },
          {
            "category": "06-10-2003",
            "value": 18.3633,
            "value2": 15.7496
          },
          {
            "category": "07-10-2003",
            "value": 18.3379,
            "value2": 15.6944
          },
          {
            "category": "08-10-2003",
            "value": 18.4084,
            "value2": 15.7626
          },
          {
            "category": "09-10-2003",
            "value": 18.68,
            "value2": 15.9825
          },
          {
            "category": "10-10-2003",
            "value": 18.8072,
            "value2": 16.0337
          },
          {
            "category": "11-10-2003",
            "value": 18.8072,
            "value2": 16.0337
          },
          {
            "category": "12-10-2003",
            "value": 18.8072,
            "value2": 16.0337
          },
          {
            "category": "13-10-2003",
            "value": 19.1799,
            "value2": 16.355
          },
          {
            "category": "14-10-2003",
            "value": 18.6203,
            "value2": 15.9893
          },
          {
            "category": "15-10-2003",
            "value": 18.8344,
            "value2": 16.1565
          },
          {
            "category": "16-10-2003",
            "value": 19.0037,
            "value2": 16.252
          },
          {
            "category": "17-10-2003",
            "value": 19.0616,
            "value2": 16.4023
          },
          {
            "category": "18-10-2003",
            "value": 19.0616,
            "value2": 16.4023
          },
          {
            "category": "19-10-2003",
            "value": 19.0617,
            "value2": 16.4023
          },
          {
            "category": "20-10-2003",
            "value": 18.5771,
            "value2": 16.065
          },
          {
            "category": "21-10-2003",
            "value": 18.1002,
            "value2": 15.717
          },
          {
            "category": "22-10-2003",
            "value": 17.9759,
            "value2": 15.5521
          },
          {
            "category": "23-10-2003",
            "value": 17.8945,
            "value2": 15.3535
          },
          {
            "category": "24-10-2003",
            "value": 18.2737,
            "value2": 15.6919
          },
          {
            "category": "25-10-2003",
            "value": 18.5824,
            "value2": 15.9419
          },
          {
            "category": "26-10-2003",
            "value": 18.5824,
            "value2": 15.9419
          },
          {
            "category": "27-10-2003",
            "value": 18.1514,
            "value2": 15.585
          },
          {
            "category": "28-10-2003",
            "value": 18.2374,
            "value2": 15.5414
          },
          {
            "category": "29-10-2003",
            "value": 18.4918,
            "value2": 15.6275
          },
          {
            "category": "30-10-2003",
            "value": 18.9619,
            "value2": 15.764
          },
          {
            "category": "31-10-2003",
            "value": 19.5668,
            "value2": 16.2087
          },
          {
            "category": "01-11-2003",
            "value": 19.5668,
            "value2": 16.2087
          },
          {
            "category": "02-11-2003",
            "value": 19.5668,
            "value2": 16.2087
          },
          {
            "category": "03-11-2003",
            "value": 19.7865,
            "value2": 16.5348
          },
          {
            "category": "04-11-2003",
            "value": 20.0452,
            "value2": 16.6894
          },
          {
            "category": "05-11-2003",
            "value": 19.8838,
            "value2": 16.7496
          },
          {
            "category": "06-11-2003",
            "value": 20.0242,
            "value2": 16.8663
          },
          {
            "category": "07-11-2003",
            "value": 19.8508,
            "value2": 16.7466
          },
          {
            "category": "08-11-2003",
            "value": 19.8508,
            "value2": 16.7466
          },
          {
            "category": "09-11-2003",
            "value": 19.8508,
            "value2": 16.7466
          },
          {
            "category": "10-11-2003",
            "value": 19.9442,
            "value2": 16.9562
          },
          {
            "category": "11-11-2003",
            "value": 20.3075,
            "value2": 17.1475
          },
          {
            "category": "12-11-2003",
            "value": 20.3342,
            "value2": 17.1478
          },
          {
            "category": "13-11-2003",
            "value": 20.0573,
            "value2": 16.8242
          },
          {
            "category": "14-11-2003",
            "value": 19.7258,
            "value2": 16.5002
          },
          {
            "category": "15-11-2003",
            "value": 20.028,
            "value2": 16.711
          },
          {
            "category": "16-11-2003",
            "value": 20.028,
            "value2": 16.711
          },
          {
            "category": "17-11-2003",
            "value": 20.2588,
            "value2": 16.8541
          },
          {
            "category": "18-11-2003",
            "value": 20.0895,
            "value2": 16.7391
          },
          {
            "category": "19-11-2003",
            "value": 19.7644,
            "value2": 16.4696
          },
          {
            "category": "20-11-2003",
            "value": 19.5986,
            "value2": 16.2335
          },
          {
            "category": "21-11-2003",
            "value": 19.683,
            "value2": 16.3887
          },
          {
            "category": "22-11-2003",
            "value": 19.6828,
            "value2": 16.3887
          },
          {
            "category": "23-11-2003",
            "value": 19.6828,
            "value2": 16.3887
          },
          {
            "category": "24-11-2003",
            "value": 19.8274,
            "value2": 16.4716
          },
          {
            "category": "25-11-2003",
            "value": 20.2275,
            "value2": 16.729
          },
          {
            "category": "26-11-2003",
            "value": 20.2274,
            "value2": 16.729
          },
          {
            "category": "27-11-2003",
            "value": 20.4611,
            "value2": 16.9965
          },
          {
            "category": "28-11-2003",
            "value": 20.779,
            "value2": 17.1982
          },
          {
            "category": "29-11-2003",
            "value": 20.779,
            "value2": 17.1982
          },
          {
            "category": "30-11-2003",
            "value": 20.779,
            "value2": 17.1982
          },
          {
            "category": "01-12-2003",
            "value": 21.1355,
            "value2": 17.5565
          },
          {
            "category": "02-12-2003",
            "value": 21.3494,
            "value2": 17.5883
          },
          {
            "category": "03-12-2003",
            "value": 21.6042,
            "value2": 17.7541
          },
          {
            "category": "04-12-2003",
            "value": 21.3998,
            "value2": 17.8838
          },
          {
            "category": "05-12-2003",
            "value": 21.5595,
            "value2": 17.633
          },
          {
            "category": "06-12-2003",
            "value": 21.5595,
            "value2": 17.633
          },
          {
            "category": "07-12-2003",
            "value": 21.5595,
            "value2": 17.633
          },
          {
            "category": "08-12-2003",
            "value": 21.9189,
            "value2": 17.7692
          },
          {
            "category": "09-12-2003",
            "value": 22.3345,
            "value2": 18.1215
          },
          {
            "category": "10-12-2003",
            "value": 22.3185,
            "value2": 18.2821
          },
          {
            "category": "11-12-2003",
            "value": 22.4605,
            "value2": 18.3722
          },
          {
            "category": "12-12-2003",
            "value": 22.5229,
            "value2": 18.4105
          },
          {
            "category": "13-12-2003",
            "value": 22.5229,
            "value2": 18.4105
          },
          {
            "category": "14-12-2003",
            "value": 22.5229,
            "value2": 18.4105
          },
          {
            "category": "15-12-2003",
            "value": 22.7755,
            "value2": 18.6424
          },
          {
            "category": "16-12-2003",
            "value": 22.7653,
            "value2": 18.6494
          },
          {
            "category": "17-12-2003",
            "value": 22.8506,
            "value2": 18.6705
          },
          {
            "category": "18-12-2003",
            "value": 23.3131,
            "value2": 19.051
          },
          {
            "category": "19-12-2003",
            "value": 23.5452,
            "value2": 19.3142
          },
          {
            "category": "20-12-2003",
            "value": 23.5452,
            "value2": 19.3142
          },
          {
            "category": "21-12-2003",
            "value": 23.5452,
            "value2": 19.3142
          },
          {
            "category": "22-12-2003",
            "value": 23.9637,
            "value2": 19.5462
          },
          {
            "category": "23-12-2003",
            "value": 23.9596,
            "value2": 19.4288
          },
          {
            "category": "24-12-2003",
            "value": 24.349,
            "value2": 19.664
          },
          {
            "category": "25-12-2003",
            "value": 24.349,
            "value2": 19.664
          },
          {
            "category": "26-12-2003",
            "value": 24.753,
            "value2": 19.9659
          },
          {
            "category": "27-12-2003",
            "value": 24.753,
            "value2": 19.9659
          },
          {
            "category": "28-12-2003",
            "value": 24.753,
            "value2": 19.9659
          },
          {
            "category": "29-12-2003",
            "value": 25.2031,
            "value2": 20.3374
          },
          {
            "category": "30-12-2003",
            "value": 25.0087,
            "value2": 20.2851
          },
          {
            "category": "31-12-2003",
            "value": 25.0691,
            "value2": 20.4329
          },
          {
            "category": "01-01-2004",
            "value": 25.3273,
            "value2": 20.8072
          },
          {
            "category": "02-01-2004",
            "value": 25.8211,
            "value2": 21.4283
          },
          {
            "category": "03-01-2004",
            "value": 25.8211,
            "value2": 21.4283
          },
          {
            "category": "04-01-2004",
            "value": 25.8211,
            "value2": 21.4283
          },
          {
            "category": "05-01-2004",
            "value": 25.8087,
            "value2": 21.4819
          },
          {
            "category": "06-01-2004",
            "value": 25.5808,
            "value2": 21.0364
          },
          {
            "category": "07-01-2004",
            "value": 25.467,
            "value2": 21.023
          },
          {
            "category": "08-01-2004",
            "value": 26.3753,
            "value2": 21.7361
          },
          {
            "category": "09-01-2004",
            "value": 26.6885,
            "value2": 21.6762
          },
          {
            "category": "10-01-2004",
            "value": 26.6885,
            "value2": 21.6762
          },
          {
            "category": "11-01-2004",
            "value": 26.6885,
            "value2": 21.6762
          },
          {
            "category": "12-01-2004",
            "value": 26.366,
            "value2": 21.3337
          },
          {
            "category": "13-01-2004",
            "value": 26.4163,
            "value2": 21.3853
          },
          {
            "category": "14-01-2004",
            "value": 26.8207,
            "value2": 21.5705
          },
          {
            "category": "15-01-2004",
            "value": 26.5577,
            "value2": 21.2302
          },
          {
            "category": "16-01-2004",
            "value": 25.9552,
            "value2": 20.7117
          },
          {
            "category": "17-01-2004",
            "value": 25.9552,
            "value2": 20.7117
          },
          {
            "category": "18-01-2004",
            "value": 25.9552,
            "value2": 20.7117
          },
          {
            "category": "19-01-2004",
            "value": 26.1916,
            "value2": 21.0104
          },
          {
            "category": "20-01-2004",
            "value": 25.6817,
            "value2": 20.5302
          },
          {
            "category": "21-01-2004",
            "value": 24.6703,
            "value2": 19.4643
          },
          {
            "category": "22-01-2004",
            "value": 23.9968,
            "value2": 18.9218
          },
          {
            "category": "23-01-2004",
            "value": 25.1658,
            "value2": 19.9447
          },
          {
            "category": "24-01-2004",
            "value": 25.1658,
            "value2": 19.9447
          },
          {
            "category": "25-01-2004",
            "value": 25.1658,
            "value2": 19.9447
          },
          {
            "category": "26-01-2004",
            "value": 25.1658,
            "value2": 19.9447
          },
          {
            "category": "27-01-2004",
            "value": 25.9421,
            "value2": 20.6087
          },
          {
            "category": "28-01-2004",
            "value": 25.7039,
            "value2": 20.1341
          },
          {
            "category": "29-01-2004",
            "value": 25.3377,
            "value2": 19.8928
          },
          {
            "category": "30-01-2004",
            "value": 24.7994,
            "value2": 19.4008
          },
          {
            "category": "31-01-2004",
            "value": 24.7994,
            "value2": 19.4008
          },
          {
            "category": "01-02-2004",
            "value": 24.7994,
            "value2": 19.4008
          },
          {
            "category": "02-02-2004",
            "value": 24.7994,
            "value2": 19.4008
          },
          {
            "category": "03-02-2004",
            "value": 23.903,
            "value2": 18.7146
          },
          {
            "category": "04-02-2004",
            "value": 24.6289,
            "value2": 19.2469
          },
          {
            "category": "05-02-2004",
            "value": 24.7544,
            "value2": 19.1949
          },
          {
            "category": "06-02-2004",
            "value": 25.0298,
            "value2": 19.5024
          },
          {
            "category": "07-02-2004",
            "value": 25.0298,
            "value2": 19.5024
          },
          {
            "category": "08-02-2004",
            "value": 25.0298,
            "value2": 19.5024
          },
          {
            "category": "09-02-2004",
            "value": 25.6457,
            "value2": 19.9692
          },
          {
            "category": "10-02-2004",
            "value": 25.7132,
            "value2": 19.9738
          },
          {
            "category": "11-02-2004",
            "value": 25.9387,
            "value2": 20.1085
          },
          {
            "category": "12-02-2004",
            "value": 26.1629,
            "value2": 20.1218
          },
          {
            "category": "13-02-2004",
            "value": 26.5186,
            "value2": 20.4143
          },
          {
            "category": "14-02-2004",
            "value": 26.5186,
            "value2": 20.4143
          },
          {
            "category": "15-02-2004",
            "value": 26.5186,
            "value2": 20.4143
          },
          {
            "category": "16-02-2004",
            "value": 26.7544,
            "value2": 20.4355
          },
          {
            "category": "17-02-2004",
            "value": 26.8459,
            "value2": 20.4481
          },
          {
            "category": "18-02-2004",
            "value": 26.8569,
            "value2": 20.5504
          },
          {
            "category": "19-02-2004",
            "value": 26.3158,
            "value2": 19.9637
          },
          {
            "category": "20-02-2004",
            "value": 26.33,
            "value2": 19.9608
          },
          {
            "category": "21-02-2004",
            "value": 26.33,
            "value2": 19.9608
          },
          {
            "category": "22-02-2004",
            "value": 26.33,
            "value2": 19.9608
          },
          {
            "category": "23-02-2004",
            "value": 25.4933,
            "value2": 19.3889
          },
          {
            "category": "24-02-2004",
            "value": 25.7768,
            "value2": 19.4684
          },
          {
            "category": "25-02-2004",
            "value": 25.3581,
            "value2": 19.017
          },
          {
            "category": "26-02-2004",
            "value": 25.2657,
            "value2": 18.9339
          },
          {
            "category": "27-02-2004",
            "value": 25.5109,
            "value2": 19.2418
          },
          {
            "category": "28-02-2004",
            "value": 25.5109,
            "value2": 19.2418
          },
          {
            "category": "29-02-2004",
            "value": 25.5109,
            "value2": 19.2418
          },
          {
            "category": "01-03-2004",
            "value": 26.1675,
            "value2": 19.78
          },
          {
            "category": "02-03-2004",
            "value": 26.1675,
            "value2": 19.78
          },
          {
            "category": "03-03-2004",
            "value": 26.299,
            "value2": 19.8061
          },
          {
            "category": "04-03-2004",
            "value": 26.2462,
            "value2": 19.7216
          },
          {
            "category": "05-03-2004",
            "value": 26.5818,
            "value2": 20.0574
          },
          {
            "category": "06-03-2004",
            "value": 26.5818,
            "value2": 20.0574
          },
          {
            "category": "07-03-2004",
            "value": 26.5818,
            "value2": 20.0574
          },
          {
            "category": "08-03-2004",
            "value": 26.9924,
            "value2": 20.4328
          },
          {
            "category": "09-03-2004",
            "value": 26.7702,
            "value2": 20.1849
          },
          {
            "category": "10-03-2004",
            "value": 26.4778,
            "value2": 19.9808
          },
          {
            "category": "11-03-2004",
            "value": 25.921,
            "value2": 19.6426
          },
          {
            "category": "12-03-2004",
            "value": 26.009,
            "value2": 19.6569
          },
          {
            "category": "13-03-2004",
            "value": 26.009,
            "value2": 19.6569
          },
          {
            "category": "14-03-2004",
            "value": 26.009,
            "value2": 19.6569
          },
          {
            "category": "15-03-2004",
            "value": 25.255,
            "value2": 19.0604
          },
          {
            "category": "16-03-2004",
            "value": 25.2145,
            "value2": 19.0534
          },
          {
            "category": "17-03-2004",
            "value": 25.1987,
            "value2": 19.1318
          },
          {
            "category": "18-03-2004",
            "value": 24.7996,
            "value2": 18.7879
          },
          {
            "category": "19-03-2004",
            "value": 24.8921,
            "value2": 18.8378
          },
          {
            "category": "20-03-2004",
            "value": 24.8921,
            "value2": 18.8378
          },
          {
            "category": "21-03-2004",
            "value": 24.8921,
            "value2": 18.8378
          },
          {
            "category": "22-03-2004",
            "value": 24.2174,
            "value2": 18.396
          },
          {
            "category": "23-03-2004",
            "value": 24.1584,
            "value2": 18.3756
          },
          {
            "category": "24-03-2004",
            "value": 24.2772,
            "value2": 18.406
          },
          {
            "category": "25-03-2004",
            "value": 24.7518,
            "value2": 18.5716
          },
          {
            "category": "26-03-2004",
            "value": 25.3145,
            "value2": 18.9806
          },
          {
            "category": "27-03-2004",
            "value": 25.3145,
            "value2": 18.9806
          },
          {
            "category": "28-03-2004",
            "value": 25.3145,
            "value2": 18.9806
          },
          {
            "category": "29-03-2004",
            "value": 25.6434,
            "value2": 19.1781
          },
          {
            "category": "30-03-2004",
            "value": 25.6802,
            "value2": 19.1659
          },
          {
            "category": "31-03-2004",
            "value": 25.4624,
            "value2": 19.3729
          },
          {
            "category": "01-04-2004",
            "value": 26.6076,
            "value2": 19.8241
          },
          {
            "category": "02-04-2004",
            "value": 26.8649,
            "value2": 20.1083
          },
          {
            "category": "03-04-2004",
            "value": 26.8649,
            "value2": 20.1083
          },
          {
            "category": "04-04-2004",
            "value": 26.8649,
            "value2": 20.1083
          },
          {
            "category": "05-04-2004",
            "value": 26.9463,
            "value2": 20.252
          },
          {
            "category": "06-04-2004",
            "value": 26.8401,
            "value2": 20.1687
          },
          {
            "category": "07-04-2004",
            "value": 26.8702,
            "value2": 20.149
          },
          {
            "category": "08-04-2004",
            "value": 27.0741,
            "value2": 20.3492
          },
          {
            "category": "09-04-2004",
            "value": 27.0741,
            "value2": 20.3492
          },
          {
            "category": "10-04-2004",
            "value": 27.074,
            "value2": 20.3492
          },
          {
            "category": "11-04-2004",
            "value": 27.074,
            "value2": 20.3492
          },
          {
            "category": "12-04-2004",
            "value": 27.0473,
            "value2": 20.3022
          },
          {
            "category": "13-04-2004",
            "value": 27.5201,
            "value2": 20.6943
          },
          {
            "category": "14-04-2004",
            "value": 27.5205,
            "value2": 20.6943
          },
          {
            "category": "15-04-2004",
            "value": 27.674,
            "value2": 20.683
          },
          {
            "category": "16-04-2004",
            "value": 27.7034,
            "value2": 20.7793
          },
          {
            "category": "17-04-2004",
            "value": 27.776,
            "value2": 20.8372
          },
          {
            "category": "18-04-2004",
            "value": 27.776,
            "value2": 20.8372
          },
          {
            "category": "19-04-2004",
            "value": 27.4102,
            "value2": 20.5255
          },
          {
            "category": "20-04-2004",
            "value": 27.3104,
            "value2": 20.4958
          },
          {
            "category": "21-04-2004",
            "value": 27.8099,
            "value2": 20.8414
          },
          {
            "category": "22-04-2004",
            "value": 28.4196,
            "value2": 21.0832
          },
          {
            "category": "23-04-2004",
            "value": 28.7223,
            "value2": 21.1208
          },
          {
            "category": "24-04-2004",
            "value": 28.7223,
            "value2": 21.1208
          },
          {
            "category": "25-04-2004",
            "value": 28.7223,
            "value2": 21.1208
          },
          {
            "category": "26-04-2004",
            "value": 28.7224,
            "value2": 21.1208
          },
          {
            "category": "27-04-2004",
            "value": 27.5873,
            "value2": 20.2254
          },
          {
            "category": "28-04-2004",
            "value": 27.5796,
            "value2": 20.192
          },
          {
            "category": "29-04-2004",
            "value": 27.6652,
            "value2": 20.1798
          },
          {
            "category": "30-04-2004",
            "value": 26.9719,
            "value2": 20.0434
          },
          {
            "category": "01-05-2004",
            "value": 26.9719,
            "value2": 20.0434
          },
          {
            "category": "02-05-2004",
            "value": 26.9719,
            "value2": 20.0434
          },
          {
            "category": "03-05-2004",
            "value": 26.3895,
            "value2": 19.6619
          },
          {
            "category": "04-05-2004",
            "value": 27.1271,
            "value2": 20.0335
          },
          {
            "category": "05-05-2004",
            "value": 27.5799,
            "value2": 20.206
          },
          {
            "category": "06-05-2004",
            "value": 27.9842,
            "value2": 20.5148
          },
          {
            "category": "07-05-2004",
            "value": 27.6227,
            "value2": 20.2324
          },
          {
            "category": "08-05-2004",
            "value": 27.6227,
            "value2": 20.2324
          },
          {
            "category": "09-05-2004",
            "value": 27.6227,
            "value2": 20.2324
          },
          {
            "category": "10-05-2004",
            "value": 27.2115,
            "value2": 19.869
          },
          {
            "category": "11-05-2004",
            "value": 25.8997,
            "value2": 18.9848
          },
          {
            "category": "12-05-2004",
            "value": 26.0748,
            "value2": 19.1205
          },
          {
            "category": "13-05-2004",
            "value": 26.0733,
            "value2": 19.1091
          },
          {
            "category": "14-05-2004",
            "value": 23.9186,
            "value2": 17.4331
          },
          {
            "category": "15-05-2004",
            "value": 24.2282,
            "value2": 17.4331
          },
          {
            "category": "16-05-2004",
            "value": 24.2282,
            "value2": 17.4331
          },
          {
            "category": "17-05-2004",
            "value": 21.8187,
            "value2": 15.394
          },
          {
            "category": "18-05-2004",
            "value": 23.3012,
            "value2": 16.5983
          },
          {
            "category": "19-05-2004",
            "value": 24.2695,
            "value2": 17.3964
          },
          {
            "category": "20-05-2004",
            "value": 24.1001,
            "value2": 17.2625
          },
          {
            "category": "21-05-2004",
            "value": 23.9153,
            "value2": 17.3252
          },
          {
            "category": "22-05-2004",
            "value": 23.9153,
            "value2": 17.3252
          },
          {
            "category": "23-05-2004",
            "value": 23.9153,
            "value2": 17.3252
          },
          {
            "category": "24-05-2004",
            "value": 24.4514,
            "value2": 17.7906
          },
          {
            "category": "25-05-2004",
            "value": 24.2943,
            "value2": 17.7601
          },
          {
            "category": "26-05-2004",
            "value": 24.4982,
            "value2": 17.7641
          },
          {
            "category": "27-05-2004",
            "value": 24.3471,
            "value2": 17.5984
          },
          {
            "category": "28-05-2004",
            "value": 23.2354,
            "value2": 16.7461
          },
          {
            "category": "29-05-2004",
            "value": 23.2354,
            "value2": 16.7461
          },
          {
            "category": "30-05-2004",
            "value": 23.2354,
            "value2": 16.7461
          },
          {
            "category": "31-05-2004",
            "value": 22.4041,
            "value2": 16.3348
          },
          {
            "category": "01-06-2004",
            "value": 22.8162,
            "value2": 16.6174
          },
          {
            "category": "02-06-2004",
            "value": 23.0116,
            "value2": 16.9051
          },
          {
            "category": "03-06-2004",
            "value": 22.5705,
            "value2": 16.5249
          },
          {
            "category": "04-06-2004",
            "value": 22.8674,
            "value2": 16.7451
          },
          {
            "category": "05-06-2004",
            "value": 22.8674,
            "value2": 16.7451
          },
          {
            "category": "06-06-2004",
            "value": 22.8674,
            "value2": 16.7451
          },
          {
            "category": "07-06-2004",
            "value": 23.1422,
            "value2": 16.9569
          },
          {
            "category": "08-06-2004",
            "value": 23.2596,
            "value2": 17.0454
          },
          {
            "category": "09-06-2004",
            "value": 23.4192,
            "value2": 17.0903
          },
          {
            "category": "10-06-2004",
            "value": 23.2962,
            "value2": 17.0912
          },
          {
            "category": "11-06-2004",
            "value": 22.8433,
            "value2": 16.7258
          },
          {
            "category": "12-06-2004",
            "value": 22.8434,
            "value2": 16.7258
          },
          {
            "category": "13-06-2004",
            "value": 22.8434,
            "value2": 16.7258
          },
          {
            "category": "14-06-2004",
            "value": 22.4462,
            "value2": 16.3655
          },
          {
            "category": "15-06-2004",
            "value": 22.5839,
            "value2": 16.5557
          },
          {
            "category": "16-06-2004",
            "value": 22.4276,
            "value2": 16.4933
          },
          {
            "category": "17-06-2004",
            "value": 22.494,
            "value2": 16.6241
          },
          {
            "category": "18-06-2004",
            "value": 22.2632,
            "value2": 16.437
          },
          {
            "category": "19-06-2004",
            "value": 22.2632,
            "value2": 16.437
          },
          {
            "category": "20-06-2004",
            "value": 22.2632,
            "value2": 16.437
          },
          {
            "category": "21-06-2004",
            "value": 22.1462,
            "value2": 16.3117
          },
          {
            "category": "22-06-2004",
            "value": 22.0295,
            "value2": 16.2078
          },
          {
            "category": "23-06-2004",
            "value": 21.5959,
            "value2": 15.8538
          },
          {
            "category": "24-06-2004",
            "value": 21.7395,
            "value2": 16.02
          },
          {
            "category": "25-06-2004",
            "value": 22.2181,
            "value2": 16.2852
          },
          {
            "category": "26-06-2004",
            "value": 22.2181,
            "value2": 16.2852
          },
          {
            "category": "27-06-2004",
            "value": 22.2181,
            "value2": 16.2852
          },
          {
            "category": "28-06-2004",
            "value": 22.6895,
            "value2": 16.6261
          },
          {
            "category": "29-06-2004",
            "value": 22.9457,
            "value2": 16.6925
          },
          {
            "category": "30-06-2004",
            "value": 23.0335,
            "value2": 16.6114
          },
          {
            "category": "01-07-2004",
            "value": 23.42,
            "value2": 16.9075
          },
          {
            "category": "02-07-2004",
            "value": 23.3636,
            "value2": 16.916
          },
          {
            "category": "03-07-2004",
            "value": 23.3636,
            "value2": 16.916
          },
          {
            "category": "04-07-2004",
            "value": 23.3636,
            "value2": 16.916
          },
          {
            "category": "05-07-2004",
            "value": 23.2423,
            "value2": 16.7797
          },
          {
            "category": "06-07-2004",
            "value": 23.6722,
            "value2": 17.0551
          },
          {
            "category": "07-07-2004",
            "value": 23.6838,
            "value2": 17.1457
          },
          {
            "category": "08-07-2004",
            "value": 23.0012,
            "value2": 16.6045
          },
          {
            "category": "09-07-2004",
            "value": 23.5223,
            "value2": 16.944
          },
          {
            "category": "10-07-2004",
            "value": 23.5223,
            "value2": 16.944
          },
          {
            "category": "11-07-2004",
            "value": 23.5243,
            "value2": 16.944
          },
          {
            "category": "12-07-2004",
            "value": 23.6121,
            "value2": 17.0049
          },
          {
            "category": "13-07-2004",
            "value": 23.3006,
            "value2": 16.8607
          },
          {
            "category": "14-07-2004",
            "value": 22.9988,
            "value2": 16.7483
          },
          {
            "category": "15-07-2004",
            "value": 23.3306,
            "value2": 16.9602
          },
          {
            "category": "16-07-2004",
            "value": 23.6387,
            "value2": 17.2343
          },
          {
            "category": "17-07-2004",
            "value": 23.6387,
            "value2": 17.2343
          },
          {
            "category": "18-07-2004",
            "value": 23.6387,
            "value2": 17.2343
          },
          {
            "category": "19-07-2004",
            "value": 23.8005,
            "value2": 17.3749
          },
          {
            "category": "20-07-2004",
            "value": 23.6912,
            "value2": 17.3071
          },
          {
            "category": "21-07-2004",
            "value": 23.8685,
            "value2": 17.4537
          },
          {
            "category": "22-07-2004",
            "value": 24.1024,
            "value2": 17.653
          },
          {
            "category": "23-07-2004",
            "value": 24.0423,
            "value2": 17.6953
          },
          {
            "category": "24-07-2004",
            "value": 24.0423,
            "value2": 17.6953
          },
          {
            "category": "25-07-2004",
            "value": 24.0449,
            "value2": 17.6953
          },
          {
            "category": "26-07-2004",
            "value": 24.2449,
            "value2": 17.8695
          },
          {
            "category": "27-07-2004",
            "value": 23.9398,
            "value2": 17.6565
          },
          {
            "category": "28-07-2004",
            "value": 23.6583,
            "value2": 17.58
          },
          {
            "category": "29-07-2004",
            "value": 23.7614,
            "value2": 17.8314
          },
          {
            "category": "30-07-2004",
            "value": 23.9771,
            "value2": 17.9712
          },
          {
            "category": "31-07-2004",
            "value": 23.9775,
            "value2": 17.9712
          },
          {
            "category": "01-08-2004",
            "value": 23.9775,
            "value2": 17.9712
          },
          {
            "category": "02-08-2004",
            "value": 24.0771,
            "value2": 18.0691
          },
          {
            "category": "03-08-2004",
            "value": 24.1465,
            "value2": 18.011
          },
          {
            "category": "04-08-2004",
            "value": 24.1126,
            "value2": 18.0063
          },
          {
            "category": "05-08-2004",
            "value": 24.2822,
            "value2": 18.3123
          },
          {
            "category": "06-08-2004",
            "value": 24.1632,
            "value2": 18.1039
          },
          {
            "category": "07-08-2004",
            "value": 24.1632,
            "value2": 18.1039
          },
          {
            "category": "08-08-2004",
            "value": 24.1683,
            "value2": 18.1039
          },
          {
            "category": "09-08-2004",
            "value": 24.3309,
            "value2": 18.182
          },
          {
            "category": "10-08-2004",
            "value": 24.495,
            "value2": 18.2804
          },
          {
            "category": "11-08-2004",
            "value": 24.1424,
            "value2": 17.9418
          },
          {
            "category": "12-08-2004",
            "value": 23.9585,
            "value2": 17.7873
          },
          {
            "category": "13-08-2004",
            "value": 23.9843,
            "value2": 17.7564
          },
          {
            "category": "14-08-2004",
            "value": 23.9843,
            "value2": 17.7564
          },
          {
            "category": "15-08-2004",
            "value": 23.9849,
            "value2": 17.7564
          },
          {
            "category": "16-08-2004",
            "value": 23.8595,
            "value2": 17.7345
          },
          {
            "category": "17-08-2004",
            "value": 24.0402,
            "value2": 17.8149
          },
          {
            "category": "18-08-2004",
            "value": 23.8259,
            "value2": 17.6277
          },
          {
            "category": "19-08-2004",
            "value": 24.0848,
            "value2": 17.8754
          },
          {
            "category": "20-08-2004",
            "value": 23.8141,
            "value2": 17.7059
          },
          {
            "category": "21-08-2004",
            "value": 23.8141,
            "value2": 17.7059
          },
          {
            "category": "22-08-2004",
            "value": 23.8141,
            "value2": 17.7059
          },
          {
            "category": "23-08-2004",
            "value": 23.7193,
            "value2": 17.5397
          },
          {
            "category": "24-08-2004",
            "value": 23.9258,
            "value2": 17.6975
          },
          {
            "category": "25-08-2004",
            "value": 24.0217,
            "value2": 17.7579
          },
          {
            "category": "26-08-2004",
            "value": 24.2702,
            "value2": 17.9644
          },
          {
            "category": "27-08-2004",
            "value": 24.5076,
            "value2": 18.0361
          },
          {
            "category": "28-08-2004",
            "value": 24.5076,
            "value2": 18.0361
          },
          {
            "category": "29-08-2004",
            "value": 24.5076,
            "value2": 18.0361
          },
          {
            "category": "30-08-2004",
            "value": 24.8334,
            "value2": 18.2878
          },
          {
            "category": "31-08-2004",
            "value": 24.8724,
            "value2": 18.3545
          },
          {
            "category": "01-09-2004",
            "value": 25.0366,
            "value2": 18.4386
          },
          {
            "category": "02-09-2004",
            "value": 24.9823,
            "value2": 18.3729
          },
          {
            "category": "03-09-2004",
            "value": 25.0788,
            "value2": 18.4457
          },
          {
            "category": "04-09-2004",
            "value": 25.0779,
            "value2": 18.4457
          },
          {
            "category": "05-09-2004",
            "value": 25.0796,
            "value2": 18.4457
          },
          {
            "category": "06-09-2004",
            "value": 25.3503,
            "value2": 18.5811
          },
          {
            "category": "07-09-2004",
            "value": 25.5341,
            "value2": 18.6557
          },
          {
            "category": "08-09-2004",
            "value": 25.7692,
            "value2": 18.7329
          },
          {
            "category": "09-09-2004",
            "value": 25.3576,
            "value2": 18.6026
          },
          {
            "category": "10-09-2004",
            "value": 25.4781,
            "value2": 18.7889
          },
          {
            "category": "11-09-2004",
            "value": 25.4781,
            "value2": 18.7889
          },
          {
            "category": "12-09-2004",
            "value": 25.4784,
            "value2": 18.7889
          },
          {
            "category": "13-09-2004",
            "value": 25.478,
            "value2": 18.8612
          },
          {
            "category": "14-09-2004",
            "value": 25.5771,
            "value2": 18.9363
          },
          {
            "category": "15-09-2004",
            "value": 25.4883,
            "value2": 18.892
          },
          {
            "category": "16-09-2004",
            "value": 25.6873,
            "value2": 19.1345
          },
          {
            "category": "17-09-2004",
            "value": 25.9896,
            "value2": 19.3852
          },
          {
            "category": "18-09-2004",
            "value": 25.9896,
            "value2": 19.3852
          },
          {
            "category": "19-09-2004",
            "value": 25.9896,
            "value2": 19.3852
          },
          {
            "category": "20-09-2004",
            "value": 25.9767,
            "value2": 19.3673
          },
          {
            "category": "21-09-2004",
            "value": 26.0507,
            "value2": 19.5926
          },
          {
            "category": "22-09-2004",
            "value": 26.1346,
            "value2": 19.6721
          },
          {
            "category": "23-09-2004",
            "value": 25.8955,
            "value2": 19.4222
          },
          {
            "category": "24-09-2004",
            "value": 26.0997,
            "value2": 19.4412
          },
          {
            "category": "25-09-2004",
            "value": 26.0997,
            "value2": 19.4412
          },
          {
            "category": "26-09-2004",
            "value": 26.0997,
            "value2": 19.4412
          },
          {
            "category": "27-09-2004",
            "value": 26.0887,
            "value2": 19.4284
          },
          {
            "category": "28-09-2004",
            "value": 25.7681,
            "value2": 19.2553
          },
          {
            "category": "29-09-2004",
            "value": 25.8668,
            "value2": 19.502
          },
          {
            "category": "30-09-2004",
            "value": 26.0896,
            "value2": 19.6602
          },
          {
            "category": "01-10-2004",
            "value": 26.082,
            "value2": 19.9246
          },
          {
            "category": "02-10-2004",
            "value": 26.082,
            "value2": 19.9246
          },
          {
            "category": "03-10-2004",
            "value": 26.082,
            "value2": 19.9246
          },
          {
            "category": "04-10-2004",
            "value": 26.1891,
            "value2": 20.2236
          },
          {
            "category": "05-10-2004",
            "value": 26.3673,
            "value2": 20.3367
          },
          {
            "category": "06-10-2004",
            "value": 26.3033,
            "value2": 20.1987
          },
          {
            "category": "07-10-2004",
            "value": 26.635,
            "value2": 20.3685
          },
          {
            "category": "08-10-2004",
            "value": 26.905,
            "value2": 20.4204
          },
          {
            "category": "09-10-2004",
            "value": 27.0887,
            "value2": 20.462
          },
          {
            "category": "10-10-2004",
            "value": 27.1376,
            "value2": 20.462
          },
          {
            "category": "11-10-2004",
            "value": 27.0955,
            "value2": 20.306
          },
          {
            "category": "12-10-2004",
            "value": 26.4026,
            "value2": 20.0135
          },
          {
            "category": "13-10-2004",
            "value": 26.4027,
            "value2": 20.0135
          },
          {
            "category": "14-10-2004",
            "value": 26.498,
            "value2": 20.0933
          },
          {
            "category": "15-10-2004",
            "value": 26.3203,
            "value2": 20.0947
          },
          {
            "category": "16-10-2004",
            "value": 26.3072,
            "value2": 20.0947
          },
          {
            "category": "17-10-2004",
            "value": 26.3072,
            "value2": 20.0947
          },
          {
            "category": "18-10-2004",
            "value": 26.2845,
            "value2": 20.0309
          },
          {
            "category": "19-10-2004",
            "value": 26.5378,
            "value2": 20.2373
          },
          {
            "category": "20-10-2004",
            "value": 26.4807,
            "value2": 20.0568
          },
          {
            "category": "21-10-2004",
            "value": 26.4813,
            "value2": 19.9636
          },
          {
            "category": "22-10-2004",
            "value": 26.4813,
            "value2": 19.9636
          },
          {
            "category": "23-10-2004",
            "value": 26.4811,
            "value2": 19.9636
          },
          {
            "category": "24-10-2004",
            "value": 26.4811,
            "value2": 19.9636
          },
          {
            "category": "25-10-2004",
            "value": 26.1871,
            "value2": 19.6502
          },
          {
            "category": "26-10-2004",
            "value": 26.4228,
            "value2": 19.8834
          },
          {
            "category": "27-10-2004",
            "value": 26.6274,
            "value2": 19.973
          },
          {
            "category": "28-10-2004",
            "value": 26.8538,
            "value2": 20.1536
          },
          {
            "category": "29-10-2004",
            "value": 26.8847,
            "value2": 20.0266
          },
          {
            "category": "30-10-2004",
            "value": 26.8958,
            "value2": 20.0266
          },
          {
            "category": "31-10-2004",
            "value": 26.8853,
            "value2": 20.0266
          },
          {
            "category": "01-11-2004",
            "value": 27.0795,
            "value2": 20.1513
          },
          {
            "category": "02-11-2004",
            "value": 27.4737,
            "value2": 20.3413
          },
          {
            "category": "03-11-2004",
            "value": 27.6752,
            "value2": 20.618
          },
          {
            "category": "04-11-2004",
            "value": 27.6079,
            "value2": 20.6267
          },
          {
            "category": "05-11-2004",
            "value": 27.8749,
            "value2": 20.8253
          },
          {
            "category": "06-11-2004",
            "value": 27.8749,
            "value2": 20.8253
          },
          {
            "category": "07-11-2004",
            "value": 27.8749,
            "value2": 20.8253
          },
          {
            "category": "08-11-2004",
            "value": 27.9086,
            "value2": 20.9252
          },
          {
            "category": "09-11-2004",
            "value": 27.8446,
            "value2": 20.853
          },
          {
            "category": "10-11-2004",
            "value": 28.0994,
            "value2": 21.0657
          },
          {
            "category": "11-11-2004",
            "value": 28.2234,
            "value2": 20.9915
          },
          {
            "category": "12-11-2004",
            "value": 28.4155,
            "value2": 21.0652
          },
          {
            "category": "13-11-2004",
            "value": 28.4155,
            "value2": 21.0652
          },
          {
            "category": "14-11-2004",
            "value": 28.4155,
            "value2": 21.0652
          },
          {
            "category": "15-11-2004",
            "value": 28.4155,
            "value2": 21.0652
          },
          {
            "category": "16-11-2004",
            "value": 28.6577,
            "value2": 21.1229
          },
          {
            "category": "17-11-2004",
            "value": 28.8424,
            "value2": 21.2772
          },
          {
            "category": "18-11-2004",
            "value": 29.037,
            "value2": 21.388
          },
          {
            "category": "19-11-2004",
            "value": 28.9373,
            "value2": 21.1598
          },
          {
            "category": "20-11-2004",
            "value": 28.9373,
            "value2": 21.1598
          },
          {
            "category": "21-11-2004",
            "value": 28.9373,
            "value2": 21.1598
          },
          {
            "category": "22-11-2004",
            "value": 29.1569,
            "value2": 21.2023
          },
          {
            "category": "23-11-2004",
            "value": 29.4146,
            "value2": 21.4456
          },
          {
            "category": "24-11-2004",
            "value": 29.7834,
            "value2": 21.5677
          },
          {
            "category": "25-11-2004",
            "value": 29.9168,
            "value2": 21.5483
          },
          {
            "category": "26-11-2004",
            "value": 29.9168,
            "value2": 21.5483
          },
          {
            "category": "27-11-2004",
            "value": 29.9168,
            "value2": 21.5483
          },
          {
            "category": "28-11-2004",
            "value": 29.9168,
            "value2": 21.5483
          },
          {
            "category": "29-11-2004",
            "value": 30.1405,
            "value2": 21.9161
          },
          {
            "category": "30-11-2004",
            "value": 30.2891,
            "value2": 22.0045
          },
          {
            "category": "01-12-2004",
            "value": 30.3596,
            "value2": 22.0734
          },
          {
            "category": "02-12-2004",
            "value": 30.8782,
            "value2": 22.4978
          },
          {
            "category": "03-12-2004",
            "value": 30.9326,
            "value2": 22.4781
          },
          {
            "category": "04-12-2004",
            "value": 30.9326,
            "value2": 22.4781
          },
          {
            "category": "05-12-2004",
            "value": 30.9326,
            "value2": 22.4781
          },
          {
            "category": "06-12-2004",
            "value": 30.9165,
            "value2": 22.4667
          },
          {
            "category": "07-12-2004",
            "value": 30.9797,
            "value2": 22.4871
          },
          {
            "category": "08-12-2004",
            "value": 31.1673,
            "value2": 22.3618
          },
          {
            "category": "09-12-2004",
            "value": 31.5247,
            "value2": 22.4904
          },
          {
            "category": "10-12-2004",
            "value": 31.3869,
            "value2": 22.3312
          },
          {
            "category": "11-12-2004",
            "value": 31.3869,
            "value2": 22.3312
          },
          {
            "category": "12-12-2004",
            "value": 31.3869,
            "value2": 22.3312
          },
          {
            "category": "13-12-2004",
            "value": 31.5145,
            "value2": 22.528
          },
          {
            "category": "14-12-2004",
            "value": 31.9947,
            "value2": 22.7855
          },
          {
            "category": "15-12-2004",
            "value": 32.3177,
            "value2": 22.9915
          },
          {
            "category": "16-12-2004",
            "value": 32.383,
            "value2": 23.0726
          },
          {
            "category": "17-12-2004",
            "value": 32.0911,
            "value2": 22.9364
          },
          {
            "category": "18-12-2004",
            "value": 32.0911,
            "value2": 22.9364
          },
          {
            "category": "19-12-2004",
            "value": 32.0911,
            "value2": 22.9364
          },
          {
            "category": "20-12-2004",
            "value": 32.3692,
            "value2": 23.1317
          },
          {
            "category": "21-12-2004",
            "value": 32.8341,
            "value2": 23.3498
          },
          {
            "category": "22-12-2004",
            "value": 32.816,
            "value2": 23.2036
          },
          {
            "category": "23-12-2004",
            "value": 33.0337,
            "value2": 23.3644
          },
          {
            "category": "24-12-2004",
            "value": 33.0822,
            "value2": 23.5565
          },
          {
            "category": "25-12-2004",
            "value": 33.0822,
            "value2": 23.5565
          },
          {
            "category": "26-12-2004",
            "value": 33.0822,
            "value2": 23.5565
          },
          {
            "category": "27-12-2004",
            "value": 32.9943,
            "value2": 23.6247
          },
          {
            "category": "28-12-2004",
            "value": 33.3875,
            "value2": 23.8545
          },
          {
            "category": "29-12-2004",
            "value": 33.1648,
            "value2": 23.7949
          },
          {
            "category": "30-12-2004",
            "value": 33.3246,
            "value2": 23.6777
          },
          {
            "category": "31-12-2004",
            "value": 33.5336,
            "value2": 24.0016
          },
          {
            "category": "01-01-2005",
            "value": 33.5336,
            "value2": 24.0016
          },
          {
            "category": "02-01-2005",
            "value": 33.5336,
            "value2": 24.0016
          },
          {
            "category": "03-01-2005",
            "value": 34.1459,
            "value2": 24.4014
          },
          {
            "category": "04-01-2005",
            "value": 34.5706,
            "value2": 24.3704
          },
          {
            "category": "05-01-2005",
            "value": 33.5912,
            "value2": 23.5275
          },
          {
            "category": "06-01-2005",
            "value": 33.5433,
            "value2": 23.1548
          },
          {
            "category": "07-01-2005",
            "value": 33.4019,
            "value2": 23.3727
          },
          {
            "category": "08-01-2005",
            "value": 33.4019,
            "value2": 23.3727
          },
          {
            "category": "09-01-2005",
            "value": 33.4019,
            "value2": 23.3727
          },
          {
            "category": "10-01-2005",
            "value": 33.4821,
            "value2": 23.1321
          },
          {
            "category": "11-01-2005",
            "value": 33.027,
            "value2": 22.704
          },
          {
            "category": "12-01-2005",
            "value": 32.147,
            "value2": 22.1117
          },
          {
            "category": "13-01-2005",
            "value": 32.9132,
            "value2": 22.6362
          },
          {
            "category": "14-01-2005",
            "value": 32.5824,
            "value2": 22.417
          },
          {
            "category": "15-01-2005",
            "value": 32.5824,
            "value2": 22.417
          },
          {
            "category": "16-01-2005",
            "value": 32.5824,
            "value2": 22.417
          },
          {
            "category": "17-01-2005",
            "value": 32.2901,
            "value2": 22.3556
          },
          {
            "category": "18-01-2005",
            "value": 32.7524,
            "value2": 22.414
          },
          {
            "category": "19-01-2005",
            "value": 32.6782,
            "value2": 22.2934
          },
          {
            "category": "20-01-2005",
            "value": 32.2034,
            "value2": 22.1779
          },
          {
            "category": "21-01-2005",
            "value": 32.2034,
            "value2": 22.1779
          },
          {
            "category": "22-01-2005",
            "value": 32.2034,
            "value2": 22.1779
          },
          {
            "category": "23-01-2005",
            "value": 32.2034,
            "value2": 22.1779
          },
          {
            "category": "24-01-2005",
            "value": 31.9082,
            "value2": 21.9992
          },
          {
            "category": "25-01-2005",
            "value": 32.2512,
            "value2": 22.2696
          },
          {
            "category": "26-01-2005",
            "value": 32.2512,
            "value2": 22.2696
          },
          {
            "category": "27-01-2005",
            "value": 32.7758,
            "value2": 22.5247
          },
          {
            "category": "28-01-2005",
            "value": 33.3115,
            "value2": 23.0611
          },
          {
            "category": "29-01-2005",
            "value": 33.3115,
            "value2": 23.0611
          },
          {
            "category": "30-01-2005",
            "value": 33.2826,
            "value2": 23.0611
          },
          {
            "category": "31-01-2005",
            "value": 33.8951,
            "value2": 23.5426
          },
          {
            "category": "01-02-2005",
            "value": 33.6219,
            "value2": 23.6632
          },
          {
            "category": "02-02-2005",
            "value": 33.6592,
            "value2": 23.6721
          },
          {
            "category": "03-02-2005",
            "value": 33.9846,
            "value2": 24.0226
          },
          {
            "category": "04-02-2005",
            "value": 34.1118,
            "value2": 23.9885
          },
          {
            "category": "05-02-2005",
            "value": 34.1118,
            "value2": 23.9885
          },
          {
            "category": "06-02-2005",
            "value": 34.1118,
            "value2": 23.9885
          },
          {
            "category": "07-02-2005",
            "value": 34.1301,
            "value2": 23.8695
          },
          {
            "category": "08-02-2005",
            "value": 34.1517,
            "value2": 23.9036
          },
          {
            "category": "09-02-2005",
            "value": 34.434,
            "value2": 24.1166
          },
          {
            "category": "10-02-2005",
            "value": 34.2569,
            "value2": 24.0952
          },
          {
            "category": "11-02-2005",
            "value": 34.4524,
            "value2": 24.2763
          },
          {
            "category": "12-02-2005",
            "value": 34.4524,
            "value2": 24.2763
          },
          {
            "category": "13-02-2005",
            "value": 34.4524,
            "value2": 24.2763
          },
          {
            "category": "14-02-2005",
            "value": 34.793,
            "value2": 24.4064
          },
          {
            "category": "15-02-2005",
            "value": 34.6805,
            "value2": 24.3052
          },
          {
            "category": "16-02-2005",
            "value": 34.5075,
            "value2": 24.1014
          },
          {
            "category": "17-02-2005",
            "value": 34.0924,
            "value2": 24.0146
          },
          {
            "category": "18-02-2005",
            "value": 34.2655,
            "value2": 24.0018
          },
          {
            "category": "19-02-2005",
            "value": 34.2655,
            "value2": 24.0018
          },
          {
            "category": "20-02-2005",
            "value": 34.2655,
            "value2": 24.0018
          },
          {
            "category": "21-02-2005",
            "value": 34.1691,
            "value2": 23.8303
          },
          {
            "category": "22-02-2005",
            "value": 34.1707,
            "value2": 23.9772
          },
          {
            "category": "23-02-2005",
            "value": 34.1689,
            "value2": 24.0383
          },
          {
            "category": "24-02-2005",
            "value": 34.3137,
            "value2": 24.0247
          },
          {
            "category": "25-02-2005",
            "value": 34.2418,
            "value2": 24.0168
          },
          {
            "category": "26-02-2005",
            "value": 34.2418,
            "value2": 24.0168
          },
          {
            "category": "27-02-2005",
            "value": 34.2418,
            "value2": 24.0168
          },
          {
            "category": "28-02-2005",
            "value": 34.9079,
            "value2": 24.3988
          },
          {
            "category": "01-03-2005",
            "value": 34.7327,
            "value2": 24.2045
          },
          {
            "category": "02-03-2005",
            "value": 35.0954,
            "value2": 24.3907
          },
          {
            "category": "03-03-2005",
            "value": 35.6784,
            "value2": 24.7705
          },
          {
            "category": "04-03-2005",
            "value": 36.2348,
            "value2": 24.9769
          },
          {
            "category": "05-03-2005",
            "value": 36.2348,
            "value2": 24.9769
          },
          {
            "category": "06-03-2005",
            "value": 36.2461,
            "value2": 24.9769
          },
          {
            "category": "07-03-2005",
            "value": 36.2889,
            "value2": 25.1009
          },
          {
            "category": "08-03-2005",
            "value": 36.1736,
            "value2": 25.2546
          },
          {
            "category": "09-03-2005",
            "value": 35.6234,
            "value2": 25.084
          },
          {
            "category": "10-03-2005",
            "value": 35.6774,
            "value2": 25.1983
          },
          {
            "category": "11-03-2005",
            "value": 35.64,
            "value2": 25.0999
          },
          {
            "category": "12-03-2005",
            "value": 35.64,
            "value2": 25.0999
          },
          {
            "category": "13-03-2005",
            "value": 35.64,
            "value2": 25.0999
          },
          {
            "category": "14-03-2005",
            "value": 35.6965,
            "value2": 24.9751
          },
          {
            "category": "15-03-2005",
            "value": 35.6587,
            "value2": 24.8124
          },
          {
            "category": "16-03-2005",
            "value": 35.6039,
            "value2": 24.8052
          },
          {
            "category": "17-03-2005",
            "value": 34.8488,
            "value2": 24.4984
          },
          {
            "category": "18-03-2005",
            "value": 34.7273,
            "value2": 24.4985
          },
          {
            "category": "19-03-2005",
            "value": 34.7273,
            "value2": 24.4985
          },
          {
            "category": "20-03-2005",
            "value": 34.7273,
            "value2": 24.4985
          },
          {
            "category": "21-03-2005",
            "value": 34.9885,
            "value2": 24.3664
          },
          {
            "category": "22-03-2005",
            "value": 34.5357,
            "value2": 23.8791
          },
          {
            "category": "23-03-2005",
            "value": 33.9713,
            "value2": 23.4349
          },
          {
            "category": "24-03-2005",
            "value": 33.8828,
            "value2": 23.2906
          },
          {
            "category": "25-03-2005",
            "value": 33.8814,
            "value2": 23.2906
          },
          {
            "category": "26-03-2005",
            "value": 33.8814,
            "value2": 23.2906
          },
          {
            "category": "27-03-2005",
            "value": 33.8814,
            "value2": 23.2906
          },
          {
            "category": "28-03-2005",
            "value": 33.8461,
            "value2": 23.4776
          },
          {
            "category": "29-03-2005",
            "value": 33.2775,
            "value2": 22.9674
          },
          {
            "category": "30-03-2005",
            "value": 33.7944,
            "value2": 23.1638
          },
          {
            "category": "31-03-2005",
            "value": 33.5926,
            "value2": 23.6131
          },
          {
            "category": "01-04-2005",
            "value": 34.054,
            "value2": 24.0625
          },
          {
            "category": "02-04-2005",
            "value": 34.0541,
            "value2": 24.0625
          },
          {
            "category": "03-04-2005",
            "value": 34.0541,
            "value2": 24.0625
          },
          {
            "category": "04-04-2005",
            "value": 33.9717,
            "value2": 24.0437
          },
          {
            "category": "05-04-2005",
            "value": 33.8443,
            "value2": 23.8739
          },
          {
            "category": "06-04-2005",
            "value": 33.8087,
            "value2": 24.0623
          },
          {
            "category": "07-04-2005",
            "value": 33.7739,
            "value2": 23.9484
          },
          {
            "category": "08-04-2005",
            "value": 33.5718,
            "value2": 23.7622
          },
          {
            "category": "09-04-2005",
            "value": 33.5718,
            "value2": 23.7622
          },
          {
            "category": "10-04-2005",
            "value": 33.6034,
            "value2": 23.7622
          },
          {
            "category": "11-04-2005",
            "value": 33.2431,
            "value2": 23.503
          },
          {
            "category": "12-04-2005",
            "value": 33.4931,
            "value2": 23.6977
          },
          {
            "category": "13-04-2005",
            "value": 33.262,
            "value2": 23.7049
          },
          {
            "category": "14-04-2005",
            "value": 33.262,
            "value2": 23.7049
          },
          {
            "category": "15-04-2005",
            "value": 32.8385,
            "value2": 23.01
          },
          {
            "category": "16-04-2005",
            "value": 32.8385,
            "value2": 23.01
          },
          {
            "category": "17-04-2005",
            "value": 32.8385,
            "value2": 23.01
          },
          {
            "category": "18-04-2005",
            "value": 32.5308,
            "value2": 22.7661
          },
          {
            "category": "19-04-2005",
            "value": 32.4356,
            "value2": 22.584
          },
          {
            "category": "20-04-2005",
            "value": 32.563,
            "value2": 22.7819
          },
          {
            "category": "21-04-2005",
            "value": 32.8161,
            "value2": 23.0396
          },
          {
            "category": "22-04-2005",
            "value": 32.9981,
            "value2": 23.2341
          },
          {
            "category": "23-04-2005",
            "value": 32.9981,
            "value2": 23.2341
          },
          {
            "category": "24-04-2005",
            "value": 32.9981,
            "value2": 23.2341
          },
          {
            "category": "25-04-2005",
            "value": 33.1528,
            "value2": 23.3329
          },
          {
            "category": "26-04-2005",
            "value": 33.1318,
            "value2": 23.2415
          },
          {
            "category": "27-04-2005",
            "value": 32.9146,
            "value2": 23.011
          },
          {
            "category": "28-04-2005",
            "value": 32.9203,
            "value2": 22.9096
          },
          {
            "category": "29-04-2005",
            "value": 32.4183,
            "value2": 22.541
          },
          {
            "category": "30-04-2005",
            "value": 32.4175,
            "value2": 22.541
          },
          {
            "category": "01-05-2005",
            "value": 32.4175,
            "value2": 22.541
          },
          {
            "category": "02-05-2005",
            "value": 32.3411,
            "value2": 22.6155
          },
          {
            "category": "03-05-2005",
            "value": 32.5267,
            "value2": 22.6631
          },
          {
            "category": "04-05-2005",
            "value": 32.8122,
            "value2": 22.9254
          },
          {
            "category": "05-05-2005",
            "value": 33.0744,
            "value2": 23.1795
          },
          {
            "category": "06-05-2005",
            "value": 33.131,
            "value2": 23.3181
          },
          {
            "category": "07-05-2005",
            "value": 33.131,
            "value2": 23.3181
          },
          {
            "category": "08-05-2005",
            "value": 33.131,
            "value2": 23.3181
          },
          {
            "category": "09-05-2005",
            "value": 33.6213,
            "value2": 23.5961
          },
          {
            "category": "10-05-2005",
            "value": 33.7457,
            "value2": 23.5467
          },
          {
            "category": "11-05-2005",
            "value": 33.75,
            "value2": 23.4821
          },
          {
            "category": "12-05-2005",
            "value": 33.8846,
            "value2": 23.5858
          },
          {
            "category": "13-05-2005",
            "value": 33.6696,
            "value2": 23.5799
          },
          {
            "category": "14-05-2005",
            "value": 33.6787,
            "value2": 23.5799
          },
          {
            "category": "15-05-2005",
            "value": 33.6787,
            "value2": 23.5799
          },
          {
            "category": "16-05-2005",
            "value": 33.9469,
            "value2": 23.8136
          },
          {
            "category": "17-05-2005",
            "value": 33.9142,
            "value2": 23.5561
          },
          {
            "category": "18-05-2005",
            "value": 33.9053,
            "value2": 23.5021
          },
          {
            "category": "19-05-2005",
            "value": 34.1275,
            "value2": 23.6258
          },
          {
            "category": "20-05-2005",
            "value": 34.2049,
            "value2": 23.638
          },
          {
            "category": "21-05-2005",
            "value": 34.2049,
            "value2": 23.638
          },
          {
            "category": "22-05-2005",
            "value": 34.2049,
            "value2": 23.638
          },
          {
            "category": "23-05-2005",
            "value": 34.3939,
            "value2": 23.8448
          },
          {
            "category": "24-05-2005",
            "value": 34.4428,
            "value2": 23.9577
          },
          {
            "category": "25-05-2005",
            "value": 34.6062,
            "value2": 24.0973
          },
          {
            "category": "26-05-2005",
            "value": 34.8471,
            "value2": 24.2506
          },
          {
            "category": "27-05-2005",
            "value": 34.7862,
            "value2": 24.2952
          },
          {
            "category": "28-05-2005",
            "value": 34.7817,
            "value2": 24.2952
          },
          {
            "category": "29-05-2005",
            "value": 34.7817,
            "value2": 24.2952
          },
          {
            "category": "30-05-2005",
            "value": 34.4105,
            "value2": 24.2192
          },
          {
            "category": "31-05-2005",
            "value": 34.5147,
            "value2": 24.4295
          },
          {
            "category": "01-06-2005",
            "value": 34.6218,
            "value2": 24.4706
          },
          {
            "category": "02-06-2005",
            "value": 34.5626,
            "value2": 24.254
          },
          {
            "category": "03-06-2005",
            "value": 34.6718,
            "value2": 24.532
          },
          {
            "category": "04-06-2005",
            "value": 34.6355,
            "value2": 24.5555
          },
          {
            "category": "05-06-2005",
            "value": 34.6355,
            "value2": 24.5555
          },
          {
            "category": "06-06-2005",
            "value": 34.7446,
            "value2": 24.6288
          },
          {
            "category": "07-06-2005",
            "value": 34.9025,
            "value2": 24.7217
          },
          {
            "category": "08-06-2005",
            "value": 35.0705,
            "value2": 24.8572
          },
          {
            "category": "09-06-2005",
            "value": 35.0751,
            "value2": 24.7975
          },
          {
            "category": "10-06-2005",
            "value": 34.8703,
            "value2": 24.6395
          },
          {
            "category": "11-06-2005",
            "value": 34.8703,
            "value2": 24.6395
          },
          {
            "category": "12-06-2005",
            "value": 34.87,
            "value2": 24.6395
          },
          {
            "category": "13-06-2005",
            "value": 34.9227,
            "value2": 24.7388
          },
          {
            "category": "14-06-2005",
            "value": 35.0276,
            "value2": 24.7898
          },
          {
            "category": "15-06-2005",
            "value": 35.2882,
            "value2": 24.9503
          },
          {
            "category": "16-06-2005",
            "value": 35.1262,
            "value2": 24.7829
          },
          {
            "category": "17-06-2005",
            "value": 34.9169,
            "value2": 24.6596
          },
          {
            "category": "18-06-2005",
            "value": 34.917,
            "value2": 24.6596
          },
          {
            "category": "19-06-2005",
            "value": 34.917,
            "value2": 24.6596
          },
          {
            "category": "20-06-2005",
            "value": 34.8421,
            "value2": 24.7535
          },
          {
            "category": "21-06-2005",
            "value": 34.9342,
            "value2": 24.9582
          },
          {
            "category": "22-06-2005",
            "value": 35.0714,
            "value2": 25.1159
          },
          {
            "category": "23-06-2005",
            "value": 35.2419,
            "value2": 25.1215
          },
          {
            "category": "24-06-2005",
            "value": 35.4505,
            "value2": 25.265
          },
          {
            "category": "25-06-2005",
            "value": 35.4506,
            "value2": 25.265
          },
          {
            "category": "26-06-2005",
            "value": 35.4506,
            "value2": 25.265
          },
          {
            "category": "27-06-2005",
            "value": 35.3527,
            "value2": 25.2751
          },
          {
            "category": "28-06-2005",
            "value": 35.0755,
            "value2": 24.9137
          },
          {
            "category": "29-06-2005",
            "value": 35.1855,
            "value2": 25.1452
          },
          {
            "category": "30-06-2005",
            "value": 35.3196,
            "value2": 25.2852
          },
          {
            "category": "01-07-2005",
            "value": 35.8558,
            "value2": 25.3967
          },
          {
            "category": "02-07-2005",
            "value": 35.8558,
            "value2": 25.3967
          },
          {
            "category": "03-07-2005",
            "value": 35.8558,
            "value2": 25.3967
          },
          {
            "category": "04-07-2005",
            "value": 36.1588,
            "value2": 25.667
          },
          {
            "category": "05-07-2005",
            "value": 35.9116,
            "value2": 25.4838
          },
          {
            "category": "06-07-2005",
            "value": 36.3056,
            "value2": 25.7719
          },
          {
            "category": "07-07-2005",
            "value": 36.0978,
            "value2": 25.3097
          },
          {
            "category": "08-07-2005",
            "value": 36.4369,
            "value2": 25.5945
          },
          {
            "category": "09-07-2005",
            "value": 36.4369,
            "value2": 25.5945
          },
          {
            "category": "10-07-2005",
            "value": 36.437,
            "value2": 25.5945
          },
          {
            "category": "11-07-2005",
            "value": 36.8177,
            "value2": 25.8678
          },
          {
            "category": "12-07-2005",
            "value": 37.0213,
            "value2": 25.9106
          },
          {
            "category": "13-07-2005",
            "value": 37.2155,
            "value2": 25.8459
          },
          {
            "category": "14-07-2005",
            "value": 37.3283,
            "value2": 25.7191
          },
          {
            "category": "15-07-2005",
            "value": 37.7619,
            "value2": 26.0056
          },
          {
            "category": "16-07-2005",
            "value": 37.7619,
            "value2": 26.0056
          },
          {
            "category": "17-07-2005",
            "value": 37.7619,
            "value2": 26.0056
          },
          {
            "category": "18-07-2005",
            "value": 38.448,
            "value2": 26.3085
          },
          {
            "category": "19-07-2005",
            "value": 38.2329,
            "value2": 26.3899
          },
          {
            "category": "20-07-2005",
            "value": 38.3873,
            "value2": 26.5134
          },
          {
            "category": "21-07-2005",
            "value": 38.0843,
            "value2": 26.3739
          },
          {
            "category": "22-07-2005",
            "value": 38.7332,
            "value2": 26.7272
          },
          {
            "category": "23-07-2005",
            "value": 38.7332,
            "value2": 26.7272
          },
          {
            "category": "24-07-2005",
            "value": 38.7332,
            "value2": 26.7272
          },
          {
            "category": "25-07-2005",
            "value": 38.913,
            "value2": 26.9735
          },
          {
            "category": "26-07-2005",
            "value": 39.0058,
            "value2": 26.9863
          },
          {
            "category": "27-07-2005",
            "value": 39.286,
            "value2": 27.1083
          },
          {
            "category": "28-07-2005",
            "value": 39.2861,
            "value2": 27.1083
          },
          {
            "category": "29-07-2005",
            "value": 39.7794,
            "value2": 26.9817
          },
          {
            "category": "30-07-2005",
            "value": 39.7783,
            "value2": 26.9817
          },
          {
            "category": "31-07-2005",
            "value": 39.7783,
            "value2": 26.9817
          },
          {
            "category": "01-08-2005",
            "value": 39.6974,
            "value2": 27.1418
          },
          {
            "category": "02-08-2005",
            "value": 40.1166,
            "value2": 27.5837
          },
          {
            "category": "03-08-2005",
            "value": 40.0583,
            "value2": 27.5476
          },
          {
            "category": "04-08-2005",
            "value": 40.5065,
            "value2": 27.712
          },
          {
            "category": "05-08-2005",
            "value": 40.6368,
            "value2": 27.7602
          },
          {
            "category": "06-08-2005",
            "value": 40.6368,
            "value2": 27.7602
          },
          {
            "category": "07-08-2005",
            "value": 40.6368,
            "value2": 27.7602
          },
          {
            "category": "08-08-2005",
            "value": 40.4416,
            "value2": 27.3567
          },
          {
            "category": "09-08-2005",
            "value": 40.5087,
            "value2": 27.2996
          },
          {
            "category": "10-08-2005",
            "value": 41.7669,
            "value2": 27.776
          },
          {
            "category": "11-08-2005",
            "value": 42.4511,
            "value2": 27.9991
          },
          {
            "category": "12-08-2005",
            "value": 42.9115,
            "value2": 27.899
          },
          {
            "category": "13-08-2005",
            "value": 42.9115,
            "value2": 27.899
          },
          {
            "category": "14-08-2005",
            "value": 42.9115,
            "value2": 27.899
          },
          {
            "category": "15-08-2005",
            "value": 42.9115,
            "value2": 27.899
          },
          {
            "category": "16-08-2005",
            "value": 42.9189,
            "value2": 27.9882
          },
          {
            "category": "17-08-2005",
            "value": 43.1673,
            "value2": 28.2902
          },
          {
            "category": "18-08-2005",
            "value": 42.8208,
            "value2": 28.1402
          },
          {
            "category": "19-08-2005",
            "value": 42.9993,
            "value2": 28.0847
          },
          {
            "category": "20-08-2005",
            "value": 42.9993,
            "value2": 28.0847
          },
          {
            "category": "21-08-2005",
            "value": 42.9993,
            "value2": 28.0847
          },
          {
            "category": "22-08-2005",
            "value": 42.9463,
            "value2": 27.975
          },
          {
            "category": "23-08-2005",
            "value": 42.2803,
            "value2": 27.4362
          },
          {
            "category": "24-08-2005",
            "value": 41.9014,
            "value2": 27.3047
          },
          {
            "category": "25-08-2005",
            "value": 42.5112,
            "value2": 27.5453
          },
          {
            "category": "26-08-2005",
            "value": 42.6176,
            "value2": 27.75
          },
          {
            "category": "27-08-2005",
            "value": 42.6176,
            "value2": 27.75
          },
          {
            "category": "28-08-2005",
            "value": 42.6176,
            "value2": 27.75
          },
          {
            "category": "29-08-2005",
            "value": 42.5647,
            "value2": 27.6349
          },
          {
            "category": "30-08-2005",
            "value": 43.0366,
            "value2": 28.0052
          },
          {
            "category": "31-08-2005",
            "value": 43.519,
            "value2": 28.2616
          },
          {
            "category": "01-09-2005",
            "value": 43.8791,
            "value2": 28.4819
          },
          {
            "category": "02-09-2005",
            "value": 44.1331,
            "value2": 28.5659
          },
          {
            "category": "03-09-2005",
            "value": 44.1332,
            "value2": 28.5659
          },
          {
            "category": "04-09-2005",
            "value": 44.1335,
            "value2": 28.5659
          },
          {
            "category": "05-09-2005",
            "value": 44.3966,
            "value2": 28.6652
          },
          {
            "category": "06-09-2005",
            "value": 44.4841,
            "value2": 28.7428
          },
          {
            "category": "07-09-2005",
            "value": 44.4841,
            "value2": 28.7428
          },
          {
            "category": "08-09-2005",
            "value": 44.6728,
            "value2": 29.0569
          },
          {
            "category": "09-09-2005",
            "value": 44.7268,
            "value2": 29.0719
          },
          {
            "category": "10-09-2005",
            "value": 44.7268,
            "value2": 29.0719
          },
          {
            "category": "11-09-2005",
            "value": 44.7268,
            "value2": 29.0719
          },
          {
            "category": "12-09-2005",
            "value": 45.12,
            "value2": 29.3798
          },
          {
            "category": "13-09-2005",
            "value": 45.7839,
            "value2": 29.6046
          },
          {
            "category": "14-09-2005",
            "value": 45.7158,
            "value2": 29.5416
          },
          {
            "category": "15-09-2005",
            "value": 46.0101,
            "value2": 29.8959
          },
          {
            "category": "16-09-2005",
            "value": 46.5896,
            "value2": 30.163
          },
          {
            "category": "17-09-2005",
            "value": 46.5896,
            "value2": 30.163
          },
          {
            "category": "18-09-2005",
            "value": 46.5896,
            "value2": 30.163
          },
          {
            "category": "19-09-2005",
            "value": 46.7891,
            "value2": 30.4228
          },
          {
            "category": "20-09-2005",
            "value": 46.5911,
            "value2": 30.4924
          },
          {
            "category": "21-09-2005",
            "value": 46.0488,
            "value2": 30.1769
          },
          {
            "category": "22-09-2005",
            "value": 44.6365,
            "value2": 28.9792
          },
          {
            "category": "23-09-2005",
            "value": 44.7918,
            "value2": 29.0892
          },
          {
            "category": "24-09-2005",
            "value": 44.7918,
            "value2": 29.0892
          },
          {
            "category": "25-09-2005",
            "value": 44.7918,
            "value2": 29.0892
          },
          {
            "category": "26-09-2005",
            "value": 45.9518,
            "value2": 30.0153
          },
          {
            "category": "27-09-2005",
            "value": 46.2767,
            "value2": 30.1936
          },
          {
            "category": "28-09-2005",
            "value": 46.448,
            "value2": 30.4305
          },
          {
            "category": "29-09-2005",
            "value": 47.2924,
            "value2": 30.4447
          },
          {
            "category": "30-09-2005",
            "value": 47.0827,
            "value2": 30.4102
          },
          {
            "category": "01-10-2005",
            "value": 47.0374,
            "value2": 30.4102
          },
          {
            "category": "02-10-2005",
            "value": 47.0374,
            "value2": 30.4102
          },
          {
            "category": "03-10-2005",
            "value": 47.4741,
            "value2": 30.7265
          },
          {
            "category": "04-10-2005",
            "value": 47.9629,
            "value2": 31.0887
          },
          {
            "category": "05-10-2005",
            "value": 47.8556,
            "value2": 30.8617
          },
          {
            "category": "06-10-2005",
            "value": 47.2598,
            "value2": 30.2664
          },
          {
            "category": "07-10-2005",
            "value": 47.0623,
            "value2": 30.1334
          },
          {
            "category": "08-10-2005",
            "value": 47.0623,
            "value2": 30.1334
          },
          {
            "category": "09-10-2005",
            "value": 47.0623,
            "value2": 30.1334
          },
          {
            "category": "10-10-2005",
            "value": 47.0559,
            "value2": 30.0198
          },
          {
            "category": "11-10-2005",
            "value": 47.0218,
            "value2": 30.1254
          },
          {
            "category": "12-10-2005",
            "value": 47.0218,
            "value2": 30.1254
          },
          {
            "category": "13-10-2005",
            "value": 46.3099,
            "value2": 29.6455
          },
          {
            "category": "14-10-2005",
            "value": 45.2713,
            "value2": 29.0263
          },
          {
            "category": "15-10-2005",
            "value": 45.2713,
            "value2": 29.0263
          },
          {
            "category": "16-10-2005",
            "value": 45.2713,
            "value2": 29.0263
          },
          {
            "category": "17-10-2005",
            "value": 45.2039,
            "value2": 28.9291
          },
          {
            "category": "18-10-2005",
            "value": 45.3129,
            "value2": 28.7264
          },
          {
            "category": "19-10-2005",
            "value": 44.1278,
            "value2": 28.0837
          },
          {
            "category": "20-10-2005",
            "value": 43.9594,
            "value2": 27.7921
          },
          {
            "category": "21-10-2005",
            "value": 45.0392,
            "value2": 28.2237
          },
          {
            "category": "22-10-2005",
            "value": 45.0392,
            "value2": 28.2237
          },
          {
            "category": "23-10-2005",
            "value": 45.0392,
            "value2": 28.2237
          },
          {
            "category": "24-10-2005",
            "value": 44.5041,
            "value2": 27.8496
          },
          {
            "category": "25-10-2005",
            "value": 44.9707,
            "value2": 28.046
          },
          {
            "category": "26-10-2005",
            "value": 45.1012,
            "value2": 27.9663
          },
          {
            "category": "27-10-2005",
            "value": 44.429,
            "value2": 27.3994
          },
          {
            "category": "28-10-2005",
            "value": 43.7892,
            "value2": 26.9556
          },
          {
            "category": "29-10-2005",
            "value": 43.7892,
            "value2": 26.9556
          },
          {
            "category": "30-10-2005",
            "value": 43.7893,
            "value2": 26.9556
          },
          {
            "category": "31-10-2005",
            "value": 44.3169,
            "value2": 27.6199
          },
          {
            "category": "01-11-2005",
            "value": 44.5824,
            "value2": 27.8668
          },
          {
            "category": "02-11-2005",
            "value": 44.9809,
            "value2": 28.194
          },
          {
            "category": "03-11-2005",
            "value": 44.981,
            "value2": 28.194
          },
          {
            "category": "04-11-2005",
            "value": 44.981,
            "value2": 28.194
          },
          {
            "category": "05-11-2005",
            "value": 44.981,
            "value2": 28.194
          },
          {
            "category": "06-11-2005",
            "value": 44.981,
            "value2": 28.194
          },
          {
            "category": "07-11-2005",
            "value": 45.6891,
            "value2": 28.6653
          },
          {
            "category": "08-11-2005",
            "value": 46.1953,
            "value2": 29.0436
          },
          {
            "category": "09-11-2005",
            "value": 46.1643,
            "value2": 29.0108
          },
          {
            "category": "10-11-2005",
            "value": 46.201,
            "value2": 29.0917
          },
          {
            "category": "11-11-2005",
            "value": 46.9186,
            "value2": 29.6362
          },
          {
            "category": "12-11-2005",
            "value": 46.9186,
            "value2": 29.6362
          },
          {
            "category": "13-11-2005",
            "value": 46.9186,
            "value2": 29.6362
          },
          {
            "category": "14-11-2005",
            "value": 46.8023,
            "value2": 29.7214
          },
          {
            "category": "15-11-2005",
            "value": 46.8023,
            "value2": 29.7214
          },
          {
            "category": "16-11-2005",
            "value": 47.2751,
            "value2": 30.0212
          },
          {
            "category": "17-11-2005",
            "value": 47.5989,
            "value2": 30.1942
          },
          {
            "category": "18-11-2005",
            "value": 47.722,
            "value2": 30.309
          },
          {
            "category": "19-11-2005",
            "value": 47.722,
            "value2": 30.309
          },
          {
            "category": "20-11-2005",
            "value": 47.722,
            "value2": 30.309
          },
          {
            "category": "21-11-2005",
            "value": 47.7229,
            "value2": 30.0714
          },
          {
            "category": "22-11-2005",
            "value": 47.68,
            "value2": 29.8323
          },
          {
            "category": "23-11-2005",
            "value": 47.965,
            "value2": 30.1038
          },
          {
            "category": "24-11-2005",
            "value": 48.275,
            "value2": 30.4121
          },
          {
            "category": "25-11-2005",
            "value": 49.1684,
            "value2": 30.7897
          },
          {
            "category": "26-11-2005",
            "value": 49.6846,
            "value2": 31.0114
          },
          {
            "category": "27-11-2005",
            "value": 49.6846,
            "value2": 31.0114
          },
          {
            "category": "28-11-2005",
            "value": 50.0793,
            "value2": 31.3406
          },
          {
            "category": "29-11-2005",
            "value": 49.4986,
            "value2": 31.1659
          },
          {
            "category": "30-11-2005",
            "value": 48.8688,
            "value2": 30.812
          },
          {
            "category": "01-12-2005",
            "value": 49.5869,
            "value2": 31.3095
          },
          {
            "category": "02-12-2005",
            "value": 49.6922,
            "value2": 31.3835
          },
          {
            "category": "03-12-2005",
            "value": 49.6922,
            "value2": 31.3835
          },
          {
            "category": "04-12-2005",
            "value": 49.6922,
            "value2": 31.3835
          },
          {
            "category": "05-12-2005",
            "value": 48.8538,
            "value2": 31.0475
          },
          {
            "category": "06-12-2005",
            "value": 48.9295,
            "value2": 30.9883
          },
          {
            "category": "07-12-2005",
            "value": 49.1237,
            "value2": 31.2222
          },
          {
            "category": "08-12-2005",
            "value": 49.201,
            "value2": 31.3291
          },
          {
            "category": "09-12-2005",
            "value": 49.7644,
            "value2": 31.7941
          },
          {
            "category": "10-12-2005",
            "value": 49.7644,
            "value2": 31.7941
          },
          {
            "category": "11-12-2005",
            "value": 49.7644,
            "value2": 31.7941
          },
          {
            "category": "12-12-2005",
            "value": 49.9066,
            "value2": 31.9822
          },
          {
            "category": "13-12-2005",
            "value": 50.1809,
            "value2": 32.3422
          },
          {
            "category": "14-12-2005",
            "value": 49.8676,
            "value2": 32.3421
          },
          {
            "category": "15-12-2005",
            "value": 49.4308,
            "value2": 32.0397
          },
          {
            "category": "16-12-2005",
            "value": 49.7564,
            "value2": 32.3865
          },
          {
            "category": "17-12-2005",
            "value": 49.7564,
            "value2": 32.3865
          },
          {
            "category": "18-12-2005",
            "value": 49.7564,
            "value2": 32.3865
          },
          {
            "category": "19-12-2005",
            "value": 50.4201,
            "value2": 32.7442
          },
          {
            "category": "20-12-2005",
            "value": 50.236,
            "value2": 32.5915
          },
          {
            "category": "21-12-2005",
            "value": 50.2224,
            "value2": 32.565
          },
          {
            "category": "22-12-2005",
            "value": 50.3884,
            "value2": 32.6443
          },
          {
            "category": "23-12-2005",
            "value": 49.9115,
            "value2": 32.2759
          },
          {
            "category": "24-12-2005",
            "value": 49.9115,
            "value2": 32.2759
          },
          {
            "category": "25-12-2005",
            "value": 49.9115,
            "value2": 32.2759
          },
          {
            "category": "26-12-2005",
            "value": 49.0027,
            "value2": 31.6129
          },
          {
            "category": "27-12-2005",
            "value": 50.0922,
            "value2": 32.2994
          },
          {
            "category": "28-12-2005",
            "value": 50.0901,
            "value2": 32.2583
          },
          {
            "category": "29-12-2005",
            "value": 50.3594,
            "value2": 32.4546
          },
          {
            "category": "30-12-2005",
            "value": 50.9402,
            "value2": 32.7772
          },
          {
            "category": "31-12-2005",
            "value": 50.8048,
            "value2": 32.7772
          },
          {
            "category": "01-01-2006",
            "value": 50.8048,
            "value2": 32.7772
          },
          {
            "category": "02-01-2006",
            "value": 50.5477,
            "value2": 32.8537
          },
          {
            "category": "03-01-2006",
            "value": 51.4053,
            "value2": 33.356
          },
          {
            "category": "04-01-2006",
            "value": 51.8972,
            "value2": 33.678
          },
          {
            "category": "05-01-2006",
            "value": 52.0982,
            "value2": 33.6978
          },
          {
            "category": "06-01-2006",
            "value": 52.3687,
            "value2": 33.8299
          },
          {
            "category": "07-01-2006",
            "value": 52.3687,
            "value2": 33.8299
          },
          {
            "category": "08-01-2006",
            "value": 52.3687,
            "value2": 33.8299
          },
          {
            "category": "09-01-2006",
            "value": 52.4267,
            "value2": 33.804
          },
          {
            "category": "10-01-2006",
            "value": 51.6495,
            "value2": 33.3973
          },
          {
            "category": "11-01-2006",
            "value": 51.6495,
            "value2": 33.3973
          },
          {
            "category": "12-01-2006",
            "value": 51.6689,
            "value2": 33.2219
          },
          {
            "category": "13-01-2006",
            "value": 51.6173,
            "value2": 33.2373
          },
          {
            "category": "14-01-2006",
            "value": 51.6173,
            "value2": 33.2373
          },
          {
            "category": "15-01-2006",
            "value": 51.6173,
            "value2": 33.2373
          },
          {
            "category": "16-01-2006",
            "value": 51.52,
            "value2": 33.0156
          },
          {
            "category": "17-01-2006",
            "value": 51.35,
            "value2": 32.882
          },
          {
            "category": "18-01-2006",
            "value": 51.04,
            "value2": 32.6491
          },
          {
            "category": "19-01-2006",
            "value": 52.1344,
            "value2": 33.3223
          },
          {
            "category": "20-01-2006",
            "value": 52.2859,
            "value2": 33.6098
          },
          {
            "category": "21-01-2006",
            "value": 52.2859,
            "value2": 33.6098
          },
          {
            "category": "22-01-2006",
            "value": 52.2859,
            "value2": 33.6098
          },
          {
            "category": "23-01-2006",
            "value": 51.9963,
            "value2": 33.4633
          },
          {
            "category": "24-01-2006",
            "value": 52.4088,
            "value2": 33.6923
          },
          {
            "category": "25-01-2006",
            "value": 52.7384,
            "value2": 33.9484
          },
          {
            "category": "26-01-2006",
            "value": 52.7384,
            "value2": 33.9484
          },
          {
            "category": "27-01-2006",
            "value": 53.7419,
            "value2": 34.5851
          },
          {
            "category": "28-01-2006",
            "value": 53.7419,
            "value2": 34.5851
          },
          {
            "category": "29-01-2006",
            "value": 53.7419,
            "value2": 34.5851
          },
          {
            "category": "30-01-2006",
            "value": 53.2236,
            "value2": 34.3851
          },
          {
            "category": "31-01-2006",
            "value": 53.3269,
            "value2": 34.5819
          },
          {
            "category": "01-02-2006",
            "value": 53.0285,
            "value2": 34.2715
          },
          {
            "category": "02-02-2006",
            "value": 53.0648,
            "value2": 34.2654
          },
          {
            "category": "03-02-2006",
            "value": 53.0096,
            "value2": 34.0842
          },
          {
            "category": "04-02-2006",
            "value": 53.0096,
            "value2": 34.0842
          },
          {
            "category": "05-02-2006",
            "value": 53.0096,
            "value2": 34.0842
          },
          {
            "category": "06-02-2006",
            "value": 53.6701,
            "value2": 34.7063
          },
          {
            "category": "07-02-2006",
            "value": 53.7824,
            "value2": 35.0087
          },
          {
            "category": "08-02-2006",
            "value": 53.7715,
            "value2": 34.9095
          },
          {
            "category": "09-02-2006",
            "value": 53.7715,
            "value2": 34.9095
          },
          {
            "category": "10-02-2006",
            "value": 54.4145,
            "value2": 35.1963
          },
          {
            "category": "11-02-2006",
            "value": 54.4145,
            "value2": 35.1963
          },
          {
            "category": "12-02-2006",
            "value": 54.4145,
            "value2": 35.1963
          },
          {
            "category": "13-02-2006",
            "value": 55.0915,
            "value2": 35.4647
          },
          {
            "category": "14-02-2006",
            "value": 54.4975,
            "value2": 35.2294
          },
          {
            "category": "15-02-2006",
            "value": 54.0508,
            "value2": 35.2476
          },
          {
            "category": "16-02-2006",
            "value": 54.0349,
            "value2": 35.3165
          },
          {
            "category": "17-02-2006",
            "value": 53.1235,
            "value2": 34.8031
          },
          {
            "category": "18-02-2006",
            "value": 53.1236,
            "value2": 34.8031
          },
          {
            "category": "19-02-2006",
            "value": 53.1236,
            "value2": 34.8031
          },
          {
            "category": "20-02-2006",
            "value": 52.5173,
            "value2": 34.859
          },
          {
            "category": "21-02-2006",
            "value": 53.6383,
            "value2": 35.136
          },
          {
            "category": "22-02-2006",
            "value": 53.4233,
            "value2": 35.2437
          },
          {
            "category": "23-02-2006",
            "value": 53.2839,
            "value2": 35.2383
          },
          {
            "category": "24-02-2006",
            "value": 53.4344,
            "value2": 35.2576
          },
          {
            "category": "25-02-2006",
            "value": 53.4341,
            "value2": 35.2576
          },
          {
            "category": "26-02-2006",
            "value": 53.4341,
            "value2": 35.2576
          },
          {
            "category": "27-02-2006",
            "value": 53.612,
            "value2": 35.516
          },
          {
            "category": "28-02-2006",
            "value": 53.699,
            "value2": 35.6622
          },
          {
            "category": "01-03-2006",
            "value": 55.0212,
            "value2": 36.2868
          },
          {
            "category": "02-03-2006",
            "value": 55.5094,
            "value2": 36.5229
          },
          {
            "category": "03-03-2006",
            "value": 55.3547,
            "value2": 36.5486
          },
          {
            "category": "04-03-2006",
            "value": 55.3547,
            "value2": 36.5486
          },
          {
            "category": "05-03-2006",
            "value": 55.3547,
            "value2": 36.5486
          },
          {
            "category": "06-03-2006",
            "value": 55.6375,
            "value2": 37.0149
          },
          {
            "category": "07-03-2006",
            "value": 55.9664,
            "value2": 37.0896
          },
          {
            "category": "08-03-2006",
            "value": 55.4971,
            "value2": 36.4191
          },
          {
            "category": "09-03-2006",
            "value": 55.9395,
            "value2": 36.6033
          },
          {
            "category": "10-03-2006",
            "value": 56.5423,
            "value2": 37.1969
          },
          {
            "category": "11-03-2006",
            "value": 56.5379,
            "value2": 37.1969
          },
          {
            "category": "12-03-2006",
            "value": 56.5379,
            "value2": 37.1969
          },
          {
            "category": "13-03-2006",
            "value": 56.5941,
            "value2": 37.3268
          },
          {
            "category": "14-03-2006",
            "value": 56.2189,
            "value2": 37.232
          },
          {
            "category": "15-03-2006",
            "value": 56.2189,
            "value2": 37.232
          },
          {
            "category": "16-03-2006",
            "value": 56.352,
            "value2": 37.4851
          },
          {
            "category": "17-03-2006",
            "value": 56.1119,
            "value2": 37.4294
          },
          {
            "category": "18-03-2006",
            "value": 56.1119,
            "value2": 37.4294
          },
          {
            "category": "19-03-2006",
            "value": 56.1118,
            "value2": 37.4294
          },
          {
            "category": "20-03-2006",
            "value": 56.3049,
            "value2": 37.7161
          },
          {
            "category": "21-03-2006",
            "value": 56.3482,
            "value2": 37.6087
          },
          {
            "category": "22-03-2006",
            "value": 55.7023,
            "value2": 37.3719
          },
          {
            "category": "23-03-2006",
            "value": 55.7932,
            "value2": 37.4433
          },
          {
            "category": "24-03-2006",
            "value": 56.1527,
            "value2": 37.8118
          },
          {
            "category": "25-03-2006",
            "value": 56.1416,
            "value2": 37.8118
          },
          {
            "category": "26-03-2006",
            "value": 56.1416,
            "value2": 37.8118
          },
          {
            "category": "27-03-2006",
            "value": 56.6297,
            "value2": 38.2116
          },
          {
            "category": "28-03-2006",
            "value": 56.5825,
            "value2": 38.1284
          },
          {
            "category": "29-03-2006",
            "value": 57.0669,
            "value2": 38.4533
          },
          {
            "category": "30-03-2006",
            "value": 57.5268,
            "value2": 38.8192
          },
          {
            "category": "31-03-2006",
            "value": 56.1864,
            "value2": 39.0009
          },
          {
            "category": "01-04-2006",
            "value": 56.1795,
            "value2": 39.0009
          },
          {
            "category": "02-04-2006",
            "value": 56.1795,
            "value2": 39.0009
          },
          {
            "category": "03-04-2006",
            "value": 57.9471,
            "value2": 39.9472
          },
          {
            "category": "04-04-2006",
            "value": 58.0574,
            "value2": 40.2336
          },
          {
            "category": "05-04-2006",
            "value": 58.9194,
            "value2": 40.6866
          },
          {
            "category": "06-04-2006",
            "value": 58.9194,
            "value2": 40.6866
          },
          {
            "category": "07-04-2006",
            "value": 58.298,
            "value2": 40.1249
          },
          {
            "category": "08-04-2006",
            "value": 58.298,
            "value2": 40.1249
          },
          {
            "category": "09-04-2006",
            "value": 58.298,
            "value2": 40.1249
          },
          {
            "category": "10-04-2006",
            "value": 58.1306,
            "value2": 40.3899
          },
          {
            "category": "11-04-2006",
            "value": 58.1306,
            "value2": 40.3899
          },
          {
            "category": "12-04-2006",
            "value": 56.3285,
            "value2": 39.4766
          },
          {
            "category": "13-04-2006",
            "value": 55.1206,
            "value2": 38.8682
          },
          {
            "category": "14-04-2006",
            "value": 55.1206,
            "value2": 38.8682
          },
          {
            "category": "15-04-2006",
            "value": 55.1206,
            "value2": 38.8682
          },
          {
            "category": "16-04-2006",
            "value": 55.1206,
            "value2": 38.8682
          },
          {
            "category": "17-04-2006",
            "value": 56.5386,
            "value2": 39.8089
          },
          {
            "category": "18-04-2006",
            "value": 57.6486,
            "value2": 40.5555
          },
          {
            "category": "19-04-2006",
            "value": 57.542,
            "value2": 40.723
          },
          {
            "category": "20-04-2006",
            "value": 57.7665,
            "value2": 41.171
          },
          {
            "category": "21-04-2006",
            "value": 57.7312,
            "value2": 41.1688
          },
          {
            "category": "22-04-2006",
            "value": 57.7307,
            "value2": 41.1688
          },
          {
            "category": "23-04-2006",
            "value": 57.7307,
            "value2": 41.1688
          },
          {
            "category": "24-04-2006",
            "value": 57.4045,
            "value2": 41.0204
          },
          {
            "category": "25-04-2006",
            "value": 56.1903,
            "value2": 40.233
          },
          {
            "category": "26-04-2006",
            "value": 57.304,
            "value2": 41.0907
          },
          {
            "category": "27-04-2006",
            "value": 56.68,
            "value2": 40.8639
          },
          {
            "category": "28-04-2006",
            "value": 56.6209,
            "value2": 40.907
          },
          {
            "category": "29-04-2006",
            "value": 58.1423,
            "value2": 41.7036
          },
          {
            "category": "30-04-2006",
            "value": 58.142,
            "value2": 41.7036
          },
          {
            "category": "01-05-2006",
            "value": 58.1417,
            "value2": 41.7036
          },
          {
            "category": "02-05-2006",
            "value": 58.1984,
            "value2": 42.2761
          },
          {
            "category": "03-05-2006",
            "value": 58.4121,
            "value2": 42.6105
          },
          {
            "category": "04-05-2006",
            "value": 58.3381,
            "value2": 42.7168
          },
          {
            "category": "05-05-2006",
            "value": 58.5887,
            "value2": 42.8231
          },
          {
            "category": "06-05-2006",
            "value": 58.5887,
            "value2": 42.8231
          },
          {
            "category": "07-05-2006",
            "value": 58.5887,
            "value2": 42.8231
          },
          {
            "category": "08-05-2006",
            "value": 58.9747,
            "value2": 43.11
          },
          {
            "category": "09-05-2006",
            "value": 59.2152,
            "value2": 43.2815
          },
          {
            "category": "10-05-2006",
            "value": 59.5715,
            "value2": 43.6011
          },
          {
            "category": "11-05-2006",
            "value": 58.9491,
            "value2": 43.0372
          },
          {
            "category": "12-05-2006",
            "value": 58.2943,
            "value2": 42.5674
          },
          {
            "category": "13-05-2006",
            "value": 58.2943,
            "value2": 42.5674
          },
          {
            "category": "14-05-2006",
            "value": 58.2943,
            "value2": 42.5674
          },
          {
            "category": "15-05-2006",
            "value": 56.1093,
            "value2": 40.9039
          },
          {
            "category": "16-05-2006",
            "value": 55.7891,
            "value2": 40.8462
          },
          {
            "category": "17-05-2006",
            "value": 57.2782,
            "value2": 42.0641
          },
          {
            "category": "18-05-2006",
            "value": 53.9704,
            "value2": 39.1724
          },
          {
            "category": "19-05-2006",
            "value": 51.8875,
            "value2": 37.5742
          },
          {
            "category": "20-05-2006",
            "value": 51.8875,
            "value2": 37.5742
          },
          {
            "category": "21-05-2006",
            "value": 51.8875,
            "value2": 37.5742
          },
          {
            "category": "22-05-2006",
            "value": 49.4852,
            "value2": 35.7298
          },
          {
            "category": "23-05-2006",
            "value": 50.7437,
            "value2": 36.959
          },
          {
            "category": "24-05-2006",
            "value": 50.0373,
            "value2": 36.2895
          },
          {
            "category": "25-05-2006",
            "value": 50.1381,
            "value2": 36.4289
          },
          {
            "category": "26-05-2006",
            "value": 50.9806,
            "value2": 37.1283
          },
          {
            "category": "27-05-2006",
            "value": 50.9806,
            "value2": 37.1283
          },
          {
            "category": "28-05-2006",
            "value": 50.9806,
            "value2": 37.1283
          },
          {
            "category": "29-05-2006",
            "value": 51.2346,
            "value2": 37.3183
          },
          {
            "category": "30-05-2006",
            "value": 50.8549,
            "value2": 37.1079
          },
          {
            "category": "31-05-2006",
            "value": 49.4281,
            "value2": 35.9027
          },
          {
            "category": "01-06-2006",
            "value": 47.4913,
            "value2": 34.7574
          },
          {
            "category": "02-06-2006",
            "value": 48.3326,
            "value2": 35.5714
          },
          {
            "category": "03-06-2006",
            "value": 48.3326,
            "value2": 35.5714
          },
          {
            "category": "04-06-2006",
            "value": 48.3326,
            "value2": 35.5714
          },
          {
            "category": "05-06-2006",
            "value": 47.5686,
            "value2": 34.8458
          },
          {
            "category": "06-06-2006",
            "value": 46.5529,
            "value2": 33.8615
          },
          {
            "category": "07-06-2006",
            "value": 44.9942,
            "value2": 32.6093
          },
          {
            "category": "08-06-2006",
            "value": 42.5768,
            "value2": 30.6534
          },
          {
            "category": "09-06-2006",
            "value": 44.8939,
            "value2": 32.493
          },
          {
            "category": "10-06-2006",
            "value": 44.8939,
            "value2": 32.493
          },
          {
            "category": "11-06-2006",
            "value": 44.8939,
            "value2": 32.493
          },
          {
            "category": "12-06-2006",
            "value": 43.754,
            "value2": 31.5456
          },
          {
            "category": "13-06-2006",
            "value": 41.4717,
            "value2": 29.9512
          },
          {
            "category": "14-06-2006",
            "value": 40.8034,
            "value2": 29.3939
          },
          {
            "category": "15-06-2006",
            "value": 43.5875,
            "value2": 31.2994
          },
          {
            "category": "16-06-2006",
            "value": 44.9261,
            "value2": 32.4771
          },
          {
            "category": "17-06-2006",
            "value": 44.926,
            "value2": 32.4771
          },
          {
            "category": "18-06-2006",
            "value": 44.926,
            "value2": 32.4771
          },
          {
            "category": "19-06-2006",
            "value": 45.2233,
            "value2": 32.9255
          },
          {
            "category": "20-06-2006",
            "value": 44.9682,
            "value2": 32.5627
          },
          {
            "category": "21-06-2006",
            "value": 46.0611,
            "value2": 33.3034
          },
          {
            "category": "22-06-2006",
            "value": 47.2926,
            "value2": 34.1028
          },
          {
            "category": "23-06-2006",
            "value": 47.3629,
            "value2": 34.3906
          },
          {
            "category": "24-06-2006",
            "value": 47.3629,
            "value2": 34.3906
          },
          {
            "category": "25-06-2006",
            "value": 47.6172,
            "value2": 34.5193
          },
          {
            "category": "26-06-2006",
            "value": 45.559,
            "value2": 33.1949
          },
          {
            "category": "27-06-2006",
            "value": 45.5733,
            "value2": 33.4019
          },
          {
            "category": "28-06-2006",
            "value": 45.4584,
            "value2": 33.304
          },
          {
            "category": "29-06-2006",
            "value": 45.8685,
            "value2": 33.4349
          },
          {
            "category": "30-06-2006",
            "value": 47.1486,
            "value2": 34.7978
          },
          {
            "category": "01-07-2006",
            "value": 47.1432,
            "value2": 34.7978
          },
          {
            "category": "02-07-2006",
            "value": 47.1432,
            "value2": 34.7978
          },
          {
            "category": "03-07-2006",
            "value": 47.3351,
            "value2": 34.9855
          },
          {
            "category": "04-07-2006",
            "value": 47.1905,
            "value2": 34.9117
          },
          {
            "category": "05-07-2006",
            "value": 48.0269,
            "value2": 35.5895
          },
          {
            "category": "06-07-2006",
            "value": 47.7206,
            "value2": 35.23
          },
          {
            "category": "07-07-2006",
            "value": 46.8105,
            "value2": 34.4267
          },
          {
            "category": "08-07-2006",
            "value": 46.8105,
            "value2": 34.4267
          },
          {
            "category": "09-07-2006",
            "value": 46.8105,
            "value2": 34.4267
          },
          {
            "category": "10-07-2006",
            "value": 47.6734,
            "value2": 34.8678
          },
          {
            "category": "11-07-2006",
            "value": 47.3254,
            "value2": 34.7117
          },
          {
            "category": "12-07-2006",
            "value": 48.1105,
            "value2": 35.42
          },
          {
            "category": "13-07-2006",
            "value": 47.8218,
            "value2": 35.2397
          },
          {
            "category": "14-07-2006",
            "value": 47.2298,
            "value2": 34.7515
          },
          {
            "category": "15-07-2006",
            "value": 47.2298,
            "value2": 34.7515
          },
          {
            "category": "16-07-2006",
            "value": 47.2298,
            "value2": 34.7515
          },
          {
            "category": "17-07-2006",
            "value": 45.9851,
            "value2": 33.6128
          },
          {
            "category": "18-07-2006",
            "value": 45.7129,
            "value2": 33.2771
          },
          {
            "category": "19-07-2006",
            "value": 44.8354,
            "value2": 32.4473
          },
          {
            "category": "20-07-2006",
            "value": 45.8213,
            "value2": 33.3918
          },
          {
            "category": "21-07-2006",
            "value": 44.7675,
            "value2": 32.5683
          },
          {
            "category": "22-07-2006",
            "value": 44.7675,
            "value2": 32.5683
          },
          {
            "category": "23-07-2006",
            "value": 44.7675,
            "value2": 32.5683
          },
          {
            "category": "24-07-2006",
            "value": 45.093,
            "value2": 32.8205
          },
          {
            "category": "25-07-2006",
            "value": 45.6831,
            "value2": 33.5137
          },
          {
            "category": "26-07-2006",
            "value": 46.3233,
            "value2": 34.2157
          },
          {
            "category": "27-07-2006",
            "value": 47.0373,
            "value2": 34.595
          },
          {
            "category": "28-07-2006",
            "value": 47.096,
            "value2": 34.5621
          },
          {
            "category": "29-07-2006",
            "value": 47.096,
            "value2": 34.5621
          },
          {
            "category": "30-07-2006",
            "value": 47.096,
            "value2": 34.5621
          },
          {
            "category": "31-07-2006",
            "value": 47.437,
            "value2": 34.7932
          },
          {
            "category": "01-08-2006",
            "value": 47.4014,
            "value2": 34.7262
          },
          {
            "category": "02-08-2006",
            "value": 47.8149,
            "value2": 35.1566
          },
          {
            "category": "03-08-2006",
            "value": 48.3989,
            "value2": 35.3061
          },
          {
            "category": "04-08-2006",
            "value": 48.2285,
            "value2": 35.1266
          },
          {
            "category": "05-08-2006",
            "value": 48.2285,
            "value2": 35.1266
          },
          {
            "category": "06-08-2006",
            "value": 48.2285,
            "value2": 35.1266
          },
          {
            "category": "07-08-2006",
            "value": 47.9034,
            "value2": 35.0358
          },
          {
            "category": "08-08-2006",
            "value": 48.675,
            "value2": 35.63
          },
          {
            "category": "09-08-2006",
            "value": 49.6812,
            "value2": 36.1378
          },
          {
            "category": "10-08-2006",
            "value": 49.9662,
            "value2": 36.3826
          },
          {
            "category": "11-08-2006",
            "value": 50.3773,
            "value2": 36.632
          },
          {
            "category": "12-08-2006",
            "value": 50.3769,
            "value2": 36.632
          },
          {
            "category": "13-08-2006",
            "value": 50.3769,
            "value2": 36.632
          },
          {
            "category": "14-08-2006",
            "value": 51.1076,
            "value2": 37.1073
          },
          {
            "category": "15-08-2006",
            "value": 51.1076,
            "value2": 37.1073
          },
          {
            "category": "16-08-2006",
            "value": 51.5952,
            "value2": 37.6026
          },
          {
            "category": "17-08-2006",
            "value": 51.5032,
            "value2": 37.5868
          },
          {
            "category": "18-08-2006",
            "value": 51.723,
            "value2": 37.651
          },
          {
            "category": "19-08-2006",
            "value": 51.7229,
            "value2": 37.651
          },
          {
            "category": "20-08-2006",
            "value": 51.7229,
            "value2": 37.651
          },
          {
            "category": "21-08-2006",
            "value": 51.9276,
            "value2": 37.882
          },
          {
            "category": "22-08-2006",
            "value": 52.0782,
            "value2": 37.8212
          },
          {
            "category": "23-08-2006",
            "value": 51.509,
            "value2": 37.4348
          },
          {
            "category": "24-08-2006",
            "value": 52.0796,
            "value2": 37.7882
          },
          {
            "category": "25-08-2006",
            "value": 52.4278,
            "value2": 38.0299
          },
          {
            "category": "26-08-2006",
            "value": 52.4275,
            "value2": 38.0299
          },
          {
            "category": "27-08-2006",
            "value": 52.4275,
            "value2": 38.0299
          },
          {
            "category": "28-08-2006",
            "value": 52.5832,
            "value2": 38.1901
          },
          {
            "category": "29-08-2006",
            "value": 52.7792,
            "value2": 38.387
          },
          {
            "category": "30-08-2006",
            "value": 52.9368,
            "value2": 38.4073
          },
          {
            "category": "31-08-2006",
            "value": 52.7801,
            "value2": 38.1991
          },
          {
            "category": "01-09-2006",
            "value": 53.5098,
            "value2": 38.5316
          },
          {
            "category": "02-09-2006",
            "value": 53.5097,
            "value2": 38.5316
          },
          {
            "category": "03-09-2006",
            "value": 53.5097,
            "value2": 38.5316
          },
          {
            "category": "04-09-2006",
            "value": 54.3468,
            "value2": 39.0528
          },
          {
            "category": "05-09-2006",
            "value": 54.2986,
            "value2": 39.1142
          },
          {
            "category": "06-09-2006",
            "value": 54.6046,
            "value2": 39.2634
          },
          {
            "category": "07-09-2006",
            "value": 54.4109,
            "value2": 39.0556
          },
          {
            "category": "08-09-2006",
            "value": 54.6946,
            "value2": 39.3055
          },
          {
            "category": "09-09-2006",
            "value": 54.7017,
            "value2": 39.3055
          },
          {
            "category": "10-09-2006",
            "value": 54.7017,
            "value2": 39.3055
          },
          {
            "category": "11-09-2006",
            "value": 53.3176,
            "value2": 38.0744
          },
          {
            "category": "12-09-2006",
            "value": 53.7865,
            "value2": 38.4622
          },
          {
            "category": "13-09-2006",
            "value": 55.0394,
            "value2": 39.2067
          },
          {
            "category": "14-09-2006",
            "value": 55.5662,
            "value2": 39.4526
          },
          {
            "category": "15-09-2006",
            "value": 55.7472,
            "value2": 39.5493
          },
          {
            "category": "16-09-2006",
            "value": 55.7472,
            "value2": 39.5493
          },
          {
            "category": "17-09-2006",
            "value": 55.7472,
            "value2": 39.5493
          },
          {
            "category": "18-09-2006",
            "value": 56.1713,
            "value2": 39.7627
          },
          {
            "category": "19-09-2006",
            "value": 55.503,
            "value2": 39.3415
          },
          {
            "category": "20-09-2006",
            "value": 56.1984,
            "value2": 39.7032
          },
          {
            "category": "21-09-2006",
            "value": 56.5817,
            "value2": 40.1784
          },
          {
            "category": "22-09-2006",
            "value": 56.4565,
            "value2": 40.0475
          },
          {
            "category": "23-09-2006",
            "value": 56.4565,
            "value2": 40.0475
          },
          {
            "category": "24-09-2006",
            "value": 56.4565,
            "value2": 40.0475
          },
          {
            "category": "25-09-2006",
            "value": 56.1622,
            "value2": 39.8913
          },
          {
            "category": "26-09-2006",
            "value": 56.6683,
            "value2": 40.2655
          },
          {
            "category": "27-09-2006",
            "value": 56.5819,
            "value2": 40.4844
          },
          {
            "category": "28-09-2006",
            "value": 56.9875,
            "value2": 40.6041
          },
          {
            "category": "29-09-2006",
            "value": 57.1754,
            "value2": 40.9259
          },
          {
            "category": "30-09-2006",
            "value": 56.9922,
            "value2": 40.9259
          },
          {
            "category": "01-10-2006",
            "value": 56.9922,
            "value2": 40.9259
          },
          {
            "category": "02-10-2006",
            "value": 56.9922,
            "value2": 40.9259
          },
          {
            "category": "03-10-2006",
            "value": 56.5995,
            "value2": 40.7959
          },
          {
            "category": "04-10-2006",
            "value": 55.8252,
            "value2": 40.3664
          },
          {
            "category": "05-10-2006",
            "value": 56.5656,
            "value2": 41.0034
          },
          {
            "category": "06-10-2006",
            "value": 56.8631,
            "value2": 41.0914
          },
          {
            "category": "07-10-2006",
            "value": 56.8628,
            "value2": 41.0914
          },
          {
            "category": "08-10-2006",
            "value": 56.8628,
            "value2": 41.0914
          },
          {
            "category": "09-10-2006",
            "value": 57.1809,
            "value2": 41.1309
          },
          {
            "category": "10-10-2006",
            "value": 57.322,
            "value2": 41.1345
          },
          {
            "category": "11-10-2006",
            "value": 57.1896,
            "value2": 40.8967
          },
          {
            "category": "12-10-2006",
            "value": 57.8625,
            "value2": 41.495
          },
          {
            "category": "13-10-2006",
            "value": 58.2135,
            "value2": 41.9348
          },
          {
            "category": "14-10-2006",
            "value": 58.2135,
            "value2": 41.9348
          },
          {
            "category": "15-10-2006",
            "value": 58.2135,
            "value2": 41.9348
          },
          {
            "category": "16-10-2006",
            "value": 58.5237,
            "value2": 42.3329
          },
          {
            "category": "17-10-2006",
            "value": 58.1261,
            "value2": 42.1926
          },
          {
            "category": "18-10-2006",
            "value": 57.8156,
            "value2": 42.1893
          },
          {
            "category": "19-10-2006",
            "value": 57.3959,
            "value2": 41.8515
          },
          {
            "category": "20-10-2006",
            "value": 57.5966,
            "value2": 41.9235
          },
          {
            "category": "21-10-2006",
            "value": 57.9066,
            "value2": 42.1421
          },
          {
            "category": "22-10-2006",
            "value": 57.9066,
            "value2": 42.1421
          },
          {
            "category": "23-10-2006",
            "value": 57.5098,
            "value2": 41.8123
          },
          {
            "category": "24-10-2006",
            "value": 57.5094,
            "value2": 41.8123
          },
          {
            "category": "25-10-2006",
            "value": 57.5094,
            "value2": 41.8123
          },
          {
            "category": "26-10-2006",
            "value": 57.8133,
            "value2": 42.1134
          },
          {
            "category": "27-10-2006",
            "value": 58.4283,
            "value2": 42.6971
          },
          {
            "category": "28-10-2006",
            "value": 58.4283,
            "value2": 42.6971
          },
          {
            "category": "29-10-2006",
            "value": 58.4283,
            "value2": 42.6971
          },
          {
            "category": "30-10-2006",
            "value": 58.8396,
            "value2": 42.9926
          },
          {
            "category": "31-10-2006",
            "value": 58.6877,
            "value2": 42.8057
          },
          {
            "category": "01-11-2006",
            "value": 59.2761,
            "value2": 43.0633
          },
          {
            "category": "02-11-2006",
            "value": 59.7864,
            "value2": 43.2955
          },
          {
            "category": "03-11-2006",
            "value": 59.6024,
            "value2": 43.3928
          },
          {
            "category": "04-11-2006",
            "value": 59.6026,
            "value2": 43.3928
          },
          {
            "category": "05-11-2006",
            "value": 59.6026,
            "value2": 43.3928
          },
          {
            "category": "06-11-2006",
            "value": 59.8162,
            "value2": 43.6501
          },
          {
            "category": "07-11-2006",
            "value": 59.8231,
            "value2": 43.6193
          },
          {
            "category": "08-11-2006",
            "value": 59.1437,
            "value2": 43.2696
          },
          {
            "category": "09-11-2006",
            "value": 59.2135,
            "value2": 43.5425
          },
          {
            "category": "10-11-2006",
            "value": 59.6261,
            "value2": 43.9704
          },
          {
            "category": "11-11-2006",
            "value": 59.6261,
            "value2": 43.9704
          },
          {
            "category": "12-11-2006",
            "value": 59.6261,
            "value2": 43.9704
          },
          {
            "category": "13-11-2006",
            "value": 60.2581,
            "value2": 44.3546
          },
          {
            "category": "14-11-2006",
            "value": 60.3209,
            "value2": 44.347
          },
          {
            "category": "15-11-2006",
            "value": 60.4715,
            "value2": 44.4926
          },
          {
            "category": "16-11-2006",
            "value": 60.5894,
            "value2": 44.5415
          },
          {
            "category": "17-11-2006",
            "value": 60.5841,
            "value2": 44.1129
          },
          {
            "category": "18-11-2006",
            "value": 60.5838,
            "value2": 44.1129
          },
          {
            "category": "19-11-2006",
            "value": 60.5838,
            "value2": 44.1129
          },
          {
            "category": "20-11-2006",
            "value": 60.8304,
            "value2": 43.9744
          },
          {
            "category": "21-11-2006",
            "value": 61.4819,
            "value2": 44.6818
          },
          {
            "category": "22-11-2006",
            "value": 61.766,
            "value2": 45.0563
          },
          {
            "category": "23-11-2006",
            "value": 62.1363,
            "value2": 45.1069
          },
          {
            "category": "24-11-2006",
            "value": 61.7667,
            "value2": 45.2092
          },
          {
            "category": "25-11-2006",
            "value": 61.7669,
            "value2": 45.2092
          },
          {
            "category": "26-11-2006",
            "value": 61.7669,
            "value2": 45.2092
          },
          {
            "category": "27-11-2006",
            "value": 62.0144,
            "value2": 45.4101
          },
          {
            "category": "28-11-2006",
            "value": 61.7229,
            "value2": 44.8745
          },
          {
            "category": "29-11-2006",
            "value": 61.8501,
            "value2": 45.0189
          },
          {
            "category": "30-11-2006",
            "value": 62.1645,
            "value2": 45.1402
          },
          {
            "category": "01-12-2006",
            "value": 62.8617,
            "value2": 45.6843
          },
          {
            "category": "02-12-2006",
            "value": 62.8619,
            "value2": 45.6843
          },
          {
            "category": "03-12-2006",
            "value": 62.8619,
            "value2": 45.6843
          },
          {
            "category": "04-12-2006",
            "value": 62.7545,
            "value2": 45.8571
          },
          {
            "category": "05-12-2006",
            "value": 63.0086,
            "value2": 45.9777
          },
          {
            "category": "06-12-2006",
            "value": 62.7978,
            "value2": 45.8061
          },
          {
            "category": "07-12-2006",
            "value": 62.4692,
            "value2": 45.8931
          },
          {
            "category": "08-12-2006",
            "value": 62.0019,
            "value2": 45.4233
          },
          {
            "category": "09-12-2006",
            "value": 62.0019,
            "value2": 45.4233
          },
          {
            "category": "10-12-2006",
            "value": 62.0019,
            "value2": 45.4233
          },
          {
            "category": "11-12-2006",
            "value": 60.4379,
            "value2": 44.0616
          },
          {
            "category": "12-12-2006",
            "value": 58.5446,
            "value2": 42.4801
          },
          {
            "category": "13-12-2006",
            "value": 59.8679,
            "value2": 43.2598
          },
          {
            "category": "14-12-2006",
            "value": 60.7213,
            "value2": 44.3127
          },
          {
            "category": "15-12-2006",
            "value": 61.4986,
            "value2": 44.713
          },
          {
            "category": "16-12-2006",
            "value": 61.4981,
            "value2": 44.713
          },
          {
            "category": "17-12-2006",
            "value": 61.4981,
            "value2": 44.713
          },
          {
            "category": "18-12-2006",
            "value": 61.6108,
            "value2": 44.8998
          },
          {
            "category": "19-12-2006",
            "value": 60.5308,
            "value2": 43.9242
          },
          {
            "category": "20-12-2006",
            "value": 60.132,
            "value2": 43.7658
          },
          {
            "category": "21-12-2006",
            "value": 60.1605,
            "value2": 43.9392
          },
          {
            "category": "22-12-2006",
            "value": 60.5509,
            "value2": 44.2985
          },
          {
            "category": "23-12-2006",
            "value": 60.5509,
            "value2": 44.2985
          },
          {
            "category": "24-12-2006",
            "value": 60.5509,
            "value2": 44.2985
          },
          {
            "category": "25-12-2006",
            "value": 60.5509,
            "value2": 44.2985
          },
          {
            "category": "26-12-2006",
            "value": 61.5077,
            "value2": 45.0152
          },
          {
            "category": "27-12-2006",
            "value": 61.9407,
            "value2": 45.4583
          },
          {
            "category": "28-12-2006",
            "value": 61.7226,
            "value2": 45.4836
          },
          {
            "category": "29-12-2006",
            "value": 61.9292,
            "value2": 45.5117
          },
          {
            "category": "30-12-2006",
            "value": 61.9278,
            "value2": 45.5117
          },
          {
            "category": "31-12-2006",
            "value": 61.7083,
            "value2": 45.5117
          },
          {
            "category": "01-01-2007",
            "value": 61.7083,
            "value2": 45.5117
          },
          {
            "category": "02-01-2007",
            "value": 62.1439,
            "value2": 45.9202
          },
          {
            "category": "03-01-2007",
            "value": 62.6098,
            "value2": 46.1822
          },
          {
            "category": "04-01-2007",
            "value": 62.1611,
            "value2": 45.9343
          },
          {
            "category": "05-01-2007",
            "value": 62.1776,
            "value2": 45.8651
          },
          {
            "category": "06-01-2007",
            "value": 62.1777,
            "value2": 45.8651
          },
          {
            "category": "07-01-2007",
            "value": 62.1777,
            "value2": 45.8651
          },
          {
            "category": "08-01-2007",
            "value": 61.3358,
            "value2": 45.3087
          },
          {
            "category": "09-01-2007",
            "value": 60.7421,
            "value2": 45.0243
          },
          {
            "category": "10-01-2007",
            "value": 60.0913,
            "value2": 44.4068
          },
          {
            "category": "11-01-2007",
            "value": 60.7318,
            "value2": 45.2486
          },
          {
            "category": "12-01-2007",
            "value": 62.0424,
            "value2": 46.3854
          },
          {
            "category": "13-01-2007",
            "value": 62.0425,
            "value2": 46.3854
          },
          {
            "category": "14-01-2007",
            "value": 62.0425,
            "value2": 46.3854
          },
          {
            "category": "15-01-2007",
            "value": 62.5341,
            "value2": 46.7911
          },
          {
            "category": "16-01-2007",
            "value": 62.5903,
            "value2": 46.8311
          },
          {
            "category": "17-01-2007",
            "value": 62.7941,
            "value2": 47.0124
          },
          {
            "category": "18-01-2007",
            "value": 63.407,
            "value2": 47.1795
          },
          {
            "category": "19-01-2007",
            "value": 63.3956,
            "value2": 46.9714
          },
          {
            "category": "20-01-2007",
            "value": 63.3956,
            "value2": 46.9714
          },
          {
            "category": "21-01-2007",
            "value": 63.3956,
            "value2": 46.9714
          },
          {
            "category": "22-01-2007",
            "value": 63.4252,
            "value2": 47.0619
          },
          {
            "category": "23-01-2007",
            "value": 62.5875,
            "value2": 46.512
          },
          {
            "category": "24-01-2007",
            "value": 62.7518,
            "value2": 46.759
          },
          {
            "category": "25-01-2007",
            "value": 63.6089,
            "value2": 47.3093
          },
          {
            "category": "26-01-2007",
            "value": 63.6089,
            "value2": 47.3093
          },
          {
            "category": "27-01-2007",
            "value": 63.6089,
            "value2": 47.3093
          },
          {
            "category": "28-01-2007",
            "value": 63.6089,
            "value2": 47.3093
          },
          {
            "category": "29-01-2007",
            "value": 63.5253,
            "value2": 47.1418
          },
          {
            "category": "30-01-2007",
            "value": 63.5255,
            "value2": 47.1418
          },
          {
            "category": "31-01-2007",
            "value": 62.9668,
            "value2": 46.7029
          },
          {
            "category": "01-02-2007",
            "value": 63.6602,
            "value2": 47.2098
          },
          {
            "category": "02-02-2007",
            "value": 64.8669,
            "value2": 47.6456
          },
          {
            "category": "03-02-2007",
            "value": 64.8675,
            "value2": 47.6456
          },
          {
            "category": "04-02-2007",
            "value": 64.8675,
            "value2": 47.6456
          },
          {
            "category": "05-02-2007",
            "value": 65.8331,
            "value2": 47.9837
          },
          {
            "category": "06-02-2007",
            "value": 65.7777,
            "value2": 47.9392
          },
          {
            "category": "07-02-2007",
            "value": 66.3802,
            "value2": 48.3175
          },
          {
            "category": "08-02-2007",
            "value": 66.2418,
            "value2": 48.2898
          },
          {
            "category": "09-02-2007",
            "value": 65.4376,
            "value2": 47.7226
          },
          {
            "category": "10-02-2007",
            "value": 65.4376,
            "value2": 47.7226
          },
          {
            "category": "11-02-2007",
            "value": 65.4376,
            "value2": 47.7226
          },
          {
            "category": "12-02-2007",
            "value": 63.6725,
            "value2": 46.168
          },
          {
            "category": "13-02-2007",
            "value": 63.2661,
            "value2": 45.8542
          },
          {
            "category": "14-02-2007",
            "value": 62.8715,
            "value2": 45.6701
          },
          {
            "category": "15-02-2007",
            "value": 64.654,
            "value2": 46.8722
          },
          {
            "category": "16-02-2007",
            "value": 64.654,
            "value2": 46.8722
          },
          {
            "category": "17-02-2007",
            "value": 64.6544,
            "value2": 46.8722
          },
          {
            "category": "18-02-2007",
            "value": 64.6544,
            "value2": 46.8722
          },
          {
            "category": "19-02-2007",
            "value": 64.5272,
            "value2": 46.9673
          },
          {
            "category": "20-02-2007",
            "value": 63.822,
            "value2": 46.4673
          },
          {
            "category": "21-02-2007",
            "value": 63.9514,
            "value2": 46.3384
          },
          {
            "category": "22-02-2007",
            "value": 62.9799,
            "value2": 45.778
          },
          {
            "category": "23-02-2007",
            "value": 61.3705,
            "value2": 44.5271
          },
          {
            "category": "24-02-2007",
            "value": 61.3709,
            "value2": 44.5271
          },
          {
            "category": "25-02-2007",
            "value": 61.3709,
            "value2": 44.5271
          },
          {
            "category": "26-02-2007",
            "value": 61.079,
            "value2": 44.6558
          },
          {
            "category": "27-02-2007",
            "value": 60.7766,
            "value2": 44.322
          },
          {
            "category": "28-02-2007",
            "value": 59.2495,
            "value2": 42.6391
          },
          {
            "category": "01-03-2007",
            "value": 59.4029,
            "value2": 43.1152
          },
          {
            "category": "02-03-2007",
            "value": 58.0258,
            "value2": 42.368
          },
          {
            "category": "03-03-2007",
            "value": 58.0247,
            "value2": 42.368
          },
          {
            "category": "04-03-2007",
            "value": 58.0247,
            "value2": 42.368
          },
          {
            "category": "05-03-2007",
            "value": 55.9629,
            "value2": 40.5493
          },
          {
            "category": "06-03-2007",
            "value": 56.8664,
            "value2": 41.2498
          },
          {
            "category": "07-03-2007",
            "value": 56.6768,
            "value2": 40.7423
          },
          {
            "category": "08-03-2007",
            "value": 58.2837,
            "value2": 42.184
          },
          {
            "category": "09-03-2007",
            "value": 57.7855,
            "value2": 41.771
          },
          {
            "category": "10-03-2007",
            "value": 57.7859,
            "value2": 41.771
          },
          {
            "category": "11-03-2007",
            "value": 57.7859,
            "value2": 41.771
          },
          {
            "category": "12-03-2007",
            "value": 58.1154,
            "value2": 42.002
          },
          {
            "category": "13-03-2007",
            "value": 58.3378,
            "value2": 42.4278
          },
          {
            "category": "14-03-2007",
            "value": 56.8447,
            "value2": 41.1733
          },
          {
            "category": "15-03-2007",
            "value": 56.7107,
            "value2": 41.2792
          },
          {
            "category": "16-03-2007",
            "value": 55.8664,
            "value2": 40.8908
          },
          {
            "category": "17-03-2007",
            "value": 55.8652,
            "value2": 40.8908
          },
          {
            "category": "18-03-2007",
            "value": 55.8652,
            "value2": 40.8908
          },
          {
            "category": "19-03-2007",
            "value": 56.611,
            "value2": 41.4922
          },
          {
            "category": "20-03-2007",
            "value": 57.113,
            "value2": 41.7771
          },
          {
            "category": "21-03-2007",
            "value": 57.6719,
            "value2": 42.336
          },
          {
            "category": "22-03-2007",
            "value": 59.073,
            "value2": 43.3016
          },
          {
            "category": "23-03-2007",
            "value": 59.0972,
            "value2": 43.2955
          },
          {
            "category": "24-03-2007",
            "value": 59.0968,
            "value2": 43.2955
          },
          {
            "category": "25-03-2007",
            "value": 59.0968,
            "value2": 43.2955
          },
          {
            "category": "26-03-2007",
            "value": 58.3897,
            "value2": 42.8957
          },
          {
            "category": "27-03-2007",
            "value": 58.3897,
            "value2": 42.8957
          },
          {
            "category": "28-03-2007",
            "value": 57.0998,
            "value2": 42.1582
          },
          {
            "category": "29-03-2007",
            "value": 57.5944,
            "value2": 42.4131
          },
          {
            "category": "30-03-2007",
            "value": 58.0981,
            "value2": 42.7886
          },
          {
            "category": "31-03-2007",
            "value": 57.7127,
            "value2": 42.7886
          },
          {
            "category": "01-04-2007",
            "value": 57.7399,
            "value2": 42.7886
          },
          {
            "category": "02-04-2007",
            "value": 54.753,
            "value2": 40.9573
          },
          {
            "category": "03-04-2007",
            "value": 54.7176,
            "value2": 41.3603
          },
          {
            "category": "04-04-2007",
            "value": 56.2783,
            "value2": 41.8116
          },
          {
            "category": "05-04-2007",
            "value": 55.6382,
            "value2": 42.1349
          },
          {
            "category": "06-04-2007",
            "value": 55.6382,
            "value2": 42.1349
          },
          {
            "category": "07-04-2007",
            "value": 55.6381,
            "value2": 42.1349
          },
          {
            "category": "08-04-2007",
            "value": 55.6381,
            "value2": 42.1349
          },
          {
            "category": "09-04-2007",
            "value": 56.4315,
            "value2": 43.1532
          },
          {
            "category": "10-04-2007",
            "value": 56.9733,
            "value2": 43.3365
          },
          {
            "category": "11-04-2007",
            "value": 58.0055,
            "value2": 43.4238
          },
          {
            "category": "12-04-2007",
            "value": 57.5728,
            "value2": 43.1981
          },
          {
            "category": "13-04-2007",
            "value": 58.8941,
            "value2": 44.0269
          },
          {
            "category": "14-04-2007",
            "value": 58.8942,
            "value2": 44.0269
          },
          {
            "category": "15-04-2007",
            "value": 58.8942,
            "value2": 44.0269
          },
          {
            "category": "16-04-2007",
            "value": 60.0312,
            "value2": 44.9409
          },
          {
            "category": "17-04-2007",
            "value": 59.9343,
            "value2": 44.6614
          },
          {
            "category": "18-04-2007",
            "value": 60.2824,
            "value2": 44.8836
          },
          {
            "category": "19-04-2007",
            "value": 58.681,
            "value2": 44.7637
          },
          {
            "category": "20-04-2007",
            "value": 61.2998,
            "value2": 45.522
          },
          {
            "category": "21-04-2007",
            "value": 61.2996,
            "value2": 45.522
          },
          {
            "category": "22-04-2007",
            "value": 61.2996,
            "value2": 45.522
          },
          {
            "category": "23-04-2007",
            "value": 61.5149,
            "value2": 45.543
          },
          {
            "category": "24-04-2007",
            "value": 62.454,
            "value2": 46.1927
          },
          {
            "category": "25-04-2007",
            "value": 62.7269,
            "value2": 46.5459
          },
          {
            "category": "26-04-2007",
            "value": 62.776,
            "value2": 46.5449
          },
          {
            "category": "27-04-2007",
            "value": 62.2232,
            "value2": 45.7253
          },
          {
            "category": "28-04-2007",
            "value": 62.2234,
            "value2": 45.7253
          },
          {
            "category": "29-04-2007",
            "value": 62.2234,
            "value2": 45.7253
          },
          {
            "category": "30-04-2007",
            "value": 62.678,
            "value2": 45.8595
          },
          {
            "category": "01-05-2007",
            "value": 62.678,
            "value2": 45.8595
          },
          {
            "category": "02-05-2007",
            "value": 62.678,
            "value2": 45.8595
          },
          {
            "category": "03-05-2007",
            "value": 63.5457,
            "value2": 46.5336
          },
          {
            "category": "04-05-2007",
            "value": 62.9724,
            "value2": 46.2749
          },
          {
            "category": "05-05-2007",
            "value": 62.9724,
            "value2": 46.2749
          },
          {
            "category": "06-05-2007",
            "value": 62.9724,
            "value2": 46.2749
          },
          {
            "category": "07-05-2007",
            "value": 62.8503,
            "value2": 46.1205
          },
          {
            "category": "08-05-2007",
            "value": 61.9114,
            "value2": 45.7127
          },
          {
            "category": "09-05-2007",
            "value": 62.4143,
            "value2": 45.8126
          },
          {
            "category": "10-05-2007",
            "value": 62.7957,
            "value2": 45.8152
          },
          {
            "category": "11-05-2007",
            "value": 62.9045,
            "value2": 45.9473
          },
          {
            "category": "12-05-2007",
            "value": 62.9042,
            "value2": 45.9473
          },
          {
            "category": "13-05-2007",
            "value": 62.9042,
            "value2": 45.9473
          },
          {
            "category": "14-05-2007",
            "value": 63.3001,
            "value2": 46.5798
          },
          {
            "category": "15-05-2007",
            "value": 63.3677,
            "value2": 46.5766
          },
          {
            "category": "16-05-2007",
            "value": 64.1184,
            "value2": 47.2004
          },
          {
            "category": "17-05-2007",
            "value": 64.545,
            "value2": 47.7424
          },
          {
            "category": "18-05-2007",
            "value": 64.7037,
            "value2": 47.7517
          },
          {
            "category": "19-05-2007",
            "value": 64.7037,
            "value2": 47.7517
          },
          {
            "category": "20-05-2007",
            "value": 64.7037,
            "value2": 47.7517
          },
          {
            "category": "21-05-2007",
            "value": 65.1511,
            "value2": 48.1935
          },
          {
            "category": "22-05-2007",
            "value": 65.2491,
            "value2": 48.3924
          },
          {
            "category": "23-05-2007",
            "value": 64.8958,
            "value2": 48.0637
          },
          {
            "category": "24-05-2007",
            "value": 64.5867,
            "value2": 47.7249
          },
          {
            "category": "25-05-2007",
            "value": 65.0118,
            "value2": 48.1409
          },
          {
            "category": "26-05-2007",
            "value": 65.0119,
            "value2": 48.1409
          },
          {
            "category": "27-05-2007",
            "value": 65.0119,
            "value2": 48.1409
          },
          {
            "category": "28-05-2007",
            "value": 65.795,
            "value2": 48.4244
          },
          {
            "category": "29-05-2007",
            "value": 66.5883,
            "value2": 48.7882
          },
          {
            "category": "30-05-2007",
            "value": 66.9922,
            "value2": 48.3297
          },
          {
            "category": "31-05-2007",
            "value": 67.719,
            "value2": 48.7596
          },
          {
            "category": "01-06-2007",
            "value": 68.0891,
            "value2": 48.972
          },
          {
            "category": "02-06-2007",
            "value": 68.0879,
            "value2": 48.972
          },
          {
            "category": "03-06-2007",
            "value": 68.0879,
            "value2": 48.972
          },
          {
            "category": "04-06-2007",
            "value": 68.1122,
            "value2": 48.7278
          },
          {
            "category": "05-06-2007",
            "value": 68.1338,
            "value2": 48.938
          },
          {
            "category": "06-06-2007",
            "value": 66.7398,
            "value2": 48.0319
          },
          {
            "category": "07-06-2007",
            "value": 66.2526,
            "value2": 47.8567
          },
          {
            "category": "08-06-2007",
            "value": 65.2832,
            "value2": 47.4225
          },
          {
            "category": "09-06-2007",
            "value": 65.2832,
            "value2": 47.4225
          },
          {
            "category": "10-06-2007",
            "value": 65.2832,
            "value2": 47.4225
          },
          {
            "category": "11-06-2007",
            "value": 65.3088,
            "value2": 47.4339
          },
          {
            "category": "12-06-2007",
            "value": 65.6095,
            "value2": 47.3613
          },
          {
            "category": "13-06-2007",
            "value": 65.1354,
            "value2": 47.0236
          },
          {
            "category": "14-06-2007",
            "value": 66.4454,
            "value2": 47.7819
          },
          {
            "category": "15-06-2007",
            "value": 66.1609,
            "value2": 47.8183
          },
          {
            "category": "16-06-2007",
            "value": 66.1608,
            "value2": 47.8183
          },
          {
            "category": "17-06-2007",
            "value": 66.1608,
            "value2": 47.8183
          },
          {
            "category": "18-06-2007",
            "value": 65.6355,
            "value2": 47.6013
          },
          {
            "category": "19-06-2007",
            "value": 66.7211,
            "value2": 48.2729
          },
          {
            "category": "20-06-2007",
            "value": 67.4021,
            "value2": 48.7483
          },
          {
            "category": "21-06-2007",
            "value": 68.2954,
            "value2": 49.0656
          },
          {
            "category": "22-06-2007",
            "value": 68.5249,
            "value2": 49.0262
          },
          {
            "category": "23-06-2007",
            "value": 68.5249,
            "value2": 49.0262
          },
          {
            "category": "24-06-2007",
            "value": 68.5249,
            "value2": 49.0262
          },
          {
            "category": "25-06-2007",
            "value": 68.524,
            "value2": 49.0866
          },
          {
            "category": "26-06-2007",
            "value": 69.1979,
            "value2": 49.2818
          },
          {
            "category": "27-06-2007",
            "value": 68.6394,
            "value2": 49.1433
          },
          {
            "category": "28-06-2007",
            "value": 69.2428,
            "value2": 49.3606
          },
          {
            "category": "29-06-2007",
            "value": 69.8251,
            "value2": 49.9207
          },
          {
            "category": "30-06-2007",
            "value": 69.5954,
            "value2": 49.9207
          },
          {
            "category": "01-07-2007",
            "value": 69.5967,
            "value2": 49.9207
          },
          {
            "category": "02-07-2007",
            "value": 69.8033,
            "value2": 50.098
          },
          {
            "category": "03-07-2007",
            "value": 70.4456,
            "value2": 50.5782
          },
          {
            "category": "04-07-2007",
            "value": 70.6461,
            "value2": 50.6821
          },
          {
            "category": "05-07-2007",
            "value": 70.4208,
            "value2": 50.5189
          },
          {
            "category": "06-07-2007",
            "value": 71.0357,
            "value2": 50.789
          },
          {
            "category": "07-07-2007",
            "value": 71.0358,
            "value2": 50.789
          },
          {
            "category": "08-07-2007",
            "value": 71.0359,
            "value2": 50.789
          },
          {
            "category": "09-07-2007",
            "value": 71.5961,
            "value2": 51.1353
          },
          {
            "category": "10-07-2007",
            "value": 70.9383,
            "value2": 50.9859
          },
          {
            "category": "11-07-2007",
            "value": 71.0589,
            "value2": 50.9235
          },
          {
            "category": "12-07-2007",
            "value": 71.7662,
            "value2": 51.6177
          },
          {
            "category": "13-07-2007",
            "value": 72.1776,
            "value2": 52.0872
          },
          {
            "category": "14-07-2007",
            "value": 72.1777,
            "value2": 52.0872
          },
          {
            "category": "15-07-2007",
            "value": 72.1778,
            "value2": 52.0872
          },
          {
            "category": "16-07-2007",
            "value": 72.5734,
            "value2": 52.3139
          },
          {
            "category": "17-07-2007",
            "value": 72.1603,
            "value2": 52.1248
          },
          {
            "category": "18-07-2007",
            "value": 72.5327,
            "value2": 52.1469
          },
          {
            "category": "19-07-2007",
            "value": 73.2591,
            "value2": 52.7935
          },
          {
            "category": "20-07-2007",
            "value": 73.4738,
            "value2": 52.808
          },
          {
            "category": "21-07-2007",
            "value": 73.4739,
            "value2": 52.808
          },
          {
            "category": "22-07-2007",
            "value": 73.474,
            "value2": 52.808
          },
          {
            "category": "23-07-2007",
            "value": 74.7271,
            "value2": 53.3152
          },
          {
            "category": "24-07-2007",
            "value": 74.2958,
            "value2": 53.3017
          },
          {
            "category": "25-07-2007",
            "value": 73.5871,
            "value2": 52.8905
          },
          {
            "category": "26-07-2007",
            "value": 73.7082,
            "value2": 53.1222
          },
          {
            "category": "27-07-2007",
            "value": 70.724,
            "value2": 51.3108
          },
          {
            "category": "28-07-2007",
            "value": 70.723,
            "value2": 51.3108
          },
          {
            "category": "29-07-2007",
            "value": 70.7183,
            "value2": 51.3108
          },
          {
            "category": "30-07-2007",
            "value": 71.0917,
            "value2": 51.3751
          },
          {
            "category": "31-07-2007",
            "value": 72.6841,
            "value2": 52.3543
          },
          {
            "category": "01-08-2007",
            "value": 70.0077,
            "value2": 50.2185
          },
          {
            "category": "02-08-2007",
            "value": 70.4657,
            "value2": 50.4628
          },
          {
            "category": "03-08-2007",
            "value": 71.4309,
            "value2": 51.0827
          },
          {
            "category": "04-08-2007",
            "value": 71.4314,
            "value2": 51.0827
          },
          {
            "category": "05-08-2007",
            "value": 71.4315,
            "value2": 51.0827
          },
          {
            "category": "06-08-2007",
            "value": 70.7991,
            "value2": 50.3781
          },
          {
            "category": "07-08-2007",
            "value": 71.3064,
            "value2": 50.5867
          },
          {
            "category": "08-08-2007",
            "value": 72.7529,
            "value2": 51.7014
          },
          {
            "category": "09-08-2007",
            "value": 71.3612,
            "value2": 50.9214
          },
          {
            "category": "10-08-2007",
            "value": 70.3348,
            "value2": 50.2111
          },
          {
            "category": "11-08-2007",
            "value": 70.3349,
            "value2": 50.2111
          },
          {
            "category": "12-08-2007",
            "value": 70.3351,
            "value2": 50.2111
          },
          {
            "category": "13-08-2007",
            "value": 70.9077,
            "value2": 50.7134
          },
          {
            "category": "14-08-2007",
            "value": 70.7428,
            "value2": 50.6642
          },
          {
            "category": "15-08-2007",
            "value": 70.7429,
            "value2": 50.6642
          },
          {
            "category": "16-08-2007",
            "value": 67.9543,
            "value2": 48.5545
          },
          {
            "category": "17-08-2007",
            "value": 67.193,
            "value2": 47.7583
          },
          {
            "category": "18-08-2007",
            "value": 67.1636,
            "value2": 47.7583
          },
          {
            "category": "19-08-2007",
            "value": 67.1637,
            "value2": 47.7583
          },
          {
            "category": "20-08-2007",
            "value": 68.3209,
            "value2": 48.7211
          },
          {
            "category": "21-08-2007",
            "value": 66.2529,
            "value2": 47.1199
          },
          {
            "category": "22-08-2007",
            "value": 67.1307,
            "value2": 47.7702
          },
          {
            "category": "23-08-2007",
            "value": 67.0806,
            "value2": 47.4147
          },
          {
            "category": "24-08-2007",
            "value": 68.4208,
            "value2": 48.1778
          },
          {
            "category": "25-08-2007",
            "value": 68.4144,
            "value2": 48.1778
          },
          {
            "category": "26-08-2007",
            "value": 68.4146,
            "value2": 48.1778
          },
          {
            "category": "27-08-2007",
            "value": 70.0172,
            "value2": 49.6216
          },
          {
            "category": "28-08-2007",
            "value": 70.2963,
            "value2": 49.9422
          },
          {
            "category": "29-08-2007",
            "value": 70.888,
            "value2": 50.239
          },
          {
            "category": "30-08-2007",
            "value": 71.2483,
            "value2": 50.5952
          },
          {
            "category": "31-08-2007",
            "value": 71.682,
            "value2": 51.3778
          },
          {
            "category": "01-09-2007",
            "value": 71.6821,
            "value2": 51.3778
          },
          {
            "category": "02-09-2007",
            "value": 71.6819,
            "value2": 51.3778
          },
          {
            "category": "03-09-2007",
            "value": 71.997,
            "value2": 51.9292
          },
          {
            "category": "04-09-2007",
            "value": 72.5676,
            "value2": 52.1517
          },
          {
            "category": "05-09-2007",
            "value": 72.2891,
            "value2": 52.1311
          },
          {
            "category": "06-09-2007",
            "value": 72.9869,
            "value2": 52.6738
          },
          {
            "category": "07-09-2007",
            "value": 72.997,
            "value2": 52.5433
          },
          {
            "category": "08-09-2007",
            "value": 72.9971,
            "value2": 52.5433
          },
          {
            "category": "09-09-2007",
            "value": 72.9972,
            "value2": 52.5433
          },
          {
            "category": "10-09-2007",
            "value": 72.9174,
            "value2": 52.688
          },
          {
            "category": "11-09-2007",
            "value": 72.714,
            "value2": 52.6126
          },
          {
            "category": "12-09-2007",
            "value": 72.7813,
            "value2": 52.6826
          },
          {
            "category": "13-09-2007",
            "value": 73.3891,
            "value2": 53.1361
          },
          {
            "category": "14-09-2007",
            "value": 73.2941,
            "value2": 52.9962
          },
          {
            "category": "15-09-2007",
            "value": 73.2942,
            "value2": 52.9962
          },
          {
            "category": "16-09-2007",
            "value": 73.2943,
            "value2": 52.9962
          },
          {
            "category": "17-09-2007",
            "value": 72.9275,
            "value2": 52.8885
          },
          {
            "category": "18-09-2007",
            "value": 73.7245,
            "value2": 53.474
          },
          {
            "category": "19-09-2007",
            "value": 76.4252,
            "value2": 55.258
          },
          {
            "category": "20-09-2007",
            "value": 76.7745,
            "value2": 55.5889
          },
          {
            "category": "21-09-2007",
            "value": 77.8096,
            "value2": 56.2501
          },
          {
            "category": "22-09-2007",
            "value": 77.8096,
            "value2": 56.2501
          },
          {
            "category": "23-09-2007",
            "value": 77.8098,
            "value2": 56.2501
          },
          {
            "category": "24-09-2007",
            "value": 78.9761,
            "value2": 57.2217
          },
          {
            "category": "25-09-2007",
            "value": 78.7237,
            "value2": 57.2609
          },
          {
            "category": "26-09-2007",
            "value": 79.1243,
            "value2": 57.399
          },
          {
            "category": "27-09-2007",
            "value": 79.9457,
            "value2": 57.8394
          },
          {
            "category": "28-09-2007",
            "value": 80.6022,
            "value2": 58.4879
          },
          {
            "category": "29-09-2007",
            "value": 80.6038,
            "value2": 58.4879
          },
          {
            "category": "30-09-2007",
            "value": 80.3288,
            "value2": 58.4879
          },
          {
            "category": "01-10-2007",
            "value": 80.3967,
            "value2": 58.9552
          },
          {
            "category": "02-10-2007",
            "value": 80.3967,
            "value2": 58.9552
          },
          {
            "category": "03-10-2007",
            "value": 82.3769,
            "value2": 60.2672
          },
          {
            "category": "04-10-2007",
            "value": 82.2186,
            "value2": 60.2206
          },
          {
            "category": "05-10-2007",
            "value": 82.2762,
            "value2": 59.8459
          },
          {
            "category": "06-10-2007",
            "value": 82.2767,
            "value2": 59.8459
          },
          {
            "category": "07-10-2007",
            "value": 82.2769,
            "value2": 59.8459
          },
          {
            "category": "08-10-2007",
            "value": 80.7612,
            "value2": 58.4122
          },
          {
            "category": "09-10-2007",
            "value": 83.6629,
            "value2": 60.7477
          },
          {
            "category": "10-10-2007",
            "value": 85.3399,
            "value2": 61.9578
          },
          {
            "category": "11-10-2007",
            "value": 86.3602,
            "value2": 62.7672
          },
          {
            "category": "12-10-2007",
            "value": 84.7006,
            "value2": 61.6589
          },
          {
            "category": "13-10-2007",
            "value": 84.7007,
            "value2": 61.6589
          },
          {
            "category": "14-10-2007",
            "value": 84.701,
            "value2": 61.6589
          },
          {
            "category": "15-10-2007",
            "value": 87.0402,
            "value2": 63.7607
          },
          {
            "category": "16-10-2007",
            "value": 86.6234,
            "value2": 64.0792
          },
          {
            "category": "17-10-2007",
            "value": 84.7738,
            "value2": 62.7229
          },
          {
            "category": "18-10-2007",
            "value": 81.3563,
            "value2": 60.4986
          },
          {
            "category": "19-10-2007",
            "value": 79.7654,
            "value2": 58.7825
          },
          {
            "category": "20-10-2007",
            "value": 79.7662,
            "value2": 58.7825
          },
          {
            "category": "21-10-2007",
            "value": 79.7664,
            "value2": 58.7825
          },
          {
            "category": "22-10-2007",
            "value": 80.1988,
            "value2": 58.9901
          },
          {
            "category": "23-10-2007",
            "value": 83.5474,
            "value2": 62.0286
          },
          {
            "category": "24-10-2007",
            "value": 84.058,
            "value2": 62.4682
          },
          {
            "category": "25-10-2007",
            "value": 85.1413,
            "value2": 63.2658
          },
          {
            "category": "26-10-2007",
            "value": 87.5784,
            "value2": 64.8584
          },
          {
            "category": "27-10-2007",
            "value": 87.5786,
            "value2": 64.8584
          },
          {
            "category": "28-10-2007",
            "value": 87.5788,
            "value2": 64.8584
          },
          {
            "category": "29-10-2007",
            "value": 89.2537,
            "value2": 66.8939
          },
          {
            "category": "30-10-2007",
            "value": 88.8679,
            "value2": 66.7783
          },
          {
            "category": "31-10-2007",
            "value": 88.89,
            "value2": 67.2235
          },
          {
            "category": "01-11-2007",
            "value": 88.4352,
            "value2": 66.5831
          },
          {
            "category": "02-11-2007",
            "value": 88.8659,
            "value2": 67.3978
          },
          {
            "category": "03-11-2007",
            "value": 88.8664,
            "value2": 67.3978
          },
          {
            "category": "04-11-2007",
            "value": 88.8666,
            "value2": 67.3978
          },
          {
            "category": "05-11-2007",
            "value": 87.695,
            "value2": 66.8525
          },
          {
            "category": "06-11-2007",
            "value": 86.9482,
            "value2": 66.1861
          },
          {
            "category": "07-11-2007",
            "value": 86.3825,
            "value2": 65.9308
          },
          {
            "category": "08-11-2007",
            "value": 85.2787,
            "value2": 65.2724
          },
          {
            "category": "09-11-2007",
            "value": 84.6983,
            "value2": 65.0943
          },
          {
            "category": "10-11-2007",
            "value": 84.6987,
            "value2": 65.0943
          },
          {
            "category": "11-11-2007",
            "value": 84.6989,
            "value2": 65.0943
          },
          {
            "category": "12-11-2007",
            "value": 83.7204,
            "value2": 64.4822
          },
          {
            "category": "13-11-2007",
            "value": 85.5114,
            "value2": 65.5945
          },
          {
            "category": "14-11-2007",
            "value": 88.3265,
            "value2": 68.1347
          },
          {
            "category": "15-11-2007",
            "value": 88.1664,
            "value2": 68.3223
          },
          {
            "category": "16-11-2007",
            "value": 87.8074,
            "value2": 68.4546
          },
          {
            "category": "17-11-2007",
            "value": 87.8077,
            "value2": 68.4546
          },
          {
            "category": "18-11-2007",
            "value": 87.8079,
            "value2": 68.4546
          },
          {
            "category": "19-11-2007",
            "value": 88.8503,
            "value2": 69.074
          },
          {
            "category": "20-11-2007",
            "value": 87.4948,
            "value2": 67.8288
          },
          {
            "category": "21-11-2007",
            "value": 84.7444,
            "value2": 64.9705
          },
          {
            "category": "22-11-2007",
            "value": 83.8297,
            "value2": 64.538
          },
          {
            "category": "23-11-2007",
            "value": 85.3442,
            "value2": 65.6723
          },
          {
            "category": "24-11-2007",
            "value": 85.3443,
            "value2": 65.6723
          },
          {
            "category": "25-11-2007",
            "value": 85.3445,
            "value2": 65.6723
          },
          {
            "category": "26-11-2007",
            "value": 86.8998,
            "value2": 67.022
          },
          {
            "category": "27-11-2007",
            "value": 86.4865,
            "value2": 66.8721
          },
          {
            "category": "28-11-2007",
            "value": 86.0904,
            "value2": 66.2242
          },
          {
            "category": "29-11-2007",
            "value": 85.9117,
            "value2": 66.3245
          },
          {
            "category": "30-11-2007",
            "value": 87.6039,
            "value2": 67.9208
          },
          {
            "category": "01-12-2007",
            "value": 87.6049,
            "value2": 67.9208
          },
          {
            "category": "02-12-2007",
            "value": 87.6052,
            "value2": 67.9208
          },
          {
            "category": "03-12-2007",
            "value": 88.2545,
            "value2": 69.2433
          },
          {
            "category": "04-12-2007",
            "value": 88.9554,
            "value2": 69.5734
          },
          {
            "category": "05-12-2007",
            "value": 89.4552,
            "value2": 70.5352
          },
          {
            "category": "06-12-2007",
            "value": 89.8016,
            "value2": 70.649
          },
          {
            "category": "07-12-2007",
            "value": 90.5646,
            "value2": 70.9708
          },
          {
            "category": "08-12-2007",
            "value": 90.5642,
            "value2": 70.9708
          },
          {
            "category": "09-12-2007",
            "value": 90.5644,
            "value2": 70.9708
          },
          {
            "category": "10-12-2007",
            "value": 90.4667,
            "value2": 71.1553
          },
          {
            "category": "11-12-2007",
            "value": 92.0069,
            "value2": 72.1931
          },
          {
            "category": "12-12-2007",
            "value": 92.8191,
            "value2": 72.9832
          },
          {
            "category": "13-12-2007",
            "value": 91.8289,
            "value2": 72.4313
          },
          {
            "category": "14-12-2007",
            "value": 91.4299,
            "value2": 72.4501
          },
          {
            "category": "15-12-2007",
            "value": 91.43,
            "value2": 72.4501
          },
          {
            "category": "16-12-2007",
            "value": 91.4301,
            "value2": 72.4501
          },
          {
            "category": "17-12-2007",
            "value": 87.646,
            "value2": 69.3558
          },
          {
            "category": "18-12-2007",
            "value": 87.25,
            "value2": 69.0857
          },
          {
            "category": "19-12-2007",
            "value": 86.9772,
            "value2": 69.0969
          },
          {
            "category": "20-12-2007",
            "value": 86.9356,
            "value2": 69.0402
          },
          {
            "category": "21-12-2007",
            "value": 86.9357,
            "value2": 69.0402
          },
          {
            "category": "22-12-2007",
            "value": 86.9358,
            "value2": 69.0402
          },
          {
            "category": "23-12-2007",
            "value": 86.9359,
            "value2": 69.0402
          },
          {
            "category": "24-12-2007",
            "value": 89.5195,
            "value2": 71.1518
          },
          {
            "category": "25-12-2007",
            "value": 89.5196,
            "value2": 71.1518
          },
          {
            "category": "26-12-2007",
            "value": 91.1601,
            "value2": 72.637
          },
          {
            "category": "27-12-2007",
            "value": 91.006,
            "value2": 72.7537
          },
          {
            "category": "28-12-2007",
            "value": 91.7416,
            "value2": 73.3746
          },
          {
            "category": "29-12-2007",
            "value": 91.7416,
            "value2": 73.3746
          },
          {
            "category": "30-12-2007",
            "value": 91.7417,
            "value2": 73.3746
          },
          {
            "category": "31-12-2007",
            "value": 92.4592,
            "value2": 74.1936
          },
          {
            "category": "01-01-2008",
            "value": 91.7646,
            "value2": 74.7824
          },
          {
            "category": "02-01-2008",
            "value": 92.498,
            "value2": 75.69
          },
          {
            "category": "03-01-2008",
            "value": 91.7129,
            "value2": 75.5199
          },
          {
            "category": "04-01-2008",
            "value": 92.6497,
            "value2": 76.4218
          },
          {
            "category": "05-01-2008",
            "value": 92.6498,
            "value2": 76.4218
          },
          {
            "category": "06-01-2008",
            "value": 92.6498,
            "value2": 76.4218
          },
          {
            "category": "07-01-2008",
            "value": 92.4742,
            "value2": 76.6963
          },
          {
            "category": "08-01-2008",
            "value": 92.8673,
            "value2": 75.9847
          },
          {
            "category": "09-01-2008",
            "value": 92.8106,
            "value2": 75.8044
          },
          {
            "category": "10-01-2008",
            "value": 90.6921,
            "value2": 73.9696
          },
          {
            "category": "11-01-2008",
            "value": 90.518,
            "value2": 74.4635
          },
          {
            "category": "12-01-2008",
            "value": 90.5191,
            "value2": 74.4635
          },
          {
            "category": "13-01-2008",
            "value": 90.5191,
            "value2": 74.4635
          },
          {
            "category": "14-01-2008",
            "value": 89.9692,
            "value2": 74.8415
          },
          {
            "category": "15-01-2008",
            "value": 88.3242,
            "value2": 73.4683
          },
          {
            "category": "16-01-2008",
            "value": 86.576,
            "value2": 72.0452
          },
          {
            "category": "17-01-2008",
            "value": 86.3626,
            "value2": 71.8777
          },
          {
            "category": "18-01-2008",
            "value": 84.421,
            "value2": 68.8432
          },
          {
            "category": "19-01-2008",
            "value": 84.421,
            "value2": 68.8432
          },
          {
            "category": "20-01-2008",
            "value": 84.421,
            "value2": 68.8432
          },
          {
            "category": "21-01-2008",
            "value": 77.9874,
            "value2": 62.1291
          },
          {
            "category": "22-01-2008",
            "value": 74.7357,
            "value2": 58.1734
          },
          {
            "category": "23-01-2008",
            "value": 79.0568,
            "value2": 62.0229
          },
          {
            "category": "24-01-2008",
            "value": 77.4976,
            "value2": 60.1869
          },
          {
            "category": "25-01-2008",
            "value": 82.2683,
            "value2": 64.3504
          },
          {
            "category": "26-01-2008",
            "value": 82.2683,
            "value2": 64.3504
          },
          {
            "category": "27-01-2008",
            "value": 82.2684,
            "value2": 64.3504
          },
          {
            "category": "28-01-2008",
            "value": 80.1756,
            "value2": 63.7819
          },
          {
            "category": "29-01-2008",
            "value": 79.9023,
            "value2": 63.6677
          },
          {
            "category": "30-01-2008",
            "value": 79.2752,
            "value2": 62.256
          },
          {
            "category": "31-01-2008",
            "value": 78.3442,
            "value2": 61.8251
          },
          {
            "category": "01-02-2008",
            "value": 80.6383,
            "value2": 63.054
          },
          {
            "category": "02-02-2008",
            "value": 80.6383,
            "value2": 63.054
          },
          {
            "category": "03-02-2008",
            "value": 80.6384,
            "value2": 63.054
          },
          {
            "category": "04-02-2008",
            "value": 83.0266,
            "value2": 64.8051
          },
          {
            "category": "05-02-2008",
            "value": 83.3697,
            "value2": 65.0872
          },
          {
            "category": "06-02-2008",
            "value": 81.2211,
            "value2": 63.5529
          },
          {
            "category": "07-02-2008",
            "value": 79.1357,
            "value2": 61.5388
          },
          {
            "category": "08-02-2008",
            "value": 78.6128,
            "value2": 60.8543
          },
          {
            "category": "09-02-2008",
            "value": 78.6128,
            "value2": 60.8543
          },
          {
            "category": "10-02-2008",
            "value": 78.6128,
            "value2": 60.8543
          },
          {
            "category": "11-02-2008",
            "value": 75.5576,
            "value2": 57.6712
          },
          {
            "category": "12-02-2008",
            "value": 75.1824,
            "value2": 57.2453
          },
          {
            "category": "13-02-2008",
            "value": 76.7561,
            "value2": 58.1077
          },
          {
            "category": "14-02-2008",
            "value": 79.8236,
            "value2": 61.1931
          },
          {
            "category": "15-02-2008",
            "value": 81.2332,
            "value2": 62.486
          },
          {
            "category": "16-02-2008",
            "value": 81.2332,
            "value2": 62.486
          },
          {
            "category": "17-02-2008",
            "value": 81.2333,
            "value2": 62.486
          },
          {
            "category": "18-02-2008",
            "value": 80.7574,
            "value2": 62.465
          },
          {
            "category": "19-02-2008",
            "value": 81.1137,
            "value2": 62.5066
          },
          {
            "category": "20-02-2008",
            "value": 79.4428,
            "value2": 61.0849
          },
          {
            "category": "21-02-2008",
            "value": 79.5834,
            "value2": 61.4894
          },
          {
            "category": "22-02-2008",
            "value": 78.378,
            "value2": 60.4215
          },
          {
            "category": "23-02-2008",
            "value": 78.378,
            "value2": 60.4215
          },
          {
            "category": "24-02-2008",
            "value": 78.378,
            "value2": 60.4215
          },
          {
            "category": "25-02-2008",
            "value": 78.988,
            "value2": 61.0753
          },
          {
            "category": "26-02-2008",
            "value": 79.3988,
            "value2": 61.849
          },
          {
            "category": "27-02-2008",
            "value": 79.7559,
            "value2": 61.9819
          },
          {
            "category": "28-02-2008",
            "value": 79.5984,
            "value2": 61.9753
          },
          {
            "category": "29-02-2008",
            "value": 79.1277,
            "value2": 61.3769
          },
          {
            "category": "01-03-2008",
            "value": 79.1277,
            "value2": 61.3769
          },
          {
            "category": "02-03-2008",
            "value": 79.1277,
            "value2": 61.3769
          },
          {
            "category": "03-03-2008",
            "value": 75.5312,
            "value2": 58.3017
          },
          {
            "category": "04-03-2008",
            "value": 74.1959,
            "value2": 56.9673
          },
          {
            "category": "05-03-2008",
            "value": 74.7634,
            "value2": 57.1065
          },
          {
            "category": "06-03-2008",
            "value": 74.7635,
            "value2": 57.1065
          },
          {
            "category": "07-03-2008",
            "value": 72.4658,
            "value2": 54.8734
          },
          {
            "category": "08-03-2008",
            "value": 72.4658,
            "value2": 54.8734
          },
          {
            "category": "09-03-2008",
            "value": 72.4659,
            "value2": 54.8734
          },
          {
            "category": "10-03-2008",
            "value": 73.0096,
            "value2": 54.7595
          },
          {
            "category": "11-03-2008",
            "value": 74.1032,
            "value2": 56.0404
          },
          {
            "category": "12-03-2008",
            "value": 73.53,
            "value2": 55.9479
          },
          {
            "category": "13-03-2008",
            "value": 69.9736,
            "value2": 52.8538
          },
          {
            "category": "14-03-2008",
            "value": 70.5938,
            "value2": 54.0608
          },
          {
            "category": "15-03-2008",
            "value": 70.5938,
            "value2": 54.0608
          },
          {
            "category": "16-03-2008",
            "value": 70.5938,
            "value2": 54.0608
          },
          {
            "category": "17-03-2008",
            "value": 66.6326,
            "value2": 50.5955
          },
          {
            "category": "18-03-2008",
            "value": 66.6203,
            "value2": 50.4043
          },
          {
            "category": "19-03-2008",
            "value": 66.7842,
            "value2": 50.5376
          },
          {
            "category": "20-03-2008",
            "value": 66.7842,
            "value2": 50.5376
          },
          {
            "category": "21-03-2008",
            "value": 66.7842,
            "value2": 50.5376
          },
          {
            "category": "22-03-2008",
            "value": 66.7842,
            "value2": 50.5376
          },
          {
            "category": "23-03-2008",
            "value": 66.7842,
            "value2": 50.5376
          },
          {
            "category": "24-03-2008",
            "value": 67.1695,
            "value2": 50.5973
          },
          {
            "category": "25-03-2008",
            "value": 70.7218,
            "value2": 53.7253
          },
          {
            "category": "26-03-2008",
            "value": 70.6997,
            "value2": 53.7149
          },
          {
            "category": "27-03-2008",
            "value": 69.9594,
            "value2": 53.6332
          },
          {
            "category": "28-03-2008",
            "value": 72.0092,
            "value2": 55.2233
          },
          {
            "category": "29-03-2008",
            "value": 72.0081,
            "value2": 55.2233
          },
          {
            "category": "30-03-2008",
            "value": 72.0081,
            "value2": 55.2233
          },
          {
            "category": "31-03-2008",
            "value": 68.6695,
            "value2": 53.1665
          },
          {
            "category": "01-04-2008",
            "value": 67.8982,
            "value2": 53.0976
          },
          {
            "category": "02-04-2008",
            "value": 68.615,
            "value2": 53.4383
          },
          {
            "category": "03-04-2008",
            "value": 68.7588,
            "value2": 53.5636
          },
          {
            "category": "04-04-2008",
            "value": 67.2269,
            "value2": 52.1983
          },
          {
            "category": "05-04-2008",
            "value": 67.2269,
            "value2": 52.1983
          },
          {
            "category": "06-04-2008",
            "value": 67.2269,
            "value2": 52.1983
          },
          {
            "category": "07-04-2008",
            "value": 68.6235,
            "value2": 53.4314
          },
          {
            "category": "08-04-2008",
            "value": 68.0215,
            "value2": 53.1385
          },
          {
            "category": "09-04-2008",
            "value": 68.7798,
            "value2": 53.8444
          },
          {
            "category": "10-04-2008",
            "value": 68.109,
            "value2": 53.7088
          },
          {
            "category": "11-04-2008",
            "value": 68.4124,
            "value2": 54.1662
          },
          {
            "category": "12-04-2008",
            "value": 68.4662,
            "value2": 54.1662
          },
          {
            "category": "13-04-2008",
            "value": 68.4662,
            "value2": 54.1662
          },
          {
            "category": "14-04-2008",
            "value": 68.4662,
            "value2": 54.1662
          },
          {
            "category": "15-04-2008",
            "value": 69.5826,
            "value2": 55.2398
          },
          {
            "category": "16-04-2008",
            "value": 69.9306,
            "value2": 55.6313
          },
          {
            "category": "17-04-2008",
            "value": 71.1869,
            "value2": 56.6026
          },
          {
            "category": "18-04-2008",
            "value": 71.1869,
            "value2": 56.6026
          },
          {
            "category": "19-04-2008",
            "value": 71.1862,
            "value2": 56.6026
          },
          {
            "category": "20-04-2008",
            "value": 71.1862,
            "value2": 56.6026
          },
          {
            "category": "21-04-2008",
            "value": 72.9862,
            "value2": 57.6274
          },
          {
            "category": "22-04-2008",
            "value": 73.4364,
            "value2": 57.9653
          },
          {
            "category": "23-04-2008",
            "value": 73.3858,
            "value2": 57.761
          },
          {
            "category": "24-04-2008",
            "value": 73.0901,
            "value2": 57.6307
          },
          {
            "category": "25-04-2008",
            "value": 75.0792,
            "value2": 58.675
          },
          {
            "category": "26-04-2008",
            "value": 75.0792,
            "value2": 58.675
          },
          {
            "category": "27-04-2008",
            "value": 75.0792,
            "value2": 58.675
          },
          {
            "category": "28-04-2008",
            "value": 74.8654,
            "value2": 58.5223
          },
          {
            "category": "29-04-2008",
            "value": 76.7843,
            "value2": 59.6686
          },
          {
            "category": "30-04-2008",
            "value": 76.284,
            "value2": 59.4632
          },
          {
            "category": "01-05-2008",
            "value": 76.2765,
            "value2": 59.4632
          },
          {
            "category": "02-05-2008",
            "value": 76.8372,
            "value2": 60.3715
          },
          {
            "category": "03-05-2008",
            "value": 76.8372,
            "value2": 60.3715
          },
          {
            "category": "04-05-2008",
            "value": 76.8372,
            "value2": 60.3715
          },
          {
            "category": "05-05-2008",
            "value": 76.3094,
            "value2": 60.2995
          },
          {
            "category": "06-05-2008",
            "value": 75.4207,
            "value2": 59.7354
          },
          {
            "category": "07-05-2008",
            "value": 75.0029,
            "value2": 59.5716
          },
          {
            "category": "08-05-2008",
            "value": 74.712,
            "value2": 58.8692
          },
          {
            "category": "09-05-2008",
            "value": 73.5379,
            "value2": 57.4927
          },
          {
            "category": "10-05-2008",
            "value": 73.5379,
            "value2": 57.4927
          },
          {
            "category": "11-05-2008",
            "value": 73.5379,
            "value2": 57.4927
          },
          {
            "category": "12-05-2008",
            "value": 73.6928,
            "value2": 57.67
          },
          {
            "category": "13-05-2008",
            "value": 73.1849,
            "value2": 57.3744
          },
          {
            "category": "14-05-2008",
            "value": 74.4605,
            "value2": 58.0289
          },
          {
            "category": "15-05-2008",
            "value": 75.8505,
            "value2": 59.1363
          },
          {
            "category": "16-05-2008",
            "value": 76.4683,
            "value2": 59.5954
          },
          {
            "category": "17-05-2008",
            "value": 76.4683,
            "value2": 59.5954
          },
          {
            "category": "18-05-2008",
            "value": 76.4683,
            "value2": 59.5954
          },
          {
            "category": "19-05-2008",
            "value": 76.4684,
            "value2": 59.5954
          },
          {
            "category": "20-05-2008",
            "value": 75.849,
            "value2": 59.1679
          },
          {
            "category": "21-05-2008",
            "value": 75.6979,
            "value2": 59.3951
          },
          {
            "category": "22-05-2008",
            "value": 74.6026,
            "value2": 58.3042
          },
          {
            "category": "23-05-2008",
            "value": 74.0316,
            "value2": 57.4153
          },
          {
            "category": "24-05-2008",
            "value": 74.0315,
            "value2": 57.4153
          },
          {
            "category": "25-05-2008",
            "value": 74.0316,
            "value2": 57.4153
          },
          {
            "category": "26-05-2008",
            "value": 73.0853,
            "value2": 56.2281
          },
          {
            "category": "27-05-2008",
            "value": 72.3652,
            "value2": 55.8095
          },
          {
            "category": "28-05-2008",
            "value": 73.3309,
            "value2": 56.5533
          },
          {
            "category": "29-05-2008",
            "value": 72.9151,
            "value2": 56.0443
          },
          {
            "category": "30-05-2008",
            "value": 73.7627,
            "value2": 56.025
          },
          {
            "category": "31-05-2008",
            "value": 73.7727,
            "value2": 56.025
          },
          {
            "category": "01-06-2008",
            "value": 73.773,
            "value2": 56.025
          },
          {
            "category": "02-06-2008",
            "value": 72.4887,
            "value2": 54.7119
          },
          {
            "category": "03-06-2008",
            "value": 71.8747,
            "value2": 54.3664
          },
          {
            "category": "04-06-2008",
            "value": 69.5737,
            "value2": 52.6778
          },
          {
            "category": "05-06-2008",
            "value": 70.2476,
            "value2": 53.3871
          },
          {
            "category": "06-06-2008",
            "value": 69.2932,
            "value2": 52.8703
          },
          {
            "category": "07-06-2008",
            "value": 69.293,
            "value2": 52.8703
          },
          {
            "category": "08-06-2008",
            "value": 69.293,
            "value2": 52.8703
          },
          {
            "category": "09-06-2008",
            "value": 67.6535,
            "value2": 51.3044
          },
          {
            "category": "10-06-2008",
            "value": 67.1885,
            "value2": 50.756
          },
          {
            "category": "11-06-2008",
            "value": 68.8325,
            "value2": 51.564
          },
          {
            "category": "12-06-2008",
            "value": 69.1105,
            "value2": 51.8413
          },
          {
            "category": "13-06-2008",
            "value": 68.733,
            "value2": 51.6282
          },
          {
            "category": "14-06-2008",
            "value": 68.733,
            "value2": 51.6282
          },
          {
            "category": "15-06-2008",
            "value": 68.733,
            "value2": 51.6282
          },
          {
            "category": "16-06-2008",
            "value": 69.4682,
            "value2": 52.341
          },
          {
            "category": "17-06-2008",
            "value": 70.7614,
            "value2": 53.3925
          },
          {
            "category": "18-06-2008",
            "value": 69.9569,
            "value2": 52.6173
          },
          {
            "category": "19-06-2008",
            "value": 68.6433,
            "value2": 51.5515
          },
          {
            "category": "20-06-2008",
            "value": 66.3242,
            "value2": 49.7723
          },
          {
            "category": "21-06-2008",
            "value": 66.3242,
            "value2": 49.7723
          },
          {
            "category": "22-06-2008",
            "value": 66.3242,
            "value2": 49.7723
          },
          {
            "category": "23-06-2008",
            "value": 65.0295,
            "value2": 48.3866
          },
          {
            "category": "24-06-2008",
            "value": 63.9451,
            "value2": 47.5591
          },
          {
            "category": "25-06-2008",
            "value": 64.489,
            "value2": 48.0022
          },
          {
            "category": "26-06-2008",
            "value": 65.0861,
            "value2": 48.4194
          },
          {
            "category": "27-06-2008",
            "value": 62.6468,
            "value2": 46.5093
          },
          {
            "category": "28-06-2008",
            "value": 62.6468,
            "value2": 46.5093
          },
          {
            "category": "29-06-2008",
            "value": 62.6468,
            "value2": 46.5093
          },
          {
            "category": "30-06-2008",
            "value": 61.1368,
            "value2": 45.1938
          },
          {
            "category": "01-07-2008",
            "value": 58.3862,
            "value2": 43.3521
          },
          {
            "category": "02-07-2008",
            "value": 61.3874,
            "value2": 45.4488
          },
          {
            "category": "03-07-2008",
            "value": 59.3252,
            "value2": 43.5842
          },
          {
            "category": "04-07-2008",
            "value": 60.573,
            "value2": 44.7473
          },
          {
            "category": "05-07-2008",
            "value": 60.573,
            "value2": 44.7473
          },
          {
            "category": "06-07-2008",
            "value": 60.573,
            "value2": 44.7473
          },
          {
            "category": "07-07-2008",
            "value": 61.171,
            "value2": 45.1151
          },
          {
            "category": "08-07-2008",
            "value": 61.0028,
            "value2": 44.7299
          },
          {
            "category": "09-07-2008",
            "value": 63.4595,
            "value2": 46.6292
          },
          {
            "category": "10-07-2008",
            "value": 63.3398,
            "value2": 46.6645
          },
          {
            "category": "11-07-2008",
            "value": 61.4868,
            "value2": 45.3577
          },
          {
            "category": "12-07-2008",
            "value": 61.4868,
            "value2": 45.3577
          },
          {
            "category": "13-07-2008",
            "value": 61.4868,
            "value2": 45.3577
          },
          {
            "category": "14-07-2008",
            "value": 61.3373,
            "value2": 45.0153
          },
          {
            "category": "15-07-2008",
            "value": 58.9081,
            "value2": 43.0184
          },
          {
            "category": "16-07-2008",
            "value": 58.3791,
            "value2": 42.5165
          },
          {
            "category": "17-07-2008",
            "value": 60.4724,
            "value2": 43.832
          },
          {
            "category": "18-07-2008",
            "value": 62.6752,
            "value2": 45.2381
          },
          {
            "category": "19-07-2008",
            "value": 62.6752,
            "value2": 45.2381
          },
          {
            "category": "20-07-2008",
            "value": 62.6752,
            "value2": 45.2381
          },
          {
            "category": "21-07-2008",
            "value": 63.2876,
            "value2": 45.7273
          },
          {
            "category": "22-07-2008",
            "value": 63.8442,
            "value2": 46.7057
          },
          {
            "category": "23-07-2008",
            "value": 67.5049,
            "value2": 49.4892
          },
          {
            "category": "24-07-2008",
            "value": 66.8148,
            "value2": 49.0474
          },
          {
            "category": "25-07-2008",
            "value": 65.2502,
            "value2": 47.8927
          },
          {
            "category": "26-07-2008",
            "value": 65.2502,
            "value2": 47.8927
          },
          {
            "category": "27-07-2008",
            "value": 65.2502,
            "value2": 47.8927
          },
          {
            "category": "28-07-2008",
            "value": 65.6177,
            "value2": 48.1818
          },
          {
            "category": "29-07-2008",
            "value": 62.6622,
            "value2": 46.4513
          },
          {
            "category": "30-07-2008",
            "value": 65.0517,
            "value2": 47.9479
          },
          {
            "category": "31-07-2008",
            "value": 65.0851,
            "value2": 48.0574
          },
          {
            "category": "01-08-2008",
            "value": 66.9126,
            "value2": 49.0782
          },
          {
            "category": "02-08-2008",
            "value": 66.9127,
            "value2": 49.0782
          },
          {
            "category": "03-08-2008",
            "value": 66.9125,
            "value2": 49.0782
          },
          {
            "category": "04-08-2008",
            "value": 66.9879,
            "value2": 49.3351
          },
          {
            "category": "05-08-2008",
            "value": 68.4798,
            "value2": 50.4894
          },
          {
            "category": "06-08-2008",
            "value": 68.9187,
            "value2": 50.5628
          },
          {
            "category": "07-08-2008",
            "value": 69.2121,
            "value2": 50.7166
          },
          {
            "category": "08-08-2008",
            "value": 69.2108,
            "value2": 50.8551
          },
          {
            "category": "09-08-2008",
            "value": 69.2108,
            "value2": 50.8551
          },
          {
            "category": "10-08-2008",
            "value": 69.2108,
            "value2": 50.8551
          },
          {
            "category": "11-08-2008",
            "value": 70.2916,
            "value2": 51.9004
          },
          {
            "category": "12-08-2008",
            "value": 68.8099,
            "value2": 51.0997
          },
          {
            "category": "13-08-2008",
            "value": 68.426,
            "value2": 50.856
          },
          {
            "category": "14-08-2008",
            "value": 67.3903,
            "value2": 49.6547
          },
          {
            "category": "15-08-2008",
            "value": 67.3904,
            "value2": 49.6547
          },
          {
            "category": "16-08-2008",
            "value": 67.3904,
            "value2": 49.6547
          },
          {
            "category": "17-08-2008",
            "value": 67.3911,
            "value2": 49.6547
          },
          {
            "category": "18-08-2008",
            "value": 67.1679,
            "value2": 49.255
          },
          {
            "category": "19-08-2008",
            "value": 66.5262,
            "value2": 48.9823
          },
          {
            "category": "20-08-2008",
            "value": 67.1805,
            "value2": 49.4766
          },
          {
            "category": "21-08-2008",
            "value": 65.249,
            "value2": 48.129
          },
          {
            "category": "22-08-2008",
            "value": 65.729,
            "value2": 48.5091
          },
          {
            "category": "23-08-2008",
            "value": 65.729,
            "value2": 48.5091
          },
          {
            "category": "24-08-2008",
            "value": 65.7291,
            "value2": 48.5091
          },
          {
            "category": "25-08-2008",
            "value": 65.9845,
            "value2": 48.6577
          },
          {
            "category": "26-08-2008",
            "value": 66.1973,
            "value2": 48.7422
          },
          {
            "category": "27-08-2008",
            "value": 65.811,
            "value2": 48.2416
          },
          {
            "category": "28-08-2008",
            "value": 64.9727,
            "value2": 47.5227
          },
          {
            "category": "29-08-2008",
            "value": 67.2289,
            "value2": 49.0685
          },
          {
            "category": "30-08-2008",
            "value": 67.2287,
            "value2": 49.0685
          },
          {
            "category": "31-08-2008",
            "value": 67.2287,
            "value2": 49.0685
          },
          {
            "category": "01-09-2008",
            "value": 66.7781,
            "value2": 48.9479
          },
          {
            "category": "02-09-2008",
            "value": 68.8413,
            "value2": 50.4776
          },
          {
            "category": "03-09-2008",
            "value": 68.8414,
            "value2": 50.4776
          },
          {
            "category": "04-09-2008",
            "value": 68.4832,
            "value2": 50.0833
          },
          {
            "category": "05-09-2008",
            "value": 67.0501,
            "value2": 48.9359
          },
          {
            "category": "06-09-2008",
            "value": 67.0499,
            "value2": 48.9359
          },
          {
            "category": "07-09-2008",
            "value": 67.05,
            "value2": 48.9359
          },
          {
            "category": "08-09-2008",
            "value": 68.3617,
            "value2": 50.1473
          },
          {
            "category": "09-09-2008",
            "value": 68.3229,
            "value2": 49.9267
          },
          {
            "category": "10-09-2008",
            "value": 67.432,
            "value2": 49.2648
          },
          {
            "category": "11-09-2008",
            "value": 66.003,
            "value2": 48.3035
          },
          {
            "category": "12-09-2008",
            "value": 65.0307,
            "value2": 47.3499
          },
          {
            "category": "13-09-2008",
            "value": 65.0308,
            "value2": 47.3499
          },
          {
            "category": "14-09-2008",
            "value": 65.0308,
            "value2": 47.3499
          },
          {
            "category": "15-09-2008",
            "value": 63.504,
            "value2": 45.5597
          },
          {
            "category": "16-09-2008",
            "value": 63.8844,
            "value2": 45.3811
          },
          {
            "category": "17-09-2008",
            "value": 62.91,
            "value2": 44.4753
          },
          {
            "category": "18-09-2008",
            "value": 63.0143,
            "value2": 44.4758
          },
          {
            "category": "19-09-2008",
            "value": 65.3014,
            "value2": 46.5413
          },
          {
            "category": "20-09-2008",
            "value": 65.3014,
            "value2": 46.5413
          },
          {
            "category": "21-09-2008",
            "value": 65.3014,
            "value2": 46.5413
          },
          {
            "category": "22-09-2008",
            "value": 65.0532,
            "value2": 46.3871
          },
          {
            "category": "23-09-2008",
            "value": 63.3895,
            "value2": 45.2778
          },
          {
            "category": "24-09-2008",
            "value": 64.1872,
            "value2": 45.5888
          },
          {
            "category": "25-09-2008",
            "value": 63.7292,
            "value2": 45.1391
          },
          {
            "category": "26-09-2008",
            "value": 62.0112,
            "value2": 43.7084
          },
          {
            "category": "27-09-2008",
            "value": 62.0115,
            "value2": 43.7084
          },
          {
            "category": "28-09-2008",
            "value": 62.0111,
            "value2": 43.7084
          },
          {
            "category": "29-09-2008",
            "value": 60.2888,
            "value2": 41.9575
          },
          {
            "category": "30-09-2008",
            "value": 61.6727,
            "value2": 42.7212
          },
          {
            "category": "01-10-2008",
            "value": 62.1715,
            "value2": 43.21
          },
          {
            "category": "02-10-2008",
            "value": 62.1716,
            "value2": 43.21
          },
          {
            "category": "03-10-2008",
            "value": 60.1749,
            "value2": 41.6175
          },
          {
            "category": "04-10-2008",
            "value": 60.175,
            "value2": 41.6175
          },
          {
            "category": "05-10-2008",
            "value": 60.175,
            "value2": 41.6175
          },
          {
            "category": "06-10-2008",
            "value": 57.3166,
            "value2": 39.0635
          },
          {
            "category": "07-10-2008",
            "value": 56.9942,
            "value2": 38.5835
          },
          {
            "category": "08-10-2008",
            "value": 55.0162,
            "value2": 37.0943
          },
          {
            "category": "09-10-2008",
            "value": 55.0163,
            "value2": 37.0943
          },
          {
            "category": "10-10-2008",
            "value": 52.3232,
            "value2": 34.323
          },
          {
            "category": "11-10-2008",
            "value": 52.3232,
            "value2": 34.323
          },
          {
            "category": "12-10-2008",
            "value": 52.3233,
            "value2": 34.323
          },
          {
            "category": "13-10-2008",
            "value": 55.0588,
            "value2": 36.5692
          },
          {
            "category": "14-10-2008",
            "value": 55.8583,
            "value2": 37.1142
          },
          {
            "category": "15-10-2008",
            "value": 53.7136,
            "value2": 35.1957
          },
          {
            "category": "16-10-2008",
            "value": 52.982,
            "value2": 34.606
          },
          {
            "category": "17-10-2008",
            "value": 50.8736,
            "value2": 32.9133
          },
          {
            "category": "18-10-2008",
            "value": 50.8737,
            "value2": 32.9133
          },
          {
            "category": "19-10-2008",
            "value": 50.8737,
            "value2": 32.9133
          },
          {
            "category": "20-10-2008",
            "value": 52.0652,
            "value2": 33.3909
          },
          {
            "category": "21-10-2008",
            "value": 53.5119,
            "value2": 34.6195
          },
          {
            "category": "22-10-2008",
            "value": 51.2183,
            "value2": 33.0794
          },
          {
            "category": "23-10-2008",
            "value": 49.1899,
            "value2": 31.7687
          },
          {
            "category": "24-10-2008",
            "value": 44.5681,
            "value2": 28.4323
          },
          {
            "category": "25-10-2008",
            "value": 44.5682,
            "value2": 28.4323
          },
          {
            "category": "26-10-2008",
            "value": 44.5682,
            "value2": 28.4323
          },
          {
            "category": "27-10-2008",
            "value": 43.1699,
            "value2": 27.6001
          },
          {
            "category": "28-10-2008",
            "value": 45.3004,
            "value2": 29.2836
          },
          {
            "category": "29-10-2008",
            "value": 45.2993,
            "value2": 29.2509
          },
          {
            "category": "30-10-2008",
            "value": 45.2994,
            "value2": 29.2509
          },
          {
            "category": "31-10-2008",
            "value": 48.0715,
            "value2": 31.1685
          },
          {
            "category": "01-11-2008",
            "value": 48.073,
            "value2": 31.1685
          },
          {
            "category": "02-11-2008",
            "value": 48.0731,
            "value2": 31.1685
          },
          {
            "category": "03-11-2008",
            "value": 50.2856,
            "value2": 32.8089
          },
          {
            "category": "04-11-2008",
            "value": 51.4996,
            "value2": 33.8706
          },
          {
            "category": "05-11-2008",
            "value": 49.7062,
            "value2": 32.5705
          },
          {
            "category": "06-11-2008",
            "value": 47.8943,
            "value2": 31.5224
          },
          {
            "category": "07-11-2008",
            "value": 49.0546,
            "value2": 32.2178
          },
          {
            "category": "08-11-2008",
            "value": 49.0549,
            "value2": 32.2178
          },
          {
            "category": "09-11-2008",
            "value": 49.0552,
            "value2": 32.2178
          },
          {
            "category": "10-11-2008",
            "value": 50.9113,
            "value2": 33.8429
          },
          {
            "category": "11-11-2008",
            "value": 48.6518,
            "value2": 31.9192
          },
          {
            "category": "12-11-2008",
            "value": 47.8695,
            "value2": 31.0358
          },
          {
            "category": "13-11-2008",
            "value": 47.8697,
            "value2": 31.0358
          },
          {
            "category": "14-11-2008",
            "value": 47.1217,
            "value2": 30.5113
          },
          {
            "category": "15-11-2008",
            "value": 47.1219,
            "value2": 30.5113
          },
          {
            "category": "16-11-2008",
            "value": 47.1221,
            "value2": 30.5113
          },
          {
            "category": "17-11-2008",
            "value": 46.6858,
            "value2": 30.0013
          },
          {
            "category": "18-11-2008",
            "value": 45.5751,
            "value2": 28.9705
          },
          {
            "category": "19-11-2008",
            "value": 45.0758,
            "value2": 28.4417
          },
          {
            "category": "20-11-2008",
            "value": 43.4658,
            "value2": 27.4455
          },
          {
            "category": "21-11-2008",
            "value": 44.9865,
            "value2": 28.4861
          },
          {
            "category": "22-11-2008",
            "value": 44.9867,
            "value2": 28.4861
          },
          {
            "category": "23-11-2008",
            "value": 44.9869,
            "value2": 28.4861
          },
          {
            "category": "24-11-2008",
            "value": 45.1797,
            "value2": 28.4593
          },
          {
            "category": "25-11-2008",
            "value": 44.7712,
            "value2": 27.9366
          },
          {
            "category": "26-11-2008",
            "value": 45.858,
            "value2": 28.653
          },
          {
            "category": "27-11-2008",
            "value": 45.8582,
            "value2": 28.653
          },
          {
            "category": "28-11-2008",
            "value": 46.4116,
            "value2": 28.7768
          },
          {
            "category": "29-11-2008",
            "value": 46.4119,
            "value2": 28.7768
          },
          {
            "category": "30-11-2008",
            "value": 46.4121,
            "value2": 28.7768
          },
          {
            "category": "01-12-2008",
            "value": 45.5391,
            "value2": 28.1447
          },
          {
            "category": "02-12-2008",
            "value": 45.2006,
            "value2": 27.8574
          },
          {
            "category": "03-12-2008",
            "value": 45.3813,
            "value2": 27.9804
          },
          {
            "category": "04-12-2008",
            "value": 46.9431,
            "value2": 29.284
          },
          {
            "category": "05-12-2008",
            "value": 45.9921,
            "value2": 28.6303
          },
          {
            "category": "06-12-2008",
            "value": 45.9924,
            "value2": 28.6303
          },
          {
            "category": "07-12-2008",
            "value": 45.9927,
            "value2": 28.6303
          },
          {
            "category": "08-12-2008",
            "value": 47.0842,
            "value2": 29.0974
          },
          {
            "category": "09-12-2008",
            "value": 47.0845,
            "value2": 29.0974
          },
          {
            "category": "10-12-2008",
            "value": 48.4224,
            "value2": 30.3841
          },
          {
            "category": "11-12-2008",
            "value": 48.3193,
            "value2": 30.5112
          },
          {
            "category": "12-12-2008",
            "value": 48.155,
            "value2": 30.7068
          },
          {
            "category": "13-12-2008",
            "value": 48.1554,
            "value2": 30.7068
          },
          {
            "category": "14-12-2008",
            "value": 48.1556,
            "value2": 30.7068
          },
          {
            "category": "15-12-2008",
            "value": 48.6783,
            "value2": 31.3993
          },
          {
            "category": "16-12-2008",
            "value": 49.1926,
            "value2": 31.9979
          },
          {
            "category": "17-12-2008",
            "value": 48.6582,
            "value2": 31.0983
          },
          {
            "category": "18-12-2008",
            "value": 49.9271,
            "value2": 32.1776
          },
          {
            "category": "19-12-2008",
            "value": 50.3263,
            "value2": 32.4339
          },
          {
            "category": "20-12-2008",
            "value": 50.3266,
            "value2": 32.4339
          },
          {
            "category": "21-12-2008",
            "value": 50.3269,
            "value2": 32.4339
          },
          {
            "category": "22-12-2008",
            "value": 49.6649,
            "value2": 32.0473
          },
          {
            "category": "23-12-2008",
            "value": 49.0461,
            "value2": 31.2534
          },
          {
            "category": "24-12-2008",
            "value": 48.847,
            "value2": 30.9357
          },
          {
            "category": "25-12-2008",
            "value": 48.8363,
            "value2": 30.9357
          },
          {
            "category": "26-12-2008",
            "value": 48.0165,
            "value2": 30.3182
          },
          {
            "category": "27-12-2008",
            "value": 48.0168,
            "value2": 30.3182
          },
          {
            "category": "28-12-2008",
            "value": 48.0171,
            "value2": 30.3182
          },
          {
            "category": "29-12-2008",
            "value": 48.8675,
            "value2": 30.9475
          },
          {
            "category": "30-12-2008",
            "value": 49.6284,
            "value2": 31.5343
          },
          {
            "category": "31-12-2008",
            "value": 49.5289,
            "value2": 31.4315
          },
          {
            "category": "01-01-2009",
            "value": 50.0367,
            "value2": 32.2608
          },
          {
            "category": "02-01-2009",
            "value": 50.0634,
            "value2": 32.5547
          },
          {
            "category": "03-01-2009",
            "value": 50.0637,
            "value2": 32.5547
          },
          {
            "category": "04-01-2009",
            "value": 50.0639,
            "value2": 32.5547
          },
          {
            "category": "05-01-2009",
            "value": 50.8216,
            "value2": 33.4143
          },
          {
            "category": "06-01-2009",
            "value": 50.9424,
            "value2": 33.544
          },
          {
            "category": "07-01-2009",
            "value": 49.1589,
            "value2": 31.1834
          },
          {
            "category": "08-01-2009",
            "value": 49.1591,
            "value2": 31.1834
          },
          {
            "category": "09-01-2009",
            "value": 49.0081,
            "value2": 30.5171
          },
          {
            "category": "10-01-2009",
            "value": 49.0084,
            "value2": 30.5171
          },
          {
            "category": "11-01-2009",
            "value": 49.0086,
            "value2": 30.5171
          },
          {
            "category": "12-01-2009",
            "value": 47.7268,
            "value2": 29.6468
          },
          {
            "category": "13-01-2009",
            "value": 48.1092,
            "value2": 29.3791
          },
          {
            "category": "14-01-2009",
            "value": 49.011,
            "value2": 30.2346
          },
          {
            "category": "15-01-2009",
            "value": 47.671,
            "value2": 29.3153
          },
          {
            "category": "16-01-2009",
            "value": 48.6353,
            "value2": 29.9892
          },
          {
            "category": "17-01-2009",
            "value": 48.6353,
            "value2": 29.9892
          },
          {
            "category": "18-01-2009",
            "value": 48.6356,
            "value2": 29.9892
          },
          {
            "category": "19-01-2009",
            "value": 48.8247,
            "value2": 30.0879
          },
          {
            "category": "20-01-2009",
            "value": 47.8958,
            "value2": 29.5116
          },
          {
            "category": "21-01-2009",
            "value": 46.7073,
            "value2": 28.6279
          },
          {
            "category": "22-01-2009",
            "value": 46.9685,
            "value2": 28.506
          },
          {
            "category": "23-01-2009",
            "value": 46.1499,
            "value2": 28.0052
          },
          {
            "category": "24-01-2009",
            "value": 46.1502,
            "value2": 28.0052
          },
          {
            "category": "25-01-2009",
            "value": 46.1504,
            "value2": 28.0052
          },
          {
            "category": "26-01-2009",
            "value": 46.1507,
            "value2": 28.0052
          },
          {
            "category": "27-01-2009",
            "value": 47.0788,
            "value2": 28.7751
          },
          {
            "category": "28-01-2009",
            "value": 47.9463,
            "value2": 29.5468
          },
          {
            "category": "29-01-2009",
            "value": 48.1777,
            "value2": 29.4153
          },
          {
            "category": "30-01-2009",
            "value": 48.8714,
            "value2": 29.9712
          },
          {
            "category": "31-01-2009",
            "value": 48.8717,
            "value2": 29.9712
          },
          {
            "category": "01-02-2009",
            "value": 48.8701,
            "value2": 29.9712
          },
          {
            "category": "02-02-2009",
            "value": 47.6059,
            "value2": 29.0299
          },
          {
            "category": "03-02-2009",
            "value": 47.7798,
            "value2": 29.1218
          },
          {
            "category": "04-02-2009",
            "value": 47.8603,
            "value2": 29.2224
          },
          {
            "category": "05-02-2009",
            "value": 47.3217,
            "value2": 28.9615
          },
          {
            "category": "06-02-2009",
            "value": 48.1779,
            "value2": 29.5524
          },
          {
            "category": "07-02-2009",
            "value": 48.1782,
            "value2": 29.5524
          },
          {
            "category": "08-02-2009",
            "value": 48.1784,
            "value2": 29.5524
          },
          {
            "category": "09-02-2009",
            "value": 49.1259,
            "value2": 30.3231
          },
          {
            "category": "10-02-2009",
            "value": 49.4838,
            "value2": 30.5317
          },
          {
            "category": "11-02-2009",
            "value": 49.6326,
            "value2": 30.501
          },
          {
            "category": "12-02-2009",
            "value": 49.1067,
            "value2": 30.1978
          },
          {
            "category": "13-02-2009",
            "value": 49.686,
            "value2": 30.6979
          },
          {
            "category": "14-02-2009",
            "value": 49.6862,
            "value2": 30.6979
          },
          {
            "category": "15-02-2009",
            "value": 49.6865,
            "value2": 30.6979
          },
          {
            "category": "16-02-2009",
            "value": 48.6494,
            "value2": 29.6919
          },
          {
            "category": "17-02-2009",
            "value": 47.652,
            "value2": 28.8896
          },
          {
            "category": "18-02-2009",
            "value": 47.5798,
            "value2": 28.8336
          },
          {
            "category": "19-02-2009",
            "value": 47.8261,
            "value2": 28.8814
          },
          {
            "category": "20-02-2009",
            "value": 47.0546,
            "value2": 28.2967
          },
          {
            "category": "21-02-2009",
            "value": 47.0556,
            "value2": 28.2967
          },
          {
            "category": "22-02-2009",
            "value": 47.0558,
            "value2": 28.2967
          },
          {
            "category": "23-02-2009",
            "value": 47.0561,
            "value2": 28.2967
          },
          {
            "category": "24-02-2009",
            "value": 46.4703,
            "value2": 28.1014
          },
          {
            "category": "25-02-2009",
            "value": 46.8041,
            "value2": 28.3096
          },
          {
            "category": "26-02-2009",
            "value": 47.0142,
            "value2": 28.3925
          },
          {
            "category": "27-02-2009",
            "value": 47.2254,
            "value2": 28.2825
          },
          {
            "category": "28-02-2009",
            "value": 47.2256,
            "value2": 28.2825
          },
          {
            "category": "01-03-2009",
            "value": 47.2258,
            "value2": 28.2825
          },
          {
            "category": "02-03-2009",
            "value": 46.0869,
            "value2": 27.4686
          },
          {
            "category": "03-03-2009",
            "value": 45.5444,
            "value2": 26.9666
          },
          {
            "category": "04-03-2009",
            "value": 45.7869,
            "value2": 27.0365
          },
          {
            "category": "05-03-2009",
            "value": 45.1527,
            "value2": 26.3523
          },
          {
            "category": "06-03-2009",
            "value": 45.5122,
            "value2": 26.5826
          },
          {
            "category": "07-03-2009",
            "value": 45.5124,
            "value2": 26.5826
          },
          {
            "category": "08-03-2009",
            "value": 45.5126,
            "value2": 26.5826
          },
          {
            "category": "09-03-2009",
            "value": 44.8373,
            "value2": 26.1036
          },
          {
            "category": "10-03-2009",
            "value": 44.8375,
            "value2": 26.1036
          },
          {
            "category": "11-03-2009",
            "value": 44.8377,
            "value2": 26.1036
          },
          {
            "category": "12-03-2009",
            "value": 44.8528,
            "value2": 26.5609
          },
          {
            "category": "13-03-2009",
            "value": 46.0118,
            "value2": 27.6664
          },
          {
            "category": "14-03-2009",
            "value": 46.012,
            "value2": 27.6664
          },
          {
            "category": "15-03-2009",
            "value": 46.0123,
            "value2": 27.6664
          },
          {
            "category": "16-03-2009",
            "value": 46.4851,
            "value2": 28.3148
          },
          {
            "category": "17-03-2009",
            "value": 45.9995,
            "value2": 28.127
          },
          {
            "category": "18-03-2009",
            "value": 46.2445,
            "value2": 28.5343
          },
          {
            "category": "19-03-2009",
            "value": 46.2119,
            "value2": 28.6318
          },
          {
            "category": "20-03-2009",
            "value": 46.1625,
            "value2": 28.5319
          },
          {
            "category": "21-03-2009",
            "value": 46.1628,
            "value2": 28.5319
          },
          {
            "category": "22-03-2009",
            "value": 46.163,
            "value2": 28.5319
          },
          {
            "category": "23-03-2009",
            "value": 47.6743,
            "value2": 29.7816
          },
          {
            "category": "24-03-2009",
            "value": 48.0862,
            "value2": 29.8016
          },
          {
            "category": "25-03-2009",
            "value": 48.5434,
            "value2": 30.3452
          },
          {
            "category": "26-03-2009",
            "value": 49.973,
            "value2": 31.1841
          },
          {
            "category": "27-03-2009",
            "value": 50.4639,
            "value2": 31.5661
          },
          {
            "category": "28-03-2009",
            "value": 50.4642,
            "value2": 31.5661
          },
          {
            "category": "29-03-2009",
            "value": 50.4644,
            "value2": 31.5661
          },
          {
            "category": "30-03-2009",
            "value": 48.7335,
            "value2": 30.3119
          },
          {
            "category": "31-03-2009",
            "value": 49.4133,
            "value2": 30.8472
          },
          {
            "category": "01-04-2009",
            "value": 49.8913,
            "value2": 31.3874
          },
          {
            "category": "02-04-2009",
            "value": 51.4672,
            "value2": 32.7221
          },
          {
            "category": "03-04-2009",
            "value": 51.4673,
            "value2": 32.7221
          },
          {
            "category": "04-04-2009",
            "value": 51.4674,
            "value2": 32.7221
          },
          {
            "category": "05-04-2009",
            "value": 51.4675,
            "value2": 32.7221
          },
          {
            "category": "06-04-2009",
            "value": 52.5326,
            "value2": 33.3559
          },
          {
            "category": "07-04-2009",
            "value": 52.5328,
            "value2": 33.3559
          },
          {
            "category": "08-04-2009",
            "value": 53.1019,
            "value2": 34.1536
          },
          {
            "category": "09-04-2009",
            "value": 53.5305,
            "value2": 34.4211
          },
          {
            "category": "10-04-2009",
            "value": 53.5306,
            "value2": 34.4211
          },
          {
            "category": "11-04-2009",
            "value": 53.5306,
            "value2": 34.4211
          },
          {
            "category": "12-04-2009",
            "value": 53.5307,
            "value2": 34.4211
          },
          {
            "category": "13-04-2009",
            "value": 54.4047,
            "value2": 35.0949
          },
          {
            "category": "14-04-2009",
            "value": 54.4048,
            "value2": 35.0949
          },
          {
            "category": "15-04-2009",
            "value": 55.3178,
            "value2": 36.3015
          },
          {
            "category": "16-04-2009",
            "value": 54.0473,
            "value2": 35.0254
          },
          {
            "category": "17-04-2009",
            "value": 55.0124,
            "value2": 35.3532
          },
          {
            "category": "18-04-2009",
            "value": 55.0133,
            "value2": 35.3532
          },
          {
            "category": "19-04-2009",
            "value": 55.0134,
            "value2": 35.3532
          },
          {
            "category": "20-04-2009",
            "value": 55.0719,
            "value2": 35.5116
          },
          {
            "category": "21-04-2009",
            "value": 54.922,
            "value2": 35.2884
          },
          {
            "category": "22-04-2009",
            "value": 54.489,
            "value2": 34.9567
          },
          {
            "category": "23-04-2009",
            "value": 55.6112,
            "value2": 35.813
          },
          {
            "category": "24-04-2009",
            "value": 56.4822,
            "value2": 36.4373
          },
          {
            "category": "25-04-2009",
            "value": 56.4822,
            "value2": 36.4373
          },
          {
            "category": "26-04-2009",
            "value": 56.4822,
            "value2": 36.4373
          },
          {
            "category": "27-04-2009",
            "value": 56.179,
            "value2": 36.4086
          },
          {
            "category": "28-04-2009",
            "value": 55.0937,
            "value2": 35.1938
          },
          {
            "category": "29-04-2009",
            "value": 56.5494,
            "value2": 36.2568
          },
          {
            "category": "30-04-2009",
            "value": 56.5494,
            "value2": 36.2568
          },
          {
            "category": "01-05-2009",
            "value": 56.5398,
            "value2": 36.2568
          },
          {
            "category": "02-05-2009",
            "value": 56.5398,
            "value2": 36.2568
          },
          {
            "category": "03-05-2009",
            "value": 56.5398,
            "value2": 36.2568
          },
          {
            "category": "04-05-2009",
            "value": 58.9992,
            "value2": 38.2381
          },
          {
            "category": "05-05-2009",
            "value": 58.6301,
            "value2": 38.472
          },
          {
            "category": "06-05-2009",
            "value": 58.1152,
            "value2": 37.9468
          },
          {
            "category": "07-05-2009",
            "value": 59.2075,
            "value2": 38.6224
          },
          {
            "category": "08-05-2009",
            "value": 58.2574,
            "value2": 38.0652
          },
          {
            "category": "09-05-2009",
            "value": 58.2578,
            "value2": 38.0652
          },
          {
            "category": "10-05-2009",
            "value": 58.2578,
            "value2": 38.0652
          },
          {
            "category": "11-05-2009",
            "value": 57.4052,
            "value2": 37.4524
          },
          {
            "category": "12-05-2009",
            "value": 58.828,
            "value2": 38.6533
          },
          {
            "category": "13-05-2009",
            "value": 58.5793,
            "value2": 38.3633
          },
          {
            "category": "14-05-2009",
            "value": 58.3591,
            "value2": 38.1636
          },
          {
            "category": "15-05-2009",
            "value": 59.7209,
            "value2": 38.931
          },
          {
            "category": "16-05-2009",
            "value": 59.7209,
            "value2": 38.931
          },
          {
            "category": "17-05-2009",
            "value": 59.721,
            "value2": 38.931
          },
          {
            "category": "18-05-2009",
            "value": 70.0279,
            "value2": 45.059
          },
          {
            "category": "19-05-2009",
            "value": 68.331,
            "value2": 45.5794
          },
          {
            "category": "20-05-2009",
            "value": 68.3643,
            "value2": 45.9048
          },
          {
            "category": "21-05-2009",
            "value": 67.7174,
            "value2": 45.2981
          },
          {
            "category": "22-05-2009",
            "value": 68.409,
            "value2": 45.8637
          },
          {
            "category": "23-05-2009",
            "value": 68.409,
            "value2": 45.8637
          },
          {
            "category": "24-05-2009",
            "value": 68.409,
            "value2": 45.8637
          },
          {
            "category": "25-05-2009",
            "value": 68.6395,
            "value2": 46.3405
          },
          {
            "category": "26-05-2009",
            "value": 67.7386,
            "value2": 45.099
          },
          {
            "category": "27-05-2009",
            "value": 69.5803,
            "value2": 46.8268
          },
          {
            "category": "28-05-2009",
            "value": 69.9455,
            "value2": 47.2545
          },
          {
            "category": "29-05-2009",
            "value": 71.6775,
            "value2": 48.3568
          },
          {
            "category": "30-05-2009",
            "value": 71.6776,
            "value2": 48.3568
          },
          {
            "category": "31-05-2009",
            "value": 71.6776,
            "value2": 48.3568
          },
          {
            "category": "01-06-2009",
            "value": 72.3052,
            "value2": 49.3298
          },
          {
            "category": "02-06-2009",
            "value": 72.8371,
            "value2": 49.4048
          },
          {
            "category": "03-06-2009",
            "value": 72.4096,
            "value2": 49.6837
          },
          {
            "category": "04-06-2009",
            "value": 72.8664,
            "value2": 50.3944
          },
          {
            "category": "05-06-2009",
            "value": 74.1149,
            "value2": 50.5445
          },
          {
            "category": "06-06-2009",
            "value": 74.1149,
            "value2": 50.5445
          },
          {
            "category": "07-06-2009",
            "value": 74.1149,
            "value2": 50.5445
          },
          {
            "category": "08-06-2009",
            "value": 72.2173,
            "value2": 48.6364
          },
          {
            "category": "09-06-2009",
            "value": 73.4179,
            "value2": 50.1188
          },
          {
            "category": "10-06-2009",
            "value": 74.6638,
            "value2": 51.1414
          },
          {
            "category": "11-06-2009",
            "value": 74.6554,
            "value2": 50.9698
          },
          {
            "category": "12-06-2009",
            "value": 73.7868,
            "value2": 50.3043
          },
          {
            "category": "13-06-2009",
            "value": 73.7868,
            "value2": 50.3043
          },
          {
            "category": "14-06-2009",
            "value": 73.7868,
            "value2": 50.3043
          },
          {
            "category": "15-06-2009",
            "value": 72.8539,
            "value2": 49.3009
          },
          {
            "category": "16-06-2009",
            "value": 73.7296,
            "value2": 49.9098
          },
          {
            "category": "17-06-2009",
            "value": 71.9495,
            "value2": 48.2336
          },
          {
            "category": "18-06-2009",
            "value": 71.4375,
            "value2": 47.183
          },
          {
            "category": "19-06-2009",
            "value": 72.6084,
            "value2": 48.0009
          },
          {
            "category": "20-06-2009",
            "value": 72.6084,
            "value2": 48.0009
          },
          {
            "category": "21-06-2009",
            "value": 72.6084,
            "value2": 48.0009
          },
          {
            "category": "22-06-2009",
            "value": 72.1285,
            "value2": 47.417
          },
          {
            "category": "23-06-2009",
            "value": 71.6641,
            "value2": 47.4085
          },
          {
            "category": "24-06-2009",
            "value": 71.9212,
            "value2": 47.9983
          },
          {
            "category": "25-06-2009",
            "value": 71.5169,
            "value2": 47.7574
          },
          {
            "category": "26-06-2009",
            "value": 72.8239,
            "value2": 49.0656
          },
          {
            "category": "27-06-2009",
            "value": 72.8239,
            "value2": 49.0656
          },
          {
            "category": "28-06-2009",
            "value": 72.8239,
            "value2": 49.0656
          },
          {
            "category": "29-06-2009",
            "value": 73.2073,
            "value2": 49.4615
          },
          {
            "category": "30-06-2009",
            "value": 72.2235,
            "value2": 48.2468
          },
          {
            "category": "01-07-2009",
            "value": 72.9702,
            "value2": 48.7591
          },
          {
            "category": "02-07-2009",
            "value": 72.9285,
            "value2": 48.886
          },
          {
            "category": "03-07-2009",
            "value": 74.1838,
            "value2": 49.6226
          },
          {
            "category": "04-07-2009",
            "value": 74.1838,
            "value2": 49.6226
          },
          {
            "category": "05-07-2009",
            "value": 74.1838,
            "value2": 49.6226
          },
          {
            "category": "06-07-2009",
            "value": 70.5419,
            "value2": 46.8931
          },
          {
            "category": "07-07-2009",
            "value": 71.5097,
            "value2": 47.2358
          },
          {
            "category": "08-07-2009",
            "value": 70.3795,
            "value2": 45.8296
          },
          {
            "category": "09-07-2009",
            "value": 70.5681,
            "value2": 45.9494
          },
          {
            "category": "10-07-2009",
            "value": 69.3263,
            "value2": 45.0772
          },
          {
            "category": "11-07-2009",
            "value": 69.3263,
            "value2": 45.0772
          },
          {
            "category": "12-07-2009",
            "value": 69.3263,
            "value2": 45.0772
          },
          {
            "category": "13-07-2009",
            "value": 68.3757,
            "value2": 44.4421
          },
          {
            "category": "14-07-2009",
            "value": 69.8546,
            "value2": 46.1225
          },
          {
            "category": "15-07-2009",
            "value": 71.6698,
            "value2": 47.6527
          },
          {
            "category": "16-07-2009",
            "value": 71.8709,
            "value2": 47.7176
          },
          {
            "category": "17-07-2009",
            "value": 74.0891,
            "value2": 49.172
          },
          {
            "category": "18-07-2009",
            "value": 74.0891,
            "value2": 49.172
          },
          {
            "category": "19-07-2009",
            "value": 74.0891,
            "value2": 49.172
          },
          {
            "category": "20-07-2009",
            "value": 75.8512,
            "value2": 50.5203
          },
          {
            "category": "21-07-2009",
            "value": 75.6467,
            "value2": 50.2057
          },
          {
            "category": "22-07-2009",
            "value": 74.1174,
            "value2": 49.5203
          },
          {
            "category": "23-07-2009",
            "value": 75.5366,
            "value2": 50.7632
          },
          {
            "category": "24-07-2009",
            "value": 76.413,
            "value2": 51.3372
          },
          {
            "category": "25-07-2009",
            "value": 76.413,
            "value2": 51.3372
          },
          {
            "category": "26-07-2009",
            "value": 76.413,
            "value2": 51.3372
          },
          {
            "category": "27-07-2009",
            "value": 76.5559,
            "value2": 51.5801
          },
          {
            "category": "28-07-2009",
            "value": 76.5442,
            "value2": 51.6921
          },
          {
            "category": "29-07-2009",
            "value": 75.6654,
            "value2": 51.0943
          },
          {
            "category": "30-07-2009",
            "value": 76.9751,
            "value2": 51.6454
          },
          {
            "category": "31-07-2009",
            "value": 77.8133,
            "value2": 52.3691
          },
          {
            "category": "01-08-2009",
            "value": 77.9235,
            "value2": 52.3691
          },
          {
            "category": "02-08-2009",
            "value": 77.9235,
            "value2": 52.3691
          },
          {
            "category": "03-08-2009",
            "value": 78.3647,
            "value2": 53.2344
          },
          {
            "category": "04-08-2009",
            "value": 77.9246,
            "value2": 52.9958
          },
          {
            "category": "05-08-2009",
            "value": 77.8669,
            "value2": 53.1788
          },
          {
            "category": "06-08-2009",
            "value": 76.3273,
            "value2": 51.9711
          },
          {
            "category": "07-08-2009",
            "value": 75.1288,
            "value2": 50.7837
          },
          {
            "category": "08-08-2009",
            "value": 75.1288,
            "value2": 50.7837
          },
          {
            "category": "09-08-2009",
            "value": 75.1288,
            "value2": 50.7837
          },
          {
            "category": "10-08-2009",
            "value": 74.2364,
            "value2": 50.1424
          },
          {
            "category": "11-08-2009",
            "value": 74.4228,
            "value2": 50.5284
          },
          {
            "category": "12-08-2009",
            "value": 74.6935,
            "value2": 50.4531
          },
          {
            "category": "13-08-2009",
            "value": 76.4378,
            "value2": 52.1667
          },
          {
            "category": "14-08-2009",
            "value": 76.5495,
            "value2": 51.9309
          },
          {
            "category": "15-08-2009",
            "value": 76.5495,
            "value2": 51.9309
          },
          {
            "category": "16-08-2009",
            "value": 76.5495,
            "value2": 51.9309
          },
          {
            "category": "17-08-2009",
            "value": 74.191,
            "value2": 49.8748
          },
          {
            "category": "18-08-2009",
            "value": 75.3226,
            "value2": 50.7414
          },
          {
            "category": "19-08-2009",
            "value": 74.5442,
            "value2": 49.9968
          },
          {
            "category": "20-08-2009",
            "value": 75.5587,
            "value2": 50.6184
          },
          {
            "category": "21-08-2009",
            "value": 76.4651,
            "value2": 51.3859
          },
          {
            "category": "22-08-2009",
            "value": 76.4651,
            "value2": 51.3859
          },
          {
            "category": "23-08-2009",
            "value": 76.4651,
            "value2": 51.3859
          },
          {
            "category": "24-08-2009",
            "value": 77.6297,
            "value2": 52.6313
          },
          {
            "category": "25-08-2009",
            "value": 77.3109,
            "value2": 52.8466
          },
          {
            "category": "26-08-2009",
            "value": 77.3677,
            "value2": 53.232
          },
          {
            "category": "27-08-2009",
            "value": 77.2287,
            "value2": 53.3329
          },
          {
            "category": "28-08-2009",
            "value": 78.2698,
            "value2": 53.7975
          },
          {
            "category": "29-08-2009",
            "value": 78.2698,
            "value2": 53.7975
          },
          {
            "category": "30-08-2009",
            "value": 78.2698,
            "value2": 53.7975
          },
          {
            "category": "31-08-2009",
            "value": 77.3328,
            "value2": 53.3557
          },
          {
            "category": "01-09-2009",
            "value": 77.0884,
            "value2": 52.8265
          },
          {
            "category": "02-09-2009",
            "value": 77.2016,
            "value2": 52.6369
          },
          {
            "category": "03-09-2009",
            "value": 77.1165,
            "value2": 52.5618
          },
          {
            "category": "04-09-2009",
            "value": 78.1602,
            "value2": 53.3749
          },
          {
            "category": "05-09-2009",
            "value": 78.1602,
            "value2": 53.3749
          },
          {
            "category": "06-09-2009",
            "value": 78.1602,
            "value2": 53.3749
          },
          {
            "category": "07-09-2009",
            "value": 79.8159,
            "value2": 54.5837
          },
          {
            "category": "08-09-2009",
            "value": 80.3759,
            "value2": 54.7009
          },
          {
            "category": "09-09-2009",
            "value": 79.7969,
            "value2": 54.7591
          },
          {
            "category": "10-09-2009",
            "value": 79.8815,
            "value2": 54.7731
          },
          {
            "category": "11-09-2009",
            "value": 80.1599,
            "value2": 54.8225
          },
          {
            "category": "12-09-2009",
            "value": 80.1599,
            "value2": 54.8225
          },
          {
            "category": "13-09-2009",
            "value": 80.1599,
            "value2": 54.8225
          },
          {
            "category": "14-09-2009",
            "value": 80.3883,
            "value2": 54.8054
          },
          {
            "category": "15-09-2009",
            "value": 81.4578,
            "value2": 55.7361
          },
          {
            "category": "16-09-2009",
            "value": 82.7274,
            "value2": 56.4383
          },
          {
            "category": "17-09-2009",
            "value": 82.7372,
            "value2": 56.4904
          },
          {
            "category": "18-09-2009",
            "value": 83.4041,
            "value2": 56.7021
          },
          {
            "category": "19-09-2009",
            "value": 83.4041,
            "value2": 56.7021
          },
          {
            "category": "20-09-2009",
            "value": 83.4041,
            "value2": 56.7021
          },
          {
            "category": "21-09-2009",
            "value": 83.4041,
            "value2": 56.7021
          },
          {
            "category": "22-09-2009",
            "value": 84.134,
            "value2": 57.1551
          },
          {
            "category": "23-09-2009",
            "value": 83.4619,
            "value2": 56.5817
          },
          {
            "category": "24-09-2009",
            "value": 83.5749,
            "value2": 56.8008
          },
          {
            "category": "25-09-2009",
            "value": 83.4533,
            "value2": 56.7296
          },
          {
            "category": "26-09-2009",
            "value": 83.4533,
            "value2": 56.7296
          },
          {
            "category": "27-09-2009",
            "value": 83.4533,
            "value2": 56.7296
          },
          {
            "category": "28-09-2009",
            "value": 83.4526,
            "value2": 56.7296
          },
          {
            "category": "29-09-2009",
            "value": 83.8455,
            "value2": 57.1505
          },
          {
            "category": "30-09-2009",
            "value": 85.079,
            "value2": 57.9127
          },
          {
            "category": "01-10-2009",
            "value": 84.8717,
            "value2": 57.944
          },
          {
            "category": "02-10-2009",
            "value": 84.8715,
            "value2": 57.944
          },
          {
            "category": "03-10-2009",
            "value": 84.8715,
            "value2": 57.944
          },
          {
            "category": "04-10-2009",
            "value": 84.8715,
            "value2": 57.944
          },
          {
            "category": "05-10-2009",
            "value": 83.8831,
            "value2": 57.0147
          },
          {
            "category": "06-10-2009",
            "value": 83.4043,
            "value2": 57.2038
          },
          {
            "category": "07-10-2009",
            "value": 82.9298,
            "value2": 57.1278
          },
          {
            "category": "08-10-2009",
            "value": 82.6283,
            "value2": 57.4121
          },
          {
            "category": "09-10-2009",
            "value": 82.0073,
            "value2": 56.8821
          },
          {
            "category": "10-10-2009",
            "value": 82.0073,
            "value2": 56.8821
          },
          {
            "category": "11-10-2009",
            "value": 82.0073,
            "value2": 56.8821
          },
          {
            "category": "12-10-2009",
            "value": 83.462,
            "value2": 57.9685
          },
          {
            "category": "13-10-2009",
            "value": 83.462,
            "value2": 57.9685
          },
          {
            "category": "14-10-2009",
            "value": 84.2546,
            "value2": 58.8165
          },
          {
            "category": "15-10-2009",
            "value": 84.021,
            "value2": 58.9244
          },
          {
            "category": "16-10-2009",
            "value": 84.3516,
            "value2": 59.349
          },
          {
            "category": "17-10-2009",
            "value": 84.8285,
            "value2": 59.4931
          },
          {
            "category": "18-10-2009",
            "value": 84.8285,
            "value2": 59.4931
          },
          {
            "category": "19-10-2009",
            "value": 84.8285,
            "value2": 59.4931
          },
          {
            "category": "20-10-2009",
            "value": 84.5415,
            "value2": 59.2182
          },
          {
            "category": "21-10-2009",
            "value": 83.6282,
            "value2": 58.5597
          },
          {
            "category": "22-10-2009",
            "value": 82.9531,
            "value2": 57.6875
          },
          {
            "category": "23-10-2009",
            "value": 82.8598,
            "value2": 57.8998
          },
          {
            "category": "24-10-2009",
            "value": 82.8598,
            "value2": 57.8998
          },
          {
            "category": "25-10-2009",
            "value": 82.8598,
            "value2": 57.8998
          },
          {
            "category": "26-10-2009",
            "value": 82.8875,
            "value2": 57.3907
          },
          {
            "category": "27-10-2009",
            "value": 80.9764,
            "value2": 55.7764
          },
          {
            "category": "28-10-2009",
            "value": 80.6304,
            "value2": 55.6282
          },
          {
            "category": "29-10-2009",
            "value": 79.5296,
            "value2": 54.7274
          },
          {
            "category": "30-10-2009",
            "value": 79.2338,
            "value2": 54.3321
          },
          {
            "category": "31-10-2009",
            "value": 79.2313,
            "value2": 54.3321
          },
          {
            "category": "01-11-2009",
            "value": 79.2111,
            "value2": 54.3321
          },
          {
            "category": "02-11-2009",
            "value": 79.1913,
            "value2": 54.3321
          },
          {
            "category": "03-11-2009",
            "value": 77.7933,
            "value2": 52.5889
          },
          {
            "category": "04-11-2009",
            "value": 79.381,
            "value2": 54.2482
          },
          {
            "category": "05-11-2009",
            "value": 80.3745,
            "value2": 55.0227
          },
          {
            "category": "06-11-2009",
            "value": 80.8184,
            "value2": 55.6893
          },
          {
            "category": "07-11-2009",
            "value": 80.8184,
            "value2": 55.6893
          },
          {
            "category": "08-11-2009",
            "value": 80.8184,
            "value2": 55.6893
          },
          {
            "category": "09-11-2009",
            "value": 81.8322,
            "value2": 56.8785
          },
          {
            "category": "10-11-2009",
            "value": 81.3613,
            "value2": 56.75
          },
          {
            "category": "11-11-2009",
            "value": 82.6508,
            "value2": 58.0703
          },
          {
            "category": "12-11-2009",
            "value": 81.9242,
            "value2": 57.4588
          },
          {
            "category": "13-11-2009",
            "value": 82.7024,
            "value2": 57.8505
          },
          {
            "category": "14-11-2009",
            "value": 82.7024,
            "value2": 57.8505
          },
          {
            "category": "15-11-2009",
            "value": 82.7024,
            "value2": 57.8505
          },
          {
            "category": "16-11-2009",
            "value": 83.4963,
            "value2": 58.5097
          },
          {
            "category": "17-11-2009",
            "value": 83.998,
            "value2": 58.5751
          },
          {
            "category": "18-11-2009",
            "value": 83.9045,
            "value2": 58.5957
          },
          {
            "category": "19-11-2009",
            "value": 82.965,
            "value2": 57.8074
          },
          {
            "category": "20-11-2009",
            "value": 83.5992,
            "value2": 58.4505
          },
          {
            "category": "21-11-2009",
            "value": 83.5992,
            "value2": 58.4505
          },
          {
            "category": "22-11-2009",
            "value": 83.5992,
            "value2": 58.4505
          },
          {
            "category": "23-11-2009",
            "value": 83.9044,
            "value2": 58.9135
          },
          {
            "category": "24-11-2009",
            "value": 84.2444,
            "value2": 58.8274
          },
          {
            "category": "25-11-2009",
            "value": 84.8791,
            "value2": 59.0068
          },
          {
            "category": "26-11-2009",
            "value": 83.7918,
            "value2": 58.0103
          },
          {
            "category": "27-11-2009",
            "value": 83.5102,
            "value2": 57.2553
          },
          {
            "category": "28-11-2009",
            "value": 83.5102,
            "value2": 57.2553
          },
          {
            "category": "29-11-2009",
            "value": 83.5102,
            "value2": 57.2553
          },
          {
            "category": "30-11-2009",
            "value": 84.6042,
            "value2": 58.2652
          },
          {
            "category": "01-12-2009",
            "value": 85.6542,
            "value2": 59.3166
          },
          {
            "category": "02-12-2009",
            "value": 85.5388,
            "value2": 59.4364
          },
          {
            "category": "03-12-2009",
            "value": 85.7885,
            "value2": 59.6492
          },
          {
            "category": "04-12-2009",
            "value": 85.6863,
            "value2": 59.4633
          },
          {
            "category": "05-12-2009",
            "value": 85.6864,
            "value2": 59.4633
          },
          {
            "category": "06-12-2009",
            "value": 85.6864,
            "value2": 59.4633
          },
          {
            "category": "07-12-2009",
            "value": 85.8472,
            "value2": 58.9841
          },
          {
            "category": "08-12-2009",
            "value": 86.9678,
            "value2": 59.8364
          },
          {
            "category": "09-12-2009",
            "value": 86.7927,
            "value2": 59.4852
          },
          {
            "category": "10-12-2009",
            "value": 86.8615,
            "value2": 59.6978
          },
          {
            "category": "11-12-2009",
            "value": 86.4624,
            "value2": 59.415
          },
          {
            "category": "12-12-2009",
            "value": 86.4624,
            "value2": 59.415
          },
          {
            "category": "13-12-2009",
            "value": 86.4624,
            "value2": 59.415
          },
          {
            "category": "14-12-2009",
            "value": 86.1396,
            "value2": 59.2796
          },
          {
            "category": "15-12-2009",
            "value": 85.1186,
            "value2": 58.4351
          },
          {
            "category": "16-12-2009",
            "value": 85.134,
            "value2": 58.5537
          },
          {
            "category": "17-12-2009",
            "value": 84.9203,
            "value2": 58.6965
          },
          {
            "category": "18-12-2009",
            "value": 84.4135,
            "value2": 58.1987
          },
          {
            "category": "19-12-2009",
            "value": 84.4135,
            "value2": 58.1987
          },
          {
            "category": "20-12-2009",
            "value": 84.4135,
            "value2": 58.1987
          },
          {
            "category": "21-12-2009",
            "value": 83.9786,
            "value2": 57.8379
          },
          {
            "category": "22-12-2009",
            "value": 84.6061,
            "value2": 58.2989
          },
          {
            "category": "23-12-2009",
            "value": 86.2792,
            "value2": 59.7955
          },
          {
            "category": "24-12-2009",
            "value": 86.6223,
            "value2": 60.1813
          },
          {
            "category": "25-12-2009",
            "value": 86.6222,
            "value2": 60.1813
          },
          {
            "category": "26-12-2009",
            "value": 86.6222,
            "value2": 60.1813
          },
          {
            "category": "27-12-2009",
            "value": 86.6222,
            "value2": 60.1813
          },
          {
            "category": "28-12-2009",
            "value": 86.6222,
            "value2": 60.1813
          },
          {
            "category": "29-12-2009",
            "value": 86.7177,
            "value2": 60.3354
          },
          {
            "category": "30-12-2009",
            "value": 86.526,
            "value2": 60.2573
          },
          {
            "category": "31-12-2009",
            "value": 87.277,
            "value2": 60.5691
          },
          {
            "category": "01-01-2010",
            "value": 86.9814,
            "value2": 60.5691
          },
          {
            "category": "02-01-2010",
            "value": 86.9814,
            "value2": 60.5691
          },
          {
            "category": "03-01-2010",
            "value": 86.9814,
            "value2": 60.5691
          },
          {
            "category": "04-01-2010",
            "value": 87.4776,
            "value2": 61.1137
          },
          {
            "category": "05-01-2010",
            "value": 87.7025,
            "value2": 61.7411
          },
          {
            "category": "06-01-2010",
            "value": 87.3973,
            "value2": 61.9151
          },
          {
            "category": "07-01-2010",
            "value": 86.8885,
            "value2": 61.7138
          },
          {
            "category": "08-01-2010",
            "value": 86.7122,
            "value2": 61.589
          },
          {
            "category": "09-01-2010",
            "value": 86.7744,
            "value2": 61.589
          },
          {
            "category": "10-01-2010",
            "value": 86.7133,
            "value2": 61.589
          },
          {
            "category": "11-01-2010",
            "value": 87.3331,
            "value2": 61.86
          },
          {
            "category": "12-01-2010",
            "value": 86.4518,
            "value2": 61.3415
          },
          {
            "category": "13-01-2010",
            "value": 86.7731,
            "value2": 61.6623
          },
          {
            "category": "14-01-2010",
            "value": 87.2061,
            "value2": 62.0238
          },
          {
            "category": "15-01-2010",
            "value": 86.9255,
            "value2": 62.0158
          },
          {
            "category": "16-01-2010",
            "value": 86.9255,
            "value2": 62.0158
          },
          {
            "category": "17-01-2010",
            "value": 86.9271,
            "value2": 62.0158
          },
          {
            "category": "18-01-2010",
            "value": 87.9398,
            "value2": 62.3204
          },
          {
            "category": "19-01-2010",
            "value": 87.2409,
            "value2": 61.819
          },
          {
            "category": "20-01-2010",
            "value": 87.3979,
            "value2": 61.7385
          },
          {
            "category": "21-01-2010",
            "value": 85.7468,
            "value2": 60.228
          },
          {
            "category": "22-01-2010",
            "value": 84.7124,
            "value2": 59.5887
          },
          {
            "category": "23-01-2010",
            "value": 84.7124,
            "value2": 59.5887
          },
          {
            "category": "24-01-2010",
            "value": 84.7124,
            "value2": 59.5887
          },
          {
            "category": "25-01-2010",
            "value": 84.1717,
            "value2": 59.1049
          },
          {
            "category": "26-01-2010",
            "value": 84.1716,
            "value2": 59.1049
          },
          {
            "category": "27-01-2010",
            "value": 82.0476,
            "value2": 57.113
          },
          {
            "category": "28-01-2010",
            "value": 81.9849,
            "value2": 57.3509
          },
          {
            "category": "29-01-2010",
            "value": 82.0856,
            "value2": 57.6379
          },
          {
            "category": "30-01-2010",
            "value": 82.0856,
            "value2": 57.6379
          },
          {
            "category": "31-01-2010",
            "value": 82.0877,
            "value2": 57.6379
          },
          {
            "category": "01-02-2010",
            "value": 82.5253,
            "value2": 58.0968
          },
          {
            "category": "02-02-2010",
            "value": 81.8587,
            "value2": 57.3346
          },
          {
            "category": "03-02-2010",
            "value": 83.2519,
            "value2": 58.4317
          },
          {
            "category": "04-02-2010",
            "value": 82.3284,
            "value2": 57.4284
          },
          {
            "category": "05-02-2010",
            "value": 80.6456,
            "value2": 55.9312
          },
          {
            "category": "06-02-2010",
            "value": 81.2795,
            "value2": 56.4943
          },
          {
            "category": "07-02-2010",
            "value": 81.2795,
            "value2": 56.4943
          },
          {
            "category": "08-02-2010",
            "value": 81.2853,
            "value2": 56.5267
          },
          {
            "category": "09-02-2010",
            "value": 82.2679,
            "value2": 56.9102
          },
          {
            "category": "10-02-2010",
            "value": 82.1877,
            "value2": 56.5978
          },
          {
            "category": "11-02-2010",
            "value": 82.9094,
            "value2": 57.2609
          },
          {
            "category": "12-02-2010",
            "value": 82.9089,
            "value2": 57.2609
          },
          {
            "category": "13-02-2010",
            "value": 82.9089,
            "value2": 57.2609
          },
          {
            "category": "14-02-2010",
            "value": 82.9089,
            "value2": 57.2609
          },
          {
            "category": "15-02-2010",
            "value": 82.3376,
            "value2": 56.938
          },
          {
            "category": "16-02-2010",
            "value": 82.7093,
            "value2": 57.5129
          },
          {
            "category": "17-02-2010",
            "value": 83.6702,
            "value2": 58.1217
          },
          {
            "category": "18-02-2010",
            "value": 83.7548,
            "value2": 57.7877
          },
          {
            "category": "19-02-2010",
            "value": 83.4515,
            "value2": 57.2113
          },
          {
            "category": "20-02-2010",
            "value": 83.4515,
            "value2": 57.2113
          },
          {
            "category": "21-02-2010",
            "value": 83.4515,
            "value2": 57.2113
          },
          {
            "category": "22-02-2010",
            "value": 83.8998,
            "value2": 57.1583
          },
          {
            "category": "23-02-2010",
            "value": 83.9201,
            "value2": 57.1552
          },
          {
            "category": "24-02-2010",
            "value": 83.6276,
            "value2": 57.0455
          },
          {
            "category": "25-02-2010",
            "value": 83.8184,
            "value2": 56.9886
          },
          {
            "category": "26-02-2010",
            "value": 84.783,
            "value2": 57.7471
          },
          {
            "category": "27-02-2010",
            "value": 84.783,
            "value2": 57.7471
          },
          {
            "category": "28-02-2010",
            "value": 84.783,
            "value2": 57.7471
          },
          {
            "category": "01-03-2010",
            "value": 84.822,
            "value2": 57.7471
          },
          {
            "category": "02-03-2010",
            "value": 85.9104,
            "value2": 58.8671
          },
          {
            "category": "03-03-2010",
            "value": 86.6493,
            "value2": 59.7061
          },
          {
            "category": "04-03-2010",
            "value": 86.6348,
            "value2": 59.8201
          },
          {
            "category": "05-03-2010",
            "value": 87.4818,
            "value2": 60.0229
          },
          {
            "category": "06-03-2010",
            "value": 87.4819,
            "value2": 60.0229
          },
          {
            "category": "07-03-2010",
            "value": 87.4819,
            "value2": 60.0229
          },
          {
            "category": "08-03-2010",
            "value": 88.1366,
            "value2": 60.3927
          },
          {
            "category": "09-03-2010",
            "value": 87.9159,
            "value2": 60.0477
          },
          {
            "category": "10-03-2010",
            "value": 88.3436,
            "value2": 60.1062
          },
          {
            "category": "11-03-2010",
            "value": 88.3424,
            "value2": 60.1846
          },
          {
            "category": "12-03-2010",
            "value": 88.2793,
            "value2": 60.1506
          },
          {
            "category": "13-03-2010",
            "value": 88.2793,
            "value2": 60.1506
          },
          {
            "category": "14-03-2010",
            "value": 88.2793,
            "value2": 60.1506
          },
          {
            "category": "15-03-2010",
            "value": 88.0255,
            "value2": 59.9521
          },
          {
            "category": "16-03-2010",
            "value": 88.5286,
            "value2": 60.6337
          },
          {
            "category": "17-03-2010",
            "value": 89.0068,
            "value2": 60.9421
          },
          {
            "category": "18-03-2010",
            "value": 89.3082,
            "value2": 61.085
          },
          {
            "category": "19-03-2010",
            "value": 90.3656,
            "value2": 61.2624
          },
          {
            "category": "20-03-2010",
            "value": 90.3656,
            "value2": 61.2624
          },
          {
            "category": "21-03-2010",
            "value": 90.3656,
            "value2": 61.2624
          },
          {
            "category": "22-03-2010",
            "value": 89.8828,
            "value2": 60.6547
          },
          {
            "category": "23-03-2010",
            "value": 90.1233,
            "value2": 60.8376
          },
          {
            "category": "24-03-2010",
            "value": 90.1225,
            "value2": 60.8376
          },
          {
            "category": "25-03-2010",
            "value": 90.9878,
            "value2": 61.0957
          },
          {
            "category": "26-03-2010",
            "value": 90.9899,
            "value2": 61.4287
          },
          {
            "category": "27-03-2010",
            "value": 90.9884,
            "value2": 61.4287
          },
          {
            "category": "28-03-2010",
            "value": 90.9884,
            "value2": 61.4287
          },
          {
            "category": "29-03-2010",
            "value": 91.305,
            "value2": 61.6057
          },
          {
            "category": "30-03-2010",
            "value": 90.2124,
            "value2": 61.3852
          },
          {
            "category": "31-03-2010",
            "value": 88.4604,
            "value2": 61.3261
          },
          {
            "category": "01-04-2010",
            "value": 89.0682,
            "value2": 61.8636
          },
          {
            "category": "02-04-2010",
            "value": 89.0682,
            "value2": 61.8636
          },
          {
            "category": "03-04-2010",
            "value": 89.0682,
            "value2": 61.8636
          },
          {
            "category": "04-04-2010",
            "value": 89.0682,
            "value2": 61.8636
          },
          {
            "category": "05-04-2010",
            "value": 90.0263,
            "value2": 62.6953
          },
          {
            "category": "06-04-2010",
            "value": 89.6813,
            "value2": 62.7941
          },
          {
            "category": "07-04-2010",
            "value": 89.5747,
            "value2": 62.9879
          },
          {
            "category": "08-04-2010",
            "value": 88.9989,
            "value2": 62.33
          },
          {
            "category": "09-04-2010",
            "value": 89.7059,
            "value2": 62.9278
          },
          {
            "category": "10-04-2010",
            "value": 89.7055,
            "value2": 62.9278
          },
          {
            "category": "11-04-2010",
            "value": 89.7055,
            "value2": 62.9278
          },
          {
            "category": "12-04-2010",
            "value": 89.7013,
            "value2": 62.6894
          },
          {
            "category": "13-04-2010",
            "value": 89.6369,
            "value2": 62.5736
          },
          {
            "category": "14-04-2010",
            "value": 89.6369,
            "value2": 62.5736
          },
          {
            "category": "15-04-2010",
            "value": 89.0887,
            "value2": 62.1298
          },
          {
            "category": "16-04-2010",
            "value": 88.8698,
            "value2": 61.9358
          },
          {
            "category": "17-04-2010",
            "value": 88.8694,
            "value2": 61.9358
          },
          {
            "category": "18-04-2010",
            "value": 88.8694,
            "value2": 61.9358
          },
          {
            "category": "19-04-2010",
            "value": 88.2492,
            "value2": 61.2727
          },
          {
            "category": "20-04-2010",
            "value": 88.3691,
            "value2": 61.7535
          },
          {
            "category": "21-04-2010",
            "value": 88.7455,
            "value2": 62.0748
          },
          {
            "category": "22-04-2010",
            "value": 89.2938,
            "value2": 62.2519
          },
          {
            "category": "23-04-2010",
            "value": 89.1423,
            "value2": 62.507
          },
          {
            "category": "24-04-2010",
            "value": 89.1423,
            "value2": 62.507
          },
          {
            "category": "25-04-2010",
            "value": 89.1423,
            "value2": 62.507
          },
          {
            "category": "26-04-2010",
            "value": 89.4431,
            "value2": 62.7671
          },
          {
            "category": "27-04-2010",
            "value": 89.1921,
            "value2": 62.6345
          },
          {
            "category": "28-04-2010",
            "value": 88.3363,
            "value2": 61.659
          },
          {
            "category": "29-04-2010",
            "value": 88.4741,
            "value2": 62.0822
          },
          {
            "category": "30-04-2010",
            "value": 89.6205,
            "value2": 62.4478
          },
          {
            "category": "01-05-2010",
            "value": 89.6204,
            "value2": 62.4478
          },
          {
            "category": "02-05-2010",
            "value": 89.6204,
            "value2": 62.4478
          },
          {
            "category": "03-05-2010",
            "value": 89.2597,
            "value2": 62.0113
          },
          {
            "category": "04-05-2010",
            "value": 88.3469,
            "value2": 61.06
          },
          {
            "category": "05-05-2010",
            "value": 88.2587,
            "value2": 60.9703
          },
          {
            "category": "06-05-2010",
            "value": 88.2447,
            "value2": 60.6725
          },
          {
            "category": "07-05-2010",
            "value": 87.1393,
            "value2": 59.5533
          },
          {
            "category": "08-05-2010",
            "value": 87.1393,
            "value2": 59.5533
          },
          {
            "category": "09-05-2010",
            "value": 87.1393,
            "value2": 59.5533
          },
          {
            "category": "10-05-2010",
            "value": 89.0753,
            "value2": 61.3793
          },
          {
            "category": "11-05-2010",
            "value": 88.5695,
            "value2": 60.6955
          },
          {
            "category": "12-05-2010",
            "value": 88.9356,
            "value2": 60.9174
          },
          {
            "category": "13-05-2010",
            "value": 89.0366,
            "value2": 61.3367
          },
          {
            "category": "14-05-2010",
            "value": 88.1082,
            "value2": 60.5028
          },
          {
            "category": "15-05-2010",
            "value": 88.1082,
            "value2": 60.5028
          },
          {
            "category": "16-05-2010",
            "value": 88.1082,
            "value2": 60.5028
          },
          {
            "category": "17-05-2010",
            "value": 88.1538,
            "value2": 60.2025
          },
          {
            "category": "18-05-2010",
            "value": 88.6231,
            "value2": 60.296
          },
          {
            "category": "19-05-2010",
            "value": 87.2198,
            "value2": 58.6637
          },
          {
            "category": "20-05-2010",
            "value": 87.5006,
            "value2": 58.9011
          },
          {
            "category": "21-05-2010",
            "value": 86.9319,
            "value2": 58.5055
          },
          {
            "category": "22-05-2010",
            "value": 86.9319,
            "value2": 58.5055
          },
          {
            "category": "23-05-2010",
            "value": 86.9319,
            "value2": 58.5055
          },
          {
            "category": "24-05-2010",
            "value": 87.2173,
            "value2": 58.5952
          },
          {
            "category": "25-05-2010",
            "value": 85.4805,
            "value2": 56.9642
          },
          {
            "category": "26-05-2010",
            "value": 86.6604,
            "value2": 58.1066
          },
          {
            "category": "27-05-2010",
            "value": 87.5476,
            "value2": 58.9419
          },
          {
            "category": "28-05-2010",
            "value": 88.2758,
            "value2": 59.7374
          },
          {
            "category": "29-05-2010",
            "value": 88.2768,
            "value2": 59.7374
          },
          {
            "category": "30-05-2010",
            "value": 88.2768,
            "value2": 59.7374
          },
          {
            "category": "31-05-2010",
            "value": 89.0212,
            "value2": 60.2135
          },
          {
            "category": "01-06-2010",
            "value": 87.7443,
            "value2": 59.0806
          },
          {
            "category": "02-06-2010",
            "value": 88.5833,
            "value2": 59.6438
          },
          {
            "category": "03-06-2010",
            "value": 89.2817,
            "value2": 60.5483
          },
          {
            "category": "04-06-2010",
            "value": 89.5895,
            "value2": 60.7824
          },
          {
            "category": "05-06-2010",
            "value": 89.5895,
            "value2": 60.7824
          },
          {
            "category": "06-06-2010",
            "value": 89.5896,
            "value2": 60.7824
          },
          {
            "category": "07-06-2010",
            "value": 88.2358,
            "value2": 59.7304
          },
          {
            "category": "08-06-2010",
            "value": 87.4693,
            "value2": 59.238
          },
          {
            "category": "09-06-2010",
            "value": 88.2416,
            "value2": 59.5776
          },
          {
            "category": "10-06-2010",
            "value": 89.7387,
            "value2": 60.4455
          },
          {
            "category": "11-06-2010",
            "value": 89.8708,
            "value2": 60.7528
          },
          {
            "category": "12-06-2010",
            "value": 89.8701,
            "value2": 60.7528
          },
          {
            "category": "13-06-2010",
            "value": 89.8701,
            "value2": 60.7528
          },
          {
            "category": "14-06-2010",
            "value": 90.8165,
            "value2": 61.5569
          },
          {
            "category": "15-06-2010",
            "value": 90.9174,
            "value2": 61.883
          },
          {
            "category": "16-06-2010",
            "value": 91.5116,
            "value2": 61.9942
          },
          {
            "category": "17-06-2010",
            "value": 91.7092,
            "value2": 62.3995
          },
          {
            "category": "18-06-2010",
            "value": 91.6443,
            "value2": 62.1619
          },
          {
            "category": "19-06-2010",
            "value": 91.6439,
            "value2": 62.1619
          },
          {
            "category": "20-06-2010",
            "value": 91.6439,
            "value2": 62.1619
          },
          {
            "category": "21-06-2010",
            "value": 92.4691,
            "value2": 63.1649
          },
          {
            "category": "22-06-2010",
            "value": 92.1241,
            "value2": 62.8669
          },
          {
            "category": "23-06-2010",
            "value": 92.3228,
            "value2": 63.068
          },
          {
            "category": "24-06-2010",
            "value": 92.3297,
            "value2": 63.038
          },
          {
            "category": "25-06-2010",
            "value": 92.0294,
            "value2": 62.6392
          },
          {
            "category": "26-06-2010",
            "value": 92.0294,
            "value2": 62.6392
          },
          {
            "category": "27-06-2010",
            "value": 92.0294,
            "value2": 62.6392
          },
          {
            "category": "28-06-2010",
            "value": 92.961,
            "value2": 63.3589
          },
          {
            "category": "29-06-2010",
            "value": 92.3371,
            "value2": 62.698
          },
          {
            "category": "30-06-2010",
            "value": 92.6274,
            "value2": 63.205
          },
          {
            "category": "01-07-2010",
            "value": 91.9224,
            "value2": 62.7305
          },
          {
            "category": "02-07-2010",
            "value": 91.7898,
            "value2": 62.6079
          },
          {
            "category": "03-07-2010",
            "value": 91.79,
            "value2": 62.6079
          },
          {
            "category": "04-07-2010",
            "value": 91.79,
            "value2": 62.6079
          },
          {
            "category": "05-07-2010",
            "value": 92.1239,
            "value2": 62.625
          },
          {
            "category": "06-07-2010",
            "value": 92.8846,
            "value2": 63.1455
          },
          {
            "category": "07-07-2010",
            "value": 92.4299,
            "value2": 62.743
          },
          {
            "category": "08-07-2010",
            "value": 93.4521,
            "value2": 63.3641
          },
          {
            "category": "09-07-2010",
            "value": 94.4243,
            "value2": 63.9055
          },
          {
            "category": "10-07-2010",
            "value": 94.4243,
            "value2": 63.9055
          },
          {
            "category": "11-07-2010",
            "value": 94.4243,
            "value2": 63.9055
          },
          {
            "category": "12-07-2010",
            "value": 94.4531,
            "value2": 64.1982
          },
          {
            "category": "13-07-2010",
            "value": 94.4329,
            "value2": 64.4677
          },
          {
            "category": "14-07-2010",
            "value": 94.7,
            "value2": 64.2941
          },
          {
            "category": "15-07-2010",
            "value": 93.9819,
            "value2": 64.2285
          },
          {
            "category": "16-07-2010",
            "value": 94.0589,
            "value2": 64.4758
          },
          {
            "category": "17-07-2010",
            "value": 94.0589,
            "value2": 64.4758
          },
          {
            "category": "18-07-2010",
            "value": 94.0589,
            "value2": 64.4758
          },
          {
            "category": "19-07-2010",
            "value": 93.9165,
            "value2": 64.4557
          },
          {
            "category": "20-07-2010",
            "value": 93.5989,
            "value2": 64.2443
          },
          {
            "category": "21-07-2010",
            "value": 93.5688,
            "value2": 64.5777
          },
          {
            "category": "22-07-2010",
            "value": 94.09,
            "value2": 65.0072
          },
          {
            "category": "23-07-2010",
            "value": 94.4979,
            "value2": 64.965
          },
          {
            "category": "24-07-2010",
            "value": 94.4979,
            "value2": 64.965
          },
          {
            "category": "25-07-2010",
            "value": 94.4979,
            "value2": 64.965
          },
          {
            "category": "26-07-2010",
            "value": 93.366,
            "value2": 64.5629
          },
          {
            "category": "27-07-2010",
            "value": 93.6379,
            "value2": 64.7286
          },
          {
            "category": "28-07-2010",
            "value": 93.3626,
            "value2": 64.4678
          },
          {
            "category": "29-07-2010",
            "value": 93.2202,
            "value2": 64.6088
          },
          {
            "category": "30-07-2010",
            "value": 93.0764,
            "value2": 64.3438
          },
          {
            "category": "31-07-2010",
            "value": 93.0764,
            "value2": 64.3438
          },
          {
            "category": "01-08-2010",
            "value": 93.0764,
            "value2": 64.3438
          },
          {
            "category": "02-08-2010",
            "value": 93.708,
            "value2": 65.0502
          },
          {
            "category": "03-08-2010",
            "value": 93.8992,
            "value2": 65.1948
          },
          {
            "category": "04-08-2010",
            "value": 94.0295,
            "value2": 65.3988
          },
          {
            "category": "05-08-2010",
            "value": 93.929,
            "value2": 65.2934
          },
          {
            "category": "06-08-2010",
            "value": 93.7519,
            "value2": 65.2411
          },
          {
            "category": "07-08-2010",
            "value": 93.7519,
            "value2": 65.2411
          },
          {
            "category": "08-08-2010",
            "value": 93.7519,
            "value2": 65.2411
          },
          {
            "category": "09-08-2010",
            "value": 94.3185,
            "value2": 65.828
          },
          {
            "category": "10-08-2010",
            "value": 94.1231,
            "value2": 65.5775
          },
          {
            "category": "11-08-2010",
            "value": 93.6314,
            "value2": 65.1292
          },
          {
            "category": "12-08-2010",
            "value": 93.8348,
            "value2": 65.2343
          },
          {
            "category": "13-08-2010",
            "value": 94.3094,
            "value2": 65.6588
          },
          {
            "category": "14-08-2010",
            "value": 94.3094,
            "value2": 65.6588
          },
          {
            "category": "15-08-2010",
            "value": 94.3094,
            "value2": 65.6588
          },
          {
            "category": "16-08-2010",
            "value": 94.0701,
            "value2": 65.3526
          },
          {
            "category": "17-08-2010",
            "value": 94.3098,
            "value2": 65.5048
          },
          {
            "category": "18-08-2010",
            "value": 95.0803,
            "value2": 66.1487
          },
          {
            "category": "19-08-2010",
            "value": 95.7557,
            "value2": 66.8015
          },
          {
            "category": "20-08-2010",
            "value": 96.1598,
            "value2": 66.7621
          },
          {
            "category": "21-08-2010",
            "value": 96.1597,
            "value2": 66.7621
          },
          {
            "category": "22-08-2010",
            "value": 96.1597,
            "value2": 66.7621
          },
          {
            "category": "23-08-2010",
            "value": 96.5092,
            "value2": 67.0125
          },
          {
            "category": "24-08-2010",
            "value": 96.2992,
            "value2": 66.6257
          },
          {
            "category": "25-08-2010",
            "value": 95.207,
            "value2": 65.9917
          },
          {
            "category": "26-08-2010",
            "value": 95.4379,
            "value2": 66.0809
          },
          {
            "category": "27-08-2010",
            "value": 94.5702,
            "value2": 65.3612
          },
          {
            "category": "28-08-2010",
            "value": 94.5702,
            "value2": 65.3612
          },
          {
            "category": "29-08-2010",
            "value": 94.5702,
            "value2": 65.3612
          },
          {
            "category": "30-08-2010",
            "value": 95.0551,
            "value2": 65.4708
          },
          {
            "category": "31-08-2010",
            "value": 94.6861,
            "value2": 65.1882
          },
          {
            "category": "01-09-2010",
            "value": 95.6195,
            "value2": 66.128
          },
          {
            "category": "02-09-2010",
            "value": 95.6046,
            "value2": 66.398
          },
          {
            "category": "03-09-2010",
            "value": 96.4391,
            "value2": 66.5485
          },
          {
            "category": "04-09-2010",
            "value": 96.4391,
            "value2": 66.5485
          },
          {
            "category": "05-09-2010",
            "value": 96.4391,
            "value2": 66.5485
          },
          {
            "category": "06-09-2010",
            "value": 97.2993,
            "value2": 67.6262
          },
          {
            "category": "07-09-2010",
            "value": 97.6206,
            "value2": 67.8764
          },
          {
            "category": "08-09-2010",
            "value": 97.9202,
            "value2": 67.9805
          },
          {
            "category": "09-09-2010",
            "value": 98.5906,
            "value2": 68.3803
          },
          {
            "category": "10-09-2010",
            "value": 98.5889,
            "value2": 68.3803
          },
          {
            "category": "11-09-2010",
            "value": 98.5889,
            "value2": 68.3803
          },
          {
            "category": "12-09-2010",
            "value": 98.5889,
            "value2": 68.3803
          },
          {
            "category": "13-09-2010",
            "value": 100.199,
            "value2": 69.5387
          },
          {
            "category": "14-09-2010",
            "value": 100.98,
            "value2": 69.7489
          },
          {
            "category": "15-09-2010",
            "value": 101.421,
            "value2": 70.2092
          },
          {
            "category": "16-09-2010",
            "value": 100.933,
            "value2": 69.8056
          },
          {
            "category": "17-09-2010",
            "value": 101.607,
            "value2": 70.5714
          },
          {
            "category": "18-09-2010",
            "value": 101.607,
            "value2": 70.5714
          },
          {
            "category": "19-09-2010",
            "value": 101.607,
            "value2": 70.5714
          },
          {
            "category": "20-09-2010",
            "value": 103.718,
            "value2": 71.4438
          },
          {
            "category": "21-09-2010",
            "value": 104.16,
            "value2": 71.4108
          },
          {
            "category": "22-09-2010",
            "value": 103.872,
            "value2": 71.1801
          },
          {
            "category": "23-09-2010",
            "value": 103.335,
            "value2": 70.9053
          },
          {
            "category": "24-09-2010",
            "value": 104.534,
            "value2": 71.5579
          },
          {
            "category": "25-09-2010",
            "value": 104.533,
            "value2": 71.5579
          },
          {
            "category": "26-09-2010",
            "value": 104.533,
            "value2": 71.5579
          },
          {
            "category": "27-09-2010",
            "value": 104.974,
            "value2": 71.8095
          },
          {
            "category": "28-09-2010",
            "value": 104.789,
            "value2": 71.8254
          },
          {
            "category": "29-09-2010",
            "value": 104.488,
            "value2": 71.4022
          },
          {
            "category": "30-09-2010",
            "value": 104.892,
            "value2": 71.4978
          },
          {
            "category": "01-10-2010",
            "value": 105.383,
            "value2": 72.8479
          },
          {
            "category": "02-10-2010",
            "value": 105.383,
            "value2": 72.8479
          },
          {
            "category": "03-10-2010",
            "value": 105.383,
            "value2": 72.8479
          },
          {
            "category": "04-10-2010",
            "value": 105.123,
            "value2": 73.1572
          },
          {
            "category": "05-10-2010",
            "value": 104.588,
            "value2": 73.236
          },
          {
            "category": "06-10-2010",
            "value": 104.93,
            "value2": 73.8183
          },
          {
            "category": "07-10-2010",
            "value": 104.282,
            "value2": 73.1568
          },
          {
            "category": "08-10-2010",
            "value": 103.968,
            "value2": 72.8911
          },
          {
            "category": "09-10-2010",
            "value": 103.968,
            "value2": 72.8911
          },
          {
            "category": "10-10-2010",
            "value": 103.968,
            "value2": 72.8911
          },
          {
            "category": "11-10-2010",
            "value": 103.897,
            "value2": 73.3302
          },
          {
            "category": "12-10-2010",
            "value": 103.47,
            "value2": 72.8165
          },
          {
            "category": "13-10-2010",
            "value": 105.183,
            "value2": 74.1484
          },
          {
            "category": "14-10-2010",
            "value": 104.379,
            "value2": 73.6115
          },
          {
            "category": "15-10-2010",
            "value": 102.344,
            "value2": 72.4122
          },
          {
            "category": "16-10-2010",
            "value": 102.344,
            "value2": 72.4122
          },
          {
            "category": "17-10-2010",
            "value": 102.344,
            "value2": 72.4122
          },
          {
            "category": "18-10-2010",
            "value": 102.759,
            "value2": 72.438
          },
          {
            "category": "19-10-2010",
            "value": 102.234,
            "value2": 72.0369
          },
          {
            "category": "20-10-2010",
            "value": 101.864,
            "value2": 71.6672
          },
          {
            "category": "21-10-2010",
            "value": 103.476,
            "value2": 72.955
          },
          {
            "category": "22-10-2010",
            "value": 103.322,
            "value2": 72.7563
          },
          {
            "category": "23-10-2010",
            "value": 103.322,
            "value2": 72.7563
          },
          {
            "category": "24-10-2010",
            "value": 103.322,
            "value2": 72.7563
          },
          {
            "category": "25-10-2010",
            "value": 103.779,
            "value2": 73.1966
          },
          {
            "category": "26-10-2010",
            "value": 103.143,
            "value2": 72.9496
          },
          {
            "category": "27-10-2010",
            "value": 102.108,
            "value2": 72.3482
          },
          {
            "category": "28-10-2010",
            "value": 102.638,
            "value2": 72.0173
          },
          {
            "category": "29-10-2010",
            "value": 102.297,
            "value2": 72.0225
          },
          {
            "category": "30-10-2010",
            "value": 102.297,
            "value2": 72.0225
          },
          {
            "category": "31-10-2010",
            "value": 102.297,
            "value2": 72.0225
          },
          {
            "category": "01-11-2010",
            "value": 103.598,
            "value2": 73.1833
          },
          {
            "category": "02-11-2010",
            "value": 103.613,
            "value2": 73.3301
          },
          {
            "category": "03-11-2010",
            "value": 104.081,
            "value2": 73.7626
          },
          {
            "category": "04-11-2010",
            "value": 105.262,
            "value2": 74.911
          },
          {
            "category": "05-11-2010",
            "value": 105.961,
            "value2": 75.3437
          },
          {
            "category": "06-11-2010",
            "value": 105.961,
            "value2": 75.3437
          },
          {
            "category": "07-11-2010",
            "value": 105.961,
            "value2": 75.3437
          },
          {
            "category": "08-11-2010",
            "value": 104.546,
            "value2": 75.0251
          },
          {
            "category": "09-11-2010",
            "value": 104.979,
            "value2": 75.35
          },
          {
            "category": "10-11-2010",
            "value": 104.182,
            "value2": 75.1983
          },
          {
            "category": "11-11-2010",
            "value": 103.271,
            "value2": 74.2662
          },
          {
            "category": "12-11-2010",
            "value": 101.684,
            "value2": 72.7092
          },
          {
            "category": "13-11-2010",
            "value": 101.684,
            "value2": 72.7092
          },
          {
            "category": "14-11-2010",
            "value": 101.684,
            "value2": 72.7092
          },
          {
            "category": "15-11-2010",
            "value": 102.449,
            "value2": 73.1093
          },
          {
            "category": "16-11-2010",
            "value": 101.76,
            "value2": 71.5775
          },
          {
            "category": "17-11-2010",
            "value": 101.76,
            "value2": 71.5775
          },
          {
            "category": "18-11-2010",
            "value": 102.706,
            "value2": 71.613
          },
          {
            "category": "19-11-2010",
            "value": 101.881,
            "value2": 70.3181
          },
          {
            "category": "20-11-2010",
            "value": 101.88,
            "value2": 70.3181
          },
          {
            "category": "21-11-2010",
            "value": 101.88,
            "value2": 70.3181
          },
          {
            "category": "22-11-2010",
            "value": 102.991,
            "value2": 71.5024
          },
          {
            "category": "23-11-2010",
            "value": 101.422,
            "value2": 70.6668
          },
          {
            "category": "24-11-2010",
            "value": 100.45,
            "value2": 69.8232
          },
          {
            "category": "25-11-2010",
            "value": 99.3198,
            "value2": 68.8404
          },
          {
            "category": "26-11-2010",
            "value": 98.9595,
            "value2": 67.7016
          },
          {
            "category": "27-11-2010",
            "value": 98.9595,
            "value2": 67.7016
          },
          {
            "category": "28-11-2010",
            "value": 98.9595,
            "value2": 67.7016
          },
          {
            "category": "29-11-2010",
            "value": 99.5461,
            "value2": 68.5106
          },
          {
            "category": "30-11-2010",
            "value": 100.557,
            "value2": 69.2251
          },
          {
            "category": "01-12-2010",
            "value": 102.071,
            "value2": 70.5946
          },
          {
            "category": "02-12-2010",
            "value": 101.845,
            "value2": 71.1281
          },
          {
            "category": "03-12-2010",
            "value": 101.847,
            "value2": 70.5816
          },
          {
            "category": "04-12-2010",
            "value": 101.848,
            "value2": 70.5816
          },
          {
            "category": "05-12-2010",
            "value": 101.848,
            "value2": 70.5816
          },
          {
            "category": "06-12-2010",
            "value": 101.209,
            "value2": 70.449
          },
          {
            "category": "07-12-2010",
            "value": 100.86,
            "value2": 70.1076
          },
          {
            "category": "08-12-2010",
            "value": 99.8901,
            "value2": 69.0944
          },
          {
            "category": "09-12-2010",
            "value": 97.8528,
            "value2": 67.1112
          },
          {
            "category": "10-12-2010",
            "value": 98.7165,
            "value2": 68.1774
          },
          {
            "category": "11-12-2010",
            "value": 98.7164,
            "value2": 68.1774
          },
          {
            "category": "12-12-2010",
            "value": 98.7164,
            "value2": 68.1774
          },
          {
            "category": "13-12-2010",
            "value": 99.6058,
            "value2": 68.9783
          },
          {
            "category": "14-12-2010",
            "value": 99.9026,
            "value2": 69.5341
          },
          {
            "category": "15-12-2010",
            "value": 98.8289,
            "value2": 68.8199
          },
          {
            "category": "16-12-2010",
            "value": 99.5666,
            "value2": 69.3193
          },
          {
            "category": "17-12-2010",
            "value": 99.5662,
            "value2": 69.3193
          },
          {
            "category": "18-12-2010",
            "value": 99.5662,
            "value2": 69.3193
          },
          {
            "category": "19-12-2010",
            "value": 99.5662,
            "value2": 69.3193
          },
          {
            "category": "20-12-2010",
            "value": 100.246,
            "value2": 69.3031
          },
          {
            "category": "21-12-2010",
            "value": 100.416,
            "value2": 69.8762
          },
          {
            "category": "22-12-2010",
            "value": 100.367,
            "value2": 69.8206
          },
          {
            "category": "23-12-2010",
            "value": 100.4,
            "value2": 69.6843
          },
          {
            "category": "24-12-2010",
            "value": 100.606,
            "value2": 69.9475
          },
          {
            "category": "25-12-2010",
            "value": 100.606,
            "value2": 69.9475
          },
          {
            "category": "26-12-2010",
            "value": 100.606,
            "value2": 69.9475
          },
          {
            "category": "27-12-2010",
            "value": 100.339,
            "value2": 69.7642
          },
          {
            "category": "28-12-2010",
            "value": 100.269,
            "value2": 69.7659
          },
          {
            "category": "29-12-2010",
            "value": 101.55,
            "value2": 70.4054
          },
          {
            "category": "30-12-2010",
            "value": 101.881,
            "value2": 70.8459
          },
          {
            "category": "31-12-2010",
            "value": 102.779,
            "value2": 71.4063
          },
          {
            "category": "01-01-2011",
            "value": 102.779,
            "value2": 71.4063
          },
          {
            "category": "02-01-2011",
            "value": 102.779,
            "value2": 71.4063
          },
          {
            "category": "03-01-2011",
            "value": 103.604,
            "value2": 71.7704
          },
          {
            "category": "04-01-2011",
            "value": 103.015,
            "value2": 71.5921
          },
          {
            "category": "05-01-2011",
            "value": 101.71,
            "value2": 70.8873
          },
          {
            "category": "06-01-2011",
            "value": 101.377,
            "value2": 70.3123
          },
          {
            "category": "07-01-2011",
            "value": 99.8638,
            "value2": 68.6759
          },
          {
            "category": "08-01-2011",
            "value": 99.8638,
            "value2": 68.6759
          },
          {
            "category": "09-01-2011",
            "value": 99.8638,
            "value2": 68.6759
          },
          {
            "category": "10-01-2011",
            "value": 98.0689,
            "value2": 67.0345
          },
          {
            "category": "11-01-2011",
            "value": 98.1539,
            "value2": 66.9322
          },
          {
            "category": "12-01-2011",
            "value": 99.0102,
            "value2": 68.0478
          },
          {
            "category": "13-01-2011",
            "value": 97.0613,
            "value2": 67.1029
          },
          {
            "category": "14-01-2011",
            "value": 95.7525,
            "value2": 66.0166
          },
          {
            "category": "15-01-2011",
            "value": 95.7532,
            "value2": 66.0166
          },
          {
            "category": "16-01-2011",
            "value": 95.7525,
            "value2": 66.0166
          },
          {
            "category": "17-01-2011",
            "value": 96.1111,
            "value2": 65.8007
          },
          {
            "category": "18-01-2011",
            "value": 96.3398,
            "value2": 66.419
          },
          {
            "category": "19-01-2011",
            "value": 95.3192,
            "value2": 66.2721
          },
          {
            "category": "20-01-2011",
            "value": 95.8393,
            "value2": 66.4607
          },
          {
            "category": "21-01-2011",
            "value": 95.7926,
            "value2": 66.4084
          },
          {
            "category": "22-01-2011",
            "value": 95.7925,
            "value2": 66.4084
          },
          {
            "category": "23-01-2011",
            "value": 95.7925,
            "value2": 66.4084
          },
          {
            "category": "24-01-2011",
            "value": 96.4117,
            "value2": 66.9066
          },
          {
            "category": "25-01-2011",
            "value": 96.3212,
            "value2": 66.3929
          },
          {
            "category": "26-01-2011",
            "value": 96.3199,
            "value2": 66.3929
          },
          {
            "category": "27-01-2011",
            "value": 94.7973,
            "value2": 65.3243
          },
          {
            "category": "28-01-2011",
            "value": 93.8018,
            "value2": 64.0365
          },
          {
            "category": "29-01-2011",
            "value": 93.8018,
            "value2": 64.0365
          },
          {
            "category": "30-01-2011",
            "value": 93.8018,
            "value2": 64.0365
          },
          {
            "category": "31-01-2011",
            "value": 91.5114,
            "value2": 63.9538
          },
          {
            "category": "01-02-2011",
            "value": 90.3719,
            "value2": 62.9096
          },
          {
            "category": "02-02-2011",
            "value": 89.955,
            "value2": 63.0694
          },
          {
            "category": "03-02-2011",
            "value": 92.9484,
            "value2": 64.0803
          },
          {
            "category": "04-02-2011",
            "value": 91.5697,
            "value2": 62.7803
          },
          {
            "category": "05-02-2011",
            "value": 91.5697,
            "value2": 62.7803
          },
          {
            "category": "06-02-2011",
            "value": 91.5697,
            "value2": 62.7803
          },
          {
            "category": "07-02-2011",
            "value": 92.0061,
            "value2": 62.6998
          },
          {
            "category": "08-02-2011",
            "value": 91.1232,
            "value2": 61.5759
          },
          {
            "category": "09-02-2011",
            "value": 90.3778,
            "value2": 60.4244
          },
          {
            "category": "10-02-2011",
            "value": 89.4975,
            "value2": 60.223
          },
          {
            "category": "11-02-2011",
            "value": 90.6525,
            "value2": 61.3129
          },
          {
            "category": "12-02-2011",
            "value": 90.6525,
            "value2": 61.3129
          },
          {
            "category": "13-02-2011",
            "value": 90.6525,
            "value2": 61.3129
          },
          {
            "category": "14-02-2011",
            "value": 92.8534,
            "value2": 63.1044
          },
          {
            "category": "15-02-2011",
            "value": 93.2159,
            "value2": 63.3567
          },
          {
            "category": "16-02-2011",
            "value": 92.8932,
            "value2": 63.5045
          },
          {
            "category": "17-02-2011",
            "value": 94.1821,
            "value2": 64.1619
          },
          {
            "category": "18-02-2011",
            "value": 93.3331,
            "value2": 63.0941
          },
          {
            "category": "19-02-2011",
            "value": 93.3331,
            "value2": 63.0941
          },
          {
            "category": "20-02-2011",
            "value": 93.3331,
            "value2": 63.0941
          },
          {
            "category": "21-02-2011",
            "value": 93.6764,
            "value2": 63.6433
          },
          {
            "category": "22-02-2011",
            "value": 92.5082,
            "value2": 63.0941
          },
          {
            "category": "23-02-2011",
            "value": 92.2684,
            "value2": 62.7354
          },
          {
            "category": "24-02-2011",
            "value": 90.5917,
            "value2": 60.9049
          },
          {
            "category": "25-02-2011",
            "value": 90.897,
            "value2": 61.154
          },
          {
            "category": "26-02-2011",
            "value": 90.8953,
            "value2": 61.154
          },
          {
            "category": "27-02-2011",
            "value": 90.8953,
            "value2": 61.154
          },
          {
            "category": "28-02-2011",
            "value": 91.913,
            "value2": 61.4995
          },
          {
            "category": "01-03-2011",
            "value": 94.116,
            "value2": 63.5268
          },
          {
            "category": "02-03-2011",
            "value": 94.1152,
            "value2": 63.5268
          },
          {
            "category": "03-03-2011",
            "value": 94.1598,
            "value2": 63.6296
          },
          {
            "category": "04-03-2011",
            "value": 94.4359,
            "value2": 63.6543
          },
          {
            "category": "05-03-2011",
            "value": 94.4359,
            "value2": 63.6543
          },
          {
            "category": "06-03-2011",
            "value": 94.4359,
            "value2": 63.6543
          },
          {
            "category": "07-03-2011",
            "value": 93.9735,
            "value2": 62.8495
          },
          {
            "category": "08-03-2011",
            "value": 94.7635,
            "value2": 63.5255
          },
          {
            "category": "09-03-2011",
            "value": 95.0746,
            "value2": 63.7184
          },
          {
            "category": "10-03-2011",
            "value": 94.496,
            "value2": 63.3895
          },
          {
            "category": "11-03-2011",
            "value": 93.7312,
            "value2": 62.8298
          },
          {
            "category": "12-03-2011",
            "value": 92.1736,
            "value2": 62.8298
          },
          {
            "category": "13-03-2011",
            "value": 93.736,
            "value2": 62.8298
          },
          {
            "category": "14-03-2011",
            "value": 94.5131,
            "value2": 63.6089
          },
          {
            "category": "15-03-2011",
            "value": 93.5275,
            "value2": 62.6976
          },
          {
            "category": "16-03-2011",
            "value": 93.8818,
            "value2": 63.437
          },
          {
            "category": "17-03-2011",
            "value": 92.996,
            "value2": 62.9125
          },
          {
            "category": "18-03-2011",
            "value": 91.8244,
            "value2": 62.1918
          },
          {
            "category": "19-03-2011",
            "value": 89.1922,
            "value2": 62.1918
          },
          {
            "category": "20-03-2011",
            "value": 89.1922,
            "value2": 62.1918
          },
          {
            "category": "21-03-2011",
            "value": 89.0751,
            "value2": 62.0752
          },
          {
            "category": "22-03-2011",
            "value": 89.8856,
            "value2": 62.5839
          },
          {
            "category": "23-03-2011",
            "value": 90.3322,
            "value2": 63.2513
          },
          {
            "category": "24-03-2011",
            "value": 91.403,
            "value2": 63.7206
          },
          {
            "category": "25-03-2011",
            "value": 93.1381,
            "value2": 64.9203
          },
          {
            "category": "26-03-2011",
            "value": 93.1381,
            "value2": 64.9203
          },
          {
            "category": "27-03-2011",
            "value": 93.1381,
            "value2": 64.9203
          },
          {
            "category": "28-03-2011",
            "value": 94.0142,
            "value2": 65.2632
          },
          {
            "category": "29-03-2011",
            "value": 95.8323,
            "value2": 65.7225
          },
          {
            "category": "30-03-2011",
            "value": 96.7634,
            "value2": 66.4382
          },
          {
            "category": "31-03-2011",
            "value": 97.8811,
            "value2": 66.7945
          },
          {
            "category": "01-04-2011",
            "value": 97.6029,
            "value2": 67.0385
          },
          {
            "category": "02-04-2011",
            "value": 96.0429,
            "value2": 67.0385
          },
          {
            "category": "03-04-2011",
            "value": 96.0429,
            "value2": 67.0385
          },
          {
            "category": "04-04-2011",
            "value": 97.248,
            "value2": 68.014
          },
          {
            "category": "05-04-2011",
            "value": 97.2877,
            "value2": 68.2299
          },
          {
            "category": "06-04-2011",
            "value": 97.4211,
            "value2": 68.1909
          },
          {
            "category": "07-04-2011",
            "value": 97.6482,
            "value2": 68.3083
          },
          {
            "category": "08-04-2011",
            "value": 97.1044,
            "value2": 67.701
          },
          {
            "category": "09-04-2011",
            "value": 97.1044,
            "value2": 67.701
          },
          {
            "category": "10-04-2011",
            "value": 97.1044,
            "value2": 67.701
          },
          {
            "category": "11-04-2011",
            "value": 96.2928,
            "value2": 67.0938
          },
          {
            "category": "12-04-2011",
            "value": 96.2908,
            "value2": 67.0938
          },
          {
            "category": "13-04-2011",
            "value": 97.8746,
            "value2": 68.3459
          },
          {
            "category": "14-04-2011",
            "value": 97.8742,
            "value2": 68.3459
          },
          {
            "category": "15-04-2011",
            "value": 97.1176,
            "value2": 67.6055
          },
          {
            "category": "16-04-2011",
            "value": 97.1176,
            "value2": 67.6055
          },
          {
            "category": "17-04-2011",
            "value": 97.1176,
            "value2": 67.6055
          },
          {
            "category": "18-04-2011",
            "value": 95.9651,
            "value2": 66.6756
          },
          {
            "category": "19-04-2011",
            "value": 95.8966,
            "value2": 66.8103
          },
          {
            "category": "20-04-2011",
            "value": 97.3844,
            "value2": 67.8949
          },
          {
            "category": "21-04-2011",
            "value": 98.2166,
            "value2": 68.1163
          },
          {
            "category": "22-04-2011",
            "value": 98.2163,
            "value2": 68.1163
          },
          {
            "category": "23-04-2011",
            "value": 98.2163,
            "value2": 68.1163
          },
          {
            "category": "24-04-2011",
            "value": 98.2163,
            "value2": 68.1163
          },
          {
            "category": "25-04-2011",
            "value": 98.5934,
            "value2": 68.1122
          },
          {
            "category": "26-04-2011",
            "value": 98.571,
            "value2": 68.051
          },
          {
            "category": "27-04-2011",
            "value": 99.3195,
            "value2": 67.8208
          },
          {
            "category": "28-04-2011",
            "value": 98.5727,
            "value2": 67.2863
          },
          {
            "category": "29-04-2011",
            "value": 98.0387,
            "value2": 66.7259
          },
          {
            "category": "30-04-2011",
            "value": 98.0387,
            "value2": 66.7259
          },
          {
            "category": "01-05-2011",
            "value": 99.6133,
            "value2": 66.7259
          },
          {
            "category": "02-05-2011",
            "value": 99.0025,
            "value2": 66.182
          },
          {
            "category": "03-05-2011",
            "value": 97.6636,
            "value2": 64.7163
          },
          {
            "category": "04-05-2011",
            "value": 97.4647,
            "value2": 64.4106
          },
          {
            "category": "05-05-2011",
            "value": 97.1799,
            "value2": 63.5889
          },
          {
            "category": "06-05-2011",
            "value": 98.2136,
            "value2": 64.5503
          },
          {
            "category": "07-05-2011",
            "value": 98.2136,
            "value2": 64.5503
          },
          {
            "category": "08-05-2011",
            "value": 98.2136,
            "value2": 64.5503
          },
          {
            "category": "09-05-2011",
            "value": 98.9579,
            "value2": 64.6117
          },
          {
            "category": "10-05-2011",
            "value": 98.3644,
            "value2": 64.5641
          },
          {
            "category": "11-05-2011",
            "value": 99.1822,
            "value2": 64.8635
          },
          {
            "category": "12-05-2011",
            "value": 98.3854,
            "value2": 64.1014
          },
          {
            "category": "13-05-2011",
            "value": 99.0326,
            "value2": 64.7804
          },
          {
            "category": "14-05-2011",
            "value": 99.0326,
            "value2": 64.7804
          },
          {
            "category": "15-05-2011",
            "value": 99.0326,
            "value2": 64.7804
          },
          {
            "category": "16-05-2011",
            "value": 99.2505,
            "value2": 64.2294
          },
          {
            "category": "17-05-2011",
            "value": 98.2278,
            "value2": 63.647
          },
          {
            "category": "18-05-2011",
            "value": 97.4762,
            "value2": 63.3281
          },
          {
            "category": "19-05-2011",
            "value": 97.3643,
            "value2": 63.2984
          },
          {
            "category": "20-05-2011",
            "value": 97.9111,
            "value2": 63.8622
          },
          {
            "category": "21-05-2011",
            "value": 97.9111,
            "value2": 63.8622
          },
          {
            "category": "22-05-2011",
            "value": 97.9111,
            "value2": 63.8622
          },
          {
            "category": "23-05-2011",
            "value": 96.7546,
            "value2": 62.8063
          },
          {
            "category": "24-05-2011",
            "value": 97.0828,
            "value2": 62.9297
          },
          {
            "category": "25-05-2011",
            "value": 96.2438,
            "value2": 62.5029
          },
          {
            "category": "26-05-2011",
            "value": 97.29,
            "value2": 63.0423
          },
          {
            "category": "27-05-2011",
            "value": 98.0625,
            "value2": 63.8558
          },
          {
            "category": "28-05-2011",
            "value": 98.0625,
            "value2": 63.8558
          },
          {
            "category": "29-05-2011",
            "value": 98.0625,
            "value2": 63.8558
          },
          {
            "category": "30-05-2011",
            "value": 98.3799,
            "value2": 64.0592
          },
          {
            "category": "31-05-2011",
            "value": 99.9625,
            "value2": 65.0807
          },
          {
            "category": "01-06-2011",
            "value": 100.937,
            "value2": 65.4596
          },
          {
            "category": "02-06-2011",
            "value": 100.489,
            "value2": 65.0502
          },
          {
            "category": "03-06-2011",
            "value": 99.8774,
            "value2": 64.6565
          },
          {
            "category": "04-06-2011",
            "value": 99.8774,
            "value2": 64.6565
          },
          {
            "category": "05-06-2011",
            "value": 99.8774,
            "value2": 64.6565
          },
          {
            "category": "06-06-2011",
            "value": 100.323,
            "value2": 64.7315
          },
          {
            "category": "07-06-2011",
            "value": 100.58,
            "value2": 65.0792
          },
          {
            "category": "08-06-2011",
            "value": 99.9236,
            "value2": 64.8573
          },
          {
            "category": "09-06-2011",
            "value": 99.6914,
            "value2": 64.7998
          },
          {
            "category": "10-06-2011",
            "value": 99.279,
            "value2": 64.4808
          },
          {
            "category": "11-06-2011",
            "value": 99.279,
            "value2": 64.4808
          },
          {
            "category": "12-06-2011",
            "value": 99.279,
            "value2": 64.4808
          },
          {
            "category": "13-06-2011",
            "value": 99.3448,
            "value2": 64.5294
          },
          {
            "category": "14-06-2011",
            "value": 99.4052,
            "value2": 64.7641
          },
          {
            "category": "15-06-2011",
            "value": 98.8437,
            "value2": 64.1889
          },
          {
            "category": "16-06-2011",
            "value": 98.0612,
            "value2": 63.6882
          },
          {
            "category": "17-06-2011",
            "value": 97.6081,
            "value2": 63.2499
          },
          {
            "category": "18-06-2011",
            "value": 97.6081,
            "value2": 63.2499
          },
          {
            "category": "19-06-2011",
            "value": 97.6081,
            "value2": 63.2499
          },
          {
            "category": "20-06-2011",
            "value": 96.6943,
            "value2": 61.8827
          },
          {
            "category": "21-06-2011",
            "value": 96.9609,
            "value2": 61.9547
          },
          {
            "category": "22-06-2011",
            "value": 96.614,
            "value2": 61.8211
          },
          {
            "category": "23-06-2011",
            "value": 97.0396,
            "value2": 62.1472
          },
          {
            "category": "24-06-2011",
            "value": 99.6601,
            "value2": 63.7458
          },
          {
            "category": "25-06-2011",
            "value": 99.6601,
            "value2": 63.7458
          },
          {
            "category": "26-06-2011",
            "value": 99.6601,
            "value2": 63.7458
          },
          {
            "category": "27-06-2011",
            "value": 100.508,
            "value2": 64.419
          },
          {
            "category": "28-06-2011",
            "value": 100.574,
            "value2": 64.652
          },
          {
            "category": "29-06-2011",
            "value": 101.2,
            "value2": 65.2385
          },
          {
            "category": "30-06-2011",
            "value": 101.978,
            "value2": 65.6305
          },
          {
            "category": "01-07-2011",
            "value": 101.645,
            "value2": 65.6737
          },
          {
            "category": "02-07-2011",
            "value": 101.645,
            "value2": 65.6737
          },
          {
            "category": "03-07-2011",
            "value": 101.645,
            "value2": 65.6737
          },
          {
            "category": "04-07-2011",
            "value": 102.35,
            "value2": 66.1136
          },
          {
            "category": "05-07-2011",
            "value": 102.063,
            "value2": 65.9522
          },
          {
            "category": "06-07-2011",
            "value": 101.913,
            "value2": 65.8828
          },
          {
            "category": "07-07-2011",
            "value": 103.205,
            "value2": 66.9384
          },
          {
            "category": "08-07-2011",
            "value": 102.086,
            "value2": 66.1997
          },
          {
            "category": "09-07-2011",
            "value": 102.086,
            "value2": 66.1997
          },
          {
            "category": "10-07-2011",
            "value": 102.086,
            "value2": 66.1997
          },
          {
            "category": "11-07-2011",
            "value": 101.355,
            "value2": 65.7546
          },
          {
            "category": "12-07-2011",
            "value": 100.326,
            "value2": 64.8938
          },
          {
            "category": "13-07-2011",
            "value": 101.003,
            "value2": 65.6024
          },
          {
            "category": "14-07-2011",
            "value": 101.171,
            "value2": 65.8282
          },
          {
            "category": "15-07-2011",
            "value": 100.981,
            "value2": 65.6883
          },
          {
            "category": "16-07-2011",
            "value": 100.981,
            "value2": 65.6883
          },
          {
            "category": "17-07-2011",
            "value": 100.981,
            "value2": 65.6883
          },
          {
            "category": "18-07-2011",
            "value": 100.995,
            "value2": 65.6411
          },
          {
            "category": "19-07-2011",
            "value": 101.392,
            "value2": 66.0702
          },
          {
            "category": "20-07-2011",
            "value": 100.38,
            "value2": 65.4619
          },
          {
            "category": "21-07-2011",
            "value": 100.332,
            "value2": 65.1402
          },
          {
            "category": "22-07-2011",
            "value": 101.553,
            "value2": 66.1198
          },
          {
            "category": "23-07-2011",
            "value": 101.553,
            "value2": 66.1198
          },
          {
            "category": "24-07-2011",
            "value": 101.553,
            "value2": 66.1198
          },
          {
            "category": "25-07-2011",
            "value": 102.045,
            "value2": 66.6154
          },
          {
            "category": "26-07-2011",
            "value": 100.959,
            "value2": 65.5827
          },
          {
            "category": "27-07-2011",
            "value": 101.825,
            "value2": 65.3867
          },
          {
            "category": "28-07-2011",
            "value": 100.892,
            "value2": 64.7188
          },
          {
            "category": "29-07-2011",
            "value": 100.604,
            "value2": 64.3876
          },
          {
            "category": "30-07-2011",
            "value": 100.604,
            "value2": 64.3876
          },
          {
            "category": "31-07-2011",
            "value": 100.604,
            "value2": 64.3876
          },
          {
            "category": "01-08-2011",
            "value": 100.633,
            "value2": 64.6301
          },
          {
            "category": "02-08-2011",
            "value": 100.092,
            "value2": 63.9498
          },
          {
            "category": "03-08-2011",
            "value": 99.3597,
            "value2": 63.4921
          },
          {
            "category": "04-08-2011",
            "value": 99.2678,
            "value2": 62.7958
          },
          {
            "category": "05-08-2011",
            "value": 97.6573,
            "value2": 61.4795
          },
          {
            "category": "06-08-2011",
            "value": 97.6573,
            "value2": 61.4795
          },
          {
            "category": "07-08-2011",
            "value": 97.6573,
            "value2": 61.4795
          },
          {
            "category": "08-08-2011",
            "value": 96.444,
            "value2": 60.4619
          },
          {
            "category": "09-08-2011",
            "value": 96.0707,
            "value2": 59.9051
          },
          {
            "category": "10-08-2011",
            "value": 97.1292,
            "value2": 60.9135
          },
          {
            "category": "11-08-2011",
            "value": 97.0366,
            "value2": 60.6697
          },
          {
            "category": "12-08-2011",
            "value": 96.4682,
            "value2": 60.0586
          },
          {
            "category": "13-08-2011",
            "value": 96.4682,
            "value2": 60.0586
          },
          {
            "category": "14-08-2011",
            "value": 96.4682,
            "value2": 60.0586
          },
          {
            "category": "15-08-2011",
            "value": 96.467,
            "value2": 60.0586
          },
          {
            "category": "16-08-2011",
            "value": 96.2036,
            "value2": 59.4201
          },
          {
            "category": "17-08-2011",
            "value": 96.9991,
            "value2": 59.4746
          },
          {
            "category": "18-08-2011",
            "value": 95.8689,
            "value2": 58.1865
          },
          {
            "category": "19-08-2011",
            "value": 94.9161,
            "value2": 57.2113
          },
          {
            "category": "20-08-2011",
            "value": 94.9161,
            "value2": 57.2113
          },
          {
            "category": "21-08-2011",
            "value": 94.9161,
            "value2": 57.2113
          },
          {
            "category": "22-08-2011",
            "value": 95.3373,
            "value2": 57.8902
          },
          {
            "category": "23-08-2011",
            "value": 96.0977,
            "value2": 58.4121
          },
          {
            "category": "24-08-2011",
            "value": 95.1122,
            "value2": 57.8281
          },
          {
            "category": "25-08-2011",
            "value": 94.0684,
            "value2": 57.345
          },
          {
            "category": "26-08-2011",
            "value": 93.2237,
            "value2": 56.1505
          },
          {
            "category": "27-08-2011",
            "value": 93.2237,
            "value2": 56.1505
          },
          {
            "category": "28-08-2011",
            "value": 93.2237,
            "value2": 56.1505
          },
          {
            "category": "29-08-2011",
            "value": 95.4325,
            "value2": 57.9665
          },
          {
            "category": "30-08-2011",
            "value": 96.9114,
            "value2": 58.8413
          },
          {
            "category": "31-08-2011",
            "value": 96.9109,
            "value2": 58.8413
          },
          {
            "category": "01-09-2011",
            "value": 96.8196,
            "value2": 58.8413
          },
          {
            "category": "02-09-2011",
            "value": 97.872,
            "value2": 59.3123
          },
          {
            "category": "03-09-2011",
            "value": 97.872,
            "value2": 59.3123
          },
          {
            "category": "04-09-2011",
            "value": 97.872,
            "value2": 59.3123
          },
          {
            "category": "05-09-2011",
            "value": 97.6326,
            "value2": 59.2145
          },
          {
            "category": "06-09-2011",
            "value": 97.6544,
            "value2": 59.6307
          },
          {
            "category": "07-09-2011",
            "value": 98.7537,
            "value2": 60.4188
          },
          {
            "category": "08-09-2011",
            "value": 98.5018,
            "value2": 60.7055
          },
          {
            "category": "09-09-2011",
            "value": 97.714,
            "value2": 59.7426
          },
          {
            "category": "10-09-2011",
            "value": 97.714,
            "value2": 59.7426
          },
          {
            "category": "11-09-2011",
            "value": 97.714,
            "value2": 59.7426
          },
          {
            "category": "12-09-2011",
            "value": 96.0637,
            "value2": 58.481
          },
          {
            "category": "13-09-2011",
            "value": 95.9836,
            "value2": 58.4569
          },
          {
            "category": "14-09-2011",
            "value": 96.2927,
            "value2": 59.1005
          },
          {
            "category": "15-09-2011",
            "value": 97.273,
            "value2": 59.7349
          },
          {
            "category": "16-09-2011",
            "value": 97.1102,
            "value2": 59.9004
          },
          {
            "category": "17-09-2011",
            "value": 97.1102,
            "value2": 59.9004
          },
          {
            "category": "18-09-2011",
            "value": 97.1102,
            "value2": 59.9004
          },
          {
            "category": "19-09-2011",
            "value": 96.8601,
            "value2": 59.427
          },
          {
            "category": "20-09-2011",
            "value": 98.1058,
            "value2": 60.4532
          },
          {
            "category": "21-09-2011",
            "value": 97.9572,
            "value2": 60.5549
          },
          {
            "category": "22-09-2011",
            "value": 95.3368,
            "value2": 58.3025
          },
          {
            "category": "23-09-2011",
            "value": 94.1101,
            "value2": 57.7296
          },
          {
            "category": "24-09-2011",
            "value": 94.1101,
            "value2": 57.7296
          },
          {
            "category": "25-09-2011",
            "value": 94.1101,
            "value2": 57.7296
          },
          {
            "category": "26-09-2011",
            "value": 93.0447,
            "value2": 57.1581
          },
          {
            "category": "27-09-2011",
            "value": 94.7423,
            "value2": 58.518
          },
          {
            "category": "28-09-2011",
            "value": 94.3052,
            "value2": 58.1648
          },
          {
            "category": "29-09-2011",
            "value": 94.6022,
            "value2": 58.7138
          },
          {
            "category": "30-09-2011",
            "value": 93.6809,
            "value2": 57.9974
          },
          {
            "category": "01-10-2011",
            "value": 93.6809,
            "value2": 57.9974
          },
          {
            "category": "02-10-2011",
            "value": 93.6809,
            "value2": 57.9974
          },
          {
            "category": "03-10-2011",
            "value": 92.8967,
            "value2": 56.9871
          },
          {
            "category": "04-10-2011",
            "value": 91.7428,
            "value2": 56.1063
          },
          {
            "category": "05-10-2011",
            "value": 91.3574,
            "value2": 55.7985
          },
          {
            "category": "06-10-2011",
            "value": 91.2879,
            "value2": 55.7985
          },
          {
            "category": "07-10-2011",
            "value": 92.3759,
            "value2": 57.0551
          },
          {
            "category": "08-10-2011",
            "value": 92.3759,
            "value2": 57.0551
          },
          {
            "category": "09-10-2011",
            "value": 92.3759,
            "value2": 57.0551
          },
          {
            "category": "10-10-2011",
            "value": 93.2085,
            "value2": 58.0001
          },
          {
            "category": "11-10-2011",
            "value": 93.3096,
            "value2": 58.0746
          },
          {
            "category": "12-10-2011",
            "value": 94.6419,
            "value2": 59.3325
          },
          {
            "category": "13-10-2011",
            "value": 94.2305,
            "value2": 59.1419
          },
          {
            "category": "14-10-2011",
            "value": 94.7445,
            "value2": 59.6453
          },
          {
            "category": "15-10-2011",
            "value": 94.7445,
            "value2": 59.6453
          },
          {
            "category": "16-10-2011",
            "value": 94.7445,
            "value2": 59.6453
          },
          {
            "category": "17-10-2011",
            "value": 94.7822,
            "value2": 59.513
          },
          {
            "category": "18-10-2011",
            "value": 94.2963,
            "value2": 58.6576
          },
          {
            "category": "19-10-2011",
            "value": 95.7628,
            "value2": 59.6628
          },
          {
            "category": "20-10-2011",
            "value": 95.3987,
            "value2": 59.1973
          },
          {
            "category": "21-10-2011",
            "value": 94.8272,
            "value2": 58.7056
          },
          {
            "category": "22-10-2011",
            "value": 94.8272,
            "value2": 58.7056
          },
          {
            "category": "23-10-2011",
            "value": 94.7927,
            "value2": 58.7056
          },
          {
            "category": "24-10-2011",
            "value": 94.8671,
            "value2": 59.0412
          },
          {
            "category": "25-10-2011",
            "value": 95.4493,
            "value2": 59.8282
          },
          {
            "category": "26-10-2011",
            "value": 95.9854,
            "value2": 60.1027
          },
          {
            "category": "27-10-2011",
            "value": 95.9854,
            "value2": 60.1027
          },
          {
            "category": "28-10-2011",
            "value": 97.2012,
            "value2": 61.631
          },
          {
            "category": "29-10-2011",
            "value": 97.2012,
            "value2": 61.631
          },
          {
            "category": "30-10-2011",
            "value": 97.2012,
            "value2": 61.631
          },
          {
            "category": "31-10-2011",
            "value": 96.9802,
            "value2": 61.4628
          },
          {
            "category": "01-11-2011",
            "value": 96.7164,
            "value2": 60.8446
          },
          {
            "category": "02-11-2011",
            "value": 96.3391,
            "value2": 60.8308
          },
          {
            "category": "03-11-2011",
            "value": 96.426,
            "value2": 60.9489
          },
          {
            "category": "04-11-2011",
            "value": 97.3685,
            "value2": 61.2866
          },
          {
            "category": "05-11-2011",
            "value": 97.3685,
            "value2": 61.2866
          },
          {
            "category": "06-11-2011",
            "value": 97.3685,
            "value2": 61.2866
          },
          {
            "category": "07-11-2011",
            "value": 97.3673,
            "value2": 61.2866
          },
          {
            "category": "08-11-2011",
            "value": 97.6141,
            "value2": 61.2925
          },
          {
            "category": "09-11-2011",
            "value": 96.5966,
            "value2": 60.5047
          },
          {
            "category": "10-11-2011",
            "value": 96.5955,
            "value2": 60.5047
          },
          {
            "category": "11-11-2011",
            "value": 96.0649,
            "value2": 59.8705
          },
          {
            "category": "12-11-2011",
            "value": 96.0649,
            "value2": 59.8705
          },
          {
            "category": "13-11-2011",
            "value": 96.0649,
            "value2": 59.8705
          },
          {
            "category": "14-11-2011",
            "value": 96.4752,
            "value2": 59.4369
          },
          {
            "category": "15-11-2011",
            "value": 95.2945,
            "value2": 58.3467
          },
          {
            "category": "16-11-2011",
            "value": 94.2413,
            "value2": 57.8989
          },
          {
            "category": "17-11-2011",
            "value": 93.8409,
            "value2": 56.8547
          },
          {
            "category": "18-11-2011",
            "value": 94.1291,
            "value2": 56.4921
          },
          {
            "category": "19-11-2011",
            "value": 94.1291,
            "value2": 56.4921
          },
          {
            "category": "20-11-2011",
            "value": 94.1291,
            "value2": 56.4921
          },
          {
            "category": "21-11-2011",
            "value": 92.3231,
            "value2": 55.1716
          },
          {
            "category": "22-11-2011",
            "value": 92.4234,
            "value2": 55.4729
          },
          {
            "category": "23-11-2011",
            "value": 90.4023,
            "value2": 54.2979
          },
          {
            "category": "24-11-2011",
            "value": 91.2045,
            "value2": 54.9116
          },
          {
            "category": "25-11-2011",
            "value": 90.9175,
            "value2": 54.6293
          },
          {
            "category": "26-11-2011",
            "value": 90.9175,
            "value2": 54.6293
          },
          {
            "category": "27-11-2011",
            "value": 90.9175,
            "value2": 54.6293
          },
          {
            "category": "28-11-2011",
            "value": 92.5812,
            "value2": 56.0526
          },
          {
            "category": "29-11-2011",
            "value": 91.7405,
            "value2": 55.5093
          },
          {
            "category": "30-11-2011",
            "value": 91.9701,
            "value2": 55.6118
          },
          {
            "category": "01-12-2011",
            "value": 93.0046,
            "value2": 56.5905
          },
          {
            "category": "02-12-2011",
            "value": 94.426,
            "value2": 57.7976
          },
          {
            "category": "03-12-2011",
            "value": 94.426,
            "value2": 57.7976
          },
          {
            "category": "04-12-2011",
            "value": 94.426,
            "value2": 57.7976
          },
          {
            "category": "05-12-2011",
            "value": 94.2495,
            "value2": 57.7461
          },
          {
            "category": "06-12-2011",
            "value": 94.2479,
            "value2": 57.7461
          },
          {
            "category": "07-12-2011",
            "value": 94.3148,
            "value2": 57.9104
          },
          {
            "category": "08-12-2011",
            "value": 92.7818,
            "value2": 56.695
          },
          {
            "category": "09-12-2011",
            "value": 91.6336,
            "value2": 55.9286
          },
          {
            "category": "10-12-2011",
            "value": 91.6336,
            "value2": 55.9286
          },
          {
            "category": "11-12-2011",
            "value": 91.6336,
            "value2": 55.9286
          },
          {
            "category": "12-12-2011",
            "value": 90.1548,
            "value2": 54.7707
          },
          {
            "category": "13-12-2011",
            "value": 90.7738,
            "value2": 55.0106
          },
          {
            "category": "14-12-2011",
            "value": 90.1955,
            "value2": 54.5358
          },
          {
            "category": "15-12-2011",
            "value": 89.5792,
            "value2": 54.3159
          },
          {
            "category": "16-12-2011",
            "value": 88.0361,
            "value2": 53.2409
          },
          {
            "category": "17-12-2011",
            "value": 88.0361,
            "value2": 53.2409
          },
          {
            "category": "18-12-2011",
            "value": 88.0401,
            "value2": 53.2409
          },
          {
            "category": "19-12-2011",
            "value": 87.5286,
            "value2": 52.6212
          },
          {
            "category": "20-12-2011",
            "value": 86.6276,
            "value2": 51.774
          },
          {
            "category": "21-12-2011",
            "value": 88.454,
            "value2": 53.1527
          },
          {
            "category": "22-12-2011",
            "value": 88.9784,
            "value2": 53.644
          },
          {
            "category": "23-12-2011",
            "value": 88.5817,
            "value2": 53.4422
          },
          {
            "category": "24-12-2011",
            "value": 88.5817,
            "value2": 53.4422
          },
          {
            "category": "25-12-2011",
            "value": 88.597,
            "value2": 53.4422
          },
          {
            "category": "26-12-2011",
            "value": 90.074,
            "value2": 54.0639
          },
          {
            "category": "27-12-2011",
            "value": 89.8524,
            "value2": 53.7249
          },
          {
            "category": "28-12-2011",
            "value": 89.2773,
            "value2": 53.2032
          },
          {
            "category": "29-12-2011",
            "value": 88.4585,
            "value2": 52.7141
          },
          {
            "category": "30-12-2011",
            "value": 88.8934,
            "value2": 52.5413
          },
          {
            "category": "31-12-2011",
            "value": 88.8931,
            "value2": 52.5413
          },
          {
            "category": "01-01-2012",
            "value": 88.8776,
            "value2": 52.5413
          },
          {
            "category": "02-01-2012",
            "value": 88.6275,
            "value2": 52.6063
          },
          {
            "category": "03-01-2012",
            "value": 90.6474,
            "value2": 54.032
          },
          {
            "category": "04-01-2012",
            "value": 90.2855,
            "value2": 53.9596
          },
          {
            "category": "05-01-2012",
            "value": 90.7618,
            "value2": 53.9626
          },
          {
            "category": "06-01-2012",
            "value": 90.2459,
            "value2": 53.9852
          },
          {
            "category": "07-01-2012",
            "value": 90.162,
            "value2": 54.0561
          },
          {
            "category": "08-01-2012",
            "value": 90.162,
            "value2": 54.0561
          },
          {
            "category": "09-01-2012",
            "value": 89.647,
            "value2": 54.1047
          },
          {
            "category": "10-01-2012",
            "value": 91.0563,
            "value2": 55.3178
          },
          {
            "category": "11-01-2012",
            "value": 91.1167,
            "value2": 55.585
          },
          {
            "category": "12-01-2012",
            "value": 90.4016,
            "value2": 55.4658
          },
          {
            "category": "13-01-2012",
            "value": 91.0868,
            "value2": 55.9146
          },
          {
            "category": "14-01-2012",
            "value": 91.0868,
            "value2": 55.9146
          },
          {
            "category": "15-01-2012",
            "value": 91.0868,
            "value2": 55.9146
          },
          {
            "category": "16-01-2012",
            "value": 91.4253,
            "value2": 56.0003
          },
          {
            "category": "17-01-2012",
            "value": 92.737,
            "value2": 56.9338
          },
          {
            "category": "18-01-2012",
            "value": 92.7918,
            "value2": 56.6567
          },
          {
            "category": "19-01-2012",
            "value": 93.5237,
            "value2": 57.4154
          },
          {
            "category": "20-01-2012",
            "value": 94.03,
            "value2": 57.7148
          },
          {
            "category": "21-01-2012",
            "value": 94.03,
            "value2": 57.7148
          },
          {
            "category": "22-01-2012",
            "value": 94.03,
            "value2": 57.7148
          },
          {
            "category": "23-01-2012",
            "value": 93.6252,
            "value2": 57.7327
          },
          {
            "category": "24-01-2012",
            "value": 94.5309,
            "value2": 58.6317
          },
          {
            "category": "25-01-2012",
            "value": 94.7857,
            "value2": 59.1223
          },
          {
            "category": "26-01-2012",
            "value": 94.7852,
            "value2": 59.1223
          },
          {
            "category": "27-01-2012",
            "value": 95.7069,
            "value2": 59.6428
          },
          {
            "category": "28-01-2012",
            "value": 95.7069,
            "value2": 59.6428
          },
          {
            "category": "29-01-2012",
            "value": 95.7069,
            "value2": 59.6428
          },
          {
            "category": "30-01-2012",
            "value": 94.1231,
            "value2": 58.3974
          },
          {
            "category": "31-01-2012",
            "value": 94.9727,
            "value2": 59.6141
          },
          {
            "category": "01-02-2012",
            "value": 94.988,
            "value2": 60.1237
          },
          {
            "category": "02-02-2012",
            "value": 95.8658,
            "value2": 60.5394
          },
          {
            "category": "03-02-2012",
            "value": 96.4788,
            "value2": 61.1496
          },
          {
            "category": "04-02-2012",
            "value": 96.4788,
            "value2": 61.1496
          },
          {
            "category": "05-02-2012",
            "value": 96.4788,
            "value2": 61.1496
          },
          {
            "category": "06-02-2012",
            "value": 97.3564,
            "value2": 61.7135
          },
          {
            "category": "07-02-2012",
            "value": 96.7458,
            "value2": 61.3372
          },
          {
            "category": "08-02-2012",
            "value": 97.0972,
            "value2": 61.8052
          },
          {
            "category": "09-02-2012",
            "value": 97.7599,
            "value2": 62.3412
          },
          {
            "category": "10-02-2012",
            "value": 97.2766,
            "value2": 62.1388
          },
          {
            "category": "11-02-2012",
            "value": 97.2766,
            "value2": 62.1388
          },
          {
            "category": "12-02-2012",
            "value": 97.2766,
            "value2": 62.1388
          },
          {
            "category": "13-02-2012",
            "value": 97.9016,
            "value2": 62.2794
          },
          {
            "category": "14-02-2012",
            "value": 98.5958,
            "value2": 62.6348
          },
          {
            "category": "15-02-2012",
            "value": 100.18,
            "value2": 63.9489
          },
          {
            "category": "16-02-2012",
            "value": 100.599,
            "value2": 64.0306
          },
          {
            "category": "17-02-2012",
            "value": 100.348,
            "value2": 64.4395
          },
          {
            "category": "18-02-2012",
            "value": 100.348,
            "value2": 64.4395
          },
          {
            "category": "19-02-2012",
            "value": 100.348,
            "value2": 64.4395
          },
          {
            "category": "20-02-2012",
            "value": 100.347,
            "value2": 64.4395
          },
          {
            "category": "21-02-2012",
            "value": 101.163,
            "value2": 64.9208
          },
          {
            "category": "22-02-2012",
            "value": 100.107,
            "value2": 63.3831
          },
          {
            "category": "23-02-2012",
            "value": 99.5149,
            "value2": 63.1336
          },
          {
            "category": "24-02-2012",
            "value": 99.188,
            "value2": 62.576
          },
          {
            "category": "25-02-2012",
            "value": 99.1881,
            "value2": 62.576
          },
          {
            "category": "26-02-2012",
            "value": 99.1881,
            "value2": 62.576
          },
          {
            "category": "27-02-2012",
            "value": 97.1636,
            "value2": 60.8178
          },
          {
            "category": "28-02-2012",
            "value": 98.4198,
            "value2": 62.211
          },
          {
            "category": "29-02-2012",
            "value": 97.9802,
            "value2": 62.4428
          },
          {
            "category": "01-03-2012",
            "value": 97.6083,
            "value2": 62.0078
          },
          {
            "category": "02-03-2012",
            "value": 97.7463,
            "value2": 62.1185
          },
          {
            "category": "03-03-2012",
            "value": 97.8066,
            "value2": 62.1746
          },
          {
            "category": "04-03-2012",
            "value": 97.8066,
            "value2": 62.1746
          },
          {
            "category": "05-03-2012",
            "value": 96.6492,
            "value2": 61.3065
          },
          {
            "category": "06-03-2012",
            "value": 96.0509,
            "value2": 60.6649
          },
          {
            "category": "07-03-2012",
            "value": 95.9257,
            "value2": 60.5719
          },
          {
            "category": "08-03-2012",
            "value": 95.9257,
            "value2": 60.5719
          },
          {
            "category": "09-03-2012",
            "value": 97.5088,
            "value2": 61.8702
          },
          {
            "category": "10-03-2012",
            "value": 97.5088,
            "value2": 61.8702
          },
          {
            "category": "11-03-2012",
            "value": 97.5088,
            "value2": 61.8702
          },
          {
            "category": "12-03-2012",
            "value": 97.6205,
            "value2": 62.28
          },
          {
            "category": "13-03-2012",
            "value": 98.3616,
            "value2": 63.0497
          },
          {
            "category": "14-03-2012",
            "value": 99.038,
            "value2": 63.4046
          },
          {
            "category": "15-03-2012",
            "value": 98.0288,
            "value2": 62.4796
          },
          {
            "category": "16-03-2012",
            "value": 97.1222,
            "value2": 61.7294
          },
          {
            "category": "17-03-2012",
            "value": 97.1222,
            "value2": 61.7294
          },
          {
            "category": "18-03-2012",
            "value": 97.1222,
            "value2": 61.7294
          },
          {
            "category": "19-03-2012",
            "value": 96.3805,
            "value2": 61.0193
          },
          {
            "category": "20-03-2012",
            "value": 96.886,
            "value2": 61.2898
          },
          {
            "category": "21-03-2012",
            "value": 97.8161,
            "value2": 62.3688
          },
          {
            "category": "22-03-2012",
            "value": 96.8286,
            "value2": 60.8641
          },
          {
            "category": "23-03-2012",
            "value": 97.989,
            "value2": 61.3966
          },
          {
            "category": "24-03-2012",
            "value": 97.989,
            "value2": 61.3966
          },
          {
            "category": "25-03-2012",
            "value": 97.989,
            "value2": 61.3966
          },
          {
            "category": "26-03-2012",
            "value": 96.8696,
            "value2": 60.3219
          },
          {
            "category": "27-03-2012",
            "value": 97.7336,
            "value2": 60.8422
          },
          {
            "category": "28-03-2012",
            "value": 97.4263,
            "value2": 60.2975
          },
          {
            "category": "29-03-2012",
            "value": 96.8811,
            "value2": 60.2832
          },
          {
            "category": "30-03-2012",
            "value": 99.4252,
            "value2": 61.6037
          },
          {
            "category": "31-03-2012",
            "value": 99.0259,
            "value2": 61.6037
          },
          {
            "category": "01-04-2012",
            "value": 99.0256,
            "value2": 61.6037
          },
          {
            "category": "02-04-2012",
            "value": 99.4116,
            "value2": 62.0271
          },
          {
            "category": "03-04-2012",
            "value": 100.159,
            "value2": 62.5592
          },
          {
            "category": "04-04-2012",
            "value": 99.6377,
            "value2": 62.2944
          },
          {
            "category": "05-04-2012",
            "value": 99.6373,
            "value2": 62.2944
          },
          {
            "category": "06-04-2012",
            "value": 99.6373,
            "value2": 62.2944
          },
          {
            "category": "07-04-2012",
            "value": 99.6373,
            "value2": 62.2944
          },
          {
            "category": "08-04-2012",
            "value": 99.6373,
            "value2": 62.2944
          },
          {
            "category": "09-04-2012",
            "value": 97.9074,
            "value2": 61.3433
          },
          {
            "category": "10-04-2012",
            "value": 99.0067,
            "value2": 61.3736
          },
          {
            "category": "11-04-2012",
            "value": 99.2114,
            "value2": 61.1284
          },
          {
            "category": "12-04-2012",
            "value": 99.6869,
            "value2": 61.6202
          },
          {
            "category": "13-04-2012",
            "value": 98.9333,
            "value2": 60.9727
          },
          {
            "category": "14-04-2012",
            "value": 98.9333,
            "value2": 60.9727
          },
          {
            "category": "15-04-2012",
            "value": 98.9333,
            "value2": 60.9727
          },
          {
            "category": "16-04-2012",
            "value": 99.0053,
            "value2": 61.2365
          },
          {
            "category": "17-04-2012",
            "value": 100.422,
            "value2": 61.876
          },
          {
            "category": "18-04-2012",
            "value": 100.824,
            "value2": 62.0389
          },
          {
            "category": "19-04-2012",
            "value": 101.684,
            "value2": 62.2814
          },
          {
            "category": "20-04-2012",
            "value": 101.636,
            "value2": 61.7742
          },
          {
            "category": "21-04-2012",
            "value": 101.636,
            "value2": 61.7742
          },
          {
            "category": "22-04-2012",
            "value": 101.636,
            "value2": 61.7742
          },
          {
            "category": "23-04-2012",
            "value": 99.8998,
            "value2": 60.7275
          },
          {
            "category": "24-04-2012",
            "value": 99.8639,
            "value2": 60.8842
          },
          {
            "category": "25-04-2012",
            "value": 100.594,
            "value2": 60.6164
          },
          {
            "category": "26-04-2012",
            "value": 99.4316,
            "value2": 60.4263
          },
          {
            "category": "27-04-2012",
            "value": 99.2764,
            "value2": 60.3645
          },
          {
            "category": "28-04-2012",
            "value": 99.6354,
            "value2": 60.6139
          },
          {
            "category": "29-04-2012",
            "value": 99.6354,
            "value2": 60.6139
          },
          {
            "category": "30-04-2012",
            "value": 100.036,
            "value2": 61.0538
          },
          {
            "category": "01-05-2012",
            "value": 99.931,
            "value2": 61.0538
          },
          {
            "category": "02-05-2012",
            "value": 100.041,
            "value2": 60.9579
          },
          {
            "category": "03-05-2012",
            "value": 98.5994,
            "value2": 60.3584
          },
          {
            "category": "04-05-2012",
            "value": 96.4223,
            "value2": 59.2179
          },
          {
            "category": "05-05-2012",
            "value": 96.4223,
            "value2": 59.2179
          },
          {
            "category": "06-05-2012",
            "value": 96.4223,
            "value2": 59.2179
          },
          {
            "category": "07-05-2012",
            "value": 96.4953,
            "value2": 59.5639
          },
          {
            "category": "08-05-2012",
            "value": 95.0187,
            "value2": 58.3896
          },
          {
            "category": "09-05-2012",
            "value": 94.5518,
            "value2": 57.9539
          },
          {
            "category": "10-05-2012",
            "value": 94.8129,
            "value2": 57.869
          },
          {
            "category": "11-05-2012",
            "value": 93.9758,
            "value2": 57.4318
          },
          {
            "category": "12-05-2012",
            "value": 93.9758,
            "value2": 57.4318
          },
          {
            "category": "13-05-2012",
            "value": 93.9758,
            "value2": 57.4318
          },
          {
            "category": "14-05-2012",
            "value": 93.7132,
            "value2": 57.0858
          },
          {
            "category": "15-05-2012",
            "value": 94.2309,
            "value2": 57.4875
          },
          {
            "category": "16-05-2012",
            "value": 93.1632,
            "value2": 56.6455
          },
          {
            "category": "17-05-2012",
            "value": 94.1099,
            "value2": 56.7235
          },
          {
            "category": "18-05-2012",
            "value": 94.3751,
            "value2": 56.891
          },
          {
            "category": "19-05-2012",
            "value": 94.3751,
            "value2": 56.891
          },
          {
            "category": "20-05-2012",
            "value": 94.3751,
            "value2": 56.891
          },
          {
            "category": "21-05-2012",
            "value": 94.5518,
            "value2": 57.0948
          },
          {
            "category": "22-05-2012",
            "value": 93.8195,
            "value2": 56.6034
          },
          {
            "category": "23-05-2012",
            "value": 93.3061,
            "value2": 56.3002
          },
          {
            "category": "24-05-2012",
            "value": 94.1199,
            "value2": 57.1563
          },
          {
            "category": "25-05-2012",
            "value": 94.3123,
            "value2": 57.2471
          },
          {
            "category": "26-05-2012",
            "value": 94.3127,
            "value2": 57.2471
          },
          {
            "category": "27-05-2012",
            "value": 94.3127,
            "value2": 57.2471
          },
          {
            "category": "28-05-2012",
            "value": 95.2945,
            "value2": 57.9582
          },
          {
            "category": "29-05-2012",
            "value": 95.4409,
            "value2": 57.971
          },
          {
            "category": "30-05-2012",
            "value": 95.0789,
            "value2": 57.4027
          },
          {
            "category": "31-05-2012",
            "value": 94.8346,
            "value2": 57.3941
          },
          {
            "category": "01-06-2012",
            "value": 93.7955,
            "value2": 56.4545
          },
          {
            "category": "02-06-2012",
            "value": 93.7955,
            "value2": 56.4545
          },
          {
            "category": "03-06-2012",
            "value": 93.7955,
            "value2": 56.4545
          },
          {
            "category": "04-06-2012",
            "value": 93.7406,
            "value2": 56.4702
          },
          {
            "category": "05-06-2012",
            "value": 94.2102,
            "value2": 56.6174
          },
          {
            "category": "06-06-2012",
            "value": 96.1719,
            "value2": 57.9937
          },
          {
            "category": "07-06-2012",
            "value": 97.2049,
            "value2": 58.5801
          },
          {
            "category": "08-06-2012",
            "value": 97.1559,
            "value2": 58.7884
          },
          {
            "category": "09-06-2012",
            "value": 97.1559,
            "value2": 58.7884
          },
          {
            "category": "10-06-2012",
            "value": 97.1559,
            "value2": 58.7884
          },
          {
            "category": "11-06-2012",
            "value": 96.8852,
            "value2": 58.6531
          },
          {
            "category": "12-06-2012",
            "value": 97.6783,
            "value2": 59.2822
          },
          {
            "category": "13-06-2012",
            "value": 97.6695,
            "value2": 59.3279
          },
          {
            "category": "14-06-2012",
            "value": 96.8481,
            "value2": 58.6156
          },
          {
            "category": "15-06-2012",
            "value": 98.0254,
            "value2": 59.4189
          },
          {
            "category": "16-06-2012",
            "value": 98.0254,
            "value2": 59.4189
          },
          {
            "category": "17-06-2012",
            "value": 98.0254,
            "value2": 59.4189
          },
          {
            "category": "18-06-2012",
            "value": 97.0563,
            "value2": 58.5896
          },
          {
            "category": "19-06-2012",
            "value": 97.6087,
            "value2": 58.9944
          },
          {
            "category": "20-06-2012",
            "value": 97.7927,
            "value2": 59.3082
          },
          {
            "category": "21-06-2012",
            "value": 98.3781,
            "value2": 59.8392
          },
          {
            "category": "22-06-2012",
            "value": 98.2757,
            "value2": 59.7227
          },
          {
            "category": "23-06-2012",
            "value": 98.2757,
            "value2": 59.7227
          },
          {
            "category": "24-06-2012",
            "value": 98.2757,
            "value2": 59.7227
          },
          {
            "category": "25-06-2012",
            "value": 97.5496,
            "value2": 59.4515
          },
          {
            "category": "26-06-2012",
            "value": 97.3731,
            "value2": 59.6027
          },
          {
            "category": "27-06-2012",
            "value": 97.685,
            "value2": 59.8403
          },
          {
            "category": "28-06-2012",
            "value": 98.207,
            "value2": 59.9283
          },
          {
            "category": "29-06-2012",
            "value": 99.9715,
            "value2": 61.3185
          },
          {
            "category": "30-06-2012",
            "value": 99.9715,
            "value2": 61.3185
          },
          {
            "category": "01-07-2012",
            "value": 99.9715,
            "value2": 61.3185
          },
          {
            "category": "02-07-2012",
            "value": 99.6745,
            "value2": 61.4827
          },
          {
            "category": "03-07-2012",
            "value": 99.711,
            "value2": 61.7258
          },
          {
            "category": "04-07-2012",
            "value": 99.8315,
            "value2": 61.9602
          },
          {
            "category": "05-07-2012",
            "value": 100.07,
            "value2": 62.326
          },
          {
            "category": "06-07-2012",
            "value": 99.6878,
            "value2": 62.1406
          },
          {
            "category": "07-07-2012",
            "value": 99.6878,
            "value2": 62.1406
          },
          {
            "category": "08-07-2012",
            "value": 99.6878,
            "value2": 62.1406
          },
          {
            "category": "09-07-2012",
            "value": 98.7848,
            "value2": 61.5607
          },
          {
            "category": "10-07-2012",
            "value": 100.015,
            "value2": 62.3247
          },
          {
            "category": "11-07-2012",
            "value": 99.7224,
            "value2": 61.9962
          },
          {
            "category": "12-07-2012",
            "value": 98.838,
            "value2": 61.3501
          },
          {
            "category": "13-07-2012",
            "value": 98.8136,
            "value2": 61.2321
          },
          {
            "category": "14-07-2012",
            "value": 98.8136,
            "value2": 61.2321
          },
          {
            "category": "15-07-2012",
            "value": 98.8136,
            "value2": 61.2321
          },
          {
            "category": "16-07-2012",
            "value": 98.6419,
            "value2": 60.921
          },
          {
            "category": "17-07-2012",
            "value": 98.6291,
            "value2": 60.7474
          },
          {
            "category": "18-07-2012",
            "value": 99.0127,
            "value2": 61.0091
          },
          {
            "category": "19-07-2012",
            "value": 99.4218,
            "value2": 61.2692
          },
          {
            "category": "20-07-2012",
            "value": 98.9725,
            "value2": 60.9729
          },
          {
            "category": "21-07-2012",
            "value": 98.9725,
            "value2": 60.9729
          },
          {
            "category": "22-07-2012",
            "value": 98.9725,
            "value2": 60.9729
          },
          {
            "category": "23-07-2012",
            "value": 97.7334,
            "value2": 60.0073
          },
          {
            "category": "24-07-2012",
            "value": 97.6422,
            "value2": 60.1888
          },
          {
            "category": "25-07-2012",
            "value": 97.7487,
            "value2": 59.9079
          },
          {
            "category": "26-07-2012",
            "value": 96.6795,
            "value2": 59.0754
          },
          {
            "category": "27-07-2012",
            "value": 97.1508,
            "value2": 59.3582
          },
          {
            "category": "28-07-2012",
            "value": 97.1508,
            "value2": 59.3582
          },
          {
            "category": "29-07-2012",
            "value": 97.1508,
            "value2": 59.3582
          },
          {
            "category": "30-07-2012",
            "value": 98.7441,
            "value2": 60.4814
          },
          {
            "category": "31-07-2012",
            "value": 98.7246,
            "value2": 60.7919
          },
          {
            "category": "01-08-2012",
            "value": 98.2338,
            "value2": 61.0493
          },
          {
            "category": "02-08-2012",
            "value": 97.9878,
            "value2": 61.0326
          },
          {
            "category": "03-08-2012",
            "value": 98.0817,
            "value2": 60.8922
          },
          {
            "category": "04-08-2012",
            "value": 98.0817,
            "value2": 60.8922
          },
          {
            "category": "05-08-2012",
            "value": 98.0817,
            "value2": 60.8922
          },
          {
            "category": "06-08-2012",
            "value": 98.7779,
            "value2": 61.4996
          },
          {
            "category": "07-08-2012",
            "value": 99.667,
            "value2": 62.0146
          },
          {
            "category": "08-08-2012",
            "value": 99.5214,
            "value2": 61.9686
          },
          {
            "category": "09-08-2012",
            "value": 99.3289,
            "value2": 61.8214
          },
          {
            "category": "10-08-2012",
            "value": 98.7197,
            "value2": 61.7631
          },
          {
            "category": "11-08-2012",
            "value": 98.7197,
            "value2": 61.7631
          },
          {
            "category": "12-08-2012",
            "value": 98.7197,
            "value2": 61.7631
          },
          {
            "category": "13-08-2012",
            "value": 99.1991,
            "value2": 62.1433
          },
          {
            "category": "14-08-2012",
            "value": 99.3035,
            "value2": 62.4752
          },
          {
            "category": "15-08-2012",
            "value": 99.3035,
            "value2": 62.4752
          },
          {
            "category": "16-08-2012",
            "value": 99.3586,
            "value2": 62.3281
          },
          {
            "category": "17-08-2012",
            "value": 99.3965,
            "value2": 62.3461
          },
          {
            "category": "18-08-2012",
            "value": 99.3965,
            "value2": 62.3461
          },
          {
            "category": "19-08-2012",
            "value": 99.3965,
            "value2": 62.3461
          },
          {
            "category": "20-08-2012",
            "value": 99.3959,
            "value2": 62.3461
          },
          {
            "category": "21-08-2012",
            "value": 100.234,
            "value2": 62.8795
          },
          {
            "category": "22-08-2012",
            "value": 100.352,
            "value2": 62.7428
          },
          {
            "category": "23-08-2012",
            "value": 100.688,
            "value2": 62.7063
          },
          {
            "category": "24-08-2012",
            "value": 100.476,
            "value2": 62.4371
          },
          {
            "category": "25-08-2012",
            "value": 100.476,
            "value2": 62.4371
          },
          {
            "category": "26-08-2012",
            "value": 100.476,
            "value2": 62.4371
          },
          {
            "category": "27-08-2012",
            "value": 100.077,
            "value2": 61.9642
          },
          {
            "category": "28-08-2012",
            "value": 99.48,
            "value2": 61.6676
          },
          {
            "category": "29-08-2012",
            "value": 98.8685,
            "value2": 61.2094
          },
          {
            "category": "30-08-2012",
            "value": 98.9905,
            "value2": 61.4274
          },
          {
            "category": "31-08-2012",
            "value": 98.2776,
            "value2": 61.1498
          },
          {
            "category": "01-09-2012",
            "value": 98.2776,
            "value2": 61.1498
          },
          {
            "category": "02-09-2012",
            "value": 98.2776,
            "value2": 61.1498
          },
          {
            "category": "03-09-2012",
            "value": 98.3826,
            "value2": 61.0045
          },
          {
            "category": "04-09-2012",
            "value": 98.2334,
            "value2": 61.2761
          },
          {
            "category": "05-09-2012",
            "value": 97.7053,
            "value2": 60.8231
          },
          {
            "category": "06-09-2012",
            "value": 97.7097,
            "value2": 61.0028
          },
          {
            "category": "07-09-2012",
            "value": 98.6376,
            "value2": 61.9991
          },
          {
            "category": "08-09-2012",
            "value": 98.9546,
            "value2": 62.2485
          },
          {
            "category": "09-09-2012",
            "value": 98.9546,
            "value2": 62.2485
          },
          {
            "category": "10-09-2012",
            "value": 99.2858,
            "value2": 62.324
          },
          {
            "category": "11-09-2012",
            "value": 99.369,
            "value2": 62.5845
          },
          {
            "category": "12-09-2012",
            "value": 99.8876,
            "value2": 62.9621
          },
          {
            "category": "13-09-2012",
            "value": 100.065,
            "value2": 62.9537
          },
          {
            "category": "14-09-2012",
            "value": 101.703,
            "value2": 64.233
          },
          {
            "category": "15-09-2012",
            "value": 101.703,
            "value2": 64.233
          },
          {
            "category": "16-09-2012",
            "value": 101.703,
            "value2": 64.233
          },
          {
            "category": "17-09-2012",
            "value": 101.852,
            "value2": 64.7451
          },
          {
            "category": "18-09-2012",
            "value": 101.804,
            "value2": 64.8669
          },
          {
            "category": "19-09-2012",
            "value": 101.803,
            "value2": 64.8669
          },
          {
            "category": "20-09-2012",
            "value": 100.776,
            "value2": 64.4123
          },
          {
            "category": "21-09-2012",
            "value": 102.247,
            "value2": 65.8141
          },
          {
            "category": "22-09-2012",
            "value": 102.247,
            "value2": 65.8141
          },
          {
            "category": "23-09-2012",
            "value": 102.247,
            "value2": 65.8141
          },
          {
            "category": "24-09-2012",
            "value": 102.011,
            "value2": 65.7468
          },
          {
            "category": "25-09-2012",
            "value": 101.832,
            "value2": 65.8959
          },
          {
            "category": "26-09-2012",
            "value": 101.626,
            "value2": 65.828
          },
          {
            "category": "27-09-2012",
            "value": 101.542,
            "value2": 65.8206
          },
          {
            "category": "28-09-2012",
            "value": 102.284,
            "value2": 66.504
          },
          {
            "category": "29-09-2012",
            "value": 102.284,
            "value2": 66.504
          },
          {
            "category": "30-09-2012",
            "value": 102.284,
            "value2": 66.504
          },
          {
            "category": "01-10-2012",
            "value": 102.415,
            "value2": 66.8287
          },
          {
            "category": "02-10-2012",
            "value": 102.411,
            "value2": 66.8287
          },
          {
            "category": "03-10-2012",
            "value": 102.563,
            "value2": 67.0699
          },
          {
            "category": "04-10-2012",
            "value": 103.193,
            "value2": 67.6332
          },
          {
            "category": "05-10-2012",
            "value": 102.095,
            "value2": 67.1788
          },
          {
            "category": "06-10-2012",
            "value": 102.095,
            "value2": 67.1788
          },
          {
            "category": "07-10-2012",
            "value": 102.095,
            "value2": 67.1788
          },
          {
            "category": "08-10-2012",
            "value": 101.188,
            "value2": 66.4857
          },
          {
            "category": "09-10-2012",
            "value": 101.992,
            "value2": 66.811
          },
          {
            "category": "10-10-2012",
            "value": 101.522,
            "value2": 66.155
          },
          {
            "category": "11-10-2012",
            "value": 102.295,
            "value2": 66.8307
          },
          {
            "category": "12-10-2012",
            "value": 101.771,
            "value2": 66.5744
          },
          {
            "category": "13-10-2012",
            "value": 101.771,
            "value2": 66.5744
          },
          {
            "category": "14-10-2012",
            "value": 101.771,
            "value2": 66.5744
          },
          {
            "category": "15-10-2012",
            "value": 101.739,
            "value2": 66.653
          },
          {
            "category": "16-10-2012",
            "value": 101.691,
            "value2": 66.1911
          },
          {
            "category": "17-10-2012",
            "value": 102.066,
            "value2": 66.2899
          },
          {
            "category": "18-10-2012",
            "value": 103.021,
            "value2": 66.9987
          },
          {
            "category": "19-10-2012",
            "value": 102.744,
            "value2": 66.5995
          },
          {
            "category": "20-10-2012",
            "value": 102.744,
            "value2": 66.5995
          },
          {
            "category": "21-10-2012",
            "value": 102.744,
            "value2": 66.5995
          },
          {
            "category": "22-10-2012",
            "value": 103.165,
            "value2": 66.8793
          },
          {
            "category": "23-10-2012",
            "value": 102.791,
            "value2": 66.6126
          },
          {
            "category": "24-10-2012",
            "value": 102.791,
            "value2": 66.6126
          },
          {
            "category": "25-10-2012",
            "value": 103.158,
            "value2": 66.7601
          },
          {
            "category": "26-10-2012",
            "value": 102.71,
            "value2": 66.1809
          },
          {
            "category": "27-10-2012",
            "value": 102.71,
            "value2": 66.1809
          },
          {
            "category": "28-10-2012",
            "value": 102.71,
            "value2": 66.1809
          },
          {
            "category": "29-10-2012",
            "value": 102.599,
            "value2": 66.1171
          },
          {
            "category": "30-10-2012",
            "value": 101.87,
            "value2": 65.3623
          },
          {
            "category": "31-10-2012",
            "value": 102.328,
            "value2": 65.734
          },
          {
            "category": "01-11-2012",
            "value": 102.313,
            "value2": 66.1387
          },
          {
            "category": "02-11-2012",
            "value": 103.188,
            "value2": 66.7139
          },
          {
            "category": "03-11-2012",
            "value": 103.188,
            "value2": 66.7139
          },
          {
            "category": "04-11-2012",
            "value": 103.188,
            "value2": 66.7139
          },
          {
            "category": "05-11-2012",
            "value": 103.466,
            "value2": 66.7014
          },
          {
            "category": "06-11-2012",
            "value": 103.977,
            "value2": 66.9656
          },
          {
            "category": "07-11-2012",
            "value": 104.643,
            "value2": 67.3852
          },
          {
            "category": "08-11-2012",
            "value": 104.373,
            "value2": 67.2657
          },
          {
            "category": "09-11-2012",
            "value": 103.749,
            "value2": 66.6991
          },
          {
            "category": "10-11-2012",
            "value": 103.749,
            "value2": 66.6991
          },
          {
            "category": "11-11-2012",
            "value": 103.749,
            "value2": 66.6991
          },
          {
            "category": "12-11-2012",
            "value": 104.239,
            "value2": 66.8454
          },
          {
            "category": "13-11-2012",
            "value": 104.062,
            "value2": 66.836
          },
          {
            "category": "14-11-2012",
            "value": 104.062,
            "value2": 66.836
          },
          {
            "category": "15-11-2012",
            "value": 103.293,
            "value2": 66.4996
          },
          {
            "category": "16-11-2012",
            "value": 102.376,
            "value2": 65.8282
          },
          {
            "category": "17-11-2012",
            "value": 102.376,
            "value2": 65.8282
          },
          {
            "category": "18-11-2012",
            "value": 102.376,
            "value2": 65.8282
          },
          {
            "category": "19-11-2012",
            "value": 102.016,
            "value2": 65.6445
          },
          {
            "category": "20-11-2012",
            "value": 102.31,
            "value2": 65.4455
          },
          {
            "category": "21-11-2012",
            "value": 102.711,
            "value2": 65.8578
          },
          {
            "category": "22-11-2012",
            "value": 103.192,
            "value2": 66.1055
          },
          {
            "category": "23-11-2012",
            "value": 103.23,
            "value2": 66.0947
          },
          {
            "category": "24-11-2012",
            "value": 103.23,
            "value2": 66.0947
          },
          {
            "category": "25-11-2012",
            "value": 103.23,
            "value2": 66.0947
          },
          {
            "category": "26-11-2012",
            "value": 103.755,
            "value2": 66.4016
          },
          {
            "category": "27-11-2012",
            "value": 105.125,
            "value2": 67.3827
          },
          {
            "category": "28-11-2012",
            "value": 105.125,
            "value2": 67.3827
          },
          {
            "category": "29-11-2012",
            "value": 106.68,
            "value2": 68.4099
          },
          {
            "category": "30-11-2012",
            "value": 107.256,
            "value2": 69.0593
          },
          {
            "category": "01-12-2012",
            "value": 107.256,
            "value2": 69.0593
          },
          {
            "category": "02-12-2012",
            "value": 107.256,
            "value2": 69.0593
          },
          {
            "category": "03-12-2012",
            "value": 106.563,
            "value2": 69.2172
          },
          {
            "category": "04-12-2012",
            "value": 107.024,
            "value2": 69.4436
          },
          {
            "category": "05-12-2012",
            "value": 107.023,
            "value2": 69.6253
          },
          {
            "category": "06-12-2012",
            "value": 107.268,
            "value2": 70.0389
          },
          {
            "category": "07-12-2012",
            "value": 107.081,
            "value2": 69.7913
          },
          {
            "category": "08-12-2012",
            "value": 107.081,
            "value2": 69.7913
          },
          {
            "category": "09-12-2012",
            "value": 107.081,
            "value2": 69.7913
          },
          {
            "category": "10-12-2012",
            "value": 107.214,
            "value2": 69.8557
          },
          {
            "category": "11-12-2012",
            "value": 107.059,
            "value2": 69.6462
          },
          {
            "category": "12-12-2012",
            "value": 107.119,
            "value2": 69.582
          },
          {
            "category": "13-12-2012",
            "value": 106.219,
            "value2": 69.045
          },
          {
            "category": "14-12-2012",
            "value": 106.2,
            "value2": 69.4281
          },
          {
            "category": "15-12-2012",
            "value": 106.2,
            "value2": 69.4281
          },
          {
            "category": "16-12-2012",
            "value": 106.2,
            "value2": 69.4281
          },
          {
            "category": "17-12-2012",
            "value": 105.545,
            "value2": 69.3875
          },
          {
            "category": "18-12-2012",
            "value": 105.987,
            "value2": 69.8591
          },
          {
            "category": "19-12-2012",
            "value": 106.478,
            "value2": 70.2839
          },
          {
            "category": "20-12-2012",
            "value": 106.402,
            "value2": 70.1498
          },
          {
            "category": "21-12-2012",
            "value": 105.504,
            "value2": 69.2468
          },
          {
            "category": "22-12-2012",
            "value": 105.504,
            "value2": 69.2468
          },
          {
            "category": "23-12-2012",
            "value": 105.504,
            "value2": 69.2468
          },
          {
            "category": "24-12-2012",
            "value": 105.484,
            "value2": 69.3861
          },
          {
            "category": "25-12-2012",
            "value": 105.484,
            "value2": 69.3861
          },
          {
            "category": "26-12-2012",
            "value": 106.197,
            "value2": 69.9032
          },
          {
            "category": "27-12-2012",
            "value": 106.163,
            "value2": 69.6048
          },
          {
            "category": "28-12-2012",
            "value": 106.84,
            "value2": 70.012
          },
          {
            "category": "29-12-2012",
            "value": 106.84,
            "value2": 70.012
          },
          {
            "category": "30-12-2012",
            "value": 106.84,
            "value2": 70.012
          },
          {
            "category": "31-12-2012",
            "value": 107.594,
            "value2": 70.0972
          },
          {
            "category": "01-01-2013",
            "value": 107.467,
            "value2": 70.7368
          },
          {
            "category": "02-01-2013",
            "value": 107.741,
            "value2": 71.2216
          },
          {
            "category": "03-01-2013",
            "value": 107.53,
            "value2": 71.4664
          },
          {
            "category": "04-01-2013",
            "value": 107.634,
            "value2": 71.5854
          },
          {
            "category": "05-01-2013",
            "value": 107.634,
            "value2": 71.5854
          },
          {
            "category": "06-01-2013",
            "value": 107.634,
            "value2": 71.5854
          },
          {
            "category": "07-01-2013",
            "value": 107.134,
            "value2": 71.4319
          },
          {
            "category": "08-01-2013",
            "value": 107.174,
            "value2": 71.5077
          },
          {
            "category": "09-01-2013",
            "value": 106.763,
            "value2": 71.2069
          },
          {
            "category": "10-01-2013",
            "value": 106.886,
            "value2": 71.0949
          },
          {
            "category": "11-01-2013",
            "value": 106.993,
            "value2": 70.6479
          },
          {
            "category": "12-01-2013",
            "value": 106.993,
            "value2": 70.6479
          },
          {
            "category": "13-01-2013",
            "value": 106.993,
            "value2": 70.6479
          },
          {
            "category": "14-01-2013",
            "value": 107.681,
            "value2": 71.5076
          },
          {
            "category": "15-01-2013",
            "value": 107.818,
            "value2": 71.7827
          },
          {
            "category": "16-01-2013",
            "value": 106.978,
            "value2": 71.0549
          },
          {
            "category": "17-01-2013",
            "value": 107.044,
            "value2": 71.4559
          },
          {
            "category": "18-01-2013",
            "value": 107.079,
            "value2": 71.5968
          },
          {
            "category": "19-01-2013",
            "value": 107.079,
            "value2": 71.5968
          },
          {
            "category": "20-01-2013",
            "value": 107.079,
            "value2": 71.5968
          },
          {
            "category": "21-01-2013",
            "value": 107.185,
            "value2": 71.7931
          },
          {
            "category": "22-01-2013",
            "value": 106.071,
            "value2": 71.2922
          },
          {
            "category": "23-01-2013",
            "value": 105.524,
            "value2": 71.1604
          },
          {
            "category": "24-01-2013",
            "value": 105.124,
            "value2": 70.3775
          },
          {
            "category": "25-01-2013",
            "value": 105.756,
            "value2": 71.1742
          },
          {
            "category": "26-01-2013",
            "value": 105.756,
            "value2": 71.1742
          },
          {
            "category": "27-01-2013",
            "value": 105.756,
            "value2": 71.1742
          },
          {
            "category": "28-01-2013",
            "value": 105.576,
            "value2": 71.226
          },
          {
            "category": "29-01-2013",
            "value": 104.972,
            "value2": 70.8485
          },
          {
            "category": "30-01-2013",
            "value": 105.078,
            "value2": 70.9048
          },
          {
            "category": "31-01-2013",
            "value": 105.005,
            "value2": 70.8932
          },
          {
            "category": "01-02-2013",
            "value": 104.446,
            "value2": 70.628
          },
          {
            "category": "02-02-2013",
            "value": 104.446,
            "value2": 70.628
          },
          {
            "category": "03-02-2013",
            "value": 104.446,
            "value2": 70.628
          },
          {
            "category": "04-02-2013",
            "value": 104.18,
            "value2": 70.3039
          },
          {
            "category": "05-02-2013",
            "value": 104.16,
            "value2": 69.9663
          },
          {
            "category": "06-02-2013",
            "value": 103.961,
            "value2": 70.0294
          },
          {
            "category": "07-02-2013",
            "value": 103.511,
            "value2": 69.6546
          },
          {
            "category": "08-02-2013",
            "value": 103.086,
            "value2": 69.1912
          },
          {
            "category": "09-02-2013",
            "value": 103.086,
            "value2": 69.1912
          },
          {
            "category": "10-02-2013",
            "value": 103.086,
            "value2": 69.1912
          },
          {
            "category": "11-02-2013",
            "value": 102.649,
            "value2": 69.1331
          },
          {
            "category": "12-02-2013",
            "value": 102.709,
            "value2": 69.3106
          },
          {
            "category": "13-02-2013",
            "value": 102.446,
            "value2": 69.2482
          },
          {
            "category": "14-02-2013",
            "value": 102.129,
            "value2": 68.6457
          },
          {
            "category": "15-02-2013",
            "value": 101.969,
            "value2": 68.6206
          },
          {
            "category": "16-02-2013",
            "value": 101.969,
            "value2": 68.6206
          },
          {
            "category": "17-02-2013",
            "value": 101.969,
            "value2": 68.6206
          },
          {
            "category": "18-02-2013",
            "value": 102.344,
            "value2": 68.8303
          },
          {
            "category": "19-02-2013",
            "value": 103.023,
            "value2": 69.4371
          },
          {
            "category": "20-02-2013",
            "value": 103.168,
            "value2": 69.4537
          },
          {
            "category": "21-02-2013",
            "value": 102.104,
            "value2": 68.29
          },
          {
            "category": "22-02-2013",
            "value": 101.912,
            "value2": 68.3399
          },
          {
            "category": "23-02-2013",
            "value": 101.912,
            "value2": 68.3399
          },
          {
            "category": "24-02-2013",
            "value": 101.912,
            "value2": 68.3399
          },
          {
            "category": "25-02-2013",
            "value": 102.114,
            "value2": 68.1648
          },
          {
            "category": "26-02-2013",
            "value": 100.874,
            "value2": 67.0763
          },
          {
            "category": "27-02-2013",
            "value": 100.939,
            "value2": 67.5586
          },
          {
            "category": "28-02-2013",
            "value": 99.1542,
            "value2": 66.2974
          },
          {
            "category": "01-03-2013",
            "value": 99.1498,
            "value2": 66.5421
          },
          {
            "category": "02-03-2013",
            "value": 99.1498,
            "value2": 66.5421
          },
          {
            "category": "03-03-2013",
            "value": 99.1498,
            "value2": 66.5421
          },
          {
            "category": "04-03-2013",
            "value": 98.5972,
            "value2": 66.0893
          },
          {
            "category": "05-03-2013",
            "value": 99.4673,
            "value2": 67.1135
          },
          {
            "category": "06-03-2013",
            "value": 99.8508,
            "value2": 67.5977
          },
          {
            "category": "07-03-2013",
            "value": 100.395,
            "value2": 68.0678
          },
          {
            "category": "08-03-2013",
            "value": 101.748,
            "value2": 68.9136
          },
          {
            "category": "09-03-2013",
            "value": 101.748,
            "value2": 68.9136
          },
          {
            "category": "10-03-2013",
            "value": 101.748,
            "value2": 68.9136
          },
          {
            "category": "11-03-2013",
            "value": 101.63,
            "value2": 68.8951
          },
          {
            "category": "12-03-2013",
            "value": 101.092,
            "value2": 68.5674
          },
          {
            "category": "13-03-2013",
            "value": 100.329,
            "value2": 67.8005
          },
          {
            "category": "14-03-2013",
            "value": 101.22,
            "value2": 68.4219
          },
          {
            "category": "15-03-2013",
            "value": 100.897,
            "value2": 68.0138
          },
          {
            "category": "16-03-2013",
            "value": 100.897,
            "value2": 68.0138
          },
          {
            "category": "17-03-2013",
            "value": 100.897,
            "value2": 68.0138
          },
          {
            "category": "18-03-2013",
            "value": 100.861,
            "value2": 67.5959
          },
          {
            "category": "19-03-2013",
            "value": 99.4348,
            "value2": 66.5369
          },
          {
            "category": "20-03-2013",
            "value": 98.6565,
            "value2": 65.7837
          },
          {
            "category": "21-03-2013",
            "value": 98.2208,
            "value2": 65.2713
          },
          {
            "category": "22-03-2013",
            "value": 98.0176,
            "value2": 65.1388
          },
          {
            "category": "23-03-2013",
            "value": 98.0176,
            "value2": 65.1388
          },
          {
            "category": "24-03-2013",
            "value": 98.0176,
            "value2": 65.1388
          },
          {
            "category": "25-03-2013",
            "value": 97.3922,
            "value2": 64.9376
          },
          {
            "category": "26-03-2013",
            "value": 97.5152,
            "value2": 64.9657
          },
          {
            "category": "27-03-2013",
            "value": 97.5152,
            "value2": 64.9657
          },
          {
            "category": "28-03-2013",
            "value": 97.9777,
            "value2": 65.6344
          },
          {
            "category": "29-03-2013",
            "value": 97.9777,
            "value2": 65.6344
          },
          {
            "category": "30-03-2013",
            "value": 97.9777,
            "value2": 65.6344
          },
          {
            "category": "31-03-2013",
            "value": 97.6753,
            "value2": 65.6344
          },
          {
            "category": "01-04-2013",
            "value": 98.2206,
            "value2": 65.9957
          },
          {
            "category": "02-04-2013",
            "value": 99.2741,
            "value2": 66.6835
          },
          {
            "category": "03-04-2013",
            "value": 98.6211,
            "value2": 65.9225
          },
          {
            "category": "04-04-2013",
            "value": 96.9868,
            "value2": 64.8039
          },
          {
            "category": "05-04-2013",
            "value": 96.7415,
            "value2": 64.6309
          },
          {
            "category": "06-04-2013",
            "value": 96.7415,
            "value2": 64.6309
          },
          {
            "category": "07-04-2013",
            "value": 96.7415,
            "value2": 64.6309
          },
          {
            "category": "08-04-2013",
            "value": 96.5041,
            "value2": 64.5606
          },
          {
            "category": "09-04-2013",
            "value": 95.9825,
            "value2": 63.9088
          },
          {
            "category": "10-04-2013",
            "value": 97.4989,
            "value2": 64.532
          },
          {
            "category": "11-04-2013",
            "value": 97.8269,
            "value2": 64.8428
          },
          {
            "category": "12-04-2013",
            "value": 96.1277,
            "value2": 64.3389
          },
          {
            "category": "13-04-2013",
            "value": 96.1277,
            "value2": 64.3389
          },
          {
            "category": "14-04-2013",
            "value": 96.1277,
            "value2": 64.3389
          },
          {
            "category": "15-04-2013",
            "value": 96.6934,
            "value2": 64.6544
          },
          {
            "category": "16-04-2013",
            "value": 97.9916,
            "value2": 65.8117
          },
          {
            "category": "17-04-2013",
            "value": 98.1144,
            "value2": 65.8733
          },
          {
            "category": "18-04-2013",
            "value": 99.6216,
            "value2": 66.7555
          },
          {
            "category": "19-04-2013",
            "value": 99.6216,
            "value2": 66.7555
          },
          {
            "category": "20-04-2013",
            "value": 99.6216,
            "value2": 66.7555
          },
          {
            "category": "21-04-2013",
            "value": 99.6216,
            "value2": 66.7555
          },
          {
            "category": "22-04-2013",
            "value": 101.188,
            "value2": 67.4122
          },
          {
            "category": "23-04-2013",
            "value": 100.795,
            "value2": 67.384
          },
          {
            "category": "24-04-2013",
            "value": 100.795,
            "value2": 67.384
          },
          {
            "category": "25-04-2013",
            "value": 101.934,
            "value2": 68.0994
          },
          {
            "category": "26-04-2013",
            "value": 101.908,
            "value2": 67.6316
          },
          {
            "category": "27-04-2013",
            "value": 101.908,
            "value2": 67.6316
          },
          {
            "category": "28-04-2013",
            "value": 101.908,
            "value2": 67.6316
          },
          {
            "category": "29-04-2013",
            "value": 102.503,
            "value2": 68.0762
          },
          {
            "category": "30-04-2013",
            "value": 102.326,
            "value2": 68.4293
          },
          {
            "category": "01-05-2013",
            "value": 102.219,
            "value2": 68.4293
          },
          {
            "category": "02-05-2013",
            "value": 103.2,
            "value2": 69.1806
          },
          {
            "category": "03-05-2013",
            "value": 102.568,
            "value2": 68.6977
          },
          {
            "category": "04-05-2013",
            "value": 102.568,
            "value2": 68.6977
          },
          {
            "category": "05-05-2013",
            "value": 102.568,
            "value2": 68.6977
          },
          {
            "category": "06-05-2013",
            "value": 103.047,
            "value2": 69.0873
          },
          {
            "category": "07-05-2013",
            "value": 104.023,
            "value2": 69.8039
          },
          {
            "category": "08-05-2013",
            "value": 104.274,
            "value2": 70.04
          },
          {
            "category": "09-05-2013",
            "value": 104.01,
            "value2": 69.8218
          },
          {
            "category": "10-05-2013",
            "value": 104.471,
            "value2": 70.2795
          },
          {
            "category": "11-05-2013",
            "value": 104.496,
            "value2": 70.4789
          },
          {
            "category": "12-05-2013",
            "value": 104.496,
            "value2": 70.4789
          },
          {
            "category": "13-05-2013",
            "value": 103.387,
            "value2": 69.1528
          },
          {
            "category": "14-05-2013",
            "value": 104.118,
            "value2": 69.3217
          },
          {
            "category": "15-05-2013",
            "value": 106.31,
            "value2": 70.869
          },
          {
            "category": "16-05-2013",
            "value": 106.56,
            "value2": 71.1449
          },
          {
            "category": "17-05-2013",
            "value": 106.834,
            "value2": 71.3526
          },
          {
            "category": "18-05-2013",
            "value": 106.834,
            "value2": 71.3526
          },
          {
            "category": "19-05-2013",
            "value": 106.834,
            "value2": 71.3526
          },
          {
            "category": "20-05-2013",
            "value": 106.739,
            "value2": 71.0463
          },
          {
            "category": "21-05-2013",
            "value": 106.453,
            "value2": 70.5618
          },
          {
            "category": "22-05-2013",
            "value": 105.426,
            "value2": 70.2436
          },
          {
            "category": "23-05-2013",
            "value": 103.64,
            "value2": 68.8309
          },
          {
            "category": "24-05-2013",
            "value": 103.975,
            "value2": 69.0744
          },
          {
            "category": "25-05-2013",
            "value": 103.975,
            "value2": 69.0744
          },
          {
            "category": "26-05-2013",
            "value": 103.975,
            "value2": 69.0744
          },
          {
            "category": "27-05-2013",
            "value": 104.907,
            "value2": 70.0715
          },
          {
            "category": "28-05-2013",
            "value": 105.597,
            "value2": 70.4224
          },
          {
            "category": "29-05-2013",
            "value": 105.508,
            "value2": 70.2362
          },
          {
            "category": "30-05-2013",
            "value": 105.637,
            "value2": 70.4455
          },
          {
            "category": "31-05-2013",
            "value": 105.128,
            "value2": 69.1922
          },
          {
            "category": "01-06-2013",
            "value": 105.128,
            "value2": 69.1922
          },
          {
            "category": "02-06-2013",
            "value": 105.128,
            "value2": 69.1922
          },
          {
            "category": "03-06-2013",
            "value": 104.896,
            "value2": 68.8784
          },
          {
            "category": "04-06-2013",
            "value": 104.656,
            "value2": 68.7863
          },
          {
            "category": "05-06-2013",
            "value": 104.315,
            "value2": 68.8328
          },
          {
            "category": "06-06-2013",
            "value": 104.04,
            "value2": 68.8118
          },
          {
            "category": "07-06-2013",
            "value": 103.466,
            "value2": 68.3776
          },
          {
            "category": "08-06-2013",
            "value": 103.466,
            "value2": 68.3776
          },
          {
            "category": "09-06-2013",
            "value": 103.466,
            "value2": 68.3776
          },
          {
            "category": "10-06-2013",
            "value": 103.202,
            "value2": 68.1194
          },
          {
            "category": "11-06-2013",
            "value": 102.149,
            "value2": 67.0406
          },
          {
            "category": "12-06-2013",
            "value": 101.668,
            "value2": 66.6494
          },
          {
            "category": "13-06-2013",
            "value": 100.906,
            "value2": 65.8446
          },
          {
            "category": "14-06-2013",
            "value": 101.856,
            "value2": 66.9649
          },
          {
            "category": "15-06-2013",
            "value": 101.856,
            "value2": 66.9649
          },
          {
            "category": "16-06-2013",
            "value": 101.856,
            "value2": 66.9649
          },
          {
            "category": "17-06-2013",
            "value": 102.365,
            "value2": 67.4318
          },
          {
            "category": "18-06-2013",
            "value": 102.197,
            "value2": 67.2126
          },
          {
            "category": "19-06-2013",
            "value": 102.706,
            "value2": 67.3973
          },
          {
            "category": "20-06-2013",
            "value": 100.861,
            "value2": 65.6332
          },
          {
            "category": "21-06-2013",
            "value": 100.63,
            "value2": 65.5389
          },
          {
            "category": "22-06-2013",
            "value": 100.63,
            "value2": 65.5389
          },
          {
            "category": "23-06-2013",
            "value": 100.63,
            "value2": 65.5389
          },
          {
            "category": "24-06-2013",
            "value": 98.8275,
            "value2": 64.404
          },
          {
            "category": "25-06-2013",
            "value": 98.0469,
            "value2": 64.4962
          },
          {
            "category": "26-06-2013",
            "value": 97.7542,
            "value2": 64.2461
          },
          {
            "category": "27-06-2013",
            "value": 99.1862,
            "value2": 65.1959
          },
          {
            "category": "28-06-2013",
            "value": 101.233,
            "value2": 66.7903
          },
          {
            "category": "29-06-2013",
            "value": 101.233,
            "value2": 66.7903
          },
          {
            "category": "30-06-2013",
            "value": 101.233,
            "value2": 66.7903
          },
          {
            "category": "01-07-2013",
            "value": 102.791,
            "value2": 67.751
          },
          {
            "category": "02-07-2013",
            "value": 102.169,
            "value2": 67.4166
          },
          {
            "category": "03-07-2013",
            "value": 101.022,
            "value2": 66.3277
          },
          {
            "category": "04-07-2013",
            "value": 101.362,
            "value2": 66.993
          },
          {
            "category": "05-07-2013",
            "value": 101.589,
            "value2": 67.2582
          },
          {
            "category": "06-07-2013",
            "value": 101.589,
            "value2": 67.2582
          },
          {
            "category": "07-07-2013",
            "value": 101.589,
            "value2": 67.2582
          },
          {
            "category": "08-07-2013",
            "value": 100.854,
            "value2": 66.8469
          },
          {
            "category": "09-07-2013",
            "value": 101.842,
            "value2": 67.3673
          },
          {
            "category": "10-07-2013",
            "value": 101.146,
            "value2": 67.0098
          },
          {
            "category": "11-07-2013",
            "value": 102.74,
            "value2": 68.0858
          },
          {
            "category": "12-07-2013",
            "value": 104.511,
            "value2": 68.7368
          },
          {
            "category": "13-07-2013",
            "value": 104.511,
            "value2": 68.7368
          },
          {
            "category": "14-07-2013",
            "value": 104.511,
            "value2": 68.7368
          },
          {
            "category": "15-07-2013",
            "value": 104.924,
            "value2": 69.2145
          },
          {
            "category": "16-07-2013",
            "value": 103.781,
            "value2": 68.3839
          },
          {
            "category": "17-07-2013",
            "value": 103.436,
            "value2": 68.4394
          },
          {
            "category": "18-07-2013",
            "value": 104.359,
            "value2": 69.1605
          },
          {
            "category": "19-07-2013",
            "value": 103.752,
            "value2": 68.995
          },
          {
            "category": "20-07-2013",
            "value": 103.752,
            "value2": 68.995
          },
          {
            "category": "21-07-2013",
            "value": 103.752,
            "value2": 68.995
          },
          {
            "category": "22-07-2013",
            "value": 103.215,
            "value2": 68.9578
          },
          {
            "category": "23-07-2013",
            "value": 103.578,
            "value2": 69.37
          },
          {
            "category": "24-07-2013",
            "value": 102.086,
            "value2": 68.3414
          },
          {
            "category": "25-07-2013",
            "value": 101.62,
            "value2": 67.5122
          },
          {
            "category": "26-07-2013",
            "value": 101.185,
            "value2": 67.1309
          },
          {
            "category": "27-07-2013",
            "value": 101.185,
            "value2": 67.1309
          },
          {
            "category": "28-07-2013",
            "value": 101.185,
            "value2": 67.1309
          },
          {
            "category": "29-07-2013",
            "value": 100.779,
            "value2": 66.4727
          },
          {
            "category": "30-07-2013",
            "value": 99.323,
            "value2": 65.4635
          },
          {
            "category": "31-07-2013",
            "value": 99.0949,
            "value2": 65.3388
          },
          {
            "category": "01-08-2013",
            "value": 97.1957,
            "value2": 64.9243
          },
          {
            "category": "02-08-2013",
            "value": 96.9359,
            "value2": 64.3608
          },
          {
            "category": "03-08-2013",
            "value": 96.9359,
            "value2": 64.3608
          },
          {
            "category": "04-08-2013",
            "value": 96.9359,
            "value2": 64.3608
          },
          {
            "category": "05-08-2013",
            "value": 96.5911,
            "value2": 64.5281
          },
          {
            "category": "06-08-2013",
            "value": 93.8938,
            "value2": 62.9172
          },
          {
            "category": "07-08-2013",
            "value": 93.6273,
            "value2": 62.9354
          },
          {
            "category": "08-08-2013",
            "value": 94.222,
            "value2": 63.4748
          },
          {
            "category": "09-08-2013",
            "value": 94.222,
            "value2": 63.4748
          },
          {
            "category": "10-08-2013",
            "value": 94.222,
            "value2": 63.4748
          },
          {
            "category": "11-08-2013",
            "value": 94.222,
            "value2": 63.4748
          },
          {
            "category": "12-08-2013",
            "value": 94.7431,
            "value2": 64.1201
          },
          {
            "category": "13-08-2013",
            "value": 95.7862,
            "value2": 65.1373
          },
          {
            "category": "14-08-2013",
            "value": 95.9718,
            "value2": 65.6143
          },
          {
            "category": "15-08-2013",
            "value": 95.9718,
            "value2": 65.6143
          },
          {
            "category": "16-08-2013",
            "value": 93.1764,
            "value2": 63.1218
          },
          {
            "category": "17-08-2013",
            "value": 93.1764,
            "value2": 63.1218
          },
          {
            "category": "18-08-2013",
            "value": 93.1765,
            "value2": 63.1218
          },
          {
            "category": "19-08-2013",
            "value": 91.9347,
            "value2": 62.081
          },
          {
            "category": "20-08-2013",
            "value": 91.5466,
            "value2": 61.8905
          },
          {
            "category": "21-08-2013",
            "value": 91.4243,
            "value2": 60.8321
          },
          {
            "category": "22-08-2013",
            "value": 92.1328,
            "value2": 61.9571
          },
          {
            "category": "23-08-2013",
            "value": 92.8535,
            "value2": 62.5967
          },
          {
            "category": "24-08-2013",
            "value": 92.8535,
            "value2": 62.5967
          },
          {
            "category": "25-08-2013",
            "value": 92.8535,
            "value2": 62.5967
          },
          {
            "category": "26-08-2013",
            "value": 93.6929,
            "value2": 62.7776
          },
          {
            "category": "27-08-2013",
            "value": 90.722,
            "value2": 60.8211
          },
          {
            "category": "28-08-2013",
            "value": 90.1761,
            "value2": 60.6445
          },
          {
            "category": "29-08-2013",
            "value": 91.3702,
            "value2": 61.8872
          },
          {
            "category": "30-08-2013",
            "value": 93.3022,
            "value2": 62.5064
          },
          {
            "category": "31-08-2013",
            "value": 93.3022,
            "value2": 62.5064
          },
          {
            "category": "01-09-2013",
            "value": 93.3022,
            "value2": 62.5064
          },
          {
            "category": "02-09-2013",
            "value": 93.4191,
            "value2": 63.4064
          },
          {
            "category": "03-09-2013",
            "value": 91.0741,
            "value2": 61.4214
          },
          {
            "category": "04-09-2013",
            "value": 92.0173,
            "value2": 62.4577
          },
          {
            "category": "05-09-2013",
            "value": 93.8779,
            "value2": 63.9088
          },
          {
            "category": "06-09-2013",
            "value": 94.645,
            "value2": 64.7637
          },
          {
            "category": "07-09-2013",
            "value": 94.645,
            "value2": 64.7637
          },
          {
            "category": "08-09-2013",
            "value": 94.645,
            "value2": 64.7637
          },
          {
            "category": "09-09-2013",
            "value": 94.645,
            "value2": 64.7637
          },
          {
            "category": "10-09-2013",
            "value": 97.201,
            "value2": 66.824
          },
          {
            "category": "11-09-2013",
            "value": 98.3742,
            "value2": 67.2174
          },
          {
            "category": "12-09-2013",
            "value": 97.5791,
            "value2": 66.5698
          },
          {
            "category": "13-09-2013",
            "value": 97.4199,
            "value2": 66.6368
          },
          {
            "category": "14-09-2013",
            "value": 97.4199,
            "value2": 66.6368
          },
          {
            "category": "15-09-2013",
            "value": 97.4199,
            "value2": 66.6368
          },
          {
            "category": "16-09-2013",
            "value": 97.4788,
            "value2": 66.4901
          },
          {
            "category": "17-09-2013",
            "value": 97.6281,
            "value2": 66.5674
          },
          {
            "category": "18-09-2013",
            "value": 97.9571,
            "value2": 67.045
          },
          {
            "category": "19-09-2013",
            "value": 100.115,
            "value2": 69.2795
          },
          {
            "category": "20-09-2013",
            "value": 99.0775,
            "value2": 68.2914
          },
          {
            "category": "21-09-2013",
            "value": 99.0775,
            "value2": 68.2914
          },
          {
            "category": "22-09-2013",
            "value": 99.0775,
            "value2": 68.2914
          },
          {
            "category": "23-09-2013",
            "value": 97.1221,
            "value2": 67.1567
          },
          {
            "category": "24-09-2013",
            "value": 97.5706,
            "value2": 67.174
          },
          {
            "category": "25-09-2013",
            "value": 97.2509,
            "value2": 67.0427
          },
          {
            "category": "26-09-2013",
            "value": 97.584,
            "value2": 67.1145
          },
          {
            "category": "27-09-2013",
            "value": 97.8006,
            "value2": 66.7636
          },
          {
            "category": "28-09-2013",
            "value": 97.8006,
            "value2": 66.7636
          },
          {
            "category": "29-09-2013",
            "value": 97.8006,
            "value2": 66.7636
          },
          {
            "category": "30-09-2013",
            "value": 97.2393,
            "value2": 65.8453
          },
          {
            "category": "01-10-2013",
            "value": 97.7759,
            "value2": 66.2594
          },
          {
            "category": "02-10-2013",
            "value": 97.7759,
            "value2": 66.2594
          },
          {
            "category": "03-10-2013",
            "value": 98.6199,
            "value2": 67.5625
          },
          {
            "category": "04-10-2013",
            "value": 98.7942,
            "value2": 67.6297
          },
          {
            "category": "05-10-2013",
            "value": 98.7942,
            "value2": 67.6297
          },
          {
            "category": "06-10-2013",
            "value": 98.7942,
            "value2": 67.6297
          },
          {
            "category": "07-10-2013",
            "value": 98.9393,
            "value2": 67.6926
          },
          {
            "category": "08-10-2013",
            "value": 98.7108,
            "value2": 67.8938
          },
          {
            "category": "09-10-2013",
            "value": 99.8933,
            "value2": 68.7444
          },
          {
            "category": "10-10-2013",
            "value": 100.086,
            "value2": 68.9542
          },
          {
            "category": "11-10-2013",
            "value": 101.137,
            "value2": 69.5639
          },
          {
            "category": "12-10-2013",
            "value": 101.137,
            "value2": 69.5639
          },
          {
            "category": "13-10-2013",
            "value": 101.137,
            "value2": 69.5639
          },
          {
            "category": "14-10-2013",
            "value": 101.326,
            "value2": 69.8105
          },
          {
            "category": "15-10-2013",
            "value": 101.146,
            "value2": 69.4066
          },
          {
            "category": "16-10-2013",
            "value": 101.146,
            "value2": 69.4066
          },
          {
            "category": "17-10-2013",
            "value": 101.116,
            "value2": 69.0627
          },
          {
            "category": "18-10-2013",
            "value": 102.678,
            "value2": 70.4226
          },
          {
            "category": "19-10-2013",
            "value": 102.678,
            "value2": 70.4226
          },
          {
            "category": "20-10-2013",
            "value": 102.678,
            "value2": 70.4226
          },
          {
            "category": "21-10-2013",
            "value": 102.902,
            "value2": 70.7943
          },
          {
            "category": "22-10-2013",
            "value": 103.283,
            "value2": 70.9139
          },
          {
            "category": "23-10-2013",
            "value": 103.21,
            "value2": 70.686
          },
          {
            "category": "24-10-2013",
            "value": 102.962,
            "value2": 70.5371
          },
          {
            "category": "25-10-2013",
            "value": 102.602,
            "value2": 70.2608
          },
          {
            "category": "26-10-2013",
            "value": 102.602,
            "value2": 70.2608
          },
          {
            "category": "27-10-2013",
            "value": 102.602,
            "value2": 70.2608
          },
          {
            "category": "28-10-2013",
            "value": 102.182,
            "value2": 69.7306
          },
          {
            "category": "29-10-2013",
            "value": 103.833,
            "value2": 70.9768
          },
          {
            "category": "30-10-2013",
            "value": 103.611,
            "value2": 71.2843
          },
          {
            "category": "31-10-2013",
            "value": 104.117,
            "value2": 71.8799
          },
          {
            "category": "01-11-2013",
            "value": 104.915,
            "value2": 72.2159
          },
          {
            "category": "02-11-2013",
            "value": 104.915,
            "value2": 72.2159
          },
          {
            "category": "03-11-2013",
            "value": 104.924,
            "value2": 72.5067
          },
          {
            "category": "04-11-2013",
            "value": 104.924,
            "value2": 72.5067
          },
          {
            "category": "05-11-2013",
            "value": 104.641,
            "value2": 72.0042
          },
          {
            "category": "06-11-2013",
            "value": 104.142,
            "value2": 71.7001
          },
          {
            "category": "07-11-2013",
            "value": 104.137,
            "value2": 71.1667
          },
          {
            "category": "08-11-2013",
            "value": 104.17,
            "value2": 70.7981
          },
          {
            "category": "09-11-2013",
            "value": 104.17,
            "value2": 70.7981
          },
          {
            "category": "10-11-2013",
            "value": 104.17,
            "value2": 70.7981
          },
          {
            "category": "11-11-2013",
            "value": 104.052,
            "value2": 70.1237
          },
          {
            "category": "12-11-2013",
            "value": 103.797,
            "value2": 69.4661
          },
          {
            "category": "13-11-2013",
            "value": 103.047,
            "value2": 69.128
          },
          {
            "category": "14-11-2013",
            "value": 103.758,
            "value2": 69.9362
          },
          {
            "category": "15-11-2013",
            "value": 103.758,
            "value2": 69.9362
          },
          {
            "category": "16-11-2013",
            "value": 103.758,
            "value2": 69.9362
          },
          {
            "category": "17-11-2013",
            "value": 103.758,
            "value2": 69.9362
          },
          {
            "category": "18-11-2013",
            "value": 105.365,
            "value2": 71.2547
          },
          {
            "category": "19-11-2013",
            "value": 105.605,
            "value2": 71.4154
          },
          {
            "category": "20-11-2013",
            "value": 104.319,
            "value2": 70.7053
          },
          {
            "category": "21-11-2013",
            "value": 102.876,
            "value2": 69.3809
          },
          {
            "category": "22-11-2013",
            "value": 103.087,
            "value2": 69.3839
          },
          {
            "category": "23-11-2013",
            "value": 103.087,
            "value2": 69.3839
          },
          {
            "category": "24-11-2013",
            "value": 103.087,
            "value2": 69.3839
          },
          {
            "category": "25-11-2013",
            "value": 104.419,
            "value2": 70.589
          },
          {
            "category": "26-11-2013",
            "value": 104.149,
            "value2": 70.0387
          },
          {
            "category": "27-11-2013",
            "value": 104.55,
            "value2": 70.0629
          },
          {
            "category": "28-11-2013",
            "value": 104.555,
            "value2": 70.4837
          },
          {
            "category": "29-11-2013",
            "value": 105.81,
            "value2": 71.358
          },
          {
            "category": "30-11-2013",
            "value": 105.81,
            "value2": 71.358
          },
          {
            "category": "01-12-2013",
            "value": 105.81,
            "value2": 71.358
          },
          {
            "category": "02-12-2013",
            "value": 106.868,
            "value2": 71.8559
          },
          {
            "category": "03-12-2013",
            "value": 106.77,
            "value2": 71.7575
          },
          {
            "category": "04-12-2013",
            "value": 106.028,
            "value2": 71.3092
          },
          {
            "category": "05-12-2013",
            "value": 106.807,
            "value2": 72.0158
          },
          {
            "category": "06-12-2013",
            "value": 106.738,
            "value2": 72.2645
          },
          {
            "category": "07-12-2013",
            "value": 106.738,
            "value2": 72.2645
          },
          {
            "category": "08-12-2013",
            "value": 106.738,
            "value2": 72.2645
          },
          {
            "category": "09-12-2013",
            "value": 107.067,
            "value2": 73.1993
          },
          {
            "category": "10-12-2013",
            "value": 106.939,
            "value2": 72.907
          },
          {
            "category": "11-12-2013",
            "value": 106.969,
            "value2": 72.6345
          },
          {
            "category": "12-12-2013",
            "value": 106.755,
            "value2": 71.9723
          },
          {
            "category": "13-12-2013",
            "value": 105.757,
            "value2": 71.148
          },
          {
            "category": "14-12-2013",
            "value": 105.757,
            "value2": 71.148
          },
          {
            "category": "15-12-2013",
            "value": 105.757,
            "value2": 71.148
          },
          {
            "category": "16-12-2013",
            "value": 107.175,
            "value2": 71.1389
          },
          {
            "category": "17-12-2013",
            "value": 106.571,
            "value2": 71.0725
          },
          {
            "category": "18-12-2013",
            "value": 108.368,
            "value2": 71.9881
          },
          {
            "category": "19-12-2013",
            "value": 107.831,
            "value2": 71.5812
          },
          {
            "category": "20-12-2013",
            "value": 110.221,
            "value2": 72.7633
          },
          {
            "category": "21-12-2013",
            "value": 110.221,
            "value2": 72.7633
          },
          {
            "category": "22-12-2013",
            "value": 110.221,
            "value2": 72.7633
          },
          {
            "category": "23-12-2013",
            "value": 109.984,
            "value2": 73.0528
          },
          {
            "category": "24-12-2013",
            "value": 109.926,
            "value2": 72.9905
          },
          {
            "category": "25-12-2013",
            "value": 109.926,
            "value2": 72.9905
          },
          {
            "category": "26-12-2013",
            "value": 110.745,
            "value2": 73.1652
          },
          {
            "category": "27-12-2013",
            "value": 111.27,
            "value2": 73.5312
          },
          {
            "category": "28-12-2013",
            "value": 111.27,
            "value2": 73.5312
          },
          {
            "category": "29-12-2013",
            "value": 111.27,
            "value2": 73.5312
          },
          {
            "category": "30-12-2013",
            "value": 110.73,
            "value2": 73.3431
          },
          {
            "category": "31-12-2013",
            "value": 110.376,
            "value2": 73.5518
          },
          {
            "category": "01-01-2014",
            "value": 110.019,
            "value2": 73.6523
          },
          {
            "category": "02-01-2014",
            "value": 109.401,
            "value2": 72.5896
          },
          {
            "category": "03-01-2014",
            "value": 109.833,
            "value2": 72.652
          },
          {
            "category": "04-01-2014",
            "value": 109.833,
            "value2": 72.652
          },
          {
            "category": "05-01-2014",
            "value": 109.833,
            "value2": 72.652
          },
          {
            "category": "06-01-2014",
            "value": 109.419,
            "value2": 72.5685
          },
          {
            "category": "07-01-2014",
            "value": 108.898,
            "value2": 72.199
          },
          {
            "category": "08-01-2014",
            "value": 109.43,
            "value2": 72.3914
          },
          {
            "category": "09-01-2014",
            "value": 108.801,
            "value2": 72.1943
          },
          {
            "category": "10-01-2014",
            "value": 109.033,
            "value2": 72.104
          },
          {
            "category": "11-01-2014",
            "value": 109.033,
            "value2": 72.104
          },
          {
            "category": "12-01-2014",
            "value": 109.033,
            "value2": 72.104
          },
          {
            "category": "13-01-2014",
            "value": 110.252,
            "value2": 72.9494
          },
          {
            "category": "14-01-2014",
            "value": 110.293,
            "value2": 72.6625
          },
          {
            "category": "15-01-2014",
            "value": 110.597,
            "value2": 73.3125
          },
          {
            "category": "16-01-2014",
            "value": 110.224,
            "value2": 73.2018
          },
          {
            "category": "17-01-2014",
            "value": 109.3,
            "value2": 72.4588
          },
          {
            "category": "18-01-2014",
            "value": 109.3,
            "value2": 72.4588
          },
          {
            "category": "19-01-2014",
            "value": 109.3,
            "value2": 72.4588
          },
          {
            "category": "20-01-2014",
            "value": 110.084,
            "value2": 73.015
          },
          {
            "category": "21-01-2014",
            "value": 110.256,
            "value2": 73.1523
          },
          {
            "category": "22-01-2014",
            "value": 110.099,
            "value2": 73.4032
          },
          {
            "category": "23-01-2014",
            "value": 110.377,
            "value2": 73.3836
          },
          {
            "category": "24-01-2014",
            "value": 109.12,
            "value2": 72.3972
          },
          {
            "category": "25-01-2014",
            "value": 109.12,
            "value2": 72.3972
          },
          {
            "category": "26-01-2014",
            "value": 109.12,
            "value2": 72.3972
          },
          {
            "category": "27-01-2014",
            "value": 107.5,
            "value2": 70.7691
          },
          {
            "category": "28-01-2014",
            "value": 106.63,
            "value2": 70.7036
          },
          {
            "category": "29-01-2014",
            "value": 106.934,
            "value2": 70.6886
          },
          {
            "category": "30-01-2014",
            "value": 105.384,
            "value2": 70.0036
          },
          {
            "category": "31-01-2014",
            "value": 105.75,
            "value2": 70.5225
          },
          {
            "category": "01-02-2014",
            "value": 105.75,
            "value2": 70.5225
          },
          {
            "category": "02-02-2014",
            "value": 105.75,
            "value2": 70.5225
          },
          {
            "category": "03-02-2014",
            "value": 104.314,
            "value2": 69.6494
          },
          {
            "category": "04-02-2014",
            "value": 104.768,
            "value2": 69.7207
          },
          {
            "category": "05-02-2014",
            "value": 105.744,
            "value2": 69.9797
          },
          {
            "category": "06-02-2014",
            "value": 105.82,
            "value2": 70.1202
          },
          {
            "category": "07-02-2014",
            "value": 105.848,
            "value2": 70.4245
          },
          {
            "category": "08-02-2014",
            "value": 105.848,
            "value2": 70.4245
          },
          {
            "category": "09-02-2014",
            "value": 105.848,
            "value2": 70.4245
          },
          {
            "category": "10-02-2014",
            "value": 105.553,
            "value2": 70.2976
          },
          {
            "category": "11-02-2014",
            "value": 105.737,
            "value2": 70.4225
          },
          {
            "category": "12-02-2014",
            "value": 106.015,
            "value2": 70.5809
          },
          {
            "category": "13-02-2014",
            "value": 105.516,
            "value2": 69.699
          },
          {
            "category": "14-02-2014",
            "value": 105.905,
            "value2": 70.0991
          },
          {
            "category": "15-02-2014",
            "value": 105.905,
            "value2": 70.0991
          },
          {
            "category": "16-02-2014",
            "value": 105.905,
            "value2": 70.0991
          },
          {
            "category": "17-02-2014",
            "value": 106.286,
            "value2": 70.3033
          },
          {
            "category": "18-02-2014",
            "value": 107.109,
            "value2": 70.8962
          },
          {
            "category": "19-02-2014",
            "value": 107.679,
            "value2": 71.2084
          },
          {
            "category": "20-02-2014",
            "value": 107.097,
            "value2": 70.7182
          },
          {
            "category": "21-02-2014",
            "value": 107.818,
            "value2": 71.3309
          },
          {
            "category": "22-02-2014",
            "value": 107.818,
            "value2": 71.3309
          },
          {
            "category": "23-02-2014",
            "value": 107.818,
            "value2": 71.3309
          },
          {
            "category": "24-02-2014",
            "value": 108.416,
            "value2": 71.6369
          },
          {
            "category": "25-02-2014",
            "value": 108.929,
            "value2": 71.8059
          },
          {
            "category": "26-02-2014",
            "value": 109.531,
            "value2": 72.11
          },
          {
            "category": "27-02-2014",
            "value": 109.531,
            "value2": 72.11
          },
          {
            "category": "28-02-2014",
            "value": 109.724,
            "value2": 72.5654
          },
          {
            "category": "01-03-2014",
            "value": 109.724,
            "value2": 72.5654
          },
          {
            "category": "02-03-2014",
            "value": 109.724,
            "value2": 72.5654
          },
          {
            "category": "03-03-2014",
            "value": 109.083,
            "value2": 72.0799
          },
          {
            "category": "04-03-2014",
            "value": 110.219,
            "value2": 72.9635
          },
          {
            "category": "05-03-2014",
            "value": 110.817,
            "value2": 73.3263
          },
          {
            "category": "06-03-2014",
            "value": 111.505,
            "value2": 74.233
          },
          {
            "category": "07-03-2014",
            "value": 112.012,
            "value2": 75.2582
          },
          {
            "category": "08-03-2014",
            "value": 112.012,
            "value2": 75.2582
          },
          {
            "category": "09-03-2014",
            "value": 112.012,
            "value2": 75.2582
          },
          {
            "category": "10-03-2014",
            "value": 112.88,
            "value2": 75.4663
          },
          {
            "category": "11-03-2014",
            "value": 112.139,
            "value2": 75.171
          },
          {
            "category": "12-03-2014",
            "value": 112.513,
            "value2": 75.2093
          },
          {
            "category": "13-03-2014",
            "value": 112.213,
            "value2": 74.9154
          },
          {
            "category": "14-03-2014",
            "value": 112.473,
            "value2": 74.9494
          },
          {
            "category": "15-03-2014",
            "value": 112.473,
            "value2": 74.9494
          },
          {
            "category": "16-03-2014",
            "value": 112.473,
            "value2": 74.9494
          },
          {
            "category": "17-03-2014",
            "value": 112.473,
            "value2": 74.9494
          },
          {
            "category": "18-03-2014",
            "value": 112.701,
            "value2": 75.2978
          },
          {
            "category": "19-03-2014",
            "value": 113.464,
            "value2": 75.439
          },
          {
            "category": "20-03-2014",
            "value": 113.021,
            "value2": 75.0372
          },
          {
            "category": "21-03-2014",
            "value": 113.163,
            "value2": 75.2412
          },
          {
            "category": "22-03-2014",
            "value": 113.465,
            "value2": 75.3536
          },
          {
            "category": "23-03-2014",
            "value": 113.465,
            "value2": 75.3536
          },
          {
            "category": "24-03-2014",
            "value": 114.386,
            "value2": 76.1279
          },
          {
            "category": "25-03-2014",
            "value": 115.445,
            "value2": 76.2805
          },
          {
            "category": "26-03-2014",
            "value": 115.179,
            "value2": 76.4497
          },
          {
            "category": "27-03-2014",
            "value": 116.364,
            "value2": 76.9614
          },
          {
            "category": "28-03-2014",
            "value": 117.022,
            "value2": 77.7602
          },
          {
            "category": "29-03-2014",
            "value": 117.022,
            "value2": 77.7602
          },
          {
            "category": "30-03-2014",
            "value": 117.022,
            "value2": 77.7602
          },
          {
            "category": "31-03-2014",
            "value": 116.689,
            "value2": 78.1343
          },
          {
            "category": "01-04-2014",
            "value": 116.338,
            "value2": 78.2668
          },
          {
            "category": "02-04-2014",
            "value": 116.713,
            "value2": 78.8646
          },
          {
            "category": "03-04-2014",
            "value": 116.687,
            "value2": 78.6377
          },
          {
            "category": "04-04-2014",
            "value": 115.363,
            "value2": 78.3432
          },
          {
            "category": "05-04-2014",
            "value": 115.363,
            "value2": 78.3432
          },
          {
            "category": "06-04-2014",
            "value": 115.363,
            "value2": 78.3432
          },
          {
            "category": "07-04-2014",
            "value": 115.718,
            "value2": 78.2968
          },
          {
            "category": "08-04-2014",
            "value": 115.718,
            "value2": 78.2968
          },
          {
            "category": "09-04-2014",
            "value": 116.926,
            "value2": 79.4638
          },
          {
            "category": "10-04-2014",
            "value": 116.818,
            "value2": 79.6966
          },
          {
            "category": "11-04-2014",
            "value": 116.086,
            "value2": 79.514
          },
          {
            "category": "12-04-2014",
            "value": 116.086,
            "value2": 79.514
          },
          {
            "category": "13-04-2014",
            "value": 116.086,
            "value2": 79.514
          },
          {
            "category": "14-04-2014",
            "value": 116.086,
            "value2": 79.514
          },
          {
            "category": "15-04-2014",
            "value": 115.249,
            "value2": 78.9925
          },
          {
            "category": "16-04-2014",
            "value": 114.395,
            "value2": 78.2756
          },
          {
            "category": "17-04-2014",
            "value": 114.97,
            "value2": 79.4283
          },
          {
            "category": "18-04-2014",
            "value": 114.97,
            "value2": 79.4283
          },
          {
            "category": "19-04-2014",
            "value": 114.97,
            "value2": 79.4283
          },
          {
            "category": "20-04-2014",
            "value": 114.97,
            "value2": 79.4283
          },
          {
            "category": "21-04-2014",
            "value": 115.447,
            "value2": 79.9605
          },
          {
            "category": "22-04-2014",
            "value": 115.986,
            "value2": 79.9362
          },
          {
            "category": "23-04-2014",
            "value": 116.694,
            "value2": 80.1885
          },
          {
            "category": "24-04-2014",
            "value": 116.694,
            "value2": 80.1885
          },
          {
            "category": "25-04-2014",
            "value": 116.286,
            "value2": 79.6611
          },
          {
            "category": "26-04-2014",
            "value": 116.286,
            "value2": 79.6611
          },
          {
            "category": "27-04-2014",
            "value": 116.286,
            "value2": 79.6611
          },
          {
            "category": "28-04-2014",
            "value": 115.902,
            "value2": 79.5844
          },
          {
            "category": "29-04-2014",
            "value": 115.528,
            "value2": 79.0595
          },
          {
            "category": "30-04-2014",
            "value": 116.019,
            "value2": 78.5881
          },
          {
            "category": "01-05-2014",
            "value": 115.908,
            "value2": 78.5881
          },
          {
            "category": "02-05-2014",
            "value": 115.853,
            "value2": 78.6781
          },
          {
            "category": "03-05-2014",
            "value": 115.853,
            "value2": 78.6781
          },
          {
            "category": "04-05-2014",
            "value": 115.854,
            "value2": 78.6781
          },
          {
            "category": "05-05-2014",
            "value": 115.845,
            "value2": 78.705
          },
          {
            "category": "06-05-2014",
            "value": 115.574,
            "value2": 78.8847
          },
          {
            "category": "07-05-2014",
            "value": 114.633,
            "value2": 78.3316
          },
          {
            "category": "08-05-2014",
            "value": 114.653,
            "value2": 78.3677
          },
          {
            "category": "09-05-2014",
            "value": 117.121,
            "value2": 80.3747
          },
          {
            "category": "10-05-2014",
            "value": 117.122,
            "value2": 80.3747
          },
          {
            "category": "11-05-2014",
            "value": 117.122,
            "value2": 80.3747
          },
          {
            "category": "12-05-2014",
            "value": 118.942,
            "value2": 81.9094
          },
          {
            "category": "13-05-2014",
            "value": 120.277,
            "value2": 83.163
          },
          {
            "category": "14-05-2014",
            "value": 120.147,
            "value2": 83.4637
          },
          {
            "category": "15-05-2014",
            "value": 119.768,
            "value2": 83.3886
          },
          {
            "category": "16-05-2014",
            "value": 120.1,
            "value2": 84.5574
          },
          {
            "category": "17-05-2014",
            "value": 120.1,
            "value2": 84.5574
          },
          {
            "category": "18-05-2014",
            "value": 120.1,
            "value2": 84.5574
          },
          {
            "category": "19-05-2014",
            "value": 120.951,
            "value2": 86.2123
          },
          {
            "category": "20-05-2014",
            "value": 122.498,
            "value2": 86.5974
          },
          {
            "category": "21-05-2014",
            "value": 123.238,
            "value2": 86.6496
          },
          {
            "category": "22-05-2014",
            "value": 123.751,
            "value2": 87.35
          },
          {
            "category": "23-05-2014",
            "value": 126.131,
            "value2": 88.5957
          },
          {
            "category": "24-05-2014",
            "value": 126.131,
            "value2": 88.5957
          },
          {
            "category": "25-05-2014",
            "value": 126.131,
            "value2": 88.5957
          },
          {
            "category": "26-05-2014",
            "value": 124.859,
            "value2": 88.0148
          },
          {
            "category": "27-05-2014",
            "value": 124.558,
            "value2": 87.4245
          },
          {
            "category": "28-05-2014",
            "value": 124.839,
            "value2": 87.6668
          },
          {
            "category": "29-05-2014",
            "value": 123.616,
            "value2": 86.7835
          },
          {
            "category": "30-05-2014",
            "value": 122.875,
            "value2": 86.8487
          },
          {
            "category": "31-05-2014",
            "value": 122.875,
            "value2": 86.8487
          },
          {
            "category": "01-06-2014",
            "value": 122.874,
            "value2": 86.8487
          },
          {
            "category": "02-06-2014",
            "value": 124.771,
            "value2": 88.485
          },
          {
            "category": "03-06-2014",
            "value": 126.263,
            "value2": 89.2164
          },
          {
            "category": "04-06-2014",
            "value": 125.908,
            "value2": 89.4817
          },
          {
            "category": "05-06-2014",
            "value": 126.336,
            "value2": 90.4802
          },
          {
            "category": "06-06-2014",
            "value": 127.552,
            "value2": 91.861
          },
          {
            "category": "07-06-2014",
            "value": 127.552,
            "value2": 91.861
          },
          {
            "category": "08-06-2014",
            "value": 127.552,
            "value2": 91.861
          },
          {
            "category": "09-06-2014",
            "value": 128.954,
            "value2": 92.9312
          },
          {
            "category": "10-06-2014",
            "value": 129.039,
            "value2": 92.8539
          },
          {
            "category": "11-06-2014",
            "value": 129.694,
            "value2": 92.214
          },
          {
            "category": "12-06-2014",
            "value": 130.812,
            "value2": 92.5412
          },
          {
            "category": "13-06-2014",
            "value": 129.74,
            "value2": 90.8677
          },
          {
            "category": "14-06-2014",
            "value": 129.74,
            "value2": 90.8677
          },
          {
            "category": "15-06-2014",
            "value": 129.74,
            "value2": 90.8677
          },
          {
            "category": "16-06-2014",
            "value": 129.633,
            "value2": 90.9155
          },
          {
            "category": "17-06-2014",
            "value": 130.832,
            "value2": 92.1812
          },
          {
            "category": "18-06-2014",
            "value": 129.948,
            "value2": 91.3495
          },
          {
            "category": "19-06-2014",
            "value": 129.786,
            "value2": 91.0305
          },
          {
            "category": "20-06-2014",
            "value": 129.191,
            "value2": 90.6555
          },
          {
            "category": "21-06-2014",
            "value": 129.191,
            "value2": 90.6555
          },
          {
            "category": "22-06-2014",
            "value": 129.191,
            "value2": 90.6555
          },
          {
            "category": "23-06-2014",
            "value": 129.492,
            "value2": 90.63
          },
          {
            "category": "24-06-2014",
            "value": 130.73,
            "value2": 91.7878
          },
          {
            "category": "25-06-2014",
            "value": 130.935,
            "value2": 91.797
          },
          {
            "category": "26-06-2014",
            "value": 131.925,
            "value2": 91.0859
          },
          {
            "category": "27-06-2014",
            "value": 134.103,
            "value2": 91.318
          },
          {
            "category": "28-06-2014",
            "value": 134.089,
            "value2": 91.318
          },
          {
            "category": "29-06-2014",
            "value": 134.089,
            "value2": 91.318
          },
          {
            "category": "30-06-2014",
            "value": 136.824,
            "value2": 92.6372
          },
          {
            "category": "01-07-2014",
            "value": 135.972,
            "value2": 93.0614
          },
          {
            "category": "02-07-2014",
            "value": 138.131,
            "value2": 94.1964
          },
          {
            "category": "03-07-2014",
            "value": 138.712,
            "value2": 94.1178
          },
          {
            "category": "04-07-2014",
            "value": 139.39,
            "value2": 94.6082
          },
          {
            "category": "05-07-2014",
            "value": 139.39,
            "value2": 94.6082
          },
          {
            "category": "06-07-2014",
            "value": 139.39,
            "value2": 94.6082
          },
          {
            "category": "07-07-2014",
            "value": 139.377,
            "value2": 94.9483
          },
          {
            "category": "08-07-2014",
            "value": 137.233,
            "value2": 92.4429
          },
          {
            "category": "09-07-2014",
            "value": 135.628,
            "value2": 91.7295
          },
          {
            "category": "10-07-2014",
            "value": 134.618,
            "value2": 91.8263
          },
          {
            "category": "11-07-2014",
            "value": 133.364,
            "value2": 90.1231
          },
          {
            "category": "12-07-2014",
            "value": 133.364,
            "value2": 90.1231
          },
          {
            "category": "13-07-2014",
            "value": 133.364,
            "value2": 90.1231
          },
          {
            "category": "14-07-2014",
            "value": 133.85,
            "value2": 90.1547
          },
          {
            "category": "15-07-2014",
            "value": 135.112,
            "value2": 91.2987
          },
          {
            "category": "16-07-2014",
            "value": 136.288,
            "value2": 92.4669
          },
          {
            "category": "17-07-2014",
            "value": 136.837,
            "value2": 92.9798
          },
          {
            "category": "18-07-2014",
            "value": 137.293,
            "value2": 93.0201
          },
          {
            "category": "19-07-2014",
            "value": 137.293,
            "value2": 93.0201
          },
          {
            "category": "20-07-2014",
            "value": 137.293,
            "value2": 93.0201
          },
          {
            "category": "21-07-2014",
            "value": 137.538,
            "value2": 93.2852
          },
          {
            "category": "22-07-2014",
            "value": 138.487,
            "value2": 94.0499
          },
          {
            "category": "23-07-2014",
            "value": 138.766,
            "value2": 94.1994
          },
          {
            "category": "24-07-2014",
            "value": 139.148,
            "value2": 94.4888
          },
          {
            "category": "25-07-2014",
            "value": 140.32,
            "value2": 93.75
          },
          {
            "category": "26-07-2014",
            "value": 140.32,
            "value2": 93.75
          },
          {
            "category": "27-07-2014",
            "value": 140.32,
            "value2": 93.75
          },
          {
            "category": "28-07-2014",
            "value": 140.167,
            "value2": 93.3832
          },
          {
            "category": "29-07-2014",
            "value": 140.167,
            "value2": 93.3832
          },
          {
            "category": "30-07-2014",
            "value": 139.875,
            "value2": 93.8594
          },
          {
            "category": "31-07-2014",
            "value": 139.031,
            "value2": 93.3599
          },
          {
            "category": "01-08-2014",
            "value": 137.267,
            "value2": 92.1446
          },
          {
            "category": "02-08-2014",
            "value": 137.267,
            "value2": 92.1446
          },
          {
            "category": "03-08-2014",
            "value": 137.267,
            "value2": 92.1446
          },
          {
            "category": "04-08-2014",
            "value": 137.847,
            "value2": 93.0903
          },
          {
            "category": "05-08-2014",
            "value": 138.92,
            "value2": 93.8218
          },
          {
            "category": "06-08-2014",
            "value": 138.186,
            "value2": 93.0666
          },
          {
            "category": "07-08-2014",
            "value": 137.897,
            "value2": 92.7986
          },
          {
            "category": "08-08-2014",
            "value": 136.936,
            "value2": 91.564
          },
          {
            "category": "09-08-2014",
            "value": 136.936,
            "value2": 91.564
          },
          {
            "category": "10-08-2014",
            "value": 136.936,
            "value2": 91.564
          },
          {
            "category": "11-08-2014",
            "value": 137.534,
            "value2": 92.2081
          },
          {
            "category": "12-08-2014",
            "value": 140.044,
            "value2": 93.2461
          },
          {
            "category": "13-08-2014",
            "value": 142.443,
            "value2": 92.9226
          },
          {
            "category": "14-08-2014",
            "value": 142.145,
            "value2": 93.7116
          },
          {
            "category": "15-08-2014",
            "value": 142.145,
            "value2": 93.7116
          },
          {
            "category": "16-08-2014",
            "value": 142.145,
            "value2": 93.7116
          },
          {
            "category": "17-08-2014",
            "value": 142.145,
            "value2": 93.7116
          },
          {
            "category": "18-08-2014",
            "value": 142.799,
            "value2": 94.937
          },
          {
            "category": "19-08-2014",
            "value": 142.878,
            "value2": 95.4744
          },
          {
            "category": "20-08-2014",
            "value": 143.592,
            "value2": 95.3979
          },
          {
            "category": "21-08-2014",
            "value": 144.488,
            "value2": 95.6595
          },
          {
            "category": "22-08-2014",
            "value": 145.474,
            "value2": 95.8676
          },
          {
            "category": "23-08-2014",
            "value": 145.474,
            "value2": 95.8676
          },
          {
            "category": "24-08-2014",
            "value": 145.474,
            "value2": 95.8676
          },
          {
            "category": "25-08-2014",
            "value": 145.47,
            "value2": 95.5841
          },
          {
            "category": "26-08-2014",
            "value": 145.643,
            "value2": 95.5134
          },
          {
            "category": "27-08-2014",
            "value": 145.632,
            "value2": 95.9284
          },
          {
            "category": "28-08-2014",
            "value": 145.804,
            "value2": 96.0214
          },
          {
            "category": "29-08-2014",
            "value": 145.804,
            "value2": 96.0214
          },
          {
            "category": "30-08-2014",
            "value": 145.804,
            "value2": 96.0214
          },
          {
            "category": "31-08-2014",
            "value": 145.804,
            "value2": 96.0214
          },
          {
            "category": "01-09-2014",
            "value": 147.238,
            "value2": 97.2044
          },
          {
            "category": "02-09-2014",
            "value": 148.776,
            "value2": 97.8839
          },
          {
            "category": "03-09-2014",
            "value": 150.834,
            "value2": 98.3849
          },
          {
            "category": "04-09-2014",
            "value": 150.616,
            "value2": 98.1719
          },
          {
            "category": "05-09-2014",
            "value": 150.131,
            "value2": 98.1929
          },
          {
            "category": "06-09-2014",
            "value": 150.131,
            "value2": 98.1929
          },
          {
            "category": "07-09-2014",
            "value": 150.131,
            "value2": 98.1929
          },
          {
            "category": "08-09-2014",
            "value": 152.051,
            "value2": 99.3488
          },
          {
            "category": "09-09-2014",
            "value": 151.337,
            "value2": 99.3916
          },
          {
            "category": "10-09-2014",
            "value": 150.332,
            "value2": 98.83
          },
          {
            "category": "11-09-2014",
            "value": 151.852,
            "value2": 99.0217
          },
          {
            "category": "12-09-2014",
            "value": 153.156,
            "value2": 99.2601
          },
          {
            "category": "13-09-2014",
            "value": 153.156,
            "value2": 99.2601
          },
          {
            "category": "14-09-2014",
            "value": 153.156,
            "value2": 99.2601
          },
          {
            "category": "15-09-2014",
            "value": 153.155,
            "value2": 98.7603
          },
          {
            "category": "16-09-2014",
            "value": 150.061,
            "value2": 96.8526
          },
          {
            "category": "17-09-2014",
            "value": 150.096,
            "value2": 97.2758
          },
          {
            "category": "18-09-2014",
            "value": 153.104,
            "value2": 99.0712
          },
          {
            "category": "19-09-2014",
            "value": 153.119,
            "value2": 99.0124
          },
          {
            "category": "20-09-2014",
            "value": 153.119,
            "value2": 99.0124
          },
          {
            "category": "21-09-2014",
            "value": 153.119,
            "value2": 99.0124
          },
          {
            "category": "22-09-2014",
            "value": 153.597,
            "value2": 99.2407
          },
          {
            "category": "23-09-2014",
            "value": 151.991,
            "value2": 97.5922
          },
          {
            "category": "24-09-2014",
            "value": 151.007,
            "value2": 97.2003
          },
          {
            "category": "25-09-2014",
            "value": 149.74,
            "value2": 95.801
          },
          {
            "category": "26-09-2014",
            "value": 150.733,
            "value2": 96.579
          },
          {
            "category": "27-09-2014",
            "value": 150.733,
            "value2": 96.579
          },
          {
            "category": "28-09-2014",
            "value": 150.733,
            "value2": 96.579
          },
          {
            "category": "29-09-2014",
            "value": 151.421,
            "value2": 96.7504
          },
          {
            "category": "30-09-2014",
            "value": 153.586,
            "value2": 96.8531
          },
          {
            "category": "01-10-2014",
            "value": 152.637,
            "value2": 96.5952
          },
          {
            "category": "02-10-2014",
            "value": 152.637,
            "value2": 96.5952
          },
          {
            "category": "03-10-2014",
            "value": 152.637,
            "value2": 96.5952
          },
          {
            "category": "04-10-2014",
            "value": 152.637,
            "value2": 96.5952
          },
          {
            "category": "05-10-2014",
            "value": 152.637,
            "value2": 96.5952
          },
          {
            "category": "06-10-2014",
            "value": 152.637,
            "value2": 96.5952
          },
          {
            "category": "07-10-2014",
            "value": 150.52,
            "value2": 95.5217
          },
          {
            "category": "08-10-2014",
            "value": 149.489,
            "value2": 95.4051
          },
          {
            "category": "09-10-2014",
            "value": 151.061,
            "value2": 96.8505
          },
          {
            "category": "10-10-2014",
            "value": 149.449,
            "value2": 95.5837
          },
          {
            "category": "11-10-2014",
            "value": 149.449,
            "value2": 95.5837
          },
          {
            "category": "12-10-2014",
            "value": 149.449,
            "value2": 95.5837
          },
          {
            "category": "13-10-2014",
            "value": 149.711,
            "value2": 95.9313
          },
          {
            "category": "14-10-2014",
            "value": 149.437,
            "value2": 95.7509
          },
          {
            "category": "15-10-2014",
            "value": 149.437,
            "value2": 95.7509
          },
          {
            "category": "16-10-2014",
            "value": 146.72,
            "value2": 94.0683
          },
          {
            "category": "17-10-2014",
            "value": 147.517,
            "value2": 94.4678
          },
          {
            "category": "18-10-2014",
            "value": 147.517,
            "value2": 94.4678
          },
          {
            "category": "19-10-2014",
            "value": 147.517,
            "value2": 94.4678
          },
          {
            "category": "20-10-2014",
            "value": 150.21,
            "value2": 95.6681
          },
          {
            "category": "21-10-2014",
            "value": 150.929,
            "value2": 96.326
          },
          {
            "category": "22-10-2014",
            "value": 152.722,
            "value2": 97.215
          },
          {
            "category": "23-10-2014",
            "value": 153.411,
            "value2": 97.6698
          },
          {
            "category": "24-10-2014",
            "value": 153.411,
            "value2": 97.6698
          },
          {
            "category": "25-10-2014",
            "value": 153.411,
            "value2": 97.6698
          },
          {
            "category": "26-10-2014",
            "value": 153.411,
            "value2": 97.6698
          },
          {
            "category": "27-10-2014",
            "value": 154.118,
            "value2": 97.2836
          },
          {
            "category": "28-10-2014",
            "value": 155.541,
            "value2": 97.6384
          },
          {
            "category": "29-10-2014",
            "value": 155.985,
            "value2": 98.341
          },
          {
            "category": "30-10-2014",
            "value": 156.88,
            "value2": 99.2255
          },
          {
            "category": "31-10-2014",
            "value": 159.796,
            "value2": 100.931
          },
          {
            "category": "01-11-2014",
            "value": 159.796,
            "value2": 100.931
          },
          {
            "category": "02-11-2014",
            "value": 159.796,
            "value2": 100.931
          },
          {
            "category": "03-11-2014",
            "value": 160.169,
            "value2": 101.198
          },
          {
            "category": "04-11-2014",
            "value": 160.169,
            "value2": 101.198
          },
          {
            "category": "05-11-2014",
            "value": 160.007,
            "value2": 101.329
          },
          {
            "category": "06-11-2014",
            "value": 160.007,
            "value2": 101.329
          },
          {
            "category": "07-11-2014",
            "value": 159.039,
            "value2": 101.468
          },
          {
            "category": "08-11-2014",
            "value": 159.039,
            "value2": 101.468
          },
          {
            "category": "09-11-2014",
            "value": 159.039,
            "value2": 101.468
          },
          {
            "category": "10-11-2014",
            "value": 159.678,
            "value2": 101.523
          },
          {
            "category": "11-11-2014",
            "value": 160.895,
            "value2": 101.916
          },
          {
            "category": "12-11-2014",
            "value": 161.889,
            "value2": 102.291
          },
          {
            "category": "13-11-2014",
            "value": 162.218,
            "value2": 101.972
          },
          {
            "category": "14-11-2014",
            "value": 164.699,
            "value2": 102.443
          },
          {
            "category": "15-11-2014",
            "value": 164.699,
            "value2": 102.443
          },
          {
            "category": "16-11-2014",
            "value": 164.699,
            "value2": 102.443
          },
          {
            "category": "17-11-2014",
            "value": 165.528,
            "value2": 103.023
          },
          {
            "category": "18-11-2014",
            "value": 166.599,
            "value2": 103.197
          },
          {
            "category": "19-11-2014",
            "value": 167.869,
            "value2": 102.629
          },
          {
            "category": "20-11-2014",
            "value": 169.338,
            "value2": 102.739
          },
          {
            "category": "21-11-2014",
            "value": 168.956,
            "value2": 103.373
          },
          {
            "category": "22-11-2014",
            "value": 168.956,
            "value2": 103.373
          },
          {
            "category": "23-11-2014",
            "value": 168.956,
            "value2": 103.373
          },
          {
            "category": "24-11-2014",
            "value": 169.588,
            "value2": 103.805
          },
          {
            "category": "25-11-2014",
            "value": 167.626,
            "value2": 102.785
          },
          {
            "category": "26-11-2014",
            "value": 168.428,
            "value2": 103.032
          },
          {
            "category": "27-11-2014",
            "value": 168.625,
            "value2": 103.307
          },
          {
            "category": "28-11-2014",
            "value": 171.681,
            "value2": 104.392
          },
          {
            "category": "29-11-2014",
            "value": 171.681,
            "value2": 104.392
          },
          {
            "category": "30-11-2014",
            "value": 171.681,
            "value2": 104.392
          },
          {
            "category": "01-12-2014",
            "value": 171.891,
            "value2": 104.122
          },
          {
            "category": "02-12-2014",
            "value": 171.27,
            "value2": 104.119
          },
          {
            "category": "03-12-2014",
            "value": 174.32,
            "value2": 104.673
          },
          {
            "category": "04-12-2014",
            "value": 175.644,
            "value2": 105.043
          },
          {
            "category": "05-12-2014",
            "value": 173.929,
            "value2": 104.711
          },
          {
            "category": "06-12-2014",
            "value": 173.929,
            "value2": 104.711
          },
          {
            "category": "07-12-2014",
            "value": 173.929,
            "value2": 104.711
          },
          {
            "category": "08-12-2014",
            "value": 172.165,
            "value2": 103.555
          },
          {
            "category": "09-12-2014",
            "value": 170.017,
            "value2": 102.208
          },
          {
            "category": "10-12-2014",
            "value": 169.821,
            "value2": 102.65
          },
          {
            "category": "11-12-2014",
            "value": 168.769,
            "value2": 101.871
          },
          {
            "category": "12-12-2014",
            "value": 168.236,
            "value2": 100.91
          },
          {
            "category": "13-12-2014",
            "value": 168.236,
            "value2": 100.91
          },
          {
            "category": "14-12-2014",
            "value": 168.236,
            "value2": 100.91
          },
          {
            "category": "15-12-2014",
            "value": 168.242,
            "value2": 100.667
          },
          {
            "category": "16-12-2014",
            "value": 165.911,
            "value2": 98.535
          },
          {
            "category": "17-12-2014",
            "value": 163.949,
            "value2": 98.0438
          },
          {
            "category": "18-12-2014",
            "value": 166.982,
            "value2": 100.019
          },
          {
            "category": "19-12-2014",
            "value": 167.493,
            "value2": 100.649
          },
          {
            "category": "20-12-2014",
            "value": 167.493,
            "value2": 100.649
          },
          {
            "category": "21-12-2014",
            "value": 167.493,
            "value2": 100.649
          },
          {
            "category": "22-12-2014",
            "value": 168.96,
            "value2": 101.735
          },
          {
            "category": "23-12-2014",
            "value": 168.441,
            "value2": 101.131
          },
          {
            "category": "24-12-2014",
            "value": 166.611,
            "value2": 100.438
          },
          {
            "category": "25-12-2014",
            "value": 166.611,
            "value2": 100.438
          },
          {
            "category": "26-12-2014",
            "value": 168.252,
            "value2": 100.671
          },
          {
            "category": "27-12-2014",
            "value": 168.252,
            "value2": 100.671
          },
          {
            "category": "28-12-2014",
            "value": 168.252,
            "value2": 100.671
          },
          {
            "category": "29-12-2014",
            "value": 170.801,
            "value2": 101.372
          },
          {
            "category": "30-12-2014",
            "value": 171.264,
            "value2": 101.622
          },
          {
            "category": "31-12-2014",
            "value": 170.783,
            "value2": 102.185
          },
          {
            "category": "01-01-2015",
            "value": 170.664,
            "value2": 102.405
          },
          {
            "category": "02-01-2015",
            "value": 171.915,
            "value2": 103.565
          },
          {
            "category": "03-01-2015",
            "value": 171.915,
            "value2": 103.565
          },
          {
            "category": "04-01-2015",
            "value": 171.915,
            "value2": 103.565
          },
          {
            "category": "05-01-2015",
            "value": 171.617,
            "value2": 103.447
          },
          {
            "category": "06-01-2015",
            "value": 167.089,
            "value2": 100.442
          },
          {
            "category": "07-01-2015",
            "value": 167.414,
            "value2": 100.292
          },
          {
            "category": "08-01-2015",
            "value": 169.783,
            "value2": 101.874
          },
          {
            "category": "09-01-2015",
            "value": 170.357,
            "value2": 102.277
          },
          {
            "category": "10-01-2015",
            "value": 170.357,
            "value2": 102.277
          },
          {
            "category": "11-01-2015",
            "value": 170.357,
            "value2": 102.277
          },
          {
            "category": "12-01-2015",
            "value": 172.713,
            "value2": 102.834
          },
          {
            "category": "13-01-2015",
            "value": 172.675,
            "value2": 102.622
          },
          {
            "category": "14-01-2015",
            "value": 173.394,
            "value2": 102.44
          },
          {
            "category": "15-01-2015",
            "value": 176.878,
            "value2": 104.605
          },
          {
            "category": "16-01-2015",
            "value": 178.361,
            "value2": 104.965
          },
          {
            "category": "17-01-2015",
            "value": 178.361,
            "value2": 104.965
          },
          {
            "category": "18-01-2015",
            "value": 178.361,
            "value2": 104.965
          },
          {
            "category": "19-01-2015",
            "value": 178.858,
            "value2": 105.48
          },
          {
            "category": "20-01-2015",
            "value": 180.399,
            "value2": 106.763
          },
          {
            "category": "21-01-2015",
            "value": 181.593,
            "value2": 106.993
          },
          {
            "category": "22-01-2015",
            "value": 182.565,
            "value2": 107.427
          },
          {
            "category": "23-01-2015",
            "value": 183.374,
            "value2": 108.12
          },
          {
            "category": "24-01-2015",
            "value": 183.374,
            "value2": 108.12
          },
          {
            "category": "25-01-2015",
            "value": 183.374,
            "value2": 108.12
          },
          {
            "category": "26-01-2015",
            "value": 183.374,
            "value2": 108.12
          },
          {
            "category": "27-01-2015",
            "value": 187.94,
            "value2": 109.041
          },
          {
            "category": "28-01-2015",
            "value": 189.287,
            "value2": 109.196
          },
          {
            "category": "29-01-2015",
            "value": 189.977,
            "value2": 109.386
          },
          {
            "category": "30-01-2015",
            "value": 187.297,
            "value2": 108.163
          },
          {
            "category": "31-01-2015",
            "value": 187.297,
            "value2": 108.163
          },
          {
            "category": "01-02-2015",
            "value": 187.297,
            "value2": 108.163
          },
          {
            "category": "02-02-2015",
            "value": 188.518,
            "value2": 108.236
          },
          {
            "category": "03-02-2015",
            "value": 187.315,
            "value2": 107.716
          },
          {
            "category": "04-02-2015",
            "value": 184.736,
            "value2": 107.278
          },
          {
            "category": "05-02-2015",
            "value": 185.237,
            "value2": 106.812
          },
          {
            "category": "06-02-2015",
            "value": 183.028,
            "value2": 106.097
          },
          {
            "category": "07-02-2015",
            "value": 183.028,
            "value2": 106.097
          },
          {
            "category": "08-02-2015",
            "value": 183.028,
            "value2": 106.097
          },
          {
            "category": "09-02-2015",
            "value": 179.706,
            "value2": 104.517
          },
          {
            "category": "10-02-2015",
            "value": 179.687,
            "value2": 105.058
          },
          {
            "category": "11-02-2015",
            "value": 183.699,
            "value2": 106.137
          },
          {
            "category": "12-02-2015",
            "value": 187.004,
            "value2": 107.268
          },
          {
            "category": "13-02-2015",
            "value": 188.066,
            "value2": 108.258
          },
          {
            "category": "14-02-2015",
            "value": 188.066,
            "value2": 108.258
          },
          {
            "category": "15-02-2015",
            "value": 188.066,
            "value2": 108.258
          },
          {
            "category": "16-02-2015",
            "value": 187.923,
            "value2": 108.245
          },
          {
            "category": "17-02-2015",
            "value": 187.923,
            "value2": 108.245
          },
          {
            "category": "18-02-2015",
            "value": 191.796,
            "value2": 109.014
          },
          {
            "category": "19-02-2015",
            "value": 190.486,
            "value2": 109.257
          },
          {
            "category": "20-02-2015",
            "value": 189.34,
            "value2": 108.76
          },
          {
            "category": "21-02-2015",
            "value": 189.34,
            "value2": 108.76
          },
          {
            "category": "22-02-2015",
            "value": 189.34,
            "value2": 108.76
          },
          {
            "category": "23-02-2015",
            "value": 187.072,
            "value2": 107.801
          },
          {
            "category": "24-02-2015",
            "value": 187.284,
            "value2": 107.829
          },
          {
            "category": "25-02-2015",
            "value": 186.45,
            "value2": 107.787
          },
          {
            "category": "26-02-2015",
            "value": 183.936,
            "value2": 106.871
          },
          {
            "category": "27-02-2015",
            "value": 187.6,
            "value2": 108.769
          },
          {
            "category": "28-02-2015",
            "value": 189.335,
            "value2": 109.237
          },
          {
            "category": "01-03-2015",
            "value": 189.325,
            "value2": 109.237
          },
          {
            "category": "02-03-2015",
            "value": 190.673,
            "value2": 110.141
          },
          {
            "category": "03-03-2015",
            "value": 191.861,
            "value2": 110.912
          },
          {
            "category": "04-03-2015",
            "value": 192.729,
            "value2": 110.004
          },
          {
            "category": "05-03-2015",
            "value": 194.18,
            "value2": 110.4
          },
          {
            "category": "06-03-2015",
            "value": 194.18,
            "value2": 110.4
          },
          {
            "category": "07-03-2015",
            "value": 194.18,
            "value2": 110.4
          },
          {
            "category": "08-03-2015",
            "value": 194.18,
            "value2": 110.4
          },
          {
            "category": "09-03-2015",
            "value": 190.53,
            "value2": 108.432
          },
          {
            "category": "10-03-2015",
            "value": 190.301,
            "value2": 108.039
          },
          {
            "category": "11-03-2015",
            "value": 190.522,
            "value2": 107.797
          },
          {
            "category": "12-03-2015",
            "value": 192.096,
            "value2": 108.911
          },
          {
            "category": "13-03-2015",
            "value": 188.746,
            "value2": 107.345
          },
          {
            "category": "14-03-2015",
            "value": 188.746,
            "value2": 107.345
          },
          {
            "category": "15-03-2015",
            "value": 188.746,
            "value2": 107.345
          },
          {
            "category": "16-03-2015",
            "value": 188.895,
            "value2": 107.085
          },
          {
            "category": "17-03-2015",
            "value": 191.214,
            "value2": 108.176
          },
          {
            "category": "18-03-2015",
            "value": 191.498,
            "value2": 107.993
          },
          {
            "category": "19-03-2015",
            "value": 191.863,
            "value2": 107.362
          },
          {
            "category": "20-03-2015",
            "value": 189.855,
            "value2": 106.333
          },
          {
            "category": "21-03-2015",
            "value": 189.855,
            "value2": 106.333
          },
          {
            "category": "22-03-2015",
            "value": 189.855,
            "value2": 106.333
          },
          {
            "category": "23-03-2015",
            "value": 188.421,
            "value2": 105.819
          },
          {
            "category": "24-03-2015",
            "value": 187.394,
            "value2": 105.704
          },
          {
            "category": "25-03-2015",
            "value": 186.231,
            "value2": 105.468
          },
          {
            "category": "26-03-2015",
            "value": 182.841,
            "value2": 103.625
          },
          {
            "category": "27-03-2015",
            "value": 182.454,
            "value2": 103.53
          },
          {
            "category": "28-03-2015",
            "value": 182.454,
            "value2": 103.53
          },
          {
            "category": "29-03-2015",
            "value": 182.454,
            "value2": 103.53
          },
          {
            "category": "30-03-2015",
            "value": 185.837,
            "value2": 105.404
          },
          {
            "category": "31-03-2015",
            "value": 182.807,
            "value2": 105.491
          },
          {
            "category": "01-04-2015",
            "value": 185.539,
            "value2": 106.781
          },
          {
            "category": "02-04-2015",
            "value": 185.539,
            "value2": 106.781
          },
          {
            "category": "03-04-2015",
            "value": 185.539,
            "value2": 106.781
          },
          {
            "category": "04-04-2015",
            "value": 185.539,
            "value2": 106.781
          },
          {
            "category": "05-04-2015",
            "value": 185.539,
            "value2": 106.781
          },
          {
            "category": "06-04-2015",
            "value": 187.15,
            "value2": 107.776
          },
          {
            "category": "07-04-2015",
            "value": 187.662,
            "value2": 108.105
          },
          {
            "category": "08-04-2015",
            "value": 190.277,
            "value2": 108.818
          },
          {
            "category": "09-04-2015",
            "value": 189.392,
            "value2": 109.4
          },
          {
            "category": "10-04-2015",
            "value": 189.391,
            "value2": 109.565
          },
          {
            "category": "11-04-2015",
            "value": 189.391,
            "value2": 109.565
          },
          {
            "category": "12-04-2015",
            "value": 189.391,
            "value2": 109.565
          },
          {
            "category": "13-04-2015",
            "value": 190.218,
            "value2": 110.164
          },
          {
            "category": "14-04-2015",
            "value": 190.218,
            "value2": 110.164
          },
          {
            "category": "15-04-2015",
            "value": 188.267,
            "value2": 109.317
          },
          {
            "category": "16-04-2015",
            "value": 186.546,
            "value2": 108.605
          },
          {
            "category": "17-04-2015",
            "value": 182.166,
            "value2": 107.314
          },
          {
            "category": "18-04-2015",
            "value": 182.166,
            "value2": 107.314
          },
          {
            "category": "19-04-2015",
            "value": 182.166,
            "value2": 107.314
          },
          {
            "category": "20-04-2015",
            "value": 178.28,
            "value2": 105.279
          },
          {
            "category": "21-04-2015",
            "value": 174.912,
            "value2": 104.551
          },
          {
            "category": "22-04-2015",
            "value": 175.061,
            "value2": 104.986
          },
          {
            "category": "23-04-2015",
            "value": 174.686,
            "value2": 104.705
          },
          {
            "category": "24-04-2015",
            "value": 171.799,
            "value2": 103.248
          },
          {
            "category": "25-04-2015",
            "value": 171.799,
            "value2": 103.248
          },
          {
            "category": "26-04-2015",
            "value": 171.799,
            "value2": 103.248
          },
          {
            "category": "27-04-2015",
            "value": 169.003,
            "value2": 101.714
          },
          {
            "category": "28-04-2015",
            "value": 172.069,
            "value2": 102.768
          },
          {
            "category": "29-04-2015",
            "value": 171.941,
            "value2": 102.585
          },
          {
            "category": "30-04-2015",
            "value": 171.767,
            "value2": 102.139
          },
          {
            "category": "01-05-2015",
            "value": 171.536,
            "value2": 102.139
          },
          {
            "category": "02-05-2015",
            "value": 171.536,
            "value2": 102.139
          },
          {
            "category": "03-05-2015",
            "value": 171.536,
            "value2": 102.139
          },
          {
            "category": "04-05-2015",
            "value": 173.833,
            "value2": 103.979
          },
          {
            "category": "05-05-2015",
            "value": 174.29,
            "value2": 104.073
          },
          {
            "category": "06-05-2015",
            "value": 169.773,
            "value2": 101.082
          },
          {
            "category": "07-05-2015",
            "value": 168.045,
            "value2": 100.122
          },
          {
            "category": "08-05-2015",
            "value": 171.394,
            "value2": 101.867
          },
          {
            "category": "09-05-2015",
            "value": 171.394,
            "value2": 101.867
          },
          {
            "category": "10-05-2015",
            "value": 171.394,
            "value2": 101.867
          },
          {
            "category": "11-05-2015",
            "value": 176.305,
            "value2": 103.48
          },
          {
            "category": "12-05-2015",
            "value": 173.859,
            "value2": 101.253
          },
          {
            "category": "13-05-2015",
            "value": 178.537,
            "value2": 102.635
          },
          {
            "category": "14-05-2015",
            "value": 179.783,
            "value2": 102.891
          },
          {
            "category": "15-05-2015",
            "value": 181.39,
            "value2": 103.326
          },
          {
            "category": "16-05-2015",
            "value": 181.39,
            "value2": 103.326
          },
          {
            "category": "17-05-2015",
            "value": 181.39,
            "value2": 103.326
          },
          {
            "category": "18-05-2015",
            "value": 182.256,
            "value2": 104.581
          },
          {
            "category": "19-05-2015",
            "value": 181.908,
            "value2": 104.581
          },
          {
            "category": "20-05-2015",
            "value": 183.329,
            "value2": 105.102
          },
          {
            "category": "21-05-2015",
            "value": 183.843,
            "value2": 105.017
          },
          {
            "category": "22-05-2015",
            "value": 185.091,
            "value2": 105.387
          },
          {
            "category": "23-05-2015",
            "value": 185.091,
            "value2": 105.387
          },
          {
            "category": "24-05-2015",
            "value": 185.091,
            "value2": 105.387
          },
          {
            "category": "25-05-2015",
            "value": 184.962,
            "value2": 104.651
          },
          {
            "category": "26-05-2015",
            "value": 183.788,
            "value2": 104.312
          },
          {
            "category": "27-05-2015",
            "value": 180.148,
            "value2": 104.242
          },
          {
            "category": "28-05-2015",
            "value": 178.535,
            "value2": 103.994
          },
          {
            "category": "29-05-2015",
            "value": 182.648,
            "value2": 105.358
          },
          {
            "category": "30-05-2015",
            "value": 182.648,
            "value2": 105.358
          },
          {
            "category": "31-05-2015",
            "value": 182.648,
            "value2": 105.358
          },
          {
            "category": "01-06-2015",
            "value": 180.11,
            "value2": 105.237
          },
          {
            "category": "02-06-2015",
            "value": 175.719,
            "value2": 102.922
          },
          {
            "category": "03-06-2015",
            "value": 173.629,
            "value2": 101.51
          },
          {
            "category": "04-06-2015",
            "value": 172.977,
            "value2": 101.597
          },
          {
            "category": "05-06-2015",
            "value": 173.24,
            "value2": 101.545
          },
          {
            "category": "06-06-2015",
            "value": 173.24,
            "value2": 101.545
          },
          {
            "category": "07-06-2015",
            "value": 173.24,
            "value2": 101.545
          },
          {
            "category": "08-06-2015",
            "value": 170.967,
            "value2": 100.422
          },
          {
            "category": "09-06-2015",
            "value": 168.851,
            "value2": 100.195
          },
          {
            "category": "10-06-2015",
            "value": 171.965,
            "value2": 101.391
          },
          {
            "category": "11-06-2015",
            "value": 168.927,
            "value2": 99.5908
          },
          {
            "category": "12-06-2015",
            "value": 168.933,
            "value2": 99.6857
          },
          {
            "category": "13-06-2015",
            "value": 168.933,
            "value2": 99.6857
          },
          {
            "category": "14-06-2015",
            "value": 168.933,
            "value2": 99.6857
          },
          {
            "category": "15-06-2015",
            "value": 170.465,
            "value2": 100.117
          },
          {
            "category": "16-06-2015",
            "value": 171.308,
            "value2": 100.483
          },
          {
            "category": "17-06-2015",
            "value": 173.416,
            "value2": 101.266
          },
          {
            "category": "18-06-2015",
            "value": 174.694,
            "value2": 102.268
          },
          {
            "category": "19-06-2015",
            "value": 175.225,
            "value2": 102.952
          },
          {
            "category": "20-06-2015",
            "value": 175.225,
            "value2": 102.952
          },
          {
            "category": "21-06-2015",
            "value": 175.225,
            "value2": 102.952
          },
          {
            "category": "22-06-2015",
            "value": 177.423,
            "value2": 104.471
          },
          {
            "category": "23-06-2015",
            "value": 177.702,
            "value2": 104.826
          },
          {
            "category": "24-06-2015",
            "value": 177.025,
            "value2": 104.399
          },
          {
            "category": "25-06-2015",
            "value": 178.107,
            "value2": 104.897
          },
          {
            "category": "26-06-2015",
            "value": 177.551,
            "value2": 104.716
          },
          {
            "category": "27-06-2015",
            "value": 177.551,
            "value2": 104.716
          },
          {
            "category": "28-06-2015",
            "value": 177.551,
            "value2": 104.716
          },
          {
            "category": "29-06-2015",
            "value": 175.079,
            "value2": 103.763
          },
          {
            "category": "30-06-2015",
            "value": 176.555,
            "value2": 104.549
          },
          {
            "category": "01-07-2015",
            "value": 178.423,
            "value2": 105.673
          },
          {
            "category": "02-07-2015",
            "value": 180.357,
            "value2": 105.854
          },
          {
            "category": "03-07-2015",
            "value": 179.67,
            "value2": 106.179
          },
          {
            "category": "04-07-2015",
            "value": 179.67,
            "value2": 106.179
          },
          {
            "category": "05-07-2015",
            "value": 179.67,
            "value2": 106.179
          },
          {
            "category": "06-07-2015",
            "value": 181.179,
            "value2": 106.805
          },
          {
            "category": "07-07-2015",
            "value": 181.605,
            "value2": 106.916
          },
          {
            "category": "08-07-2015",
            "value": 179.076,
            "value2": 105.249
          },
          {
            "category": "09-07-2015",
            "value": 179.239,
            "value2": 104.889
          },
          {
            "category": "10-07-2015",
            "value": 180.495,
            "value2": 105.204
          },
          {
            "category": "11-07-2015",
            "value": 180.495,
            "value2": 105.204
          },
          {
            "category": "12-07-2015",
            "value": 180.495,
            "value2": 105.204
          },
          {
            "category": "13-07-2015",
            "value": 183.413,
            "value2": 106.446
          },
          {
            "category": "14-07-2015",
            "value": 182.863,
            "value2": 106.475
          },
          {
            "category": "15-07-2015",
            "value": 183.92,
            "value2": 107.244
          },
          {
            "category": "16-07-2015",
            "value": 186.41,
            "value2": 108.303
          },
          {
            "category": "17-07-2015",
            "value": 186.059,
            "value2": 108.487
          },
          {
            "category": "18-07-2015",
            "value": 186.06,
            "value2": 108.487
          },
          {
            "category": "19-07-2015",
            "value": 186.06,
            "value2": 108.487
          },
          {
            "category": "20-07-2015",
            "value": 186.841,
            "value2": 108.579
          },
          {
            "category": "21-07-2015",
            "value": 181.76,
            "value2": 107.357
          },
          {
            "category": "22-07-2015",
            "value": 184.086,
            "value2": 108.562
          },
          {
            "category": "23-07-2015",
            "value": 183.033,
            "value2": 108.335
          },
          {
            "category": "24-07-2015",
            "value": 182.81,
            "value2": 107.5
          },
          {
            "category": "25-07-2015",
            "value": 182.81,
            "value2": 107.5
          },
          {
            "category": "26-07-2015",
            "value": 182.81,
            "value2": 107.5
          },
          {
            "category": "27-07-2015",
            "value": 179.732,
            "value2": 105.756
          },
          {
            "category": "28-07-2015",
            "value": 179.287,
            "value2": 105.427
          },
          {
            "category": "29-07-2015",
            "value": 180.293,
            "value2": 105.999
          },
          {
            "category": "30-07-2015",
            "value": 180.074,
            "value2": 106.721
          },
          {
            "category": "31-07-2015",
            "value": 182.025,
            "value2": 108.031
          },
          {
            "category": "01-08-2015",
            "value": 182.03,
            "value2": 108.031
          },
          {
            "category": "02-08-2015",
            "value": 182.03,
            "value2": 108.031
          },
          {
            "category": "03-08-2015",
            "value": 181.897,
            "value2": 108.385
          },
          {
            "category": "04-08-2015",
            "value": 183.733,
            "value2": 108.55
          },
          {
            "category": "05-08-2015",
            "value": 186.061,
            "value2": 109.288
          },
          {
            "category": "06-08-2015",
            "value": 186.729,
            "value2": 109.522
          },
          {
            "category": "07-08-2015",
            "value": 185.973,
            "value2": 109.271
          },
          {
            "category": "08-08-2015",
            "value": 185.973,
            "value2": 109.271
          },
          {
            "category": "09-08-2015",
            "value": 185.973,
            "value2": 109.271
          },
          {
            "category": "10-08-2015",
            "value": 185.584,
            "value2": 108.823
          },
          {
            "category": "11-08-2015",
            "value": 184.332,
            "value2": 107.928
          },
          {
            "category": "12-08-2015",
            "value": 180.695,
            "value2": 106.155
          },
          {
            "category": "13-08-2015",
            "value": 180.97,
            "value2": 106.052
          },
          {
            "category": "14-08-2015",
            "value": 185.364,
            "value2": 108.113
          },
          {
            "category": "15-08-2015",
            "value": 185.364,
            "value2": 108.113
          },
          {
            "category": "16-08-2015",
            "value": 185.364,
            "value2": 108.113
          },
          {
            "category": "17-08-2015",
            "value": 185.451,
            "value2": 107.838
          },
          {
            "category": "18-08-2015",
            "value": 186.024,
            "value2": 107.961
          },
          {
            "category": "19-08-2015",
            "value": 186.922,
            "value2": 108.236
          },
          {
            "category": "20-08-2015",
            "value": 184.912,
            "value2": 106.645
          },
          {
            "category": "21-08-2015",
            "value": 182.074,
            "value2": 105.763
          },
          {
            "category": "22-08-2015",
            "value": 182.066,
            "value2": 105.763
          },
          {
            "category": "23-08-2015",
            "value": 182.062,
            "value2": 105.763
          },
          {
            "category": "24-08-2015",
            "value": 170.809,
            "value2": 98.7069
          },
          {
            "category": "25-08-2015",
            "value": 172.441,
            "value2": 99.9514
          },
          {
            "category": "26-08-2015",
            "value": 169.25,
            "value2": 99.0523
          },
          {
            "category": "27-08-2015",
            "value": 173.668,
            "value2": 101.27
          },
          {
            "category": "28-08-2015",
            "value": 174.264,
            "value2": 101.739
          },
          {
            "category": "29-08-2015",
            "value": 174.268,
            "value2": 101.739
          },
          {
            "category": "30-08-2015",
            "value": 174.268,
            "value2": 101.739
          },
          {
            "category": "31-08-2015",
            "value": 174.052,
            "value2": 101.421
          },
          {
            "category": "01-09-2015",
            "value": 169.803,
            "value2": 99.2044
          },
          {
            "category": "02-09-2015",
            "value": 167.85,
            "value2": 98.544
          },
          {
            "category": "03-09-2015",
            "value": 169.154,
            "value2": 99.8402
          },
          {
            "category": "04-09-2015",
            "value": 165.734,
            "value2": 97.712
          },
          {
            "category": "05-09-2015",
            "value": 165.73,
            "value2": 97.712
          },
          {
            "category": "06-09-2015",
            "value": 165.73,
            "value2": 97.712
          },
          {
            "category": "07-09-2015",
            "value": 162.201,
            "value2": 96.245
          },
          {
            "category": "08-09-2015",
            "value": 163.818,
            "value2": 97.5552
          },
          {
            "category": "09-09-2015",
            "value": 166.166,
            "value2": 99.228
          },
          {
            "category": "10-09-2015",
            "value": 166.501,
            "value2": 99.0238
          },
          {
            "category": "11-09-2015",
            "value": 167.538,
            "value2": 99.0596
          },
          {
            "category": "12-09-2015",
            "value": 167.538,
            "value2": 99.0596
          },
          {
            "category": "13-09-2015",
            "value": 167.537,
            "value2": 99.0596
          },
          {
            "category": "14-09-2015",
            "value": 169.552,
            "value2": 100.165
          },
          {
            "category": "15-09-2015",
            "value": 167.739,
            "value2": 99.5543
          },
          {
            "category": "16-09-2015",
            "value": 167.327,
            "value2": 100.048
          },
          {
            "category": "17-09-2015",
            "value": 167.329,
            "value2": 100.048
          },
          {
            "category": "18-09-2015",
            "value": 169.326,
            "value2": 101.102
          },
          {
            "category": "19-09-2015",
            "value": 169.326,
            "value2": 101.102
          },
          {
            "category": "20-09-2015",
            "value": 169.326,
            "value2": 101.102
          },
          {
            "category": "21-09-2015",
            "value": 169.155,
            "value2": 101.272
          },
          {
            "category": "22-09-2015",
            "value": 166.153,
            "value2": 99.4059
          },
          {
            "category": "23-09-2015",
            "value": 166.282,
            "value2": 99.8965
          },
          {
            "category": "24-09-2015",
            "value": 165.939,
            "value2": 100.146
          },
          {
            "category": "25-09-2015",
            "value": 165.936,
            "value2": 100.146
          },
          {
            "category": "26-09-2015",
            "value": 165.936,
            "value2": 100.146
          },
          {
            "category": "27-09-2015",
            "value": 165.936,
            "value2": 100.146
          },
          {
            "category": "28-09-2015",
            "value": 163.912,
            "value2": 99.4145
          },
          {
            "category": "29-09-2015",
            "value": 164.21,
            "value2": 99.7992
          },
          {
            "category": "30-09-2015",
            "value": 165.343,
            "value2": 101.182
          },
          {
            "category": "01-10-2015",
            "value": 166.067,
            "value2": 101.332
          },
          {
            "category": "02-10-2015",
            "value": 166.068,
            "value2": 101.332
          },
          {
            "category": "03-10-2015",
            "value": 166.068,
            "value2": 101.332
          },
          {
            "category": "04-10-2015",
            "value": 166.068,
            "value2": 101.332
          },
          {
            "category": "05-10-2015",
            "value": 170.523,
            "value2": 103.357
          },
          {
            "category": "06-10-2015",
            "value": 171.247,
            "value2": 103.832
          },
          {
            "category": "07-10-2015",
            "value": 172.52,
            "value2": 104.117
          },
          {
            "category": "08-10-2015",
            "value": 171.642,
            "value2": 103.508
          },
          {
            "category": "09-10-2015",
            "value": 170.956,
            "value2": 103.988
          },
          {
            "category": "10-10-2015",
            "value": 170.956,
            "value2": 103.988
          },
          {
            "category": "11-10-2015",
            "value": 170.956,
            "value2": 103.988
          },
          {
            "category": "12-10-2015",
            "value": 169.79,
            "value2": 103.627
          },
          {
            "category": "13-10-2015",
            "value": 169.461,
            "value2": 103.522
          },
          {
            "category": "14-10-2015",
            "value": 169.046,
            "value2": 103.345
          },
          {
            "category": "15-10-2015",
            "value": 170.046,
            "value2": 104.135
          },
          {
            "category": "16-10-2015",
            "value": 171.59,
            "value2": 104.769
          },
          {
            "category": "17-10-2015",
            "value": 171.59,
            "value2": 104.769
          },
          {
            "category": "18-10-2015",
            "value": 171.59,
            "value2": 104.769
          },
          {
            "category": "19-10-2015",
            "value": 172.148,
            "value2": 105.293
          },
          {
            "category": "20-10-2015",
            "value": 172.513,
            "value2": 105.305
          },
          {
            "category": "21-10-2015",
            "value": 171.663,
            "value2": 105.124
          },
          {
            "category": "22-10-2015",
            "value": 171.663,
            "value2": 105.124
          },
          {
            "category": "23-10-2015",
            "value": 172.196,
            "value2": 105.408
          },
          {
            "category": "24-10-2015",
            "value": 172.193,
            "value2": 105.408
          },
          {
            "category": "25-10-2015",
            "value": 172.193,
            "value2": 105.408
          },
          {
            "category": "26-10-2015",
            "value": 170.523,
            "value2": 104.929
          },
          {
            "category": "27-10-2015",
            "value": 170.69,
            "value2": 104.744
          },
          {
            "category": "28-10-2015",
            "value": 169.187,
            "value2": 104.128
          },
          {
            "category": "29-10-2015",
            "value": 167.071,
            "value2": 103.481
          },
          {
            "category": "30-10-2015",
            "value": 167.154,
            "value2": 102.948
          },
          {
            "category": "31-10-2015",
            "value": 167.154,
            "value2": 102.948
          },
          {
            "category": "01-11-2015",
            "value": 167.156,
            "value2": 102.948
          },
          {
            "category": "02-11-2015",
            "value": 166.115,
            "value2": 102.794
          },
          {
            "category": "03-11-2015",
            "value": 166.717,
            "value2": 102.997
          },
          {
            "category": "04-11-2015",
            "value": 166.063,
            "value2": 102.826
          },
          {
            "category": "05-11-2015",
            "value": 163.932,
            "value2": 101.649
          },
          {
            "category": "06-11-2015",
            "value": 163.083,
            "value2": 101.411
          },
          {
            "category": "07-11-2015",
            "value": 163.083,
            "value2": 101.411
          },
          {
            "category": "08-11-2015",
            "value": 163.083,
            "value2": 101.411
          },
          {
            "category": "09-11-2015",
            "value": 161.642,
            "value2": 101.298
          },
          {
            "category": "10-11-2015",
            "value": 159.366,
            "value2": 99.7814
          },
          {
            "category": "11-11-2015",
            "value": 161.089,
            "value2": 100.54
          },
          {
            "category": "12-11-2015",
            "value": 161.089,
            "value2": 100.54
          },
          {
            "category": "13-11-2015",
            "value": 160.714,
            "value2": 99.6053
          },
          {
            "category": "14-11-2015",
            "value": 160.714,
            "value2": 99.6053
          },
          {
            "category": "15-11-2015",
            "value": 160.714,
            "value2": 99.6053
          },
          {
            "category": "16-11-2015",
            "value": 160.575,
            "value2": 100.015
          },
          {
            "category": "17-11-2015",
            "value": 161.695,
            "value2": 100.439
          },
          {
            "category": "18-11-2015",
            "value": 160.935,
            "value2": 99.3578
          },
          {
            "category": "19-11-2015",
            "value": 163.484,
            "value2": 100.701
          },
          {
            "category": "20-11-2015",
            "value": 163.226,
            "value2": 100.988
          },
          {
            "category": "21-11-2015",
            "value": 163.232,
            "value2": 100.988
          },
          {
            "category": "22-11-2015",
            "value": 163.232,
            "value2": 100.988
          },
          {
            "category": "23-11-2015",
            "value": 162.643,
            "value2": 101.043
          },
          {
            "category": "24-11-2015",
            "value": 161.984,
            "value2": 100.945
          },
          {
            "category": "25-11-2015",
            "value": 161.984,
            "value2": 100.945
          },
          {
            "category": "26-11-2015",
            "value": 162.024,
            "value2": 101.499
          },
          {
            "category": "27-11-2015",
            "value": 163.301,
            "value2": 102.047
          },
          {
            "category": "28-11-2015",
            "value": 163.284,
            "value2": 102.047
          },
          {
            "category": "29-11-2015",
            "value": 163.284,
            "value2": 102.047
          },
          {
            "category": "30-11-2015",
            "value": 162.619,
            "value2": 102.116
          },
          {
            "category": "01-12-2015",
            "value": 162.226,
            "value2": 102.413
          },
          {
            "category": "02-12-2015",
            "value": 161.807,
            "value2": 102.26
          },
          {
            "category": "03-12-2015",
            "value": 161.349,
            "value2": 101.496
          },
          {
            "category": "04-12-2015",
            "value": 160.215,
            "value2": 100.553
          },
          {
            "category": "05-12-2015",
            "value": 160.215,
            "value2": 100.553
          },
          {
            "category": "06-12-2015",
            "value": 160.215,
            "value2": 100.553
          },
          {
            "category": "07-12-2015",
            "value": 160.947,
            "value2": 100.43
          },
          {
            "category": "08-12-2015",
            "value": 159.812,
            "value2": 99.4685
          },
          {
            "category": "09-12-2015",
            "value": 157.101,
            "value2": 98.0818
          },
          {
            "category": "10-12-2015",
            "value": 158.87,
            "value2": 98.9761
          },
          {
            "category": "11-12-2015",
            "value": 157.48,
            "value2": 98.0686
          },
          {
            "category": "12-12-2015",
            "value": 157.48,
            "value2": 98.0686
          },
          {
            "category": "13-12-2015",
            "value": 157.48,
            "value2": 98.0686
          },
          {
            "category": "14-12-2015",
            "value": 158.272,
            "value2": 98.563
          },
          {
            "category": "15-12-2015",
            "value": 160.863,
            "value2": 99.1657
          },
          {
            "category": "16-12-2015",
            "value": 161.879,
            "value2": 99.7316
          },
          {
            "category": "17-12-2015",
            "value": 163.238,
            "value2": 101.044
          },
          {
            "category": "18-12-2015",
            "value": 161.753,
            "value2": 100.34
          },
          {
            "category": "19-12-2015",
            "value": 161.753,
            "value2": 100.34
          },
          {
            "category": "20-12-2015",
            "value": 161.753,
            "value2": 100.34
          },
          {
            "category": "21-12-2015",
            "value": 161.147,
            "value2": 101.113
          },
          {
            "category": "22-12-2015",
            "value": 161.515,
            "value2": 100.677
          },
          {
            "category": "23-12-2015",
            "value": 162.79,
            "value2": 101.504
          },
          {
            "category": "24-12-2015",
            "value": 162.72,
            "value2": 101.611
          },
          {
            "category": "25-12-2015",
            "value": 162.72,
            "value2": 101.611
          },
          {
            "category": "26-12-2015",
            "value": 162.72,
            "value2": 101.611
          },
          {
            "category": "27-12-2015",
            "value": 162.72,
            "value2": 101.611
          },
          {
            "category": "28-12-2015",
            "value": 164.765,
            "value2": 102.187
          },
          {
            "category": "29-12-2015",
            "value": 164.538,
            "value2": 102.342
          },
          {
            "category": "30-12-2015",
            "value": 163.762,
            "value2": 102.08
          },
          {
            "category": "31-12-2015",
            "value": 165.286,
            "value2": 102.643
          },
          {
            "category": "01-01-2016",
            "value": 166.907,
            "value2": 103.089
          },
          {
            "category": "02-01-2016",
            "value": 166.907,
            "value2": 103.089
          },
          {
            "category": "03-01-2016",
            "value": 166.907,
            "value2": 103.089
          },
          {
            "category": "04-01-2016",
            "value": 163.706,
            "value2": 101.266
          },
          {
            "category": "05-01-2016",
            "value": 163.015,
            "value2": 101.409
          },
          {
            "category": "06-01-2016",
            "value": 162.928,
            "value2": 100.883
          },
          {
            "category": "07-01-2016",
            "value": 159.617,
            "value2": 98.4098
          },
          {
            "category": "08-01-2016",
            "value": 160.532,
            "value2": 99.0978
          },
          {
            "category": "09-01-2016",
            "value": 160.532,
            "value2": 99.0978
          },
          {
            "category": "10-01-2016",
            "value": 160.532,
            "value2": 99.0978
          },
          {
            "category": "11-01-2016",
            "value": 159.082,
            "value2": 98.5507
          },
          {
            "category": "12-01-2016",
            "value": 158.52,
            "value2": 97.8098
          },
          {
            "category": "13-01-2016",
            "value": 158.309,
            "value2": 97.8925
          },
          {
            "category": "14-01-2016",
            "value": 157.561,
            "value2": 97.3199
          },
          {
            "category": "15-01-2016",
            "value": 155.126,
            "value2": 95.5375
          },
          {
            "category": "16-01-2016",
            "value": 155.126,
            "value2": 95.5375
          },
          {
            "category": "17-01-2016",
            "value": 155.126,
            "value2": 95.5375
          },
          {
            "category": "18-01-2016",
            "value": 152.028,
            "value2": 93.8003
          },
          {
            "category": "19-01-2016",
            "value": 154.559,
            "value2": 94.9686
          },
          {
            "category": "20-01-2016",
            "value": 153,
            "value2": 93.2806
          },
          {
            "category": "21-01-2016",
            "value": 151.911,
            "value2": 93.0085
          },
          {
            "category": "22-01-2016",
            "value": 153.305,
            "value2": 94.8552
          },
          {
            "category": "23-01-2016",
            "value": 153.305,
            "value2": 94.8552
          },
          {
            "category": "24-01-2016",
            "value": 153.305,
            "value2": 94.8552
          },
          {
            "category": "25-01-2016",
            "value": 153.003,
            "value2": 95.1389
          },
          {
            "category": "26-01-2016",
            "value": 153.003,
            "value2": 95.1389
          },
          {
            "category": "27-01-2016",
            "value": 151.737,
            "value2": 95.2709
          },
          {
            "category": "28-01-2016",
            "value": 151.34,
            "value2": 95.1504
          },
          {
            "category": "29-01-2016",
            "value": 153.569,
            "value2": 96.6858
          },
          {
            "category": "30-01-2016",
            "value": 154.389,
            "value2": 96.6858
          },
          {
            "category": "31-01-2016",
            "value": 154.389,
            "value2": 96.6858
          },
          {
            "category": "01-02-2016",
            "value": 154.392,
            "value2": 96.7569
          },
          {
            "category": "02-02-2016",
            "value": 152.268,
            "value2": 95.4619
          },
          {
            "category": "03-02-2016",
            "value": 150.477,
            "value2": 94.0774
          },
          {
            "category": "04-02-2016",
            "value": 151.069,
            "value2": 94.3638
          },
          {
            "category": "05-02-2016",
            "value": 153.634,
            "value2": 95.661
          },
          {
            "category": "06-02-2016",
            "value": 153.634,
            "value2": 95.661
          },
          {
            "category": "07-02-2016",
            "value": 153.634,
            "value2": 95.661
          },
          {
            "category": "08-02-2016",
            "value": 152.288,
            "value2": 94.7552
          },
          {
            "category": "09-02-2016",
            "value": 151.127,
            "value2": 93.4859
          },
          {
            "category": "10-02-2016",
            "value": 149.635,
            "value2": 92.3808
          },
          {
            "category": "11-02-2016",
            "value": 145.097,
            "value2": 89.1519
          },
          {
            "category": "12-02-2016",
            "value": 145.25,
            "value2": 89.0196
          },
          {
            "category": "13-02-2016",
            "value": 145.25,
            "value2": 89.0196
          },
          {
            "category": "14-02-2016",
            "value": 145.25,
            "value2": 89.0196
          },
          {
            "category": "15-02-2016",
            "value": 148.97,
            "value2": 91.5121
          },
          {
            "category": "16-02-2016",
            "value": 146.528,
            "value2": 89.8666
          },
          {
            "category": "17-02-2016",
            "value": 148.236,
            "value2": 90.4932
          },
          {
            "category": "18-02-2016",
            "value": 149.208,
            "value2": 91.3924
          },
          {
            "category": "19-02-2016",
            "value": 149.884,
            "value2": 91.5748
          },
          {
            "category": "20-02-2016",
            "value": 149.884,
            "value2": 91.5748
          },
          {
            "category": "21-02-2016",
            "value": 149.884,
            "value2": 91.5748
          },
          {
            "category": "22-02-2016",
            "value": 151.252,
            "value2": 91.9771
          },
          {
            "category": "23-02-2016",
            "value": 149.261,
            "value2": 90.5653
          },
          {
            "category": "24-02-2016",
            "value": 148.143,
            "value2": 89.4921
          },
          {
            "category": "25-02-2016",
            "value": 147.326,
            "value2": 88.8147
          },
          {
            "category": "26-02-2016",
            "value": 148.69,
            "value2": 89.3134
          },
          {
            "category": "27-02-2016",
            "value": 148.69,
            "value2": 89.3134
          },
          {
            "category": "28-02-2016",
            "value": 148.69,
            "value2": 89.3134
          },
          {
            "category": "29-02-2016",
            "value": 147.018,
            "value2": 88.9892
          },
          {
            "category": "01-03-2016",
            "value": 150.206,
            "value2": 91.8301
          },
          {
            "category": "02-03-2016",
            "value": 152.169,
            "value2": 93.6288
          },
          {
            "category": "03-03-2016",
            "value": 154.165,
            "value2": 94.8449
          },
          {
            "category": "04-03-2016",
            "value": 154.252,
            "value2": 95.2262
          },
          {
            "category": "05-03-2016",
            "value": 154.252,
            "value2": 95.2262
          },
          {
            "category": "06-03-2016",
            "value": 154.252,
            "value2": 95.2262
          },
          {
            "category": "07-03-2016",
            "value": 154.252,
            "value2": 95.2262
          },
          {
            "category": "08-03-2016",
            "value": 154.359,
            "value2": 95.2763
          },
          {
            "category": "09-03-2016",
            "value": 154.639,
            "value2": 95.7661
          },
          {
            "category": "10-03-2016",
            "value": 154.178,
            "value2": 95.2586
          },
          {
            "category": "11-03-2016",
            "value": 154.943,
            "value2": 95.4696
          },
          {
            "category": "12-03-2016",
            "value": 154.943,
            "value2": 95.4696
          },
          {
            "category": "13-03-2016",
            "value": 154.943,
            "value2": 95.4696
          },
          {
            "category": "14-03-2016",
            "value": 154.505,
            "value2": 95.8916
          },
          {
            "category": "15-03-2016",
            "value": 153.561,
            "value2": 95.0721
          },
          {
            "category": "16-03-2016",
            "value": 152.957,
            "value2": 95.3024
          },
          {
            "category": "17-03-2016",
            "value": 153.175,
            "value2": 95.5775
          },
          {
            "category": "18-03-2016",
            "value": 154.995,
            "value2": 96.6088
          },
          {
            "category": "19-03-2016",
            "value": 154.995,
            "value2": 96.6088
          },
          {
            "category": "20-03-2016",
            "value": 154.995,
            "value2": 96.6088
          },
          {
            "category": "21-03-2016",
            "value": 157.39,
            "value2": 97.9305
          },
          {
            "category": "22-03-2016",
            "value": 158.648,
            "value2": 98.1721
          },
          {
            "category": "23-03-2016",
            "value": 159.396,
            "value2": 98.2407
          },
          {
            "category": "24-03-2016",
            "value": 159.396,
            "value2": 98.2407
          },
          {
            "category": "25-03-2016",
            "value": 159.396,
            "value2": 98.2407
          },
          {
            "category": "26-03-2016",
            "value": 159.396,
            "value2": 98.2407
          },
          {
            "category": "27-03-2016",
            "value": 159.396,
            "value2": 98.2407
          },
          {
            "category": "28-03-2016",
            "value": 157.658,
            "value2": 96.8933
          },
          {
            "category": "29-03-2016",
            "value": 157.865,
            "value2": 96.7094
          },
          {
            "category": "30-03-2016",
            "value": 160.121,
            "value2": 98.4512
          },
          {
            "category": "31-03-2016",
            "value": 160.49,
            "value2": 98.7138
          },
          {
            "category": "01-04-2016",
            "value": 158.941,
            "value2": 98.6607
          },
          {
            "category": "02-04-2016",
            "value": 158.941,
            "value2": 98.6607
          },
          {
            "category": "03-04-2016",
            "value": 158.941,
            "value2": 98.6607
          },
          {
            "category": "04-04-2016",
            "value": 160.244,
            "value2": 99.1742
          },
          {
            "category": "05-04-2016",
            "value": 157.378,
            "value2": 97.3555
          },
          {
            "category": "06-04-2016",
            "value": 157.399,
            "value2": 97.6543
          },
          {
            "category": "07-04-2016",
            "value": 155.853,
            "value2": 96.9466
          },
          {
            "category": "08-04-2016",
            "value": 155.831,
            "value2": 97.197
          },
          {
            "category": "09-04-2016",
            "value": 155.831,
            "value2": 97.197
          },
          {
            "category": "10-04-2016",
            "value": 155.831,
            "value2": 97.197
          },
          {
            "category": "11-04-2016",
            "value": 158.339,
            "value2": 98.428
          },
          {
            "category": "12-04-2016",
            "value": 159.718,
            "value2": 99.0541
          },
          {
            "category": "13-04-2016",
            "value": 162.188,
            "value2": 100.632
          },
          {
            "category": "14-04-2016",
            "value": 162.188,
            "value2": 100.632
          },
          {
            "category": "15-04-2016",
            "value": 162.188,
            "value2": 100.632
          },
          {
            "category": "16-04-2016",
            "value": 162.188,
            "value2": 100.632
          },
          {
            "category": "17-04-2016",
            "value": 162.188,
            "value2": 100.632
          },
          {
            "category": "18-04-2016",
            "value": 163.303,
            "value2": 101.516
          },
          {
            "category": "19-04-2016",
            "value": 163.303,
            "value2": 101.516
          },
          {
            "category": "20-04-2016",
            "value": 162.697,
            "value2": 101.692
          },
          {
            "category": "21-04-2016",
            "value": 162.42,
            "value2": 101.537
          },
          {
            "category": "22-04-2016",
            "value": 161.811,
            "value2": 101.466
          },
          {
            "category": "23-04-2016",
            "value": 161.811,
            "value2": 101.466
          },
          {
            "category": "24-04-2016",
            "value": 161.811,
            "value2": 101.466
          },
          {
            "category": "25-04-2016",
            "value": 161.232,
            "value2": 100.938
          },
          {
            "category": "26-04-2016",
            "value": 163.191,
            "value2": 102.118
          },
          {
            "category": "27-04-2016",
            "value": 163.471,
            "value2": 102.33
          },
          {
            "category": "28-04-2016",
            "value": 161.857,
            "value2": 100.84
          },
          {
            "category": "29-04-2016",
            "value": 162.264,
            "value2": 100.861
          },
          {
            "category": "30-04-2016",
            "value": 162.263,
            "value2": 100.861
          },
          {
            "category": "01-05-2016",
            "value": 162.263,
            "value2": 100.861
          },
          {
            "category": "02-05-2016",
            "value": 160.957,
            "value2": 100.731
          },
          {
            "category": "03-05-2016",
            "value": 160.561,
            "value2": 100.046
          },
          {
            "category": "04-05-2016",
            "value": 160.5,
            "value2": 99.2687
          },
          {
            "category": "05-05-2016",
            "value": 161.152,
            "value2": 99.5658
          },
          {
            "category": "06-05-2016",
            "value": 161.55,
            "value2": 99.5694
          },
          {
            "category": "07-05-2016",
            "value": 161.55,
            "value2": 99.5694
          },
          {
            "category": "08-05-2016",
            "value": 161.55,
            "value2": 99.5694
          },
          {
            "category": "09-05-2016",
            "value": 164.092,
            "value2": 101.089
          },
          {
            "category": "10-05-2016",
            "value": 163.999,
            "value2": 101.297
          },
          {
            "category": "11-05-2016",
            "value": 163.651,
            "value2": 100.905
          },
          {
            "category": "12-05-2016",
            "value": 164.61,
            "value2": 101.684
          },
          {
            "category": "13-05-2016",
            "value": 162.39,
            "value2": 100.808
          },
          {
            "category": "14-05-2016",
            "value": 162.39,
            "value2": 100.808
          },
          {
            "category": "15-05-2016",
            "value": 162.39,
            "value2": 100.808
          },
          {
            "category": "16-05-2016",
            "value": 163.11,
            "value2": 101.264
          },
          {
            "category": "17-05-2016",
            "value": 163.749,
            "value2": 101.571
          },
          {
            "category": "18-05-2016",
            "value": 162.296,
            "value2": 101.424
          },
          {
            "category": "19-05-2016",
            "value": 160.446,
            "value2": 100.258
          },
          {
            "category": "20-05-2016",
            "value": 159.566,
            "value2": 99.7726
          },
          {
            "category": "21-05-2016",
            "value": 159.566,
            "value2": 99.7726
          },
          {
            "category": "22-05-2016",
            "value": 159.566,
            "value2": 99.7726
          },
          {
            "category": "23-05-2016",
            "value": 159.013,
            "value2": 99.5319
          },
          {
            "category": "24-05-2016",
            "value": 158.629,
            "value2": 99.5589
          },
          {
            "category": "25-05-2016",
            "value": 162.218,
            "value2": 101.495
          },
          {
            "category": "26-05-2016",
            "value": 164.363,
            "value2": 102.974
          },
          {
            "category": "27-05-2016",
            "value": 168.339,
            "value2": 104.108
          },
          {
            "category": "28-05-2016",
            "value": 168.339,
            "value2": 104.108
          },
          {
            "category": "29-05-2016",
            "value": 168.339,
            "value2": 104.108
          },
          {
            "category": "30-05-2016",
            "value": 168.757,
            "value2": 104.57
          },
          {
            "category": "31-05-2016",
            "value": 167.422,
            "value2": 104.416
          },
          {
            "category": "01-06-2016",
            "value": 167.17,
            "value2": 104.512
          },
          {
            "category": "02-06-2016",
            "value": 167.71,
            "value2": 105.008
          },
          {
            "category": "03-06-2016",
            "value": 167.513,
            "value2": 104.98
          },
          {
            "category": "04-06-2016",
            "value": 167.513,
            "value2": 104.98
          },
          {
            "category": "05-06-2016",
            "value": 167.513,
            "value2": 104.98
          },
          {
            "category": "06-06-2016",
            "value": 167.435,
            "value2": 104.862
          },
          {
            "category": "07-06-2016",
            "value": 168.283,
            "value2": 105.618
          },
          {
            "category": "08-06-2016",
            "value": 167.982,
            "value2": 105.913
          },
          {
            "category": "09-06-2016",
            "value": 166.876,
            "value2": 105.344
          },
          {
            "category": "10-06-2016",
            "value": 165.791,
            "value2": 104.942
          },
          {
            "category": "11-06-2016",
            "value": 165.791,
            "value2": 104.942
          },
          {
            "category": "12-06-2016",
            "value": 165.791,
            "value2": 104.942
          },
          {
            "category": "13-06-2016",
            "value": 165.287,
            "value2": 104.233
          },
          {
            "category": "14-06-2016",
            "value": 165.535,
            "value2": 104.334
          },
          {
            "category": "15-06-2016",
            "value": 167.425,
            "value2": 105.387
          },
          {
            "category": "16-06-2016",
            "value": 167.247,
            "value2": 104.775
          },
          {
            "category": "17-06-2016",
            "value": 167.219,
            "value2": 105.065
          },
          {
            "category": "18-06-2016",
            "value": 167.219,
            "value2": 105.065
          },
          {
            "category": "19-06-2016",
            "value": 167.219,
            "value2": 105.065
          },
          {
            "category": "20-06-2016",
            "value": 168.312,
            "value2": 105.877
          },
          {
            "category": "21-06-2016",
            "value": 167.58,
            "value2": 105.783
          },
          {
            "category": "22-06-2016",
            "value": 167.274,
            "value2": 105.534
          },
          {
            "category": "23-06-2016",
            "value": 168.115,
            "value2": 106.157
          },
          {
            "category": "24-06-2016",
            "value": 165.765,
            "value2": 104.129
          },
          {
            "category": "25-06-2016",
            "value": 165.765,
            "value2": 104.129
          },
          {
            "category": "26-06-2016",
            "value": 165.765,
            "value2": 104.129
          },
          {
            "category": "27-06-2016",
            "value": 166.399,
            "value2": 104.628
          },
          {
            "category": "28-06-2016",
            "value": 167.371,
            "value2": 105.154
          },
          {
            "category": "29-06-2016",
            "value": 169.743,
            "value2": 106.191
          },
          {
            "category": "30-06-2016",
            "value": 170.834,
            "value2": 107.313
          },
          {
            "category": "01-07-2016",
            "value": 170.797,
            "value2": 108.061
          },
          {
            "category": "02-07-2016",
            "value": 170.797,
            "value2": 108.061
          },
          {
            "category": "03-07-2016",
            "value": 170.797,
            "value2": 108.061
          },
          {
            "category": "04-07-2016",
            "value": 171.689,
            "value2": 108.711
          },
          {
            "category": "05-07-2016",
            "value": 171.238,
            "value2": 108.396
          },
          {
            "category": "06-07-2016",
            "value": 171.238,
            "value2": 108.396
          },
          {
            "category": "07-07-2016",
            "value": 171.379,
            "value2": 108.417
          },
          {
            "category": "08-07-2016",
            "value": 171.342,
            "value2": 108.247
          },
          {
            "category": "09-07-2016",
            "value": 171.342,
            "value2": 108.247
          },
          {
            "category": "10-07-2016",
            "value": 171.342,
            "value2": 108.247
          },
          {
            "category": "11-07-2016",
            "value": 173.989,
            "value2": 109.959
          },
          {
            "category": "12-07-2016",
            "value": 174.141,
            "value2": 110.531
          },
          {
            "category": "13-07-2016",
            "value": 173.096,
            "value2": 110.29
          },
          {
            "category": "14-07-2016",
            "value": 173.667,
            "value2": 110.942
          },
          {
            "category": "15-07-2016",
            "value": 174.259,
            "value2": 110.65
          },
          {
            "category": "16-07-2016",
            "value": 174.259,
            "value2": 110.65
          },
          {
            "category": "17-07-2016",
            "value": 174.259,
            "value2": 110.65
          },
          {
            "category": "18-07-2016",
            "value": 174.469,
            "value2": 110.225
          },
          {
            "category": "19-07-2016",
            "value": 174.887,
            "value2": 110.395
          },
          {
            "category": "20-07-2016",
            "value": 176.172,
            "value2": 111.149
          },
          {
            "category": "21-07-2016",
            "value": 175.097,
            "value2": 110.606
          },
          {
            "category": "22-07-2016",
            "value": 175.527,
            "value2": 111.261
          },
          {
            "category": "23-07-2016",
            "value": 175.527,
            "value2": 111.261
          },
          {
            "category": "24-07-2016",
            "value": 175.527,
            "value2": 111.261
          },
          {
            "category": "25-07-2016",
            "value": 177.996,
            "value2": 112.428
          },
          {
            "category": "26-07-2016",
            "value": 176.654,
            "value2": 111.957
          },
          {
            "category": "27-07-2016",
            "value": 177.791,
            "value2": 112.373
          },
          {
            "category": "28-07-2016",
            "value": 180.307,
            "value2": 113.045
          },
          {
            "category": "29-07-2016",
            "value": 181.984,
            "value2": 112.933
          },
          {
            "category": "30-07-2016",
            "value": 181.984,
            "value2": 112.933
          },
          {
            "category": "31-07-2016",
            "value": 181.983,
            "value2": 112.933
          },
          {
            "category": "01-08-2016",
            "value": 181.01,
            "value2": 112.988
          },
          {
            "category": "02-08-2016",
            "value": 178.732,
            "value2": 112.614
          },
          {
            "category": "03-08-2016",
            "value": 177.771,
            "value2": 111.442
          },
          {
            "category": "04-08-2016",
            "value": 177.163,
            "value2": 111.658
          },
          {
            "category": "05-08-2016",
            "value": 180.907,
            "value2": 113.368
          },
          {
            "category": "06-08-2016",
            "value": 180.907,
            "value2": 113.368
          },
          {
            "category": "07-08-2016",
            "value": 180.907,
            "value2": 113.368
          },
          {
            "category": "08-08-2016",
            "value": 182.09,
            "value2": 113.983
          },
          {
            "category": "09-08-2016",
            "value": 180.728,
            "value2": 113.51
          },
          {
            "category": "10-08-2016",
            "value": 178.397,
            "value2": 112.114
          },
          {
            "category": "11-08-2016",
            "value": 179.141,
            "value2": 112.279
          },
          {
            "category": "12-08-2016",
            "value": 179.725,
            "value2": 113.111
          },
          {
            "category": "13-08-2016",
            "value": 179.725,
            "value2": 113.111
          },
          {
            "category": "14-08-2016",
            "value": 179.725,
            "value2": 113.111
          },
          {
            "category": "15-08-2016",
            "value": 179.725,
            "value2": 113.111
          },
          {
            "category": "16-08-2016",
            "value": 179.637,
            "value2": 113.039
          },
          {
            "category": "17-08-2016",
            "value": 179.347,
            "value2": 113.096
          },
          {
            "category": "18-08-2016",
            "value": 180.186,
            "value2": 113.849
          },
          {
            "category": "19-08-2016",
            "value": 180.069,
            "value2": 113.958
          },
          {
            "category": "20-08-2016",
            "value": 180.069,
            "value2": 113.958
          },
          {
            "category": "21-08-2016",
            "value": 180.069,
            "value2": 113.958
          },
          {
            "category": "22-08-2016",
            "value": 179.342,
            "value2": 113.5
          },
          {
            "category": "23-08-2016",
            "value": 179.39,
            "value2": 113.419
          },
          {
            "category": "24-08-2016",
            "value": 179.583,
            "value2": 113.869
          },
          {
            "category": "25-08-2016",
            "value": 179.175,
            "value2": 113.257
          },
          {
            "category": "26-08-2016",
            "value": 178.838,
            "value2": 113.097
          },
          {
            "category": "27-08-2016",
            "value": 178.838,
            "value2": 113.097
          },
          {
            "category": "28-08-2016",
            "value": 178.838,
            "value2": 113.097
          },
          {
            "category": "29-08-2016",
            "value": 179.269,
            "value2": 113.434
          },
          {
            "category": "30-08-2016",
            "value": 182.978,
            "value2": 115.025
          },
          {
            "category": "31-08-2016",
            "value": 183.182,
            "value2": 115.5
          },
          {
            "category": "01-09-2016",
            "value": 182.375,
            "value2": 115.211
          },
          {
            "category": "02-09-2016",
            "value": 183.199,
            "value2": 115.671
          },
          {
            "category": "03-09-2016",
            "value": 183.199,
            "value2": 115.671
          },
          {
            "category": "04-09-2016",
            "value": 183.199,
            "value2": 115.671
          },
          {
            "category": "05-09-2016",
            "value": 183.199,
            "value2": 115.671
          },
          {
            "category": "06-09-2016",
            "value": 185.119,
            "value2": 117.392
          },
          {
            "category": "07-09-2016",
            "value": 184.053,
            "value2": 117.343
          },
          {
            "category": "08-09-2016",
            "value": 185.322,
            "value2": 117.891
          },
          {
            "category": "09-09-2016",
            "value": 183.456,
            "value2": 116.898
          },
          {
            "category": "10-09-2016",
            "value": 183.456,
            "value2": 116.898
          },
          {
            "category": "11-09-2016",
            "value": 183.456,
            "value2": 116.898
          },
          {
            "category": "12-09-2016",
            "value": 180.331,
            "value2": 114.53
          },
          {
            "category": "13-09-2016",
            "value": 180.331,
            "value2": 114.53
          },
          {
            "category": "14-09-2016",
            "value": 180.362,
            "value2": 115.112
          },
          {
            "category": "15-09-2016",
            "value": 180.649,
            "value2": 115.156
          },
          {
            "category": "16-09-2016",
            "value": 181.71,
            "value2": 115.642
          },
          {
            "category": "17-09-2016",
            "value": 181.71,
            "value2": 115.642
          },
          {
            "category": "18-09-2016",
            "value": 181.71,
            "value2": 115.642
          },
          {
            "category": "19-09-2016",
            "value": 182.154,
            "value2": 115.969
          },
          {
            "category": "20-09-2016",
            "value": 181.465,
            "value2": 115.632
          },
          {
            "category": "21-09-2016",
            "value": 182.265,
            "value2": 115.642
          },
          {
            "category": "22-09-2016",
            "value": 184.692,
            "value2": 116.916
          },
          {
            "category": "23-09-2016",
            "value": 185.144,
            "value2": 116.693
          },
          {
            "category": "24-09-2016",
            "value": 185.144,
            "value2": 116.693
          },
          {
            "category": "25-09-2016",
            "value": 185.144,
            "value2": 116.693
          },
          {
            "category": "26-09-2016",
            "value": 184.008,
            "value2": 115.574
          },
          {
            "category": "27-09-2016",
            "value": 183.566,
            "value2": 115.417
          },
          {
            "category": "28-09-2016",
            "value": 184.848,
            "value2": 116.095
          },
          {
            "category": "29-09-2016",
            "value": 181.127,
            "value2": 113.329
          },
          {
            "category": "30-09-2016",
            "value": 182.225,
            "value2": 114.281
          },
          {
            "category": "01-10-2016",
            "value": 182.225,
            "value2": 114.281
          },
          {
            "category": "02-10-2016",
            "value": 182.225,
            "value2": 114.281
          },
          {
            "category": "03-10-2016",
            "value": 184.976,
            "value2": 116.38
          },
          {
            "category": "04-10-2016",
            "value": 186.068,
            "value2": 116.893
          },
          {
            "category": "05-10-2016",
            "value": 186.382,
            "value2": 116.869
          },
          {
            "category": "06-10-2016",
            "value": 186.54,
            "value2": 116.454
          },
          {
            "category": "07-10-2016",
            "value": 185.796,
            "value2": 116.305
          },
          {
            "category": "08-10-2016",
            "value": 185.796,
            "value2": 116.305
          },
          {
            "category": "09-10-2016",
            "value": 185.796,
            "value2": 116.305
          },
          {
            "category": "10-10-2016",
            "value": 185.59,
            "value2": 116.381
          },
          {
            "category": "11-10-2016",
            "value": 185.59,
            "value2": 116.381
          },
          {
            "category": "12-10-2016",
            "value": 185.59,
            "value2": 116.381
          },
          {
            "category": "13-10-2016",
            "value": 183.494,
            "value2": 114.617
          },
          {
            "category": "14-10-2016",
            "value": 183.75,
            "value2": 115.004
          },
          {
            "category": "15-10-2016",
            "value": 183.75,
            "value2": 115.004
          },
          {
            "category": "16-10-2016",
            "value": 183.75,
            "value2": 115.004
          },
          {
            "category": "17-10-2016",
            "value": 180.865,
            "value2": 114.12
          },
          {
            "category": "18-10-2016",
            "value": 183.916,
            "value2": 116.074
          },
          {
            "category": "19-10-2016",
            "value": 183.578,
            "value2": 116.033
          },
          {
            "category": "20-10-2016",
            "value": 183.609,
            "value2": 116.5
          },
          {
            "category": "21-10-2016",
            "value": 184.056,
            "value2": 116.576
          },
          {
            "category": "22-10-2016",
            "value": 184.056,
            "value2": 116.576
          },
          {
            "category": "23-10-2016",
            "value": 184.054,
            "value2": 116.576
          },
          {
            "category": "24-10-2016",
            "value": 184.216,
            "value2": 116.81
          },
          {
            "category": "25-10-2016",
            "value": 183.461,
            "value2": 116.601
          },
          {
            "category": "26-10-2016",
            "value": 182.682,
            "value2": 115.644
          },
          {
            "category": "27-10-2016",
            "value": 181.823,
            "value2": 115.285
          },
          {
            "category": "28-10-2016",
            "value": 181.446,
            "value2": 115.848
          },
          {
            "category": "29-10-2016",
            "value": 181.445,
            "value2": 115.848
          },
          {
            "category": "30-10-2016",
            "value": 181.508,
            "value2": 116.097
          },
          {
            "category": "31-10-2016",
            "value": 181.507,
            "value2": 116.097
          },
          {
            "category": "01-11-2016",
            "value": 181.834,
            "value2": 115.956
          },
          {
            "category": "02-11-2016",
            "value": 178.7,
            "value2": 114.288
          },
          {
            "category": "03-11-2016",
            "value": 177.276,
            "value2": 113.589
          },
          {
            "category": "04-11-2016",
            "value": 174.46,
            "value2": 112.489
          },
          {
            "category": "05-11-2016",
            "value": 174.456,
            "value2": 112.489
          },
          {
            "category": "06-11-2016",
            "value": 174.456,
            "value2": 112.489
          },
          {
            "category": "07-11-2016",
            "value": 175.359,
            "value2": 113.38
          },
          {
            "category": "08-11-2016",
            "value": 175.868,
            "value2": 113.873
          },
          {
            "category": "09-11-2016",
            "value": 175.023,
            "value2": 112.08
          },
          {
            "category": "10-11-2016",
            "value": 175.053,
            "value2": 113.558
          },
          {
            "category": "11-11-2016",
            "value": 170.665,
            "value2": 110.206
          },
          {
            "category": "12-11-2016",
            "value": 170.665,
            "value2": 110.206
          },
          {
            "category": "13-11-2016",
            "value": 170.665,
            "value2": 110.206
          },
          {
            "category": "14-11-2016",
            "value": 170.665,
            "value2": 110.206
          },
          {
            "category": "15-11-2016",
            "value": 166.826,
            "value2": 106.92
          },
          {
            "category": "16-11-2016",
            "value": 166.868,
            "value2": 107.042
          },
          {
            "category": "17-11-2016",
            "value": 166.373,
            "value2": 106.761
          },
          {
            "category": "18-11-2016",
            "value": 167.163,
            "value2": 106.794
          },
          {
            "category": "19-11-2016",
            "value": 167.163,
            "value2": 106.794
          },
          {
            "category": "20-11-2016",
            "value": 167.163,
            "value2": 106.794
          },
          {
            "category": "21-11-2016",
            "value": 163.063,
            "value2": 104.546
          },
          {
            "category": "22-11-2016",
            "value": 164.673,
            "value2": 105.662
          },
          {
            "category": "23-11-2016",
            "value": 165.986,
            "value2": 106.385
          },
          {
            "category": "24-11-2016",
            "value": 164.739,
            "value2": 105.82
          },
          {
            "category": "25-11-2016",
            "value": 166.748,
            "value2": 107.62
          },
          {
            "category": "26-11-2016",
            "value": 166.748,
            "value2": 107.62
          },
          {
            "category": "27-11-2016",
            "value": 166.748,
            "value2": 107.62
          },
          {
            "category": "28-11-2016",
            "value": 167.364,
            "value2": 107.981
          },
          {
            "category": "29-11-2016",
            "value": 168.149,
            "value2": 108.315
          },
          {
            "category": "30-11-2016",
            "value": 169.738,
            "value2": 109.478
          },
          {
            "category": "01-12-2016",
            "value": 168.552,
            "value2": 108.848
          },
          {
            "category": "02-12-2016",
            "value": 166.552,
            "value2": 107.465
          },
          {
            "category": "03-12-2016",
            "value": 166.552,
            "value2": 107.465
          },
          {
            "category": "04-12-2016",
            "value": 166.552,
            "value2": 107.465
          },
          {
            "category": "05-12-2016",
            "value": 167.389,
            "value2": 108.037
          },
          {
            "category": "06-12-2016",
            "value": 167.414,
            "value2": 108.3
          },
          {
            "category": "07-12-2016",
            "value": 167.479,
            "value2": 107.834
          },
          {
            "category": "08-12-2016",
            "value": 170.174,
            "value2": 109.577
          },
          {
            "category": "09-12-2016",
            "value": 169.409,
            "value2": 109.799
          },
          {
            "category": "10-12-2016",
            "value": 169.409,
            "value2": 109.799
          },
          {
            "category": "11-12-2016",
            "value": 169.409,
            "value2": 109.799
          },
          {
            "category": "12-12-2016",
            "value": 167.246,
            "value2": 108.638
          },
          {
            "category": "13-12-2016",
            "value": 168.529,
            "value2": 108.954
          },
          {
            "category": "14-12-2016",
            "value": 166.757,
            "value2": 108.358
          },
          {
            "category": "15-12-2016",
            "value": 165.812,
            "value2": 108.123
          },
          {
            "category": "16-12-2016",
            "value": 165.859,
            "value2": 107.886
          },
          {
            "category": "17-12-2016",
            "value": 165.859,
            "value2": 107.886
          },
          {
            "category": "18-12-2016",
            "value": 165.859,
            "value2": 107.886
          },
          {
            "category": "19-12-2016",
            "value": 164.497,
            "value2": 107.406
          },
          {
            "category": "20-12-2016",
            "value": 163.102,
            "value2": 106.817
          },
          {
            "category": "21-12-2016",
            "value": 162.506,
            "value2": 106.671
          },
          {
            "category": "22-12-2016",
            "value": 161.229,
            "value2": 105.446
          },
          {
            "category": "23-12-2016",
            "value": 161.87,
            "value2": 105.435
          },
          {
            "category": "24-12-2016",
            "value": 161.87,
            "value2": 105.435
          },
          {
            "category": "25-12-2016",
            "value": 161.87,
            "value2": 105.435
          },
          {
            "category": "26-12-2016",
            "value": 159.846,
            "value2": 103.993
          },
          {
            "category": "27-12-2016",
            "value": 162.329,
            "value2": 105.634
          },
          {
            "category": "28-12-2016",
            "value": 162.446,
            "value2": 105.908
          },
          {
            "category": "29-12-2016",
            "value": 164.37,
            "value2": 106.903
          },
          {
            "category": "30-12-2016",
            "value": 165.738,
            "value2": 107.929
          },
          {
            "category": "31-12-2016",
            "value": 165.738,
            "value2": 107.929
          },
          {
            "category": "01-01-2017",
            "value": 165.739,
            "value2": 107.929
          },
          {
            "category": "02-01-2017",
            "value": 165.529,
            "value2": 108.283
          },
          {
            "category": "03-01-2017",
            "value": 165.24,
            "value2": 108.742
          },
          {
            "category": "04-01-2017",
            "value": 165.038,
            "value2": 108.737
          },
          {
            "category": "05-01-2017",
            "value": 166.57,
            "value2": 109.884
          },
          {
            "category": "06-01-2017",
            "value": 166.816,
            "value2": 109.524
          },
          {
            "category": "07-01-2017",
            "value": 166.816,
            "value2": 109.524
          },
          {
            "category": "08-01-2017",
            "value": 166.816,
            "value2": 109.524
          },
          {
            "category": "09-01-2017",
            "value": 166.03,
            "value2": 109.56
          },
          {
            "category": "10-01-2017",
            "value": 167.456,
            "value2": 110.26
          },
          {
            "category": "11-01-2017",
            "value": 169.214,
            "value2": 111.509
          },
          {
            "category": "12-01-2017",
            "value": 169.309,
            "value2": 111.77
          },
          {
            "category": "13-01-2017",
            "value": 168.838,
            "value2": 111.714
          },
          {
            "category": "14-01-2017",
            "value": 168.825,
            "value2": 111.714
          },
          {
            "category": "15-01-2017",
            "value": 168.823,
            "value2": 111.714
          },
          {
            "category": "16-01-2017",
            "value": 169.59,
            "value2": 112.039
          },
          {
            "category": "17-01-2017",
            "value": 170.402,
            "value2": 111.959
          },
          {
            "category": "18-01-2017",
            "value": 170.429,
            "value2": 112.372
          },
          {
            "category": "19-01-2017",
            "value": 170.953,
            "value2": 112.656
          },
          {
            "category": "20-01-2017",
            "value": 170.092,
            "value2": 111.382
          },
          {
            "category": "21-01-2017",
            "value": 170.092,
            "value2": 111.382
          },
          {
            "category": "22-01-2017",
            "value": 170.092,
            "value2": 111.382
          },
          {
            "category": "23-01-2017",
            "value": 171.574,
            "value2": 111.984
          },
          {
            "category": "24-01-2017",
            "value": 173.477,
            "value2": 113.114
          },
          {
            "category": "25-01-2017",
            "value": 177.379,
            "value2": 114.597
          },
          {
            "category": "26-01-2017",
            "value": 177.379,
            "value2": 114.597
          },
          {
            "category": "27-01-2017",
            "value": 177.708,
            "value2": 115.251
          },
          {
            "category": "28-01-2017",
            "value": 177.706,
            "value2": 115.251
          },
          {
            "category": "29-01-2017",
            "value": 177.706,
            "value2": 115.251
          },
          {
            "category": "30-01-2017",
            "value": 177.121,
            "value2": 115.16
          },
          {
            "category": "31-01-2017",
            "value": 174.568,
            "value2": 114.049
          },
          {
            "category": "01-02-2017",
            "value": 177.711,
            "value2": 116.148
          },
          {
            "category": "02-02-2017",
            "value": 177.669,
            "value2": 116.662
          },
          {
            "category": "03-02-2017",
            "value": 177.115,
            "value2": 117.003
          },
          {
            "category": "04-02-2017",
            "value": 177.113,
            "value2": 117.003
          },
          {
            "category": "05-02-2017",
            "value": 177.113,
            "value2": 117.003
          },
          {
            "category": "06-02-2017",
            "value": 178.904,
            "value2": 117.903
          },
          {
            "category": "07-02-2017",
            "value": 179.165,
            "value2": 117.558
          },
          {
            "category": "08-02-2017",
            "value": 179.258,
            "value2": 117.73
          },
          {
            "category": "09-02-2017",
            "value": 179.779,
            "value2": 117.942
          },
          {
            "category": "10-02-2017",
            "value": 180.085,
            "value2": 117.989
          },
          {
            "category": "11-02-2017",
            "value": 180.085,
            "value2": 117.989
          },
          {
            "category": "12-02-2017",
            "value": 180.085,
            "value2": 117.989
          },
          {
            "category": "13-02-2017",
            "value": 180.456,
            "value2": 117.941
          },
          {
            "category": "14-02-2017",
            "value": 179.652,
            "value2": 117.645
          },
          {
            "category": "15-02-2017",
            "value": 177.54,
            "value2": 116.486
          },
          {
            "category": "16-02-2017",
            "value": 179.036,
            "value2": 117.556
          },
          {
            "category": "17-02-2017",
            "value": 180.025,
            "value2": 118.163
          },
          {
            "category": "18-02-2017",
            "value": 180.023,
            "value2": 118.163
          },
          {
            "category": "19-02-2017",
            "value": 180.023,
            "value2": 118.163
          },
          {
            "category": "20-02-2017",
            "value": 181.602,
            "value2": 118.991
          },
          {
            "category": "21-02-2017",
            "value": 182.514,
            "value2": 119.522
          },
          {
            "category": "22-02-2017",
            "value": 182.564,
            "value2": 119.473
          },
          {
            "category": "23-02-2017",
            "value": 182.569,
            "value2": 119.59
          },
          {
            "category": "24-02-2017",
            "value": 182.569,
            "value2": 119.59
          },
          {
            "category": "25-02-2017",
            "value": 182.569,
            "value2": 119.59
          },
          {
            "category": "26-02-2017",
            "value": 182.569,
            "value2": 119.59
          },
          {
            "category": "27-02-2017",
            "value": 181.333,
            "value2": 119.251
          },
          {
            "category": "28-02-2017",
            "value": 180.85,
            "value2": 119.271
          },
          {
            "category": "01-03-2017",
            "value": 181.248,
            "value2": 119.994
          },
          {
            "category": "02-03-2017",
            "value": 179.598,
            "value2": 118.968
          },
          {
            "category": "03-03-2017",
            "value": 178.515,
            "value2": 119.1
          },
          {
            "category": "04-03-2017",
            "value": 178.515,
            "value2": 119.1
          },
          {
            "category": "05-03-2017",
            "value": 178.515,
            "value2": 119.1
          },
          {
            "category": "06-03-2017",
            "value": 179.739,
            "value2": 119.843
          },
          {
            "category": "07-03-2017",
            "value": 179.602,
            "value2": 119.717
          },
          {
            "category": "08-03-2017",
            "value": 180.178,
            "value2": 119.322
          },
          {
            "category": "09-03-2017",
            "value": 180.326,
            "value2": 119.327
          },
          {
            "category": "10-03-2017",
            "value": 180.831,
            "value2": 119.307
          },
          {
            "category": "11-03-2017",
            "value": 180.831,
            "value2": 119.307
          },
          {
            "category": "12-03-2017",
            "value": 180.831,
            "value2": 119.307
          },
          {
            "category": "13-03-2017",
            "value": 180.831,
            "value2": 119.307
          },
          {
            "category": "14-03-2017",
            "value": 184.009,
            "value2": 121.286
          },
          {
            "category": "15-03-2017",
            "value": 184.361,
            "value2": 121.601
          },
          {
            "category": "16-03-2017",
            "value": 185.997,
            "value2": 122.73
          },
          {
            "category": "17-03-2017",
            "value": 185.571,
            "value2": 122.81
          },
          {
            "category": "18-03-2017",
            "value": 185.571,
            "value2": 122.81
          },
          {
            "category": "19-03-2017",
            "value": 185.571,
            "value2": 122.81
          },
          {
            "category": "20-03-2017",
            "value": 185.669,
            "value2": 122.642
          },
          {
            "category": "21-03-2017",
            "value": 185.403,
            "value2": 122.455
          },
          {
            "category": "22-03-2017",
            "value": 184.452,
            "value2": 121.261
          },
          {
            "category": "23-03-2017",
            "value": 185.781,
            "value2": 122.128
          },
          {
            "category": "24-03-2017",
            "value": 186.917,
            "value2": 122.449
          },
          {
            "category": "25-03-2017",
            "value": 186.917,
            "value2": 122.449
          },
          {
            "category": "26-03-2017",
            "value": 186.917,
            "value2": 122.449
          },
          {
            "category": "27-03-2017",
            "value": 185.919,
            "value2": 121.898
          },
          {
            "category": "28-03-2017",
            "value": 187.264,
            "value2": 122.617
          },
          {
            "category": "29-03-2017",
            "value": 187.325,
            "value2": 123.065
          },
          {
            "category": "30-03-2017",
            "value": 188.096,
            "value2": 123.641
          },
          {
            "category": "31-03-2017",
            "value": 187.726,
            "value2": 123.916
          },
          {
            "category": "01-04-2017",
            "value": 187.727,
            "value2": 123.916
          },
          {
            "category": "02-04-2017",
            "value": 187.727,
            "value2": 123.916
          },
          {
            "category": "03-04-2017",
            "value": 187.826,
            "value2": 124.834
          },
          {
            "category": "04-04-2017",
            "value": 187.826,
            "value2": 124.834
          },
          {
            "category": "05-04-2017",
            "value": 188.144,
            "value2": 125.461
          },
          {
            "category": "06-04-2017",
            "value": 188.356,
            "value2": 125.418
          },
          {
            "category": "07-04-2017",
            "value": 188.084,
            "value2": 124.707
          },
          {
            "category": "08-04-2017",
            "value": 188.084,
            "value2": 124.707
          },
          {
            "category": "09-04-2017",
            "value": 188.084,
            "value2": 124.707
          },
          {
            "category": "10-04-2017",
            "value": 188.053,
            "value2": 124.831
          },
          {
            "category": "11-04-2017",
            "value": 189.507,
            "value2": 125.703
          },
          {
            "category": "12-04-2017",
            "value": 190.37,
            "value2": 125.281
          },
          {
            "category": "13-04-2017",
            "value": 190.332,
            "value2": 124.738
          },
          {
            "category": "14-04-2017",
            "value": 190.332,
            "value2": 124.738
          },
          {
            "category": "15-04-2017",
            "value": 190.332,
            "value2": 124.738
          },
          {
            "category": "16-04-2017",
            "value": 190.332,
            "value2": 124.738
          },
          {
            "category": "17-04-2017",
            "value": 189.234,
            "value2": 124.691
          },
          {
            "category": "18-04-2017",
            "value": 187.639,
            "value2": 124.081
          },
          {
            "category": "19-04-2017",
            "value": 187.462,
            "value2": 124.403
          },
          {
            "category": "20-04-2017",
            "value": 189.146,
            "value2": 125.048
          },
          {
            "category": "21-04-2017",
            "value": 188.32,
            "value2": 124.927
          },
          {
            "category": "22-04-2017",
            "value": 188.316,
            "value2": 124.927
          },
          {
            "category": "23-04-2017",
            "value": 188.316,
            "value2": 124.927
          },
          {
            "category": "24-04-2017",
            "value": 190.704,
            "value2": 126.154
          },
          {
            "category": "25-04-2017",
            "value": 192.368,
            "value2": 127.415
          },
          {
            "category": "26-04-2017",
            "value": 192.737,
            "value2": 127.645
          },
          {
            "category": "27-04-2017",
            "value": 193.122,
            "value2": 127.526
          },
          {
            "category": "28-04-2017",
            "value": 192.103,
            "value2": 127.383
          },
          {
            "category": "29-04-2017",
            "value": 192.099,
            "value2": 127.383
          },
          {
            "category": "30-04-2017",
            "value": 192.098,
            "value2": 127.383
          },
          {
            "category": "01-05-2017",
            "value": 191.704,
            "value2": 127.383
          },
          {
            "category": "02-05-2017",
            "value": 193.206,
            "value2": 127.653
          },
          {
            "category": "03-05-2017",
            "value": 193.175,
            "value2": 127.567
          },
          {
            "category": "04-05-2017",
            "value": 192.368,
            "value2": 128.204
          },
          {
            "category": "05-05-2017",
            "value": 192.089,
            "value2": 127.06
          },
          {
            "category": "06-05-2017",
            "value": 192.09,
            "value2": 127.06
          },
          {
            "category": "07-05-2017",
            "value": 192.09,
            "value2": 127.06
          },
          {
            "category": "08-05-2017",
            "value": 192.725,
            "value2": 127.606
          },
          {
            "category": "09-05-2017",
            "value": 193.127,
            "value2": 127.866
          },
          {
            "category": "10-05-2017",
            "value": 195.016,
            "value2": 129.077
          },
          {
            "category": "11-05-2017",
            "value": 196.668,
            "value2": 129.24
          },
          {
            "category": "12-05-2017",
            "value": 196.624,
            "value2": 128.704
          },
          {
            "category": "13-05-2017",
            "value": 196.624,
            "value2": 128.704
          },
          {
            "category": "14-05-2017",
            "value": 196.624,
            "value2": 128.704
          },
          {
            "category": "15-05-2017",
            "value": 197.787,
            "value2": 129.514
          },
          {
            "category": "16-05-2017",
            "value": 198.98,
            "value2": 130.281
          },
          {
            "category": "17-05-2017",
            "value": 198.223,
            "value2": 130.252
          },
          {
            "category": "18-05-2017",
            "value": 195.621,
            "value2": 128.44
          },
          {
            "category": "19-05-2017",
            "value": 193.652,
            "value2": 128.103
          },
          {
            "category": "20-05-2017",
            "value": 193.652,
            "value2": 128.103
          },
          {
            "category": "21-05-2017",
            "value": 193.652,
            "value2": 128.103
          },
          {
            "category": "22-05-2017",
            "value": 193.004,
            "value2": 127.768
          },
          {
            "category": "23-05-2017",
            "value": 192.309,
            "value2": 126.559
          },
          {
            "category": "24-05-2017",
            "value": 192.253,
            "value2": 125.755
          },
          {
            "category": "25-05-2017",
            "value": 196.311,
            "value2": 127.687
          },
          {
            "category": "26-05-2017",
            "value": 197.701,
            "value2": 129.252
          },
          {
            "category": "27-05-2017",
            "value": 197.701,
            "value2": 129.252
          },
          {
            "category": "28-05-2017",
            "value": 197.701,
            "value2": 129.252
          },
          {
            "category": "29-05-2017",
            "value": 197.496,
            "value2": 128.744
          },
          {
            "category": "30-05-2017",
            "value": 196.939,
            "value2": 129.198
          },
          {
            "category": "31-05-2017",
            "value": 197.415,
            "value2": 129.599
          },
          {
            "category": "01-06-2017",
            "value": 198.456,
            "value2": 129.889
          },
          {
            "category": "02-06-2017",
            "value": 199.068,
            "value2": 130.565
          },
          {
            "category": "03-06-2017",
            "value": 199.065,
            "value2": 130.565
          },
          {
            "category": "04-06-2017",
            "value": 199.065,
            "value2": 130.565
          },
          {
            "category": "05-06-2017",
            "value": 199.673,
            "value2": 131.075
          },
          {
            "category": "06-06-2017",
            "value": 198.816,
            "value2": 130.483
          },
          {
            "category": "07-06-2017",
            "value": 199.708,
            "value2": 130.991
          },
          {
            "category": "08-06-2017",
            "value": 199.615,
            "value2": 130.951
          },
          {
            "category": "09-06-2017",
            "value": 200.244,
            "value2": 131.308
          },
          {
            "category": "10-06-2017",
            "value": 200.244,
            "value2": 131.308
          },
          {
            "category": "11-06-2017",
            "value": 200.244,
            "value2": 131.308
          },
          {
            "category": "12-06-2017",
            "value": 199.413,
            "value2": 130.644
          },
          {
            "category": "13-06-2017",
            "value": 199.714,
            "value2": 130.692
          },
          {
            "category": "14-06-2017",
            "value": 199.741,
            "value2": 130.837
          },
          {
            "category": "15-06-2017",
            "value": 198.449,
            "value2": 130.595
          },
          {
            "category": "16-06-2017",
            "value": 199.298,
            "value2": 130.695
          },
          {
            "category": "17-06-2017",
            "value": 199.298,
            "value2": 130.695
          },
          {
            "category": "18-06-2017",
            "value": 199.298,
            "value2": 130.695
          },
          {
            "category": "19-06-2017",
            "value": 200.302,
            "value2": 131.356
          },
          {
            "category": "20-06-2017",
            "value": 199.352,
            "value2": 131.466
          },
          {
            "category": "21-06-2017",
            "value": 198.794,
            "value2": 131.273
          },
          {
            "category": "22-06-2017",
            "value": 198.946,
            "value2": 131.041
          },
          {
            "category": "23-06-2017",
            "value": 196.521,
            "value2": 129.976
          },
          {
            "category": "24-06-2017",
            "value": 196.521,
            "value2": 129.976
          },
          {
            "category": "25-06-2017",
            "value": 196.521,
            "value2": 129.976
          },
          {
            "category": "26-06-2017",
            "value": 196.521,
            "value2": 129.976
          },
          {
            "category": "27-06-2017",
            "value": 194.145,
            "value2": 128.947
          },
          {
            "category": "28-06-2017",
            "value": 194.179,
            "value2": 128.836
          },
          {
            "category": "29-06-2017",
            "value": 193.777,
            "value2": 129.272
          },
          {
            "category": "30-06-2017",
            "value": 193.152,
            "value2": 129.753
          },
          {
            "category": "01-07-2017",
            "value": 193.152,
            "value2": 129.753
          },
          {
            "category": "02-07-2017",
            "value": 193.152,
            "value2": 129.753
          },
          {
            "category": "03-07-2017",
            "value": 194.823,
            "value2": 131.101
          },
          {
            "category": "04-07-2017",
            "value": 195.004,
            "value2": 130.996
          },
          {
            "category": "05-07-2017",
            "value": 195.789,
            "value2": 131.628
          },
          {
            "category": "06-07-2017",
            "value": 196.053,
            "value2": 132.109
          },
          {
            "category": "07-07-2017",
            "value": 195.971,
            "value2": 132.087
          },
          {
            "category": "08-07-2017",
            "value": 195.971,
            "value2": 132.087
          },
          {
            "category": "09-07-2017",
            "value": 195.971,
            "value2": 132.087
          },
          {
            "category": "10-07-2017",
            "value": 197.818,
            "value2": 133.338
          },
          {
            "category": "11-07-2017",
            "value": 198.548,
            "value2": 133.115
          },
          {
            "category": "12-07-2017",
            "value": 200.383,
            "value2": 133.741
          },
          {
            "category": "13-07-2017",
            "value": 202.26,
            "value2": 134.684
          },
          {
            "category": "14-07-2017",
            "value": 200.637,
            "value2": 134.706
          },
          {
            "category": "15-07-2017",
            "value": 200.637,
            "value2": 134.706
          },
          {
            "category": "16-07-2017",
            "value": 200.637,
            "value2": 134.706
          },
          {
            "category": "17-07-2017",
            "value": 201.624,
            "value2": 135.028
          },
          {
            "category": "18-07-2017",
            "value": 202.1,
            "value2": 133.946
          },
          {
            "category": "19-07-2017",
            "value": 203.322,
            "value2": 135.105
          },
          {
            "category": "20-07-2017",
            "value": 202.462,
            "value2": 134.804
          },
          {
            "category": "21-07-2017",
            "value": 202.369,
            "value2": 135.194
          },
          {
            "category": "22-07-2017",
            "value": 202.369,
            "value2": 135.194
          },
          {
            "category": "23-07-2017",
            "value": 202.369,
            "value2": 135.194
          },
          {
            "category": "24-07-2017",
            "value": 202.554,
            "value2": 135.792
          },
          {
            "category": "25-07-2017",
            "value": 202.814,
            "value2": 135.922
          },
          {
            "category": "26-07-2017",
            "value": 203.537,
            "value2": 136.509
          },
          {
            "category": "27-07-2017",
            "value": 204.67,
            "value2": 136.405
          },
          {
            "category": "28-07-2017",
            "value": 205.249,
            "value2": 136.476
          },
          {
            "category": "29-07-2017",
            "value": 205.249,
            "value2": 136.476
          },
          {
            "category": "30-07-2017",
            "value": 205.249,
            "value2": 136.476
          },
          {
            "category": "31-07-2017",
            "value": 205.858,
            "value2": 137.167
          },
          {
            "category": "01-08-2017",
            "value": 207.582,
            "value2": 137.473
          },
          {
            "category": "02-08-2017",
            "value": 206.997,
            "value2": 137.157
          },
          {
            "category": "03-08-2017",
            "value": 205.693,
            "value2": 136.315
          },
          {
            "category": "04-08-2017",
            "value": 207.771,
            "value2": 137.025
          },
          {
            "category": "05-08-2017",
            "value": 207.771,
            "value2": 137.025
          },
          {
            "category": "06-08-2017",
            "value": 207.771,
            "value2": 137.025
          },
          {
            "category": "07-08-2017",
            "value": 208.417,
            "value2": 137.519
          },
          {
            "category": "08-08-2017",
            "value": 207.343,
            "value2": 136.241
          },
          {
            "category": "09-08-2017",
            "value": 204.918,
            "value2": 134.88
          },
          {
            "category": "10-08-2017",
            "value": 201.872,
            "value2": 132.688
          },
          {
            "category": "11-08-2017",
            "value": 199.971,
            "value2": 131.719
          },
          {
            "category": "12-08-2017",
            "value": 199.971,
            "value2": 131.719
          },
          {
            "category": "13-08-2017",
            "value": 199.971,
            "value2": 131.719
          },
          {
            "category": "14-08-2017",
            "value": 201.862,
            "value2": 133.471
          },
          {
            "category": "15-08-2017",
            "value": 201.862,
            "value2": 133.471
          },
          {
            "category": "16-08-2017",
            "value": 204.083,
            "value2": 134.929
          },
          {
            "category": "17-08-2017",
            "value": 203.908,
            "value2": 135.042
          },
          {
            "category": "18-08-2017",
            "value": 203.596,
            "value2": 134.301
          },
          {
            "category": "19-08-2017",
            "value": 203.596,
            "value2": 134.301
          },
          {
            "category": "20-08-2017",
            "value": 203.596,
            "value2": 134.301
          },
          {
            "category": "21-08-2017",
            "value": 202.404,
            "value2": 132.988
          },
          {
            "category": "22-08-2017",
            "value": 201.671,
            "value2": 132.879
          },
          {
            "category": "23-08-2017",
            "value": 202.578,
            "value2": 134.211
          },
          {
            "category": "24-08-2017",
            "value": 202.344,
            "value2": 134.543
          },
          {
            "category": "25-08-2017",
            "value": 202.344,
            "value2": 134.543
          },
          {
            "category": "26-08-2017",
            "value": 202.344,
            "value2": 134.543
          },
          {
            "category": "27-08-2017",
            "value": 202.344,
            "value2": 134.543
          },
          {
            "category": "28-08-2017",
            "value": 204.075,
            "value2": 135.574
          },
          {
            "category": "29-08-2017",
            "value": 201.805,
            "value2": 134.075
          },
          {
            "category": "30-08-2017",
            "value": 204.576,
            "value2": 135.524
          },
          {
            "category": "31-08-2017",
            "value": 204.96,
            "value2": 135.983
          },
          {
            "category": "01-09-2017",
            "value": 206.701,
            "value2": 136.926
          },
          {
            "category": "02-09-2017",
            "value": 206.701,
            "value2": 136.926
          },
          {
            "category": "03-09-2017",
            "value": 206.701,
            "value2": 136.926
          },
          {
            "category": "04-09-2017",
            "value": 205.887,
            "value2": 136.018
          },
          {
            "category": "05-09-2017",
            "value": 206.75,
            "value2": 136.778
          },
          {
            "category": "06-09-2017",
            "value": 205.669,
            "value2": 136.544
          },
          {
            "category": "07-09-2017",
            "value": 206.97,
            "value2": 137.012
          },
          {
            "category": "08-09-2017",
            "value": 206.704,
            "value2": 136.869
          },
          {
            "category": "09-09-2017",
            "value": 206.704,
            "value2": 136.869
          },
          {
            "category": "10-09-2017",
            "value": 206.704,
            "value2": 136.869
          },
          {
            "category": "11-09-2017",
            "value": 208.201,
            "value2": 137.919
          },
          {
            "category": "12-09-2017",
            "value": 210.451,
            "value2": 139.131
          },
          {
            "category": "13-09-2017",
            "value": 209.035,
            "value2": 138.768
          },
          {
            "category": "14-09-2017",
            "value": 208.839,
            "value2": 139.147
          },
          {
            "category": "15-09-2017",
            "value": 208.666,
            "value2": 139.178
          },
          {
            "category": "16-09-2017",
            "value": 208.666,
            "value2": 139.178
          },
          {
            "category": "17-09-2017",
            "value": 208.666,
            "value2": 139.178
          },
          {
            "category": "18-09-2017",
            "value": 209.829,
            "value2": 140.077
          },
          {
            "category": "19-09-2017",
            "value": 209.53,
            "value2": 140.185
          },
          {
            "category": "20-09-2017",
            "value": 208.074,
            "value2": 139.99
          },
          {
            "category": "21-09-2017",
            "value": 207.844,
            "value2": 139.526
          },
          {
            "category": "22-09-2017",
            "value": 204.928,
            "value2": 136.799
          },
          {
            "category": "23-09-2017",
            "value": 204.928,
            "value2": 136.799
          },
          {
            "category": "24-09-2017",
            "value": 204.928,
            "value2": 136.799
          },
          {
            "category": "25-09-2017",
            "value": 201.837,
            "value2": 135.234
          },
          {
            "category": "26-09-2017",
            "value": 201.485,
            "value2": 135.489
          },
          {
            "category": "27-09-2017",
            "value": 198.886,
            "value2": 133.376
          },
          {
            "category": "28-09-2017",
            "value": 198.732,
            "value2": 133.98
          },
          {
            "category": "29-09-2017",
            "value": 200.12,
            "value2": 134.585
          },
          {
            "category": "30-09-2017",
            "value": 199.666,
            "value2": 134.585
          },
          {
            "category": "01-10-2017",
            "value": 199.666,
            "value2": 134.585
          },
          {
            "category": "02-10-2017",
            "value": 199.666,
            "value2": 134.585
          },
          {
            "category": "03-10-2017",
            "value": 201.04,
            "value2": 135.618
          },
          {
            "category": "04-10-2017",
            "value": 202.085,
            "value2": 136.323
          },
          {
            "category": "05-10-2017",
            "value": 202.007,
            "value2": 136.322
          },
          {
            "category": "06-10-2017",
            "value": 204.505,
            "value2": 137.546
          },
          {
            "category": "07-10-2017",
            "value": 204.505,
            "value2": 137.546
          },
          {
            "category": "08-10-2017",
            "value": 204.505,
            "value2": 137.546
          },
          {
            "category": "09-10-2017",
            "value": 204.205,
            "value2": 137.748
          },
          {
            "category": "10-10-2017",
            "value": 204.364,
            "value2": 138.318
          },
          {
            "category": "11-10-2017",
            "value": 203.723,
            "value2": 137.652
          },
          {
            "category": "12-10-2017",
            "value": 205.801,
            "value2": 139.158
          },
          {
            "category": "13-10-2017",
            "value": 206.682,
            "value2": 139.887
          },
          {
            "category": "14-10-2017",
            "value": 206.682,
            "value2": 139.887
          },
          {
            "category": "15-10-2017",
            "value": 206.682,
            "value2": 139.887
          },
          {
            "category": "16-10-2017",
            "value": 208.391,
            "value2": 140.619
          },
          {
            "category": "17-10-2017",
            "value": 208.974,
            "value2": 140.877
          },
          {
            "category": "18-10-2017",
            "value": 208.325,
            "value2": 140.621
          },
          {
            "category": "19-10-2017",
            "value": 207.505,
            "value2": 139.963
          },
          {
            "category": "20-10-2017",
            "value": 207.505,
            "value2": 139.963
          },
          {
            "category": "21-10-2017",
            "value": 207.505,
            "value2": 139.963
          },
          {
            "category": "22-10-2017",
            "value": 207.505,
            "value2": 139.963
          },
          {
            "category": "23-10-2017",
            "value": 207.035,
            "value2": 140.397
          },
          {
            "category": "24-10-2017",
            "value": 207.641,
            "value2": 140.769
          },
          {
            "category": "25-10-2017",
            "value": 205.177,
            "value2": 141.925
          },
          {
            "category": "26-10-2017",
            "value": 206.515,
            "value2": 142.559
          },
          {
            "category": "27-10-2017",
            "value": 207.956,
            "value2": 142.452
          },
          {
            "category": "28-10-2017",
            "value": 207.956,
            "value2": 142.452
          },
          {
            "category": "29-10-2017",
            "value": 207.956,
            "value2": 142.452
          },
          {
            "category": "30-10-2017",
            "value": 209.516,
            "value2": 143.333
          },
          {
            "category": "31-10-2017",
            "value": 208.929,
            "value2": 143.313
          },
          {
            "category": "01-11-2017",
            "value": 208.857,
            "value2": 144.557
          },
          {
            "category": "02-11-2017",
            "value": 209.479,
            "value2": 144.678
          },
          {
            "category": "03-11-2017",
            "value": 209.589,
            "value2": 145.066
          },
          {
            "category": "04-11-2017",
            "value": 209.589,
            "value2": 145.066
          },
          {
            "category": "05-11-2017",
            "value": 209.589,
            "value2": 145.066
          },
          {
            "category": "06-11-2017",
            "value": 208.598,
            "value2": 145.305
          },
          {
            "category": "07-11-2017",
            "value": 207.145,
            "value2": 143.687
          },
          {
            "category": "08-11-2017",
            "value": 207.433,
            "value2": 142.871
          },
          {
            "category": "09-11-2017",
            "value": 207.71,
            "value2": 143.322
          },
          {
            "category": "10-11-2017",
            "value": 205.895,
            "value2": 143.376
          },
          {
            "category": "11-11-2017",
            "value": 205.893,
            "value2": 143.376
          },
          {
            "category": "12-11-2017",
            "value": 205.893,
            "value2": 143.376
          },
          {
            "category": "13-11-2017",
            "value": 204.164,
            "value2": 142.332
          },
          {
            "category": "14-11-2017",
            "value": 203.018,
            "value2": 141.829
          },
          {
            "category": "15-11-2017",
            "value": 203.199,
            "value2": 140.589
          },
          {
            "category": "16-11-2017",
            "value": 204.137,
            "value2": 141.988
          },
          {
            "category": "17-11-2017",
            "value": 205.986,
            "value2": 143.08
          },
          {
            "category": "18-11-2017",
            "value": 205.986,
            "value2": 143.08
          },
          {
            "category": "19-11-2017",
            "value": 205.986,
            "value2": 143.08
          },
          {
            "category": "20-11-2017",
            "value": 206.529,
            "value2": 143.474
          },
          {
            "category": "21-11-2017",
            "value": 207.14,
            "value2": 143.868
          },
          {
            "category": "22-11-2017",
            "value": 206.932,
            "value2": 144.03
          },
          {
            "category": "23-11-2017",
            "value": 206.652,
            "value2": 144.249
          },
          {
            "category": "24-11-2017",
            "value": 207.427,
            "value2": 144.807
          },
          {
            "category": "25-11-2017",
            "value": 207.427,
            "value2": 144.807
          },
          {
            "category": "26-11-2017",
            "value": 207.427,
            "value2": 144.807
          },
          {
            "category": "27-11-2017",
            "value": 207.523,
            "value2": 145.145
          },
          {
            "category": "28-11-2017",
            "value": 207.634,
            "value2": 144.94
          },
          {
            "category": "29-11-2017",
            "value": 208.25,
            "value2": 144.795
          },
          {
            "category": "30-11-2017",
            "value": 205.854,
            "value2": 143.471
          },
          {
            "category": "01-12-2017",
            "value": 204.098,
            "value2": 141.933
          },
          {
            "category": "02-12-2017",
            "value": 204.098,
            "value2": 141.933
          },
          {
            "category": "03-12-2017",
            "value": 204.098,
            "value2": 141.933
          },
          {
            "category": "04-12-2017",
            "value": 203.405,
            "value2": 141.913
          },
          {
            "category": "05-12-2017",
            "value": 203.311,
            "value2": 141.82
          },
          {
            "category": "06-12-2017",
            "value": 200.523,
            "value2": 140.733
          },
          {
            "category": "07-12-2017",
            "value": 203.117,
            "value2": 142.542
          },
          {
            "category": "08-12-2017",
            "value": 205.722,
            "value2": 143.887
          },
          {
            "category": "09-12-2017",
            "value": 205.722,
            "value2": 143.887
          },
          {
            "category": "10-12-2017",
            "value": 205.722,
            "value2": 143.887
          },
          {
            "category": "11-12-2017",
            "value": 206.877,
            "value2": 144.536
          },
          {
            "category": "12-12-2017",
            "value": 205.231,
            "value2": 143.418
          },
          {
            "category": "13-12-2017",
            "value": 203.891,
            "value2": 142.54
          },
          {
            "category": "14-12-2017",
            "value": 204.512,
            "value2": 143.075
          },
          {
            "category": "15-12-2017",
            "value": 206.263,
            "value2": 144.39
          },
          {
            "category": "16-12-2017",
            "value": 206.263,
            "value2": 144.39
          },
          {
            "category": "17-12-2017",
            "value": 206.263,
            "value2": 144.39
          },
          {
            "category": "18-12-2017",
            "value": 207.733,
            "value2": 145.132
          },
          {
            "category": "19-12-2017",
            "value": 209.421,
            "value2": 146.534
          },
          {
            "category": "20-12-2017",
            "value": 209.222,
            "value2": 146.594
          },
          {
            "category": "21-12-2017",
            "value": 209.55,
            "value2": 146.953
          },
          {
            "category": "22-12-2017",
            "value": 210.239,
            "value2": 147.579
          },
          {
            "category": "23-12-2017",
            "value": 210.239,
            "value2": 147.579
          },
          {
            "category": "24-12-2017",
            "value": 210.239,
            "value2": 147.579
          },
          {
            "category": "25-12-2017",
            "value": 210.239,
            "value2": 147.579
          },
          {
            "category": "26-12-2017",
            "value": 211.329,
            "value2": 148.137
          },
          {
            "category": "27-12-2017",
            "value": 210.943,
            "value2": 147.719
          },
          {
            "category": "28-12-2017",
            "value": 210.443,
            "value2": 147.658
          },
          {
            "category": "29-12-2017",
            "value": 210.428,
            "value2": 148.516
          },
          {
            "category": "30-12-2017",
            "value": 210.427,
            "value2": 148.516
          },
          {
            "category": "31-12-2017",
            "value": 209.955,
            "value2": 148.516
          },
          {
            "category": "01-01-2018",
            "value": 208.29,
            "value2": 147.851
          },
          {
            "category": "02-01-2018",
            "value": 207.628,
            "value2": 147.453
          },
          {
            "category": "03-01-2018",
            "value": 207.68,
            "value2": 147.815
          },
          {
            "category": "04-01-2018",
            "value": 208.568,
            "value2": 148.812
          },
          {
            "category": "05-01-2018",
            "value": 210.149,
            "value2": 149.855
          },
          {
            "category": "06-01-2018",
            "value": 210.149,
            "value2": 149.855
          },
          {
            "category": "07-01-2018",
            "value": 210.149,
            "value2": 149.855
          },
          {
            "category": "08-01-2018",
            "value": 212.417,
            "value2": 150.907
          },
          {
            "category": "09-01-2018",
            "value": 210.519,
            "value2": 150.971
          },
          {
            "category": "10-01-2018",
            "value": 209.531,
            "value2": 150.815
          },
          {
            "category": "11-01-2018",
            "value": 210.508,
            "value2": 151.133
          },
          {
            "category": "12-01-2018",
            "value": 210.549,
            "value2": 151.3
          },
          {
            "category": "13-01-2018",
            "value": 210.549,
            "value2": 151.3
          },
          {
            "category": "14-01-2018",
            "value": 210.549,
            "value2": 151.3
          },
          {
            "category": "15-01-2018",
            "value": 211.656,
            "value2": 151.872
          },
          {
            "category": "16-01-2018",
            "value": 210.321,
            "value2": 150.57
          },
          {
            "category": "17-01-2018",
            "value": 212.193,
            "value2": 151.731
          },
          {
            "category": "18-01-2018",
            "value": 212.053,
            "value2": 151.065
          },
          {
            "category": "19-01-2018",
            "value": 213.629,
            "value2": 152.252
          },
          {
            "category": "20-01-2018",
            "value": 213.628,
            "value2": 152.252
          },
          {
            "category": "21-01-2018",
            "value": 213.628,
            "value2": 152.252
          },
          {
            "category": "22-01-2018",
            "value": 213.879,
            "value2": 153.296
          },
          {
            "category": "23-01-2018",
            "value": 213.943,
            "value2": 154.642
          },
          {
            "category": "24-01-2018",
            "value": 213.838,
            "value2": 154.367
          },
          {
            "category": "25-01-2018",
            "value": 213.073,
            "value2": 153.603
          },
          {
            "category": "26-01-2018",
            "value": 213.073,
            "value2": 153.603
          },
          {
            "category": "27-01-2018",
            "value": 213.073,
            "value2": 153.603
          },
          {
            "category": "28-01-2018",
            "value": 213.073,
            "value2": 153.603
          },
          {
            "category": "29-01-2018",
            "value": 214.206,
            "value2": 153.787
          },
          {
            "category": "30-01-2018",
            "value": 212.705,
            "value2": 152.66
          },
          {
            "category": "31-01-2018",
            "value": 211.661,
            "value2": 151.964
          },
          {
            "category": "01-02-2018",
            "value": 213.987,
            "value2": 151.8
          },
          {
            "category": "02-02-2018",
            "value": 208.789,
            "value2": 147.411
          },
          {
            "category": "03-02-2018",
            "value": 208.789,
            "value2": 147.411
          },
          {
            "category": "04-02-2018",
            "value": 208.789,
            "value2": 147.411
          },
          {
            "category": "05-02-2018",
            "value": 207.188,
            "value2": 146.485
          },
          {
            "category": "06-02-2018",
            "value": 204.378,
            "value2": 144.005
          },
          {
            "category": "07-02-2018",
            "value": 205.165,
            "value2": 144.225
          },
          {
            "category": "08-02-2018",
            "value": 208.187,
            "value2": 146.174
          },
          {
            "category": "09-02-2018",
            "value": 206.359,
            "value2": 145.159
          },
          {
            "category": "10-02-2018",
            "value": 206.359,
            "value2": 145.159
          },
          {
            "category": "11-02-2018",
            "value": 206.359,
            "value2": 145.159
          },
          {
            "category": "12-02-2018",
            "value": 208.181,
            "value2": 146.612
          },
          {
            "category": "13-02-2018",
            "value": 208.181,
            "value2": 146.612
          },
          {
            "category": "14-02-2018",
            "value": 207.336,
            "value2": 146.267
          },
          {
            "category": "15-02-2018",
            "value": 207.567,
            "value2": 146.257
          },
          {
            "category": "16-02-2018",
            "value": 206.066,
            "value2": 144.865
          },
          {
            "category": "17-02-2018",
            "value": 206.066,
            "value2": 144.865
          },
          {
            "category": "18-02-2018",
            "value": 206.066,
            "value2": 144.865
          },
          {
            "category": "19-02-2018",
            "value": 204.577,
            "value2": 143.682
          },
          {
            "category": "20-02-2018",
            "value": 203.957,
            "value2": 143.567
          },
          {
            "category": "21-02-2018",
            "value": 203.716,
            "value2": 143.84
          },
          {
            "category": "22-02-2018",
            "value": 203.232,
            "value2": 143.455
          },
          {
            "category": "23-02-2018",
            "value": 204.968,
            "value2": 145.159
          },
          {
            "category": "24-02-2018",
            "value": 204.968,
            "value2": 145.159
          },
          {
            "category": "25-02-2018",
            "value": 204.968,
            "value2": 145.159
          },
          {
            "category": "26-02-2018",
            "value": 208.098,
            "value2": 146.49
          },
          {
            "category": "27-02-2018",
            "value": 206.948,
            "value2": 145.985
          },
          {
            "category": "28-02-2018",
            "value": 205.647,
            "value2": 145.488
          },
          {
            "category": "01-03-2018",
            "value": 205.557,
            "value2": 144.9
          },
          {
            "category": "02-03-2018",
            "value": 205.557,
            "value2": 144.9
          },
          {
            "category": "03-03-2018",
            "value": 205.557,
            "value2": 144.9
          },
          {
            "category": "04-03-2018",
            "value": 205.557,
            "value2": 144.9
          },
          {
            "category": "05-03-2018",
            "value": 203.865,
            "value2": 143.473
          },
          {
            "category": "06-03-2018",
            "value": 202.411,
            "value2": 141.962
          },
          {
            "category": "07-03-2018",
            "value": 200.364,
            "value2": 140.416
          },
          {
            "category": "08-03-2018",
            "value": 201.924,
            "value2": 141.387
          },
          {
            "category": "09-03-2018",
            "value": 202.337,
            "value2": 141.17
          },
          {
            "category": "10-03-2018",
            "value": 202.337,
            "value2": 141.17
          },
          {
            "category": "11-03-2018",
            "value": 202.337,
            "value2": 141.17
          },
          {
            "category": "12-03-2018",
            "value": 204.262,
            "value2": 143.214
          },
          {
            "category": "13-03-2018",
            "value": 206.01,
            "value2": 143.779
          },
          {
            "category": "14-03-2018",
            "value": 205.08,
            "value2": 143.8
          },
          {
            "category": "15-03-2018",
            "value": 204.789,
            "value2": 143.52
          },
          {
            "category": "16-03-2018",
            "value": 201.267,
            "value2": 141.657
          },
          {
            "category": "17-03-2018",
            "value": 201.267,
            "value2": 141.657
          },
          {
            "category": "18-03-2018",
            "value": 201.267,
            "value2": 141.657
          },
          {
            "category": "19-03-2018",
            "value": 198.588,
            "value2": 139.915
          },
          {
            "category": "20-03-2018",
            "value": 199.595,
            "value2": 140.327
          },
          {
            "category": "21-03-2018",
            "value": 199.772,
            "value2": 140.801
          },
          {
            "category": "22-03-2018",
            "value": 198.52,
            "value2": 140.068
          },
          {
            "category": "23-03-2018",
            "value": 197.072,
            "value2": 138.4
          },
          {
            "category": "24-03-2018",
            "value": 197.072,
            "value2": 138.4
          },
          {
            "category": "25-03-2018",
            "value": 197.072,
            "value2": 138.4
          },
          {
            "category": "26-03-2018",
            "value": 199.846,
            "value2": 140.175
          },
          {
            "category": "27-03-2018",
            "value": 202.277,
            "value2": 141.187
          },
          {
            "category": "28-03-2018",
            "value": 201.998,
            "value2": 140.282
          },
          {
            "category": "29-03-2018",
            "value": 201.998,
            "value2": 140.282
          },
          {
            "category": "30-03-2018",
            "value": 201.996,
            "value2": 140.282
          },
          {
            "category": "31-03-2018",
            "value": 201.275,
            "value2": 140.282
          },
          {
            "category": "01-04-2018",
            "value": 201.275,
            "value2": 140.282
          },
          {
            "category": "02-04-2018",
            "value": 204.504,
            "value2": 141.966
          },
          {
            "category": "03-04-2018",
            "value": 205.121,
            "value2": 142.788
          },
          {
            "category": "04-04-2018",
            "value": 204.051,
            "value2": 141.217
          },
          {
            "category": "05-04-2018",
            "value": 208.386,
            "value2": 143.838
          },
          {
            "category": "06-04-2018",
            "value": 209.879,
            "value2": 144.266
          },
          {
            "category": "07-04-2018",
            "value": 209.879,
            "value2": 144.266
          },
          {
            "category": "08-04-2018",
            "value": 209.879,
            "value2": 144.266
          },
          {
            "category": "09-04-2018",
            "value": 211.132,
            "value2": 144.811
          },
          {
            "category": "10-04-2018",
            "value": 210.6,
            "value2": 145.033
          },
          {
            "category": "11-04-2018",
            "value": 209.98,
            "value2": 145.159
          },
          {
            "category": "12-04-2018",
            "value": 210.687,
            "value2": 145.466
          },
          {
            "category": "13-04-2018",
            "value": 209.817,
            "value2": 145.835
          },
          {
            "category": "14-04-2018",
            "value": 209.817,
            "value2": 145.835
          },
          {
            "category": "15-04-2018",
            "value": 209.817,
            "value2": 145.835
          },
          {
            "category": "16-04-2018",
            "value": 211.26,
            "value2": 146.5
          },
          {
            "category": "17-04-2018",
            "value": 210.942,
            "value2": 146.855
          },
          {
            "category": "18-04-2018",
            "value": 210.121,
            "value2": 146.59
          },
          {
            "category": "19-04-2018",
            "value": 209.582,
            "value2": 147.271
          },
          {
            "category": "20-04-2018",
            "value": 209.056,
            "value2": 147.162
          },
          {
            "category": "21-04-2018",
            "value": 209.056,
            "value2": 147.162
          },
          {
            "category": "22-04-2018",
            "value": 209.056,
            "value2": 147.162
          },
          {
            "category": "23-04-2018",
            "value": 209.36,
            "value2": 147.491
          },
          {
            "category": "24-04-2018",
            "value": 211.103,
            "value2": 147.743
          },
          {
            "category": "25-04-2018",
            "value": 210.837,
            "value2": 147.079
          },
          {
            "category": "26-04-2018",
            "value": 212.191,
            "value2": 147.657
          },
          {
            "category": "27-04-2018",
            "value": 212.333,
            "value2": 148.594
          },
          {
            "category": "28-04-2018",
            "value": 212.336,
            "value2": 148.594
          },
          {
            "category": "29-04-2018",
            "value": 212.336,
            "value2": 148.594
          },
          {
            "category": "30-04-2018",
            "value": 212.517,
            "value2": 149.452
          },
          {
            "category": "01-05-2018",
            "value": 212.517,
            "value2": 149.452
          },
          {
            "category": "02-05-2018",
            "value": 211.319,
            "value2": 148.635
          },
          {
            "category": "03-05-2018",
            "value": 209.059,
            "value2": 147.756
          },
          {
            "category": "04-05-2018",
            "value": 207.244,
            "value2": 147.038
          },
          {
            "category": "05-05-2018",
            "value": 207.244,
            "value2": 147.038
          },
          {
            "category": "06-05-2018",
            "value": 207.244,
            "value2": 147.038
          },
          {
            "category": "07-05-2018",
            "value": 208.663,
            "value2": 148.191
          },
          {
            "category": "08-05-2018",
            "value": 209.115,
            "value2": 148.239
          },
          {
            "category": "09-05-2018",
            "value": 209.12,
            "value2": 148.243
          },
          {
            "category": "10-05-2018",
            "value": 208.682,
            "value2": 147.331
          },
          {
            "category": "11-05-2018",
            "value": 210.122,
            "value2": 148.276
          },
          {
            "category": "12-05-2018",
            "value": 210.122,
            "value2": 148.276
          },
          {
            "category": "13-05-2018",
            "value": 210.122,
            "value2": 148.276
          },
          {
            "category": "14-05-2018",
            "value": 210.17,
            "value2": 147.821
          },
          {
            "category": "15-05-2018",
            "value": 210.163,
            "value2": 147.524
          },
          {
            "category": "16-05-2018",
            "value": 208.897,
            "value2": 147.025
          },
          {
            "category": "17-05-2018",
            "value": 208.676,
            "value2": 146.65
          },
          {
            "category": "18-05-2018",
            "value": 207.315,
            "value2": 145.067
          },
          {
            "category": "19-05-2018",
            "value": 207.315,
            "value2": 145.067
          },
          {
            "category": "20-05-2018",
            "value": 207.315,
            "value2": 145.067
          },
          {
            "category": "21-05-2018",
            "value": 205.16,
            "value2": 143.53
          },
          {
            "category": "22-05-2018",
            "value": 206.033,
            "value2": 144.104
          },
          {
            "category": "23-05-2018",
            "value": 204.382,
            "value2": 142.864
          },
          {
            "category": "24-05-2018",
            "value": 204.275,
            "value2": 143.612
          },
          {
            "category": "25-05-2018",
            "value": 207.44,
            "value2": 145.337
          },
          {
            "category": "26-05-2018",
            "value": 207.44,
            "value2": 145.337
          },
          {
            "category": "27-05-2018",
            "value": 207.44,
            "value2": 145.337
          },
          {
            "category": "28-05-2018",
            "value": 210.227,
            "value2": 146.776
          },
          {
            "category": "29-05-2018",
            "value": 209.338,
            "value2": 146.08
          },
          {
            "category": "30-05-2018",
            "value": 209.76,
            "value2": 145.858
          },
          {
            "category": "31-05-2018",
            "value": 211.081,
            "value2": 146.885
          },
          {
            "category": "01-06-2018",
            "value": 209.614,
            "value2": 145.968
          },
          {
            "category": "02-06-2018",
            "value": 209.602,
            "value2": 145.968
          },
          {
            "category": "03-06-2018",
            "value": 209.602,
            "value2": 145.968
          },
          {
            "category": "04-06-2018",
            "value": 207.429,
            "value2": 144.749
          },
          {
            "category": "05-06-2018",
            "value": 206.934,
            "value2": 143.698
          },
          {
            "category": "06-06-2018",
            "value": 209.056,
            "value2": 145.205
          },
          {
            "category": "07-06-2018",
            "value": 210.148,
            "value2": 146.672
          },
          {
            "category": "08-06-2018",
            "value": 210.531,
            "value2": 146.933
          },
          {
            "category": "09-06-2018",
            "value": 210.531,
            "value2": 146.933
          },
          {
            "category": "10-06-2018",
            "value": 210.531,
            "value2": 146.933
          },
          {
            "category": "11-06-2018",
            "value": 211.048,
            "value2": 147.178
          },
          {
            "category": "12-06-2018",
            "value": 212.468,
            "value2": 147.975
          },
          {
            "category": "13-06-2018",
            "value": 212.289,
            "value2": 148.02
          },
          {
            "category": "14-06-2018",
            "value": 212.154,
            "value2": 147.7
          },
          {
            "category": "15-06-2018",
            "value": 211.443,
            "value2": 147.476
          },
          {
            "category": "16-06-2018",
            "value": 211.443,
            "value2": 147.476
          },
          {
            "category": "17-06-2018",
            "value": 211.443,
            "value2": 147.476
          },
          {
            "category": "18-06-2018",
            "value": 211.476,
            "value2": 147.122
          },
          {
            "category": "19-06-2018",
            "value": 210.051,
            "value2": 145.845
          },
          {
            "category": "20-06-2018",
            "value": 209.995,
            "value2": 146.504
          },
          {
            "category": "21-06-2018",
            "value": 209.303,
            "value2": 145.923
          },
          {
            "category": "22-06-2018",
            "value": 210.879,
            "value2": 146.81
          },
          {
            "category": "23-06-2018",
            "value": 210.879,
            "value2": 146.81
          },
          {
            "category": "24-06-2018",
            "value": 210.879,
            "value2": 146.81
          },
          {
            "category": "25-06-2018",
            "value": 209.482,
            "value2": 145.844
          },
          {
            "category": "26-06-2018",
            "value": 209.562,
            "value2": 145.792
          },
          {
            "category": "27-06-2018",
            "value": 206.353,
            "value2": 144.226
          },
          {
            "category": "28-06-2018",
            "value": 204.637,
            "value2": 142.822
          },
          {
            "category": "29-06-2018",
            "value": 205.994,
            "value2": 144.726
          },
          {
            "category": "30-06-2018",
            "value": 205.532,
            "value2": 144.726
          },
          {
            "category": "01-07-2018",
            "value": 205.532,
            "value2": 144.726
          },
          {
            "category": "02-07-2018",
            "value": 204.16,
            "value2": 143.946
          },
          {
            "category": "03-07-2018",
            "value": 205.304,
            "value2": 144.597
          },
          {
            "category": "04-07-2018",
            "value": 207.467,
            "value2": 145.294
          },
          {
            "category": "05-07-2018",
            "value": 207.182,
            "value2": 144.958
          },
          {
            "category": "06-07-2018",
            "value": 207.697,
            "value2": 145.376
          },
          {
            "category": "07-07-2018",
            "value": 207.697,
            "value2": 145.376
          },
          {
            "category": "08-07-2018",
            "value": 207.697,
            "value2": 145.376
          },
          {
            "category": "09-07-2018",
            "value": 208.145,
            "value2": 146.703
          },
          {
            "category": "10-07-2018",
            "value": 208.793,
            "value2": 147.984
          },
          {
            "category": "11-07-2018",
            "value": 207.524,
            "value2": 147.73
          },
          {
            "category": "12-07-2018",
            "value": 209.137,
            "value2": 148.372
          },
          {
            "category": "13-07-2018",
            "value": 209.106,
            "value2": 147.825
          },
          {
            "category": "14-07-2018",
            "value": 209.106,
            "value2": 147.825
          },
          {
            "category": "15-07-2018",
            "value": 209.106,
            "value2": 147.825
          },
          {
            "category": "16-07-2018",
            "value": 206.99,
            "value2": 146.069
          },
          {
            "category": "17-07-2018",
            "value": 209.149,
            "value2": 147.451
          },
          {
            "category": "18-07-2018",
            "value": 209.489,
            "value2": 146.659
          },
          {
            "category": "19-07-2018",
            "value": 207.673,
            "value2": 146.281
          },
          {
            "category": "20-07-2018",
            "value": 208.983,
            "value2": 147.061
          },
          {
            "category": "21-07-2018",
            "value": 208.983,
            "value2": 147.061
          },
          {
            "category": "22-07-2018",
            "value": 208.983,
            "value2": 147.061
          },
          {
            "category": "23-07-2018",
            "value": 210.369,
            "value2": 148.267
          },
          {
            "category": "24-07-2018",
            "value": 211.4,
            "value2": 149.657
          },
          {
            "category": "25-07-2018",
            "value": 211.992,
            "value2": 149.678
          },
          {
            "category": "26-07-2018",
            "value": 212.628,
            "value2": 150.35
          },
          {
            "category": "27-07-2018",
            "value": 213.368,
            "value2": 151.762
          },
          {
            "category": "28-07-2018",
            "value": 213.368,
            "value2": 151.762
          },
          {
            "category": "29-07-2018",
            "value": 213.368,
            "value2": 151.762
          },
          {
            "category": "30-07-2018",
            "value": 212.158,
            "value2": 152.403
          },
          {
            "category": "31-07-2018",
            "value": 211.639,
            "value2": 152.911
          },
          {
            "category": "01-08-2018",
            "value": 211.587,
            "value2": 152.895
          },
          {
            "category": "02-08-2018",
            "value": 210.549,
            "value2": 151.995
          },
          {
            "category": "03-08-2018",
            "value": 211.867,
            "value2": 153.409
          },
          {
            "category": "04-08-2018",
            "value": 211.867,
            "value2": 153.409
          },
          {
            "category": "05-08-2018",
            "value": 211.867,
            "value2": 153.409
          },
          {
            "category": "06-08-2018",
            "value": 211.771,
            "value2": 153.85
          },
          {
            "category": "07-08-2018",
            "value": 211.813,
            "value2": 153.719
          },
          {
            "category": "08-08-2018",
            "value": 212.155,
            "value2": 154.315
          },
          {
            "category": "09-08-2018",
            "value": 211.717,
            "value2": 154.827
          },
          {
            "category": "10-08-2018",
            "value": 213.524,
            "value2": 154.06
          },
          {
            "category": "11-08-2018",
            "value": 213.524,
            "value2": 154.06
          },
          {
            "category": "12-08-2018",
            "value": 213.524,
            "value2": 154.06
          },
          {
            "category": "13-08-2018",
            "value": 210.799,
            "value2": 153.071
          },
          {
            "category": "14-08-2018",
            "value": 212.607,
            "value2": 154.167
          },
          {
            "category": "15-08-2018",
            "value": 212.607,
            "value2": 154.167
          },
          {
            "category": "16-08-2018",
            "value": 211.753,
            "value2": 153.524
          },
          {
            "category": "17-08-2018",
            "value": 212.492,
            "value2": 154.867
          },
          {
            "category": "18-08-2018",
            "value": 212.492,
            "value2": 154.867
          },
          {
            "category": "19-08-2018",
            "value": 212.492,
            "value2": 154.867
          },
          {
            "category": "20-08-2018",
            "value": 214.319,
            "value2": 155.989
          },
          {
            "category": "21-08-2018",
            "value": 214.61,
            "value2": 156.33
          },
          {
            "category": "22-08-2018",
            "value": 214.61,
            "value2": 156.33
          },
          {
            "category": "23-08-2018",
            "value": 213.854,
            "value2": 156.579
          },
          {
            "category": "24-08-2018",
            "value": 213.044,
            "value2": 156.257
          },
          {
            "category": "25-08-2018",
            "value": 213.044,
            "value2": 156.257
          },
          {
            "category": "26-08-2018",
            "value": 213.044,
            "value2": 156.257
          },
          {
            "category": "27-08-2018",
            "value": 214.923,
            "value2": 157.929
          },
          {
            "category": "28-08-2018",
            "value": 214.753,
            "value2": 158.354
          },
          {
            "category": "29-08-2018",
            "value": 215.812,
            "value2": 158.133
          },
          {
            "category": "30-08-2018",
            "value": 216.277,
            "value2": 158.168
          },
          {
            "category": "31-08-2018",
            "value": 216.297,
            "value2": 158.455
          },
          {
            "category": "01-09-2018",
            "value": 216.297,
            "value2": 158.455
          },
          {
            "category": "02-09-2018",
            "value": 216.297,
            "value2": 158.455
          },
          {
            "category": "03-09-2018",
            "value": 215.095,
            "value2": 157.369
          },
          {
            "category": "04-09-2018",
            "value": 212.507,
            "value2": 155.481
          },
          {
            "category": "05-09-2018",
            "value": 212.071,
            "value2": 154.853
          },
          {
            "category": "06-09-2018",
            "value": 213.153,
            "value2": 155.652
          },
          {
            "category": "07-09-2018",
            "value": 214.615,
            "value2": 156.623
          },
          {
            "category": "08-09-2018",
            "value": 214.615,
            "value2": 156.623
          },
          {
            "category": "09-09-2018",
            "value": 214.615,
            "value2": 156.623
          },
          {
            "category": "10-09-2018",
            "value": 211.181,
            "value2": 154.555
          },
          {
            "category": "11-09-2018",
            "value": 208.71,
            "value2": 152.391
          },
          {
            "category": "12-09-2018",
            "value": 210.27,
            "value2": 153.326
          },
          {
            "category": "13-09-2018",
            "value": 210.27,
            "value2": 153.326
          },
          {
            "category": "14-09-2018",
            "value": 213.803,
            "value2": 155.355
          },
          {
            "category": "15-09-2018",
            "value": 213.803,
            "value2": 155.355
          },
          {
            "category": "16-09-2018",
            "value": 213.803,
            "value2": 155.355
          },
          {
            "category": "17-09-2018",
            "value": 210.992,
            "value2": 153.846
          },
          {
            "category": "18-09-2018",
            "value": 207.998,
            "value2": 152.263
          },
          {
            "category": "19-09-2018",
            "value": 207.43,
            "value2": 151.489
          },
          {
            "category": "20-09-2018",
            "value": 207.43,
            "value2": 151.489
          },
          {
            "category": "21-09-2018",
            "value": 205.376,
            "value2": 149.551
          },
          {
            "category": "22-09-2018",
            "value": 205.376,
            "value2": 149.551
          },
          {
            "category": "23-09-2018",
            "value": 205.376,
            "value2": 149.551
          },
          {
            "category": "24-09-2018",
            "value": 198.509,
            "value2": 146.64
          },
          {
            "category": "25-09-2018",
            "value": 201.019,
            "value2": 147.611
          },
          {
            "category": "26-09-2018",
            "value": 201.249,
            "value2": 147.555
          },
          {
            "category": "27-09-2018",
            "value": 198.514,
            "value2": 146.003
          },
          {
            "category": "28-09-2018",
            "value": 196.223,
            "value2": 144.553
          },
          {
            "category": "29-09-2018",
            "value": 196.222,
            "value2": 144.553
          },
          {
            "category": "30-09-2018",
            "value": 195.763,
            "value2": 144.553
          },
          {
            "category": "01-10-2018",
            "value": 195.949,
            "value2": 145.43
          },
          {
            "category": "02-10-2018",
            "value": 195.949,
            "value2": 145.43
          },
          {
            "category": "03-10-2018",
            "value": 192.811,
            "value2": 143.747
          },
          {
            "category": "04-10-2018",
            "value": 187.947,
            "value2": 140.565
          },
          {
            "category": "05-10-2018",
            "value": 182.007,
            "value2": 136.988
          },
          {
            "category": "06-10-2018",
            "value": 182.007,
            "value2": 136.988
          },
          {
            "category": "07-10-2018",
            "value": 182.007,
            "value2": 136.988
          },
          {
            "category": "08-10-2018",
            "value": 182.485,
            "value2": 136.615
          },
          {
            "category": "09-10-2018",
            "value": 181.461,
            "value2": 135.942
          },
          {
            "category": "10-10-2018",
            "value": 187.357,
            "value2": 138.913
          },
          {
            "category": "11-10-2018",
            "value": 183.992,
            "value2": 136.056
          },
          {
            "category": "12-10-2018",
            "value": 189.994,
            "value2": 139.151
          },
          {
            "category": "13-10-2018",
            "value": 189.978,
            "value2": 139.151
          },
          {
            "category": "14-10-2018",
            "value": 189.978,
            "value2": 139.151
          },
          {
            "category": "15-10-2018",
            "value": 190.024,
            "value2": 139.875
          },
          {
            "category": "16-10-2018",
            "value": 191.235,
            "value2": 141.208
          },
          {
            "category": "17-10-2018",
            "value": 186.527,
            "value2": 138.963
          },
          {
            "category": "18-10-2018",
            "value": 186.527,
            "value2": 138.963
          },
          {
            "category": "19-10-2018",
            "value": 185.469,
            "value2": 137.136
          },
          {
            "category": "20-10-2018",
            "value": 185.487,
            "value2": 137.136
          },
          {
            "category": "21-10-2018",
            "value": 185.487,
            "value2": 137.136
          },
          {
            "category": "22-10-2018",
            "value": 183.952,
            "value2": 136.117
          },
          {
            "category": "23-10-2018",
            "value": 182.451,
            "value2": 134.803
          },
          {
            "category": "24-10-2018",
            "value": 184.663,
            "value2": 135.882
          },
          {
            "category": "25-10-2018",
            "value": 183.965,
            "value2": 134.618
          },
          {
            "category": "26-10-2018",
            "value": 182.924,
            "value2": 133.707
          },
          {
            "category": "27-10-2018",
            "value": 182.924,
            "value2": 133.707
          },
          {
            "category": "28-10-2018",
            "value": 182.924,
            "value2": 133.707
          },
          {
            "category": "29-10-2018",
            "value": 184.546,
            "value2": 136.748
          },
          {
            "category": "30-10-2018",
            "value": 184.134,
            "value2": 136.665
          },
          {
            "category": "31-10-2018",
            "value": 186.45,
            "value2": 139.025
          },
          {
            "category": "01-11-2018",
            "value": 187.947,
            "value2": 139.382
          },
          {
            "category": "02-11-2018",
            "value": 191.577,
            "value2": 141.356
          },
          {
            "category": "03-11-2018",
            "value": 191.577,
            "value2": 141.356
          },
          {
            "category": "04-11-2018",
            "value": 191.577,
            "value2": 141.356
          },
          {
            "category": "05-11-2018",
            "value": 190.545,
            "value2": 141.057
          },
          {
            "category": "06-11-2018",
            "value": 189.877,
            "value2": 140.943
          },
          {
            "category": "07-11-2018",
            "value": 191.296,
            "value2": 142.021
          },
          {
            "category": "08-11-2018",
            "value": 191.296,
            "value2": 142.021
          },
          {
            "category": "09-11-2018",
            "value": 192.499,
            "value2": 142.176
          },
          {
            "category": "10-11-2018",
            "value": 192.499,
            "value2": 142.176
          },
          {
            "category": "11-11-2018",
            "value": 192.499,
            "value2": 142.176
          },
          {
            "category": "12-11-2018",
            "value": 189.971,
            "value2": 140.75
          },
          {
            "category": "13-11-2018",
            "value": 191.364,
            "value2": 141.792
          },
          {
            "category": "14-11-2018",
            "value": 192.242,
            "value2": 141.767
          },
          {
            "category": "15-11-2018",
            "value": 194.229,
            "value2": 142.28
          },
          {
            "category": "16-11-2018",
            "value": 195.517,
            "value2": 142.847
          },
          {
            "category": "17-11-2018",
            "value": 195.517,
            "value2": 142.847
          },
          {
            "category": "18-11-2018",
            "value": 195.517,
            "value2": 142.847
          },
          {
            "category": "19-11-2018",
            "value": 196.209,
            "value2": 143.814
          },
          {
            "category": "20-11-2018",
            "value": 194.815,
            "value2": 142.436
          },
          {
            "category": "21-11-2018",
            "value": 195.414,
            "value2": 142.088
          },
          {
            "category": "22-11-2018",
            "value": 194.304,
            "value2": 141.144
          },
          {
            "category": "23-11-2018",
            "value": 194.304,
            "value2": 141.144
          },
          {
            "category": "24-11-2018",
            "value": 194.304,
            "value2": 141.144
          },
          {
            "category": "25-11-2018",
            "value": 194.304,
            "value2": 141.144
          },
          {
            "category": "26-11-2018",
            "value": 195.393,
            "value2": 142.138
          },
          {
            "category": "27-11-2018",
            "value": 195.801,
            "value2": 142.728
          },
          {
            "category": "28-11-2018",
            "value": 194.091,
            "value2": 142.861
          },
          {
            "category": "29-11-2018",
            "value": 196.915,
            "value2": 144.322
          },
          {
            "category": "30-11-2018",
            "value": 196.975,
            "value2": 144.649
          },
          {
            "category": "01-12-2018",
            "value": 196.975,
            "value2": 144.649
          },
          {
            "category": "02-12-2018",
            "value": 196.975,
            "value2": 144.649
          },
          {
            "category": "03-12-2018",
            "value": 196.939,
            "value2": 145.058
          },
          {
            "category": "04-12-2018",
            "value": 197.61,
            "value2": 144.903
          },
          {
            "category": "05-12-2018",
            "value": 195.229,
            "value2": 143.515
          },
          {
            "category": "06-12-2018",
            "value": 192.726,
            "value2": 141.158
          },
          {
            "category": "07-12-2018",
            "value": 194.589,
            "value2": 142.058
          },
          {
            "category": "08-12-2018",
            "value": 194.589,
            "value2": 142.058
          },
          {
            "category": "09-12-2018",
            "value": 194.589,
            "value2": 142.058
          },
          {
            "category": "10-12-2018",
            "value": 191.361,
            "value2": 139.388
          },
          {
            "category": "11-12-2018",
            "value": 192.544,
            "value2": 140.681
          },
          {
            "category": "12-12-2018",
            "value": 196.69,
            "value2": 143.43
          },
          {
            "category": "13-12-2018",
            "value": 198.154,
            "value2": 144.271
          },
          {
            "category": "14-12-2018",
            "value": 198.983,
            "value2": 144.483
          },
          {
            "category": "15-12-2018",
            "value": 198.983,
            "value2": 144.483
          },
          {
            "category": "16-12-2018",
            "value": 198.983,
            "value2": 144.483
          },
          {
            "category": "17-12-2018",
            "value": 199.59,
            "value2": 145.525
          },
          {
            "category": "18-12-2018",
            "value": 200.911,
            "value2": 145.897
          },
          {
            "category": "19-12-2018",
            "value": 201.678,
            "value2": 146.98
          },
          {
            "category": "20-12-2018",
            "value": 202.01,
            "value2": 146.796
          },
          {
            "category": "21-12-2018",
            "value": 199.942,
            "value2": 144.408
          },
          {
            "category": "22-12-2018",
            "value": 199.942,
            "value2": 144.408
          },
          {
            "category": "23-12-2018",
            "value": 199.942,
            "value2": 144.408
          },
          {
            "category": "24-12-2018",
            "value": 197.38,
            "value2": 143.189
          },
          {
            "category": "25-12-2018",
            "value": 197.38,
            "value2": 143.189
          },
          {
            "category": "26-12-2018",
            "value": 198.259,
            "value2": 143.738
          },
          {
            "category": "27-12-2018",
            "value": 198.365,
            "value2": 144.297
          },
          {
            "category": "28-12-2018",
            "value": 198.912,
            "value2": 145.583
          },
          {
            "category": "29-12-2018",
            "value": 198.912,
            "value2": 145.583
          },
          {
            "category": "30-12-2018",
            "value": 198.912,
            "value2": 145.583
          },
          {
            "category": "31-12-2018",
            "value": 199.781,
            "value2": 145.827
          },
          {
            "category": "01-01-2019",
            "value": 201.152,
            "value2": 146.297
          },
          {
            "category": "02-01-2019",
            "value": 198.253,
            "value2": 144.744
          },
          {
            "category": "03-01-2019",
            "value": 196.321,
            "value2": 143.324
          },
          {
            "category": "04-01-2019",
            "value": 196.702,
            "value2": 143.968
          },
          {
            "category": "05-01-2019",
            "value": 196.702,
            "value2": 143.968
          },
          {
            "category": "06-01-2019",
            "value": 196.702,
            "value2": 143.968
          },
          {
            "category": "07-01-2019",
            "value": 196.096,
            "value2": 144.312
          },
          {
            "category": "08-01-2019",
            "value": 196.01,
            "value2": 144.675
          },
          {
            "category": "09-01-2019",
            "value": 196.226,
            "value2": 145.123
          },
          {
            "category": "10-01-2019",
            "value": 196.513,
            "value2": 145.019
          },
          {
            "category": "11-01-2019",
            "value": 196.18,
            "value2": 144.649
          },
          {
            "category": "12-01-2019",
            "value": 196.18,
            "value2": 144.649
          },
          {
            "category": "13-01-2019",
            "value": 196.18,
            "value2": 144.649
          },
          {
            "category": "14-01-2019",
            "value": 194.681,
            "value2": 143.921
          },
          {
            "category": "15-01-2019",
            "value": 196.05,
            "value2": 145.567
          },
          {
            "category": "16-01-2019",
            "value": 195.797,
            "value2": 145.624
          },
          {
            "category": "17-01-2019",
            "value": 196.534,
            "value2": 145.68
          },
          {
            "category": "18-01-2019",
            "value": 194.844,
            "value2": 145.321
          },
          {
            "category": "19-01-2019",
            "value": 194.844,
            "value2": 145.321
          },
          {
            "category": "20-01-2019",
            "value": 194.844,
            "value2": 145.321
          },
          {
            "category": "21-01-2019",
            "value": 194.651,
            "value2": 145.499
          },
          {
            "category": "22-01-2019",
            "value": 195.039,
            "value2": 145.138
          },
          {
            "category": "23-01-2019",
            "value": 194.105,
            "value2": 144.229
          },
          {
            "category": "24-01-2019",
            "value": 193.858,
            "value2": 144.266
          },
          {
            "category": "25-01-2019",
            "value": 192.816,
            "value2": 143.036
          },
          {
            "category": "26-01-2019",
            "value": 192.816,
            "value2": 143.036
          },
          {
            "category": "27-01-2019",
            "value": 192.816,
            "value2": 143.036
          },
          {
            "category": "28-01-2019",
            "value": 190.184,
            "value2": 141.183
          },
          {
            "category": "29-01-2019",
            "value": 190.08,
            "value2": 141.185
          },
          {
            "category": "30-01-2019",
            "value": 190.201,
            "value2": 141.259
          },
          {
            "category": "31-01-2019",
            "value": 191.856,
            "value2": 143.287
          },
          {
            "category": "01-02-2019",
            "value": 192.751,
            "value2": 144.057
          },
          {
            "category": "02-02-2019",
            "value": 192.75,
            "value2": 144.057
          },
          {
            "category": "03-02-2019",
            "value": 192.75,
            "value2": 144.057
          },
          {
            "category": "04-02-2019",
            "value": 192.273,
            "value2": 143.821
          },
          {
            "category": "05-02-2019",
            "value": 193.245,
            "value2": 143.667
          },
          {
            "category": "06-02-2019",
            "value": 195.324,
            "value2": 144.985
          },
          {
            "category": "07-02-2019",
            "value": 196.059,
            "value2": 145.481
          },
          {
            "category": "08-02-2019",
            "value": 194.339,
            "value2": 143.713
          },
          {
            "category": "09-02-2019",
            "value": 194.339,
            "value2": 143.713
          },
          {
            "category": "10-02-2019",
            "value": 194.339,
            "value2": 143.713
          },
          {
            "category": "11-02-2019",
            "value": 193.265,
            "value2": 142.643
          },
          {
            "category": "12-02-2019",
            "value": 193.27,
            "value2": 141.997
          },
          {
            "category": "13-02-2019",
            "value": 192.13,
            "value2": 141.391
          },
          {
            "category": "14-02-2019",
            "value": 190.929,
            "value2": 141.172
          },
          {
            "category": "15-02-2019",
            "value": 190.647,
            "value2": 140.472
          },
          {
            "category": "16-02-2019",
            "value": 190.647,
            "value2": 140.472
          },
          {
            "category": "17-02-2019",
            "value": 190.647,
            "value2": 140.472
          },
          {
            "category": "18-02-2019",
            "value": 189.143,
            "value2": 139.281
          },
          {
            "category": "19-02-2019",
            "value": 189.776,
            "value2": 139.12
          },
          {
            "category": "20-02-2019",
            "value": 191.232,
            "value2": 140.728
          },
          {
            "category": "21-02-2019",
            "value": 192.957,
            "value2": 141.626
          },
          {
            "category": "22-02-2019",
            "value": 193.04,
            "value2": 141.936
          },
          {
            "category": "23-02-2019",
            "value": 193.04,
            "value2": 141.936
          },
          {
            "category": "24-02-2019",
            "value": 193.04,
            "value2": 141.936
          },
          {
            "category": "25-02-2019",
            "value": 193.576,
            "value2": 143.029
          },
          {
            "category": "26-02-2019",
            "value": 193.467,
            "value2": 142.432
          },
          {
            "category": "27-02-2019",
            "value": 192.954,
            "value2": 142.305
          },
          {
            "category": "28-02-2019",
            "value": 193.16,
            "value2": 142.576
          },
          {
            "category": "01-03-2019",
            "value": 194.929,
            "value2": 143.803
          },
          {
            "category": "02-03-2019",
            "value": 194.929,
            "value2": 143.803
          },
          {
            "category": "03-03-2019",
            "value": 194.929,
            "value2": 143.803
          },
          {
            "category": "04-03-2019",
            "value": 194.929,
            "value2": 143.803
          },
          {
            "category": "05-03-2019",
            "value": 199.118,
            "value2": 145.955
          },
          {
            "category": "06-03-2019",
            "value": 200.37,
            "value2": 146.812
          },
          {
            "category": "07-03-2019",
            "value": 200.376,
            "value2": 146.75
          },
          {
            "category": "08-03-2019",
            "value": 200.854,
            "value2": 146.547
          },
          {
            "category": "09-03-2019",
            "value": 200.854,
            "value2": 146.547
          },
          {
            "category": "10-03-2019",
            "value": 200.854,
            "value2": 146.547
          },
          {
            "category": "11-03-2019",
            "value": 204.494,
            "value2": 148.644
          },
          {
            "category": "12-03-2019",
            "value": 205.72,
            "value2": 150.167
          },
          {
            "category": "13-03-2019",
            "value": 206.483,
            "value2": 150.42
          },
          {
            "category": "14-03-2019",
            "value": 205.544,
            "value2": 150.421
          },
          {
            "category": "15-03-2019",
            "value": 207.628,
            "value2": 151.185
          },
          {
            "category": "16-03-2019",
            "value": 207.628,
            "value2": 151.185
          },
          {
            "category": "17-03-2019",
            "value": 207.628,
            "value2": 151.185
          },
          {
            "category": "18-03-2019",
            "value": 207.766,
            "value2": 151.429
          },
          {
            "category": "19-03-2019",
            "value": 207.633,
            "value2": 152.347
          },
          {
            "category": "20-03-2019",
            "value": 206.743,
            "value2": 152.106
          },
          {
            "category": "21-03-2019",
            "value": 206.743,
            "value2": 152.106
          },
          {
            "category": "22-03-2019",
            "value": 206.007,
            "value2": 151.279
          },
          {
            "category": "23-03-2019",
            "value": 206.007,
            "value2": 151.279
          },
          {
            "category": "24-03-2019",
            "value": 206.007,
            "value2": 151.279
          },
          {
            "category": "25-03-2019",
            "value": 204.362,
            "value2": 149.927
          },
          {
            "category": "26-03-2019",
            "value": 206.502,
            "value2": 151.444
          },
          {
            "category": "27-03-2019",
            "value": 205.401,
            "value2": 151.359
          },
          {
            "category": "28-03-2019",
            "value": 207.473,
            "value2": 152.953
          },
          {
            "category": "29-03-2019",
            "value": 209.337,
            "value2": 153.845
          },
          {
            "category": "30-03-2019",
            "value": 209.338,
            "value2": 153.845
          },
          {
            "category": "31-03-2019",
            "value": 208.542,
            "value2": 153.845
          },
          {
            "category": "01-04-2019",
            "value": 208.511,
            "value2": 154.542
          },
          {
            "category": "02-04-2019",
            "value": 208.087,
            "value2": 154.801
          },
          {
            "category": "03-04-2019",
            "value": 206.925,
            "value2": 153.927
          },
          {
            "category": "04-04-2019",
            "value": 206.204,
            "value2": 153.407
          },
          {
            "category": "05-04-2019",
            "value": 207.605,
            "value2": 154.277
          },
          {
            "category": "06-04-2019",
            "value": 207.605,
            "value2": 154.277
          },
          {
            "category": "07-04-2019",
            "value": 207.605,
            "value2": 154.277
          },
          {
            "category": "08-04-2019",
            "value": 206.596,
            "value2": 153.482
          },
          {
            "category": "09-04-2019",
            "value": 207.636,
            "value2": 154.176
          },
          {
            "category": "10-04-2019",
            "value": 206.842,
            "value2": 153.254
          },
          {
            "category": "11-04-2019",
            "value": 208,
            "value2": 153.417
          },
          {
            "category": "12-04-2019",
            "value": 209.208,
            "value2": 154.051
          },
          {
            "category": "13-04-2019",
            "value": 209.207,
            "value2": 154.051
          },
          {
            "category": "14-04-2019",
            "value": 209.207,
            "value2": 154.051
          },
          {
            "category": "15-04-2019",
            "value": 210.133,
            "value2": 154.698
          },
          {
            "category": "16-04-2019",
            "value": 212.132,
            "value2": 155.829
          },
          {
            "category": "17-04-2019",
            "value": 212.132,
            "value2": 155.829
          },
          {
            "category": "18-04-2019",
            "value": 211.616,
            "value2": 155.03
          },
          {
            "category": "19-04-2019",
            "value": 211.616,
            "value2": 155.03
          },
          {
            "category": "20-04-2019",
            "value": 211.616,
            "value2": 155.03
          },
          {
            "category": "21-04-2019",
            "value": 211.616,
            "value2": 155.03
          },
          {
            "category": "22-04-2019",
            "value": 207.828,
            "value2": 152.874
          },
          {
            "category": "23-04-2019",
            "value": 207.322,
            "value2": 152.679
          },
          {
            "category": "24-04-2019",
            "value": 209.053,
            "value2": 154.289
          },
          {
            "category": "25-04-2019",
            "value": 209.01,
            "value2": 153.387
          },
          {
            "category": "26-04-2019",
            "value": 210.735,
            "value2": 154.196
          },
          {
            "category": "27-04-2019",
            "value": 210.735,
            "value2": 154.196
          },
          {
            "category": "28-04-2019",
            "value": 210.735,
            "value2": 154.196
          },
          {
            "category": "29-04-2019",
            "value": 210.735,
            "value2": 154.196
          },
          {
            "category": "30-04-2019",
            "value": 210.318,
            "value2": 153.747
          },
          {
            "category": "01-05-2019",
            "value": 210.318,
            "value2": 153.747
          },
          {
            "category": "02-05-2019",
            "value": 211.406,
            "value2": 153.328
          },
          {
            "category": "03-05-2019",
            "value": 211.706,
            "value2": 153.121
          },
          {
            "category": "04-05-2019",
            "value": 211.689,
            "value2": 153.121
          },
          {
            "category": "05-05-2019",
            "value": 211.689,
            "value2": 153.121
          },
          {
            "category": "06-05-2019",
            "value": 210.418,
            "value2": 151.674
          },
          {
            "category": "07-05-2019",
            "value": 209.094,
            "value2": 150.398
          },
          {
            "category": "08-05-2019",
            "value": 207.66,
            "value2": 148.615
          },
          {
            "category": "09-05-2019",
            "value": 207.386,
            "value2": 147.971
          },
          {
            "category": "10-05-2019",
            "value": 207.196,
            "value2": 147.802
          },
          {
            "category": "11-05-2019",
            "value": 207.196,
            "value2": 147.802
          },
          {
            "category": "12-05-2019",
            "value": 207.196,
            "value2": 147.802
          },
          {
            "category": "13-05-2019",
            "value": 204.364,
            "value2": 145.754
          },
          {
            "category": "14-05-2019",
            "value": 204.464,
            "value2": 146.587
          },
          {
            "category": "15-05-2019",
            "value": 204.653,
            "value2": 145.776
          },
          {
            "category": "16-05-2019",
            "value": 205.764,
            "value2": 146.769
          },
          {
            "category": "17-05-2019",
            "value": 210.118,
            "value2": 148.487
          },
          {
            "category": "18-05-2019",
            "value": 210.118,
            "value2": 148.487
          },
          {
            "category": "19-05-2019",
            "value": 210.118,
            "value2": 148.487
          },
          {
            "category": "20-05-2019",
            "value": 216.927,
            "value2": 153.893
          },
          {
            "category": "21-05-2019",
            "value": 214.908,
            "value2": 152.406
          },
          {
            "category": "22-05-2019",
            "value": 215.05,
            "value2": 152.893
          },
          {
            "category": "23-05-2019",
            "value": 213.996,
            "value2": 152.185
          },
          {
            "category": "24-05-2019",
            "value": 217.811,
            "value2": 154.894
          },
          {
            "category": "25-05-2019",
            "value": 217.811,
            "value2": 154.894
          },
          {
            "category": "26-05-2019",
            "value": 217.811,
            "value2": 154.894
          },
          {
            "category": "27-05-2019",
            "value": 220.27,
            "value2": 156.217
          },
          {
            "category": "28-05-2019",
            "value": 219.828,
            "value2": 156.507
          },
          {
            "category": "29-05-2019",
            "value": 218.51,
            "value2": 155.462
          },
          {
            "category": "30-05-2019",
            "value": 220.084,
            "value2": 156.429
          },
          {
            "category": "31-05-2019",
            "value": 219.581,
            "value2": 156.17
          },
          {
            "category": "01-06-2019",
            "value": 219.565,
            "value2": 156.17
          },
          {
            "category": "02-06-2019",
            "value": 219.565,
            "value2": 156.17
          },
          {
            "category": "03-06-2019",
            "value": 221.329,
            "value2": 158.064
          },
          {
            "category": "04-06-2019",
            "value": 220.863,
            "value2": 157.419
          },
          {
            "category": "05-06-2019",
            "value": 220.863,
            "value2": 157.419
          },
          {
            "category": "06-06-2019",
            "value": 217.696,
            "value2": 155.014
          },
          {
            "category": "07-06-2019",
            "value": 218.639,
            "value2": 155.191
          },
          {
            "category": "08-06-2019",
            "value": 218.639,
            "value2": 155.191
          },
          {
            "category": "09-06-2019",
            "value": 218.639,
            "value2": 155.191
          },
          {
            "category": "10-06-2019",
            "value": 218.817,
            "value2": 155.648
          },
          {
            "category": "11-06-2019",
            "value": 219.47,
            "value2": 156.311
          },
          {
            "category": "12-06-2019",
            "value": 217.337,
            "value2": 155.429
          },
          {
            "category": "13-06-2019",
            "value": 218.671,
            "value2": 155.435
          },
          {
            "category": "14-06-2019",
            "value": 217.146,
            "value2": 154.204
          },
          {
            "category": "15-06-2019",
            "value": 217.146,
            "value2": 154.204
          },
          {
            "category": "16-06-2019",
            "value": 217.146,
            "value2": 154.204
          },
          {
            "category": "17-06-2019",
            "value": 213.934,
            "value2": 152.208
          },
          {
            "category": "18-06-2019",
            "value": 214.498,
            "value2": 152.427
          },
          {
            "category": "19-06-2019",
            "value": 214.378,
            "value2": 152.042
          },
          {
            "category": "20-06-2019",
            "value": 217.081,
            "value2": 154.034
          },
          {
            "category": "21-06-2019",
            "value": 216.035,
            "value2": 152.979
          },
          {
            "category": "22-06-2019",
            "value": 216.035,
            "value2": 152.979
          },
          {
            "category": "23-06-2019",
            "value": 216.035,
            "value2": 152.979
          },
          {
            "category": "24-06-2019",
            "value": 215.652,
            "value2": 152.701
          },
          {
            "category": "25-06-2019",
            "value": 216.065,
            "value2": 153.797
          },
          {
            "category": "26-06-2019",
            "value": 217.59,
            "value2": 154.59
          },
          {
            "category": "27-06-2019",
            "value": 218.337,
            "value2": 154.773
          },
          {
            "category": "28-06-2019",
            "value": 218.417,
            "value2": 154.186
          },
          {
            "category": "29-06-2019",
            "value": 218.417,
            "value2": 154.186
          },
          {
            "category": "30-06-2019",
            "value": 217.935,
            "value2": 154.186
          },
          {
            "category": "01-07-2019",
            "value": 218.606,
            "value2": 155.081
          },
          {
            "category": "02-07-2019",
            "value": 218.833,
            "value2": 155.58
          },
          {
            "category": "03-07-2019",
            "value": 219.234,
            "value2": 155.788
          },
          {
            "category": "04-07-2019",
            "value": 220.035,
            "value2": 156.11
          },
          {
            "category": "05-07-2019",
            "value": 217.551,
            "value2": 154.221
          },
          {
            "category": "06-07-2019",
            "value": 217.551,
            "value2": 154.221
          },
          {
            "category": "07-07-2019",
            "value": 217.551,
            "value2": 154.221
          },
          {
            "category": "08-07-2019",
            "value": 211.048,
            "value2": 150.89
          },
          {
            "category": "09-07-2019",
            "value": 211.251,
            "value2": 150.992
          },
          {
            "category": "10-07-2019",
            "value": 209.829,
            "value2": 150.104
          },
          {
            "category": "11-07-2019",
            "value": 211.783,
            "value2": 151.193
          },
          {
            "category": "12-07-2019",
            "value": 211.967,
            "value2": 151.057
          },
          {
            "category": "13-07-2019",
            "value": 211.967,
            "value2": 151.057
          },
          {
            "category": "14-07-2019",
            "value": 211.967,
            "value2": 151.057
          },
          {
            "category": "15-07-2019",
            "value": 212.496,
            "value2": 151.265
          },
          {
            "category": "16-07-2019",
            "value": 213.864,
            "value2": 152.298
          },
          {
            "category": "17-07-2019",
            "value": 214.02,
            "value2": 152.515
          },
          {
            "category": "18-07-2019",
            "value": 212.992,
            "value2": 151.127
          },
          {
            "category": "19-07-2019",
            "value": 209.292,
            "value2": 148.843
          },
          {
            "category": "20-07-2019",
            "value": 209.292,
            "value2": 148.843
          },
          {
            "category": "21-07-2019",
            "value": 209.292,
            "value2": 148.843
          },
          {
            "category": "22-07-2019",
            "value": 207.168,
            "value2": 147.91
          },
          {
            "category": "23-07-2019",
            "value": 208.362,
            "value2": 147.68
          },
          {
            "category": "24-07-2019",
            "value": 206.433,
            "value2": 146.571
          },
          {
            "category": "25-07-2019",
            "value": 205.833,
            "value2": 146.614
          },
          {
            "category": "26-07-2019",
            "value": 208.777,
            "value2": 147.126
          },
          {
            "category": "27-07-2019",
            "value": 208.777,
            "value2": 147.126
          },
          {
            "category": "28-07-2019",
            "value": 208.777,
            "value2": 147.126
          },
          {
            "category": "29-07-2019",
            "value": 206.75,
            "value2": 145.827
          },
          {
            "category": "30-07-2019",
            "value": 204.708,
            "value2": 144.175
          },
          {
            "category": "31-07-2019",
            "value": 206.216,
            "value2": 144.832
          },
          {
            "category": "01-08-2019",
            "value": 204.432,
            "value2": 143.108
          },
          {
            "category": "02-08-2019",
            "value": 205.063,
            "value2": 143.288
          },
          {
            "category": "03-08-2019",
            "value": 205.063,
            "value2": 143.288
          },
          {
            "category": "04-08-2019",
            "value": 205.063,
            "value2": 143.288
          },
          {
            "category": "05-08-2019",
            "value": 201.711,
            "value2": 141.548
          },
          {
            "category": "06-08-2019",
            "value": 205.474,
            "value2": 142.916
          },
          {
            "category": "07-08-2019",
            "value": 204.047,
            "value2": 141.913
          },
          {
            "category": "08-08-2019",
            "value": 207.477,
            "value2": 143.984
          },
          {
            "category": "09-08-2019",
            "value": 210.054,
            "value2": 145.039
          },
          {
            "category": "10-08-2019",
            "value": 210.054,
            "value2": 145.039
          },
          {
            "category": "11-08-2019",
            "value": 210.054,
            "value2": 145.039
          },
          {
            "category": "12-08-2019",
            "value": 210.054,
            "value2": 145.039
          },
          {
            "category": "13-08-2019",
            "value": 204.877,
            "value2": 142.522
          },
          {
            "category": "14-08-2019",
            "value": 206.947,
            "value2": 143.862
          },
          {
            "category": "15-08-2019",
            "value": 206.947,
            "value2": 143.862
          },
          {
            "category": "16-08-2019",
            "value": 208.427,
            "value2": 144.105
          },
          {
            "category": "17-08-2019",
            "value": 208.427,
            "value2": 144.105
          },
          {
            "category": "18-08-2019",
            "value": 208.427,
            "value2": 144.105
          },
          {
            "category": "19-08-2019",
            "value": 207.839,
            "value2": 144.318
          },
          {
            "category": "20-08-2019",
            "value": 207.635,
            "value2": 143.735
          },
          {
            "category": "21-08-2019",
            "value": 206.694,
            "value2": 142.321
          },
          {
            "category": "22-08-2019",
            "value": 203.353,
            "value2": 139.998
          },
          {
            "category": "23-08-2019",
            "value": 203.597,
            "value2": 141.13
          },
          {
            "category": "24-08-2019",
            "value": 203.597,
            "value2": 141.13
          },
          {
            "category": "25-08-2019",
            "value": 203.597,
            "value2": 141.13
          },
          {
            "category": "26-08-2019",
            "value": 208.302,
            "value2": 143.928
          },
          {
            "category": "27-08-2019",
            "value": 210.585,
            "value2": 144.875
          },
          {
            "category": "28-08-2019",
            "value": 209.014,
            "value2": 144.036
          },
          {
            "category": "29-08-2019",
            "value": 207.551,
            "value2": 143.049
          },
          {
            "category": "30-08-2019",
            "value": 208.985,
            "value2": 144.183
          },
          {
            "category": "31-08-2019",
            "value": 208.511,
            "value2": 144.183
          },
          {
            "category": "01-09-2019",
            "value": 208.51,
            "value2": 144.183
          },
          {
            "category": "02-09-2019",
            "value": 208.509,
            "value2": 144.183
          },
          {
            "category": "03-09-2019",
            "value": 205.207,
            "value2": 141.366
          },
          {
            "category": "04-09-2019",
            "value": 206.007,
            "value2": 141.842
          },
          {
            "category": "05-09-2019",
            "value": 206.407,
            "value2": 142.044
          },
          {
            "category": "06-09-2019",
            "value": 207.999,
            "value2": 143.204
          },
          {
            "category": "07-09-2019",
            "value": 207.999,
            "value2": 143.204
          },
          {
            "category": "08-09-2019",
            "value": 207.999,
            "value2": 143.204
          },
          {
            "category": "09-09-2019",
            "value": 209.335,
            "value2": 144.05
          },
          {
            "category": "10-09-2019",
            "value": 209.335,
            "value2": 144.05
          },
          {
            "category": "11-09-2019",
            "value": 210.641,
            "value2": 144.748
          },
          {
            "category": "12-09-2019",
            "value": 210.326,
            "value2": 144.275
          },
          {
            "category": "13-09-2019",
            "value": 212.254,
            "value2": 145.401
          },
          {
            "category": "14-09-2019",
            "value": 212.255,
            "value2": 145.401
          },
          {
            "category": "15-09-2019",
            "value": 212.256,
            "value2": 145.401
          },
          {
            "category": "16-09-2019",
            "value": 210.51,
            "value2": 144.715
          },
          {
            "category": "17-09-2019",
            "value": 206.416,
            "value2": 142.229
          },
          {
            "category": "18-09-2019",
            "value": 207.767,
            "value2": 142.635
          },
          {
            "category": "19-09-2019",
            "value": 205.659,
            "value2": 140.844
          },
          {
            "category": "20-09-2019",
            "value": 218.066,
            "value2": 148.369
          },
          {
            "category": "21-09-2019",
            "value": 218.066,
            "value2": 148.369
          },
          {
            "category": "22-09-2019",
            "value": 218.067,
            "value2": 148.369
          },
          {
            "category": "23-09-2019",
            "value": 225.74,
            "value2": 152.441
          },
          {
            "category": "24-09-2019",
            "value": 225.91,
            "value2": 152.236
          },
          {
            "category": "25-09-2019",
            "value": 223.434,
            "value2": 150.155
          },
          {
            "category": "26-09-2019",
            "value": 226.678,
            "value2": 151.796
          },
          {
            "category": "27-09-2019",
            "value": 227.22,
            "value2": 150.977
          },
          {
            "category": "28-09-2019",
            "value": 227.22,
            "value2": 150.977
          },
          {
            "category": "29-09-2019",
            "value": 227.221,
            "value2": 150.977
          },
          {
            "category": "30-09-2019",
            "value": 225.92,
            "value2": 150.068
          },
          {
            "category": "01-10-2019",
            "value": 225.815,
            "value2": 148.399
          },
          {
            "category": "02-10-2019",
            "value": 225.815,
            "value2": 148.399
          },
          {
            "category": "03-10-2019",
            "value": 225.58,
            "value2": 147.798
          },
          {
            "category": "04-10-2019",
            "value": 221.127,
            "value2": 146.071
          },
          {
            "category": "05-10-2019",
            "value": 221.127,
            "value2": 146.071
          },
          {
            "category": "06-10-2019",
            "value": 221.128,
            "value2": 146.071
          },
          {
            "category": "07-10-2019",
            "value": 219.569,
            "value2": 145.294
          },
          {
            "category": "08-10-2019",
            "value": 219.569,
            "value2": 145.294
          },
          {
            "category": "09-10-2019",
            "value": 223.167,
            "value2": 147.493
          },
          {
            "category": "10-10-2019",
            "value": 220.558,
            "value2": 146.428
          },
          {
            "category": "11-10-2019",
            "value": 221.368,
            "value2": 147.291
          },
          {
            "category": "12-10-2019",
            "value": 221.368,
            "value2": 147.291
          },
          {
            "category": "13-10-2019",
            "value": 221.368,
            "value2": 147.291
          },
          {
            "category": "14-10-2019",
            "value": 221.812,
            "value2": 147.738
          },
          {
            "category": "15-10-2019",
            "value": 223.709,
            "value2": 148.765
          },
          {
            "category": "16-10-2019",
            "value": 225.199,
            "value2": 149.275
          },
          {
            "category": "17-10-2019",
            "value": 227.283,
            "value2": 151.101
          },
          {
            "category": "18-10-2019",
            "value": 228.374,
            "value2": 152.527
          },
          {
            "category": "19-10-2019",
            "value": 228.374,
            "value2": 152.527
          },
          {
            "category": "20-10-2019",
            "value": 228.374,
            "value2": 152.527
          },
          {
            "category": "21-10-2019",
            "value": 228.374,
            "value2": 152.527
          },
          {
            "category": "22-10-2019",
            "value": 230.413,
            "value2": 151.968
          },
          {
            "category": "23-10-2019",
            "value": 230.671,
            "value2": 152.26
          },
          {
            "category": "24-10-2019",
            "value": 230.313,
            "value2": 151.875
          },
          {
            "category": "25-10-2019",
            "value": 230.208,
            "value2": 151.764
          },
          {
            "category": "26-10-2019",
            "value": 230.208,
            "value2": 151.764
          },
          {
            "category": "27-10-2019",
            "value": 231.227,
            "value2": 152.577
          },
          {
            "category": "28-10-2019",
            "value": 231.227,
            "value2": 152.577
          },
          {
            "category": "29-10-2019",
            "value": 233.331,
            "value2": 154.523
          },
          {
            "category": "30-10-2019",
            "value": 234.062,
            "value2": 155.323
          },
          {
            "category": "31-10-2019",
            "value": 233.003,
            "value2": 156.03
          },
          {
            "category": "01-11-2019",
            "value": 233.637,
            "value2": 156.262
          },
          {
            "category": "02-11-2019",
            "value": 233.637,
            "value2": 156.262
          },
          {
            "category": "03-11-2019",
            "value": 233.637,
            "value2": 156.262
          },
          {
            "category": "04-11-2019",
            "value": 233.961,
            "value2": 156.696
          },
          {
            "category": "05-11-2019",
            "value": 233.222,
            "value2": 156.107
          },
          {
            "category": "06-11-2019",
            "value": 234.843,
            "value2": 156.554
          },
          {
            "category": "07-11-2019",
            "value": 236.253,
            "value2": 157.261
          },
          {
            "category": "08-11-2019",
            "value": 234.533,
            "value2": 155.895
          },
          {
            "category": "09-11-2019",
            "value": 234.533,
            "value2": 155.895
          },
          {
            "category": "10-11-2019",
            "value": 234.533,
            "value2": 155.895
          },
          {
            "category": "11-11-2019",
            "value": 236.094,
            "value2": 156.089
          },
          {
            "category": "12-11-2019",
            "value": 236.094,
            "value2": 156.089
          },
          {
            "category": "13-11-2019",
            "value": 236.095,
            "value2": 155.009
          },
          {
            "category": "14-11-2019",
            "value": 238.179,
            "value2": 155.302
          },
          {
            "category": "15-11-2019",
            "value": 237.022,
            "value2": 155.539
          },
          {
            "category": "16-11-2019",
            "value": 237.022,
            "value2": 155.539
          },
          {
            "category": "17-11-2019",
            "value": 237.022,
            "value2": 155.539
          },
          {
            "category": "18-11-2019",
            "value": 237.057,
            "value2": 155.591
          },
          {
            "category": "19-11-2019",
            "value": 237.529,
            "value2": 156.142
          },
          {
            "category": "20-11-2019",
            "value": 239.201,
            "value2": 156.821
          },
          {
            "category": "21-11-2019",
            "value": 238.786,
            "value2": 156.31
          },
          {
            "category": "22-11-2019",
            "value": 237.458,
            "value2": 155.748
          },
          {
            "category": "23-11-2019",
            "value": 237.458,
            "value2": 155.748
          },
          {
            "category": "24-11-2019",
            "value": 237.458,
            "value2": 155.748
          },
          {
            "category": "25-11-2019",
            "value": 239.864,
            "value2": 157.768
          },
          {
            "category": "26-11-2019",
            "value": 239.681,
            "value2": 157.137
          },
          {
            "category": "27-11-2019",
            "value": 240.076,
            "value2": 157.988
          },
          {
            "category": "28-11-2019",
            "value": 240.339,
            "value2": 158.839
          },
          {
            "category": "29-11-2019",
            "value": 239.582,
            "value2": 157.901
          },
          {
            "category": "30-11-2019",
            "value": 239.059,
            "value2": 157.901
          },
          {
            "category": "01-12-2019",
            "value": 239.059,
            "value2": 157.901
          },
          {
            "category": "02-12-2019",
            "value": 237.974,
            "value2": 157.675
          },
          {
            "category": "03-12-2019",
            "value": 238.03,
            "value2": 156.782
          },
          {
            "category": "04-12-2019",
            "value": 237.93,
            "value2": 157.432
          },
          {
            "category": "05-12-2019",
            "value": 236.833,
            "value2": 156.998
          },
          {
            "category": "06-12-2019",
            "value": 235.416,
            "value2": 155.58
          },
          {
            "category": "07-12-2019",
            "value": 235.416,
            "value2": 155.58
          },
          {
            "category": "08-12-2019",
            "value": 235.416,
            "value2": 155.58
          },
          {
            "category": "09-12-2019",
            "value": 235.749,
            "value2": 155.64
          },
          {
            "category": "10-12-2019",
            "value": 234.892,
            "value2": 154.409
          },
          {
            "category": "11-12-2019",
            "value": 236.46,
            "value2": 155.058
          },
          {
            "category": "12-12-2019",
            "value": 238.253,
            "value2": 155.916
          },
          {
            "category": "13-12-2019",
            "value": 239.662,
            "value2": 157.443
          },
          {
            "category": "14-12-2019",
            "value": 239.662,
            "value2": 157.443
          },
          {
            "category": "15-12-2019",
            "value": 239.662,
            "value2": 157.443
          },
          {
            "category": "16-12-2019",
            "value": 239.329,
            "value2": 156.946
          },
          {
            "category": "17-12-2019",
            "value": 241.618,
            "value2": 158.262
          },
          {
            "category": "18-12-2019",
            "value": 243.377,
            "value2": 158.776
          },
          {
            "category": "19-12-2019",
            "value": 243.565,
            "value2": 159.152
          },
          {
            "category": "20-12-2019",
            "value": 243.853,
            "value2": 159.422
          },
          {
            "category": "21-12-2019",
            "value": 243.853,
            "value2": 159.422
          },
          {
            "category": "22-12-2019",
            "value": 243.853,
            "value2": 159.422
          },
          {
            "category": "23-12-2019",
            "value": 244.395,
            "value2": 159.316
          },
          {
            "category": "24-12-2019",
            "value": 243.389,
            "value2": 158.84
          },
          {
            "category": "25-12-2019",
            "value": 243.389,
            "value2": 158.84
          },
          {
            "category": "26-12-2019",
            "value": 243.046,
            "value2": 158.054
          },
          {
            "category": "27-12-2019",
            "value": 244.221,
            "value2": 159.524
          },
          {
            "category": "28-12-2019",
            "value": 244.22,
            "value2": 159.524
          },
          {
            "category": "29-12-2019",
            "value": 244.22,
            "value2": 159.524
          },
          {
            "category": "30-12-2019",
            "value": 244.956,
            "value2": 159.744
          },
          {
            "category": "31-12-2019",
            "value": 242.837,
            "value2": 158.925
          },
          {
            "category": "01-01-2020",
            "value": 242.904,
            "value2": 159.199
          },
          {
            "category": "02-01-2020",
            "value": 244.581,
            "value2": 160.662
          },
          {
            "category": "03-01-2020",
            "value": 243.217,
            "value2": 160.042
          },
          {
            "category": "04-01-2020",
            "value": 243.216,
            "value2": 160.042
          },
          {
            "category": "05-01-2020",
            "value": 243.216,
            "value2": 160.042
          },
          {
            "category": "06-01-2020",
            "value": 239.146,
            "value2": 156.887
          },
          {
            "category": "07-01-2020",
            "value": 240.547,
            "value2": 157.783
          },
          {
            "category": "08-01-2020",
            "value": 240.758,
            "value2": 157.626
          },
          {
            "category": "09-01-2020",
            "value": 242.748,
            "value2": 160.063
          },
          {
            "category": "10-01-2020",
            "value": 243.165,
            "value2": 160.629
          },
          {
            "category": "11-01-2020",
            "value": 243.165,
            "value2": 160.629
          },
          {
            "category": "12-01-2020",
            "value": 243.165,
            "value2": 160.629
          },
          {
            "category": "13-01-2020",
            "value": 243.94,
            "value2": 161.712
          },
          {
            "category": "14-01-2020",
            "value": 244.171,
            "value2": 162.266
          },
          {
            "category": "15-01-2020",
            "value": 245.016,
            "value2": 162.449
          },
          {
            "category": "16-01-2020",
            "value": 245.757,
            "value2": 162.794
          },
          {
            "category": "17-01-2020",
            "value": 245.018,
            "value2": 162.938
          },
          {
            "category": "18-01-2020",
            "value": 245.018,
            "value2": 162.938
          },
          {
            "category": "19-01-2020",
            "value": 245.018,
            "value2": 162.938
          },
          {
            "category": "20-01-2020",
            "value": 242.889,
            "value2": 161.501
          },
          {
            "category": "21-01-2020",
            "value": 242.612,
            "value2": 160.886
          },
          {
            "category": "22-01-2020",
            "value": 241.329,
            "value2": 160.236
          },
          {
            "category": "23-01-2020",
            "value": 243.773,
            "value2": 161.476
          },
          {
            "category": "24-01-2020",
            "value": 245.867,
            "value2": 162.455
          },
          {
            "category": "25-01-2020",
            "value": 245.867,
            "value2": 162.455
          },
          {
            "category": "26-01-2020",
            "value": 245.867,
            "value2": 162.455
          },
          {
            "category": "27-01-2020",
            "value": 244.051,
            "value2": 161.098
          },
          {
            "category": "28-01-2020",
            "value": 244.169,
            "value2": 160.358
          },
          {
            "category": "29-01-2020",
            "value": 244.607,
            "value2": 161.223
          },
          {
            "category": "30-01-2020",
            "value": 243.433,
            "value2": 159.754
          },
          {
            "category": "31-01-2020",
            "value": 242.493,
            "value2": 158.768
          },
          {
            "category": "01-02-2020",
            "value": 233.124,
            "value2": 154.695
          },
          {
            "category": "02-02-2020",
            "value": 233.124,
            "value2": 154.695
          },
          {
            "category": "03-02-2020",
            "value": 235.396,
            "value2": 155.422
          },
          {
            "category": "04-02-2020",
            "value": 239.259,
            "value2": 158.629
          },
          {
            "category": "05-02-2020",
            "value": 241.374,
            "value2": 160.225
          },
          {
            "category": "06-02-2020",
            "value": 242.731,
            "value2": 161.065
          },
          {
            "category": "07-02-2020",
            "value": 242.539,
            "value2": 160.981
          },
          {
            "category": "08-02-2020",
            "value": 242.539,
            "value2": 160.981
          },
          {
            "category": "09-02-2020",
            "value": 242.539,
            "value2": 160.981
          },
          {
            "category": "10-02-2020",
            "value": 240.308,
            "value2": 160.102
          },
          {
            "category": "11-02-2020",
            "value": 241.156,
            "value2": 160.754
          },
          {
            "category": "12-02-2020",
            "value": 242.53,
            "value2": 161.568
          },
          {
            "category": "13-02-2020",
            "value": 242.525,
            "value2": 161.269
          },
          {
            "category": "14-02-2020",
            "value": 241.347,
            "value2": 160.325
          },
          {
            "category": "15-02-2020",
            "value": 241.347,
            "value2": 160.325
          },
          {
            "category": "16-02-2020",
            "value": 241.347,
            "value2": 160.325
          },
          {
            "category": "17-02-2020",
            "value": 238.806,
            "value2": 159.239
          },
          {
            "category": "18-02-2020",
            "value": 238.162,
            "value2": 158.551
          },
          {
            "category": "19-02-2020",
            "value": 241.374,
            "value2": 160.526
          },
          {
            "category": "20-02-2020",
            "value": 242.588,
            "value2": 160.317
          },
          {
            "category": "21-02-2020",
            "value": 242.588,
            "value2": 160.317
          },
          {
            "category": "22-02-2020",
            "value": 242.588,
            "value2": 160.317
          },
          {
            "category": "23-02-2020",
            "value": 242.588,
            "value2": 160.317
          },
          {
            "category": "24-02-2020",
            "value": 239.694,
            "value2": 157.094
          },
          {
            "category": "25-02-2020",
            "value": 238.875,
            "value2": 156.589
          },
          {
            "category": "26-02-2020",
            "value": 236.405,
            "value2": 154.947
          },
          {
            "category": "27-02-2020",
            "value": 236.496,
            "value2": 154.206
          },
          {
            "category": "28-02-2020",
            "value": 229.247,
            "value2": 148.581
          },
          {
            "category": "29-02-2020",
            "value": 228.739,
            "value2": 148.581
          },
          {
            "category": "01-03-2020",
            "value": 228.739,
            "value2": 148.581
          },
          {
            "category": "02-03-2020",
            "value": 229.093,
            "value2": 147.805
          },
          {
            "category": "03-03-2020",
            "value": 232.766,
            "value2": 150.24
          },
          {
            "category": "04-03-2020",
            "value": 230.228,
            "value2": 149.156
          },
          {
            "category": "05-03-2020",
            "value": 231.511,
            "value2": 149.485
          },
          {
            "category": "06-03-2020",
            "value": 224.712,
            "value2": 145.925
          },
          {
            "category": "07-03-2020",
            "value": 224.712,
            "value2": 145.925
          },
          {
            "category": "08-03-2020",
            "value": 224.712,
            "value2": 145.925
          },
          {
            "category": "09-03-2020",
            "value": 217.314,
            "value2": 138.94
          },
          {
            "category": "10-03-2020",
            "value": 217.314,
            "value2": 138.94
          },
          {
            "category": "11-03-2020",
            "value": 216.153,
            "value2": 138.786
          },
          {
            "category": "12-03-2020",
            "value": 198.656,
            "value2": 127.253
          },
          {
            "category": "13-03-2020",
            "value": 205.123,
            "value2": 131.569
          },
          {
            "category": "14-03-2020",
            "value": 205.111,
            "value2": 131.569
          },
          {
            "category": "15-03-2020",
            "value": 205.111,
            "value2": 131.569
          },
          {
            "category": "16-03-2020",
            "value": 193.314,
            "value2": 122.284
          },
          {
            "category": "17-03-2020",
            "value": 187.615,
            "value2": 119.557
          },
          {
            "category": "18-03-2020",
            "value": 174.952,
            "value2": 113.054
          },
          {
            "category": "19-03-2020",
            "value": 170.433,
            "value2": 109.768
          },
          {
            "category": "20-03-2020",
            "value": 176.315,
            "value2": 115.635
          },
          {
            "category": "21-03-2020",
            "value": 176.315,
            "value2": 115.635
          },
          {
            "category": "22-03-2020",
            "value": 176.315,
            "value2": 115.635
          },
          {
            "category": "23-03-2020",
            "value": 150.833,
            "value2": 100.845
          },
          {
            "category": "24-03-2020",
            "value": 153.197,
            "value2": 102.951
          },
          {
            "category": "25-03-2020",
            "value": 162.984,
            "value2": 108.729
          },
          {
            "category": "26-03-2020",
            "value": 172.88,
            "value2": 113.648
          },
          {
            "category": "27-03-2020",
            "value": 170.897,
            "value2": 113.223
          },
          {
            "category": "28-03-2020",
            "value": 170.897,
            "value2": 113.223
          },
          {
            "category": "29-03-2020",
            "value": 170.897,
            "value2": 113.223
          },
          {
            "category": "30-03-2020",
            "value": 163.82,
            "value2": 109.231
          },
          {
            "category": "31-03-2020",
            "value": 167.823,
            "value2": 113.141
          },
          {
            "category": "01-04-2020",
            "value": 162.985,
            "value2": 109.375
          },
          {
            "category": "02-04-2020",
            "value": 162.984,
            "value2": 109.375
          },
          {
            "category": "03-04-2020",
            "value": 158.381,
            "value2": 107.323
          },
          {
            "category": "04-04-2020",
            "value": 158.381,
            "value2": 107.323
          },
          {
            "category": "05-04-2020",
            "value": 158.381,
            "value2": 107.323
          },
          {
            "category": "06-04-2020",
            "value": 158.381,
            "value2": 107.323
          },
          {
            "category": "07-04-2020",
            "value": 170.989,
            "value2": 115.674
          },
          {
            "category": "08-04-2020",
            "value": 171.818,
            "value2": 115.853
          },
          {
            "category": "09-04-2020",
            "value": 177.594,
            "value2": 120.349
          },
          {
            "category": "10-04-2020",
            "value": 177.594,
            "value2": 120.349
          },
          {
            "category": "11-04-2020",
            "value": 177.594,
            "value2": 120.349
          },
          {
            "category": "12-04-2020",
            "value": 177.594,
            "value2": 120.349
          },
          {
            "category": "13-04-2020",
            "value": 175.865,
            "value2": 119.017
          },
          {
            "category": "14-04-2020",
            "value": 175.865,
            "value2": 119.017
          },
          {
            "category": "15-04-2020",
            "value": 176.424,
            "value2": 118.79
          },
          {
            "category": "16-04-2020",
            "value": 179.817,
            "value2": 119.968
          },
          {
            "category": "17-04-2020",
            "value": 185.493,
            "value2": 123.218
          },
          {
            "category": "18-04-2020",
            "value": 185.493,
            "value2": 123.218
          },
          {
            "category": "19-04-2020",
            "value": 185.493,
            "value2": 123.218
          },
          {
            "category": "20-04-2020",
            "value": 186.415,
            "value2": 123.224
          },
          {
            "category": "21-04-2020",
            "value": 180.987,
            "value2": 119.61
          },
          {
            "category": "22-04-2020",
            "value": 182.846,
            "value2": 121.9
          },
          {
            "category": "23-04-2020",
            "value": 183.918,
            "value2": 123.389
          },
          {
            "category": "24-04-2020",
            "value": 180.26,
            "value2": 121.271
          },
          {
            "category": "25-04-2020",
            "value": 180.26,
            "value2": 121.271
          },
          {
            "category": "26-04-2020",
            "value": 180.26,
            "value2": 121.271
          },
          {
            "category": "27-04-2020",
            "value": 184.755,
            "value2": 123.054
          },
          {
            "category": "28-04-2020",
            "value": 187.702,
            "value2": 124.164
          },
          {
            "category": "29-04-2020",
            "value": 189.777,
            "value2": 126.168
          },
          {
            "category": "30-04-2020",
            "value": 192.762,
            "value2": 129.697
          },
          {
            "category": "01-05-2020",
            "value": 192.762,
            "value2": 129.697
          },
          {
            "category": "02-05-2020",
            "value": 192.762,
            "value2": 129.697
          },
          {
            "category": "03-05-2020",
            "value": 192.762,
            "value2": 129.697
          },
          {
            "category": "04-05-2020",
            "value": 183.516,
            "value2": 122.871
          },
          {
            "category": "05-05-2020",
            "value": 180.417,
            "value2": 121.727
          },
          {
            "category": "06-05-2020",
            "value": 182.484,
            "value2": 122.596
          },
          {
            "category": "07-05-2020",
            "value": 179.419,
            "value2": 121.727
          },
          {
            "category": "08-05-2020",
            "value": 180.266,
            "value2": 122.242
          },
          {
            "category": "09-05-2020",
            "value": 180.266,
            "value2": 122.242
          },
          {
            "category": "10-05-2020",
            "value": 180.266,
            "value2": 122.242
          },
          {
            "category": "11-05-2020",
            "value": 178.506,
            "value2": 122.332
          },
          {
            "category": "12-05-2020",
            "value": 177.849,
            "value2": 121.745
          },
          {
            "category": "13-05-2020",
            "value": 180.746,
            "value2": 124.101
          },
          {
            "category": "14-05-2020",
            "value": 178.465,
            "value2": 121.604
          },
          {
            "category": "15-05-2020",
            "value": 177.873,
            "value2": 121.409
          },
          {
            "category": "16-05-2020",
            "value": 177.873,
            "value2": 121.409
          },
          {
            "category": "17-05-2020",
            "value": 177.871,
            "value2": 121.409
          },
          {
            "category": "18-05-2020",
            "value": 169.589,
            "value2": 117.249
          },
          {
            "category": "19-05-2020",
            "value": 171.363,
            "value2": 117.863
          },
          {
            "category": "20-05-2020",
            "value": 175.447,
            "value2": 120.173
          },
          {
            "category": "21-05-2020",
            "value": 176.709,
            "value2": 120.782
          },
          {
            "category": "22-05-2020",
            "value": 174.655,
            "value2": 119.959
          },
          {
            "category": "23-05-2020",
            "value": 174.655,
            "value2": 119.959
          },
          {
            "category": "24-05-2020",
            "value": 174.655,
            "value2": 119.959
          },
          {
            "category": "25-05-2020",
            "value": 174.655,
            "value2": 119.959
          },
          {
            "category": "26-05-2020",
            "value": 175.419,
            "value2": 120.11
          },
          {
            "category": "27-05-2020",
            "value": 179.179,
            "value2": 122.937
          },
          {
            "category": "28-05-2020",
            "value": 183.225,
            "value2": 125.143
          },
          {
            "category": "29-05-2020",
            "value": 186.108,
            "value2": 126.686
          },
          {
            "category": "30-05-2020",
            "value": 186.108,
            "value2": 126.686
          },
          {
            "category": "31-05-2020",
            "value": 185.693,
            "value2": 126.686
          },
          {
            "category": "01-06-2020",
            "value": 188.507,
            "value2": 129.869
          },
          {
            "category": "02-06-2020",
            "value": 189.52,
            "value2": 131.784
          },
          {
            "category": "03-06-2020",
            "value": 191.053,
            "value2": 132.845
          },
          {
            "category": "04-06-2020",
            "value": 190.692,
            "value2": 132.624
          },
          {
            "category": "05-06-2020",
            "value": 193.824,
            "value2": 134.401
          },
          {
            "category": "06-06-2020",
            "value": 193.824,
            "value2": 134.401
          },
          {
            "category": "07-06-2020",
            "value": 193.824,
            "value2": 134.401
          },
          {
            "category": "08-06-2020",
            "value": 194.728,
            "value2": 134.805
          },
          {
            "category": "09-06-2020",
            "value": 191.745,
            "value2": 133.407
          },
          {
            "category": "10-06-2020",
            "value": 193.251,
            "value2": 134.324
          },
          {
            "category": "11-06-2020",
            "value": 189.921,
            "value2": 131.765
          },
          {
            "category": "12-06-2020",
            "value": 191.643,
            "value2": 132.706
          },
          {
            "category": "13-06-2020",
            "value": 191.643,
            "value2": 132.706
          },
          {
            "category": "14-06-2020",
            "value": 191.643,
            "value2": 132.706
          },
          {
            "category": "15-06-2020",
            "value": 187.969,
            "value2": 130.933
          },
          {
            "category": "16-06-2020",
            "value": 190.33,
            "value2": 131.94
          },
          {
            "category": "17-06-2020",
            "value": 190.437,
            "value2": 131.832
          },
          {
            "category": "18-06-2020",
            "value": 193.632,
            "value2": 134.114
          },
          {
            "category": "19-06-2020",
            "value": 197.185,
            "value2": 136.094
          },
          {
            "category": "20-06-2020",
            "value": 197.185,
            "value2": 136.094
          },
          {
            "category": "21-06-2020",
            "value": 197.185,
            "value2": 136.094
          },
          {
            "category": "22-06-2020",
            "value": 198.543,
            "value2": 137.388
          },
          {
            "category": "23-06-2020",
            "value": 202.093,
            "value2": 139.546
          },
          {
            "category": "24-06-2020",
            "value": 199.119,
            "value2": 137.519
          },
          {
            "category": "25-06-2020",
            "value": 201.308,
            "value2": 137.621
          },
          {
            "category": "26-06-2020",
            "value": 201.484,
            "value2": 138.62
          },
          {
            "category": "27-06-2020",
            "value": 201.484,
            "value2": 138.62
          },
          {
            "category": "28-06-2020",
            "value": 201.484,
            "value2": 138.62
          },
          {
            "category": "29-06-2020",
            "value": 200.209,
            "value2": 137.49
          },
          {
            "category": "30-06-2020",
            "value": 201.068,
            "value2": 137.205
          },
          {
            "category": "01-07-2020",
            "value": 202.408,
            "value2": 138.546
          },
          {
            "category": "02-07-2020",
            "value": 203.648,
            "value2": 140.168
          },
          {
            "category": "03-07-2020",
            "value": 205.101,
            "value2": 140.956
          },
          {
            "category": "04-07-2020",
            "value": 205.104,
            "value2": 140.956
          },
          {
            "category": "05-07-2020",
            "value": 205.104,
            "value2": 140.956
          },
          {
            "category": "06-07-2020",
            "value": 207.541,
            "value2": 142.889
          },
          {
            "category": "07-07-2020",
            "value": 207.74,
            "value2": 143.462
          },
          {
            "category": "08-07-2020",
            "value": 206.413,
            "value2": 142.368
          },
          {
            "category": "09-07-2020",
            "value": 207.905,
            "value2": 143.585
          },
          {
            "category": "10-07-2020",
            "value": 207.075,
            "value2": 142.984
          },
          {
            "category": "11-07-2020",
            "value": 207.075,
            "value2": 142.984
          },
          {
            "category": "12-07-2020",
            "value": 207.075,
            "value2": 142.984
          },
          {
            "category": "13-07-2020",
            "value": 206.973,
            "value2": 143.34
          },
          {
            "category": "14-07-2020",
            "value": 204.685,
            "value2": 141.072
          },
          {
            "category": "15-07-2020",
            "value": 203.485,
            "value2": 141.081
          },
          {
            "category": "16-07-2020",
            "value": 208.193,
            "value2": 142.391
          },
          {
            "category": "17-07-2020",
            "value": 211.706,
            "value2": 144.532
          },
          {
            "category": "18-07-2020",
            "value": 211.705,
            "value2": 144.532
          },
          {
            "category": "19-07-2020",
            "value": 211.705,
            "value2": 144.532
          },
          {
            "category": "20-07-2020",
            "value": 213.008,
            "value2": 145.976
          },
          {
            "category": "21-07-2020",
            "value": 214.916,
            "value2": 147.382
          },
          {
            "category": "22-07-2020",
            "value": 213.596,
            "value2": 147.021
          },
          {
            "category": "23-07-2020",
            "value": 215.732,
            "value2": 148.098
          },
          {
            "category": "24-07-2020",
            "value": 213.458,
            "value2": 147.64
          },
          {
            "category": "25-07-2020",
            "value": 213.458,
            "value2": 147.64
          },
          {
            "category": "26-07-2020",
            "value": 213.458,
            "value2": 147.64
          },
          {
            "category": "27-07-2020",
            "value": 210.819,
            "value2": 146.666
          },
          {
            "category": "28-07-2020",
            "value": 212.736,
            "value2": 148.579
          },
          {
            "category": "29-07-2020",
            "value": 212.978,
            "value2": 147.867
          },
          {
            "category": "30-07-2020",
            "value": 210.731,
            "value2": 146.856
          },
          {
            "category": "31-07-2020",
            "value": 210.545,
            "value2": 146.942
          },
          {
            "category": "01-08-2020",
            "value": 210.545,
            "value2": 146.942
          },
          {
            "category": "02-08-2020",
            "value": 210.545,
            "value2": 146.942
          },
          {
            "category": "03-08-2020",
            "value": 207.917,
            "value2": 145.276
          },
          {
            "category": "04-08-2020",
            "value": 210.152,
            "value2": 147.636
          },
          {
            "category": "05-08-2020",
            "value": 210.609,
            "value2": 147.895
          },
          {
            "category": "06-08-2020",
            "value": 210.774,
            "value2": 149.242
          },
          {
            "category": "07-08-2020",
            "value": 211.69,
            "value2": 149.704
          },
          {
            "category": "08-08-2020",
            "value": 211.69,
            "value2": 149.704
          },
          {
            "category": "09-08-2020",
            "value": 211.69,
            "value2": 149.704
          },
          {
            "category": "10-08-2020",
            "value": 212.821,
            "value2": 150.82
          },
          {
            "category": "11-08-2020",
            "value": 212.965,
            "value2": 151.228
          },
          {
            "category": "12-08-2020",
            "value": 212.743,
            "value2": 151.114
          },
          {
            "category": "13-08-2020",
            "value": 213.459,
            "value2": 151.538
          },
          {
            "category": "14-08-2020",
            "value": 210.825,
            "value2": 150.019
          },
          {
            "category": "15-08-2020",
            "value": 210.825,
            "value2": 150.019
          },
          {
            "category": "16-08-2020",
            "value": 210.825,
            "value2": 150.019
          },
          {
            "category": "17-08-2020",
            "value": 211.32,
            "value2": 150.914
          },
          {
            "category": "18-08-2020",
            "value": 214.223,
            "value2": 152.73
          },
          {
            "category": "19-08-2020",
            "value": 214.938,
            "value2": 153.291
          },
          {
            "category": "20-08-2020",
            "value": 213.535,
            "value2": 152.536
          },
          {
            "category": "21-08-2020",
            "value": 214.994,
            "value2": 153.432
          },
          {
            "category": "22-08-2020",
            "value": 214.994,
            "value2": 153.432
          },
          {
            "category": "23-08-2020",
            "value": 214.994,
            "value2": 153.432
          },
          {
            "category": "24-08-2020",
            "value": 217.29,
            "value2": 154.624
          },
          {
            "category": "25-08-2020",
            "value": 221.726,
            "value2": 154.84
          },
          {
            "category": "26-08-2020",
            "value": 220.892,
            "value2": 155.772
          },
          {
            "category": "27-08-2020",
            "value": 220.518,
            "value2": 156.037
          },
          {
            "category": "28-08-2020",
            "value": 222.159,
            "value2": 156.986
          },
          {
            "category": "29-08-2020",
            "value": 222.159,
            "value2": 156.986
          },
          {
            "category": "30-08-2020",
            "value": 222.159,
            "value2": 156.986
          },
          {
            "category": "31-08-2020",
            "value": 215.872,
            "value2": 152.685
          },
          {
            "category": "01-09-2020",
            "value": 219.252,
            "value2": 153.875
          },
          {
            "category": "02-09-2020",
            "value": 219.65,
            "value2": 155.089
          },
          {
            "category": "03-09-2020",
            "value": 219.727,
            "value2": 155.328
          },
          {
            "category": "04-09-2020",
            "value": 216.216,
            "value2": 152.782
          },
          {
            "category": "05-09-2020",
            "value": 216.216,
            "value2": 152.782
          },
          {
            "category": "06-09-2020",
            "value": 216.216,
            "value2": 152.782
          },
          {
            "category": "07-09-2020",
            "value": 217.013,
            "value2": 152.806
          },
          {
            "category": "08-09-2020",
            "value": 214.963,
            "value2": 152.002
          },
          {
            "category": "09-09-2020",
            "value": 214.161,
            "value2": 151.422
          },
          {
            "category": "10-09-2020",
            "value": 215.652,
            "value2": 153.57
          },
          {
            "category": "11-09-2020",
            "value": 215.774,
            "value2": 153.97
          },
          {
            "category": "12-09-2020",
            "value": 215.774,
            "value2": 153.97
          },
          {
            "category": "13-09-2020",
            "value": 215.774,
            "value2": 153.97
          },
          {
            "category": "14-09-2020",
            "value": 216.381,
            "value2": 154.762
          },
          {
            "category": "15-09-2020",
            "value": 217.754,
            "value2": 156.089
          },
          {
            "category": "16-09-2020",
            "value": 219.592,
            "value2": 157.074
          },
          {
            "category": "17-09-2020",
            "value": 218.894,
            "value2": 156.046
          },
          {
            "category": "18-09-2020",
            "value": 218.572,
            "value2": 155.955
          },
          {
            "category": "19-09-2020",
            "value": 218.572,
            "value2": 155.955
          },
          {
            "category": "20-09-2020",
            "value": 218.572,
            "value2": 155.955
          },
          {
            "category": "21-09-2020",
            "value": 212.835,
            "value2": 151.873
          },
          {
            "category": "22-09-2020",
            "value": 210.612,
            "value2": 150.314
          },
          {
            "category": "23-09-2020",
            "value": 209.114,
            "value2": 149.959
          },
          {
            "category": "24-09-2020",
            "value": 204.721,
            "value2": 145.893
          },
          {
            "category": "25-09-2020",
            "value": 208.792,
            "value2": 149.345
          },
          {
            "category": "26-09-2020",
            "value": 208.792,
            "value2": 149.345
          },
          {
            "category": "27-09-2020",
            "value": 208.792,
            "value2": 149.345
          },
          {
            "category": "28-09-2020",
            "value": 213.356,
            "value2": 152.182
          },
          {
            "category": "29-09-2020",
            "value": 211.322,
            "value2": 152.032
          },
          {
            "category": "30-09-2020",
            "value": 210.427,
            "value2": 152.354
          },
          {
            "category": "01-10-2020",
            "value": 213.938,
            "value2": 154.275
          },
          {
            "category": "05-10-2020",
            "value": 213.576,
            "value2": 155.176
          },
          {
            "category": "06-10-2020",
            "value": 216.013,
            "value2": 156.913
          },
          {
            "category": "07-10-2020",
            "value": 214.96,
            "value2": 157.405
          },
          {
            "category": "08-10-2020",
            "value": 215.723,
            "value2": 158.349
          },
          {
            "category": "09-10-2020",
            "value": 216.939,
            "value2": 158.994
          },
          {
            "category": "12-10-2020",
            "value": 215.813,
            "value2": 158.812
          },
          {
            "category": "13-10-2020",
            "value": 214.68,
            "value2": 158.689
          },
          {
            "category": "14-10-2020",
            "value": 215.783,
            "value2": 159.129
          },
          {
            "category": "15-10-2020",
            "value": 211.299,
            "value2": 155.631
          },
          {
            "category": "16-10-2020",
            "value": 213.177,
            "value2": 156.875
          },
          {
            "category": "19-10-2020",
            "value": 215.622,
            "value2": 158.27
          },
          {
            "category": "20-10-2020",
            "value": 215.908,
            "value2": 158.664
          },
          {
            "category": "21-10-2020",
            "value": 216.778,
            "value2": 159.039
          },
          {
            "category": "22-10-2020",
            "value": 217.466,
            "value2": 158.925
          },
          {
            "category": "23-10-2020",
            "value": 218.915,
            "value2": 159.552
          },
          {
            "category": "26-10-2020",
            "value": 216.911,
            "value2": 157.405
          },
          {
            "category": "27-10-2020",
            "value": 221.902,
            "value2": 159.002
          },
          {
            "category": "28-10-2020",
            "value": 220.104,
            "value2": 157.09
          },
          {
            "category": "29-10-2020",
            "value": 219.609,
            "value2": 156.536
          },
          {
            "category": "30-10-2020",
            "value": 218.065,
            "value2": 156.323
          },
          {
            "category": "31-10-2020",
            "value": 217.57,
            "value2": 156.323
          },
          {
            "category": "02-11-2020",
            "value": 220.194,
            "value2": 156.549
          },
          {
            "category": "03-11-2020",
            "value": 222.723,
            "value2": 158.174
          },
          {
            "category": "04-11-2020",
            "value": 223.146,
            "value2": 159.339
          },
          {
            "category": "05-11-2020",
            "value": 226.226,
            "value2": 162.129
          },
          {
            "category": "06-11-2020",
            "value": 227.102,
            "value2": 163.682
          },
          {
            "category": "09-11-2020",
            "value": 230.055,
            "value2": 166.022
          },
          {
            "category": "10-11-2020",
            "value": 231.631,
            "value2": 167.536
          },
          {
            "category": "11-11-2020",
            "value": 234.286,
            "value2": 168.958
          },
          {
            "category": "12-11-2020",
            "value": 233.619,
            "value2": 168.75
          },
          {
            "category": "13-11-2020",
            "value": 234.452,
            "value2": 169.495
          },
          {
            "category": "14-11-2020",
            "value": 236.233,
            "value2": 170.367
          },
          {
            "category": "16-11-2020",
            "value": 236.231,
            "value2": 170.367
          },
          {
            "category": "17-11-2020",
            "value": 237.955,
            "value2": 171.679
          },
          {
            "category": "18-11-2020",
            "value": 239.556,
            "value2": 172.729
          },
          {
            "category": "19-11-2020",
            "value": 236.664,
            "value2": 170.913
          },
          {
            "category": "20-11-2020",
            "value": 240.083,
            "value2": 172.209
          },
          {
            "category": "23-11-2020",
            "value": 241.069,
            "value2": 173.458
          },
          {
            "category": "24-11-2020",
            "value": 243.54,
            "value2": 175.053
          },
          {
            "category": "25-11-2020",
            "value": 240.124,
            "value2": 172.488
          },
          {
            "category": "26-11-2020",
            "value": 241.397,
            "value2": 174.185
          },
          {
            "category": "27-11-2020",
            "value": 241.499,
            "value2": 174.731
          },
          {
            "category": "28-11-2020",
            "value": 241.499,
            "value2": 174.731
          },
          {
            "category": "29-11-2020",
            "value": 241.499,
            "value2": 174.731
          },
          {
            "category": "30-11-2020",
            "value": 241.499,
            "value2": 174.731
          },
          {
            "category": "01-12-2020",
            "value": 242.16,
            "value2": 176.719
          },
          {
            "category": "02-12-2020",
            "value": 241.98,
            "value2": 177.175
          },
          {
            "category": "03-12-2020",
            "value": 244,
            "value2": 177.816
          },
          {
            "category": "04-12-2020",
            "value": 246.17,
            "value2": 179.227
          },
          {
            "category": "07-12-2020",
            "value": 247.92,
            "value2": 180.76
          },
          {
            "category": "08-12-2020",
            "value": 247.52,
            "value2": 181.053
          },
          {
            "category": "09-12-2020",
            "value": 248.54,
            "value2": 182.484
          },
          {
            "category": "10-12-2020",
            "value": 248.82,
            "value2": 181.83
          },
          {
            "category": "11-12-2020",
            "value": 249.46,
            "value2": 182.329
          },
          {
            "category": "14-12-2020",
            "value": 250.5,
            "value2": 183.054
          },
          {
            "category": "15-12-2020",
            "value": 250.13,
            "value2": 183.201
          },
          {
            "category": "16-12-2020",
            "value": 251.16,
            "value2": 184.775
          },
          {
            "category": "17-12-2020",
            "value": 252.44,
            "value2": 185.187
          },
          {
            "category": "18-12-2020",
            "value": 252.01,
            "value2": 185.377
          },
          {
            "category": "21-12-2020",
            "value": 244.4,
            "value2": 178.893
          },
          {
            "category": "22-12-2020",
            "value": 246.03,
            "value2": 180.867
          },
          {
            "category": "23-12-2020",
            "value": 249.83,
            "value2": 183.348
          },
          {
            "category": "24-12-2020",
            "value": 251.49,
            "value2": 185.016
          },
          {
            "category": "28-12-2020",
            "value": 254.56,
            "value2": 186.794
          },
          {
            "category": "29-12-2020",
            "value": 253.99,
            "value2": 187.349
          },
          {
            "category": "30-12-2020",
            "value": 254.74,
            "value2": 188.022
          },
          {
            "category": "31-12-2020",
            "value": 254.39,
            "value2": 188.184
          },
          {
            "category": "01-01-2021",
            "value": 255.55,
            "value2": 189.099
          },
          {
            "category": "04-01-2021",
            "value": 257.83,
            "value2": 191.092
          },
          {
            "category": "05-01-2021",
            "value": 260.86,
            "value2": 192.315
          },
          {
            "category": "06-01-2021",
            "value": 262.77,
            "value2": 191.872
          },
          {
            "category": "07-01-2021",
            "value": 263.82,
            "value2": 192.376
          },
          {
            "category": "08-01-2021",
            "value": 266.45,
            "value2": 194.908
          },
          {
            "category": "11-01-2021",
            "value": 267.47,
            "value2": 196.216
          },
          {
            "category": "12-01-2021",
            "value": 267.89,
            "value2": 197.131
          },
          {
            "category": "13-01-2021",
            "value": 267.91,
            "value2": 196.859
          },
          {
            "category": "14-01-2021",
            "value": 268.13,
            "value2": 197.309
          },
          {
            "category": "15-01-2021",
            "value": 265.02,
            "value2": 195.076
          },
          {
            "category": "18-01-2021",
            "value": 261.34,
            "value2": 192.451
          },
          {
            "category": "19-01-2021",
            "value": 265.41,
            "value2": 195.905
          },
          {
            "category": "20-01-2021",
            "value": 267.21,
            "value2": 197.484
          },
          {
            "category": "21-01-2021",
            "value": 264.16,
            "value2": 196.453
          },
          {
            "category": "22-01-2021",
            "value": 262.06,
            "value2": 193.836
          },
          {
            "category": "25-01-2021",
            "value": 260.556,
            "value2": 192.002
          },
          {
            "category": "26-01-2021",
            "value": 260.556,
            "value2": 192.002
          },
          {
            "category": "27-01-2021",
            "value": 255.267,
            "value2": 188.833
          },
          {
            "category": "28-01-2021",
            "value": 252.392,
            "value2": 187.211
          },
          {
            "category": "29-01-2021",
            "value": 251.625,
            "value2": 184.894
          },
          {
            "category": "30-01-2021",
            "value": 251.625,
            "value2": 184.894
          },
          {
            "category": "31-01-2021",
            "value": 251.032,
            "value2": 184.894
          },
          {
            "category": "01-02-2021",
            "value": 260.993,
            "value2": 192.586
          },
          {
            "category": "02-02-2021",
            "value": 264.982,
            "value2": 197.151
          },
          {
            "category": "03-02-2021",
            "value": 267.57,
            "value2": 199.193
          },
          {
            "category": "04-02-2021",
            "value": 270.031,
            "value2": 200.983
          },
          {
            "category": "05-02-2021",
            "value": 268.215,
            "value2": 200.824
          },
          {
            "category": "08-02-2021",
            "value": 272.016,
            "value2": 203.515
          },
          {
            "category": "09-02-2021",
            "value": 273.88,
            "value2": 203.38
          },
          {
            "category": "10-02-2021",
            "value": 276.114,
            "value2": 203.751
          },
          {
            "category": "11-02-2021",
            "value": 278.295,
            "value2": 204.765
          },
          {
            "category": "12-02-2021",
            "value": 277.8,
            "value2": 204.696
          },
          {
            "category": "15-02-2021",
            "value": 281.078,
            "value2": 206.744
          },
          {
            "category": "16-02-2021",
            "value": 282.107,
            "value2": 206.978
          },
          {
            "category": "17-02-2021",
            "value": 281.977,
            "value2": 206.261
          },
          {
            "category": "18-02-2021",
            "value": 281.827,
            "value2": 205.774
          },
          {
            "category": "19-02-2021",
            "value": 277.766,
            "value2": 203.633
          },
          {
            "category": "22-02-2021",
            "value": 271.722,
            "value2": 200.047
          },
          {
            "category": "23-02-2021",
            "value": 273.579,
            "value2": 200.937
          },
          {
            "category": "24-02-2021",
            "value": 277.852,
            "value2": 204.109
          },
          {
            "category": "25-02-2021",
            "value": 281.857,
            "value2": 205.943
          },
          {
            "category": "26-02-2021",
            "value": 273.786,
            "value2": 199.562
          },
          {
            "category": "27-02-2021",
            "value": 273.786,
            "value2": 199.562
          },
          {
            "category": "28-02-2021",
            "value": 273.228,
            "value2": 199.562
          },
          {
            "category": "01-03-2021",
            "value": 275.26,
            "value2": 202.673
          },
          {
            "category": "02-03-2021",
            "value": 279.935,
            "value2": 205.155
          },
          {
            "category": "03-03-2021",
            "value": 284.705,
            "value2": 209.19
          },
          {
            "category": "04-03-2021",
            "value": 282.976,
            "value2": 207.943
          },
          {
            "category": "05-03-2021",
            "value": 280.111,
            "value2": 205.524
          },
          {
            "category": "08-03-2021",
            "value": 280.018,
            "value2": 205.943
          },
          {
            "category": "09-03-2021",
            "value": 281.963,
            "value2": 206.878
          },
          {
            "category": "10-03-2021",
            "value": 283.192,
            "value2": 208.142
          },
          {
            "category": "11-03-2021",
            "value": 283.192,
            "value2": 208.142
          },
          {
            "category": "12-03-2021",
            "value": 278.967,
            "value2": 206.552
          },
          {
            "category": "15-03-2021",
            "value": 276.671,
            "value2": 205.221
          },
          {
            "category": "16-03-2021",
            "value": 276.103,
            "value2": 205.284
          },
          {
            "category": "17-03-2021",
            "value": 271.369,
            "value2": 202.138
          },
          {
            "category": "18-03-2021",
            "value": 269.136,
            "value2": 199.756
          },
          {
            "category": "19-03-2021",
            "value": 270.488,
            "value2": 202.135
          },
          {
            "category": "22-03-2021",
            "value": 269.194,
            "value2": 202.525
          },
          {
            "category": "23-03-2021",
            "value": 271.073,
            "value2": 203.802
          },
          {
            "category": "24-03-2021",
            "value": 266.145,
            "value2": 200.237
          },
          {
            "category": "25-03-2021",
            "value": 263.69,
            "value2": 196.925
          },
          {
            "category": "26-03-2021",
            "value": 267.63,
            "value2": 199.44
          },
          {
            "category": "29-03-2021",
            "value": 267.63,
            "value2": 199.44
          },
          {
            "category": "30-03-2021",
            "value": 272.124,
            "value2": 203.322
          },
          {
            "category": "31-03-2021",
            "value": 268.351,
            "value2": 202.099
          },
          {
            "category": "01-04-2021",
            "value": 271.838,
            "value2": 204.83
          },
          {
            "category": "02-04-2021",
            "value": 271.838,
            "value2": 204.83
          },
          {
            "category": "03-04-2021",
            "value": 271.838,
            "value2": 204.83
          },
          {
            "category": "04-04-2021",
            "value": 269.511,
            "value2": 204.83
          },
          {
            "category": "05-04-2021",
            "value": 266.621,
            "value2": 201.878
          },
          {
            "category": "06-04-2021",
            "value": 268.018,
            "value2": 202.834
          },
          {
            "category": "07-04-2021",
            "value": 271.584,
            "value2": 204.859
          },
          {
            "category": "08-04-2021",
            "value": 271.737,
            "value2": 205.871
          },
          {
            "category": "09-04-2021",
            "value": 270.985,
            "value2": 205.65
          },
          {
            "category": "12-04-2021",
            "value": 263.152,
            "value2": 197.358
          },
          {
            "category": "13-04-2021",
            "value": 267.259,
            "value2": 200.07
          },
          {
            "category": "14-04-2021",
            "value": 267.254,
            "value2": 200.07
          },
          {
            "category": "15-04-2021",
            "value": 267.53,
            "value2": 200.903
          },
          {
            "category": "16-04-2021",
            "value": 269.25,
            "value2": 201.896
          },
          {
            "category": "19-04-2021",
            "value": 264.221,
            "value2": 198.247
          },
          {
            "category": "20-04-2021",
            "value": 265.2,
            "value2": 197.873
          },
          {
            "category": "21-04-2021",
            "value": 265.201,
            "value2": 197.873
          },
          {
            "category": "22-04-2021",
            "value": 267.504,
            "value2": 199.14
          },
          {
            "category": "23-04-2021",
            "value": 266.989,
            "value2": 198.72
          },
          {
            "category": "26-04-2021",
            "value": 268.939,
            "value2": 200.537
          },
          {
            "category": "27-04-2021",
            "value": 271.402,
            "value2": 202.923
          },
          {
            "category": "28-04-2021",
            "value": 276.519,
            "value2": 205.427
          },
          {
            "category": "29-04-2021",
            "value": 276.712,
            "value2": 205.73
          },
          {
            "category": "30-04-2021",
            "value": 271.688,
            "value2": 203.059
          },
          {
            "category": "03-05-2021",
            "value": 272.712,
            "value2": 203.529
          },
          {
            "category": "04-05-2021",
            "value": 270.713,
            "value2": 201.996
          },
          {
            "category": "05-05-2021",
            "value": 273.001,
            "value2": 203.841
          },
          {
            "category": "06-05-2021",
            "value": 275.099,
            "value2": 205.27
          },
          {
            "category": "07-05-2021",
            "value": 275.53,
            "value2": 206.266
          },
          {
            "category": "10-05-2021",
            "value": 277.002,
            "value2": 208.054
          },
          {
            "category": "11-05-2021",
            "value": 276.885,
            "value2": 207.631
          },
          {
            "category": "12-05-2021",
            "value": 273.88,
            "value2": 205.933
          },
          {
            "category": "13-05-2021",
            "value": 273.88,
            "value2": 205.933
          },
          {
            "category": "14-05-2021",
            "value": 272.55,
            "value2": 204.843
          },
          {
            "category": "17-05-2021",
            "value": 276.245,
            "value2": 208.137
          },
          {
            "category": "18-05-2021",
            "value": 278.891,
            "value2": 210.879
          },
          {
            "category": "19-05-2021",
            "value": 278.056,
            "value2": 210.389
          },
          {
            "category": "20-05-2021",
            "value": 277.163,
            "value2": 209.202
          },
          {
            "category": "21-05-2021",
            "value": 281.363,
            "value2": 212.132
          },
          {
            "category": "24-05-2021",
            "value": 282.89,
            "value2": 212.853
          },
          {
            "category": "25-05-2021",
            "value": 282.763,
            "value2": 212.93
          },
          {
            "category": "26-05-2021",
            "value": 283.944,
            "value2": 213.909
          },
          {
            "category": "27-05-2021",
            "value": 284.608,
            "value2": 214.612
          },
          {
            "category": "28-05-2021",
            "value": 284.193,
            "value2": 215.389
          },
          {
            "category": "31-05-2021",
            "value": 285.954,
            "value2": 217.42
          },
          {
            "category": "01-06-2021",
            "value": 285.501,
            "value2": 217.19
          },
          {
            "category": "02-06-2021",
            "value": 287.065,
            "value2": 218.261
          },
          {
            "category": "03-06-2021",
            "value": 289.883,
            "value2": 220.029
          },
          {
            "category": "04-06-2021",
            "value": 290.128,
            "value2": 220.256
          },
          {
            "category": "07-06-2021",
            "value": 291.716,
            "value2": 221.646
          },
          {
            "category": "08-06-2021",
            "value": 293.778,
            "value2": 221.972
          },
          {
            "category": "09-06-2021",
            "value": 291.954,
            "value2": 220.33
          },
          {
            "category": "10-06-2021",
            "value": 294.698,
            "value2": 222.464
          },
          {
            "category": "11-06-2021",
            "value": 295.017,
            "value2": 223.297
          },
          {
            "category": "14-06-2021",
            "value": 294.495,
            "value2": 223.191
          },
          {
            "category": "15-06-2021",
            "value": 295.994,
            "value2": 224.062
          },
          {
            "category": "16-06-2021",
            "value": 293.67,
            "value2": 222.471
          },
          {
            "category": "17-06-2021",
            "value": 290.482,
            "value2": 221.111
          },
          {
            "category": "18-06-2021",
            "value": 287.938,
            "value2": 220.469
          },
          {
            "category": "21-06-2021",
            "value": 289.532,
            "value2": 221.617
          },
          {
            "category": "22-06-2021",
            "value": 289.003,
            "value2": 222.239
          },
          {
            "category": "23-06-2021",
            "value": 287.786,
            "value2": 221.112
          },
          {
            "category": "24-06-2021",
            "value": 289.774,
            "value2": 221.834
          },
          {
            "category": "25-06-2021",
            "value": 294.095,
            "value2": 223.018
          },
          {
            "category": "28-06-2021",
            "value": 292.866,
            "value2": 222.94
          },
          {
            "category": "29-06-2021",
            "value": 291.784,
            "value2": 222.14
          },
          {
            "category": "30-06-2021",
            "value": 290.003,
            "value2": 222.04
          },
          {
            "category": "01-07-2021",
            "value": 290.15,
            "value2": 221.723
          },
          {
            "category": "02-07-2021",
            "value": 290.181,
            "value2": 222.389
          },
          {
            "category": "03-07-2021",
            "value": 290.181,
            "value2": 222.389
          },
          {
            "category": "04-07-2021",
            "value": 290.181,
            "value2": 222.389
          },
          {
            "category": "05-07-2021",
            "value": 290.839,
            "value2": 223.915
          },
          {
            "category": "06-07-2021",
            "value": 291.461,
            "value2": 223.692
          },
          {
            "category": "07-07-2021",
            "value": 292.469,
            "value2": 224.58
          },
          {
            "category": "08-07-2021",
            "value": 290.3,
            "value2": 222.985
          },
          {
            "category": "09-07-2021",
            "value": 291.082,
            "value2": 222.964
          },
          {
            "category": "10-07-2021",
            "value": 291.082,
            "value2": 222.964
          },
          {
            "category": "11-07-2021",
            "value": 291.082,
            "value2": 222.964
          },
          {
            "category": "12-07-2021",
            "value": 291.444,
            "value2": 223.321
          },
          {
            "category": "13-07-2021",
            "value": 293.215,
            "value2": 224.6
          },
          {
            "category": "14-07-2021",
            "value": 293.609,
            "value2": 225.1
          },
          {
            "category": "15-07-2021",
            "value": 294.914,
            "value2": 226.059
          },
          {
            "category": "16-07-2021",
            "value": 295.172,
            "value2": 226.404
          },
          {
            "category": "17-07-2021",
            "value": 295.172,
            "value2": 226.404
          },
          {
            "category": "18-07-2021",
            "value": 295.172,
            "value2": 226.404
          },
          {
            "category": "19-07-2021",
            "value": 292.363,
            "value2": 224.381
          },
          {
            "category": "20-07-2021",
            "value": 288.744,
            "value2": 222.361
          },
          {
            "category": "21-07-2021",
            "value": 288.744,
            "value2": 222.361
          },
          {
            "category": "22-07-2021",
            "value": 291.891,
            "value2": 225.285
          },
          {
            "category": "23-07-2021",
            "value": 294.203,
            "value2": 225.624
          },
          {
            "category": "24-07-2021",
            "value": 294.203,
            "value2": 225.624
          },
          {
            "category": "25-07-2021",
            "value": 294.203,
            "value2": 225.624
          },
          {
            "category": "26-07-2021",
            "value": 295.315,
            "value2": 225.439
          },
          {
            "category": "27-07-2021",
            "value": 293.558,
            "value2": 224.384
          },
          {
            "category": "28-07-2021",
            "value": 292.8,
            "value2": 223.948
          },
          {
            "category": "29-07-2021",
            "value": 293.24,
            "value2": 225.107
          },
          {
            "category": "30-07-2021",
            "value": 294.209,
            "value2": 225.425
          },
          {
            "category": "31-07-2021",
            "value": 293.58,
            "value2": 225.425
          },
          {
            "category": "01-08-2021",
            "value": 293.604,
            "value2": 225.425
          },
          {
            "category": "02-08-2021",
            "value": 293.826,
            "value2": 227.36
          },
          {
            "category": "03-08-2021",
            "value": 297.334,
            "value2": 229.975
          },
          {
            "category": "04-08-2021",
            "value": 298.26,
            "value2": 230.516
          },
          {
            "category": "05-08-2021",
            "value": 297.763,
            "value2": 230.757
          },
          {
            "category": "06-08-2021",
            "value": 297.038,
            "value2": 230.351
          },
          {
            "category": "07-08-2021",
            "value": 297.038,
            "value2": 230.351
          },
          {
            "category": "08-08-2021",
            "value": 297.038,
            "value2": 230.351
          },
          {
            "category": "09-08-2021",
            "value": 296.118,
            "value2": 229.966
          },
          {
            "category": "10-08-2021",
            "value": 295.076,
            "value2": 229.248
          },
          {
            "category": "11-08-2021",
            "value": 294.913,
            "value2": 229.017
          },
          {
            "category": "12-08-2021",
            "value": 297.682,
            "value2": 230.646
          },
          {
            "category": "13-08-2021",
            "value": 299.803,
            "value2": 232.143
          },
          {
            "category": "14-08-2021",
            "value": 299.803,
            "value2": 232.143
          },
          {
            "category": "15-08-2021",
            "value": 299.803,
            "value2": 232.143
          },
          {
            "category": "16-08-2021",
            "value": 299.1,
            "value2": 232.281
          },
          {
            "category": "17-08-2021",
            "value": 300.006,
            "value2": 233
          },
          {
            "category": "18-08-2021",
            "value": 298.927,
            "value2": 232.65
          },
          {
            "category": "19-08-2021",
            "value": 298.927,
            "value2": 232.65
          },
          {
            "category": "20-08-2021",
            "value": 295.486,
            "value2": 230.108
          },
          {
            "category": "21-08-2021",
            "value": 295.486,
            "value2": 230.108
          },
          {
            "category": "22-08-2021",
            "value": 295.486,
            "value2": 230.108
          },
          {
            "category": "23-08-2021",
            "value": 296.237,
            "value2": 229.938
          },
          {
            "category": "24-08-2021",
            "value": 298.333,
            "value2": 232.176
          },
          {
            "category": "25-08-2021",
            "value": 298.125,
            "value2": 232.677
          },
          {
            "category": "26-08-2021",
            "value": 298.565,
            "value2": 232.859
          },
          {
            "category": "27-08-2021",
            "value": 299.709,
            "value2": 234.283
          },
          {
            "category": "28-08-2021",
            "value": 299.709,
            "value2": 234.283
          },
          {
            "category": "29-08-2021",
            "value": 299.709,
            "value2": 234.283
          },
          {
            "category": "30-08-2021",
            "value": 304.855,
            "value2": 237.67
          },
          {
            "category": "31-08-2021",
            "value": 308.305,
            "value2": 240.3
          },
          {
            "category": "01-09-2021",
            "value": 308.087,
            "value2": 240.23
          },
          {
            "category": "02-09-2021",
            "value": 310.862,
            "value2": 242.437
          },
          {
            "category": "03-09-2021",
            "value": 309.707,
            "value2": 243.648
          },
          {
            "category": "04-09-2021",
            "value": 309.707,
            "value2": 243.648
          },
          {
            "category": "05-09-2021",
            "value": 309.707,
            "value2": 243.648
          },
          {
            "category": "06-09-2021",
            "value": 309.325,
            "value2": 244.397
          },
          {
            "category": "07-09-2021",
            "value": 310.258,
            "value2": 244.025
          },
          {
            "category": "08-09-2021",
            "value": 311.209,
            "value2": 244.417
          },
          {
            "category": "09-09-2021",
            "value": 311.168,
            "value2": 244.946
          },
          {
            "category": "10-09-2021",
            "value": 311.168,
            "value2": 244.946
          },
          {
            "category": "11-09-2021",
            "value": 311.168,
            "value2": 244.946
          },
          {
            "category": "12-09-2021",
            "value": 311.168,
            "value2": 244.946
          },
          {
            "category": "13-09-2021",
            "value": 310.593,
            "value2": 245.168
          },
          {
            "category": "14-09-2021",
            "value": 311.163,
            "value2": 246.039
          },
          {
            "category": "15-09-2021",
            "value": 314.824,
            "value2": 248.014
          },
          {
            "category": "16-09-2021",
            "value": 315.92,
            "value2": 249.354
          },
          {
            "category": "17-09-2021",
            "value": 315.04,
            "value2": 248.088
          },
          {
            "category": "18-09-2021",
            "value": 315.041,
            "value2": 248.088
          },
          {
            "category": "19-09-2021",
            "value": 315.041,
            "value2": 248.088
          },
          {
            "category": "20-09-2021",
            "value": 311.765,
            "value2": 244.773
          },
          {
            "category": "21-09-2021",
            "value": 313.231,
            "value2": 246.73
          },
          {
            "category": "22-09-2021",
            "value": 313.943,
            "value2": 247.527
          },
          {
            "category": "23-09-2021",
            "value": 317.102,
            "value2": 251.044
          },
          {
            "category": "24-09-2021",
            "value": 317.338,
            "value2": 250.79
          },
          {
            "category": "25-09-2021",
            "value": 317.338,
            "value2": 250.79
          },
          {
            "category": "26-09-2021",
            "value": 317.338,
            "value2": 250.79
          },
          {
            "category": "27-09-2021",
            "value": 316.627,
            "value2": 250.81
          },
          {
            "category": "28-09-2021",
            "value": 312.352,
            "value2": 249.239
          },
          {
            "category": "29-09-2021",
            "value": 312.434,
            "value2": 249.108
          },
          {
            "category": "30-09-2021",
            "value": 311.237,
            "value2": 248.482
          },
          {
            "category": "01-10-2021",
            "value": 311.469,
            "value2": 247.815
          },
          {
            "category": "02-10-2021",
            "value": 311.469,
            "value2": 247.815
          },
          {
            "category": "03-10-2021",
            "value": 311.469,
            "value2": 247.815
          },
          {
            "category": "04-10-2021",
            "value": 313.348,
            "value2": 250.593
          },
          {
            "category": "05-10-2021",
            "value": 315.061,
            "value2": 252.278
          },
          {
            "category": "06-10-2021",
            "value": 312.478,
            "value2": 249.895
          },
          {
            "category": "07-10-2021",
            "value": 316.905,
            "value2": 252.466
          },
          {
            "category": "08-10-2021",
            "value": 317.194,
            "value2": 253.65
          },
          {
            "category": "09-10-2021",
            "value": 317.194,
            "value2": 253.65
          },
          {
            "category": "10-10-2021",
            "value": 317.194,
            "value2": 253.65
          },
          {
            "category": "11-10-2021",
            "value": 318.135,
            "value2": 254.683
          },
          {
            "category": "12-10-2021",
            "value": 318.45,
            "value2": 255.596
          },
          {
            "category": "13-10-2021",
            "value": 319.561,
            "value2": 258.106
          },
          {
            "category": "14-10-2021",
            "value": 320.957,
            "value2": 260.383
          },
          {
            "category": "15-10-2021",
            "value": 320.957,
            "value2": 260.383
          },
          {
            "category": "16-10-2021",
            "value": 320.957,
            "value2": 260.383
          },
          {
            "category": "17-10-2021",
            "value": 320.957,
            "value2": 260.383
          },
          {
            "category": "18-10-2021",
            "value": 321.765,
            "value2": 262.296
          },
          {
            "category": "19-10-2021",
            "value": 320.132,
            "value2": 260.174
          },
          {
            "category": "20-10-2021",
            "value": 317.672,
            "value2": 257.09
          },
          {
            "category": "21-10-2021",
            "value": 317.151,
            "value2": 255.805
          },
          {
            "category": "22-10-2021",
            "value": 317.561,
            "value2": 254.318
          },
          {
            "category": "23-10-2021",
            "value": 317.561,
            "value2": 254.318
          },
          {
            "category": "24-10-2021",
            "value": 317.561,
            "value2": 254.318
          },
          {
            "category": "25-10-2021",
            "value": 318.303,
            "value2": 253.264
          },
          {
            "category": "26-10-2021",
            "value": 319.834,
            "value2": 256.133
          },
          {
            "category": "27-10-2021",
            "value": 322.03,
            "value2": 255.812
          },
          {
            "category": "28-10-2021",
            "value": 316.96,
            "value2": 250.97
          },
          {
            "category": "29-10-2021",
            "value": 315.687,
            "value2": 249.238
          },
          {
            "category": "30-10-2021",
            "value": 315.687,
            "value2": 249.238
          },
          {
            "category": "31-10-2021",
            "value": 315.009,
            "value2": 249.238
          },
          {
            "category": "01-11-2021",
            "value": 319.816,
            "value2": 252.766
          },
          {
            "category": "02-11-2021",
            "value": 323.194,
            "value2": 252.96
          },
          {
            "category": "03-11-2021",
            "value": 322.264,
            "value2": 252.427
          },
          {
            "category": "04-11-2021",
            "value": 324.573,
            "value2": 254.002
          },
          {
            "category": "05-11-2021",
            "value": 324.573,
            "value2": 254.002
          },
          {
            "category": "06-11-2021",
            "value": 324.573,
            "value2": 254.002
          },
          {
            "category": "07-11-2021",
            "value": 324.573,
            "value2": 254.002
          },
          {
            "category": "08-11-2021",
            "value": 327.865,
            "value2": 256.335
          },
          {
            "category": "09-11-2021",
            "value": 327.423,
            "value2": 256.713
          },
          {
            "category": "10-11-2021",
            "value": 327.166,
            "value2": 256.406
          },
          {
            "category": "11-11-2021",
            "value": 325.268,
            "value2": 254.674
          },
          {
            "category": "12-11-2021",
            "value": 328.453,
            "value2": 257.455
          },
          {
            "category": "13-11-2021",
            "value": 328.453,
            "value2": 257.455
          },
          {
            "category": "14-11-2021",
            "value": 328.453,
            "value2": 257.455
          },
          {
            "category": "15-11-2021",
            "value": 327.387,
            "value2": 257.554
          },
          {
            "category": "16-11-2021",
            "value": 326.921,
            "value2": 256.43
          },
          {
            "category": "17-11-2021",
            "value": 326.585,
            "value2": 255.329
          },
          {
            "category": "18-11-2021",
            "value": 322.303,
            "value2": 252.803
          },
          {
            "category": "19-11-2021",
            "value": 322.303,
            "value2": 252.803
          },
          {
            "category": "20-11-2021",
            "value": 322.303,
            "value2": 252.803
          },
          {
            "category": "21-11-2021",
            "value": 322.303,
            "value2": 252.803
          },
          {
            "category": "22-11-2021",
            "value": 317.722,
            "value2": 247.462
          },
          {
            "category": "23-11-2021",
            "value": 319.468,
            "value2": 249.582
          },
          {
            "category": "24-11-2021",
            "value": 319.609,
            "value2": 248.559
          },
          {
            "category": "25-11-2021",
            "value": 319.952,
            "value2": 250.272
          },
          {
            "category": "26-11-2021",
            "value": 310.499,
            "value2": 242.862
          },
          {
            "category": "27-11-2021",
            "value": 310.499,
            "value2": 242.862
          },
          {
            "category": "28-11-2021",
            "value": 310.499,
            "value2": 242.862
          },
          {
            "category": "29-11-2021",
            "value": 309.066,
            "value2": 242.077
          },
          {
            "category": "30-11-2021",
            "value": 309.306,
            "value2": 242.001
          },
          {
            "category": "01-12-2021",
            "value": 311.771,
            "value2": 244.204
          },
          {
            "category": "02-12-2021",
            "value": 314.732,
            "value2": 247.294
          },
          {
            "category": "03-12-2021",
            "value": 313.598,
            "value2": 245.312
          },
          {
            "category": "04-12-2021",
            "value": 313.598,
            "value2": 245.312
          },
          {
            "category": "05-12-2021",
            "value": 313.598,
            "value2": 245.312
          },
          {
            "category": "06-12-2021",
            "value": 309.299,
            "value2": 241.375
          },
          {
            "category": "07-12-2021",
            "value": 314.211,
            "value2": 244.947
          },
          {
            "category": "08-12-2021",
            "value": 318.483,
            "value2": 248.885
          },
          {
            "category": "09-12-2021",
            "value": 317.909,
            "value2": 249.743
          },
          {
            "category": "10-12-2021",
            "value": 318.148,
            "value2": 250.2
          },
          {
            "category": "11-12-2021",
            "value": 318.148,
            "value2": 250.2
          },
          {
            "category": "12-12-2021",
            "value": 318.148,
            "value2": 250.2
          },
          {
            "category": "13-12-2021",
            "value": 317.431,
            "value2": 248.717
          },
          {
            "category": "14-12-2021",
            "value": 316.495,
            "value2": 248.298
          },
          {
            "category": "15-12-2021",
            "value": 314.01,
            "value2": 246.804
          },
          {
            "category": "16-12-2021",
            "value": 312.905,
            "value2": 246.597
          },
          {
            "category": "17-12-2021",
            "value": 306.837,
            "value2": 242.302
          },
          {
            "category": "18-12-2021",
            "value": 306.837,
            "value2": 242.302
          },
          {
            "category": "19-12-2021",
            "value": 306.837,
            "value2": 242.302
          },
          {
            "category": "20-12-2021",
            "value": 300.483,
            "value2": 236.153
          },
          {
            "category": "21-12-2021",
            "value": 305.937,
            "value2": 238.764
          },
          {
            "category": "22-12-2021",
            "value": 307.577,
            "value2": 241.799
          },
          {
            "category": "23-12-2021",
            "value": 310.629,
            "value2": 243.584
          },
          {
            "category": "24-12-2021",
            "value": 308.521,
            "value2": 242.224
          },
          {
            "category": "25-12-2021",
            "value": 308.521,
            "value2": 242.224
          },
          {
            "category": "26-12-2021",
            "value": 308.521,
            "value2": 242.224
          },
          {
            "category": "27-12-2021",
            "value": 309.951,
            "value2": 243.28
          },
          {
            "category": "28-12-2021",
            "value": 312.446,
            "value2": 245.574
          },
          {
            "category": "29-12-2021",
            "value": 312.28,
            "value2": 245.487
          },
          {
            "category": "30-12-2021",
            "value": 314.279,
            "value2": 245.154
          },
          {
            "category": "31-12-2021",
            "value": 315.06,
            "value2": 247.71
          },
          {
            "category": "01-01-2022",
            "value": 315.043,
            "value2": 247.71
          },
          {
            "category": "02-01-2022",
            "value": 315.043,
            "value2": 247.71
          },
          {
            "category": "03-01-2022",
            "value": 321.031,
            "value2": 251.135
          },
          {
            "category": "04-01-2022",
            "value": 323.994,
            "value2": 252.962
          },
          {
            "category": "05-01-2022",
            "value": 324.725,
            "value2": 254.267
          },
          {
            "category": "06-01-2022",
            "value": 322.319,
            "value2": 252.61
          },
          {
            "category": "07-01-2022",
            "value": 323.853,
            "value2": 253.577
          },
          {
            "category": "08-01-2022",
            "value": 323.853,
            "value2": 253.577
          },
          {
            "category": "09-01-2022",
            "value": 323.861,
            "value2": 253.577
          },
          {
            "category": "10-01-2022",
            "value": 327.644,
            "value2": 256.062
          },
          {
            "category": "11-01-2022",
            "value": 328.819,
            "value2": 256.697
          },
          {
            "category": "12-01-2022",
            "value": 331.668,
            "value2": 258.939
          },
          {
            "category": "13-01-2022",
            "value": 331.919,
            "value2": 259.782
          },
          {
            "category": "14-01-2022",
            "value": 331.356,
            "value2": 259.921
          },
          {
            "category": "15-01-2022",
            "value": 331.356,
            "value2": 259.921
          },
          {
            "category": "16-01-2022",
            "value": 331.356,
            "value2": 259.921
          },
          {
            "category": "17-01-2022",
            "value": 331.205,
            "value2": 260.745
          },
          {
            "category": "18-01-2022",
            "value": 327.546,
            "value2": 257.388
          },
          {
            "category": "19-01-2022",
            "value": 324.648,
            "value2": 255.707
          },
          {
            "category": "20-01-2022",
            "value": 322.447,
            "value2": 253.912
          },
          {
            "category": "21-01-2022",
            "value": 318.316,
            "value2": 250.921
          },
          {
            "category": "22-01-2022",
            "value": 318.316,
            "value2": 250.921
          },
          {
            "category": "23-01-2022",
            "value": 318.316,
            "value2": 250.921
          },
          {
            "category": "24-01-2022",
            "value": 309.956,
            "value2": 243.206
          },
          {
            "category": "25-01-2022",
            "value": 312.09,
            "value2": 245.236
          },
          {
            "category": "26-01-2022",
            "value": 312.09,
            "value2": 245.236
          },
          {
            "category": "27-01-2022",
            "value": 309.964,
            "value2": 242.726
          },
          {
            "category": "28-01-2022",
            "value": 310.921,
            "value2": 243.425
          },
          {
            "category": "29-01-2022",
            "value": 310.921,
            "value2": 243.425
          },
          {
            "category": "30-01-2022",
            "value": 310.921,
            "value2": 243.425
          },
          {
            "category": "31-01-2022",
            "value": 315.161,
            "value2": 246.771
          },
          {
            "category": "01-02-2022",
            "value": 317.158,
            "value2": 249.985
          },
          {
            "category": "02-02-2022",
            "value": 321.178,
            "value2": 252.838
          },
          {
            "category": "03-02-2022",
            "value": 317.199,
            "value2": 250.262
          },
          {
            "category": "04-02-2022",
            "value": 314.888,
            "value2": 249.446
          },
          {
            "category": "05-02-2022",
            "value": 314.888,
            "value2": 249.446
          },
          {
            "category": "06-02-2022",
            "value": 314.888,
            "value2": 249.446
          },
          {
            "category": "07-02-2022",
            "value": 309.717,
            "value2": 245.947
          },
          {
            "category": "08-02-2022",
            "value": 308.414,
            "value2": 245.69
          },
          {
            "category": "09-02-2022",
            "value": 312.689,
            "value2": 248.4
          },
          {
            "category": "10-02-2022",
            "value": 313.741,
            "value2": 249.963
          },
          {
            "category": "11-02-2022",
            "value": 308.142,
            "value2": 246.358
          },
          {
            "category": "12-02-2022",
            "value": 308.142,
            "value2": 246.358
          },
          {
            "category": "13-02-2022",
            "value": 308.142,
            "value2": 246.358
          },
          {
            "category": "14-02-2022",
            "value": 297.847,
            "value2": 238.406
          },
          {
            "category": "15-02-2022",
            "value": 305.668,
            "value2": 245.106
          },
          {
            "category": "16-02-2022",
            "value": 304.93,
            "value2": 244.932
          },
          {
            "category": "17-02-2022",
            "value": 303.615,
            "value2": 244.619
          },
          {
            "category": "18-02-2022",
            "value": 302.949,
            "value2": 243.67
          },
          {
            "category": "19-02-2022",
            "value": 302.949,
            "value2": 243.67
          },
          {
            "category": "20-02-2022",
            "value": 302.949,
            "value2": 243.67
          },
          {
            "category": "21-02-2022",
            "value": 300.746,
            "value2": 241.833
          },
          {
            "category": "22-02-2022",
            "value": 298.289,
            "value2": 240.094
          },
          {
            "category": "23-02-2022",
            "value": 298.289,
            "value2": 240.395
          },
          {
            "category": "24-02-2022",
            "value": 285.664,
            "value2": 228.224
          },
          {
            "category": "25-02-2022",
            "value": 292.18,
            "value2": 235.067
          },
          {
            "category": "26-02-2022",
            "value": 292.18,
            "value2": 235.067
          },
          {
            "category": "27-02-2022",
            "value": 292.18,
            "value2": 235.067
          },
          {
            "category": "28-02-2022",
            "value": 292.035,
            "value2": 237.045
          },
          {
            "category": "01-03-2022",
            "value": 292.031,
            "value2": 237.045
          },
          {
            "category": "02-03-2022",
            "value": 289.279,
            "value2": 235.222
          },
          {
            "category": "03-03-2022",
            "value": 285.757,
            "value2": 234.171
          },
          {
            "category": "04-03-2022",
            "value": 280.679,
            "value2": 230.231
          },
          {
            "category": "05-03-2022",
            "value": 280.679,
            "value2": 230.231
          },
          {
            "category": "06-03-2022",
            "value": 280.679,
            "value2": 230.231
          },
          {
            "category": "07-03-2022",
            "value": 273.211,
            "value2": 224.701
          },
          {
            "category": "08-03-2022",
            "value": 277.408,
            "value2": 227.049
          },
          {
            "category": "09-03-2022",
            "value": 282.854,
            "value2": 231.668
          },
          {
            "category": "10-03-2022",
            "value": 286.621,
            "value2": 235.056
          },
          {
            "category": "11-03-2022",
            "value": 288.285,
            "value2": 235.867
          },
          {
            "category": "12-03-2022",
            "value": 288.285,
            "value2": 235.867
          },
          {
            "category": "13-03-2022",
            "value": 288.285,
            "value2": 235.867
          },
          {
            "category": "14-03-2022",
            "value": 290.102,
            "value2": 238.107
          },
          {
            "category": "15-03-2022",
            "value": 288.031,
            "value2": 235.489
          },
          {
            "category": "16-03-2022",
            "value": 291.881,
            "value2": 239.887
          },
          {
            "category": "17-03-2022",
            "value": 297.276,
            "value2": 243.904
          },
          {
            "category": "18-03-2022",
            "value": 297.276,
            "value2": 243.904
          },
          {
            "category": "19-03-2022",
            "value": 297.276,
            "value2": 243.904
          },
          {
            "category": "20-03-2022",
            "value": 297.276,
            "value2": 243.904
          },
          {
            "category": "21-03-2022",
            "value": 293.784,
            "value2": 241.956
          },
          {
            "category": "22-03-2022",
            "value": 296.315,
            "value2": 243.999
          },
          {
            "category": "23-03-2022",
            "value": 295.081,
            "value2": 243.473
          },
          {
            "category": "24-03-2022",
            "value": 293.957,
            "value2": 243.622
          },
          {
            "category": "25-03-2022",
            "value": 292.514,
            "value2": 242.902
          },
          {
            "category": "26-03-2022",
            "value": 292.514,
            "value2": 242.902
          },
          {
            "category": "27-03-2022",
            "value": 292.514,
            "value2": 242.902
          },
          {
            "category": "28-03-2022",
            "value": 292.081,
            "value2": 243.351
          },
          {
            "category": "29-03-2022",
            "value": 295.247,
            "value2": 244.865
          },
          {
            "category": "30-03-2022",
            "value": 299.739,
            "value2": 247.156
          },
          {
            "category": "31-03-2022",
            "value": 298.604,
            "value2": 247.085
          },
          {
            "category": "01-04-2022",
            "value": 302.176,
            "value2": 250.306
          },
          {
            "category": "02-04-2022",
            "value": 302.176,
            "value2": 250.306
          },
          {
            "category": "03-04-2022",
            "value": 302.176,
            "value2": 250.306
          },
          {
            "category": "04-04-2022",
            "value": 307.409,
            "value2": 255.246
          },
          {
            "category": "05-04-2022",
            "value": 306.607,
            "value2": 255.411
          },
          {
            "category": "06-04-2022",
            "value": 305.223,
            "value2": 254.352
          },
          {
            "category": "07-04-2022",
            "value": 304.238,
            "value2": 252.231
          },
          {
            "category": "08-04-2022",
            "value": 306.811,
            "value2": 254.598
          },
          {
            "category": "09-04-2022",
            "value": 306.811,
            "value2": 254.598
          },
          {
            "category": "10-04-2022",
            "value": 306.811,
            "value2": 254.598
          },
          {
            "category": "11-04-2022",
            "value": 305.478,
            "value2": 254.335
          },
          {
            "category": "12-04-2022",
            "value": 303.123,
            "value2": 251.699
          },
          {
            "category": "13-04-2022",
            "value": 303.16,
            "value2": 251.264
          },
          {
            "category": "14-04-2022",
            "value": 303.16,
            "value2": 251.264
          },
          {
            "category": "15-04-2022",
            "value": 303.16,
            "value2": 251.264
          },
          {
            "category": "16-04-2022",
            "value": 303.16,
            "value2": 251.264
          },
          {
            "category": "17-04-2022",
            "value": 303.16,
            "value2": 251.264
          },
          {
            "category": "18-04-2022",
            "value": 299.413,
            "value2": 247.771
          },
          {
            "category": "19-04-2022",
            "value": 294.753,
            "value2": 244.502
          },
          {
            "category": "20-04-2022",
            "value": 296.109,
            "value2": 246.592
          },
          {
            "category": "21-04-2022",
            "value": 299.865,
            "value2": 249.959
          },
          {
            "category": "22-04-2022",
            "value": 296.827,
            "value2": 247.338
          },
          {
            "category": "23-04-2022",
            "value": 296.827,
            "value2": 247.338
          },
          {
            "category": "24-04-2022",
            "value": 296.827,
            "value2": 247.338
          },
          {
            "category": "25-04-2022",
            "value": 293.287,
            "value2": 243.748
          },
          {
            "category": "26-04-2022",
            "value": 297.07,
            "value2": 247.487
          },
          {
            "category": "27-04-2022",
            "value": 294.072,
            "value2": 245.196
          },
          {
            "category": "28-04-2022",
            "value": 297.922,
            "value2": 247.848
          },
          {
            "category": "29-04-2022",
            "value": 295.892,
            "value2": 245.68
          },
          {
            "category": "30-04-2022",
            "value": 295.276,
            "value2": 245.68
          },
          {
            "category": "01-05-2022",
            "value": 295.276,
            "value2": 245.68
          },
          {
            "category": "02-05-2022",
            "value": 293.944,
            "value2": 244.889
          },
          {
            "category": "03-05-2022",
            "value": 293.944,
            "value2": 244.889
          },
          {
            "category": "04-05-2022",
            "value": 288.039,
            "value2": 239.184
          },
          {
            "category": "05-05-2022",
            "value": 288.671,
            "value2": 239.327
          },
          {
            "category": "06-05-2022",
            "value": 283.214,
            "value2": 235.246
          },
          {
            "category": "07-05-2022",
            "value": 283.214,
            "value2": 235.246
          },
          {
            "category": "08-05-2022",
            "value": 283.214,
            "value2": 235.246
          },
          {
            "category": "09-05-2022",
            "value": 282.245,
            "value2": 232.711
          },
          {
            "category": "10-05-2022",
            "value": 281.639,
            "value2": 230.442
          },
          {
            "category": "11-05-2022",
            "value": 279.676,
            "value2": 229.067
          },
          {
            "category": "12-05-2022",
            "value": 274.307,
            "value2": 224.079
          },
          {
            "category": "13-05-2022",
            "value": 274.93,
            "value2": 224.12
          },
          {
            "category": "14-05-2022",
            "value": 274.93,
            "value2": 224.12
          },
          {
            "category": "15-05-2022",
            "value": 274.93,
            "value2": 224.12
          },
          {
            "category": "16-05-2022",
            "value": 277.026,
            "value2": 225.75
          },
          {
            "category": "17-05-2022",
            "value": 282.83,
            "value2": 231.67
          },
          {
            "category": "18-05-2022",
            "value": 282.636,
            "value2": 231.529
          },
          {
            "category": "19-05-2022",
            "value": 275.486,
            "value2": 225.48
          },
          {
            "category": "20-05-2022",
            "value": 281.246,
            "value2": 231.206
          },
          {
            "category": "21-05-2022",
            "value": 281.246,
            "value2": 231.206
          },
          {
            "category": "22-05-2022",
            "value": 281.246,
            "value2": 231.206
          },
          {
            "category": "23-05-2022",
            "value": 281.887,
            "value2": 230.234
          },
          {
            "category": "24-05-2022",
            "value": 280.001,
            "value2": 228.759
          },
          {
            "category": "25-05-2022",
            "value": 278.512,
            "value2": 226.266
          },
          {
            "category": "26-05-2022",
            "value": 282.217,
            "value2": 228.686
          },
          {
            "category": "27-05-2022",
            "value": 284.958,
            "value2": 231.191
          },
          {
            "category": "28-05-2022",
            "value": 284.958,
            "value2": 231.191
          },
          {
            "category": "29-05-2022",
            "value": 284.958,
            "value2": 231.191
          },
          {
            "category": "30-05-2022",
            "value": 289.942,
            "value2": 235.858
          },
          {
            "category": "31-05-2022",
            "value": 289.313,
            "value2": 235.465
          },
          {
            "category": "01-06-2022",
            "value": 289.2,
            "value2": 234.754
          },
          {
            "category": "02-06-2022",
            "value": 289.958,
            "value2": 236.162
          },
          {
            "category": "03-06-2022",
            "value": 287.963,
            "value2": 234.623
          },
          {
            "category": "04-06-2022",
            "value": 287.963,
            "value2": 234.623
          },
          {
            "category": "05-06-2022",
            "value": 287.963,
            "value2": 234.623
          },
          {
            "category": "06-06-2022",
            "value": 286.746,
            "value2": 234.249
          },
          {
            "category": "07-06-2022",
            "value": 283.523,
            "value2": 232.33
          },
          {
            "category": "08-06-2022",
            "value": 283.775,
            "value2": 231.542
          },
          {
            "category": "09-06-2022",
            "value": 285.011,
            "value2": 233.091
          },
          {
            "category": "10-06-2022",
            "value": 282.201,
            "value2": 229.824
          },
          {
            "category": "11-06-2022",
            "value": 282.201,
            "value2": 229.824
          },
          {
            "category": "12-06-2022",
            "value": 282.201,
            "value2": 229.824
          },
          {
            "category": "13-06-2022",
            "value": 274.806,
            "value2": 223.758
          },
          {
            "category": "14-06-2022",
            "value": 274.261,
            "value2": 223.213
          },
          {
            "category": "15-06-2022",
            "value": 275.588,
            "value2": 223.295
          },
          {
            "category": "16-06-2022",
            "value": 270.522,
            "value2": 218.443
          },
          {
            "category": "17-06-2022",
            "value": 268.005,
            "value2": 216.974
          },
          {
            "category": "18-06-2022",
            "value": 268.005,
            "value2": 216.974
          },
          {
            "category": "19-06-2022",
            "value": 268.005,
            "value2": 216.974
          },
          {
            "category": "20-06-2022",
            "value": 267.797,
            "value2": 216.156
          },
          {
            "category": "21-06-2022",
            "value": 273.299,
            "value2": 221.055
          },
          {
            "category": "22-06-2022",
            "value": 270.267,
            "value2": 217.793
          },
          {
            "category": "23-06-2022",
            "value": 273.919,
            "value2": 220.03
          },
          {
            "category": "24-06-2022",
            "value": 277.602,
            "value2": 222.393
          },
          {
            "category": "25-06-2022",
            "value": 277.602,
            "value2": 222.393
          },
          {
            "category": "26-06-2022",
            "value": 277.602,
            "value2": 222.393
          },
          {
            "category": "27-06-2022",
            "value": 280.607,
            "value2": 224.404
          },
          {
            "category": "28-06-2022",
            "value": 281.166,
            "value2": 224.796
          },
          {
            "category": "29-06-2022",
            "value": 278.438,
            "value2": 224.039
          },
          {
            "category": "30-06-2022",
            "value": 278.454,
            "value2": 223.51
          },
          {
            "category": "01-07-2022",
            "value": 279.71,
            "value2": 223.677
          },
          {
            "category": "02-07-2022",
            "value": 279.71,
            "value2": 223.677
          },
          {
            "category": "03-07-2022",
            "value": 279.71,
            "value2": 223.677
          },
          {
            "category": "04-07-2022",
            "value": 281.688,
            "value2": 225.019
          },
          {
            "category": "05-07-2022",
            "value": 281.929,
            "value2": 224.84
          },
          {
            "category": "06-07-2022",
            "value": 287.109,
            "value2": 227.71
          },
          {
            "category": "07-07-2022",
            "value": 290.643,
            "value2": 229.929
          },
          {
            "category": "08-07-2022",
            "value": 292.453,
            "value2": 230.972
          },
          {
            "category": "09-07-2022",
            "value": 292.453,
            "value2": 230.972
          },
          {
            "category": "10-07-2022",
            "value": 292.453,
            "value2": 230.972
          },
          {
            "category": "11-07-2022",
            "value": 292.54,
            "value2": 231.98
          },
          {
            "category": "12-07-2022",
            "value": 289.961,
            "value2": 230.23
          },
          {
            "category": "13-07-2022",
            "value": 289.254,
            "value2": 229.333
          },
          {
            "category": "14-07-2022",
            "value": 288.746,
            "value2": 229.004
          },
          {
            "category": "15-07-2022",
            "value": 290.669,
            "value2": 230.485
          },
          {
            "category": "16-07-2022",
            "value": 290.669,
            "value2": 230.485
          },
          {
            "category": "17-07-2022",
            "value": 290.669,
            "value2": 230.485
          },
          {
            "category": "18-07-2022",
            "value": 294.38,
            "value2": 233.697
          },
          {
            "category": "19-07-2022",
            "value": 296.374,
            "value2": 234.847
          },
          {
            "category": "20-07-2022",
            "value": 297.775,
            "value2": 236.691
          },
          {
            "category": "21-07-2022",
            "value": 299.473,
            "value2": 238.644
          },
          {
            "category": "22-07-2022",
            "value": 301.303,
            "value2": 239.636
          },
          {
            "category": "23-07-2022",
            "value": 301.303,
            "value2": 239.636
          },
          {
            "category": "24-07-2022",
            "value": 301.303,
            "value2": 239.636
          },
          {
            "category": "25-07-2022",
            "value": 300.735,
            "value2": 238.701
          },
          {
            "category": "26-07-2022",
            "value": 296.86,
            "value2": 236.405
          },
          {
            "category": "27-07-2022",
            "value": 298.608,
            "value2": 238.569
          },
          {
            "category": "28-07-2022",
            "value": 302.922,
            "value2": 242.04
          },
          {
            "category": "29-07-2022",
            "value": 305.958,
            "value2": 245.318
          },
          {
            "category": "30-07-2022",
            "value": 305.958,
            "value2": 245.318
          },
          {
            "category": "31-07-2022",
            "value": 305.337,
            "value2": 245.318
          },
          {
            "category": "01-08-2022",
            "value": 307.984,
            "value2": 248.388
          },
          {
            "category": "02-08-2022",
            "value": 307.802,
            "value2": 248.786
          },
          {
            "category": "03-08-2022",
            "value": 306.414,
            "value2": 248.918
          },
          {
            "category": "04-08-2022",
            "value": 306.857,
            "value2": 249.186
          },
          {
            "category": "05-08-2022",
            "value": 308.131,
            "value2": 249.295
          },
          {
            "category": "06-08-2022",
            "value": 308.131,
            "value2": 249.295
          },
          {
            "category": "07-08-2022",
            "value": 308.131,
            "value2": 249.295
          },
          {
            "category": "08-08-2022",
            "value": 308.148,
            "value2": 251.035
          },
          {
            "category": "09-08-2022",
            "value": 308.148,
            "value2": 251.035
          },
          {
            "category": "10-08-2022",
            "value": 308.241,
            "value2": 251.04
          },
          {
            "category": "11-08-2022",
            "value": 311.187,
            "value2": 252.997
          },
          {
            "category": "12-08-2022",
            "value": 312.218,
            "value2": 253.768
          },
          {
            "category": "13-08-2022",
            "value": 312.218,
            "value2": 253.768
          },
          {
            "category": "14-08-2022",
            "value": 312.218,
            "value2": 253.768
          },
          {
            "category": "15-08-2022",
            "value": 312.218,
            "value2": 253.768
          },
          {
            "category": "16-08-2022",
            "value": 315.09,
            "value2": 256.096
          },
          {
            "category": "17-08-2022",
            "value": 317.861,
            "value2": 257.812
          },
          {
            "category": "18-08-2022",
            "value": 317.421,
            "value2": 258.333
          },
          {
            "category": "19-08-2022",
            "value": 313.947,
            "value2": 255.624
          },
          {
            "category": "20-08-2022",
            "value": 313.947,
            "value2": 255.624
          },
          {
            "category": "21-08-2022",
            "value": 313.947,
            "value2": 255.624
          },
          {
            "category": "22-08-2022",
            "value": 309.174,
            "value2": 251.716
          },
          {
            "category": "23-08-2022",
            "value": 310.603,
            "value2": 253.192
          },
          {
            "category": "24-08-2022",
            "value": 312.228,
            "value2": 253.932
          },
          {
            "category": "25-08-2022",
            "value": 313.835,
            "value2": 253.289
          },
          {
            "category": "26-08-2022",
            "value": 313.835,
            "value2": 253.991
          },
          {
            "category": "27-08-2022",
            "value": 310.527,
            "value2": 253.991
          },
          {
            "category": "28-08-2022",
            "value": 313.184,
            "value2": 253.991
          },
          {
            "category": "29-08-2022",
            "value": 313.835,
            "value2": 251.16
          },
          {
            "category": "30-08-2022",
            "value": 317.226,
            "value2": 257.108
          },
          {
            "category": "31-08-2022",
            "value": 316.573,
            "value2": 257.108
          },
          {
            "category": "01-09-2022",
            "value": 315.116,
            "value2": 255.141
          },
          {
            "category": "02-09-2022",
            "value": 314.81,
            "value2": 254.961
          },
          {
            "category": "03-09-2022",
            "value": 314.81,
            "value2": 254.961
          },
          {
            "category": "04-09-2022",
            "value": 314.81,
            "value2": 254.961
          },
          {
            "category": "05-09-2022",
            "value": 316.639,
            "value2": 256.475
          },
          {
            "category": "06-09-2022",
            "value": 316.37,
            "value2": 256.845
          },
          {
            "category": "07-09-2022",
            "value": 317.32,
            "value2": 256.926
          },
          {
            "category": "08-09-2022",
            "value": 320.49,
            "value2": 258.923
          },
          {
            "category": "09-09-2022",
            "value": 320.376,
            "value2": 259.39
          },
          {
            "category": "10-09-2022",
            "value": 320.376,
            "value2": 259.39
          },
          {
            "category": "11-09-2022",
            "value": 320.376,
            "value2": 259.39
          },
          {
            "category": "12-09-2022",
            "value": 322.084,
            "value2": 261.251
          },
          {
            "category": "13-09-2022",
            "value": 323.32,
            "value2": 262.815
          },
          {
            "category": "14-09-2022",
            "value": 323.037,
            "value2": 262.146
          },
          {
            "category": "15-09-2022",
            "value": 323.215,
            "value2": 261.358
          },
          {
            "category": "16-09-2022",
            "value": 318.16,
            "value2": 255.755
          },
          {
            "category": "17-09-2022",
            "value": 318.16,
            "value2": 255.755
          },
          {
            "category": "18-09-2022",
            "value": 318.16,
            "value2": 255.755
          },
          {
            "category": "19-09-2022",
            "value": 319.61,
            "value2": 256.512
          },
          {
            "category": "20-09-2022",
            "value": 323.732,
            "value2": 259.444
          },
          {
            "category": "21-09-2022",
            "value": 321.855,
            "value2": 257.675
          },
          {
            "category": "22-09-2022",
            "value": 321.415,
            "value2": 257.307
          },
          {
            "category": "23-09-2022",
            "value": 315.476,
            "value2": 252.64
          },
          {
            "category": "24-09-2022",
            "value": 315.476,
            "value2": 252.64
          },
          {
            "category": "25-09-2022",
            "value": 315.476,
            "value2": 252.64
          },
          {
            "category": "26-09-2022",
            "value": 308.496,
            "value2": 247.03
          },
          {
            "category": "27-09-2022",
            "value": 308.798,
            "value2": 247.063
          },
          {
            "category": "28-09-2022",
            "value": 308.078,
            "value2": 245.253
          },
          {
            "category": "29-09-2022",
            "value": 308.203,
            "value2": 244.986
          },
          {
            "category": "30-09-2022",
            "value": 311.985,
            "value2": 248.821
          },
          {
            "category": "01-10-2022",
            "value": 311.985,
            "value2": 248.821
          },
          {
            "category": "02-10-2022",
            "value": 311.985,
            "value2": 248.821
          },
          {
            "category": "03-10-2022",
            "value": 308.608,
            "value2": 245.608
          },
          {
            "category": "04-10-2022",
            "value": 313.984,
            "value2": 251.086
          },
          {
            "category": "05-10-2022",
            "value": 313.984,
            "value2": 251.086
          },
          {
            "category": "06-10-2022",
            "value": 315.294,
            "value2": 252.552
          },
          {
            "category": "07-10-2022",
            "value": 314.621,
            "value2": 252.232
          },
          {
            "category": "08-10-2022",
            "value": 314.621,
            "value2": 252.232
          },
          {
            "category": "09-10-2022",
            "value": 314.621,
            "value2": 252.232
          },
          {
            "category": "10-10-2022",
            "value": 312.766,
            "value2": 250.832
          },
          {
            "category": "11-10-2022",
            "value": 308.156,
            "value2": 246.956
          },
          {
            "category": "12-10-2022",
            "value": 308.696,
            "value2": 248.783
          },
          {
            "category": "13-10-2022",
            "value": 306.925,
            "value2": 247.127
          },
          {
            "category": "14-10-2022",
            "value": 308.403,
            "value2": 248.579
          },
          {
            "category": "15-10-2022",
            "value": 308.403,
            "value2": 248.579
          },
          {
            "category": "16-10-2022",
            "value": 308.403,
            "value2": 248.579
          },
          {
            "category": "17-10-2022",
            "value": 310.284,
            "value2": 250.15
          },
          {
            "category": "18-10-2022",
            "value": 313.363,
            "value2": 252.614
          },
          {
            "category": "19-10-2022",
            "value": 313.371,
            "value2": 252.82
          },
          {
            "category": "20-10-2022",
            "value": 312.563,
            "value2": 253.559
          },
          {
            "category": "21-10-2022",
            "value": 311.759,
            "value2": 253.137
          },
          {
            "category": "22-10-2022",
            "value": 311.759,
            "value2": 253.137
          },
          {
            "category": "23-10-2022",
            "value": 311.759,
            "value2": 253.137
          },
          {
            "category": "24-10-2022",
            "value": 314.091,
            "value2": 255.085
          },
          {
            "category": "25-10-2022",
            "value": 313.497,
            "value2": 254.413
          },
          {
            "category": "26-10-2022",
            "value": 313.497,
            "value2": 254.413
          },
          {
            "category": "27-10-2022",
            "value": 314.19,
            "value2": 255.949
          },
          {
            "category": "28-10-2022",
            "value": 313.667,
            "value2": 255.926
          },
          {
            "category": "29-10-2022",
            "value": 313.667,
            "value2": 255.926
          },
          {
            "category": "30-10-2022",
            "value": 313.667,
            "value2": 255.926
          },
          {
            "category": "31-10-2022",
            "value": 316.942,
            "value2": 258.993
          },
          {
            "category": "01-11-2022",
            "value": 318.995,
            "value2": 261.015
          },
          {
            "category": "02-11-2022",
            "value": 317.562,
            "value2": 260.332
          },
          {
            "category": "03-11-2022",
            "value": 317.188,
            "value2": 260.187
          },
          {
            "category": "04-11-2022",
            "value": 316.815,
            "value2": 260.925
          },
          {
            "category": "05-11-2022",
            "value": 316.815,
            "value2": 260.925
          },
          {
            "category": "06-11-2022",
            "value": 316.815,
            "value2": 260.925
          },
          {
            "category": "07-11-2022",
            "value": 317.206,
            "value2": 262.348
          },
          {
            "category": "08-11-2022",
            "value": 317.206,
            "value2": 262.348
          },
          {
            "category": "09-11-2022",
            "value": 314.806,
            "value2": 261.612
          },
          {
            "category": "10-11-2022",
            "value": 311.97,
            "value2": 259.509
          },
          {
            "category": "11-11-2022",
            "value": 314.206,
            "value2": 262.975
          },
          {
            "category": "12-11-2022",
            "value": 314.206,
            "value2": 262.975
          },
          {
            "category": "13-11-2022",
            "value": 314.206,
            "value2": 262.975
          },
          {
            "category": "14-11-2022",
            "value": 312.218,
            "value2": 262.984
          },
          {
            "category": "15-11-2022",
            "value": 313.732,
            "value2": 263.736
          },
          {
            "category": "16-11-2022",
            "value": 314.845,
            "value2": 263.158
          },
          {
            "category": "17-11-2022",
            "value": 313.132,
            "value2": 262.052
          },
          {
            "category": "18-11-2022",
            "value": 312.65,
            "value2": 261.301
          },
          {
            "category": "19-11-2022",
            "value": 312.65,
            "value2": 261.301
          },
          {
            "category": "20-11-2022",
            "value": 312.65,
            "value2": 261.301
          },
          {
            "category": "21-11-2022",
            "value": 311.147,
            "value2": 259.679
          },
          {
            "category": "22-11-2022",
            "value": 311.974,
            "value2": 260.611
          },
          {
            "category": "23-11-2022",
            "value": 312.701,
            "value2": 261.088
          },
          {
            "category": "24-11-2022",
            "value": 315.153,
            "value2": 263.532
          },
          {
            "category": "25-11-2022",
            "value": 316.319,
            "value2": 264.202
          },
          {
            "category": "26-11-2022",
            "value": 316.319,
            "value2": 264.202
          },
          {
            "category": "27-11-2022",
            "value": 316.319,
            "value2": 264.202
          },
          {
            "category": "28-11-2022",
            "value": 318.164,
            "value2": 265.215
          },
          {
            "category": "29-11-2022",
            "value": 318.626,
            "value2": 265.687
          },
          {
            "category": "30-11-2022",
            "value": 320.502,
            "value2": 267.96
          },
          {
            "category": "01-12-2022",
            "value": 321.539,
            "value2": 268.913
          },
          {
            "category": "02-12-2022",
            "value": 320.978,
            "value2": 268.195
          },
          {
            "category": "03-12-2022",
            "value": 320.978,
            "value2": 268.195
          },
          {
            "category": "04-12-2022",
            "value": 320.978,
            "value2": 268.195
          },
          {
            "category": "05-12-2022",
            "value": 320.791,
            "value2": 268.243
          },
          {
            "category": "06-12-2022",
            "value": 319.243,
            "value2": 267.493
          },
          {
            "category": "07-12-2022",
            "value": 318.654,
            "value2": 266.503
          },
          {
            "category": "08-12-2022",
            "value": 319.759,
            "value2": 267.294
          },
          {
            "category": "09-12-2022",
            "value": 318.355,
            "value2": 265.612
          },
          {
            "category": "10-12-2022",
            "value": 318.355,
            "value2": 265.612
          },
          {
            "category": "11-12-2022",
            "value": 318.355,
            "value2": 265.612
          },
          {
            "category": "12-12-2022",
            "value": 317.86,
            "value2": 265.791
          },
          {
            "category": "13-12-2022",
            "value": 318.772,
            "value2": 267.035
          },
          {
            "category": "14-12-2022",
            "value": 319.625,
            "value2": 268.063
          },
          {
            "category": "15-12-2022",
            "value": 316.529,
            "value2": 265.021
          },
          {
            "category": "16-12-2022",
            "value": 314.114,
            "value2": 262.563
          },
          {
            "category": "17-12-2022",
            "value": 314.114,
            "value2": 262.563
          },
          {
            "category": "18-12-2022",
            "value": 314.114,
            "value2": 262.563
          },
          {
            "category": "19-12-2022",
            "value": 315.927,
            "value2": 264.564
          },
          {
            "category": "20-12-2022",
            "value": 314.724,
            "value2": 264.052
          },
          {
            "category": "21-12-2022",
            "value": 310.703,
            "value2": 260.758
          },
          {
            "category": "22-12-2022",
            "value": 309.562,
            "value2": 259.102
          },
          {
            "category": "23-12-2022",
            "value": 303.5,
            "value2": 252.656
          },
          {
            "category": "24-12-2022",
            "value": 303.5,
            "value2": 252.656
          },
          {
            "category": "25-12-2022",
            "value": 303.5,
            "value2": 252.656
          },
          {
            "category": "26-12-2022",
            "value": 307.774,
            "value2": 256.854
          },
          {
            "category": "27-12-2022",
            "value": 309.839,
            "value2": 258.897
          },
          {
            "category": "28-12-2022",
            "value": 309.484,
            "value2": 259.205
          },
          {
            "category": "29-12-2022",
            "value": 310.587,
            "value2": 260.095
          },
          {
            "category": "30-12-2022",
            "value": 309.662,
            "value2": 259.514
          },
          {
            "category": "31-12-2022",
            "value": 309.015,
            "value2": 259.514
          },
          {
            "category": "01-01-2023",
            "value": 309.015,
            "value2": 259.514
          },
          {
            "category": "02-01-2023",
            "value": 310.243,
            "value2": 260.74
          },
          {
            "category": "03-01-2023",
            "value": 311.477,
            "value2": 261.315
          },
          {
            "category": "04-01-2023",
            "value": 309.866,
            "value2": 258.525
          },
          {
            "category": "05-01-2023",
            "value": 309.337,
            "value2": 258.37
          },
          {
            "category": "06-01-2023",
            "value": 306.716,
            "value2": 256.48
          },
          {
            "category": "07-01-2023",
            "value": 306.716,
            "value2": 256.48
          },
          {
            "category": "08-01-2023",
            "value": 306.716,
            "value2": 256.48
          },
          {
            "category": "09-01-2023",
            "value": 309.337,
            "value2": 259.484
          },
          {
            "category": "10-01-2023",
            "value": 307.589,
            "value2": 257.362
          },
          {
            "category": "11-01-2023",
            "value": 308.384,
            "value2": 257.014
          },
          {
            "category": "12-01-2023",
            "value": 308.917,
            "value2": 256.626
          },
          {
            "category": "13-01-2023",
            "value": 310.219,
            "value2": 257.737
          },
          {
            "category": "14-01-2023",
            "value": 310.219,
            "value2": 257.737
          },
          {
            "category": "15-01-2023",
            "value": 310.219,
            "value2": 257.737
          },
          {
            "category": "16-01-2023",
            "value": 309.081,
            "value2": 257.086
          },
          {
            "category": "17-01-2023",
            "value": 310.254,
            "value2": 258.62
          },
          {
            "category": "18-01-2023",
            "value": 312.667,
            "value2": 259.812
          },
          {
            "category": "19-01-2023",
            "value": 312.472,
            "value2": 259.085
          },
          {
            "category": "20-01-2023",
            "value": 310.874,
            "value2": 257.863
          },
          {
            "category": "21-01-2023",
            "value": 310.874,
            "value2": 257.863
          },
          {
            "category": "22-01-2023",
            "value": 310.874,
            "value2": 257.863
          },
          {
            "category": "23-01-2023",
            "value": 311.942,
            "value2": 258.793
          },
          {
            "category": "24-01-2023",
            "value": 312.109,
            "value2": 258.504
          },
          {
            "category": "25-01-2023",
            "value": 309.202,
            "value2": 254.913
          },
          {
            "category": "26-01-2023",
            "value": 309.202,
            "value2": 254.913
          },
          {
            "category": "27-01-2023",
            "value": 303.295,
            "value2": 249.901
          },
          {
            "category": "28-01-2023",
            "value": 303.295,
            "value2": 249.901
          },
          {
            "category": "29-01-2023",
            "value": 303.295,
            "value2": 249.901
          },
          {
            "category": "30-01-2023",
            "value": 304.068,
            "value2": 249.467
          },
          {
            "category": "31-01-2023",
            "value": 303.676,
            "value2": 250.857
          },
          {
            "category": "01-02-2023",
            "value": 301.4,
            "value2": 249.289
          },
          {
            "category": "02-02-2023",
            "value": 301.879,
            "value2": 249.19
          },
          {
            "category": "03-02-2023",
            "value": 304.155,
            "value2": 251.303
          },
          {
            "category": "04-02-2023",
            "value": 304.155,
            "value2": 251.303
          },
          {
            "category": "05-02-2023",
            "value": 304.155,
            "value2": 251.303
          },
          {
            "category": "06-02-2023",
            "value": 302.975,
            "value2": 250.807
          },
          {
            "category": "07-02-2023",
            "value": 302.525,
            "value2": 250.237
          },
          {
            "category": "08-02-2023",
            "value": 304.507,
            "value2": 252.404
          },
          {
            "category": "09-02-2023",
            "value": 305.215,
            "value2": 252.416
          },
          {
            "category": "10-02-2023",
            "value": 304.547,
            "value2": 252.122
          },
          {
            "category": "11-02-2023",
            "value": 304.547,
            "value2": 252.122
          },
          {
            "category": "12-02-2023",
            "value": 304.547,
            "value2": 252.122
          },
          {
            "category": "13-02-2023",
            "value": 303.399,
            "value2": 250.226
          },
          {
            "category": "14-02-2023",
            "value": 303.345,
            "value2": 251.368
          },
          {
            "category": "15-02-2023",
            "value": 303.303,
            "value2": 252.629
          },
          {
            "category": "16-02-2023",
            "value": 303.576,
            "value2": 253.43
          },
          {
            "category": "17-02-2023",
            "value": 302.283,
            "value2": 252.112
          },
          {
            "category": "18-02-2023",
            "value": 302.283,
            "value2": 252.112
          },
          {
            "category": "19-02-2023",
            "value": 302.283,
            "value2": 252.112
          },
          {
            "category": "20-02-2023",
            "value": 301.907,
            "value2": 251.035
          },
          {
            "category": "21-02-2023",
            "value": 301.263,
            "value2": 250.647
          },
          {
            "category": "22-02-2023",
            "value": 297.724,
            "value2": 247.06
          },
          {
            "category": "23-02-2023",
            "value": 297.738,
            "value2": 246.533
          },
          {
            "category": "24-02-2023",
            "value": 297.482,
            "value2": 245.904
          },
          {
            "category": "25-02-2023",
            "value": 297.482,
            "value2": 245.904
          },
          {
            "category": "26-02-2023",
            "value": 297.482,
            "value2": 245.904
          },
          {
            "category": "27-02-2023",
            "value": 296.929,
            "value2": 244.495
          },
          {
            "category": "28-02-2023",
            "value": 296.156,
            "value2": 243.963
          },
          {
            "category": "01-03-2023",
            "value": 298.51,
            "value2": 246.407
          },
          {
            "category": "02-03-2023",
            "value": 296.737,
            "value2": 245.143
          },
          {
            "category": "03-03-2023",
            "value": 298.592,
            "value2": 248.311
          },
          {
            "category": "04-03-2023",
            "value": 298.592,
            "value2": 248.311
          },
          {
            "category": "05-03-2023",
            "value": 298.592,
            "value2": 248.311
          },
          {
            "category": "06-03-2023",
            "value": 300.486,
            "value2": 250.098
          },
          {
            "category": "07-03-2023",
            "value": 300.486,
            "value2": 250.098
          },
          {
            "category": "08-03-2023",
            "value": 300.499,
            "value2": 250.834
          },
          {
            "category": "09-03-2023",
            "value": 298.178,
            "value2": 248.93
          },
          {
            "category": "10-03-2023",
            "value": 296.223,
            "value2": 246.806
          },
          {
            "category": "11-03-2023",
            "value": 296.223,
            "value2": 246.806
          },
          {
            "category": "12-03-2023",
            "value": 296.223,
            "value2": 246.806
          },
          {
            "category": "13-03-2023",
            "value": 290.928,
            "value2": 243.052
          },
          {
            "category": "14-03-2023",
            "value": 290.433,
            "value2": 241.467
          },
          {
            "category": "15-03-2023",
            "value": 288.899,
            "value2": 240.761
          },
          {
            "category": "16-03-2023",
            "value": 289.206,
            "value2": 241.024
          },
          {
            "category": "17-03-2023",
            "value": 290.958,
            "value2": 242.494
          },
          {
            "category": "18-03-2023",
            "value": 290.958,
            "value2": 242.494
          },
          {
            "category": "19-03-2023",
            "value": 290.958,
            "value2": 242.494
          },
          {
            "category": "20-03-2023",
            "value": 288.153,
            "value2": 240.742
          },
          {
            "category": "21-03-2023",
            "value": 290.756,
            "value2": 242.357
          },
          {
            "category": "22-03-2023",
            "value": 292.027,
            "value2": 243.15
          },
          {
            "category": "23-03-2023",
            "value": 291.332,
            "value2": 242.198
          },
          {
            "category": "24-03-2023",
            "value": 288.517,
            "value2": 240.122
          },
          {
            "category": "25-03-2023",
            "value": 288.517,
            "value2": 240.122
          },
          {
            "category": "26-03-2023",
            "value": 288.517,
            "value2": 240.122
          },
          {
            "category": "27-03-2023",
            "value": 286.956,
            "value2": 239.809
          },
          {
            "category": "28-03-2023",
            "value": 285.735,
            "value2": 238.978
          },
          {
            "category": "29-03-2023",
            "value": 289.547,
            "value2": 241.259
          },
          {
            "category": "30-03-2023",
            "value": 289.547,
            "value2": 241.259
          },
          {
            "category": "31-03-2023",
            "value": 293.044,
            "value2": 244.832
          },
          {
            "category": "01-04-2023",
            "value": 293.162,
            "value2": 244.832
          },
          {
            "category": "02-04-2023",
            "value": 293.162,
            "value2": 244.832
          },
          {
            "category": "03-04-2023",
            "value": 293.573,
            "value2": 245.538
          },
          {
            "category": "04-04-2023",
            "value": 293.573,
            "value2": 245.538
          },
          {
            "category": "05-04-2023",
            "value": 294.989,
            "value2": 247.339
          },
          {
            "category": "06-04-2023",
            "value": 295.374,
            "value2": 248.214
          },
          {
            "category": "07-04-2023",
            "value": 295.374,
            "value2": 248.214
          },
          {
            "category": "08-04-2023",
            "value": 295.374,
            "value2": 248.214
          },
          {
            "category": "09-04-2023",
            "value": 295.266,
            "value2": 248.214
          },
          {
            "category": "10-04-2023",
            "value": 295.289,
            "value2": 248.756
          },
          {
            "category": "11-04-2023",
            "value": 295.947,
            "value2": 250.091
          },
          {
            "category": "12-04-2023",
            "value": 295.702,
            "value2": 251.319
          },
          {
            "category": "13-04-2023",
            "value": 295.702,
            "value2": 251.559
          },
          {
            "category": "14-04-2023",
            "value": 295.702,
            "value2": 251.559
          },
          {
            "category": "15-04-2023",
            "value": 295.702,
            "value2": 251.559
          },
          {
            "category": "16-04-2023",
            "value": 295.778,
            "value2": 251.559
          },
          {
            "category": "17-04-2023",
            "value": 296.426,
            "value2": 250.677
          },
          {
            "category": "18-04-2023",
            "value": 295.689,
            "value2": 250.552
          },
          {
            "category": "19-04-2023",
            "value": 295.374,
            "value2": 249.874
          },
          {
            "category": "20-04-2023",
            "value": 296.316,
            "value2": 249.965
          },
          {
            "category": "21-04-2023",
            "value": 295.463,
            "value2": 249.692
          },
          {
            "category": "22-04-2023",
            "value": 295.463,
            "value2": 249.692
          },
          {
            "category": "23-04-2023",
            "value": 295.463,
            "value2": 249.692
          },
          {
            "category": "24-04-2023",
            "value": 296.797,
            "value2": 251.113
          },
          {
            "category": "25-04-2023",
            "value": 298.309,
            "value2": 251.549
          },
          {
            "category": "26-04-2023",
            "value": 299.122,
            "value2": 252.229
          },
          {
            "category": "27-04-2023",
            "value": 300.912,
            "value2": 253.63
          },
          {
            "category": "28-04-2023",
            "value": 304.166,
            "value2": 256.06
          },
          {
            "category": "29-04-2023",
            "value": 304.166,
            "value2": 256.06
          },
          {
            "category": "30-04-2023",
            "value": 303.594,
            "value2": 256.06
          },
          {
            "category": "01-05-2023",
            "value": 303.591,
            "value2": 256.06
          },
          {
            "category": "02-05-2023",
            "value": 304.917,
            "value2": 257.486
          },
          {
            "category": "03-05-2023",
            "value": 304.671,
            "value2": 257.068
          },
          {
            "category": "04-05-2023",
            "value": 307.285,
            "value2": 259.205
          },
          {
            "category": "05-05-2023",
            "value": 307.076,
            "value2": 257.091
          },
          {
            "category": "06-05-2023",
            "value": 307.076,
            "value2": 257.091
          },
          {
            "category": "07-05-2023",
            "value": 307.076,
            "value2": 257.091
          },
          {
            "category": "08-05-2023",
            "value": 310.021,
            "value2": 259.599
          },
          {
            "category": "09-05-2023",
            "value": 309.406,
            "value2": 259.455
          },
          {
            "category": "10-05-2023",
            "value": 311.208,
            "value2": 260.25
          },
          {
            "category": "11-05-2023",
            "value": 310.993,
            "value2": 260.542
          },
          {
            "category": "12-05-2023",
            "value": 311.95,
            "value2": 260.45
          },
          {
            "category": "13-05-2023",
            "value": 311.95,
            "value2": 260.45
          },
          {
            "category": "14-05-2023",
            "value": 311.95,
            "value2": 260.45
          },
          {
            "category": "15-05-2023",
            "value": 312.7,
            "value2": 261.626
          },
          {
            "category": "16-05-2023",
            "value": 313.874,
            "value2": 260.989
          },
          {
            "category": "17-05-2023",
            "value": 313.675,
            "value2": 259.895
          },
          {
            "category": "18-05-2023",
            "value": 313.383,
            "value2": 258.76
          },
          {
            "category": "19-05-2023",
            "value": 314.285,
            "value2": 259.57
          },
          {
            "category": "20-05-2023",
            "value": 314.285,
            "value2": 259.57
          },
          {
            "category": "21-05-2023",
            "value": 314.285,
            "value2": 259.57
          },
          {
            "category": "22-05-2023",
            "value": 314.094,
            "value2": 261.175
          },
          {
            "category": "23-05-2023",
            "value": 314.196,
            "value2": 261.821
          },
          {
            "category": "24-05-2023",
            "value": 314.426,
            "value2": 261.367
          },
          {
            "category": "25-05-2023",
            "value": 316.467,
            "value2": 262.072
          },
          {
            "category": "26-05-2023",
            "value": 319.769,
            "value2": 264.436
          },
          {
            "category": "27-05-2023",
            "value": 319.769,
            "value2": 264.436
          },
          {
            "category": "28-05-2023",
            "value": 319.769,
            "value2": 264.436
          },
          {
            "category": "29-05-2023",
            "value": 320.452,
            "value2": 265.686
          },
          {
            "category": "30-05-2023",
            "value": 320.981,
            "value2": 266.164
          },
          {
            "category": "31-05-2023",
            "value": 321.769,
            "value2": 265.727
          },
          {
            "category": "01-06-2023",
            "value": 320.713,
            "value2": 265.546
          },
          {
            "category": "02-06-2023",
            "value": 324.088,
            "value2": 266.563
          },
          {
            "category": "03-06-2023",
            "value": 324.088,
            "value2": 266.563
          },
          {
            "category": "04-06-2023",
            "value": 324.088,
            "value2": 266.563
          },
          {
            "category": "05-06-2023",
            "value": 324.445,
            "value2": 267.393
          },
          {
            "category": "06-06-2023",
            "value": 326.03,
            "value2": 267.736
          },
          {
            "category": "07-06-2023",
            "value": 329.177,
            "value2": 269.839
          },
          {
            "category": "08-06-2023",
            "value": 328.219,
            "value2": 268.412
          },
          {
            "category": "09-06-2023",
            "value": 328.879,
            "value2": 267.681
          },
          {
            "category": "10-06-2023",
            "value": 328.879,
            "value2": 267.681
          },
          {
            "category": "11-06-2023",
            "value": 328.879,
            "value2": 267.681
          },
          {
            "category": "12-06-2023",
            "value": 329.743,
            "value2": 268.524
          },
          {
            "category": "13-06-2023",
            "value": 332.757,
            "value2": 270.581
          },
          {
            "category": "14-06-2023",
            "value": 333.277,
            "value2": 271.16
          },
          {
            "category": "15-06-2023",
            "value": 335.179,
            "value2": 270.72
          },
          {
            "category": "16-06-2023",
            "value": 338.376,
            "value2": 272.84
          },
          {
            "category": "17-06-2023",
            "value": 338.376,
            "value2": 272.84
          },
          {
            "category": "18-06-2023",
            "value": 338.376,
            "value2": 272.84
          },
          {
            "category": "19-06-2023",
            "value": 337.414,
            "value2": 272.17
          },
          {
            "category": "20-06-2023",
            "value": 337.991,
            "value2": 273.074
          },
          {
            "category": "21-06-2023",
            "value": 337.764,
            "value2": 273.857
          },
          {
            "category": "22-06-2023",
            "value": 334.456,
            "value2": 272.144
          },
          {
            "category": "23-06-2023",
            "value": 332.234,
            "value2": 270.002
          },
          {
            "category": "24-06-2023",
            "value": 332.234,
            "value2": 270.002
          },
          {
            "category": "25-06-2023",
            "value": 332.234,
            "value2": 270.002
          },
          {
            "category": "26-06-2023",
            "value": 332.75,
            "value2": 270.932
          },
          {
            "category": "27-06-2023",
            "value": 334.262,
            "value2": 272.569
          },
          {
            "category": "28-06-2023",
            "value": 335.845,
            "value2": 274.489
          },
          {
            "category": "29-06-2023",
            "value": 335.845,
            "value2": 274.489
          },
          {
            "category": "30-06-2023",
            "value": 338.562,
            "value2": 277.101
          },
          {
            "category": "01-07-2023",
            "value": 338.547,
            "value2": 277.101
          },
          {
            "category": "02-07-2023",
            "value": 338.547,
            "value2": 277.101
          },
          {
            "category": "03-07-2023",
            "value": 339.512,
            "value2": 278.714
          },
          {
            "category": "04-07-2023",
            "value": 339.801,
            "value2": 279.224
          },
          {
            "category": "05-07-2023",
            "value": 341.95,
            "value2": 279.927
          },
          {
            "category": "06-07-2023",
            "value": 343.972,
            "value2": 281.56
          },
          {
            "category": "07-07-2023",
            "value": 340.157,
            "value2": 279.447
          },
          {
            "category": "08-07-2023",
            "value": 340.157,
            "value2": 279.447
          },
          {
            "category": "09-07-2023",
            "value": 340.157,
            "value2": 279.447
          },
          {
            "category": "10-07-2023",
            "value": 338.969,
            "value2": 279.269
          },
          {
            "category": "11-07-2023",
            "value": 342.97,
            "value2": 280.84
          },
          {
            "category": "12-07-2023",
            "value": 345.121,
            "value2": 280.684
          },
          {
            "category": "13-07-2023",
            "value": 343.696,
            "value2": 280.523
          },
          {
            "category": "14-07-2023",
            "value": 347.719,
            "value2": 282.931
          },
          {
            "category": "15-07-2023",
            "value": 347.719,
            "value2": 282.931
          },
          {
            "category": "16-07-2023",
            "value": 347.719,
            "value2": 282.931
          },
          {
            "category": "17-07-2023",
            "value": 348.573,
            "value2": 284.714
          },
          {
            "category": "18-07-2023",
            "value": 347.809,
            "value2": 284.783
          },
          {
            "category": "19-07-2023",
            "value": 349.74,
            "value2": 286.109
          },
          {
            "category": "20-07-2023",
            "value": 348.987,
            "value2": 287.584
          },
          {
            "category": "21-07-2023",
            "value": 350.472,
            "value2": 285.237
          },
          {
            "category": "22-07-2023",
            "value": 350.472,
            "value2": 285.237
          },
          {
            "category": "23-07-2023",
            "value": 350.472,
            "value2": 285.237
          },
          {
            "category": "24-07-2023",
            "value": 351.209,
            "value2": 284.618
          },
          {
            "category": "25-07-2023",
            "value": 349.664,
            "value2": 285.152
          },
          {
            "category": "26-07-2023",
            "value": 353.15,
            "value2": 286.505
          },
          {
            "category": "27-07-2023",
            "value": 352.283,
            "value2": 285.645
          },
          {
            "category": "28-07-2023",
            "value": 352.918,
            "value2": 286.168
          },
          {
            "category": "29-07-2023",
            "value": 352.918,
            "value2": 286.168
          },
          {
            "category": "30-07-2023",
            "value": 352.918,
            "value2": 286.168
          },
          {
            "category": "31-07-2023",
            "value": 355.394,
            "value2": 288.04
          },
          {
            "category": "01-08-2023",
            "value": 353.62,
            "value2": 287.891
          },
          {
            "category": "02-08-2023",
            "value": 350.38,
            "value2": 284.742
          },
          {
            "category": "03-08-2023",
            "value": 349.8,
            "value2": 283.343
          },
          {
            "category": "04-08-2023",
            "value": 354.8,
            "value2": 285.312
          },
          {
            "category": "05-08-2023",
            "value": 354.8,
            "value2": 285.312
          },
          {
            "category": "06-08-2023",
            "value": 354.8,
            "value2": 285.312
          },
          {
            "category": "07-08-2023",
            "value": 358.92,
            "value2": 286.383
          },
          {
            "category": "08-08-2023",
            "value": 356.86,
            "value2": 286.286
          },
          {
            "category": "09-08-2023",
            "value": 359.13,
            "value2": 287.278
          },
          {
            "category": "10-08-2023",
            "value": 356.87,
            "value2": 286.28
          },
          {
            "category": "11-08-2023",
            "value": 358.4,
            "value2": 285.005
          },
          {
            "category": "12-08-2023",
            "value": 358.4,
            "value2": 285.005
          },
          {
            "category": "13-08-2023",
            "value": 358.4,
            "value2": 285.005
          },
          {
            "category": "14-08-2023",
            "value": 356.42,
            "value2": 284.454
          },
          {
            "category": "15-08-2023",
            "value": 356.42,
            "value2": 284.454
          },
          {
            "category": "16-08-2023",
            "value": 357.8,
            "value2": 284.969
          },
          {
            "category": "17-08-2023",
            "value": 358.65,
            "value2": 284.073
          },
          {
            "category": "18-08-2023",
            "value": 357.46,
            "value2": 283.359
          },
          {
            "category": "19-08-2023",
            "value": 357.46,
            "value2": 283.359
          },
          {
            "category": "20-08-2023",
            "value": 357.46,
            "value2": 283.359
          },
          {
            "category": "21-08-2023",
            "value": 358.41,
            "value2": 285.038
          },
          {
            "category": "22-08-2023",
            "value": 360.89,
            "value2": 285.962
          },
          {
            "category": "23-08-2023",
            "value": 363.12,
            "value2": 286.722
          },
          {
            "category": "24-08-2023",
            "value": 363.87,
            "value2": 286.358
          },
          {
            "category": "25-08-2023",
            "value": 362.46,
            "value2": 284.58
          },
          {
            "category": "26-08-2023",
            "value": 362.46,
            "value2": 284.58
          },
          {
            "category": "27-08-2023",
            "value": 362.46,
            "value2": 284.58
          },
          {
            "category": "28-08-2023",
            "value": 365.457,
            "value2": 285.473
          },
          {
            "category": "29-08-2023",
            "value": 365.636,
            "value2": 286.385
          },
          {
            "category": "30-08-2023",
            "value": 368.486,
            "value2": 287.06
          },
          {
            "category": "31-08-2023",
            "value": 368.405,
            "value2": 286.274
          },
          {
            "category": "01-09-2023",
            "value": 369.544,
            "value2": 288.74
          },
          {
            "category": "02-09-2023",
            "value": 369.544,
            "value2": 288.74
          },
          {
            "category": "03-09-2023",
            "value": 369.544,
            "value2": 288.74
          },
          {
            "category": "04-09-2023",
            "value": 370.073,
            "value2": 290.421
          },
          {
            "category": "05-09-2023",
            "value": 370.819,
            "value2": 291.667
          },
          {
            "category": "06-09-2023",
            "value": 370.067,
            "value2": 292.178
          },
          {
            "category": "07-09-2023",
            "value": 371.301,
            "value2": 293.85
          },
          {
            "category": "08-09-2023",
            "value": 374.263,
            "value2": 295.576
          },
          {
            "category": "09-09-2023",
            "value": 374.263,
            "value2": 295.576
          },
          {
            "category": "10-09-2023",
            "value": 374.263,
            "value2": 295.576
          },
          {
            "category": "11-09-2023",
            "value": 379.241,
            "value2": 298.327
          },
          {
            "category": "12-09-2023",
            "value": 367.777,
            "value2": 295.139
          },
          {
            "category": "13-09-2023",
            "value": 366.965,
            "value2": 296.25
          },
          {
            "category": "14-09-2023",
            "value": 371.537,
            "value2": 297.555
          },
          {
            "category": "15-09-2023",
            "value": 375.403,
            "value2": 298.679
          },
          {
            "category": "16-09-2023",
            "value": 375.403,
            "value2": 298.679
          },
          {
            "category": "17-09-2023",
            "value": 375.403,
            "value2": 298.679
          },
          {
            "category": "18-09-2023",
            "value": 374.404,
            "value2": 297.725
          },
          {
            "category": "19-09-2023",
            "value": 374.404,
            "value2": 297.725
          },
          {
            "category": "20-09-2023",
            "value": 371.42,
            "value2": 294.99
          },
          {
            "category": "21-09-2023",
            "value": 368.767,
            "value2": 292.516
          },
          {
            "category": "22-09-2023",
            "value": 369.604,
            "value2": 291.742
          },
          {
            "category": "23-09-2023",
            "value": 369.604,
            "value2": 291.742
          },
          {
            "category": "24-09-2023",
            "value": 369.604,
            "value2": 291.742
          },
          {
            "category": "25-09-2023",
            "value": 368.561,
            "value2": 291.927
          },
          {
            "category": "26-09-2023",
            "value": 370.637,
            "value2": 291.933
          },
          {
            "category": "27-09-2023",
            "value": 373.578,
            "value2": 293.053
          },
          {
            "category": "28-09-2023",
            "value": 371.58,
            "value2": 290.233
          },
          {
            "category": "29-09-2023",
            "value": 372.67,
            "value2": 292.305
          },
          {
            "category": "30-09-2023",
            "value": 371.969,
            "value2": 292.305
          },
          {
            "category": "01-10-2023",
            "value": 371.969,
            "value2": 292.305
          },
          {
            "category": "02-10-2023",
            "value": 371.969,
            "value2": 292.305
          },
          {
            "category": "03-10-2023",
            "value": 375.994,
            "value2": 291.599
          },
          {
            "category": "04-10-2023",
            "value": 371.528,
            "value2": 289.434
          },
          {
            "category": "05-10-2023",
            "value": 377.371,
            "value2": 290.635
          },
          {
            "category": "06-10-2023",
            "value": 379.766,
            "value2": 292.24
          },
          {
            "category": "07-10-2023",
            "value": 379.766,
            "value2": 292.24
          },
          {
            "category": "08-10-2023",
            "value": 379.766,
            "value2": 292.24
          },
          {
            "category": "09-10-2023",
            "value": 377.365,
            "value2": 289.385
          },
          {
            "category": "10-10-2023",
            "value": 382.109,
            "value2": 292.379
          },
          {
            "category": "11-10-2023",
            "value": 386.336,
            "value2": 294.133
          },
          {
            "category": "12-10-2023",
            "value": 386.498,
            "value2": 294.389
          },
          {
            "category": "13-10-2023",
            "value": 385.915,
            "value2": 293.936
          },
          {
            "category": "14-10-2023",
            "value": 385.915,
            "value2": 293.936
          },
          {
            "category": "15-10-2023",
            "value": 385.915,
            "value2": 293.936
          },
          {
            "category": "16-10-2023",
            "value": 387.629,
            "value2": 293.892
          },
          {
            "category": "17-10-2023",
            "value": 390.405,
            "value2": 295.183
          },
          {
            "category": "18-10-2023",
            "value": 389.139,
            "value2": 293.054
          },
          {
            "category": "19-10-2023",
            "value": 390.484,
            "value2": 292.43
          },
          {
            "category": "20-10-2023",
            "value": 391.444,
            "value2": 290.793
          },
          {
            "category": "21-10-2023",
            "value": 391.444,
            "value2": 290.793
          },
          {
            "category": "22-10-2023",
            "value": 391.444,
            "value2": 290.793
          },
          {
            "category": "23-10-2023",
            "value": 382.78,
            "value2": 285.297
          },
          {
            "category": "24-10-2023",
            "value": 382.78,
            "value2": 285.297
          },
          {
            "category": "25-10-2023",
            "value": 382.208,
            "value2": 283.342
          },
          {
            "category": "26-10-2023",
            "value": 381.346,
            "value2": 279.835
          },
          {
            "category": "27-10-2023",
            "value": 386.648,
            "value2": 283.336
          },
          {
            "category": "28-10-2023",
            "value": 386.648,
            "value2": 283.336
          },
          {
            "category": "29-10-2023",
            "value": 386.648,
            "value2": 283.336
          },
          {
            "category": "30-10-2023",
            "value": 388.072,
            "value2": 284.36
          },
          {
            "category": "31-10-2023",
            "value": 385.253,
            "value2": 283.943
          },
          {
            "category": "01-11-2023",
            "value": 383.646,
            "value2": 282.773
          },
          {
            "category": "02-11-2023",
            "value": 386.712,
            "value2": 285.507
          },
          {
            "category": "03-11-2023",
            "value": 391.866,
            "value2": 287.376
          },
          {
            "category": "04-11-2023",
            "value": 391.866,
            "value2": 287.376
          },
          {
            "category": "05-11-2023",
            "value": 391.866,
            "value2": 287.376
          },
          {
            "category": "06-11-2023",
            "value": 396.739,
            "value2": 290.075
          },
          {
            "category": "07-11-2023",
            "value": 399.239,
            "value2": 290.527
          },
          {
            "category": "08-11-2023",
            "value": 405.108,
            "value2": 291.645
          },
          {
            "category": "09-11-2023",
            "value": 404.133,
            "value2": 291.089
          },
          {
            "category": "10-11-2023",
            "value": 406.651,
            "value2": 291.628
          },
          {
            "category": "11-11-2023",
            "value": 406.651,
            "value2": 291.628
          },
          {
            "category": "12-11-2023",
            "value": 412.477,
            "value2": 293.391
          },
          {
            "category": "13-11-2023",
            "value": 413.97,
            "value2": 292.597
          },
          {
            "category": "14-11-2023",
            "value": 413.959,
            "value2": 292.597
          },
          {
            "category": "15-11-2023",
            "value": 416.612,
            "value2": 295.804
          },
          {
            "category": "16-11-2023",
            "value": 417.647,
            "value2": 297.278
          },
          {
            "category": "17-11-2023",
            "value": 418.323,
            "value2": 297.41
          },
          {
            "category": "18-11-2023",
            "value": 418.323,
            "value2": 297.41
          },
          {
            "category": "19-11-2023",
            "value": 418.323,
            "value2": 297.41
          },
          {
            "category": "20-11-2023",
            "value": 416.194,
            "value2": 296.999
          },
          {
            "category": "21-11-2023",
            "value": 414.257,
            "value2": 297.935
          },
          {
            "category": "22-11-2023",
            "value": 410.122,
            "value2": 297.963
          },
          {
            "category": "23-11-2023",
            "value": 410.568,
            "value2": 298.18
          },
          {
            "category": "24-11-2023",
            "value": 413.031,
            "value2": 298.224
          },
          {
            "category": "25-11-2023",
            "value": 413.031,
            "value2": 298.224
          },
          {
            "category": "26-11-2023",
            "value": 413.031,
            "value2": 298.224
          },
          {
            "category": "27-11-2023",
            "value": 413.031,
            "value2": 298.224
          },
          {
            "category": "28-11-2023",
            "value": 415.386,
            "value2": 299.872
          },
          {
            "category": "29-11-2023",
            "value": 417.154,
            "value2": 302.517
          },
          {
            "category": "30-11-2023",
            "value": 422.742,
            "value2": 303.983
          },
          {
            "category": "01-12-2023",
            "value": 425.018,
            "value2": 306.086
          },
          {
            "category": "02-12-2023",
            "value": 425.018,
            "value2": 306.086
          },
          {
            "category": "03-12-2023",
            "value": 425.018,
            "value2": 306.086
          },
          {
            "category": "04-12-2023",
            "value": 430.919,
            "value2": 311.704
          },
          {
            "category": "05-12-2023",
            "value": 429.604,
            "value2": 314.151
          },
          {
            "category": "06-12-2023",
            "value": 428.444,
            "value2": 315.715
          },
          {
            "category": "07-12-2023",
            "value": 426.187,
            "value2": 316.188
          },
          {
            "category": "08-12-2023",
            "value": 424.902,
            "value2": 316.151
          },
          {
            "category": "09-12-2023",
            "value": 424.902,
            "value2": 316.151
          },
          {
            "category": "10-12-2023",
            "value": 424.902,
            "value2": 316.151
          },
          {
            "category": "11-12-2023",
            "value": 425.601,
            "value2": 317.275
          },
          {
            "category": "12-12-2023",
            "value": 421.76,
            "value2": 315.833
          },
          {
            "category": "13-12-2023",
            "value": 424.754,
            "value2": 316.892
          },
          {
            "category": "14-12-2023",
            "value": 429.13,
            "value2": 320.667
          },
          {
            "category": "15-12-2023",
            "value": 432.022,
            "value2": 323.529
          },
          {
            "category": "16-12-2023",
            "value": 432.022,
            "value2": 323.529
          },
          {
            "category": "17-12-2023",
            "value": 432.015,
            "value2": 323.529
          },
          {
            "category": "18-12-2023",
            "value": 435.161,
            "value2": 323.667
          },
          {
            "category": "19-12-2023",
            "value": 436.446,
            "value2": 323.926
          },
          {
            "category": "20-12-2023",
            "value": 428.638,
            "value2": 317.312
          },
          {
            "category": "21-12-2023",
            "value": 433.238,
            "value2": 320.134
          },
          {
            "category": "22-12-2023",
            "value": 437.985,
            "value2": 321.998
          },
          {
            "category": "23-12-2023",
            "value": 437.985,
            "value2": 321.998
          },
          {
            "category": "24-12-2023",
            "value": 437.985,
            "value2": 321.998
          },
          {
            "category": "25-12-2023",
            "value": 437.985,
            "value2": 321.998
          },
          {
            "category": "26-12-2023",
            "value": 437.505,
            "value2": 323.641
          },
          {
            "category": "27-12-2023",
            "value": 439.554,
            "value2": 326.075
          },
          {
            "category": "28-12-2023",
            "value": 441.017,
            "value2": 327.886
          },
          {
            "category": "29-12-2023",
            "value": 444.149,
            "value2": 328.406
          },
          {
            "category": "30-12-2023",
            "value": 444.149,
            "value2": 328.406
          },
          {
            "category": "31-12-2023",
            "value": 443.301,
            "value2": 328.406
          },
          {
            "category": "01-01-2024",
            "value": 446.367,
            "value2": 329.105
          },
          {
            "category": "02-01-2024",
            "value": 446.333,
            "value2": 328.211
          },
          {
            "category": "03-01-2024",
            "value": 446.037,
            "value2": 327.347
          },
          {
            "category": "04-01-2024",
            "value": 452.203,
            "value2": 330.239
          },
          {
            "category": "05-01-2024",
            "value": 454.208,
            "value2": 331.086
          },
          {
            "category": "06-01-2024",
            "value": 454.208,
            "value2": 331.086
          },
          {
            "category": "07-01-2024",
            "value": 454.208,
            "value2": 331.086
          },
          {
            "category": "08-01-2024",
            "value": 453.632,
            "value2": 328.129
          },
          {
            "category": "09-01-2024",
            "value": 453.827,
            "value2": 328.845
          },
          {
            "category": "10-01-2024",
            "value": 453.102,
            "value2": 329.884
          },
          {
            "category": "11-01-2024",
            "value": 457.838,
            "value2": 330.979
          },
          {
            "category": "12-01-2024",
            "value": 459.485,
            "value2": 333.702
          },
          {
            "category": "13-01-2024",
            "value": 459.485,
            "value2": 333.702
          },
          {
            "category": "14-01-2024",
            "value": 459.485,
            "value2": 333.702
          },
          {
            "category": "15-01-2024",
            "value": 458.354,
            "value2": 336.107
          },
          {
            "category": "16-01-2024",
            "value": 452.351,
            "value2": 335.097
          },
          {
            "category": "17-01-2024",
            "value": 447.68,
            "value2": 329.346
          },
          {
            "category": "18-01-2024",
            "value": 449.587,
            "value2": 328.115
          },
          {
            "category": "19-01-2024",
            "value": 453.661,
            "value2": 331.448
          },
          {
            "category": "20-01-2024",
            "value": 451.905,
            "value2": 331.363
          },
          {
            "category": "21-01-2024",
            "value": 451.905,
            "value2": 331.363
          },
          {
            "category": "22-01-2024",
            "value": 451.905,
            "value2": 331.363
          },
          {
            "category": "23-01-2024",
            "value": 438.038,
            "value2": 324.713
          },
          {
            "category": "24-01-2024",
            "value": 445.062,
            "value2": 328.846
          },
          {
            "category": "25-01-2024",
            "value": 446.045,
            "value2": 327.913
          },
          {
            "category": "26-01-2024",
            "value": 446.045,
            "value2": 327.913
          },
          {
            "category": "27-01-2024",
            "value": 446.045,
            "value2": 327.913
          },
          {
            "category": "28-01-2024",
            "value": 446.045,
            "value2": 327.913
          },
          {
            "category": "29-01-2024",
            "value": 451.297,
            "value2": 333.332
          },
          {
            "category": "30-01-2024",
            "value": 452.8,
            "value2": 330.908
          },
          {
            "category": "31-01-2024",
            "value": 459.195,
            "value2": 334.704
          },
          {
            "category": "01-02-2024",
            "value": 457.425,
            "value2": 334.187
          },
          {
            "category": "02-02-2024",
            "value": 456.067,
            "value2": 336.514
          },
          {
            "category": "03-02-2024",
            "value": 456.067,
            "value2": 336.514
          },
          {
            "category": "04-02-2024",
            "value": 456.067,
            "value2": 336.514
          },
          {
            "category": "05-02-2024",
            "value": 453.452,
            "value2": 335.48
          },
          {
            "category": "06-02-2024",
            "value": 452.673,
            "value2": 338.618
          },
          {
            "category": "07-02-2024",
            "value": 455.821,
            "value2": 339.869
          },
          {
            "category": "08-02-2024",
            "value": 455.944,
            "value2": 337.998
          },
          {
            "category": "09-02-2024",
            "value": 454.411,
            "value2": 337.493
          },
          {
            "category": "10-02-2024",
            "value": 454.411,
            "value2": 337.493
          },
          {
            "category": "11-02-2024",
            "value": 454.411,
            "value2": 337.493
          },
          {
            "category": "12-02-2024",
            "value": 446.451,
            "value2": 332.763
          },
          {
            "category": "13-02-2024",
            "value": 447.438,
            "value2": 334.652
          },
          {
            "category": "14-02-2024",
            "value": 447.407,
            "value2": 337.014
          },
          {
            "category": "15-02-2024",
            "value": 454.22,
            "value2": 339.041
          },
          {
            "category": "16-02-2024",
            "value": 459.167,
            "value2": 341.256
          },
          {
            "category": "17-02-2024",
            "value": 459.167,
            "value2": 341.256
          },
          {
            "category": "18-02-2024",
            "value": 459.167,
            "value2": 341.256
          },
          {
            "category": "19-02-2024",
            "value": 461.85,
            "value2": 342.505
          },
          {
            "category": "20-02-2024",
            "value": 461.697,
            "value2": 343.082
          },
          {
            "category": "21-02-2024",
            "value": 459.665,
            "value2": 340.67
          },
          {
            "category": "22-02-2024",
            "value": 463.213,
            "value2": 343.391
          },
          {
            "category": "23-02-2024",
            "value": 467.95,
            "value2": 343.865
          },
          {
            "category": "24-02-2024",
            "value": 467.95,
            "value2": 343.865
          },
          {
            "category": "25-02-2024",
            "value": 467.95,
            "value2": 343.865
          },
          {
            "category": "26-02-2024",
            "value": 467.245,
            "value2": 342.898
          },
          {
            "category": "27-02-2024",
            "value": 466.504,
            "value2": 343.491
          },
          {
            "category": "28-02-2024",
            "value": 461.459,
            "value2": 338.787
          },
          {
            "category": "29-02-2024",
            "value": 463.22,
            "value2": 340.256
          },
          {
            "category": "01-03-2024",
            "value": 463.518,
            "value2": 344.574
          },
          {
            "category": "02-03-2024",
            "value": 467.929,
            "value2": 345.702
          },
          {
            "category": "03-03-2024",
            "value": 467.929,
            "value2": 345.702
          },
          {
            "category": "04-03-2024",
            "value": 464.29,
            "value2": 345.846
          },
          {
            "category": "05-03-2024",
            "value": 460.491,
            "value2": 345.07
          },
          {
            "category": "06-03-2024",
            "value": 455.991,
            "value2": 345.016
          },
          {
            "category": "07-03-2024",
            "value": 456.023,
            "value2": 345.988
          },
          {
            "category": "08-03-2024",
            "value": 456.023,
            "value2": 345.988
          },
          {
            "category": "09-03-2024",
            "value": 456.023,
            "value2": 345.988
          },
          {
            "category": "10-03-2024",
            "value": 456.023,
            "value2": 345.988
          },
          {
            "category": "11-03-2024",
            "value": 448.519,
            "value2": 343.502
          },
          {
            "category": "12-03-2024",
            "value": 441.067,
            "value2": 341.573
          },
          {
            "category": "13-03-2024",
            "value": 426.455,
            "value2": 332.732
          },
          {
            "category": "14-03-2024",
            "value": 429.111,
            "value2": 337.399
          },
          {
            "category": "15-03-2024",
            "value": 428.223,
            "value2": 335.809
          },
          {
            "category": "16-03-2024",
            "value": 428.223,
            "value2": 335.809
          },
          {
            "category": "17-03-2024",
            "value": 428.223,
            "value2": 335.809
          },
          {
            "category": "18-03-2024",
            "value": 427.269,
            "value2": 336.101
          },
          {
            "category": "19-03-2024",
            "value": 421.225,
            "value2": 332.206
          },
          {
            "category": "20-03-2024",
            "value": 426.352,
            "value2": 332.581
          },
          {
            "category": "21-03-2024",
            "value": 440.426,
            "value2": 336.993
          },
          {
            "category": "22-03-2024",
            "value": 450.113,
            "value2": 338.647
          },
          {
            "category": "23-03-2024",
            "value": 450.113,
            "value2": 338.647
          },
          {
            "category": "24-03-2024",
            "value": 450.113,
            "value2": 338.647
          },
          {
            "category": "25-03-2024",
            "value": 450.113,
            "value2": 338.647
          },
          {
            "category": "26-03-2024",
            "value": 457.412,
            "value2": 338.789
          },
          {
            "category": "27-03-2024",
            "value": 465.017,
            "value2": 340.176
          },
          {
            "category": "28-03-2024",
            "value": 469.446,
            "value2": 343.166
          },
          {
            "category": "29-03-2024",
            "value": 469.444,
            "value2": 343.166
          },
          {
            "category": "30-03-2024",
            "value": 469.444,
            "value2": 343.166
          },
          {
            "category": "31-03-2024",
            "value": 464.925,
            "value2": 343.166
          },
          {
            "category": "01-04-2024",
            "value": 471.96,
            "value2": 346.919
          },
          {
            "category": "02-04-2024",
            "value": 474.583,
            "value2": 348.201
          },
          {
            "category": "03-04-2024",
            "value": 475.811,
            "value2": 348.801
          },
          {
            "category": "04-04-2024",
            "value": 477.276,
            "value2": 349.743
          },
          {
            "category": "05-04-2024",
            "value": 479.058,
            "value2": 350.474
          },
          {
            "category": "06-04-2024",
            "value": 479.058,
            "value2": 350.474
          },
          {
            "category": "07-04-2024",
            "value": 479.058,
            "value2": 350.474
          },
          {
            "category": "08-04-2024",
            "value": 478.895,
            "value2": 352.262
          },
          {
            "category": "09-04-2024",
            "value": 476.13,
            "value2": 351.805
          },
          {
            "category": "10-04-2024",
            "value": 482.493,
            "value2": 353.922
          },
          {
            "category": "11-04-2024",
            "value": 482.493,
            "value2": 353.922
          },
          {
            "category": "12-04-2024",
            "value": 479.041,
            "value2": 350.992
          },
          {
            "category": "13-04-2024",
            "value": 479.041,
            "value2": 350.992
          },
          {
            "category": "14-04-2024",
            "value": 479.041,
            "value2": 350.992
          },
          {
            "category": "15-04-2024",
            "value": 473.341,
            "value2": 346.632
          },
          {
            "category": "16-04-2024",
            "value": 472.851,
            "value2": 345.609
          },
          {
            "category": "17-04-2024",
            "value": 472.851,
            "value2": 345.609
          },
          {
            "category": "18-04-2024",
            "value": 472.49,
            "value2": 343.764
          },
          {
            "category": "19-04-2024",
            "value": 475.056,
            "value2": 344.86
          },
          {
            "category": "20-04-2024",
            "value": 475.056,
            "value2": 344.86
          },
          {
            "category": "21-04-2024",
            "value": 475.056,
            "value2": 344.86
          },
          {
            "category": "22-04-2024",
            "value": 482.208,
            "value2": 347.971
          },
          {
            "category": "23-04-2024",
            "value": 489.374,
            "value2": 349.037
          },
          {
            "category": "24-04-2024",
            "value": 491.092,
            "value2": 350.395
          },
          {
            "category": "25-04-2024",
            "value": 493.93,
            "value2": 352.677
          },
          {
            "category": "26-04-2024",
            "value": 493.998,
            "value2": 352.122
          },
          {
            "category": "27-04-2024",
            "value": 493.998,
            "value2": 352.122
          },
          {
            "category": "28-04-2024",
            "value": 493.998,
            "value2": 352.122
          },
          {
            "category": "29-04-2024",
            "value": 490.347,
            "value2": 354.927
          },
          {
            "category": "30-04-2024",
            "value": 491.25,
            "value2": 354.964
          },
          {
            "category": "01-05-2024",
            "value": 491.25,
            "value2": 354.964
          },
          {
            "category": "02-05-2024",
            "value": 492.367,
            "value2": 356.46
          },
          {
            "category": "03-05-2024",
            "value": 489.87,
            "value2": 354.286
          },
          {
            "category": "04-05-2024",
            "value": 489.87,
            "value2": 354.286
          },
          {
            "category": "05-05-2024",
            "value": 489.87,
            "value2": 354.286
          },
          {
            "category": "06-05-2024",
            "value": 488.526,
            "value2": 352.811
          },
          {
            "category": "07-05-2024",
            "value": 480.262,
            "value2": 349.106
          },
          {
            "category": "08-05-2024",
            "value": 482.506,
            "value2": 350.304
          },
          {
            "category": "09-05-2024",
            "value": 472.038,
            "value2": 344.198
          },
          {
            "category": "10-05-2024",
            "value": 477.044,
            "value2": 346.69
          },
          {
            "category": "11-05-2024",
            "value": 477.044,
            "value2": 346.69
          },
          {
            "category": "12-05-2024",
            "value": 477.044,
            "value2": 346.69
          },
          {
            "category": "13-05-2024",
            "value": 475.046,
            "value2": 347.52
          },
          {
            "category": "14-05-2024",
            "value": 482.229,
            "value2": 350.344
          },
          {
            "category": "15-05-2024",
            "value": 487.484,
            "value2": 351.215
          },
          {
            "category": "16-05-2024",
            "value": 491.151,
            "value2": 354.512
          },
          {
            "category": "17-05-2024",
            "value": 498.328,
            "value2": 356.771
          },
          {
            "category": "18-05-2024",
            "value": 503.322,
            "value2": 357.974
          },
          {
            "category": "19-05-2024",
            "value": 503.322,
            "value2": 357.974
          },
          {
            "category": "20-05-2024",
            "value": 503.322,
            "value2": 357.974
          },
          {
            "category": "21-05-2024",
            "value": 499.192,
            "value2": 358.8
          },
          {
            "category": "22-05-2024",
            "value": 498.059,
            "value2": 359.895
          },
          {
            "category": "23-05-2024",
            "value": 502.435,
            "value2": 364.12
          },
          {
            "category": "24-05-2024",
            "value": 503.91,
            "value2": 364.056
          },
          {
            "category": "25-05-2024",
            "value": 503.91,
            "value2": 364.056
          },
          {
            "category": "26-05-2024",
            "value": 503.91,
            "value2": 364.056
          },
          {
            "category": "27-05-2024",
            "value": 504.267,
            "value2": 364.23
          },
          {
            "category": "28-05-2024",
            "value": 494.72,
            "value2": 362.463
          },
          {
            "category": "29-05-2024",
            "value": 493.248,
            "value2": 360.329
          },
          {
            "category": "30-05-2024",
            "value": 490.017,
            "value2": 356.716
          },
          {
            "category": "31-05-2024",
            "value": 494.834,
            "value2": 357.928
          },
          {
            "category": "01-06-2024",
            "value": 494.961,
            "value2": 357.928
          },
          {
            "category": "02-06-2024",
            "value": 494.961,
            "value2": 357.928
          },
          {
            "category": "03-06-2024",
            "value": 510.458,
            "value2": 369.793
          },
          {
            "category": "04-06-2024",
            "value": 469.613,
            "value2": 345.076
          },
          {
            "category": "05-06-2024",
            "value": 485.325,
            "value2": 357.5
          },
          {
            "category": "06-06-2024",
            "value": 497.004,
            "value2": 362.765
          },
          {
            "category": "07-06-2024",
            "value": 505.238,
            "value2": 369.471
          },
          {
            "category": "08-06-2024",
            "value": 505.238,
            "value2": 369.471
          },
          {
            "category": "09-06-2024",
            "value": 505.238,
            "value2": 369.471
          },
          {
            "category": "10-06-2024",
            "value": 506.758,
            "value2": 370.125
          },
          {
            "category": "11-06-2024",
            "value": 513.869,
            "value2": 370.983
          },
          {
            "category": "12-06-2024",
            "value": 516.702,
            "value2": 372.756
          },
          {
            "category": "13-06-2024",
            "value": 522.199,
            "value2": 374.691
          },
          {
            "category": "14-06-2024",
            "value": 530.076,
            "value2": 377.113
          },
          {
            "category": "15-06-2024",
            "value": 530.076,
            "value2": 377.113
          },
          {
            "category": "16-06-2024",
            "value": 530.076,
            "value2": 377.113
          },
          {
            "category": "17-06-2024",
            "value": 530.076,
            "value2": 377.113
          },
          {
            "category": "18-06-2024",
            "value": 536.915,
            "value2": 379.04
          },
          {
            "category": "19-06-2024",
            "value": 531.276,
            "value2": 377.282
          },
          {
            "category": "20-06-2024",
            "value": 536.482,
            "value2": 378.744
          },
          {
            "category": "21-06-2024",
            "value": 535.655,
            "value2": 377.472
          },
          {
            "category": "22-06-2024",
            "value": 535.655,
            "value2": 377.472
          },
          {
            "category": "23-06-2024",
            "value": 535.655,
            "value2": 377.472
          },
          {
            "category": "24-06-2024",
            "value": 539.304,
            "value2": 378.482
          },
          {
            "category": "25-06-2024",
            "value": 540.015,
            "value2": 379.865
          },
          {
            "category": "26-06-2024",
            "value": 539.51,
            "value2": 381.005
          },
          {
            "category": "27-06-2024",
            "value": 538.343,
            "value2": 382.751
          },
          {
            "category": "28-06-2024",
            "value": 542.31,
            "value2": 383.173
          },
          {
            "category": "29-06-2024",
            "value": 542.31,
            "value2": 383.173
          },
          {
            "category": "30-06-2024",
            "value": 541.302,
            "value2": 383.173
          },
          {
            "category": "01-07-2024",
            "value": 544.961,
            "value2": 385.979
          },
          {
            "category": "02-07-2024",
            "value": 542.288,
            "value2": 385.191
          },
          {
            "category": "03-07-2024",
            "value": 544.013,
            "value2": 388.148
          },
          {
            "category": "04-07-2024",
            "value": 548.173,
            "value2": 389.234
          },
          {
            "category": "05-07-2024",
            "value": 556.696,
            "value2": 390.588
          },
          {
            "category": "06-07-2024",
            "value": 556.696,
            "value2": 390.588
          },
          {
            "category": "07-07-2024",
            "value": 556.696,
            "value2": 390.588
          },
          {
            "category": "08-07-2024",
            "value": 555.331,
            "value2": 390.224
          },
          {
            "category": "09-07-2024",
            "value": 554.58,
            "value2": 391.995
          },
          {
            "category": "10-07-2024",
            "value": 551.298,
            "value2": 390.546
          },
          {
            "category": "11-07-2024",
            "value": 553.761,
            "value2": 391.076
          },
          {
            "category": "12-07-2024",
            "value": 554.769,
            "value2": 392.545
          },
          {
            "category": "13-07-2024",
            "value": 554.769,
            "value2": 392.545
          },
          {
            "category": "14-07-2024",
            "value": 554.769,
            "value2": 392.545
          },
          {
            "category": "15-07-2024",
            "value": 556.171,
            "value2": 394.448
          },
          {
            "category": "16-07-2024",
            "value": 556.669,
            "value2": 394.413
          },
          {
            "category": "17-07-2024",
            "value": 556.669,
            "value2": 394.413
          },
          {
            "category": "18-07-2024",
            "value": 546.43,
            "value2": 394.492
          },
          {
            "category": "19-07-2024",
            "value": 534.907,
            "value2": 388.559
          },
          {
            "category": "20-07-2024",
            "value": 534.907,
            "value2": 388.559
          },
          {
            "category": "21-07-2024",
            "value": 534.89,
            "value2": 388.559
          },
          {
            "category": "22-07-2024",
            "value": 537.653,
            "value2": 390.072
          },
          {
            "category": "23-07-2024",
            "value": 538.09,
            "value2": 388.805
          },
          {
            "category": "24-07-2024",
            "value": 547.716,
            "value2": 389.724
          },
          {
            "category": "25-07-2024",
            "value": 546.907,
            "value2": 389.676
          },
          {
            "category": "26-07-2024",
            "value": 552.971,
            "value2": 396.112
          },
          {
            "category": "27-07-2024",
            "value": 552.971,
            "value2": 396.112
          },
          {
            "category": "28-07-2024",
            "value": 552.971,
            "value2": 396.112
          },
          {
            "category": "29-07-2024",
            "value": 565.142,
            "value2": 397.918
          },
          {
            "category": "30-07-2024",
            "value": 567.693,
            "value2": 398.693
          },
          {
            "category": "31-07-2024",
            "value": 569.175,
            "value2": 400.203
          },
          {
            "category": "01-08-2024",
            "value": 563.299,
            "value2": 399.787
          },
          {
            "category": "02-08-2024",
            "value": 562.56,
            "value2": 395.718
          },
          {
            "category": "03-08-2024",
            "value": 562.56,
            "value2": 395.718
          },
          {
            "category": "04-08-2024",
            "value": 562.56,
            "value2": 395.718
          },
          {
            "category": "05-08-2024",
            "value": 542.519,
            "value2": 383.55
          },
          {
            "category": "06-08-2024",
            "value": 534.897,
            "value2": 381.9
          },
          {
            "category": "07-08-2024",
            "value": 547.379,
            "value2": 389.063
          },
          {
            "category": "08-08-2024",
            "value": 549.429,
            "value2": 386.416
          },
          {
            "category": "09-08-2024",
            "value": 558.638,
            "value2": 390.166
          },
          {
            "category": "10-08-2024",
            "value": 558.638,
            "value2": 390.166
          },
          {
            "category": "11-08-2024",
            "value": 558.638,
            "value2": 390.166
          },
          {
            "category": "12-08-2024",
            "value": 563.053,
            "value2": 390.223
          },
          {
            "category": "13-08-2024",
            "value": 561.4,
            "value2": 386.562
          },
          {
            "category": "14-08-2024",
            "value": 560.279,
            "value2": 386.026
          },
          {
            "category": "15-08-2024",
            "value": 560.279,
            "value2": 386.026
          },
          {
            "category": "16-08-2024",
            "value": 571.866,
            "value2": 392.623
          },
          {
            "category": "17-08-2024",
            "value": 571.866,
            "value2": 392.623
          },
          {
            "category": "18-08-2024",
            "value": 571.866,
            "value2": 392.623
          },
          {
            "category": "19-08-2024",
            "value": 575.002,
            "value2": 394.075
          },
          {
            "category": "20-08-2024",
            "value": 578.82,
            "value2": 396.486
          },
          {
            "category": "21-08-2024",
            "value": 577.317,
            "value2": 397.968
          },
          {
            "category": "22-08-2024",
            "value": 584.309,
            "value2": 399.153
          },
          {
            "category": "23-08-2024",
            "value": 585.348,
            "value2": 398.766
          },
          {
            "category": "24-08-2024",
            "value": 585.348,
            "value2": 398.766
          },
          {
            "category": "25-08-2024",
            "value": 585.345,
            "value2": 398.766
          },
          {
            "category": "26-08-2024",
            "value": 587.104,
            "value2": 401.165
          },
          {
            "category": "27-08-2024",
            "value": 585.165,
            "value2": 401.842
          },
          {
            "category": "28-08-2024",
            "value": 588.052,
            "value2": 402.05
          },
          {
            "category": "29-08-2024",
            "value": 583.165,
            "value2": 402.334
          },
          {
            "category": "30-08-2024",
            "value": 587.48,
            "value2": 404.041
          },
          {
            "category": "31-08-2024",
            "value": 586.341,
            "value2": 404.041
          },
          {
            "category": "01-09-2024",
            "value": 586.341,
            "value2": 404.041
          },
          {
            "category": "02-09-2024",
            "value": 581.649,
            "value2": 404.51
          },
          {
            "category": "03-09-2024",
            "value": 584.529,
            "value2": 405.005
          },
          {
            "category": "04-09-2024",
            "value": 589.443,
            "value2": 404.344
          },
          {
            "category": "05-09-2024",
            "value": 591.614,
            "value2": 404.451
          },
          {
            "category": "06-09-2024",
            "value": 587.843,
            "value2": 399.688
          },
          {
            "category": "07-09-2024",
            "value": 587.843,
            "value2": 399.688
          },
          {
            "category": "08-09-2024",
            "value": 587.843,
            "value2": 399.688
          },
          {
            "category": "09-09-2024",
            "value": 588.706,
            "value2": 400.159
          },
          {
            "category": "10-09-2024",
            "value": 595.945,
            "value2": 402.554
          },
          {
            "category": "11-09-2024",
            "value": 597.276,
            "value2": 400.693
          },
          {
            "category": "12-09-2024",
            "value": 610.804,
            "value2": 406.96
          },
          {
            "category": "13-09-2024",
            "value": 614.019,
            "value2": 407.361
          },
          {
            "category": "14-09-2024",
            "value": 614.019,
            "value2": 407.361
          },
          {
            "category": "15-09-2024",
            "value": 614.019,
            "value2": 407.361
          },
          {
            "category": "16-09-2024",
            "value": 617.148,
            "value2": 407.866
          },
          {
            "category": "17-09-2024",
            "value": 616.017,
            "value2": 407.899
          },
          {
            "category": "18-09-2024",
            "value": 613.66,
            "value2": 406.409
          },
          {
            "category": "19-09-2024",
            "value": 606.91,
            "value2": 405.859
          },
          {
            "category": "20-09-2024",
            "value": 617.614,
            "value2": 411.707
          },
          {
            "category": "21-09-2024",
            "value": 617.614,
            "value2": 411.707
          },
          {
            "category": "22-09-2024",
            "value": 617.614,
            "value2": 411.707
          },
          {
            "category": "23-09-2024",
            "value": 628.297,
            "value2": 414.694
          },
          {
            "category": "24-09-2024",
            "value": 627.529,
            "value2": 414.821
          },
          {
            "category": "25-09-2024",
            "value": 620.02,
            "value2": 414.504
          },
          {
            "category": "26-09-2024",
            "value": 615.846,
            "value2": 416.71
          },
          {
            "category": "27-09-2024",
            "value": 615.535,
            "value2": 416.655
          },
          {
            "category": "28-09-2024",
            "value": 615.535,
            "value2": 416.655
          },
          {
            "category": "29-09-2024",
            "value": 615.486,
            "value2": 416.655
          },
          {
            "category": "30-09-2024",
            "value": 614.704,
            "value2": 412.476
          },
          {
            "category": "01-10-2024",
            "value": 617.046,
            "value2": 412.881
          },
          {
            "category": "02-10-2024",
            "value": 617.046,
            "value2": 412.881
          },
          {
            "category": "03-10-2024",
            "value": 606.63,
            "value2": 404.11
          },
          {
            "category": "04-10-2024",
            "value": 597.972,
            "value2": 400.226
          },
          {
            "category": "05-10-2024",
            "value": 597.972,
            "value2": 400.226
          },
          {
            "category": "06-10-2024",
            "value": 597.972,
            "value2": 400.226
          },
          {
            "category": "07-10-2024",
            "value": 585.361,
            "value2": 394.375
          },
          {
            "category": "08-10-2024",
            "value": 603.018,
            "value2": 399.944
          },
          {
            "category": "09-10-2024",
            "value": 617.444,
            "value2": 401.399
          },
          {
            "category": "10-10-2024",
            "value": 621.691,
            "value2": 401.29
          },
          {
            "category": "11-10-2024",
            "value": 623.377,
            "value2": 401.428
          },
          {
            "category": "12-10-2024",
            "value": 623.377,
            "value2": 401.428
          },
          {
            "category": "13-10-2024",
            "value": 623.377,
            "value2": 401.428
          },
          {
            "category": "14-10-2024",
            "value": 625.199,
            "value2": 403.273
          },
          {
            "category": "15-10-2024",
            "value": 633.403,
            "value2": 403.466
          },
          {
            "category": "16-10-2024",
            "value": 632.855,
            "value2": 402.372
          },
          {
            "category": "17-10-2024",
            "value": 622.398,
            "value2": 397.518
          },
          {
            "category": "18-10-2024",
            "value": 620.166,
            "value2": 398.466
          },
          {
            "category": "19-10-2024",
            "value": 620.166,
            "value2": 398.466
          },
          {
            "category": "20-10-2024",
            "value": 620.166,
            "value2": 398.466
          },
          {
            "category": "21-10-2024",
            "value": 615.917,
            "value2": 395.605
          },
          {
            "category": "22-10-2024",
            "value": 595.406,
            "value2": 388.073
          },
          {
            "category": "23-10-2024",
            "value": 600.306,
            "value2": 388.43
          },
          {
            "category": "24-10-2024",
            "value": 594.23,
            "value2": 387.596
          },
          {
            "category": "25-10-2024",
            "value": 584.247,
            "value2": 382.656
          },
          {
            "category": "26-10-2024",
            "value": 584.246,
            "value2": 382.656
          },
          {
            "category": "27-10-2024",
            "value": 584.246,
            "value2": 382.656
          },
          {
            "category": "28-10-2024",
            "value": 587.527,
            "value2": 385.241
          },
          {
            "category": "29-10-2024",
            "value": 590.99,
            "value2": 387.811
          },
          {
            "category": "30-10-2024",
            "value": 593.191,
            "value2": 386.991
          },
          {
            "category": "31-10-2024",
            "value": 594.399,
            "value2": 385.885
          },
          {
            "category": "01-11-2024",
            "value": 601.915,
            "value2": 388.192
          },
          {
            "category": "02-11-2024",
            "value": 601.915,
            "value2": 388.192
          },
          {
            "category": "03-11-2024",
            "value": 601.915,
            "value2": 388.192
          },
          {
            "category": "04-11-2024",
            "value": 590.607,
            "value2": 383.045
          },
          {
            "category": "05-11-2024",
            "value": 596.688,
            "value2": 385.694
          },
          {
            "category": "06-11-2024",
            "value": 612.054,
            "value2": 391.772
          },
          {
            "category": "07-11-2024",
            "value": 603.134,
            "value2": 387.817
          },
          {
            "category": "08-11-2024",
            "value": 590.243,
            "value2": 385.256
          },
          {
            "category": "09-11-2024",
            "value": 590.238,
            "value2": 385.256
          },
          {
            "category": "10-11-2024",
            "value": 590.238,
            "value2": 385.256
          },
          {
            "category": "11-11-2024",
            "value": 587.105,
            "value2": 384.354
          },
          {
            "category": "12-11-2024",
            "value": 581.761,
            "value2": 379.837
          },
          {
            "category": "13-11-2024",
            "value": 564.185,
            "value2": 373.053
          },
          {
            "category": "14-11-2024",
            "value": 569.818,
            "value2": 373.828
          },
          {
            "category": "15-11-2024",
            "value": 569.818,
            "value2": 373.828
          },
          {
            "category": "16-11-2024",
            "value": 569.814,
            "value2": 373.828
          },
          {
            "category": "17-11-2024",
            "value": 569.814,
            "value2": 373.828
          },
          {
            "category": "18-11-2024",
            "value": 573.428,
            "value2": 372.719
          },
          {
            "category": "19-11-2024",
            "value": 580.431,
            "value2": 374.452
          },
          {
            "category": "20-11-2024",
            "value": 580.431,
            "value2": 374.452
          },
          {
            "category": "21-11-2024",
            "value": 581.584,
            "value2": 371.324
          },
          {
            "category": "22-11-2024",
            "value": 584.025,
            "value2": 378.331
          },
          {
            "category": "23-11-2024",
            "value": 584.025,
            "value2": 378.331
          },
          {
            "category": "24-11-2024",
            "value": 584.001,
            "value2": 378.331
          },
          {
            "category": "25-11-2024",
            "value": 595.304,
            "value2": 383.946
          },
          {
            "category": "26-11-2024",
            "value": 595.966,
            "value2": 383.969
          },
          {
            "category": "27-11-2024",
            "value": 601.98,
            "value2": 386.157
          },
          {
            "category": "28-11-2024",
            "value": 601.828,
            "value2": 383.148
          },
          {
            "category": "29-11-2024",
            "value": 605.176,
            "value2": 386.1
          },
          {
            "category": "30-11-2024",
            "value": 604.073,
            "value2": 386.1
          }
        ],
        "2": {},
        "3": [
          {
            "category": "03-08-2007",
            "value": 10,
            "value2": 10
          },
          {
            "category": "04-08-2007",
            "value": 9.987,
            "value2": 10
          },
          {
            "category": "05-08-2007",
            "value": 9.9876,
            "value2": 10
          },
          {
            "category": "06-08-2007",
            "value": 9.9981,
            "value2": 9.86
          },
          {
            "category": "07-08-2007",
            "value": 10.0228,
            "value2": 9.9
          },
          {
            "category": "08-08-2007",
            "value": 10.1193,
            "value2": 10.12
          },
          {
            "category": "09-08-2007",
            "value": 10.0082,
            "value2": 9.97
          },
          {
            "category": "10-08-2007",
            "value": 9.923,
            "value2": 9.83
          },
          {
            "category": "11-08-2007",
            "value": 9.923,
            "value2": 9.83
          },
          {
            "category": "12-08-2007",
            "value": 9.923,
            "value2": 9.83
          },
          {
            "category": "13-08-2007",
            "value": 10.0057,
            "value2": 9.93
          },
          {
            "category": "14-08-2007",
            "value": 9.9603,
            "value2": 9.92
          },
          {
            "category": "15-08-2007",
            "value": 9.9603,
            "value2": 9.92
          },
          {
            "category": "16-08-2007",
            "value": 9.5755,
            "value2": 9.51
          },
          {
            "category": "17-08-2007",
            "value": 9.4119,
            "value2": 9.35
          },
          {
            "category": "18-08-2007",
            "value": 9.4119,
            "value2": 9.35
          },
          {
            "category": "19-08-2007",
            "value": 9.4119,
            "value2": 9.35
          },
          {
            "category": "20-08-2007",
            "value": 9.5589,
            "value2": 9.54
          },
          {
            "category": "21-08-2007",
            "value": 9.3392,
            "value2": 9.22
          },
          {
            "category": "22-08-2007",
            "value": 9.3601,
            "value2": 9.35
          },
          {
            "category": "23-08-2007",
            "value": 9.3597,
            "value2": 9.28
          },
          {
            "category": "24-08-2007",
            "value": 9.4152,
            "value2": 9.43
          },
          {
            "category": "25-08-2007",
            "value": 9.416,
            "value2": 9.43
          },
          {
            "category": "26-08-2007",
            "value": 9.4167,
            "value2": 9.43
          },
          {
            "category": "27-08-2007",
            "value": 9.4955,
            "value2": 9.71
          },
          {
            "category": "28-08-2007",
            "value": 9.5046,
            "value2": 9.78
          },
          {
            "category": "29-08-2007",
            "value": 9.5659,
            "value2": 9.83
          },
          {
            "category": "30-08-2007",
            "value": 9.6131,
            "value2": 9.9
          },
          {
            "category": "31-08-2007",
            "value": 9.7256,
            "value2": 10.06
          },
          {
            "category": "01-09-2007",
            "value": 9.7263,
            "value2": 10.06
          },
          {
            "category": "02-09-2007",
            "value": 9.727,
            "value2": 10.06
          },
          {
            "category": "03-09-2007",
            "value": 9.7745,
            "value2": 10.17
          },
          {
            "category": "04-09-2007",
            "value": 9.8273,
            "value2": 10.21
          },
          {
            "category": "05-09-2007",
            "value": 9.8269,
            "value2": 10.21
          },
          {
            "category": "06-09-2007",
            "value": 9.9266,
            "value2": 10.31
          },
          {
            "category": "07-09-2007",
            "value": 9.9058,
            "value2": 10.29
          },
          {
            "category": "08-09-2007",
            "value": 9.906,
            "value2": 10.29
          },
          {
            "category": "09-09-2007",
            "value": 9.9062,
            "value2": 10.29
          },
          {
            "category": "10-09-2007",
            "value": 9.9856,
            "value2": 10.31
          },
          {
            "category": "11-09-2007",
            "value": 9.9978,
            "value2": 10.3
          },
          {
            "category": "12-09-2007",
            "value": 9.9975,
            "value2": 10.31
          },
          {
            "category": "13-09-2007",
            "value": 10.0501,
            "value2": 10.4
          },
          {
            "category": "14-09-2007",
            "value": 9.8844,
            "value2": 10.37
          },
          {
            "category": "15-09-2007",
            "value": 9.8844,
            "value2": 10.37
          },
          {
            "category": "16-09-2007",
            "value": 9.8844,
            "value2": 10.37
          },
          {
            "category": "17-09-2007",
            "value": 9.9359,
            "value2": 10.35
          },
          {
            "category": "18-09-2007",
            "value": 10.0332,
            "value2": 10.47
          },
          {
            "category": "19-09-2007",
            "value": 10.258,
            "value2": 10.82
          },
          {
            "category": "20-09-2007",
            "value": 10.3223,
            "value2": 10.88
          },
          {
            "category": "21-09-2007",
            "value": 10.323,
            "value2": 11.01
          },
          {
            "category": "22-09-2007",
            "value": 10.323,
            "value2": 11.01
          },
          {
            "category": "23-09-2007",
            "value": 10.323,
            "value2": 11.01
          },
          {
            "category": "24-09-2007",
            "value": 10.4496,
            "value2": 11.2
          },
          {
            "category": "25-09-2007",
            "value": 10.3826,
            "value2": 11.21
          },
          {
            "category": "26-09-2007",
            "value": 10.3654,
            "value2": 11.24
          },
          {
            "category": "27-09-2007",
            "value": 10.3783,
            "value2": 11.32
          },
          {
            "category": "28-09-2007",
            "value": 10.4677,
            "value2": 11.45
          },
          {
            "category": "29-09-2007",
            "value": 10.4677,
            "value2": 11.45
          },
          {
            "category": "30-09-2007",
            "value": 10.4182,
            "value2": 11.45
          },
          {
            "category": "01-10-2007",
            "value": 10.6129,
            "value2": 11.54
          },
          {
            "category": "02-10-2007",
            "value": 10.6129,
            "value2": 11.54
          },
          {
            "category": "03-10-2007",
            "value": 10.7758,
            "value2": 11.8
          },
          {
            "category": "04-10-2007",
            "value": 10.7489,
            "value2": 11.79
          },
          {
            "category": "05-10-2007",
            "value": 10.6434,
            "value2": 11.72
          },
          {
            "category": "06-10-2007",
            "value": 10.6434,
            "value2": 11.72
          },
          {
            "category": "07-10-2007",
            "value": 10.6434,
            "value2": 11.72
          },
          {
            "category": "08-10-2007",
            "value": 10.3679,
            "value2": 11.43
          },
          {
            "category": "09-10-2007",
            "value": 10.6427,
            "value2": 11.89
          },
          {
            "category": "10-10-2007",
            "value": 10.8088,
            "value2": 12.13
          },
          {
            "category": "11-10-2007",
            "value": 11.0964,
            "value2": 12.29
          },
          {
            "category": "12-10-2007",
            "value": 11.0074,
            "value2": 12.07
          },
          {
            "category": "13-10-2007",
            "value": 11.0074,
            "value2": 12.07
          },
          {
            "category": "14-10-2007",
            "value": 11.0074,
            "value2": 12.07
          },
          {
            "category": "15-10-2007",
            "value": 11.2693,
            "value2": 12.48
          },
          {
            "category": "16-10-2007",
            "value": 11.2776,
            "value2": 12.54
          },
          {
            "category": "17-10-2007",
            "value": 10.9639,
            "value2": 12.28
          },
          {
            "category": "18-10-2007",
            "value": 10.6379,
            "value2": 11.84
          },
          {
            "category": "19-10-2007",
            "value": 10.3418,
            "value2": 11.51
          },
          {
            "category": "20-10-2007",
            "value": 10.3418,
            "value2": 11.51
          },
          {
            "category": "21-10-2007",
            "value": 10.3418,
            "value2": 11.51
          },
          {
            "category": "22-10-2007",
            "value": 10.3717,
            "value2": 11.55
          },
          {
            "category": "23-10-2007",
            "value": 11.0319,
            "value2": 12.14
          },
          {
            "category": "24-10-2007",
            "value": 11.0483,
            "value2": 12.23
          },
          {
            "category": "25-10-2007",
            "value": 11.1995,
            "value2": 12.38
          },
          {
            "category": "26-10-2007",
            "value": 11.4268,
            "value2": 12.7
          },
          {
            "category": "27-10-2007",
            "value": 11.4268,
            "value2": 12.7
          },
          {
            "category": "28-10-2007",
            "value": 11.4268,
            "value2": 12.7
          },
          {
            "category": "29-10-2007",
            "value": 11.7617,
            "value2": 13.1
          },
          {
            "category": "30-10-2007",
            "value": 11.6299,
            "value2": 13.07
          },
          {
            "category": "31-10-2007",
            "value": 11.6174,
            "value2": 13.16
          },
          {
            "category": "01-11-2007",
            "value": 11.61,
            "value2": 13.03
          },
          {
            "category": "02-11-2007",
            "value": 11.6154,
            "value2": 13.19
          },
          {
            "category": "03-11-2007",
            "value": 11.5812,
            "value2": 13.19
          },
          {
            "category": "04-11-2007",
            "value": 11.5812,
            "value2": 13.19
          },
          {
            "category": "05-11-2007",
            "value": 11.5609,
            "value2": 13.09
          },
          {
            "category": "06-11-2007",
            "value": 11.5442,
            "value2": 12.96
          },
          {
            "category": "07-11-2007",
            "value": 11.5377,
            "value2": 12.91
          },
          {
            "category": "08-11-2007",
            "value": 11.5298,
            "value2": 12.78
          },
          {
            "category": "09-11-2007",
            "value": 11.5313,
            "value2": 12.74
          },
          {
            "category": "10-11-2007",
            "value": 11.5346,
            "value2": 12.74
          },
          {
            "category": "11-11-2007",
            "value": 11.5364,
            "value2": 12.74
          },
          {
            "category": "12-11-2007",
            "value": 11.5303,
            "value2": 12.62
          },
          {
            "category": "13-11-2007",
            "value": 11.5155,
            "value2": 12.84
          },
          {
            "category": "14-11-2007",
            "value": 11.5165,
            "value2": 13.34
          },
          {
            "category": "15-11-2007",
            "value": 11.514,
            "value2": 13.37
          },
          {
            "category": "16-11-2007",
            "value": 11.5093,
            "value2": 13.4
          },
          {
            "category": "17-11-2007",
            "value": 11.4959,
            "value2": 13.4
          },
          {
            "category": "18-11-2007",
            "value": 11.4977,
            "value2": 13.4
          },
          {
            "category": "19-11-2007",
            "value": 11.4796,
            "value2": 13.52
          },
          {
            "category": "20-11-2007",
            "value": 11.4492,
            "value2": 13.28
          },
          {
            "category": "21-11-2007",
            "value": 11.4287,
            "value2": 12.72
          },
          {
            "category": "22-11-2007",
            "value": 11.4054,
            "value2": 12.63
          },
          {
            "category": "23-11-2007",
            "value": 11.3942,
            "value2": 12.86
          },
          {
            "category": "24-11-2007",
            "value": 11.3949,
            "value2": 12.86
          },
          {
            "category": "25-11-2007",
            "value": 11.3966,
            "value2": 12.86
          },
          {
            "category": "26-11-2007",
            "value": 11.3732,
            "value2": 13.12
          },
          {
            "category": "27-11-2007",
            "value": 11.3433,
            "value2": 13.09
          },
          {
            "category": "28-11-2007",
            "value": 11.3118,
            "value2": 12.96
          },
          {
            "category": "29-11-2007",
            "value": 11.3097,
            "value2": 12.98
          },
          {
            "category": "30-11-2007",
            "value": 11.3088,
            "value2": 13.3
          },
          {
            "category": "01-12-2007",
            "value": 11.2919,
            "value2": 13.3
          },
          {
            "category": "02-12-2007",
            "value": 11.2937,
            "value2": 13.3
          },
          {
            "category": "03-12-2007",
            "value": 11.285,
            "value2": 13.56
          },
          {
            "category": "04-12-2007",
            "value": 11.2774,
            "value2": 13.62
          },
          {
            "category": "05-12-2007",
            "value": 11.2591,
            "value2": 13.81
          },
          {
            "category": "06-12-2007",
            "value": 11.2574,
            "value2": 13.83
          },
          {
            "category": "07-12-2007",
            "value": 11.2354,
            "value2": 13.89
          },
          {
            "category": "08-12-2007",
            "value": 11.2368,
            "value2": 13.89
          },
          {
            "category": "09-12-2007",
            "value": 11.2154,
            "value2": 13.89
          },
          {
            "category": "10-12-2007",
            "value": 11.2093,
            "value2": 13.93
          },
          {
            "category": "11-12-2007",
            "value": 11.2073,
            "value2": 14.13
          },
          {
            "category": "12-12-2007",
            "value": 11.1772,
            "value2": 14.29
          },
          {
            "category": "13-12-2007",
            "value": 11.1585,
            "value2": 14.18
          },
          {
            "category": "14-12-2007",
            "value": 11.1505,
            "value2": 14.18
          },
          {
            "category": "15-12-2007",
            "value": 11.15,
            "value2": 14.18
          },
          {
            "category": "16-12-2007",
            "value": 11.1513,
            "value2": 14.18
          },
          {
            "category": "17-12-2007",
            "value": 11.0533,
            "value2": 13.58
          },
          {
            "category": "18-12-2007",
            "value": 11.0573,
            "value2": 13.52
          },
          {
            "category": "19-12-2007",
            "value": 11.0457,
            "value2": 13.53
          },
          {
            "category": "20-12-2007",
            "value": 11.0001,
            "value2": 13.52
          },
          {
            "category": "21-12-2007",
            "value": 11.0012,
            "value2": 13.52
          },
          {
            "category": "22-12-2007",
            "value": 11.0024,
            "value2": 13.52
          },
          {
            "category": "23-12-2007",
            "value": 11.0036,
            "value2": 13.52
          },
          {
            "category": "24-12-2007",
            "value": 11.058,
            "value2": 13.93
          },
          {
            "category": "25-12-2007",
            "value": 11.059,
            "value2": 13.93
          },
          {
            "category": "26-12-2007",
            "value": 11.1249,
            "value2": 14.22
          },
          {
            "category": "27-12-2007",
            "value": 11.2166,
            "value2": 14.24
          },
          {
            "category": "28-12-2007",
            "value": 11.2152,
            "value2": 14.36
          },
          {
            "category": "29-12-2007",
            "value": 11.2162,
            "value2": 14.36
          },
          {
            "category": "30-12-2007",
            "value": 11.2172,
            "value2": 14.36
          },
          {
            "category": "31-12-2007",
            "value": 11.3043,
            "value2": 14.52
          },
          {
            "category": "01-01-2008",
            "value": 11.2821,
            "value2": 14.64
          },
          {
            "category": "02-01-2008",
            "value": 11.3815,
            "value2": 14.82
          },
          {
            "category": "03-01-2008",
            "value": 11.4537,
            "value2": 14.78
          },
          {
            "category": "04-01-2008",
            "value": 11.3851,
            "value2": 14.96
          },
          {
            "category": "05-01-2008",
            "value": 11.3854,
            "value2": 14.96
          },
          {
            "category": "06-01-2008",
            "value": 11.3862,
            "value2": 14.96
          },
          {
            "category": "07-01-2008",
            "value": 11.4317,
            "value2": 15.01
          },
          {
            "category": "08-01-2008",
            "value": 11.3397,
            "value2": 14.87
          },
          {
            "category": "09-01-2008",
            "value": 11.2934,
            "value2": 14.84
          },
          {
            "category": "10-01-2008",
            "value": 11.1031,
            "value2": 14.48
          },
          {
            "category": "11-01-2008",
            "value": 11.0696,
            "value2": 14.58
          },
          {
            "category": "12-01-2008",
            "value": 11.0698,
            "value2": 14.58
          },
          {
            "category": "13-01-2008",
            "value": 11.07,
            "value2": 14.58
          },
          {
            "category": "14-01-2008",
            "value": 11.1031,
            "value2": 14.65
          },
          {
            "category": "15-01-2008",
            "value": 11.033,
            "value2": 14.38
          },
          {
            "category": "16-01-2008",
            "value": 10.8654,
            "value2": 14.1
          },
          {
            "category": "17-01-2008",
            "value": 11.0046,
            "value2": 14.07
          },
          {
            "category": "18-01-2008",
            "value": 10.7144,
            "value2": 13.48
          },
          {
            "category": "19-01-2008",
            "value": 10.7146,
            "value2": 13.48
          },
          {
            "category": "20-01-2008",
            "value": 10.7148,
            "value2": 13.48
          },
          {
            "category": "21-01-2008",
            "value": 9.839,
            "value2": 12.16
          },
          {
            "category": "22-01-2008",
            "value": 9.4312,
            "value2": 11.39
          },
          {
            "category": "23-01-2008",
            "value": 10.191,
            "value2": 12.14
          },
          {
            "category": "24-01-2008",
            "value": 9.879,
            "value2": 11.78
          },
          {
            "category": "25-01-2008",
            "value": 10.3654,
            "value2": 12.6
          },
          {
            "category": "26-01-2008",
            "value": 10.3653,
            "value2": 12.6
          },
          {
            "category": "27-01-2008",
            "value": 10.3653,
            "value2": 12.6
          },
          {
            "category": "28-01-2008",
            "value": 10.4745,
            "value2": 12.49
          },
          {
            "category": "29-01-2008",
            "value": 10.393,
            "value2": 12.46
          },
          {
            "category": "30-01-2008",
            "value": 10.2444,
            "value2": 12.19
          },
          {
            "category": "31-01-2008",
            "value": 10.2678,
            "value2": 12.1
          },
          {
            "category": "01-02-2008",
            "value": 10.3003,
            "value2": 12.34
          },
          {
            "category": "02-02-2008",
            "value": 10.2999,
            "value2": 12.34
          },
          {
            "category": "03-02-2008",
            "value": 10.2999,
            "value2": 12.34
          },
          {
            "category": "04-02-2008",
            "value": 10.5061,
            "value2": 12.69
          },
          {
            "category": "05-02-2008",
            "value": 10.4829,
            "value2": 12.74
          },
          {
            "category": "06-02-2008",
            "value": 10.3295,
            "value2": 12.44
          },
          {
            "category": "07-02-2008",
            "value": 10.0637,
            "value2": 12.05
          },
          {
            "category": "08-02-2008",
            "value": 9.8933,
            "value2": 11.91
          },
          {
            "category": "09-02-2008",
            "value": 9.8933,
            "value2": 11.91
          },
          {
            "category": "10-02-2008",
            "value": 9.8933,
            "value2": 11.91
          },
          {
            "category": "11-02-2008",
            "value": 9.5868,
            "value2": 11.29
          },
          {
            "category": "12-02-2008",
            "value": 9.5544,
            "value2": 11.21
          },
          {
            "category": "13-02-2008",
            "value": 9.425,
            "value2": 11.38
          },
          {
            "category": "14-02-2008",
            "value": 9.7697,
            "value2": 11.98
          },
          {
            "category": "15-02-2008",
            "value": 9.996,
            "value2": 12.23
          },
          {
            "category": "16-02-2008",
            "value": 9.9952,
            "value2": 12.23
          },
          {
            "category": "17-02-2008",
            "value": 9.9952,
            "value2": 12.23
          },
          {
            "category": "18-02-2008",
            "value": 9.9352,
            "value2": 12.23
          },
          {
            "category": "19-02-2008",
            "value": 9.9769,
            "value2": 12.24
          },
          {
            "category": "20-02-2008",
            "value": 9.8089,
            "value2": 11.96
          },
          {
            "category": "21-02-2008",
            "value": 9.7992,
            "value2": 12.04
          },
          {
            "category": "22-02-2008",
            "value": 9.7274,
            "value2": 11.83
          },
          {
            "category": "23-02-2008",
            "value": 9.7273,
            "value2": 11.83
          },
          {
            "category": "24-02-2008",
            "value": 9.7273,
            "value2": 11.83
          },
          {
            "category": "25-02-2008",
            "value": 9.7413,
            "value2": 11.96
          },
          {
            "category": "26-02-2008",
            "value": 9.8373,
            "value2": 12.11
          },
          {
            "category": "27-02-2008",
            "value": 9.9638,
            "value2": 12.13
          },
          {
            "category": "28-02-2008",
            "value": 9.8821,
            "value2": 12.13
          },
          {
            "category": "29-02-2008",
            "value": 9.833,
            "value2": 12.02
          },
          {
            "category": "01-03-2008",
            "value": 9.833,
            "value2": 12.02
          },
          {
            "category": "02-03-2008",
            "value": 9.833,
            "value2": 12.02
          },
          {
            "category": "03-03-2008",
            "value": 9.5586,
            "value2": 11.41
          },
          {
            "category": "04-03-2008",
            "value": 9.4133,
            "value2": 11.15
          },
          {
            "category": "05-03-2008",
            "value": 9.3648,
            "value2": 11.18
          },
          {
            "category": "06-03-2008",
            "value": 9.3648,
            "value2": 11.18
          },
          {
            "category": "07-03-2008",
            "value": 8.9266,
            "value2": 10.74
          },
          {
            "category": "08-03-2008",
            "value": 8.9266,
            "value2": 10.74
          },
          {
            "category": "09-03-2008",
            "value": 8.9266,
            "value2": 10.74
          },
          {
            "category": "10-03-2008",
            "value": 8.8782,
            "value2": 10.72
          },
          {
            "category": "11-03-2008",
            "value": 9.1422,
            "value2": 10.97
          },
          {
            "category": "12-03-2008",
            "value": 9.0746,
            "value2": 10.95
          },
          {
            "category": "13-03-2008",
            "value": 8.7383,
            "value2": 10.35
          },
          {
            "category": "14-03-2008",
            "value": 8.7048,
            "value2": 10.58
          },
          {
            "category": "15-03-2008",
            "value": 8.7048,
            "value2": 10.58
          },
          {
            "category": "16-03-2008",
            "value": 8.7048,
            "value2": 10.58
          },
          {
            "category": "17-03-2008",
            "value": 8.2198,
            "value2": 9.9
          },
          {
            "category": "18-03-2008",
            "value": 8.0618,
            "value2": 9.87
          },
          {
            "category": "19-03-2008",
            "value": 8.0387,
            "value2": 9.89
          },
          {
            "category": "20-03-2008",
            "value": 8.0387,
            "value2": 9.89
          },
          {
            "category": "21-03-2008",
            "value": 8.0387,
            "value2": 9.89
          },
          {
            "category": "22-03-2008",
            "value": 8.0387,
            "value2": 9.89
          },
          {
            "category": "23-03-2008",
            "value": 8.0387,
            "value2": 9.89
          },
          {
            "category": "24-03-2008",
            "value": 7.9362,
            "value2": 9.9
          },
          {
            "category": "25-03-2008",
            "value": 8.3349,
            "value2": 10.52
          },
          {
            "category": "26-03-2008",
            "value": 8.4786,
            "value2": 10.52
          },
          {
            "category": "27-03-2008",
            "value": 8.4221,
            "value2": 10.5
          },
          {
            "category": "28-03-2008",
            "value": 8.6307,
            "value2": 10.81
          },
          {
            "category": "29-03-2008",
            "value": 8.6307,
            "value2": 10.81
          },
          {
            "category": "30-03-2008",
            "value": 8.6307,
            "value2": 10.81
          },
          {
            "category": "31-03-2008",
            "value": 8.4814,
            "value2": 10.41
          },
          {
            "category": "01-04-2008",
            "value": 8.4229,
            "value2": 10.39
          },
          {
            "category": "02-04-2008",
            "value": 8.4378,
            "value2": 10.46
          },
          {
            "category": "03-04-2008",
            "value": 8.4173,
            "value2": 10.49
          },
          {
            "category": "04-04-2008",
            "value": 8.3109,
            "value2": 10.22
          },
          {
            "category": "05-04-2008",
            "value": 8.3109,
            "value2": 10.22
          },
          {
            "category": "06-04-2008",
            "value": 8.3109,
            "value2": 10.22
          },
          {
            "category": "07-04-2008",
            "value": 8.3567,
            "value2": 10.46
          },
          {
            "category": "08-04-2008",
            "value": 8.4089,
            "value2": 10.4
          },
          {
            "category": "09-04-2008",
            "value": 8.5128,
            "value2": 10.54
          },
          {
            "category": "10-04-2008",
            "value": 8.4904,
            "value2": 10.51
          },
          {
            "category": "11-04-2008",
            "value": 8.5105,
            "value2": 10.6
          },
          {
            "category": "12-04-2008",
            "value": 8.5105,
            "value2": 10.6
          },
          {
            "category": "13-04-2008",
            "value": 8.5105,
            "value2": 10.6
          },
          {
            "category": "14-04-2008",
            "value": 8.5105,
            "value2": 10.6
          },
          {
            "category": "15-04-2008",
            "value": 8.5752,
            "value2": 10.81
          },
          {
            "category": "16-04-2008",
            "value": 8.6056,
            "value2": 10.89
          },
          {
            "category": "17-04-2008",
            "value": 8.7412,
            "value2": 11.08
          },
          {
            "category": "18-04-2008",
            "value": 8.7412,
            "value2": 11.08
          },
          {
            "category": "19-04-2008",
            "value": 8.7412,
            "value2": 11.08
          },
          {
            "category": "20-04-2008",
            "value": 8.7412,
            "value2": 11.08
          },
          {
            "category": "21-04-2008",
            "value": 8.8816,
            "value2": 11.28
          },
          {
            "category": "22-04-2008",
            "value": 8.9965,
            "value2": 11.35
          },
          {
            "category": "23-04-2008",
            "value": 8.9992,
            "value2": 11.31
          },
          {
            "category": "24-04-2008",
            "value": 8.9166,
            "value2": 11.28
          },
          {
            "category": "25-04-2008",
            "value": 8.9369,
            "value2": 11.49
          },
          {
            "category": "26-04-2008",
            "value": 8.9369,
            "value2": 11.49
          },
          {
            "category": "27-04-2008",
            "value": 8.9369,
            "value2": 11.49
          },
          {
            "category": "28-04-2008",
            "value": 8.9382,
            "value2": 11.46
          },
          {
            "category": "29-04-2008",
            "value": 8.9834,
            "value2": 11.68
          },
          {
            "category": "30-04-2008",
            "value": 9.1104,
            "value2": 11.64
          },
          {
            "category": "01-05-2008",
            "value": 9.0978,
            "value2": 11.64
          },
          {
            "category": "02-05-2008",
            "value": 9.1741,
            "value2": 11.82
          },
          {
            "category": "03-05-2008",
            "value": 9.1741,
            "value2": 11.82
          },
          {
            "category": "04-05-2008",
            "value": 9.1741,
            "value2": 11.82
          },
          {
            "category": "05-05-2008",
            "value": 9.1858,
            "value2": 11.8
          },
          {
            "category": "06-05-2008",
            "value": 9.1239,
            "value2": 11.69
          },
          {
            "category": "07-05-2008",
            "value": 9.1539,
            "value2": 11.66
          },
          {
            "category": "08-05-2008",
            "value": 9.0339,
            "value2": 11.52
          },
          {
            "category": "09-05-2008",
            "value": 8.9217,
            "value2": 11.25
          },
          {
            "category": "10-05-2008",
            "value": 8.9217,
            "value2": 11.25
          },
          {
            "category": "11-05-2008",
            "value": 8.9217,
            "value2": 11.25
          },
          {
            "category": "12-05-2008",
            "value": 8.8327,
            "value2": 11.29
          },
          {
            "category": "13-05-2008",
            "value": 8.8733,
            "value2": 11.23
          },
          {
            "category": "14-05-2008",
            "value": 8.9254,
            "value2": 11.36
          },
          {
            "category": "15-05-2008",
            "value": 9.0052,
            "value2": 11.58
          },
          {
            "category": "16-05-2008",
            "value": 9.0365,
            "value2": 11.67
          },
          {
            "category": "17-05-2008",
            "value": 9.0365,
            "value2": 11.67
          },
          {
            "category": "18-05-2008",
            "value": 9.0365,
            "value2": 11.67
          },
          {
            "category": "19-05-2008",
            "value": 9.0365,
            "value2": 11.67
          },
          {
            "category": "20-05-2008",
            "value": 9.0092,
            "value2": 11.58
          },
          {
            "category": "21-05-2008",
            "value": 9.0197,
            "value2": 11.63
          },
          {
            "category": "22-05-2008",
            "value": 8.9116,
            "value2": 11.41
          },
          {
            "category": "23-05-2008",
            "value": 8.8303,
            "value2": 11.24
          },
          {
            "category": "24-05-2008",
            "value": 8.8303,
            "value2": 11.24
          },
          {
            "category": "25-05-2008",
            "value": 8.8303,
            "value2": 11.24
          },
          {
            "category": "26-05-2008",
            "value": 8.6762,
            "value2": 11.01
          },
          {
            "category": "27-05-2008",
            "value": 8.6637,
            "value2": 10.93
          },
          {
            "category": "28-05-2008",
            "value": 8.7762,
            "value2": 11.07
          },
          {
            "category": "29-05-2008",
            "value": 8.7033,
            "value2": 10.97
          },
          {
            "category": "30-05-2008",
            "value": 8.6658,
            "value2": 10.97
          },
          {
            "category": "31-05-2008",
            "value": 8.6702,
            "value2": 10.97
          },
          {
            "category": "01-06-2008",
            "value": 8.6702,
            "value2": 10.97
          },
          {
            "category": "02-06-2008",
            "value": 8.468,
            "value2": 10.71
          },
          {
            "category": "03-06-2008",
            "value": 8.4144,
            "value2": 10.64
          },
          {
            "category": "04-06-2008",
            "value": 8.1918,
            "value2": 10.31
          },
          {
            "category": "05-06-2008",
            "value": 8.2324,
            "value2": 10.45
          },
          {
            "category": "06-06-2008",
            "value": 8.2198,
            "value2": 10.35
          },
          {
            "category": "07-06-2008",
            "value": 8.2198,
            "value2": 10.35
          },
          {
            "category": "08-06-2008",
            "value": 8.2198,
            "value2": 10.35
          },
          {
            "category": "09-06-2008",
            "value": 8.0098,
            "value2": 10.04
          },
          {
            "category": "10-06-2008",
            "value": 7.9686,
            "value2": 9.94
          },
          {
            "category": "11-06-2008",
            "value": 8.0402,
            "value2": 10.09
          },
          {
            "category": "12-06-2008",
            "value": 7.9909,
            "value2": 10.15
          },
          {
            "category": "13-06-2008",
            "value": 7.9869,
            "value2": 10.11
          },
          {
            "category": "14-06-2008",
            "value": 7.9869,
            "value2": 10.11
          },
          {
            "category": "15-06-2008",
            "value": 7.9869,
            "value2": 10.11
          },
          {
            "category": "16-06-2008",
            "value": 8.0158,
            "value2": 10.25
          },
          {
            "category": "17-06-2008",
            "value": 8.146,
            "value2": 10.45
          },
          {
            "category": "18-06-2008",
            "value": 8.1384,
            "value2": 10.3
          },
          {
            "category": "19-06-2008",
            "value": 8.0157,
            "value2": 10.09
          },
          {
            "category": "20-06-2008",
            "value": 7.8166,
            "value2": 9.74
          },
          {
            "category": "21-06-2008",
            "value": 7.8166,
            "value2": 9.74
          },
          {
            "category": "22-06-2008",
            "value": 7.8166,
            "value2": 9.74
          },
          {
            "category": "23-06-2008",
            "value": 7.5934,
            "value2": 9.47
          },
          {
            "category": "24-06-2008",
            "value": 7.5154,
            "value2": 9.31
          },
          {
            "category": "25-06-2008",
            "value": 7.4651,
            "value2": 9.4
          },
          {
            "category": "26-06-2008",
            "value": 7.4592,
            "value2": 9.48
          },
          {
            "category": "27-06-2008",
            "value": 7.2806,
            "value2": 9.1
          },
          {
            "category": "28-06-2008",
            "value": 7.2806,
            "value2": 9.1
          },
          {
            "category": "29-06-2008",
            "value": 7.2806,
            "value2": 9.1
          },
          {
            "category": "30-06-2008",
            "value": 7.0665,
            "value2": 8.85
          },
          {
            "category": "01-07-2008",
            "value": 6.69,
            "value2": 8.49
          },
          {
            "category": "02-07-2008",
            "value": 6.8992,
            "value2": 8.9
          },
          {
            "category": "03-07-2008",
            "value": 6.7347,
            "value2": 8.53
          },
          {
            "category": "04-07-2008",
            "value": 6.9041,
            "value2": 8.76
          },
          {
            "category": "05-07-2008",
            "value": 6.9041,
            "value2": 8.76
          },
          {
            "category": "06-07-2008",
            "value": 6.9041,
            "value2": 8.76
          },
          {
            "category": "07-07-2008",
            "value": 7.0205,
            "value2": 8.83
          },
          {
            "category": "08-07-2008",
            "value": 7.0367,
            "value2": 8.76
          },
          {
            "category": "09-07-2008",
            "value": 7.2247,
            "value2": 9.13
          },
          {
            "category": "10-07-2008",
            "value": 7.1832,
            "value2": 9.14
          },
          {
            "category": "11-07-2008",
            "value": 7.0628,
            "value2": 8.88
          },
          {
            "category": "12-07-2008",
            "value": 7.0628,
            "value2": 8.88
          },
          {
            "category": "13-07-2008",
            "value": 7.0628,
            "value2": 8.88
          },
          {
            "category": "14-07-2008",
            "value": 7.0156,
            "value2": 8.81
          },
          {
            "category": "15-07-2008",
            "value": 6.785,
            "value2": 8.42
          },
          {
            "category": "16-07-2008",
            "value": 6.6645,
            "value2": 8.32
          },
          {
            "category": "17-07-2008",
            "value": 6.758,
            "value2": 8.58
          },
          {
            "category": "18-07-2008",
            "value": 6.9063,
            "value2": 8.86
          },
          {
            "category": "19-07-2008",
            "value": 6.9063,
            "value2": 8.86
          },
          {
            "category": "20-07-2008",
            "value": 6.9063,
            "value2": 8.86
          },
          {
            "category": "21-07-2008",
            "value": 6.9007,
            "value2": 8.95
          },
          {
            "category": "22-07-2008",
            "value": 6.8901,
            "value2": 9.14
          },
          {
            "category": "23-07-2008",
            "value": 7.128,
            "value2": 9.69
          },
          {
            "category": "24-07-2008",
            "value": 7.1018,
            "value2": 9.6
          },
          {
            "category": "25-07-2008",
            "value": 7.0719,
            "value2": 9.38
          },
          {
            "category": "26-07-2008",
            "value": 7.0719,
            "value2": 9.38
          },
          {
            "category": "27-07-2008",
            "value": 7.0719,
            "value2": 9.38
          },
          {
            "category": "28-07-2008",
            "value": 7.1263,
            "value2": 9.43
          },
          {
            "category": "29-07-2008",
            "value": 6.9561,
            "value2": 9.09
          },
          {
            "category": "30-07-2008",
            "value": 7.0793,
            "value2": 9.39
          },
          {
            "category": "31-07-2008",
            "value": 7.0672,
            "value2": 9.41
          },
          {
            "category": "01-08-2008",
            "value": 7.101,
            "value2": 9.61
          },
          {
            "category": "02-08-2008",
            "value": 7.101,
            "value2": 9.61
          },
          {
            "category": "03-08-2008",
            "value": 7.101,
            "value2": 9.61
          },
          {
            "category": "04-08-2008",
            "value": 7.1281,
            "value2": 9.66
          },
          {
            "category": "05-08-2008",
            "value": 7.3029,
            "value2": 9.88
          },
          {
            "category": "06-08-2008",
            "value": 7.3056,
            "value2": 9.9
          },
          {
            "category": "07-08-2008",
            "value": 7.3587,
            "value2": 9.93
          },
          {
            "category": "08-08-2008",
            "value": 7.3842,
            "value2": 9.96
          },
          {
            "category": "09-08-2008",
            "value": 7.3842,
            "value2": 9.96
          },
          {
            "category": "10-08-2008",
            "value": 7.3842,
            "value2": 9.96
          },
          {
            "category": "11-08-2008",
            "value": 7.518,
            "value2": 10.16
          },
          {
            "category": "12-08-2008",
            "value": 7.4632,
            "value2": 10
          },
          {
            "category": "13-08-2008",
            "value": 7.4111,
            "value2": 9.96
          },
          {
            "category": "14-08-2008",
            "value": 7.2579,
            "value2": 9.72
          },
          {
            "category": "15-08-2008",
            "value": 7.2579,
            "value2": 9.72
          },
          {
            "category": "16-08-2008",
            "value": 7.2579,
            "value2": 9.72
          },
          {
            "category": "17-08-2008",
            "value": 7.2579,
            "value2": 9.72
          },
          {
            "category": "18-08-2008",
            "value": 7.2319,
            "value2": 9.64
          },
          {
            "category": "19-08-2008",
            "value": 7.1858,
            "value2": 9.59
          },
          {
            "category": "20-08-2008",
            "value": 7.2244,
            "value2": 9.69
          },
          {
            "category": "21-08-2008",
            "value": 7.1368,
            "value2": 9.42
          },
          {
            "category": "22-08-2008",
            "value": 7.1251,
            "value2": 9.5
          },
          {
            "category": "23-08-2008",
            "value": 7.1251,
            "value2": 9.5
          },
          {
            "category": "24-08-2008",
            "value": 7.1251,
            "value2": 9.5
          },
          {
            "category": "25-08-2008",
            "value": 7.2025,
            "value2": 9.53
          },
          {
            "category": "26-08-2008",
            "value": 7.2551,
            "value2": 9.54
          },
          {
            "category": "27-08-2008",
            "value": 7.2197,
            "value2": 9.44
          },
          {
            "category": "28-08-2008",
            "value": 7.1738,
            "value2": 9.3
          },
          {
            "category": "29-08-2008",
            "value": 7.318,
            "value2": 9.61
          },
          {
            "category": "30-08-2008",
            "value": 7.318,
            "value2": 9.61
          },
          {
            "category": "31-08-2008",
            "value": 7.318,
            "value2": 9.61
          },
          {
            "category": "01-09-2008",
            "value": 7.3315,
            "value2": 9.58
          },
          {
            "category": "02-09-2008",
            "value": 7.4843,
            "value2": 9.88
          },
          {
            "category": "03-09-2008",
            "value": 7.4843,
            "value2": 9.88
          },
          {
            "category": "04-09-2008",
            "value": 7.4318,
            "value2": 9.8
          },
          {
            "category": "05-09-2008",
            "value": 7.3545,
            "value2": 9.58
          },
          {
            "category": "06-09-2008",
            "value": 7.3545,
            "value2": 9.58
          },
          {
            "category": "07-09-2008",
            "value": 7.3545,
            "value2": 9.58
          },
          {
            "category": "08-09-2008",
            "value": 7.4324,
            "value2": 9.82
          },
          {
            "category": "09-09-2008",
            "value": 7.3668,
            "value2": 9.77
          },
          {
            "category": "10-09-2008",
            "value": 7.3293,
            "value2": 9.64
          },
          {
            "category": "11-09-2008",
            "value": 7.2594,
            "value2": 9.46
          },
          {
            "category": "12-09-2008",
            "value": 7.1938,
            "value2": 9.27
          },
          {
            "category": "13-09-2008",
            "value": 7.1938,
            "value2": 9.27
          },
          {
            "category": "14-09-2008",
            "value": 7.1938,
            "value2": 9.27
          },
          {
            "category": "15-09-2008",
            "value": 6.9934,
            "value2": 8.92
          },
          {
            "category": "16-09-2008",
            "value": 6.9659,
            "value2": 8.88
          },
          {
            "category": "17-09-2008",
            "value": 6.9201,
            "value2": 8.71
          },
          {
            "category": "18-09-2008",
            "value": 6.8542,
            "value2": 8.71
          },
          {
            "category": "19-09-2008",
            "value": 6.9835,
            "value2": 9.11
          },
          {
            "category": "20-09-2008",
            "value": 6.9835,
            "value2": 9.11
          },
          {
            "category": "21-09-2008",
            "value": 6.9835,
            "value2": 9.11
          },
          {
            "category": "22-09-2008",
            "value": 6.8926,
            "value2": 9.08
          },
          {
            "category": "23-09-2008",
            "value": 6.8267,
            "value2": 8.86
          },
          {
            "category": "24-09-2008",
            "value": 6.8258,
            "value2": 8.92
          },
          {
            "category": "25-09-2008",
            "value": 6.7811,
            "value2": 8.84
          },
          {
            "category": "26-09-2008",
            "value": 6.6377,
            "value2": 8.56
          },
          {
            "category": "27-09-2008",
            "value": 6.6377,
            "value2": 8.56
          },
          {
            "category": "28-09-2008",
            "value": 6.6377,
            "value2": 8.56
          },
          {
            "category": "29-09-2008",
            "value": 6.4629,
            "value2": 8.21
          },
          {
            "category": "30-09-2008",
            "value": 6.6027,
            "value2": 8.36
          },
          {
            "category": "01-10-2008",
            "value": 6.6345,
            "value2": 8.46
          },
          {
            "category": "02-10-2008",
            "value": 6.6345,
            "value2": 8.46
          },
          {
            "category": "03-10-2008",
            "value": 6.4569,
            "value2": 8.15
          },
          {
            "category": "04-10-2008",
            "value": 6.4569,
            "value2": 8.15
          },
          {
            "category": "05-10-2008",
            "value": 6.4569,
            "value2": 8.15
          },
          {
            "category": "06-10-2008",
            "value": 6.1996,
            "value2": 7.65
          },
          {
            "category": "07-10-2008",
            "value": 6.1407,
            "value2": 7.55
          },
          {
            "category": "08-10-2008",
            "value": 5.9098,
            "value2": 7.26
          },
          {
            "category": "09-10-2008",
            "value": 5.9098,
            "value2": 7.26
          },
          {
            "category": "10-10-2008",
            "value": 5.5879,
            "value2": 6.72
          },
          {
            "category": "11-10-2008",
            "value": 5.5879,
            "value2": 6.72
          },
          {
            "category": "12-10-2008",
            "value": 5.5879,
            "value2": 6.72
          },
          {
            "category": "13-10-2008",
            "value": 5.7195,
            "value2": 7.16
          },
          {
            "category": "14-10-2008",
            "value": 5.8035,
            "value2": 7.27
          },
          {
            "category": "15-10-2008",
            "value": 5.6549,
            "value2": 6.89
          },
          {
            "category": "16-10-2008",
            "value": 5.6241,
            "value2": 6.77
          },
          {
            "category": "17-10-2008",
            "value": 5.4905,
            "value2": 6.44
          },
          {
            "category": "18-10-2008",
            "value": 5.4905,
            "value2": 6.44
          },
          {
            "category": "19-10-2008",
            "value": 5.4905,
            "value2": 6.44
          },
          {
            "category": "20-10-2008",
            "value": 5.5646,
            "value2": 6.54
          },
          {
            "category": "21-10-2008",
            "value": 5.7207,
            "value2": 6.78
          },
          {
            "category": "22-10-2008",
            "value": 5.5789,
            "value2": 6.48
          },
          {
            "category": "23-10-2008",
            "value": 5.4075,
            "value2": 6.22
          },
          {
            "category": "24-10-2008",
            "value": 4.9872,
            "value2": 5.57
          },
          {
            "category": "25-10-2008",
            "value": 4.9872,
            "value2": 5.57
          },
          {
            "category": "26-10-2008",
            "value": 4.9872,
            "value2": 5.57
          },
          {
            "category": "27-10-2008",
            "value": 4.7527,
            "value2": 5.4
          },
          {
            "category": "28-10-2008",
            "value": 4.9498,
            "value2": 5.73
          },
          {
            "category": "29-10-2008",
            "value": 4.9008,
            "value2": 5.73
          },
          {
            "category": "30-10-2008",
            "value": 4.9008,
            "value2": 5.73
          },
          {
            "category": "31-10-2008",
            "value": 5.0276,
            "value2": 6.1
          },
          {
            "category": "01-11-2008",
            "value": 5.0253,
            "value2": 6.1
          },
          {
            "category": "02-11-2008",
            "value": 5.0253,
            "value2": 6.1
          },
          {
            "category": "03-11-2008",
            "value": 5.1707,
            "value2": 6.42
          },
          {
            "category": "04-11-2008",
            "value": 5.3186,
            "value2": 6.63
          },
          {
            "category": "05-11-2008",
            "value": 5.3089,
            "value2": 6.38
          },
          {
            "category": "06-11-2008",
            "value": 5.2113,
            "value2": 6.17
          },
          {
            "category": "07-11-2008",
            "value": 5.2725,
            "value2": 6.31
          },
          {
            "category": "08-11-2008",
            "value": 5.2725,
            "value2": 6.31
          },
          {
            "category": "09-11-2008",
            "value": 5.2725,
            "value2": 6.31
          },
          {
            "category": "10-11-2008",
            "value": 5.3746,
            "value2": 6.63
          },
          {
            "category": "11-11-2008",
            "value": 5.2422,
            "value2": 6.25
          },
          {
            "category": "12-11-2008",
            "value": 5.1469,
            "value2": 6.08
          },
          {
            "category": "13-11-2008",
            "value": 5.1469,
            "value2": 6.08
          },
          {
            "category": "14-11-2008",
            "value": 5.0658,
            "value2": 5.97
          },
          {
            "category": "15-11-2008",
            "value": 5.0658,
            "value2": 5.97
          },
          {
            "category": "16-11-2008",
            "value": 5.0658,
            "value2": 5.97
          },
          {
            "category": "17-11-2008",
            "value": 4.9525,
            "value2": 5.87
          },
          {
            "category": "18-11-2008",
            "value": 4.8766,
            "value2": 5.67
          },
          {
            "category": "19-11-2008",
            "value": 4.8303,
            "value2": 5.57
          },
          {
            "category": "20-11-2008",
            "value": 4.6996,
            "value2": 5.37
          },
          {
            "category": "21-11-2008",
            "value": 4.7403,
            "value2": 5.58
          },
          {
            "category": "22-11-2008",
            "value": 4.7403,
            "value2": 5.58
          },
          {
            "category": "23-11-2008",
            "value": 4.7403,
            "value2": 5.58
          },
          {
            "category": "24-11-2008",
            "value": 4.7065,
            "value2": 5.57
          },
          {
            "category": "25-11-2008",
            "value": 4.6878,
            "value2": 5.47
          },
          {
            "category": "26-11-2008",
            "value": 4.7052,
            "value2": 5.61
          },
          {
            "category": "27-11-2008",
            "value": 4.7052,
            "value2": 5.61
          },
          {
            "category": "28-11-2008",
            "value": 4.725,
            "value2": 5.63
          },
          {
            "category": "29-11-2008",
            "value": 4.725,
            "value2": 5.63
          },
          {
            "category": "30-11-2008",
            "value": 4.725,
            "value2": 5.63
          },
          {
            "category": "01-12-2008",
            "value": 4.6903,
            "value2": 5.51
          },
          {
            "category": "02-12-2008",
            "value": 4.6702,
            "value2": 5.45
          },
          {
            "category": "03-12-2008",
            "value": 4.7457,
            "value2": 5.48
          },
          {
            "category": "04-12-2008",
            "value": 4.8663,
            "value2": 5.73
          },
          {
            "category": "05-12-2008",
            "value": 4.8625,
            "value2": 5.6
          },
          {
            "category": "06-12-2008",
            "value": 4.8625,
            "value2": 5.6
          },
          {
            "category": "07-12-2008",
            "value": 4.8625,
            "value2": 5.6
          },
          {
            "category": "08-12-2008",
            "value": 4.9173,
            "value2": 5.7
          },
          {
            "category": "09-12-2008",
            "value": 4.9173,
            "value2": 5.7
          },
          {
            "category": "10-12-2008",
            "value": 4.9607,
            "value2": 5.95
          },
          {
            "category": "11-12-2008",
            "value": 4.9699,
            "value2": 5.97
          },
          {
            "category": "12-12-2008",
            "value": 4.9597,
            "value2": 6.01
          },
          {
            "category": "13-12-2008",
            "value": 4.9598,
            "value2": 6.01
          },
          {
            "category": "14-12-2008",
            "value": 4.9598,
            "value2": 6.01
          },
          {
            "category": "15-12-2008",
            "value": 5.0368,
            "value2": 6.15
          },
          {
            "category": "16-12-2008",
            "value": 5.1513,
            "value2": 6.26
          },
          {
            "category": "17-12-2008",
            "value": 5.0728,
            "value2": 6.09
          },
          {
            "category": "18-12-2008",
            "value": 5.1439,
            "value2": 6.3
          },
          {
            "category": "19-12-2008",
            "value": 5.2142,
            "value2": 6.35
          },
          {
            "category": "20-12-2008",
            "value": 5.2142,
            "value2": 6.35
          },
          {
            "category": "21-12-2008",
            "value": 5.2142,
            "value2": 6.35
          },
          {
            "category": "22-12-2008",
            "value": 5.192,
            "value2": 6.27
          },
          {
            "category": "23-12-2008",
            "value": 5.1231,
            "value2": 6.12
          },
          {
            "category": "24-12-2008",
            "value": 5.1397,
            "value2": 6.06
          },
          {
            "category": "25-12-2008",
            "value": 5.1397,
            "value2": 6.06
          },
          {
            "category": "26-12-2008",
            "value": 5.085,
            "value2": 5.94
          },
          {
            "category": "27-12-2008",
            "value": 5.085,
            "value2": 5.94
          },
          {
            "category": "28-12-2008",
            "value": 5.085,
            "value2": 5.94
          },
          {
            "category": "29-12-2008",
            "value": 5.1263,
            "value2": 6.06
          },
          {
            "category": "30-12-2008",
            "value": 5.1801,
            "value2": 6.17
          },
          {
            "category": "31-12-2008",
            "value": 5.2358,
            "value2": 6.15
          },
          {
            "category": "01-01-2009",
            "value": 5.3148,
            "value2": 6.32
          },
          {
            "category": "02-01-2009",
            "value": 5.3577,
            "value2": 6.37
          },
          {
            "category": "03-01-2009",
            "value": 5.3577,
            "value2": 6.37
          },
          {
            "category": "04-01-2009",
            "value": 5.3577,
            "value2": 6.37
          },
          {
            "category": "05-01-2009",
            "value": 5.4221,
            "value2": 6.54
          },
          {
            "category": "06-01-2009",
            "value": 5.393,
            "value2": 6.57
          },
          {
            "category": "07-01-2009",
            "value": 5.2724,
            "value2": 6.1
          },
          {
            "category": "08-01-2009",
            "value": 5.2724,
            "value2": 6.1
          },
          {
            "category": "09-01-2009",
            "value": 5.2683,
            "value2": 5.97
          },
          {
            "category": "10-01-2009",
            "value": 5.2683,
            "value2": 5.97
          },
          {
            "category": "11-01-2009",
            "value": 5.2684,
            "value2": 5.97
          },
          {
            "category": "12-01-2009",
            "value": 5.1511,
            "value2": 5.8
          },
          {
            "category": "13-01-2009",
            "value": 5.1926,
            "value2": 5.75
          },
          {
            "category": "14-01-2009",
            "value": 5.2571,
            "value2": 5.92
          },
          {
            "category": "15-01-2009",
            "value": 5.1821,
            "value2": 5.74
          },
          {
            "category": "16-01-2009",
            "value": 5.1836,
            "value2": 5.87
          },
          {
            "category": "17-01-2009",
            "value": 5.1836,
            "value2": 5.87
          },
          {
            "category": "18-01-2009",
            "value": 5.1836,
            "value2": 5.87
          },
          {
            "category": "19-01-2009",
            "value": 5.1902,
            "value2": 5.89
          },
          {
            "category": "20-01-2009",
            "value": 5.2009,
            "value2": 5.78
          },
          {
            "category": "21-01-2009",
            "value": 5.1226,
            "value2": 5.6
          },
          {
            "category": "22-01-2009",
            "value": 5.0764,
            "value2": 5.58
          },
          {
            "category": "23-01-2009",
            "value": 4.9948,
            "value2": 5.48
          },
          {
            "category": "24-01-2009",
            "value": 4.9948,
            "value2": 5.48
          },
          {
            "category": "25-01-2009",
            "value": 4.9948,
            "value2": 5.48
          },
          {
            "category": "26-01-2009",
            "value": 4.9948,
            "value2": 5.48
          },
          {
            "category": "27-01-2009",
            "value": 4.9696,
            "value2": 5.63
          },
          {
            "category": "28-01-2009",
            "value": 5.0278,
            "value2": 5.78
          },
          {
            "category": "29-01-2009",
            "value": 5.0843,
            "value2": 5.76
          },
          {
            "category": "30-01-2009",
            "value": 5.1175,
            "value2": 5.87
          },
          {
            "category": "31-01-2009",
            "value": 5.1179,
            "value2": 5.87
          },
          {
            "category": "01-02-2009",
            "value": 5.1179,
            "value2": 5.87
          },
          {
            "category": "02-02-2009",
            "value": 5.0573,
            "value2": 5.68
          },
          {
            "category": "03-02-2009",
            "value": 5.0627,
            "value2": 5.7
          },
          {
            "category": "04-02-2009",
            "value": 5.0719,
            "value2": 5.72
          },
          {
            "category": "05-02-2009",
            "value": 5.0102,
            "value2": 5.67
          },
          {
            "category": "06-02-2009",
            "value": 5.0758,
            "value2": 5.79
          },
          {
            "category": "07-02-2009",
            "value": 5.0758,
            "value2": 5.79
          },
          {
            "category": "08-02-2009",
            "value": 5.0758,
            "value2": 5.79
          },
          {
            "category": "09-02-2009",
            "value": 5.1333,
            "value2": 5.94
          },
          {
            "category": "10-02-2009",
            "value": 5.1698,
            "value2": 5.98
          },
          {
            "category": "11-02-2009",
            "value": 5.1902,
            "value2": 5.97
          },
          {
            "category": "12-02-2009",
            "value": 5.1892,
            "value2": 5.91
          },
          {
            "category": "13-02-2009",
            "value": 5.2613,
            "value2": 6.01
          },
          {
            "category": "14-02-2009",
            "value": 5.2613,
            "value2": 6.01
          },
          {
            "category": "15-02-2009",
            "value": 5.2613,
            "value2": 6.01
          },
          {
            "category": "16-02-2009",
            "value": 5.1628,
            "value2": 5.81
          },
          {
            "category": "17-02-2009",
            "value": 5.0568,
            "value2": 5.66
          },
          {
            "category": "18-02-2009",
            "value": 5.0173,
            "value2": 5.64
          },
          {
            "category": "19-02-2009",
            "value": 5.0494,
            "value2": 5.65
          },
          {
            "category": "20-02-2009",
            "value": 4.9795,
            "value2": 5.54
          },
          {
            "category": "21-02-2009",
            "value": 4.9796,
            "value2": 5.54
          },
          {
            "category": "22-02-2009",
            "value": 4.9796,
            "value2": 5.54
          },
          {
            "category": "23-02-2009",
            "value": 4.9796,
            "value2": 5.54
          },
          {
            "category": "24-02-2009",
            "value": 4.8901,
            "value2": 5.5
          },
          {
            "category": "25-02-2009",
            "value": 4.9414,
            "value2": 5.54
          },
          {
            "category": "26-02-2009",
            "value": 4.9573,
            "value2": 5.56
          },
          {
            "category": "27-02-2009",
            "value": 4.9769,
            "value2": 5.54
          },
          {
            "category": "28-02-2009",
            "value": 4.9769,
            "value2": 5.54
          },
          {
            "category": "01-03-2009",
            "value": 4.9769,
            "value2": 5.54
          },
          {
            "category": "02-03-2009",
            "value": 4.8746,
            "value2": 5.38
          },
          {
            "category": "03-03-2009",
            "value": 4.8218,
            "value2": 5.28
          },
          {
            "category": "04-03-2009",
            "value": 4.8517,
            "value2": 5.29
          },
          {
            "category": "05-03-2009",
            "value": 4.7953,
            "value2": 5.16
          },
          {
            "category": "06-03-2009",
            "value": 4.7584,
            "value2": 5.2
          },
          {
            "category": "07-03-2009",
            "value": 4.7584,
            "value2": 5.2
          },
          {
            "category": "08-03-2009",
            "value": 4.7584,
            "value2": 5.2
          },
          {
            "category": "09-03-2009",
            "value": 4.7137,
            "value2": 5.11
          },
          {
            "category": "10-03-2009",
            "value": 4.7137,
            "value2": 5.11
          },
          {
            "category": "11-03-2009",
            "value": 4.7137,
            "value2": 5.11
          },
          {
            "category": "12-03-2009",
            "value": 4.749,
            "value2": 5.2
          },
          {
            "category": "13-03-2009",
            "value": 4.8693,
            "value2": 5.42
          },
          {
            "category": "14-03-2009",
            "value": 4.8693,
            "value2": 5.42
          },
          {
            "category": "15-03-2009",
            "value": 4.8693,
            "value2": 5.42
          },
          {
            "category": "16-03-2009",
            "value": 4.9348,
            "value2": 5.54
          },
          {
            "category": "17-03-2009",
            "value": 4.9103,
            "value2": 5.51
          },
          {
            "category": "18-03-2009",
            "value": 4.9839,
            "value2": 5.59
          },
          {
            "category": "19-03-2009",
            "value": 4.9678,
            "value2": 5.6
          },
          {
            "category": "20-03-2009",
            "value": 4.9747,
            "value2": 5.59
          },
          {
            "category": "21-03-2009",
            "value": 4.9747,
            "value2": 5.59
          },
          {
            "category": "22-03-2009",
            "value": 4.9747,
            "value2": 5.59
          },
          {
            "category": "23-03-2009",
            "value": 5.0813,
            "value2": 5.83
          },
          {
            "category": "24-03-2009",
            "value": 5.0946,
            "value2": 5.83
          },
          {
            "category": "25-03-2009",
            "value": 5.0885,
            "value2": 5.94
          },
          {
            "category": "26-03-2009",
            "value": 5.1932,
            "value2": 6.1
          },
          {
            "category": "27-03-2009",
            "value": 5.2704,
            "value2": 6.18
          },
          {
            "category": "28-03-2009",
            "value": 5.2704,
            "value2": 6.18
          },
          {
            "category": "29-03-2009",
            "value": 5.2704,
            "value2": 6.18
          },
          {
            "category": "30-03-2009",
            "value": 5.2285,
            "value2": 5.93
          },
          {
            "category": "31-03-2009",
            "value": 5.2961,
            "value2": 6.04
          },
          {
            "category": "01-04-2009",
            "value": 5.3323,
            "value2": 6.14
          },
          {
            "category": "02-04-2009",
            "value": 5.4611,
            "value2": 6.41
          },
          {
            "category": "03-04-2009",
            "value": 5.4611,
            "value2": 6.41
          },
          {
            "category": "04-04-2009",
            "value": 5.4611,
            "value2": 6.41
          },
          {
            "category": "05-04-2009",
            "value": 5.4611,
            "value2": 6.41
          },
          {
            "category": "06-04-2009",
            "value": 5.5459,
            "value2": 6.53
          },
          {
            "category": "07-04-2009",
            "value": 5.546,
            "value2": 6.53
          },
          {
            "category": "08-04-2009",
            "value": 5.6111,
            "value2": 6.69
          },
          {
            "category": "09-04-2009",
            "value": 5.7153,
            "value2": 6.74
          },
          {
            "category": "10-04-2009",
            "value": 5.7153,
            "value2": 6.74
          },
          {
            "category": "11-04-2009",
            "value": 5.7153,
            "value2": 6.74
          },
          {
            "category": "12-04-2009",
            "value": 5.7153,
            "value2": 6.74
          },
          {
            "category": "13-04-2009",
            "value": 5.8952,
            "value2": 6.87
          },
          {
            "category": "14-04-2009",
            "value": 5.8952,
            "value2": 6.87
          },
          {
            "category": "15-04-2009",
            "value": 5.9831,
            "value2": 7.11
          },
          {
            "category": "16-04-2009",
            "value": 5.9133,
            "value2": 6.86
          },
          {
            "category": "17-04-2009",
            "value": 6.0876,
            "value2": 6.92
          },
          {
            "category": "18-04-2009",
            "value": 6.0876,
            "value2": 6.92
          },
          {
            "category": "19-04-2009",
            "value": 6.0876,
            "value2": 6.92
          },
          {
            "category": "20-04-2009",
            "value": 6.1074,
            "value2": 6.95
          },
          {
            "category": "21-04-2009",
            "value": 6.0696,
            "value2": 6.91
          },
          {
            "category": "22-04-2009",
            "value": 6.0238,
            "value2": 6.84
          },
          {
            "category": "23-04-2009",
            "value": 6.1258,
            "value2": 7.01
          },
          {
            "category": "24-04-2009",
            "value": 6.2309,
            "value2": 7.13
          },
          {
            "category": "25-04-2009",
            "value": 6.2309,
            "value2": 7.13
          },
          {
            "category": "26-04-2009",
            "value": 6.2309,
            "value2": 7.13
          },
          {
            "category": "27-04-2009",
            "value": 6.2216,
            "value2": 7.13
          },
          {
            "category": "28-04-2009",
            "value": 6.1086,
            "value2": 6.89
          },
          {
            "category": "29-04-2009",
            "value": 6.2985,
            "value2": 7.1
          },
          {
            "category": "30-04-2009",
            "value": 6.2985,
            "value2": 7.1
          },
          {
            "category": "01-05-2009",
            "value": 6.2902,
            "value2": 7.1
          },
          {
            "category": "02-05-2009",
            "value": 6.2902,
            "value2": 7.1
          },
          {
            "category": "03-05-2009",
            "value": 6.2902,
            "value2": 7.1
          },
          {
            "category": "04-05-2009",
            "value": 6.397,
            "value2": 7.49
          },
          {
            "category": "05-05-2009",
            "value": 6.4271,
            "value2": 7.53
          },
          {
            "category": "06-05-2009",
            "value": 6.3964,
            "value2": 7.43
          },
          {
            "category": "07-05-2009",
            "value": 6.4921,
            "value2": 7.56
          },
          {
            "category": "08-05-2009",
            "value": 6.434,
            "value2": 7.45
          },
          {
            "category": "09-05-2009",
            "value": 6.434,
            "value2": 7.45
          },
          {
            "category": "10-05-2009",
            "value": 6.434,
            "value2": 7.45
          },
          {
            "category": "11-05-2009",
            "value": 6.3929,
            "value2": 7.33
          },
          {
            "category": "12-05-2009",
            "value": 6.4389,
            "value2": 7.57
          },
          {
            "category": "13-05-2009",
            "value": 6.4585,
            "value2": 7.51
          },
          {
            "category": "14-05-2009",
            "value": 6.4988,
            "value2": 7.47
          },
          {
            "category": "15-05-2009",
            "value": 6.5396,
            "value2": 7.62
          },
          {
            "category": "16-05-2009",
            "value": 6.5396,
            "value2": 7.62
          },
          {
            "category": "17-05-2009",
            "value": 6.5396,
            "value2": 7.62
          },
          {
            "category": "18-05-2009",
            "value": 7.2173,
            "value2": 8.82
          },
          {
            "category": "19-05-2009",
            "value": 7.4004,
            "value2": 8.92
          },
          {
            "category": "20-05-2009",
            "value": 7.6307,
            "value2": 8.99
          },
          {
            "category": "21-05-2009",
            "value": 7.7072,
            "value2": 8.87
          },
          {
            "category": "22-05-2009",
            "value": 7.7091,
            "value2": 8.98
          },
          {
            "category": "23-05-2009",
            "value": 7.7091,
            "value2": 8.98
          },
          {
            "category": "24-05-2009",
            "value": 7.7091,
            "value2": 8.98
          },
          {
            "category": "25-05-2009",
            "value": 7.8658,
            "value2": 9.07
          },
          {
            "category": "26-05-2009",
            "value": 7.7918,
            "value2": 8.83
          },
          {
            "category": "27-05-2009",
            "value": 7.9767,
            "value2": 9.17
          },
          {
            "category": "28-05-2009",
            "value": 7.9596,
            "value2": 9.25
          },
          {
            "category": "29-05-2009",
            "value": 8.0901,
            "value2": 9.47
          },
          {
            "category": "30-05-2009",
            "value": 8.0901,
            "value2": 9.47
          },
          {
            "category": "31-05-2009",
            "value": 8.0901,
            "value2": 9.47
          },
          {
            "category": "01-06-2009",
            "value": 8.2192,
            "value2": 9.66
          },
          {
            "category": "02-06-2009",
            "value": 8.2248,
            "value2": 9.67
          },
          {
            "category": "03-06-2009",
            "value": 8.2234,
            "value2": 9.73
          },
          {
            "category": "04-06-2009",
            "value": 8.3485,
            "value2": 9.87
          },
          {
            "category": "05-06-2009",
            "value": 8.4997,
            "value2": 9.89
          },
          {
            "category": "06-06-2009",
            "value": 8.4996,
            "value2": 9.89
          },
          {
            "category": "07-06-2009",
            "value": 8.4996,
            "value2": 9.89
          },
          {
            "category": "08-06-2009",
            "value": 8.2037,
            "value2": 9.52
          },
          {
            "category": "09-06-2009",
            "value": 8.3393,
            "value2": 9.81
          },
          {
            "category": "10-06-2009",
            "value": 8.4311,
            "value2": 10.01
          },
          {
            "category": "11-06-2009",
            "value": 8.4278,
            "value2": 9.98
          },
          {
            "category": "12-06-2009",
            "value": 8.3725,
            "value2": 9.85
          },
          {
            "category": "13-06-2009",
            "value": 8.3725,
            "value2": 9.85
          },
          {
            "category": "14-06-2009",
            "value": 8.3726,
            "value2": 9.85
          },
          {
            "category": "15-06-2009",
            "value": 8.2818,
            "value2": 9.65
          },
          {
            "category": "16-06-2009",
            "value": 8.4135,
            "value2": 9.77
          },
          {
            "category": "17-06-2009",
            "value": 8.2287,
            "value2": 9.44
          },
          {
            "category": "18-06-2009",
            "value": 8.1502,
            "value2": 9.24
          },
          {
            "category": "19-06-2009",
            "value": 8.2408,
            "value2": 9.4
          },
          {
            "category": "20-06-2009",
            "value": 8.2408,
            "value2": 9.4
          },
          {
            "category": "21-06-2009",
            "value": 8.2408,
            "value2": 9.4
          },
          {
            "category": "22-06-2009",
            "value": 8.2464,
            "value2": 9.28
          },
          {
            "category": "23-06-2009",
            "value": 8.2575,
            "value2": 9.28
          },
          {
            "category": "24-06-2009",
            "value": 8.3358,
            "value2": 9.4
          },
          {
            "category": "25-06-2009",
            "value": 8.3145,
            "value2": 9.35
          },
          {
            "category": "26-06-2009",
            "value": 8.4084,
            "value2": 9.61
          },
          {
            "category": "27-06-2009",
            "value": 8.4084,
            "value2": 9.61
          },
          {
            "category": "28-06-2009",
            "value": 8.4084,
            "value2": 9.61
          },
          {
            "category": "29-06-2009",
            "value": 8.5285,
            "value2": 9.68
          },
          {
            "category": "30-06-2009",
            "value": 8.5242,
            "value2": 9.44
          },
          {
            "category": "01-07-2009",
            "value": 8.5523,
            "value2": 9.55
          },
          {
            "category": "02-07-2009",
            "value": 8.5548,
            "value2": 9.57
          },
          {
            "category": "03-07-2009",
            "value": 8.5661,
            "value2": 9.71
          },
          {
            "category": "04-07-2009",
            "value": 8.5661,
            "value2": 9.71
          },
          {
            "category": "05-07-2009",
            "value": 8.5661,
            "value2": 9.71
          },
          {
            "category": "06-07-2009",
            "value": 8.4062,
            "value2": 9.18
          },
          {
            "category": "07-07-2009",
            "value": 8.5558,
            "value2": 9.25
          },
          {
            "category": "08-07-2009",
            "value": 8.5195,
            "value2": 8.97
          },
          {
            "category": "09-07-2009",
            "value": 8.5704,
            "value2": 9
          },
          {
            "category": "10-07-2009",
            "value": 8.5003,
            "value2": 8.82
          },
          {
            "category": "11-07-2009",
            "value": 8.5003,
            "value2": 8.82
          },
          {
            "category": "12-07-2009",
            "value": 8.5003,
            "value2": 8.82
          },
          {
            "category": "13-07-2009",
            "value": 8.2748,
            "value2": 8.7
          },
          {
            "category": "14-07-2009",
            "value": 8.4593,
            "value2": 9.03
          },
          {
            "category": "15-07-2009",
            "value": 8.6705,
            "value2": 9.33
          },
          {
            "category": "16-07-2009",
            "value": 8.7309,
            "value2": 9.34
          },
          {
            "category": "17-07-2009",
            "value": 8.8399,
            "value2": 9.63
          },
          {
            "category": "18-07-2009",
            "value": 8.8399,
            "value2": 9.63
          },
          {
            "category": "19-07-2009",
            "value": 8.8399,
            "value2": 9.63
          },
          {
            "category": "20-07-2009",
            "value": 9.0069,
            "value2": 9.89
          },
          {
            "category": "21-07-2009",
            "value": 9.042,
            "value2": 9.83
          },
          {
            "category": "22-07-2009",
            "value": 9.0018,
            "value2": 9.69
          },
          {
            "category": "23-07-2009",
            "value": 9.1166,
            "value2": 9.94
          },
          {
            "category": "24-07-2009",
            "value": 9.2606,
            "value2": 10.05
          },
          {
            "category": "25-07-2009",
            "value": 9.2606,
            "value2": 10.05
          },
          {
            "category": "26-07-2009",
            "value": 9.2606,
            "value2": 10.05
          },
          {
            "category": "27-07-2009",
            "value": 9.4039,
            "value2": 10.1
          },
          {
            "category": "28-07-2009",
            "value": 9.4502,
            "value2": 10.12
          },
          {
            "category": "29-07-2009",
            "value": 9.3156,
            "value2": 10
          },
          {
            "category": "30-07-2009",
            "value": 9.3524,
            "value2": 10.11
          },
          {
            "category": "31-07-2009",
            "value": 9.4609,
            "value2": 10.25
          },
          {
            "category": "01-08-2009",
            "value": 9.4606,
            "value2": 10.25
          },
          {
            "category": "02-08-2009",
            "value": 9.4606,
            "value2": 10.25
          },
          {
            "category": "03-08-2009",
            "value": 9.4932,
            "value2": 10.42
          },
          {
            "category": "04-08-2009",
            "value": 9.5081,
            "value2": 10.37
          },
          {
            "category": "05-08-2009",
            "value": 9.4953,
            "value2": 10.41
          },
          {
            "category": "06-08-2009",
            "value": 9.4129,
            "value2": 10.17
          },
          {
            "category": "07-08-2009",
            "value": 9.2961,
            "value2": 9.94
          },
          {
            "category": "08-08-2009",
            "value": 9.2961,
            "value2": 9.94
          },
          {
            "category": "09-08-2009",
            "value": 9.2961,
            "value2": 9.94
          },
          {
            "category": "10-08-2009",
            "value": 9.1482,
            "value2": 9.82
          },
          {
            "category": "11-08-2009",
            "value": 9.1513,
            "value2": 9.89
          },
          {
            "category": "12-08-2009",
            "value": 9.1586,
            "value2": 9.88
          },
          {
            "category": "13-08-2009",
            "value": 9.3545,
            "value2": 10.21
          },
          {
            "category": "14-08-2009",
            "value": 9.3808,
            "value2": 10.17
          },
          {
            "category": "15-08-2009",
            "value": 9.3808,
            "value2": 10.17
          },
          {
            "category": "16-08-2009",
            "value": 9.3808,
            "value2": 10.17
          },
          {
            "category": "17-08-2009",
            "value": 9.186,
            "value2": 9.76
          },
          {
            "category": "18-08-2009",
            "value": 9.2428,
            "value2": 9.93
          },
          {
            "category": "19-08-2009",
            "value": 9.1785,
            "value2": 9.79
          },
          {
            "category": "20-08-2009",
            "value": 9.2611,
            "value2": 9.91
          },
          {
            "category": "21-08-2009",
            "value": 9.4188,
            "value2": 10.06
          },
          {
            "category": "22-08-2009",
            "value": 9.4188,
            "value2": 10.06
          },
          {
            "category": "23-08-2009",
            "value": 9.4188,
            "value2": 10.06
          },
          {
            "category": "24-08-2009",
            "value": 9.5529,
            "value2": 10.3
          },
          {
            "category": "25-08-2009",
            "value": 9.5305,
            "value2": 10.35
          },
          {
            "category": "26-08-2009",
            "value": 9.5277,
            "value2": 10.42
          },
          {
            "category": "27-08-2009",
            "value": 9.5211,
            "value2": 10.44
          },
          {
            "category": "28-08-2009",
            "value": 9.5943,
            "value2": 10.53
          },
          {
            "category": "29-08-2009",
            "value": 9.5943,
            "value2": 10.53
          },
          {
            "category": "30-08-2009",
            "value": 9.5943,
            "value2": 10.53
          },
          {
            "category": "31-08-2009",
            "value": 9.5996,
            "value2": 10.44
          },
          {
            "category": "01-09-2009",
            "value": 9.5112,
            "value2": 10.34
          },
          {
            "category": "02-09-2009",
            "value": 9.4772,
            "value2": 10.3
          },
          {
            "category": "03-09-2009",
            "value": 9.5031,
            "value2": 10.29
          },
          {
            "category": "04-09-2009",
            "value": 9.5508,
            "value2": 10.45
          },
          {
            "category": "05-09-2009",
            "value": 9.5508,
            "value2": 10.45
          },
          {
            "category": "06-09-2009",
            "value": 9.5508,
            "value2": 10.45
          },
          {
            "category": "07-09-2009",
            "value": 9.6478,
            "value2": 10.69
          },
          {
            "category": "08-09-2009",
            "value": 9.699,
            "value2": 10.71
          },
          {
            "category": "09-09-2009",
            "value": 9.7003,
            "value2": 10.72
          },
          {
            "category": "10-09-2009",
            "value": 9.633,
            "value2": 10.72
          },
          {
            "category": "11-09-2009",
            "value": 9.6368,
            "value2": 10.73
          },
          {
            "category": "12-09-2009",
            "value": 9.6368,
            "value2": 10.73
          },
          {
            "category": "13-09-2009",
            "value": 9.6368,
            "value2": 10.73
          },
          {
            "category": "14-09-2009",
            "value": 9.7128,
            "value2": 10.73
          },
          {
            "category": "15-09-2009",
            "value": 9.7366,
            "value2": 10.91
          },
          {
            "category": "16-09-2009",
            "value": 9.7755,
            "value2": 11.05
          },
          {
            "category": "17-09-2009",
            "value": 9.7338,
            "value2": 11.06
          },
          {
            "category": "18-09-2009",
            "value": 9.7937,
            "value2": 11.1
          },
          {
            "category": "19-09-2009",
            "value": 9.7937,
            "value2": 11.1
          },
          {
            "category": "20-09-2009",
            "value": 9.7937,
            "value2": 11.1
          },
          {
            "category": "21-09-2009",
            "value": 9.7937,
            "value2": 11.1
          },
          {
            "category": "22-09-2009",
            "value": 9.8554,
            "value2": 11.19
          },
          {
            "category": "23-09-2009",
            "value": 9.825,
            "value2": 11.08
          },
          {
            "category": "24-09-2009",
            "value": 9.8067,
            "value2": 11.12
          },
          {
            "category": "25-09-2009",
            "value": 9.8578,
            "value2": 11.11
          },
          {
            "category": "26-09-2009",
            "value": 9.8578,
            "value2": 11.11
          },
          {
            "category": "27-09-2009",
            "value": 9.8578,
            "value2": 11.11
          },
          {
            "category": "28-09-2009",
            "value": 9.8578,
            "value2": 11.11
          },
          {
            "category": "29-09-2009",
            "value": 9.8852,
            "value2": 11.19
          },
          {
            "category": "30-09-2009",
            "value": 10.0217,
            "value2": 11.34
          },
          {
            "category": "01-10-2009",
            "value": 10.0376,
            "value2": 11.34
          },
          {
            "category": "02-10-2009",
            "value": 10.0376,
            "value2": 11.34
          },
          {
            "category": "03-10-2009",
            "value": 10.0376,
            "value2": 11.34
          },
          {
            "category": "04-10-2009",
            "value": 10.0376,
            "value2": 11.34
          },
          {
            "category": "05-10-2009",
            "value": 9.9613,
            "value2": 11.16
          },
          {
            "category": "06-10-2009",
            "value": 9.9597,
            "value2": 11.2
          },
          {
            "category": "07-10-2009",
            "value": 9.9915,
            "value2": 11.18
          },
          {
            "category": "08-10-2009",
            "value": 9.9736,
            "value2": 11.24
          },
          {
            "category": "09-10-2009",
            "value": 9.967,
            "value2": 11.14
          },
          {
            "category": "10-10-2009",
            "value": 9.967,
            "value2": 11.14
          },
          {
            "category": "11-10-2009",
            "value": 9.967,
            "value2": 11.14
          },
          {
            "category": "12-10-2009",
            "value": 10.0701,
            "value2": 11.35
          },
          {
            "category": "13-10-2009",
            "value": 10.0701,
            "value2": 11.35
          },
          {
            "category": "14-10-2009",
            "value": 10.1448,
            "value2": 11.51
          },
          {
            "category": "15-10-2009",
            "value": 10.1479,
            "value2": 11.54
          },
          {
            "category": "16-10-2009",
            "value": 10.1942,
            "value2": 11.62
          },
          {
            "category": "17-10-2009",
            "value": 10.345,
            "value2": 11.65
          },
          {
            "category": "18-10-2009",
            "value": 10.345,
            "value2": 11.65
          },
          {
            "category": "19-10-2009",
            "value": 10.345,
            "value2": 11.65
          },
          {
            "category": "20-10-2009",
            "value": 10.36,
            "value2": 11.59
          },
          {
            "category": "21-10-2009",
            "value": 10.2704,
            "value2": 11.46
          },
          {
            "category": "22-10-2009",
            "value": 10.1922,
            "value2": 11.29
          },
          {
            "category": "23-10-2009",
            "value": 10.2298,
            "value2": 11.33
          },
          {
            "category": "24-10-2009",
            "value": 10.2298,
            "value2": 11.33
          },
          {
            "category": "25-10-2009",
            "value": 10.2298,
            "value2": 11.33
          },
          {
            "category": "26-10-2009",
            "value": 10.1012,
            "value2": 11.23
          },
          {
            "category": "27-10-2009",
            "value": 9.8639,
            "value2": 10.92
          },
          {
            "category": "28-10-2009",
            "value": 9.7937,
            "value2": 10.89
          },
          {
            "category": "29-10-2009",
            "value": 9.7088,
            "value2": 10.71
          },
          {
            "category": "30-10-2009",
            "value": 9.7415,
            "value2": 10.64
          },
          {
            "category": "31-10-2009",
            "value": 9.7415,
            "value2": 10.64
          },
          {
            "category": "01-11-2009",
            "value": 9.7441,
            "value2": 10.64
          },
          {
            "category": "02-11-2009",
            "value": 9.7294,
            "value2": 10.64
          },
          {
            "category": "03-11-2009",
            "value": 9.5571,
            "value2": 10.29
          },
          {
            "category": "04-11-2009",
            "value": 9.709,
            "value2": 10.62
          },
          {
            "category": "05-11-2009",
            "value": 9.8939,
            "value2": 10.77
          },
          {
            "category": "06-11-2009",
            "value": 9.9649,
            "value2": 10.9
          },
          {
            "category": "07-11-2009",
            "value": 9.9649,
            "value2": 10.9
          },
          {
            "category": "08-11-2009",
            "value": 9.9649,
            "value2": 10.9
          },
          {
            "category": "09-11-2009",
            "value": 10.088,
            "value2": 11.13
          },
          {
            "category": "10-11-2009",
            "value": 10.0697,
            "value2": 11.11
          },
          {
            "category": "11-11-2009",
            "value": 10.19,
            "value2": 11.37
          },
          {
            "category": "12-11-2009",
            "value": 10.097,
            "value2": 11.25
          },
          {
            "category": "13-11-2009",
            "value": 10.1536,
            "value2": 11.32
          },
          {
            "category": "14-11-2009",
            "value": 10.1536,
            "value2": 11.32
          },
          {
            "category": "15-11-2009",
            "value": 10.1536,
            "value2": 11.32
          },
          {
            "category": "16-11-2009",
            "value": 10.2654,
            "value2": 11.45
          },
          {
            "category": "17-11-2009",
            "value": 10.3018,
            "value2": 11.47
          },
          {
            "category": "18-11-2009",
            "value": 10.3374,
            "value2": 11.47
          },
          {
            "category": "19-11-2009",
            "value": 10.2384,
            "value2": 11.32
          },
          {
            "category": "20-11-2009",
            "value": 10.4022,
            "value2": 11.44
          },
          {
            "category": "21-11-2009",
            "value": 10.4022,
            "value2": 11.44
          },
          {
            "category": "22-11-2009",
            "value": 10.4022,
            "value2": 11.44
          },
          {
            "category": "23-11-2009",
            "value": 10.4613,
            "value2": 11.53
          },
          {
            "category": "24-11-2009",
            "value": 10.5441,
            "value2": 11.52
          },
          {
            "category": "25-11-2009",
            "value": 10.6012,
            "value2": 11.55
          },
          {
            "category": "26-11-2009",
            "value": 10.4484,
            "value2": 11.36
          },
          {
            "category": "27-11-2009",
            "value": 10.4243,
            "value2": 11.21
          },
          {
            "category": "28-11-2009",
            "value": 10.4243,
            "value2": 11.21
          },
          {
            "category": "29-11-2009",
            "value": 10.4243,
            "value2": 11.21
          },
          {
            "category": "30-11-2009",
            "value": 10.5228,
            "value2": 11.41
          },
          {
            "category": "01-12-2009",
            "value": 10.6428,
            "value2": 11.61
          },
          {
            "category": "02-12-2009",
            "value": 10.6929,
            "value2": 11.64
          },
          {
            "category": "03-12-2009",
            "value": 10.7464,
            "value2": 11.68
          },
          {
            "category": "04-12-2009",
            "value": 10.7636,
            "value2": 11.64
          },
          {
            "category": "05-12-2009",
            "value": 10.7636,
            "value2": 11.64
          },
          {
            "category": "06-12-2009",
            "value": 10.7636,
            "value2": 11.64
          },
          {
            "category": "07-12-2009",
            "value": 10.7385,
            "value2": 11.55
          },
          {
            "category": "08-12-2009",
            "value": 10.8219,
            "value2": 11.71
          },
          {
            "category": "09-12-2009",
            "value": 10.8254,
            "value2": 11.64
          },
          {
            "category": "10-12-2009",
            "value": 10.8754,
            "value2": 11.69
          },
          {
            "category": "11-12-2009",
            "value": 10.8671,
            "value2": 11.63
          },
          {
            "category": "12-12-2009",
            "value": 10.8671,
            "value2": 11.63
          },
          {
            "category": "13-12-2009",
            "value": 10.8671,
            "value2": 11.63
          },
          {
            "category": "14-12-2009",
            "value": 10.8484,
            "value2": 11.6
          },
          {
            "category": "15-12-2009",
            "value": 10.7367,
            "value2": 11.44
          },
          {
            "category": "16-12-2009",
            "value": 10.7474,
            "value2": 11.46
          },
          {
            "category": "17-12-2009",
            "value": 10.7673,
            "value2": 11.49
          },
          {
            "category": "18-12-2009",
            "value": 10.6949,
            "value2": 11.39
          },
          {
            "category": "19-12-2009",
            "value": 10.6949,
            "value2": 11.39
          },
          {
            "category": "20-12-2009",
            "value": 10.6949,
            "value2": 11.39
          },
          {
            "category": "21-12-2009",
            "value": 10.6533,
            "value2": 11.32
          },
          {
            "category": "22-12-2009",
            "value": 10.745,
            "value2": 11.41
          },
          {
            "category": "23-12-2009",
            "value": 10.8641,
            "value2": 11.71
          },
          {
            "category": "24-12-2009",
            "value": 10.8467,
            "value2": 11.78
          },
          {
            "category": "25-12-2009",
            "value": 10.8467,
            "value2": 11.78
          },
          {
            "category": "26-12-2009",
            "value": 10.8467,
            "value2": 11.78
          },
          {
            "category": "27-12-2009",
            "value": 10.8467,
            "value2": 11.78
          },
          {
            "category": "28-12-2009",
            "value": 10.8467,
            "value2": 11.78
          },
          {
            "category": "29-12-2009",
            "value": 10.9414,
            "value2": 11.81
          },
          {
            "category": "30-12-2009",
            "value": 10.968,
            "value2": 11.8
          },
          {
            "category": "31-12-2009",
            "value": 11.0555,
            "value2": 11.86
          },
          {
            "category": "01-01-2010",
            "value": 11.0396,
            "value2": 11.86
          },
          {
            "category": "02-01-2010",
            "value": 11.0397,
            "value2": 11.86
          },
          {
            "category": "03-01-2010",
            "value": 11.0397,
            "value2": 11.86
          },
          {
            "category": "04-01-2010",
            "value": 11.169,
            "value2": 11.96
          },
          {
            "category": "05-01-2010",
            "value": 11.2539,
            "value2": 12.09
          },
          {
            "category": "06-01-2010",
            "value": 11.3071,
            "value2": 12.12
          },
          {
            "category": "07-01-2010",
            "value": 11.2586,
            "value2": 12.08
          },
          {
            "category": "08-01-2010",
            "value": 11.3006,
            "value2": 12.06
          },
          {
            "category": "09-01-2010",
            "value": 11.2993,
            "value2": 12.06
          },
          {
            "category": "10-01-2010",
            "value": 11.3006,
            "value2": 12.06
          },
          {
            "category": "11-01-2010",
            "value": 11.4619,
            "value2": 12.11
          },
          {
            "category": "12-01-2010",
            "value": 11.3592,
            "value2": 12.01
          },
          {
            "category": "13-01-2010",
            "value": 11.4285,
            "value2": 12.07
          },
          {
            "category": "14-01-2010",
            "value": 11.5091,
            "value2": 12.14
          },
          {
            "category": "15-01-2010",
            "value": 11.512,
            "value2": 12.14
          },
          {
            "category": "16-01-2010",
            "value": 11.5119,
            "value2": 12.14
          },
          {
            "category": "17-01-2010",
            "value": 11.5119,
            "value2": 12.14
          },
          {
            "category": "18-01-2010",
            "value": 11.5743,
            "value2": 12.2
          },
          {
            "category": "19-01-2010",
            "value": 11.4841,
            "value2": 12.1
          },
          {
            "category": "20-01-2010",
            "value": 11.4729,
            "value2": 12.09
          },
          {
            "category": "21-01-2010",
            "value": 11.3233,
            "value2": 11.79
          },
          {
            "category": "22-01-2010",
            "value": 11.1922,
            "value2": 11.67
          },
          {
            "category": "23-01-2010",
            "value": 11.1922,
            "value2": 11.67
          },
          {
            "category": "24-01-2010",
            "value": 11.1922,
            "value2": 11.67
          },
          {
            "category": "25-01-2010",
            "value": 11.0828,
            "value2": 11.57
          },
          {
            "category": "26-01-2010",
            "value": 11.0828,
            "value2": 11.57
          },
          {
            "category": "27-01-2010",
            "value": 10.7853,
            "value2": 11.18
          },
          {
            "category": "28-01-2010",
            "value": 10.8024,
            "value2": 11.23
          },
          {
            "category": "29-01-2010",
            "value": 10.884,
            "value2": 11.28
          },
          {
            "category": "30-01-2010",
            "value": 10.884,
            "value2": 11.28
          },
          {
            "category": "31-01-2010",
            "value": 10.884,
            "value2": 11.28
          },
          {
            "category": "01-02-2010",
            "value": 11.0415,
            "value2": 11.37
          },
          {
            "category": "02-02-2010",
            "value": 10.9795,
            "value2": 11.22
          },
          {
            "category": "03-02-2010",
            "value": 11.09,
            "value2": 11.44
          },
          {
            "category": "04-02-2010",
            "value": 10.9753,
            "value2": 11.24
          },
          {
            "category": "05-02-2010",
            "value": 10.7679,
            "value2": 10.95
          },
          {
            "category": "06-02-2010",
            "value": 10.8764,
            "value2": 11.06
          },
          {
            "category": "07-02-2010",
            "value": 10.8764,
            "value2": 11.06
          },
          {
            "category": "08-02-2010",
            "value": 10.9279,
            "value2": 11.07
          },
          {
            "category": "09-02-2010",
            "value": 10.9794,
            "value2": 11.14
          },
          {
            "category": "10-02-2010",
            "value": 10.9662,
            "value2": 11.08
          },
          {
            "category": "11-02-2010",
            "value": 11.0155,
            "value2": 11.21
          },
          {
            "category": "12-02-2010",
            "value": 11.0155,
            "value2": 11.21
          },
          {
            "category": "13-02-2010",
            "value": 11.0155,
            "value2": 11.21
          },
          {
            "category": "14-02-2010",
            "value": 11.0155,
            "value2": 11.21
          },
          {
            "category": "15-02-2010",
            "value": 11.0068,
            "value2": 11.15
          },
          {
            "category": "16-02-2010",
            "value": 11.0793,
            "value2": 11.26
          },
          {
            "category": "17-02-2010",
            "value": 11.1313,
            "value2": 11.38
          },
          {
            "category": "18-02-2010",
            "value": 11.0848,
            "value2": 11.31
          },
          {
            "category": "19-02-2010",
            "value": 10.96,
            "value2": 11.2
          },
          {
            "category": "20-02-2010",
            "value": 10.96,
            "value2": 11.2
          },
          {
            "category": "21-02-2010",
            "value": 10.96,
            "value2": 11.2
          },
          {
            "category": "22-02-2010",
            "value": 10.9219,
            "value2": 11.19
          },
          {
            "category": "23-02-2010",
            "value": 10.8681,
            "value2": 11.19
          },
          {
            "category": "24-02-2010",
            "value": 10.8712,
            "value2": 11.17
          },
          {
            "category": "25-02-2010",
            "value": 10.883,
            "value2": 11.16
          },
          {
            "category": "26-02-2010",
            "value": 11.0473,
            "value2": 11.3
          },
          {
            "category": "27-02-2010",
            "value": 11.0473,
            "value2": 11.3
          },
          {
            "category": "28-02-2010",
            "value": 11.0473,
            "value2": 11.3
          },
          {
            "category": "01-03-2010",
            "value": 11.0318,
            "value2": 11.3
          },
          {
            "category": "02-03-2010",
            "value": 11.1534,
            "value2": 11.52
          },
          {
            "category": "03-03-2010",
            "value": 11.243,
            "value2": 11.69
          },
          {
            "category": "04-03-2010",
            "value": 11.3293,
            "value2": 11.71
          },
          {
            "category": "05-03-2010",
            "value": 11.4562,
            "value2": 11.75
          },
          {
            "category": "06-03-2010",
            "value": 11.4562,
            "value2": 11.75
          },
          {
            "category": "07-03-2010",
            "value": 11.4562,
            "value2": 11.75
          },
          {
            "category": "08-03-2010",
            "value": 11.4903,
            "value2": 11.82
          },
          {
            "category": "09-03-2010",
            "value": 11.429,
            "value2": 11.76
          },
          {
            "category": "10-03-2010",
            "value": 11.3723,
            "value2": 11.77
          },
          {
            "category": "11-03-2010",
            "value": 11.3641,
            "value2": 11.78
          },
          {
            "category": "12-03-2010",
            "value": 11.3509,
            "value2": 11.78
          },
          {
            "category": "13-03-2010",
            "value": 11.3509,
            "value2": 11.78
          },
          {
            "category": "14-03-2010",
            "value": 11.3509,
            "value2": 11.78
          },
          {
            "category": "15-03-2010",
            "value": 11.2853,
            "value2": 11.74
          },
          {
            "category": "16-03-2010",
            "value": 11.3534,
            "value2": 11.87
          },
          {
            "category": "17-03-2010",
            "value": 11.3908,
            "value2": 11.93
          },
          {
            "category": "18-03-2010",
            "value": 11.4374,
            "value2": 11.96
          },
          {
            "category": "19-03-2010",
            "value": 11.5666,
            "value2": 11.99
          },
          {
            "category": "20-03-2010",
            "value": 11.5666,
            "value2": 11.99
          },
          {
            "category": "21-03-2010",
            "value": 11.5666,
            "value2": 11.99
          },
          {
            "category": "22-03-2010",
            "value": 11.4491,
            "value2": 11.87
          },
          {
            "category": "23-03-2010",
            "value": 11.5669,
            "value2": 11.91
          },
          {
            "category": "24-03-2010",
            "value": 11.5669,
            "value2": 11.91
          },
          {
            "category": "25-03-2010",
            "value": 11.6036,
            "value2": 11.96
          },
          {
            "category": "26-03-2010",
            "value": 11.5892,
            "value2": 12.03
          },
          {
            "category": "27-03-2010",
            "value": 11.5892,
            "value2": 12.03
          },
          {
            "category": "28-03-2010",
            "value": 11.5892,
            "value2": 12.03
          },
          {
            "category": "29-03-2010",
            "value": 11.6054,
            "value2": 12.06
          },
          {
            "category": "30-03-2010",
            "value": 11.5814,
            "value2": 12.02
          },
          {
            "category": "31-03-2010",
            "value": 11.5346,
            "value2": 12.01
          },
          {
            "category": "01-04-2010",
            "value": 11.6892,
            "value2": 12.11
          },
          {
            "category": "02-04-2010",
            "value": 11.6892,
            "value2": 12.11
          },
          {
            "category": "03-04-2010",
            "value": 11.6892,
            "value2": 12.11
          },
          {
            "category": "04-04-2010",
            "value": 11.6892,
            "value2": 12.11
          },
          {
            "category": "05-04-2010",
            "value": 11.8097,
            "value2": 12.27
          },
          {
            "category": "06-04-2010",
            "value": 11.8436,
            "value2": 12.29
          },
          {
            "category": "07-04-2010",
            "value": 11.8615,
            "value2": 12.33
          },
          {
            "category": "08-04-2010",
            "value": 11.8865,
            "value2": 12.2
          },
          {
            "category": "09-04-2010",
            "value": 12.0443,
            "value2": 12.32
          },
          {
            "category": "10-04-2010",
            "value": 12.0443,
            "value2": 12.32
          },
          {
            "category": "11-04-2010",
            "value": 12.0443,
            "value2": 12.32
          },
          {
            "category": "12-04-2010",
            "value": 12.0229,
            "value2": 12.27
          },
          {
            "category": "13-04-2010",
            "value": 11.9444,
            "value2": 12.25
          },
          {
            "category": "14-04-2010",
            "value": 11.9444,
            "value2": 12.25
          },
          {
            "category": "15-04-2010",
            "value": 11.8992,
            "value2": 12.16
          },
          {
            "category": "16-04-2010",
            "value": 11.8237,
            "value2": 12.12
          },
          {
            "category": "17-04-2010",
            "value": 11.8237,
            "value2": 12.12
          },
          {
            "category": "18-04-2010",
            "value": 11.8237,
            "value2": 12.12
          },
          {
            "category": "19-04-2010",
            "value": 11.7529,
            "value2": 11.99
          },
          {
            "category": "20-04-2010",
            "value": 11.8778,
            "value2": 12.09
          },
          {
            "category": "21-04-2010",
            "value": 11.9336,
            "value2": 12.15
          },
          {
            "category": "22-04-2010",
            "value": 11.9507,
            "value2": 12.19
          },
          {
            "category": "23-04-2010",
            "value": 11.8975,
            "value2": 12.24
          },
          {
            "category": "24-04-2010",
            "value": 11.8975,
            "value2": 12.24
          },
          {
            "category": "25-04-2010",
            "value": 11.8975,
            "value2": 12.24
          },
          {
            "category": "26-04-2010",
            "value": 11.9203,
            "value2": 12.29
          },
          {
            "category": "27-04-2010",
            "value": 11.887,
            "value2": 12.26
          },
          {
            "category": "28-04-2010",
            "value": 11.772,
            "value2": 12.07
          },
          {
            "category": "29-04-2010",
            "value": 11.7754,
            "value2": 12.15
          },
          {
            "category": "30-04-2010",
            "value": 11.8363,
            "value2": 12.22
          },
          {
            "category": "01-05-2010",
            "value": 11.8363,
            "value2": 12.22
          },
          {
            "category": "02-05-2010",
            "value": 11.8363,
            "value2": 12.22
          },
          {
            "category": "03-05-2010",
            "value": 11.8108,
            "value2": 12.14
          },
          {
            "category": "04-05-2010",
            "value": 11.6135,
            "value2": 11.95
          },
          {
            "category": "05-05-2010",
            "value": 11.6139,
            "value2": 11.94
          },
          {
            "category": "06-05-2010",
            "value": 11.5604,
            "value2": 11.88
          },
          {
            "category": "07-05-2010",
            "value": 11.4415,
            "value2": 11.66
          },
          {
            "category": "08-05-2010",
            "value": 11.4415,
            "value2": 11.66
          },
          {
            "category": "09-05-2010",
            "value": 11.4415,
            "value2": 11.66
          },
          {
            "category": "10-05-2010",
            "value": 11.6523,
            "value2": 12.02
          },
          {
            "category": "11-05-2010",
            "value": 11.598,
            "value2": 11.88
          },
          {
            "category": "12-05-2010",
            "value": 11.656,
            "value2": 11.93
          },
          {
            "category": "13-05-2010",
            "value": 11.6858,
            "value2": 12.01
          },
          {
            "category": "14-05-2010",
            "value": 11.6146,
            "value2": 11.84
          },
          {
            "category": "15-05-2010",
            "value": 11.6146,
            "value2": 11.84
          },
          {
            "category": "16-05-2010",
            "value": 11.6146,
            "value2": 11.84
          },
          {
            "category": "17-05-2010",
            "value": 11.6698,
            "value2": 11.79
          },
          {
            "category": "18-05-2010",
            "value": 11.7554,
            "value2": 11.8
          },
          {
            "category": "19-05-2010",
            "value": 11.5546,
            "value2": 11.48
          },
          {
            "category": "20-05-2010",
            "value": 11.5067,
            "value2": 11.53
          },
          {
            "category": "21-05-2010",
            "value": 11.3707,
            "value2": 11.45
          },
          {
            "category": "22-05-2010",
            "value": 11.3707,
            "value2": 11.45
          },
          {
            "category": "23-05-2010",
            "value": 11.3707,
            "value2": 11.45
          },
          {
            "category": "24-05-2010",
            "value": 11.3932,
            "value2": 11.47
          },
          {
            "category": "25-05-2010",
            "value": 11.0817,
            "value2": 11.15
          },
          {
            "category": "26-05-2010",
            "value": 11.2238,
            "value2": 11.38
          },
          {
            "category": "27-05-2010",
            "value": 11.3141,
            "value2": 11.54
          },
          {
            "category": "28-05-2010",
            "value": 11.4526,
            "value2": 11.69
          },
          {
            "category": "29-05-2010",
            "value": 11.4526,
            "value2": 11.69
          },
          {
            "category": "30-05-2010",
            "value": 11.4526,
            "value2": 11.69
          },
          {
            "category": "31-05-2010",
            "value": 11.4972,
            "value2": 11.79
          },
          {
            "category": "01-06-2010",
            "value": 11.3494,
            "value2": 11.57
          },
          {
            "category": "02-06-2010",
            "value": 11.4656,
            "value2": 11.68
          },
          {
            "category": "03-06-2010",
            "value": 11.5842,
            "value2": 11.85
          },
          {
            "category": "04-06-2010",
            "value": 11.604,
            "value2": 11.9
          },
          {
            "category": "05-06-2010",
            "value": 11.604,
            "value2": 11.9
          },
          {
            "category": "06-06-2010",
            "value": 11.604,
            "value2": 11.9
          },
          {
            "category": "07-06-2010",
            "value": 11.4806,
            "value2": 11.69
          },
          {
            "category": "08-06-2010",
            "value": 11.4204,
            "value2": 11.6
          },
          {
            "category": "09-06-2010",
            "value": 11.4836,
            "value2": 11.66
          },
          {
            "category": "10-06-2010",
            "value": 11.68,
            "value2": 11.83
          },
          {
            "category": "11-06-2010",
            "value": 11.6898,
            "value2": 11.89
          },
          {
            "category": "12-06-2010",
            "value": 11.6898,
            "value2": 11.89
          },
          {
            "category": "13-06-2010",
            "value": 11.6898,
            "value2": 11.89
          },
          {
            "category": "14-06-2010",
            "value": 11.6229,
            "value2": 12.05
          },
          {
            "category": "15-06-2010",
            "value": 11.6804,
            "value2": 12.11
          },
          {
            "category": "16-06-2010",
            "value": 11.7156,
            "value2": 12.14
          },
          {
            "category": "17-06-2010",
            "value": 11.7178,
            "value2": 12.22
          },
          {
            "category": "18-06-2010",
            "value": 11.6741,
            "value2": 12.17
          },
          {
            "category": "19-06-2010",
            "value": 11.6741,
            "value2": 12.17
          },
          {
            "category": "20-06-2010",
            "value": 11.6741,
            "value2": 12.17
          },
          {
            "category": "21-06-2010",
            "value": 11.8338,
            "value2": 12.37
          },
          {
            "category": "22-06-2010",
            "value": 11.7645,
            "value2": 12.31
          },
          {
            "category": "23-06-2010",
            "value": 11.8308,
            "value2": 12.35
          },
          {
            "category": "24-06-2010",
            "value": 11.8411,
            "value2": 12.34
          },
          {
            "category": "25-06-2010",
            "value": 11.7629,
            "value2": 12.26
          },
          {
            "category": "26-06-2010",
            "value": 11.7629,
            "value2": 12.26
          },
          {
            "category": "27-06-2010",
            "value": 11.7629,
            "value2": 12.26
          },
          {
            "category": "28-06-2010",
            "value": 11.8097,
            "value2": 12.4
          },
          {
            "category": "29-06-2010",
            "value": 11.7943,
            "value2": 12.27
          },
          {
            "category": "30-06-2010",
            "value": 11.8528,
            "value2": 12.37
          },
          {
            "category": "01-07-2010",
            "value": 11.8472,
            "value2": 12.28
          },
          {
            "category": "02-07-2010",
            "value": 11.8462,
            "value2": 12.26
          },
          {
            "category": "03-07-2010",
            "value": 11.8462,
            "value2": 12.26
          },
          {
            "category": "04-07-2010",
            "value": 11.8462,
            "value2": 12.26
          },
          {
            "category": "05-07-2010",
            "value": 11.8908,
            "value2": 12.26
          },
          {
            "category": "06-07-2010",
            "value": 12.0399,
            "value2": 12.36
          },
          {
            "category": "07-07-2010",
            "value": 12.0187,
            "value2": 12.28
          },
          {
            "category": "08-07-2010",
            "value": 12.1224,
            "value2": 12.4
          },
          {
            "category": "09-07-2010",
            "value": 12.1726,
            "value2": 12.51
          },
          {
            "category": "10-07-2010",
            "value": 12.1726,
            "value2": 12.51
          },
          {
            "category": "11-07-2010",
            "value": 12.1726,
            "value2": 12.51
          },
          {
            "category": "12-07-2010",
            "value": 12.2723,
            "value2": 12.57
          },
          {
            "category": "13-07-2010",
            "value": 12.3696,
            "value2": 12.62
          },
          {
            "category": "14-07-2010",
            "value": 12.2961,
            "value2": 12.59
          },
          {
            "category": "15-07-2010",
            "value": 12.3284,
            "value2": 12.57
          },
          {
            "category": "16-07-2010",
            "value": 12.3808,
            "value2": 12.62
          },
          {
            "category": "17-07-2010",
            "value": 12.3808,
            "value2": 12.62
          },
          {
            "category": "18-07-2010",
            "value": 12.3808,
            "value2": 12.62
          },
          {
            "category": "19-07-2010",
            "value": 12.447,
            "value2": 12.62
          },
          {
            "category": "20-07-2010",
            "value": 12.4199,
            "value2": 12.58
          },
          {
            "category": "21-07-2010",
            "value": 12.3882,
            "value2": 12.64
          },
          {
            "category": "22-07-2010",
            "value": 12.436,
            "value2": 12.73
          },
          {
            "category": "23-07-2010",
            "value": 12.4001,
            "value2": 12.72
          },
          {
            "category": "24-07-2010",
            "value": 12.4001,
            "value2": 12.72
          },
          {
            "category": "25-07-2010",
            "value": 12.4001,
            "value2": 12.72
          },
          {
            "category": "26-07-2010",
            "value": 12.2446,
            "value2": 12.64
          },
          {
            "category": "27-07-2010",
            "value": 12.2139,
            "value2": 12.67
          },
          {
            "category": "28-07-2010",
            "value": 12.2326,
            "value2": 12.62
          },
          {
            "category": "29-07-2010",
            "value": 12.3991,
            "value2": 12.65
          },
          {
            "category": "30-07-2010",
            "value": 12.4489,
            "value2": 12.6
          },
          {
            "category": "31-07-2010",
            "value": 12.4489,
            "value2": 12.6
          },
          {
            "category": "01-08-2010",
            "value": 12.4515,
            "value2": 12.6
          },
          {
            "category": "02-08-2010",
            "value": 12.6543,
            "value2": 12.73
          },
          {
            "category": "03-08-2010",
            "value": 12.7206,
            "value2": 12.76
          },
          {
            "category": "04-08-2010",
            "value": 12.7437,
            "value2": 12.8
          },
          {
            "category": "05-08-2010",
            "value": 12.6895,
            "value2": 12.78
          },
          {
            "category": "06-08-2010",
            "value": 12.6633,
            "value2": 12.77
          },
          {
            "category": "07-08-2010",
            "value": 12.6633,
            "value2": 12.77
          },
          {
            "category": "08-08-2010",
            "value": 12.6633,
            "value2": 12.77
          },
          {
            "category": "09-08-2010",
            "value": 12.7322,
            "value2": 12.89
          },
          {
            "category": "10-08-2010",
            "value": 12.6386,
            "value2": 12.84
          },
          {
            "category": "11-08-2010",
            "value": 12.6913,
            "value2": 12.75
          },
          {
            "category": "12-08-2010",
            "value": 12.7199,
            "value2": 12.77
          },
          {
            "category": "13-08-2010",
            "value": 12.7959,
            "value2": 12.85
          },
          {
            "category": "14-08-2010",
            "value": 12.7959,
            "value2": 12.85
          },
          {
            "category": "15-08-2010",
            "value": 12.7959,
            "value2": 12.85
          },
          {
            "category": "16-08-2010",
            "value": 12.6902,
            "value2": 12.79
          },
          {
            "category": "17-08-2010",
            "value": 12.7435,
            "value2": 12.82
          },
          {
            "category": "18-08-2010",
            "value": 12.8101,
            "value2": 12.95
          },
          {
            "category": "19-08-2010",
            "value": 12.9419,
            "value2": 13.08
          },
          {
            "category": "20-08-2010",
            "value": 12.9983,
            "value2": 13.07
          },
          {
            "category": "21-08-2010",
            "value": 12.9983,
            "value2": 13.07
          },
          {
            "category": "22-08-2010",
            "value": 12.9983,
            "value2": 13.07
          },
          {
            "category": "23-08-2010",
            "value": 13.1436,
            "value2": 13.12
          },
          {
            "category": "24-08-2010",
            "value": 13.0402,
            "value2": 13.04
          },
          {
            "category": "25-08-2010",
            "value": 12.9628,
            "value2": 12.92
          },
          {
            "category": "26-08-2010",
            "value": 13.0425,
            "value2": 12.94
          },
          {
            "category": "27-08-2010",
            "value": 12.9979,
            "value2": 12.8
          },
          {
            "category": "28-08-2010",
            "value": 12.9979,
            "value2": 12.8
          },
          {
            "category": "29-08-2010",
            "value": 12.9979,
            "value2": 12.8
          },
          {
            "category": "30-08-2010",
            "value": 12.9389,
            "value2": 12.82
          },
          {
            "category": "31-08-2010",
            "value": 12.9464,
            "value2": 12.76
          },
          {
            "category": "01-09-2010",
            "value": 13.0242,
            "value2": 12.95
          },
          {
            "category": "02-09-2010",
            "value": 13.1289,
            "value2": 13
          },
          {
            "category": "03-09-2010",
            "value": 13.1866,
            "value2": 13.03
          },
          {
            "category": "04-09-2010",
            "value": 13.1866,
            "value2": 13.03
          },
          {
            "category": "05-09-2010",
            "value": 13.1866,
            "value2": 13.03
          },
          {
            "category": "06-09-2010",
            "value": 13.4216,
            "value2": 13.24
          },
          {
            "category": "07-09-2010",
            "value": 13.3969,
            "value2": 13.29
          },
          {
            "category": "08-09-2010",
            "value": 13.4607,
            "value2": 13.31
          },
          {
            "category": "09-09-2010",
            "value": 13.5231,
            "value2": 13.39
          },
          {
            "category": "10-09-2010",
            "value": 13.5231,
            "value2": 13.39
          },
          {
            "category": "11-09-2010",
            "value": 13.5231,
            "value2": 13.39
          },
          {
            "category": "12-09-2010",
            "value": 13.5231,
            "value2": 13.39
          },
          {
            "category": "13-09-2010",
            "value": 13.6144,
            "value2": 13.61
          },
          {
            "category": "14-09-2010",
            "value": 13.5823,
            "value2": 13.65
          },
          {
            "category": "15-09-2010",
            "value": 13.636,
            "value2": 13.74
          },
          {
            "category": "16-09-2010",
            "value": 13.5662,
            "value2": 13.67
          },
          {
            "category": "17-09-2010",
            "value": 13.7133,
            "value2": 13.82
          },
          {
            "category": "18-09-2010",
            "value": 13.7133,
            "value2": 13.82
          },
          {
            "category": "19-09-2010",
            "value": 13.7133,
            "value2": 13.82
          },
          {
            "category": "20-09-2010",
            "value": 13.8315,
            "value2": 13.99
          },
          {
            "category": "21-09-2010",
            "value": 13.7541,
            "value2": 13.98
          },
          {
            "category": "22-09-2010",
            "value": 13.6676,
            "value2": 13.93
          },
          {
            "category": "23-09-2010",
            "value": 13.595,
            "value2": 13.88
          },
          {
            "category": "24-09-2010",
            "value": 13.798,
            "value2": 14.01
          },
          {
            "category": "25-09-2010",
            "value": 13.7844,
            "value2": 14.01
          },
          {
            "category": "26-09-2010",
            "value": 13.7844,
            "value2": 14.01
          },
          {
            "category": "27-09-2010",
            "value": 13.8,
            "value2": 14.06
          },
          {
            "category": "28-09-2010",
            "value": 13.7928,
            "value2": 14.06
          },
          {
            "category": "29-09-2010",
            "value": 13.8183,
            "value2": 13.98
          },
          {
            "category": "30-09-2010",
            "value": 13.8035,
            "value2": 14
          },
          {
            "category": "01-10-2010",
            "value": 13.9497,
            "value2": 14.26
          },
          {
            "category": "02-10-2010",
            "value": 13.9497,
            "value2": 14.26
          },
          {
            "category": "03-10-2010",
            "value": 13.9497,
            "value2": 14.26
          },
          {
            "category": "04-10-2010",
            "value": 13.9376,
            "value2": 14.32
          },
          {
            "category": "05-10-2010",
            "value": 13.8925,
            "value2": 14.34
          },
          {
            "category": "06-10-2010",
            "value": 13.9733,
            "value2": 14.45
          },
          {
            "category": "07-10-2010",
            "value": 13.9042,
            "value2": 14.32
          },
          {
            "category": "08-10-2010",
            "value": 13.7954,
            "value2": 14.27
          },
          {
            "category": "09-10-2010",
            "value": 13.7954,
            "value2": 14.27
          },
          {
            "category": "10-10-2010",
            "value": 13.7954,
            "value2": 14.27
          },
          {
            "category": "11-10-2010",
            "value": 13.8574,
            "value2": 14.36
          },
          {
            "category": "12-10-2010",
            "value": 13.8337,
            "value2": 14.25
          },
          {
            "category": "13-10-2010",
            "value": 13.9575,
            "value2": 14.52
          },
          {
            "category": "14-10-2010",
            "value": 13.8979,
            "value2": 14.41
          },
          {
            "category": "15-10-2010",
            "value": 13.7105,
            "value2": 14.18
          },
          {
            "category": "16-10-2010",
            "value": 13.7105,
            "value2": 14.18
          },
          {
            "category": "17-10-2010",
            "value": 13.7105,
            "value2": 14.18
          },
          {
            "category": "18-10-2010",
            "value": 13.631,
            "value2": 14.18
          },
          {
            "category": "19-10-2010",
            "value": 13.7441,
            "value2": 14.1
          },
          {
            "category": "20-10-2010",
            "value": 13.7245,
            "value2": 14.03
          },
          {
            "category": "21-10-2010",
            "value": 13.9099,
            "value2": 14.28
          },
          {
            "category": "22-10-2010",
            "value": 13.9846,
            "value2": 14.24
          },
          {
            "category": "23-10-2010",
            "value": 13.9846,
            "value2": 14.24
          },
          {
            "category": "24-10-2010",
            "value": 13.9846,
            "value2": 14.24
          },
          {
            "category": "25-10-2010",
            "value": 14.2238,
            "value2": 14.33
          },
          {
            "category": "26-10-2010",
            "value": 14.2016,
            "value2": 14.28
          },
          {
            "category": "27-10-2010",
            "value": 14.1494,
            "value2": 14.16
          },
          {
            "category": "28-10-2010",
            "value": 14.209,
            "value2": 14.1
          },
          {
            "category": "29-10-2010",
            "value": 14.1802,
            "value2": 14.1
          },
          {
            "category": "30-10-2010",
            "value": 14.1802,
            "value2": 14.1
          },
          {
            "category": "31-10-2010",
            "value": 14.1802,
            "value2": 14.1
          },
          {
            "category": "01-11-2010",
            "value": 14.4212,
            "value2": 14.33
          },
          {
            "category": "02-11-2010",
            "value": 14.3336,
            "value2": 14.36
          },
          {
            "category": "03-11-2010",
            "value": 14.467,
            "value2": 14.44
          },
          {
            "category": "04-11-2010",
            "value": 14.6112,
            "value2": 14.66
          },
          {
            "category": "05-11-2010",
            "value": 14.6751,
            "value2": 14.75
          },
          {
            "category": "06-11-2010",
            "value": 14.6751,
            "value2": 14.75
          },
          {
            "category": "07-11-2010",
            "value": 14.6751,
            "value2": 14.75
          },
          {
            "category": "08-11-2010",
            "value": 14.7266,
            "value2": 14.69
          },
          {
            "category": "09-11-2010",
            "value": 14.7886,
            "value2": 14.75
          },
          {
            "category": "10-11-2010",
            "value": 14.7416,
            "value2": 14.72
          },
          {
            "category": "11-11-2010",
            "value": 14.5891,
            "value2": 14.54
          },
          {
            "category": "12-11-2010",
            "value": 14.3952,
            "value2": 14.23
          },
          {
            "category": "13-11-2010",
            "value": 14.3952,
            "value2": 14.23
          },
          {
            "category": "14-11-2010",
            "value": 14.3952,
            "value2": 14.23
          },
          {
            "category": "15-11-2010",
            "value": 14.4258,
            "value2": 14.31
          },
          {
            "category": "16-11-2010",
            "value": 14.2585,
            "value2": 14.01
          },
          {
            "category": "17-11-2010",
            "value": 14.2585,
            "value2": 14.01
          },
          {
            "category": "18-11-2010",
            "value": 14.192,
            "value2": 14.02
          },
          {
            "category": "19-11-2010",
            "value": 13.9373,
            "value2": 13.77
          },
          {
            "category": "20-11-2010",
            "value": 13.9373,
            "value2": 13.77
          },
          {
            "category": "21-11-2010",
            "value": 13.9373,
            "value2": 13.77
          },
          {
            "category": "22-11-2010",
            "value": 14.1093,
            "value2": 14
          },
          {
            "category": "23-11-2010",
            "value": 14.0091,
            "value2": 13.83
          },
          {
            "category": "24-11-2010",
            "value": 13.8261,
            "value2": 13.67
          },
          {
            "category": "25-11-2010",
            "value": 13.6971,
            "value2": 13.48
          },
          {
            "category": "26-11-2010",
            "value": 13.4259,
            "value2": 13.25
          },
          {
            "category": "27-11-2010",
            "value": 13.4259,
            "value2": 13.25
          },
          {
            "category": "28-11-2010",
            "value": 13.4259,
            "value2": 13.25
          },
          {
            "category": "29-11-2010",
            "value": 13.4315,
            "value2": 13.41
          },
          {
            "category": "30-11-2010",
            "value": 13.6991,
            "value2": 13.55
          },
          {
            "category": "01-12-2010",
            "value": 13.8815,
            "value2": 13.82
          },
          {
            "category": "02-12-2010",
            "value": 13.9987,
            "value2": 13.92
          },
          {
            "category": "03-12-2010",
            "value": 13.8015,
            "value2": 13.82
          },
          {
            "category": "04-12-2010",
            "value": 13.8015,
            "value2": 13.82
          },
          {
            "category": "05-12-2010",
            "value": 13.8015,
            "value2": 13.82
          },
          {
            "category": "06-12-2010",
            "value": 13.7404,
            "value2": 13.79
          },
          {
            "category": "07-12-2010",
            "value": 13.5597,
            "value2": 13.72
          },
          {
            "category": "08-12-2010",
            "value": 13.3655,
            "value2": 13.53
          },
          {
            "category": "09-12-2010",
            "value": 12.9104,
            "value2": 13.14
          },
          {
            "category": "10-12-2010",
            "value": 13.0886,
            "value2": 13.35
          },
          {
            "category": "11-12-2010",
            "value": 13.0886,
            "value2": 13.35
          },
          {
            "category": "12-12-2010",
            "value": 13.0886,
            "value2": 13.35
          },
          {
            "category": "13-12-2010",
            "value": 13.2725,
            "value2": 13.5
          },
          {
            "category": "14-12-2010",
            "value": 13.3518,
            "value2": 13.61
          },
          {
            "category": "15-12-2010",
            "value": 13.1888,
            "value2": 13.47
          },
          {
            "category": "16-12-2010",
            "value": 13.1697,
            "value2": 13.57
          },
          {
            "category": "17-12-2010",
            "value": 13.1697,
            "value2": 13.57
          },
          {
            "category": "18-12-2010",
            "value": 13.1697,
            "value2": 13.57
          },
          {
            "category": "19-12-2010",
            "value": 13.1697,
            "value2": 13.57
          },
          {
            "category": "20-12-2010",
            "value": 13.146,
            "value2": 13.57
          },
          {
            "category": "21-12-2010",
            "value": 13.1902,
            "value2": 13.68
          },
          {
            "category": "22-12-2010",
            "value": 13.1928,
            "value2": 13.67
          },
          {
            "category": "23-12-2010",
            "value": 13.2165,
            "value2": 13.64
          },
          {
            "category": "24-12-2010",
            "value": 13.2294,
            "value2": 13.69
          },
          {
            "category": "25-12-2010",
            "value": 13.2294,
            "value2": 13.69
          },
          {
            "category": "26-12-2010",
            "value": 13.2294,
            "value2": 13.69
          },
          {
            "category": "27-12-2010",
            "value": 13.1915,
            "value2": 13.66
          },
          {
            "category": "28-12-2010",
            "value": 13.1732,
            "value2": 13.66
          },
          {
            "category": "29-12-2010",
            "value": 13.2454,
            "value2": 13.78
          },
          {
            "category": "30-12-2010",
            "value": 13.3112,
            "value2": 13.87
          },
          {
            "category": "31-12-2010",
            "value": 13.4436,
            "value2": 13.98
          },
          {
            "category": "01-01-2011",
            "value": 13.4436,
            "value2": 13.98
          },
          {
            "category": "02-01-2011",
            "value": 13.4436,
            "value2": 13.98
          },
          {
            "category": "03-01-2011",
            "value": 13.5599,
            "value2": 14.05
          },
          {
            "category": "04-01-2011",
            "value": 13.4664,
            "value2": 14.01
          },
          {
            "category": "05-01-2011",
            "value": 13.3261,
            "value2": 13.88
          },
          {
            "category": "06-01-2011",
            "value": 13.2197,
            "value2": 13.76
          },
          {
            "category": "07-01-2011",
            "value": 12.9744,
            "value2": 13.44
          },
          {
            "category": "08-01-2011",
            "value": 12.9744,
            "value2": 13.44
          },
          {
            "category": "09-01-2011",
            "value": 12.9744,
            "value2": 13.44
          },
          {
            "category": "10-01-2011",
            "value": 12.7433,
            "value2": 13.12
          },
          {
            "category": "11-01-2011",
            "value": 12.6841,
            "value2": 13.1
          },
          {
            "category": "12-01-2011",
            "value": 12.7646,
            "value2": 13.32
          },
          {
            "category": "13-01-2011",
            "value": 12.6934,
            "value2": 13.14
          },
          {
            "category": "14-01-2011",
            "value": 12.5816,
            "value2": 12.92
          },
          {
            "category": "15-01-2011",
            "value": 12.5816,
            "value2": 12.92
          },
          {
            "category": "16-01-2011",
            "value": 12.5816,
            "value2": 12.92
          },
          {
            "category": "17-01-2011",
            "value": 12.4869,
            "value2": 12.88
          },
          {
            "category": "18-01-2011",
            "value": 12.637,
            "value2": 13
          },
          {
            "category": "19-01-2011",
            "value": 12.623,
            "value2": 12.97
          },
          {
            "category": "20-01-2011",
            "value": 12.6564,
            "value2": 13.01
          },
          {
            "category": "21-01-2011",
            "value": 12.5593,
            "value2": 13
          },
          {
            "category": "22-01-2011",
            "value": 12.5593,
            "value2": 13
          },
          {
            "category": "23-01-2011",
            "value": 12.5593,
            "value2": 13
          },
          {
            "category": "24-01-2011",
            "value": 12.5775,
            "value2": 13.1
          },
          {
            "category": "25-01-2011",
            "value": 12.5278,
            "value2": 13
          },
          {
            "category": "26-01-2011",
            "value": 12.5278,
            "value2": 13
          },
          {
            "category": "27-01-2011",
            "value": 12.3982,
            "value2": 12.79
          },
          {
            "category": "28-01-2011",
            "value": 12.1117,
            "value2": 12.54
          },
          {
            "category": "29-01-2011",
            "value": 12.1117,
            "value2": 12.54
          },
          {
            "category": "30-01-2011",
            "value": 12.1117,
            "value2": 12.54
          },
          {
            "category": "31-01-2011",
            "value": 12.1278,
            "value2": 12.52
          },
          {
            "category": "01-02-2011",
            "value": 11.8491,
            "value2": 12.32
          },
          {
            "category": "02-02-2011",
            "value": 11.8392,
            "value2": 12.35
          },
          {
            "category": "03-02-2011",
            "value": 11.918,
            "value2": 12.54
          },
          {
            "category": "04-02-2011",
            "value": 11.6355,
            "value2": 12.29
          },
          {
            "category": "05-02-2011",
            "value": 11.6355,
            "value2": 12.29
          },
          {
            "category": "06-02-2011",
            "value": 11.6355,
            "value2": 12.29
          },
          {
            "category": "07-02-2011",
            "value": 11.534,
            "value2": 12.27
          },
          {
            "category": "08-02-2011",
            "value": 11.3752,
            "value2": 12.05
          },
          {
            "category": "09-02-2011",
            "value": 11.1679,
            "value2": 11.83
          },
          {
            "category": "10-02-2011",
            "value": 11.2248,
            "value2": 11.79
          },
          {
            "category": "11-02-2011",
            "value": 11.6006,
            "value2": 12
          },
          {
            "category": "12-02-2011",
            "value": 11.6006,
            "value2": 12
          },
          {
            "category": "13-02-2011",
            "value": 11.6006,
            "value2": 12
          },
          {
            "category": "14-02-2011",
            "value": 11.9227,
            "value2": 12.35
          },
          {
            "category": "15-02-2011",
            "value": 11.9371,
            "value2": 12.4
          },
          {
            "category": "16-02-2011",
            "value": 12.001,
            "value2": 12.43
          },
          {
            "category": "17-02-2011",
            "value": 12.1346,
            "value2": 12.56
          },
          {
            "category": "18-02-2011",
            "value": 12.0122,
            "value2": 12.35
          },
          {
            "category": "19-02-2011",
            "value": 12.0122,
            "value2": 12.35
          },
          {
            "category": "20-02-2011",
            "value": 12.0122,
            "value2": 12.35
          },
          {
            "category": "21-02-2011",
            "value": 12.1126,
            "value2": 12.46
          },
          {
            "category": "22-02-2011",
            "value": 12.0114,
            "value2": 12.35
          },
          {
            "category": "23-02-2011",
            "value": 11.9534,
            "value2": 12.28
          },
          {
            "category": "24-02-2011",
            "value": 11.6647,
            "value2": 11.92
          },
          {
            "category": "25-02-2011",
            "value": 11.7477,
            "value2": 11.97
          },
          {
            "category": "26-02-2011",
            "value": 11.7477,
            "value2": 11.97
          },
          {
            "category": "27-02-2011",
            "value": 11.7477,
            "value2": 11.97
          },
          {
            "category": "28-02-2011",
            "value": 11.9171,
            "value2": 12.04
          },
          {
            "category": "01-03-2011",
            "value": 12.0831,
            "value2": 12.44
          },
          {
            "category": "02-03-2011",
            "value": 12.0824,
            "value2": 12.44
          },
          {
            "category": "03-03-2011",
            "value": 12.1564,
            "value2": 12.46
          },
          {
            "category": "04-03-2011",
            "value": 12.1696,
            "value2": 12.46
          },
          {
            "category": "05-03-2011",
            "value": 12.1696,
            "value2": 12.46
          },
          {
            "category": "06-03-2011",
            "value": 12.1696,
            "value2": 12.46
          },
          {
            "category": "07-03-2011",
            "value": 12.0856,
            "value2": 12.3
          },
          {
            "category": "08-03-2011",
            "value": 12.119,
            "value2": 12.44
          },
          {
            "category": "09-03-2011",
            "value": 12.1695,
            "value2": 12.47
          },
          {
            "category": "10-03-2011",
            "value": 12.1846,
            "value2": 12.41
          },
          {
            "category": "11-03-2011",
            "value": 12.0885,
            "value2": 12.3
          },
          {
            "category": "12-03-2011",
            "value": 12.0826,
            "value2": 12.3
          },
          {
            "category": "13-03-2011",
            "value": 12.0885,
            "value2": 12.3
          },
          {
            "category": "14-03-2011",
            "value": 12.1458,
            "value2": 12.45
          },
          {
            "category": "15-03-2011",
            "value": 11.9993,
            "value2": 12.27
          },
          {
            "category": "16-03-2011",
            "value": 12.1169,
            "value2": 12.42
          },
          {
            "category": "17-03-2011",
            "value": 11.9533,
            "value2": 12.32
          },
          {
            "category": "18-03-2011",
            "value": 11.849,
            "value2": 12.17
          },
          {
            "category": "19-03-2011",
            "value": 11.8425,
            "value2": 12.17
          },
          {
            "category": "20-03-2011",
            "value": 11.8425,
            "value2": 12.17
          },
          {
            "category": "21-03-2011",
            "value": 11.7852,
            "value2": 12.15
          },
          {
            "category": "22-03-2011",
            "value": 11.825,
            "value2": 12.25
          },
          {
            "category": "23-03-2011",
            "value": 11.8904,
            "value2": 12.38
          },
          {
            "category": "24-03-2011",
            "value": 12.0109,
            "value2": 12.47
          },
          {
            "category": "25-03-2011",
            "value": 12.1144,
            "value2": 12.71
          },
          {
            "category": "26-03-2011",
            "value": 12.1144,
            "value2": 12.71
          },
          {
            "category": "27-03-2011",
            "value": 12.1144,
            "value2": 12.71
          },
          {
            "category": "28-03-2011",
            "value": 12.2878,
            "value2": 12.78
          },
          {
            "category": "29-03-2011",
            "value": 12.4187,
            "value2": 12.87
          },
          {
            "category": "30-03-2011",
            "value": 12.6037,
            "value2": 13.01
          },
          {
            "category": "31-03-2011",
            "value": 12.6084,
            "value2": 13.08
          },
          {
            "category": "01-04-2011",
            "value": 12.6798,
            "value2": 13.12
          },
          {
            "category": "02-04-2011",
            "value": 12.6798,
            "value2": 13.12
          },
          {
            "category": "03-04-2011",
            "value": 12.6798,
            "value2": 13.12
          },
          {
            "category": "04-04-2011",
            "value": 12.8833,
            "value2": 13.31
          },
          {
            "category": "05-04-2011",
            "value": 12.8732,
            "value2": 13.36
          },
          {
            "category": "06-04-2011",
            "value": 12.8897,
            "value2": 13.35
          },
          {
            "category": "07-04-2011",
            "value": 12.8767,
            "value2": 13.37
          },
          {
            "category": "08-04-2011",
            "value": 12.7848,
            "value2": 13.25
          },
          {
            "category": "09-04-2011",
            "value": 12.7848,
            "value2": 13.25
          },
          {
            "category": "10-04-2011",
            "value": 12.7848,
            "value2": 13.25
          },
          {
            "category": "11-04-2011",
            "value": 12.6936,
            "value2": 13.13
          },
          {
            "category": "12-04-2011",
            "value": 12.6936,
            "value2": 13.13
          },
          {
            "category": "13-04-2011",
            "value": 12.7991,
            "value2": 13.38
          },
          {
            "category": "14-04-2011",
            "value": 12.7991,
            "value2": 13.38
          },
          {
            "category": "15-04-2011",
            "value": 12.7,
            "value2": 13.23
          },
          {
            "category": "16-04-2011",
            "value": 12.7,
            "value2": 13.23
          },
          {
            "category": "17-04-2011",
            "value": 12.7,
            "value2": 13.23
          },
          {
            "category": "18-04-2011",
            "value": 12.5524,
            "value2": 13.05
          },
          {
            "category": "19-04-2011",
            "value": 12.5369,
            "value2": 13.08
          },
          {
            "category": "20-04-2011",
            "value": 12.6695,
            "value2": 13.29
          },
          {
            "category": "21-04-2011",
            "value": 12.7422,
            "value2": 13.33
          },
          {
            "category": "22-04-2011",
            "value": 12.7422,
            "value2": 13.33
          },
          {
            "category": "23-04-2011",
            "value": 12.7422,
            "value2": 13.33
          },
          {
            "category": "24-04-2011",
            "value": 12.7422,
            "value2": 13.33
          },
          {
            "category": "25-04-2011",
            "value": 12.8681,
            "value2": 13.33
          },
          {
            "category": "26-04-2011",
            "value": 12.8138,
            "value2": 13.32
          },
          {
            "category": "27-04-2011",
            "value": 12.815,
            "value2": 13.28
          },
          {
            "category": "28-04-2011",
            "value": 12.7724,
            "value2": 13.17
          },
          {
            "category": "29-04-2011",
            "value": 12.6736,
            "value2": 13.06
          },
          {
            "category": "30-04-2011",
            "value": 12.6736,
            "value2": 13.06
          },
          {
            "category": "01-05-2011",
            "value": 12.6736,
            "value2": 13.06
          },
          {
            "category": "02-05-2011",
            "value": 12.5521,
            "value2": 12.96
          },
          {
            "category": "03-05-2011",
            "value": 12.3524,
            "value2": 12.67
          },
          {
            "category": "04-05-2011",
            "value": 12.3274,
            "value2": 12.61
          },
          {
            "category": "05-05-2011",
            "value": 12.1969,
            "value2": 12.45
          },
          {
            "category": "06-05-2011",
            "value": 12.3257,
            "value2": 12.64
          },
          {
            "category": "07-05-2011",
            "value": 12.3257,
            "value2": 12.64
          },
          {
            "category": "08-05-2011",
            "value": 12.3257,
            "value2": 12.64
          },
          {
            "category": "09-05-2011",
            "value": 12.3674,
            "value2": 12.65
          },
          {
            "category": "10-05-2011",
            "value": 12.3572,
            "value2": 12.64
          },
          {
            "category": "11-05-2011",
            "value": 12.4924,
            "value2": 12.7
          },
          {
            "category": "12-05-2011",
            "value": 12.5152,
            "value2": 12.55
          },
          {
            "category": "13-05-2011",
            "value": 12.5861,
            "value2": 12.68
          },
          {
            "category": "14-05-2011",
            "value": 12.5861,
            "value2": 12.68
          },
          {
            "category": "15-05-2011",
            "value": 12.5861,
            "value2": 12.68
          },
          {
            "category": "16-05-2011",
            "value": 12.4987,
            "value2": 12.57
          },
          {
            "category": "17-05-2011",
            "value": 12.4622,
            "value2": 12.46
          },
          {
            "category": "18-05-2011",
            "value": 12.4747,
            "value2": 12.4
          },
          {
            "category": "19-05-2011",
            "value": 12.3973,
            "value2": 12.39
          },
          {
            "category": "20-05-2011",
            "value": 12.4454,
            "value2": 12.5
          },
          {
            "category": "21-05-2011",
            "value": 12.4454,
            "value2": 12.5
          },
          {
            "category": "22-05-2011",
            "value": 12.4454,
            "value2": 12.5
          },
          {
            "category": "23-05-2011",
            "value": 12.2746,
            "value2": 12.3
          },
          {
            "category": "24-05-2011",
            "value": 12.2863,
            "value2": 12.32
          },
          {
            "category": "25-05-2011",
            "value": 12.2443,
            "value2": 12.24
          },
          {
            "category": "26-05-2011",
            "value": 12.2425,
            "value2": 12.34
          },
          {
            "category": "27-05-2011",
            "value": 12.3837,
            "value2": 12.5
          },
          {
            "category": "28-05-2011",
            "value": 12.3837,
            "value2": 12.5
          },
          {
            "category": "29-05-2011",
            "value": 12.3837,
            "value2": 12.5
          },
          {
            "category": "30-05-2011",
            "value": 12.5546,
            "value2": 12.54
          },
          {
            "category": "31-05-2011",
            "value": 12.646,
            "value2": 12.74
          },
          {
            "category": "01-06-2011",
            "value": 12.7295,
            "value2": 12.81
          },
          {
            "category": "02-06-2011",
            "value": 12.6488,
            "value2": 12.73
          },
          {
            "category": "03-06-2011",
            "value": 12.6632,
            "value2": 12.66
          },
          {
            "category": "04-06-2011",
            "value": 12.6632,
            "value2": 12.66
          },
          {
            "category": "05-06-2011",
            "value": 12.6632,
            "value2": 12.66
          },
          {
            "category": "06-06-2011",
            "value": 12.6106,
            "value2": 12.67
          },
          {
            "category": "07-06-2011",
            "value": 12.6365,
            "value2": 12.74
          },
          {
            "category": "08-06-2011",
            "value": 12.5862,
            "value2": 12.7
          },
          {
            "category": "09-06-2011",
            "value": 12.5846,
            "value2": 12.69
          },
          {
            "category": "10-06-2011",
            "value": 12.5516,
            "value2": 12.62
          },
          {
            "category": "11-06-2011",
            "value": 12.5516,
            "value2": 12.62
          },
          {
            "category": "12-06-2011",
            "value": 12.5516,
            "value2": 12.62
          },
          {
            "category": "13-06-2011",
            "value": 12.6139,
            "value2": 12.63
          },
          {
            "category": "14-06-2011",
            "value": 12.6629,
            "value2": 12.68
          },
          {
            "category": "15-06-2011",
            "value": 12.6472,
            "value2": 12.57
          },
          {
            "category": "16-06-2011",
            "value": 12.5919,
            "value2": 12.47
          },
          {
            "category": "17-06-2011",
            "value": 12.5069,
            "value2": 12.38
          },
          {
            "category": "18-06-2011",
            "value": 12.5069,
            "value2": 12.38
          },
          {
            "category": "19-06-2011",
            "value": 12.5069,
            "value2": 12.38
          },
          {
            "category": "20-06-2011",
            "value": 12.2988,
            "value2": 12.11
          },
          {
            "category": "21-06-2011",
            "value": 12.3131,
            "value2": 12.13
          },
          {
            "category": "22-06-2011",
            "value": 12.2642,
            "value2": 12.1
          },
          {
            "category": "23-06-2011",
            "value": 12.2524,
            "value2": 12.17
          },
          {
            "category": "24-06-2011",
            "value": 12.5015,
            "value2": 12.48
          },
          {
            "category": "25-06-2011",
            "value": 12.5015,
            "value2": 12.48
          },
          {
            "category": "26-06-2011",
            "value": 12.5015,
            "value2": 12.48
          },
          {
            "category": "27-06-2011",
            "value": 12.648,
            "value2": 12.61
          },
          {
            "category": "28-06-2011",
            "value": 12.7263,
            "value2": 12.66
          },
          {
            "category": "29-06-2011",
            "value": 12.8758,
            "value2": 12.77
          },
          {
            "category": "30-06-2011",
            "value": 12.8709,
            "value2": 12.85
          },
          {
            "category": "01-07-2011",
            "value": 13.0425,
            "value2": 12.86
          },
          {
            "category": "02-07-2011",
            "value": 13.0425,
            "value2": 12.86
          },
          {
            "category": "03-07-2011",
            "value": 13.0425,
            "value2": 12.86
          },
          {
            "category": "04-07-2011",
            "value": 13.0901,
            "value2": 12.94
          },
          {
            "category": "05-07-2011",
            "value": 13.0794,
            "value2": 12.91
          },
          {
            "category": "06-07-2011",
            "value": 13.066,
            "value2": 12.9
          },
          {
            "category": "07-07-2011",
            "value": 13.2429,
            "value2": 13.1
          },
          {
            "category": "08-07-2011",
            "value": 13.1356,
            "value2": 12.96
          },
          {
            "category": "09-07-2011",
            "value": 13.1356,
            "value2": 12.96
          },
          {
            "category": "10-07-2011",
            "value": 13.1356,
            "value2": 12.96
          },
          {
            "category": "11-07-2011",
            "value": 13.0364,
            "value2": 12.87
          },
          {
            "category": "12-07-2011",
            "value": 12.9434,
            "value2": 12.7
          },
          {
            "category": "13-07-2011",
            "value": 13.0943,
            "value2": 12.84
          },
          {
            "category": "14-07-2011",
            "value": 13.2315,
            "value2": 12.89
          },
          {
            "category": "15-07-2011",
            "value": 13.2303,
            "value2": 12.86
          },
          {
            "category": "16-07-2011",
            "value": 13.2303,
            "value2": 12.86
          },
          {
            "category": "17-07-2011",
            "value": 13.2303,
            "value2": 12.86
          },
          {
            "category": "18-07-2011",
            "value": 13.2725,
            "value2": 12.85
          },
          {
            "category": "19-07-2011",
            "value": 13.4922,
            "value2": 12.93
          },
          {
            "category": "20-07-2011",
            "value": 13.4236,
            "value2": 12.81
          },
          {
            "category": "21-07-2011",
            "value": 13.387,
            "value2": 12.75
          },
          {
            "category": "22-07-2011",
            "value": 13.5538,
            "value2": 12.94
          },
          {
            "category": "23-07-2011",
            "value": 13.5538,
            "value2": 12.94
          },
          {
            "category": "24-07-2011",
            "value": 13.5538,
            "value2": 12.94
          },
          {
            "category": "25-07-2011",
            "value": 13.6109,
            "value2": 13.04
          },
          {
            "category": "26-07-2011",
            "value": 13.3669,
            "value2": 12.84
          },
          {
            "category": "27-07-2011",
            "value": 13.3428,
            "value2": 12.8
          },
          {
            "category": "28-07-2011",
            "value": 13.2039,
            "value2": 12.67
          },
          {
            "category": "29-07-2011",
            "value": 13.1442,
            "value2": 12.6
          },
          {
            "category": "30-07-2011",
            "value": 13.1442,
            "value2": 12.6
          },
          {
            "category": "31-07-2011",
            "value": 13.1442,
            "value2": 12.6
          },
          {
            "category": "01-08-2011",
            "value": 13.2073,
            "value2": 12.65
          },
          {
            "category": "02-08-2011",
            "value": 13.0266,
            "value2": 12.52
          },
          {
            "category": "03-08-2011",
            "value": 12.9322,
            "value2": 12.43
          },
          {
            "category": "04-08-2011",
            "value": 12.8462,
            "value2": 12.29
          },
          {
            "category": "05-08-2011",
            "value": 12.626,
            "value2": 12.04
          },
          {
            "category": "06-08-2011",
            "value": 12.626,
            "value2": 12.04
          },
          {
            "category": "07-08-2011",
            "value": 12.626,
            "value2": 12.04
          },
          {
            "category": "08-08-2011",
            "value": 12.5744,
            "value2": 11.84
          },
          {
            "category": "09-08-2011",
            "value": 12.5388,
            "value2": 11.73
          },
          {
            "category": "10-08-2011",
            "value": 12.9585,
            "value2": 11.92
          },
          {
            "category": "11-08-2011",
            "value": 13.0883,
            "value2": 11.88
          },
          {
            "category": "12-08-2011",
            "value": 13.0874,
            "value2": 11.76
          },
          {
            "category": "13-08-2011",
            "value": 13.0874,
            "value2": 11.76
          },
          {
            "category": "14-08-2011",
            "value": 13.0874,
            "value2": 11.76
          },
          {
            "category": "15-08-2011",
            "value": 13.0874,
            "value2": 11.76
          },
          {
            "category": "16-08-2011",
            "value": 12.9993,
            "value2": 11.63
          },
          {
            "category": "17-08-2011",
            "value": 12.8804,
            "value2": 11.64
          },
          {
            "category": "18-08-2011",
            "value": 12.7435,
            "value2": 11.39
          },
          {
            "category": "19-08-2011",
            "value": 12.4897,
            "value2": 11.2
          },
          {
            "category": "20-08-2011",
            "value": 12.4897,
            "value2": 11.2
          },
          {
            "category": "21-08-2011",
            "value": 12.4897,
            "value2": 11.2
          },
          {
            "category": "22-08-2011",
            "value": 12.552,
            "value2": 11.33
          },
          {
            "category": "23-08-2011",
            "value": 12.6917,
            "value2": 11.43
          },
          {
            "category": "24-08-2011",
            "value": 12.5315,
            "value2": 11.32
          },
          {
            "category": "25-08-2011",
            "value": 12.4567,
            "value2": 11.23
          },
          {
            "category": "26-08-2011",
            "value": 12.2829,
            "value2": 10.99
          },
          {
            "category": "27-08-2011",
            "value": 12.2829,
            "value2": 10.99
          },
          {
            "category": "28-08-2011",
            "value": 12.2829,
            "value2": 10.99
          },
          {
            "category": "29-08-2011",
            "value": 12.4321,
            "value2": 11.35
          },
          {
            "category": "30-08-2011",
            "value": 12.6286,
            "value2": 11.52
          },
          {
            "category": "31-08-2011",
            "value": 12.6286,
            "value2": 11.52
          },
          {
            "category": "01-09-2011",
            "value": 12.5997,
            "value2": 11.52
          },
          {
            "category": "02-09-2011",
            "value": 12.6726,
            "value2": 11.61
          },
          {
            "category": "03-09-2011",
            "value": 12.6726,
            "value2": 11.61
          },
          {
            "category": "04-09-2011",
            "value": 12.6726,
            "value2": 11.61
          },
          {
            "category": "05-09-2011",
            "value": 12.6766,
            "value2": 11.59
          },
          {
            "category": "06-09-2011",
            "value": 12.6315,
            "value2": 11.67
          },
          {
            "category": "07-09-2011",
            "value": 12.846,
            "value2": 11.83
          },
          {
            "category": "08-09-2011",
            "value": 12.8865,
            "value2": 11.88
          },
          {
            "category": "09-09-2011",
            "value": 12.7898,
            "value2": 11.7
          },
          {
            "category": "10-09-2011",
            "value": 12.7898,
            "value2": 11.7
          },
          {
            "category": "11-09-2011",
            "value": 12.7898,
            "value2": 11.7
          },
          {
            "category": "12-09-2011",
            "value": 12.6191,
            "value2": 11.45
          },
          {
            "category": "13-09-2011",
            "value": 12.6812,
            "value2": 11.44
          },
          {
            "category": "14-09-2011",
            "value": 12.6435,
            "value2": 11.57
          },
          {
            "category": "15-09-2011",
            "value": 12.7567,
            "value2": 11.69
          },
          {
            "category": "16-09-2011",
            "value": 12.7601,
            "value2": 11.73
          },
          {
            "category": "17-09-2011",
            "value": 12.7601,
            "value2": 11.73
          },
          {
            "category": "18-09-2011",
            "value": 12.7601,
            "value2": 11.73
          },
          {
            "category": "19-09-2011",
            "value": 12.7529,
            "value2": 11.63
          },
          {
            "category": "20-09-2011",
            "value": 12.846,
            "value2": 11.83
          },
          {
            "category": "21-09-2011",
            "value": 12.956,
            "value2": 11.85
          },
          {
            "category": "22-09-2011",
            "value": 12.6257,
            "value2": 11.41
          },
          {
            "category": "23-09-2011",
            "value": 12.6769,
            "value2": 11.3
          },
          {
            "category": "24-09-2011",
            "value": 12.6769,
            "value2": 11.3
          },
          {
            "category": "25-09-2011",
            "value": 12.6769,
            "value2": 11.3
          },
          {
            "category": "26-09-2011",
            "value": 12.5353,
            "value2": 11.19
          },
          {
            "category": "27-09-2011",
            "value": 12.7039,
            "value2": 11.46
          },
          {
            "category": "28-09-2011",
            "value": 12.5266,
            "value2": 11.39
          },
          {
            "category": "29-09-2011",
            "value": 12.5872,
            "value2": 11.49
          },
          {
            "category": "30-09-2011",
            "value": 12.5654,
            "value2": 11.35
          },
          {
            "category": "01-10-2011",
            "value": 12.5654,
            "value2": 11.35
          },
          {
            "category": "02-10-2011",
            "value": 12.5654,
            "value2": 11.35
          },
          {
            "category": "03-10-2011",
            "value": 12.4311,
            "value2": 11.16
          },
          {
            "category": "04-10-2011",
            "value": 12.2551,
            "value2": 10.98
          },
          {
            "category": "05-10-2011",
            "value": 12.093,
            "value2": 10.92
          },
          {
            "category": "06-10-2011",
            "value": 12.093,
            "value2": 10.92
          },
          {
            "category": "07-10-2011",
            "value": 12.187,
            "value2": 11.17
          },
          {
            "category": "08-10-2011",
            "value": 12.187,
            "value2": 11.17
          },
          {
            "category": "09-10-2011",
            "value": 12.187,
            "value2": 11.17
          },
          {
            "category": "10-10-2011",
            "value": 12.3125,
            "value2": 11.35
          },
          {
            "category": "11-10-2011",
            "value": 12.3905,
            "value2": 11.37
          },
          {
            "category": "12-10-2011",
            "value": 12.5556,
            "value2": 11.61
          },
          {
            "category": "13-10-2011",
            "value": 12.4833,
            "value2": 11.58
          },
          {
            "category": "14-10-2011",
            "value": 12.5435,
            "value2": 11.68
          },
          {
            "category": "15-10-2011",
            "value": 12.5435,
            "value2": 11.68
          },
          {
            "category": "16-10-2011",
            "value": 12.5435,
            "value2": 11.68
          },
          {
            "category": "17-10-2011",
            "value": 12.634,
            "value2": 11.65
          },
          {
            "category": "18-10-2011",
            "value": 12.4934,
            "value2": 11.48
          },
          {
            "category": "19-10-2011",
            "value": 12.5202,
            "value2": 11.68
          },
          {
            "category": "20-10-2011",
            "value": 12.4155,
            "value2": 11.59
          },
          {
            "category": "21-10-2011",
            "value": 12.3287,
            "value2": 11.49
          },
          {
            "category": "22-10-2011",
            "value": 12.3287,
            "value2": 11.49
          },
          {
            "category": "23-10-2011",
            "value": 12.3287,
            "value2": 11.49
          },
          {
            "category": "24-10-2011",
            "value": 12.3301,
            "value2": 11.56
          },
          {
            "category": "25-10-2011",
            "value": 12.314,
            "value2": 11.71
          },
          {
            "category": "26-10-2011",
            "value": 12.4184,
            "value2": 11.77
          },
          {
            "category": "27-10-2011",
            "value": 12.4184,
            "value2": 11.77
          },
          {
            "category": "28-10-2011",
            "value": 12.5839,
            "value2": 12.06
          },
          {
            "category": "29-10-2011",
            "value": 12.5839,
            "value2": 12.06
          },
          {
            "category": "30-10-2011",
            "value": 12.5839,
            "value2": 12.06
          },
          {
            "category": "31-10-2011",
            "value": 12.7027,
            "value2": 12.03
          },
          {
            "category": "01-11-2011",
            "value": 12.5561,
            "value2": 11.91
          },
          {
            "category": "02-11-2011",
            "value": 12.5056,
            "value2": 11.91
          },
          {
            "category": "03-11-2011",
            "value": 12.6538,
            "value2": 11.93
          },
          {
            "category": "04-11-2011",
            "value": 12.7641,
            "value2": 12
          },
          {
            "category": "05-11-2011",
            "value": 12.7641,
            "value2": 12
          },
          {
            "category": "06-11-2011",
            "value": 12.7641,
            "value2": 12
          },
          {
            "category": "07-11-2011",
            "value": 12.7641,
            "value2": 12
          },
          {
            "category": "08-11-2011",
            "value": 12.8276,
            "value2": 12
          },
          {
            "category": "09-11-2011",
            "value": 12.655,
            "value2": 11.84
          },
          {
            "category": "10-11-2011",
            "value": 12.655,
            "value2": 11.84
          },
          {
            "category": "11-11-2011",
            "value": 12.5434,
            "value2": 11.72
          },
          {
            "category": "12-11-2011",
            "value": 12.5434,
            "value2": 11.72
          },
          {
            "category": "13-11-2011",
            "value": 12.5434,
            "value2": 11.72
          },
          {
            "category": "14-11-2011",
            "value": 12.4987,
            "value2": 11.64
          },
          {
            "category": "15-11-2011",
            "value": 12.2318,
            "value2": 11.42
          },
          {
            "category": "16-11-2011",
            "value": 12.1955,
            "value2": 11.33
          },
          {
            "category": "17-11-2011",
            "value": 12.1254,
            "value2": 11.13
          },
          {
            "category": "18-11-2011",
            "value": 12.0614,
            "value2": 11.06
          },
          {
            "category": "19-11-2011",
            "value": 12.0614,
            "value2": 11.06
          },
          {
            "category": "20-11-2011",
            "value": 12.0614,
            "value2": 11.06
          },
          {
            "category": "21-11-2011",
            "value": 11.9621,
            "value2": 10.8
          },
          {
            "category": "22-11-2011",
            "value": 11.9429,
            "value2": 10.86
          },
          {
            "category": "23-11-2011",
            "value": 11.6909,
            "value2": 10.63
          },
          {
            "category": "24-11-2011",
            "value": 11.8527,
            "value2": 10.75
          },
          {
            "category": "25-11-2011",
            "value": 11.9388,
            "value2": 10.69
          },
          {
            "category": "26-11-2011",
            "value": 11.9388,
            "value2": 10.69
          },
          {
            "category": "27-11-2011",
            "value": 11.9388,
            "value2": 10.69
          },
          {
            "category": "28-11-2011",
            "value": 12.1039,
            "value2": 10.97
          },
          {
            "category": "29-11-2011",
            "value": 12.0433,
            "value2": 10.87
          },
          {
            "category": "30-11-2011",
            "value": 12.059,
            "value2": 10.89
          },
          {
            "category": "01-12-2011",
            "value": 11.9654,
            "value2": 11.08
          },
          {
            "category": "02-12-2011",
            "value": 12.1337,
            "value2": 11.31
          },
          {
            "category": "03-12-2011",
            "value": 12.1337,
            "value2": 11.31
          },
          {
            "category": "04-12-2011",
            "value": 12.1337,
            "value2": 11.31
          },
          {
            "category": "05-12-2011",
            "value": 12.1335,
            "value2": 11.3
          },
          {
            "category": "06-12-2011",
            "value": 12.1335,
            "value2": 11.3
          },
          {
            "category": "07-12-2011",
            "value": 12.1065,
            "value2": 11.34
          },
          {
            "category": "08-12-2011",
            "value": 12.0236,
            "value2": 11.1
          },
          {
            "category": "09-12-2011",
            "value": 11.946,
            "value2": 10.95
          },
          {
            "category": "10-12-2011",
            "value": 11.946,
            "value2": 10.95
          },
          {
            "category": "11-12-2011",
            "value": 11.946,
            "value2": 10.95
          },
          {
            "category": "12-12-2011",
            "value": 11.7225,
            "value2": 10.72
          },
          {
            "category": "13-12-2011",
            "value": 11.7115,
            "value2": 10.77
          },
          {
            "category": "14-12-2011",
            "value": 11.6743,
            "value2": 10.68
          },
          {
            "category": "15-12-2011",
            "value": 11.5454,
            "value2": 10.63
          },
          {
            "category": "16-12-2011",
            "value": 11.3689,
            "value2": 10.42
          },
          {
            "category": "17-12-2011",
            "value": 11.3689,
            "value2": 10.42
          },
          {
            "category": "18-12-2011",
            "value": 11.3689,
            "value2": 10.42
          },
          {
            "category": "19-12-2011",
            "value": 11.1097,
            "value2": 10.3
          },
          {
            "category": "20-12-2011",
            "value": 10.9207,
            "value2": 10.14
          },
          {
            "category": "21-12-2011",
            "value": 10.9885,
            "value2": 10.41
          },
          {
            "category": "22-12-2011",
            "value": 11.0485,
            "value2": 10.5
          },
          {
            "category": "23-12-2011",
            "value": 10.997,
            "value2": 10.46
          },
          {
            "category": "24-12-2011",
            "value": 10.997,
            "value2": 10.46
          },
          {
            "category": "25-12-2011",
            "value": 10.997,
            "value2": 10.46
          },
          {
            "category": "26-12-2011",
            "value": 11.0771,
            "value2": 10.58
          },
          {
            "category": "27-12-2011",
            "value": 11.0097,
            "value2": 10.52
          },
          {
            "category": "28-12-2011",
            "value": 10.8714,
            "value2": 10.42
          },
          {
            "category": "29-12-2011",
            "value": 10.8644,
            "value2": 10.32
          },
          {
            "category": "30-12-2011",
            "value": 10.9363,
            "value2": 10.29
          },
          {
            "category": "31-12-2011",
            "value": 10.9363,
            "value2": 10.29
          },
          {
            "category": "01-01-2012",
            "value": 10.9363,
            "value2": 10.29
          },
          {
            "category": "02-01-2012",
            "value": 10.8815,
            "value2": 10.3
          },
          {
            "category": "03-01-2012",
            "value": 11.1222,
            "value2": 10.58
          },
          {
            "category": "04-01-2012",
            "value": 11.1179,
            "value2": 10.56
          },
          {
            "category": "05-01-2012",
            "value": 11.1303,
            "value2": 10.56
          },
          {
            "category": "06-01-2012",
            "value": 11.0547,
            "value2": 10.57
          },
          {
            "category": "07-01-2012",
            "value": 11.1395,
            "value2": 10.58
          },
          {
            "category": "08-01-2012",
            "value": 11.1395,
            "value2": 10.58
          },
          {
            "category": "09-01-2012",
            "value": 11.1437,
            "value2": 10.59
          },
          {
            "category": "10-01-2012",
            "value": 11.3619,
            "value2": 10.83
          },
          {
            "category": "11-01-2012",
            "value": 11.543,
            "value2": 10.88
          },
          {
            "category": "12-01-2012",
            "value": 11.5406,
            "value2": 10.86
          },
          {
            "category": "13-01-2012",
            "value": 11.6655,
            "value2": 10.95
          },
          {
            "category": "14-01-2012",
            "value": 11.6655,
            "value2": 10.95
          },
          {
            "category": "15-01-2012",
            "value": 11.6655,
            "value2": 10.95
          },
          {
            "category": "16-01-2012",
            "value": 11.7259,
            "value2": 10.96
          },
          {
            "category": "17-01-2012",
            "value": 11.8937,
            "value2": 11.15
          },
          {
            "category": "18-01-2012",
            "value": 11.7315,
            "value2": 11.09
          },
          {
            "category": "19-01-2012",
            "value": 11.797,
            "value2": 11.24
          },
          {
            "category": "20-01-2012",
            "value": 11.95,
            "value2": 11.3
          },
          {
            "category": "21-01-2012",
            "value": 11.95,
            "value2": 11.3
          },
          {
            "category": "22-01-2012",
            "value": 11.95,
            "value2": 11.3
          },
          {
            "category": "23-01-2012",
            "value": 12.0236,
            "value2": 11.3
          },
          {
            "category": "24-01-2012",
            "value": 12.3103,
            "value2": 11.48
          },
          {
            "category": "25-01-2012",
            "value": 12.3703,
            "value2": 11.57
          },
          {
            "category": "26-01-2012",
            "value": 12.3703,
            "value2": 11.57
          },
          {
            "category": "27-01-2012",
            "value": 12.4375,
            "value2": 11.68
          },
          {
            "category": "28-01-2012",
            "value": 12.4375,
            "value2": 11.68
          },
          {
            "category": "29-01-2012",
            "value": 12.4375,
            "value2": 11.68
          },
          {
            "category": "30-01-2012",
            "value": 12.2845,
            "value2": 11.43
          },
          {
            "category": "31-01-2012",
            "value": 12.5117,
            "value2": 11.67
          },
          {
            "category": "01-02-2012",
            "value": 12.6376,
            "value2": 11.77
          },
          {
            "category": "02-02-2012",
            "value": 12.7927,
            "value2": 11.85
          },
          {
            "category": "03-02-2012",
            "value": 12.8676,
            "value2": 11.97
          },
          {
            "category": "04-02-2012",
            "value": 12.8676,
            "value2": 11.97
          },
          {
            "category": "05-02-2012",
            "value": 12.8676,
            "value2": 11.97
          },
          {
            "category": "06-02-2012",
            "value": 13.2342,
            "value2": 12.08
          },
          {
            "category": "07-02-2012",
            "value": 13.1213,
            "value2": 12.01
          },
          {
            "category": "08-02-2012",
            "value": 13.2339,
            "value2": 12.1
          },
          {
            "category": "09-02-2012",
            "value": 13.1323,
            "value2": 12.2
          },
          {
            "category": "10-02-2012",
            "value": 13.1939,
            "value2": 12.16
          },
          {
            "category": "11-02-2012",
            "value": 13.1939,
            "value2": 12.16
          },
          {
            "category": "12-02-2012",
            "value": 13.1939,
            "value2": 12.16
          },
          {
            "category": "13-02-2012",
            "value": 13.1609,
            "value2": 12.19
          },
          {
            "category": "14-02-2012",
            "value": 13.1631,
            "value2": 12.26
          },
          {
            "category": "15-02-2012",
            "value": 13.4096,
            "value2": 12.52
          },
          {
            "category": "16-02-2012",
            "value": 13.3159,
            "value2": 12.53
          },
          {
            "category": "17-02-2012",
            "value": 13.2694,
            "value2": 12.61
          },
          {
            "category": "18-02-2012",
            "value": 13.2694,
            "value2": 12.61
          },
          {
            "category": "19-02-2012",
            "value": 13.2694,
            "value2": 12.61
          },
          {
            "category": "20-02-2012",
            "value": 13.2694,
            "value2": 12.61
          },
          {
            "category": "21-02-2012",
            "value": 13.4497,
            "value2": 12.71
          },
          {
            "category": "22-02-2012",
            "value": 13.1856,
            "value2": 12.41
          },
          {
            "category": "23-02-2012",
            "value": 13.1747,
            "value2": 12.36
          },
          {
            "category": "24-02-2012",
            "value": 13.2038,
            "value2": 12.25
          },
          {
            "category": "25-02-2012",
            "value": 13.2038,
            "value2": 12.25
          },
          {
            "category": "26-02-2012",
            "value": 13.2038,
            "value2": 12.25
          },
          {
            "category": "27-02-2012",
            "value": 12.8821,
            "value2": 11.91
          },
          {
            "category": "28-02-2012",
            "value": 13.2639,
            "value2": 12.18
          },
          {
            "category": "29-02-2012",
            "value": 13.3082,
            "value2": 12.22
          },
          {
            "category": "01-03-2012",
            "value": 13.3031,
            "value2": 12.14
          },
          {
            "category": "02-03-2012",
            "value": 13.2737,
            "value2": 12.16
          },
          {
            "category": "03-03-2012",
            "value": 13.2663,
            "value2": 12.17
          },
          {
            "category": "04-03-2012",
            "value": 13.2663,
            "value2": 12.17
          },
          {
            "category": "05-03-2012",
            "value": 13.1559,
            "value2": 12
          },
          {
            "category": "06-03-2012",
            "value": 13.1585,
            "value2": 11.88
          },
          {
            "category": "07-03-2012",
            "value": 13.0921,
            "value2": 11.86
          },
          {
            "category": "08-03-2012",
            "value": 13.0921,
            "value2": 11.86
          },
          {
            "category": "09-03-2012",
            "value": 13.3303,
            "value2": 12.11
          },
          {
            "category": "10-03-2012",
            "value": 13.3303,
            "value2": 12.11
          },
          {
            "category": "11-03-2012",
            "value": 13.3303,
            "value2": 12.11
          },
          {
            "category": "12-03-2012",
            "value": 13.5083,
            "value2": 12.19
          },
          {
            "category": "13-03-2012",
            "value": 13.7059,
            "value2": 12.34
          },
          {
            "category": "14-03-2012",
            "value": 13.7681,
            "value2": 12.41
          },
          {
            "category": "15-03-2012",
            "value": 13.6464,
            "value2": 12.23
          },
          {
            "category": "16-03-2012",
            "value": 13.6276,
            "value2": 12.08
          },
          {
            "category": "17-03-2012",
            "value": 13.6276,
            "value2": 12.08
          },
          {
            "category": "18-03-2012",
            "value": 13.6276,
            "value2": 12.08
          },
          {
            "category": "19-03-2012",
            "value": 13.5915,
            "value2": 11.95
          },
          {
            "category": "20-03-2012",
            "value": 13.6558,
            "value2": 12
          },
          {
            "category": "21-03-2012",
            "value": 13.8802,
            "value2": 12.21
          },
          {
            "category": "22-03-2012",
            "value": 13.6987,
            "value2": 11.91
          },
          {
            "category": "23-03-2012",
            "value": 13.7144,
            "value2": 12.02
          },
          {
            "category": "24-03-2012",
            "value": 13.7144,
            "value2": 12.02
          },
          {
            "category": "25-03-2012",
            "value": 13.7144,
            "value2": 12.02
          },
          {
            "category": "26-03-2012",
            "value": 13.5368,
            "value2": 11.81
          },
          {
            "category": "27-03-2012",
            "value": 13.5808,
            "value2": 11.91
          },
          {
            "category": "28-03-2012",
            "value": 13.5149,
            "value2": 11.8
          },
          {
            "category": "29-03-2012",
            "value": 13.5932,
            "value2": 11.8
          },
          {
            "category": "30-03-2012",
            "value": 14.113,
            "value2": 12.06
          },
          {
            "category": "31-03-2012",
            "value": 14.0468,
            "value2": 12.06
          },
          {
            "category": "01-04-2012",
            "value": 14.0468,
            "value2": 12.06
          },
          {
            "category": "02-04-2012",
            "value": 14.1526,
            "value2": 12.14
          },
          {
            "category": "03-04-2012",
            "value": 14.27,
            "value2": 12.25
          },
          {
            "category": "04-04-2012",
            "value": 14.2306,
            "value2": 12.19
          },
          {
            "category": "05-04-2012",
            "value": 14.2306,
            "value2": 12.19
          },
          {
            "category": "06-04-2012",
            "value": 14.2306,
            "value2": 12.19
          },
          {
            "category": "07-04-2012",
            "value": 14.2306,
            "value2": 12.19
          },
          {
            "category": "08-04-2012",
            "value": 14.2306,
            "value2": 12.19
          },
          {
            "category": "09-04-2012",
            "value": 14.0843,
            "value2": 12.01
          },
          {
            "category": "10-04-2012",
            "value": 14.1859,
            "value2": 12.01
          },
          {
            "category": "11-04-2012",
            "value": 14.1156,
            "value2": 11.97
          },
          {
            "category": "12-04-2012",
            "value": 14.3315,
            "value2": 12.06
          },
          {
            "category": "13-04-2012",
            "value": 14.2945,
            "value2": 11.94
          },
          {
            "category": "14-04-2012",
            "value": 14.2945,
            "value2": 11.94
          },
          {
            "category": "15-04-2012",
            "value": 14.2945,
            "value2": 11.94
          },
          {
            "category": "16-04-2012",
            "value": 14.3477,
            "value2": 11.99
          },
          {
            "category": "17-04-2012",
            "value": 14.4911,
            "value2": 12.11
          },
          {
            "category": "18-04-2012",
            "value": 14.5978,
            "value2": 12.14
          },
          {
            "category": "19-04-2012",
            "value": 14.8184,
            "value2": 12.19
          },
          {
            "category": "20-04-2012",
            "value": 14.724,
            "value2": 12.09
          },
          {
            "category": "21-04-2012",
            "value": 14.724,
            "value2": 12.09
          },
          {
            "category": "22-04-2012",
            "value": 14.724,
            "value2": 12.09
          },
          {
            "category": "23-04-2012",
            "value": 14.5105,
            "value2": 11.89
          },
          {
            "category": "24-04-2012",
            "value": 14.4757,
            "value2": 11.92
          },
          {
            "category": "25-04-2012",
            "value": 14.3963,
            "value2": 11.87
          },
          {
            "category": "26-04-2012",
            "value": 14.3843,
            "value2": 11.83
          },
          {
            "category": "27-04-2012",
            "value": 14.4826,
            "value2": 11.82
          },
          {
            "category": "28-04-2012",
            "value": 14.5138,
            "value2": 11.87
          },
          {
            "category": "29-04-2012",
            "value": 14.5138,
            "value2": 11.87
          },
          {
            "category": "30-04-2012",
            "value": 14.5877,
            "value2": 11.95
          },
          {
            "category": "01-05-2012",
            "value": 14.5536,
            "value2": 11.95
          },
          {
            "category": "02-05-2012",
            "value": 14.5302,
            "value2": 11.93
          },
          {
            "category": "03-05-2012",
            "value": 14.2867,
            "value2": 11.82
          },
          {
            "category": "04-05-2012",
            "value": 14.0771,
            "value2": 11.59
          },
          {
            "category": "05-05-2012",
            "value": 14.0771,
            "value2": 11.59
          },
          {
            "category": "06-05-2012",
            "value": 14.0771,
            "value2": 11.59
          },
          {
            "category": "07-05-2012",
            "value": 14.0218,
            "value2": 11.66
          },
          {
            "category": "08-05-2012",
            "value": 13.779,
            "value2": 11.43
          },
          {
            "category": "09-05-2012",
            "value": 13.7336,
            "value2": 11.35
          },
          {
            "category": "10-05-2012",
            "value": 13.8451,
            "value2": 11.33
          },
          {
            "category": "11-05-2012",
            "value": 13.8284,
            "value2": 11.24
          },
          {
            "category": "12-05-2012",
            "value": 13.8284,
            "value2": 11.24
          },
          {
            "category": "13-05-2012",
            "value": 13.8284,
            "value2": 11.24
          },
          {
            "category": "14-05-2012",
            "value": 13.575,
            "value2": 11.18
          },
          {
            "category": "15-05-2012",
            "value": 13.7407,
            "value2": 11.25
          },
          {
            "category": "16-05-2012",
            "value": 13.6559,
            "value2": 11.09
          },
          {
            "category": "17-05-2012",
            "value": 13.6517,
            "value2": 11.1
          },
          {
            "category": "18-05-2012",
            "value": 13.5745,
            "value2": 11.14
          },
          {
            "category": "19-05-2012",
            "value": 13.5745,
            "value2": 11.14
          },
          {
            "category": "20-05-2012",
            "value": 13.5745,
            "value2": 11.14
          },
          {
            "category": "21-05-2012",
            "value": 13.6625,
            "value2": 11.18
          },
          {
            "category": "22-05-2012",
            "value": 13.5723,
            "value2": 11.08
          },
          {
            "category": "23-05-2012",
            "value": 13.4788,
            "value2": 11.02
          },
          {
            "category": "24-05-2012",
            "value": 13.5946,
            "value2": 11.19
          },
          {
            "category": "25-05-2012",
            "value": 13.7001,
            "value2": 11.21
          },
          {
            "category": "26-05-2012",
            "value": 13.7001,
            "value2": 11.21
          },
          {
            "category": "27-05-2012",
            "value": 13.7001,
            "value2": 11.21
          },
          {
            "category": "28-05-2012",
            "value": 13.8479,
            "value2": 11.35
          },
          {
            "category": "29-05-2012",
            "value": 13.8745,
            "value2": 11.35
          },
          {
            "category": "30-05-2012",
            "value": 13.6273,
            "value2": 11.24
          },
          {
            "category": "31-05-2012",
            "value": 13.8175,
            "value2": 11.24
          },
          {
            "category": "01-06-2012",
            "value": 13.5398,
            "value2": 11.05
          },
          {
            "category": "02-06-2012",
            "value": 13.5398,
            "value2": 11.05
          },
          {
            "category": "03-06-2012",
            "value": 13.5398,
            "value2": 11.05
          },
          {
            "category": "04-06-2012",
            "value": 13.4732,
            "value2": 11.05
          },
          {
            "category": "05-06-2012",
            "value": 13.5803,
            "value2": 11.08
          },
          {
            "category": "06-06-2012",
            "value": 13.7262,
            "value2": 11.35
          },
          {
            "category": "07-06-2012",
            "value": 13.8457,
            "value2": 11.47
          },
          {
            "category": "08-06-2012",
            "value": 13.8637,
            "value2": 11.51
          },
          {
            "category": "09-06-2012",
            "value": 13.8637,
            "value2": 11.51
          },
          {
            "category": "10-06-2012",
            "value": 13.8637,
            "value2": 11.51
          },
          {
            "category": "11-06-2012",
            "value": 13.8209,
            "value2": 11.48
          },
          {
            "category": "12-06-2012",
            "value": 13.7205,
            "value2": 11.61
          },
          {
            "category": "13-06-2012",
            "value": 13.6815,
            "value2": 11.61
          },
          {
            "category": "14-06-2012",
            "value": 13.5252,
            "value2": 11.47
          },
          {
            "category": "15-06-2012",
            "value": 13.6186,
            "value2": 11.63
          },
          {
            "category": "16-06-2012",
            "value": 13.6186,
            "value2": 11.63
          },
          {
            "category": "17-06-2012",
            "value": 13.6186,
            "value2": 11.63
          },
          {
            "category": "18-06-2012",
            "value": 13.4946,
            "value2": 11.47
          },
          {
            "category": "19-06-2012",
            "value": 13.4745,
            "value2": 11.55
          },
          {
            "category": "20-06-2012",
            "value": 13.5736,
            "value2": 11.61
          },
          {
            "category": "21-06-2012",
            "value": 13.6857,
            "value2": 11.71
          },
          {
            "category": "22-06-2012",
            "value": 13.6942,
            "value2": 11.69
          },
          {
            "category": "23-06-2012",
            "value": 13.6942,
            "value2": 11.69
          },
          {
            "category": "24-06-2012",
            "value": 13.6942,
            "value2": 11.69
          },
          {
            "category": "25-06-2012",
            "value": 13.7173,
            "value2": 11.64
          },
          {
            "category": "26-06-2012",
            "value": 13.7276,
            "value2": 11.67
          },
          {
            "category": "27-06-2012",
            "value": 13.7916,
            "value2": 11.71
          },
          {
            "category": "28-06-2012",
            "value": 13.8033,
            "value2": 11.73
          },
          {
            "category": "29-06-2012",
            "value": 14.0967,
            "value2": 12
          },
          {
            "category": "30-06-2012",
            "value": 14.0967,
            "value2": 12
          },
          {
            "category": "01-07-2012",
            "value": 14.0967,
            "value2": 12
          },
          {
            "category": "02-07-2012",
            "value": 14.058,
            "value2": 12.04
          },
          {
            "category": "03-07-2012",
            "value": 14.1839,
            "value2": 12.08
          },
          {
            "category": "04-07-2012",
            "value": 14.2898,
            "value2": 12.13
          },
          {
            "category": "05-07-2012",
            "value": 14.3167,
            "value2": 12.2
          },
          {
            "category": "06-07-2012",
            "value": 14.2463,
            "value2": 12.16
          },
          {
            "category": "07-07-2012",
            "value": 14.2463,
            "value2": 12.16
          },
          {
            "category": "08-07-2012",
            "value": 14.2463,
            "value2": 12.16
          },
          {
            "category": "09-07-2012",
            "value": 14.084,
            "value2": 12.05
          },
          {
            "category": "10-07-2012",
            "value": 14.2145,
            "value2": 12.2
          },
          {
            "category": "11-07-2012",
            "value": 14.1635,
            "value2": 12.14
          },
          {
            "category": "12-07-2012",
            "value": 14.1506,
            "value2": 12.01
          },
          {
            "category": "13-07-2012",
            "value": 14.1421,
            "value2": 11.99
          },
          {
            "category": "14-07-2012",
            "value": 14.1421,
            "value2": 11.99
          },
          {
            "category": "15-07-2012",
            "value": 14.1421,
            "value2": 11.99
          },
          {
            "category": "16-07-2012",
            "value": 14.1119,
            "value2": 11.93
          },
          {
            "category": "17-07-2012",
            "value": 14.0509,
            "value2": 11.89
          },
          {
            "category": "18-07-2012",
            "value": 14.1281,
            "value2": 11.94
          },
          {
            "category": "19-07-2012",
            "value": 14.1811,
            "value2": 11.99
          },
          {
            "category": "20-07-2012",
            "value": 14.1792,
            "value2": 11.94
          },
          {
            "category": "21-07-2012",
            "value": 14.1792,
            "value2": 11.94
          },
          {
            "category": "22-07-2012",
            "value": 14.1792,
            "value2": 11.94
          },
          {
            "category": "23-07-2012",
            "value": 14.1043,
            "value2": 11.75
          },
          {
            "category": "24-07-2012",
            "value": 14.1157,
            "value2": 11.78
          },
          {
            "category": "25-07-2012",
            "value": 14.1325,
            "value2": 11.73
          },
          {
            "category": "26-07-2012",
            "value": 13.9849,
            "value2": 11.56
          },
          {
            "category": "27-07-2012",
            "value": 13.9337,
            "value2": 11.62
          },
          {
            "category": "28-07-2012",
            "value": 13.9337,
            "value2": 11.62
          },
          {
            "category": "29-07-2012",
            "value": 13.9337,
            "value2": 11.62
          },
          {
            "category": "30-07-2012",
            "value": 14.1223,
            "value2": 11.84
          },
          {
            "category": "31-07-2012",
            "value": 14.1216,
            "value2": 11.9
          },
          {
            "category": "01-08-2012",
            "value": 14.1948,
            "value2": 11.95
          },
          {
            "category": "02-08-2012",
            "value": 14.3646,
            "value2": 11.95
          },
          {
            "category": "03-08-2012",
            "value": 14.3093,
            "value2": 11.92
          },
          {
            "category": "04-08-2012",
            "value": 14.3093,
            "value2": 11.92
          },
          {
            "category": "05-08-2012",
            "value": 14.3093,
            "value2": 11.92
          },
          {
            "category": "06-08-2012",
            "value": 14.3646,
            "value2": 12.04
          },
          {
            "category": "07-08-2012",
            "value": 14.5409,
            "value2": 12.14
          },
          {
            "category": "08-08-2012",
            "value": 14.4842,
            "value2": 12.13
          },
          {
            "category": "09-08-2012",
            "value": 14.5357,
            "value2": 12.1
          },
          {
            "category": "10-08-2012",
            "value": 14.4286,
            "value2": 12.09
          },
          {
            "category": "11-08-2012",
            "value": 14.4286,
            "value2": 12.09
          },
          {
            "category": "12-08-2012",
            "value": 14.4286,
            "value2": 12.09
          },
          {
            "category": "13-08-2012",
            "value": 14.5283,
            "value2": 12.17
          },
          {
            "category": "14-08-2012",
            "value": 14.5256,
            "value2": 12.23
          },
          {
            "category": "15-08-2012",
            "value": 14.5256,
            "value2": 12.23
          },
          {
            "category": "16-08-2012",
            "value": 14.6409,
            "value2": 12.2
          },
          {
            "category": "17-08-2012",
            "value": 14.5632,
            "value2": 12.2
          },
          {
            "category": "18-08-2012",
            "value": 14.5632,
            "value2": 12.2
          },
          {
            "category": "19-08-2012",
            "value": 14.5632,
            "value2": 12.2
          },
          {
            "category": "20-08-2012",
            "value": 14.563,
            "value2": 12.2
          },
          {
            "category": "21-08-2012",
            "value": 14.5731,
            "value2": 12.31
          },
          {
            "category": "22-08-2012",
            "value": 14.5588,
            "value2": 12.28
          },
          {
            "category": "23-08-2012",
            "value": 14.4457,
            "value2": 12.28
          },
          {
            "category": "24-08-2012",
            "value": 14.3297,
            "value2": 12.22
          },
          {
            "category": "25-08-2012",
            "value": 14.3297,
            "value2": 12.22
          },
          {
            "category": "26-08-2012",
            "value": 14.3297,
            "value2": 12.22
          },
          {
            "category": "27-08-2012",
            "value": 14.4132,
            "value2": 12.13
          },
          {
            "category": "28-08-2012",
            "value": 14.3344,
            "value2": 12.07
          },
          {
            "category": "29-08-2012",
            "value": 14.2555,
            "value2": 11.98
          },
          {
            "category": "30-08-2012",
            "value": 14.2535,
            "value2": 12.03
          },
          {
            "category": "31-08-2012",
            "value": 14.272,
            "value2": 11.97
          },
          {
            "category": "01-09-2012",
            "value": 14.272,
            "value2": 11.97
          },
          {
            "category": "02-09-2012",
            "value": 14.272,
            "value2": 11.97
          },
          {
            "category": "03-09-2012",
            "value": 14.2671,
            "value2": 11.94
          },
          {
            "category": "04-09-2012",
            "value": 14.2845,
            "value2": 12
          },
          {
            "category": "05-09-2012",
            "value": 14.21,
            "value2": 11.91
          },
          {
            "category": "06-09-2012",
            "value": 14.3412,
            "value2": 11.94
          },
          {
            "category": "07-09-2012",
            "value": 14.3907,
            "value2": 12.14
          },
          {
            "category": "08-09-2012",
            "value": 14.4591,
            "value2": 12.19
          },
          {
            "category": "09-09-2012",
            "value": 14.4591,
            "value2": 12.19
          },
          {
            "category": "10-09-2012",
            "value": 14.5346,
            "value2": 12.2
          },
          {
            "category": "11-09-2012",
            "value": 14.593,
            "value2": 12.25
          },
          {
            "category": "12-09-2012",
            "value": 14.6712,
            "value2": 12.33
          },
          {
            "category": "13-09-2012",
            "value": 14.7874,
            "value2": 12.32
          },
          {
            "category": "14-09-2012",
            "value": 14.9433,
            "value2": 12.57
          },
          {
            "category": "15-09-2012",
            "value": 14.9433,
            "value2": 12.57
          },
          {
            "category": "16-09-2012",
            "value": 14.9433,
            "value2": 12.57
          },
          {
            "category": "17-09-2012",
            "value": 15.0166,
            "value2": 12.67
          },
          {
            "category": "18-09-2012",
            "value": 15.0017,
            "value2": 12.7
          },
          {
            "category": "19-09-2012",
            "value": 15.0017,
            "value2": 12.7
          },
          {
            "category": "20-09-2012",
            "value": 14.8939,
            "value2": 12.61
          },
          {
            "category": "21-09-2012",
            "value": 14.9854,
            "value2": 12.88
          },
          {
            "category": "22-09-2012",
            "value": 14.9854,
            "value2": 12.88
          },
          {
            "category": "23-09-2012",
            "value": 14.9854,
            "value2": 12.88
          },
          {
            "category": "24-09-2012",
            "value": 14.9237,
            "value2": 12.87
          },
          {
            "category": "25-09-2012",
            "value": 15.0189,
            "value2": 12.9
          },
          {
            "category": "26-09-2012",
            "value": 15.2284,
            "value2": 12.89
          },
          {
            "category": "27-09-2012",
            "value": 15.1968,
            "value2": 12.89
          },
          {
            "category": "28-09-2012",
            "value": 15.359,
            "value2": 13.02
          },
          {
            "category": "29-09-2012",
            "value": 15.359,
            "value2": 13.02
          },
          {
            "category": "30-09-2012",
            "value": 15.359,
            "value2": 13.02
          },
          {
            "category": "01-10-2012",
            "value": 15.3897,
            "value2": 13.08
          },
          {
            "category": "02-10-2012",
            "value": 15.3897,
            "value2": 13.08
          },
          {
            "category": "03-10-2012",
            "value": 15.4372,
            "value2": 13.13
          },
          {
            "category": "04-10-2012",
            "value": 15.5581,
            "value2": 13.24
          },
          {
            "category": "05-10-2012",
            "value": 15.5384,
            "value2": 13.15
          },
          {
            "category": "06-10-2012",
            "value": 15.5384,
            "value2": 13.15
          },
          {
            "category": "07-10-2012",
            "value": 15.5384,
            "value2": 13.15
          },
          {
            "category": "08-10-2012",
            "value": 15.4202,
            "value2": 13.02
          },
          {
            "category": "09-10-2012",
            "value": 15.5502,
            "value2": 13.08
          },
          {
            "category": "10-10-2012",
            "value": 15.5429,
            "value2": 12.95
          },
          {
            "category": "11-10-2012",
            "value": 15.6314,
            "value2": 13.08
          },
          {
            "category": "12-10-2012",
            "value": 15.6977,
            "value2": 13.03
          },
          {
            "category": "13-10-2012",
            "value": 15.6977,
            "value2": 13.03
          },
          {
            "category": "14-10-2012",
            "value": 15.6977,
            "value2": 13.03
          },
          {
            "category": "15-10-2012",
            "value": 15.7101,
            "value2": 13.05
          },
          {
            "category": "16-10-2012",
            "value": 15.7244,
            "value2": 12.96
          },
          {
            "category": "17-10-2012",
            "value": 15.8049,
            "value2": 12.98
          },
          {
            "category": "18-10-2012",
            "value": 15.9538,
            "value2": 13.12
          },
          {
            "category": "19-10-2012",
            "value": 15.7988,
            "value2": 13.04
          },
          {
            "category": "20-10-2012",
            "value": 15.7988,
            "value2": 13.04
          },
          {
            "category": "21-10-2012",
            "value": 15.7988,
            "value2": 13.04
          },
          {
            "category": "22-10-2012",
            "value": 15.7337,
            "value2": 13.09
          },
          {
            "category": "23-10-2012",
            "value": 15.7872,
            "value2": 13.04
          },
          {
            "category": "24-10-2012",
            "value": 15.7872,
            "value2": 13.04
          },
          {
            "category": "25-10-2012",
            "value": 15.8577,
            "value2": 13.07
          },
          {
            "category": "26-10-2012",
            "value": 15.7212,
            "value2": 12.96
          },
          {
            "category": "27-10-2012",
            "value": 15.7212,
            "value2": 12.96
          },
          {
            "category": "28-10-2012",
            "value": 15.7212,
            "value2": 12.96
          },
          {
            "category": "29-10-2012",
            "value": 15.6845,
            "value2": 12.94
          },
          {
            "category": "30-10-2012",
            "value": 15.5076,
            "value2": 12.8
          },
          {
            "category": "31-10-2012",
            "value": 15.7038,
            "value2": 12.87
          },
          {
            "category": "01-11-2012",
            "value": 15.7404,
            "value2": 12.95
          },
          {
            "category": "02-11-2012",
            "value": 15.8078,
            "value2": 13.06
          },
          {
            "category": "03-11-2012",
            "value": 15.8078,
            "value2": 13.06
          },
          {
            "category": "04-11-2012",
            "value": 15.8078,
            "value2": 13.06
          },
          {
            "category": "05-11-2012",
            "value": 15.7613,
            "value2": 13.06
          },
          {
            "category": "06-11-2012",
            "value": 15.913,
            "value2": 13.11
          },
          {
            "category": "07-11-2012",
            "value": 16.047,
            "value2": 13.19
          },
          {
            "category": "08-11-2012",
            "value": 16.0964,
            "value2": 13.17
          },
          {
            "category": "09-11-2012",
            "value": 15.889,
            "value2": 13.06
          },
          {
            "category": "10-11-2012",
            "value": 15.889,
            "value2": 13.06
          },
          {
            "category": "11-11-2012",
            "value": 15.889,
            "value2": 13.06
          },
          {
            "category": "12-11-2012",
            "value": 15.9766,
            "value2": 13.09
          },
          {
            "category": "13-11-2012",
            "value": 16.1055,
            "value2": 13.08
          },
          {
            "category": "14-11-2012",
            "value": 16.1055,
            "value2": 13.08
          },
          {
            "category": "15-11-2012",
            "value": 16.0197,
            "value2": 13.02
          },
          {
            "category": "16-11-2012",
            "value": 15.8251,
            "value2": 12.89
          },
          {
            "category": "17-11-2012",
            "value": 15.8251,
            "value2": 12.89
          },
          {
            "category": "18-11-2012",
            "value": 15.8251,
            "value2": 12.89
          },
          {
            "category": "19-11-2012",
            "value": 15.7813,
            "value2": 12.85
          },
          {
            "category": "20-11-2012",
            "value": 15.7307,
            "value2": 12.81
          },
          {
            "category": "21-11-2012",
            "value": 15.751,
            "value2": 12.89
          },
          {
            "category": "22-11-2012",
            "value": 15.7851,
            "value2": 12.94
          },
          {
            "category": "23-11-2012",
            "value": 15.8475,
            "value2": 12.94
          },
          {
            "category": "24-11-2012",
            "value": 15.8475,
            "value2": 12.94
          },
          {
            "category": "25-11-2012",
            "value": 15.8475,
            "value2": 12.94
          },
          {
            "category": "26-11-2012",
            "value": 16.1907,
            "value2": 13
          },
          {
            "category": "27-11-2012",
            "value": 16.3044,
            "value2": 13.19
          },
          {
            "category": "28-11-2012",
            "value": 16.3044,
            "value2": 13.19
          },
          {
            "category": "29-11-2012",
            "value": 16.564,
            "value2": 13.39
          },
          {
            "category": "30-11-2012",
            "value": 16.6171,
            "value2": 13.52
          },
          {
            "category": "01-12-2012",
            "value": 16.6171,
            "value2": 13.52
          },
          {
            "category": "02-12-2012",
            "value": 16.6171,
            "value2": 13.52
          },
          {
            "category": "03-12-2012",
            "value": 16.7877,
            "value2": 13.55
          },
          {
            "category": "04-12-2012",
            "value": 16.8526,
            "value2": 13.59
          },
          {
            "category": "05-12-2012",
            "value": 16.8206,
            "value2": 13.63
          },
          {
            "category": "06-12-2012",
            "value": 16.8087,
            "value2": 13.71
          },
          {
            "category": "07-12-2012",
            "value": 16.7513,
            "value2": 13.66
          },
          {
            "category": "08-12-2012",
            "value": 16.7513,
            "value2": 13.66
          },
          {
            "category": "09-12-2012",
            "value": 16.7513,
            "value2": 13.66
          },
          {
            "category": "10-12-2012",
            "value": 16.7737,
            "value2": 13.68
          },
          {
            "category": "11-12-2012",
            "value": 16.6826,
            "value2": 13.63
          },
          {
            "category": "12-12-2012",
            "value": 16.7023,
            "value2": 13.62
          },
          {
            "category": "13-12-2012",
            "value": 16.5047,
            "value2": 13.52
          },
          {
            "category": "14-12-2012",
            "value": 16.5485,
            "value2": 13.59
          },
          {
            "category": "15-12-2012",
            "value": 16.5485,
            "value2": 13.59
          },
          {
            "category": "16-12-2012",
            "value": 16.5485,
            "value2": 13.59
          },
          {
            "category": "17-12-2012",
            "value": 16.5671,
            "value2": 13.58
          },
          {
            "category": "18-12-2012",
            "value": 16.5755,
            "value2": 13.68
          },
          {
            "category": "19-12-2012",
            "value": 16.6309,
            "value2": 13.76
          },
          {
            "category": "20-12-2012",
            "value": 16.5699,
            "value2": 13.73
          },
          {
            "category": "21-12-2012",
            "value": 16.3854,
            "value2": 13.56
          },
          {
            "category": "22-12-2012",
            "value": 16.3854,
            "value2": 13.56
          },
          {
            "category": "23-12-2012",
            "value": 16.3854,
            "value2": 13.56
          },
          {
            "category": "24-12-2012",
            "value": 16.4941,
            "value2": 13.58
          },
          {
            "category": "25-12-2012",
            "value": 16.4941,
            "value2": 13.58
          },
          {
            "category": "26-12-2012",
            "value": 16.6365,
            "value2": 13.68
          },
          {
            "category": "27-12-2012",
            "value": 16.6199,
            "value2": 13.63
          },
          {
            "category": "28-12-2012",
            "value": 16.7892,
            "value2": 13.71
          },
          {
            "category": "29-12-2012",
            "value": 16.7892,
            "value2": 13.71
          },
          {
            "category": "30-12-2012",
            "value": 16.7892,
            "value2": 13.71
          },
          {
            "category": "31-12-2012",
            "value": 16.8651,
            "value2": 13.72
          },
          {
            "category": "01-01-2013",
            "value": 16.946,
            "value2": 13.85
          },
          {
            "category": "02-01-2013",
            "value": 16.9556,
            "value2": 13.94
          },
          {
            "category": "03-01-2013",
            "value": 17.0047,
            "value2": 13.99
          },
          {
            "category": "04-01-2013",
            "value": 17.0637,
            "value2": 14.01
          },
          {
            "category": "05-01-2013",
            "value": 17.0637,
            "value2": 14.01
          },
          {
            "category": "06-01-2013",
            "value": 17.0637,
            "value2": 14.01
          },
          {
            "category": "07-01-2013",
            "value": 17.0804,
            "value2": 13.98
          },
          {
            "category": "08-01-2013",
            "value": 17.0932,
            "value2": 14
          },
          {
            "category": "09-01-2013",
            "value": 17.023,
            "value2": 13.94
          },
          {
            "category": "10-01-2013",
            "value": 17.037,
            "value2": 13.92
          },
          {
            "category": "11-01-2013",
            "value": 16.8667,
            "value2": 13.83
          },
          {
            "category": "12-01-2013",
            "value": 16.8667,
            "value2": 13.83
          },
          {
            "category": "13-01-2013",
            "value": 16.8667,
            "value2": 13.83
          },
          {
            "category": "14-01-2013",
            "value": 16.9232,
            "value2": 14
          },
          {
            "category": "15-01-2013",
            "value": 16.9345,
            "value2": 14.05
          },
          {
            "category": "16-01-2013",
            "value": 16.8089,
            "value2": 13.91
          },
          {
            "category": "17-01-2013",
            "value": 16.7829,
            "value2": 13.99
          },
          {
            "category": "18-01-2013",
            "value": 16.7759,
            "value2": 14.02
          },
          {
            "category": "19-01-2013",
            "value": 16.7759,
            "value2": 14.02
          },
          {
            "category": "20-01-2013",
            "value": 16.7759,
            "value2": 14.02
          },
          {
            "category": "21-01-2013",
            "value": 16.7355,
            "value2": 14.05
          },
          {
            "category": "22-01-2013",
            "value": 16.7087,
            "value2": 13.96
          },
          {
            "category": "23-01-2013",
            "value": 16.6081,
            "value2": 13.93
          },
          {
            "category": "24-01-2013",
            "value": 16.49,
            "value2": 13.78
          },
          {
            "category": "25-01-2013",
            "value": 16.5219,
            "value2": 13.93
          },
          {
            "category": "26-01-2013",
            "value": 16.5219,
            "value2": 13.93
          },
          {
            "category": "27-01-2013",
            "value": 16.5219,
            "value2": 13.93
          },
          {
            "category": "28-01-2013",
            "value": 16.5113,
            "value2": 13.94
          },
          {
            "category": "29-01-2013",
            "value": 16.5368,
            "value2": 13.87
          },
          {
            "category": "30-01-2013",
            "value": 16.6007,
            "value2": 13.88
          },
          {
            "category": "31-01-2013",
            "value": 16.7827,
            "value2": 13.88
          },
          {
            "category": "01-02-2013",
            "value": 16.8241,
            "value2": 13.83
          },
          {
            "category": "02-02-2013",
            "value": 16.8241,
            "value2": 13.83
          },
          {
            "category": "03-02-2013",
            "value": 16.8241,
            "value2": 13.83
          },
          {
            "category": "04-02-2013",
            "value": 16.8191,
            "value2": 13.76
          },
          {
            "category": "05-02-2013",
            "value": 16.7103,
            "value2": 13.7
          },
          {
            "category": "06-02-2013",
            "value": 16.5909,
            "value2": 13.71
          },
          {
            "category": "07-02-2013",
            "value": 16.5264,
            "value2": 13.64
          },
          {
            "category": "08-02-2013",
            "value": 16.4533,
            "value2": 13.54
          },
          {
            "category": "09-02-2013",
            "value": 16.4533,
            "value2": 13.54
          },
          {
            "category": "10-02-2013",
            "value": 16.4533,
            "value2": 13.54
          },
          {
            "category": "11-02-2013",
            "value": 16.4435,
            "value2": 13.53
          },
          {
            "category": "12-02-2013",
            "value": 16.3853,
            "value2": 13.57
          },
          {
            "category": "13-02-2013",
            "value": 16.4453,
            "value2": 13.56
          },
          {
            "category": "14-02-2013",
            "value": 16.4726,
            "value2": 13.44
          },
          {
            "category": "15-02-2013",
            "value": 16.3339,
            "value2": 13.43
          },
          {
            "category": "16-02-2013",
            "value": 16.3339,
            "value2": 13.43
          },
          {
            "category": "17-02-2013",
            "value": 16.3339,
            "value2": 13.43
          },
          {
            "category": "18-02-2013",
            "value": 16.4093,
            "value2": 13.47
          },
          {
            "category": "19-02-2013",
            "value": 16.6296,
            "value2": 13.59
          },
          {
            "category": "20-02-2013",
            "value": 16.7015,
            "value2": 13.6
          },
          {
            "category": "21-02-2013",
            "value": 16.4714,
            "value2": 13.37
          },
          {
            "category": "22-02-2013",
            "value": 16.4197,
            "value2": 13.38
          },
          {
            "category": "23-02-2013",
            "value": 16.4197,
            "value2": 13.38
          },
          {
            "category": "24-02-2013",
            "value": 16.4197,
            "value2": 13.38
          },
          {
            "category": "25-02-2013",
            "value": 16.3732,
            "value2": 13.34
          },
          {
            "category": "26-02-2013",
            "value": 16.2378,
            "value2": 13.13
          },
          {
            "category": "27-02-2013",
            "value": 16.3792,
            "value2": 13.23
          },
          {
            "category": "28-02-2013",
            "value": 16.073,
            "value2": 12.98
          },
          {
            "category": "01-03-2013",
            "value": 16.2229,
            "value2": 13.03
          },
          {
            "category": "02-03-2013",
            "value": 16.2229,
            "value2": 13.03
          },
          {
            "category": "03-03-2013",
            "value": 16.2229,
            "value2": 13.03
          },
          {
            "category": "04-03-2013",
            "value": 16.0736,
            "value2": 12.94
          },
          {
            "category": "05-03-2013",
            "value": 16.2459,
            "value2": 13.14
          },
          {
            "category": "06-03-2013",
            "value": 16.3281,
            "value2": 13.23
          },
          {
            "category": "07-03-2013",
            "value": 16.4314,
            "value2": 13.33
          },
          {
            "category": "08-03-2013",
            "value": 16.4356,
            "value2": 13.49
          },
          {
            "category": "09-03-2013",
            "value": 16.4356,
            "value2": 13.49
          },
          {
            "category": "10-03-2013",
            "value": 16.4356,
            "value2": 13.49
          },
          {
            "category": "11-03-2013",
            "value": 16.3954,
            "value2": 13.49
          },
          {
            "category": "12-03-2013",
            "value": 16.4726,
            "value2": 13.42
          },
          {
            "category": "13-03-2013",
            "value": 16.3626,
            "value2": 13.27
          },
          {
            "category": "14-03-2013",
            "value": 16.3894,
            "value2": 13.39
          },
          {
            "category": "15-03-2013",
            "value": 16.3906,
            "value2": 13.31
          },
          {
            "category": "16-03-2013",
            "value": 16.3906,
            "value2": 13.31
          },
          {
            "category": "17-03-2013",
            "value": 16.3906,
            "value2": 13.31
          },
          {
            "category": "18-03-2013",
            "value": 16.4032,
            "value2": 13.23
          },
          {
            "category": "19-03-2013",
            "value": 16.2997,
            "value2": 13.03
          },
          {
            "category": "20-03-2013",
            "value": 16.1318,
            "value2": 12.88
          },
          {
            "category": "21-03-2013",
            "value": 16.0345,
            "value2": 12.78
          },
          {
            "category": "22-03-2013",
            "value": 16.0321,
            "value2": 12.75
          },
          {
            "category": "23-03-2013",
            "value": 16.0321,
            "value2": 12.75
          },
          {
            "category": "24-03-2013",
            "value": 16.0321,
            "value2": 12.75
          },
          {
            "category": "25-03-2013",
            "value": 15.9162,
            "value2": 12.71
          },
          {
            "category": "26-03-2013",
            "value": 15.9242,
            "value2": 12.72
          },
          {
            "category": "27-03-2013",
            "value": 15.9242,
            "value2": 12.72
          },
          {
            "category": "28-03-2013",
            "value": 16.08,
            "value2": 12.85
          },
          {
            "category": "29-03-2013",
            "value": 16.08,
            "value2": 12.85
          },
          {
            "category": "30-03-2013",
            "value": 16.08,
            "value2": 12.85
          },
          {
            "category": "31-03-2013",
            "value": 16.0026,
            "value2": 12.85
          },
          {
            "category": "01-04-2013",
            "value": 16.093,
            "value2": 12.92
          },
          {
            "category": "02-04-2013",
            "value": 16.2808,
            "value2": 13.05
          },
          {
            "category": "03-04-2013",
            "value": 16.3035,
            "value2": 12.91
          },
          {
            "category": "04-04-2013",
            "value": 16.1161,
            "value2": 12.69
          },
          {
            "category": "05-04-2013",
            "value": 16.1829,
            "value2": 12.65
          },
          {
            "category": "06-04-2013",
            "value": 16.1829,
            "value2": 12.65
          },
          {
            "category": "07-04-2013",
            "value": 16.1829,
            "value2": 12.65
          },
          {
            "category": "08-04-2013",
            "value": 16.1163,
            "value2": 12.64
          },
          {
            "category": "09-04-2013",
            "value": 16.0333,
            "value2": 12.51
          },
          {
            "category": "10-04-2013",
            "value": 16.0292,
            "value2": 12.63
          },
          {
            "category": "11-04-2013",
            "value": 16.0688,
            "value2": 12.69
          },
          {
            "category": "12-04-2013",
            "value": 16.1193,
            "value2": 12.6
          },
          {
            "category": "13-04-2013",
            "value": 16.1193,
            "value2": 12.6
          },
          {
            "category": "14-04-2013",
            "value": 16.1193,
            "value2": 12.6
          },
          {
            "category": "15-04-2013",
            "value": 16.087,
            "value2": 12.66
          },
          {
            "category": "16-04-2013",
            "value": 16.1359,
            "value2": 12.88
          },
          {
            "category": "17-04-2013",
            "value": 16.0707,
            "value2": 12.9
          },
          {
            "category": "18-04-2013",
            "value": 16.2077,
            "value2": 13.07
          },
          {
            "category": "19-04-2013",
            "value": 16.2077,
            "value2": 13.07
          },
          {
            "category": "20-04-2013",
            "value": 16.2077,
            "value2": 13.07
          },
          {
            "category": "21-04-2013",
            "value": 16.2077,
            "value2": 13.07
          },
          {
            "category": "22-04-2013",
            "value": 16.3774,
            "value2": 13.2
          },
          {
            "category": "23-04-2013",
            "value": 16.3823,
            "value2": 13.19
          },
          {
            "category": "24-04-2013",
            "value": 16.3823,
            "value2": 13.19
          },
          {
            "category": "25-04-2013",
            "value": 16.5576,
            "value2": 13.33
          },
          {
            "category": "26-04-2013",
            "value": 16.5401,
            "value2": 13.24
          },
          {
            "category": "27-04-2013",
            "value": 16.5401,
            "value2": 13.24
          },
          {
            "category": "28-04-2013",
            "value": 16.5401,
            "value2": 13.24
          },
          {
            "category": "29-04-2013",
            "value": 16.5918,
            "value2": 13.33
          },
          {
            "category": "30-04-2013",
            "value": 16.7009,
            "value2": 13.4
          },
          {
            "category": "01-05-2013",
            "value": 16.6687,
            "value2": 13.4
          },
          {
            "category": "02-05-2013",
            "value": 16.9688,
            "value2": 13.54
          },
          {
            "category": "03-05-2013",
            "value": 16.921,
            "value2": 13.45
          },
          {
            "category": "04-05-2013",
            "value": 16.921,
            "value2": 13.45
          },
          {
            "category": "05-05-2013",
            "value": 16.921,
            "value2": 13.45
          },
          {
            "category": "06-05-2013",
            "value": 17.0266,
            "value2": 13.52
          },
          {
            "category": "07-05-2013",
            "value": 17.1269,
            "value2": 13.66
          },
          {
            "category": "08-05-2013",
            "value": 17.1718,
            "value2": 13.71
          },
          {
            "category": "09-05-2013",
            "value": 17.137,
            "value2": 13.67
          },
          {
            "category": "10-05-2013",
            "value": 17.3447,
            "value2": 13.76
          },
          {
            "category": "11-05-2013",
            "value": 17.3743,
            "value2": 13.8
          },
          {
            "category": "12-05-2013",
            "value": 17.3743,
            "value2": 13.8
          },
          {
            "category": "13-05-2013",
            "value": 17.3146,
            "value2": 13.54
          },
          {
            "category": "14-05-2013",
            "value": 17.4896,
            "value2": 13.57
          },
          {
            "category": "15-05-2013",
            "value": 17.6734,
            "value2": 13.87
          },
          {
            "category": "16-05-2013",
            "value": 17.8088,
            "value2": 13.93
          },
          {
            "category": "17-05-2013",
            "value": 17.9661,
            "value2": 13.97
          },
          {
            "category": "18-05-2013",
            "value": 17.9661,
            "value2": 13.97
          },
          {
            "category": "19-05-2013",
            "value": 17.9661,
            "value2": 13.97
          },
          {
            "category": "20-05-2013",
            "value": 17.9324,
            "value2": 13.91
          },
          {
            "category": "21-05-2013",
            "value": 17.7677,
            "value2": 13.81
          },
          {
            "category": "22-05-2013",
            "value": 17.6939,
            "value2": 13.75
          },
          {
            "category": "23-05-2013",
            "value": 17.4424,
            "value2": 13.47
          },
          {
            "category": "24-05-2013",
            "value": 17.6097,
            "value2": 13.52
          },
          {
            "category": "25-05-2013",
            "value": 17.6097,
            "value2": 13.52
          },
          {
            "category": "26-05-2013",
            "value": 17.6097,
            "value2": 13.52
          },
          {
            "category": "27-05-2013",
            "value": 17.8127,
            "value2": 13.72
          },
          {
            "category": "28-05-2013",
            "value": 17.9144,
            "value2": 13.79
          },
          {
            "category": "29-05-2013",
            "value": 18.084,
            "value2": 13.75
          },
          {
            "category": "30-05-2013",
            "value": 18.2594,
            "value2": 13.79
          },
          {
            "category": "31-05-2013",
            "value": 18.2872,
            "value2": 13.55
          },
          {
            "category": "01-06-2013",
            "value": 18.2872,
            "value2": 13.55
          },
          {
            "category": "02-06-2013",
            "value": 18.2872,
            "value2": 13.55
          },
          {
            "category": "03-06-2013",
            "value": 18.182,
            "value2": 13.48
          },
          {
            "category": "04-06-2013",
            "value": 18.2204,
            "value2": 13.47
          },
          {
            "category": "05-06-2013",
            "value": 18.1881,
            "value2": 13.47
          },
          {
            "category": "06-06-2013",
            "value": 18.0708,
            "value2": 13.47
          },
          {
            "category": "07-06-2013",
            "value": 18.1338,
            "value2": 13.39
          },
          {
            "category": "08-06-2013",
            "value": 18.1338,
            "value2": 13.39
          },
          {
            "category": "09-06-2013",
            "value": 18.1338,
            "value2": 13.39
          },
          {
            "category": "10-06-2013",
            "value": 18.1111,
            "value2": 13.34
          },
          {
            "category": "11-06-2013",
            "value": 18.0222,
            "value2": 13.12
          },
          {
            "category": "12-06-2013",
            "value": 17.9712,
            "value2": 13.05
          },
          {
            "category": "13-06-2013",
            "value": 17.8552,
            "value2": 12.89
          },
          {
            "category": "14-06-2013",
            "value": 18.0059,
            "value2": 13.11
          },
          {
            "category": "15-06-2013",
            "value": 18.0059,
            "value2": 13.11
          },
          {
            "category": "16-06-2013",
            "value": 18.0059,
            "value2": 13.11
          },
          {
            "category": "17-06-2013",
            "value": 18.1356,
            "value2": 13.2
          },
          {
            "category": "18-06-2013",
            "value": 18.2099,
            "value2": 13.16
          },
          {
            "category": "19-06-2013",
            "value": 18.1829,
            "value2": 13.19
          },
          {
            "category": "20-06-2013",
            "value": 17.9217,
            "value2": 12.85
          },
          {
            "category": "21-06-2013",
            "value": 17.6694,
            "value2": 12.83
          },
          {
            "category": "22-06-2013",
            "value": 17.6694,
            "value2": 12.83
          },
          {
            "category": "23-06-2013",
            "value": 17.6694,
            "value2": 12.83
          },
          {
            "category": "24-06-2013",
            "value": 17.2987,
            "value2": 12.61
          },
          {
            "category": "25-06-2013",
            "value": 17.0098,
            "value2": 12.63
          },
          {
            "category": "26-06-2013",
            "value": 17.0359,
            "value2": 12.58
          },
          {
            "category": "27-06-2013",
            "value": 17.1476,
            "value2": 12.76
          },
          {
            "category": "28-06-2013",
            "value": 17.5565,
            "value2": 13.07
          },
          {
            "category": "29-06-2013",
            "value": 17.5565,
            "value2": 13.07
          },
          {
            "category": "30-06-2013",
            "value": 17.5565,
            "value2": 13.07
          },
          {
            "category": "01-07-2013",
            "value": 17.7404,
            "value2": 13.26
          },
          {
            "category": "02-07-2013",
            "value": 17.9276,
            "value2": 13.2
          },
          {
            "category": "03-07-2013",
            "value": 17.8687,
            "value2": 12.98
          },
          {
            "category": "04-07-2013",
            "value": 17.9502,
            "value2": 13.11
          },
          {
            "category": "05-07-2013",
            "value": 17.9779,
            "value2": 13.17
          },
          {
            "category": "06-07-2013",
            "value": 17.9779,
            "value2": 13.17
          },
          {
            "category": "07-07-2013",
            "value": 17.9779,
            "value2": 13.17
          },
          {
            "category": "08-07-2013",
            "value": 17.7558,
            "value2": 13.09
          },
          {
            "category": "09-07-2013",
            "value": 17.8199,
            "value2": 13.19
          },
          {
            "category": "10-07-2013",
            "value": 17.7256,
            "value2": 13.12
          },
          {
            "category": "11-07-2013",
            "value": 17.9355,
            "value2": 13.33
          },
          {
            "category": "12-07-2013",
            "value": 17.9221,
            "value2": 13.46
          },
          {
            "category": "13-07-2013",
            "value": 17.9221,
            "value2": 13.46
          },
          {
            "category": "14-07-2013",
            "value": 17.9221,
            "value2": 13.46
          },
          {
            "category": "15-07-2013",
            "value": 18.1856,
            "value2": 13.55
          },
          {
            "category": "16-07-2013",
            "value": 18.073,
            "value2": 13.39
          },
          {
            "category": "17-07-2013",
            "value": 18.0489,
            "value2": 13.4
          },
          {
            "category": "18-07-2013",
            "value": 18.1179,
            "value2": 13.54
          },
          {
            "category": "19-07-2013",
            "value": 18,
            "value2": 13.51
          },
          {
            "category": "20-07-2013",
            "value": 18,
            "value2": 13.51
          },
          {
            "category": "21-07-2013",
            "value": 18,
            "value2": 13.51
          },
          {
            "category": "22-07-2013",
            "value": 17.899,
            "value2": 13.5
          },
          {
            "category": "23-07-2013",
            "value": 17.7585,
            "value2": 13.58
          },
          {
            "category": "24-07-2013",
            "value": 17.6823,
            "value2": 13.38
          },
          {
            "category": "25-07-2013",
            "value": 17.4909,
            "value2": 13.22
          },
          {
            "category": "26-07-2013",
            "value": 17.3246,
            "value2": 13.14
          },
          {
            "category": "27-07-2013",
            "value": 17.3246,
            "value2": 13.14
          },
          {
            "category": "28-07-2013",
            "value": 17.3246,
            "value2": 13.14
          },
          {
            "category": "29-07-2013",
            "value": 17.1418,
            "value2": 13.01
          },
          {
            "category": "30-07-2013",
            "value": 16.9518,
            "value2": 12.82
          },
          {
            "category": "31-07-2013",
            "value": 16.8633,
            "value2": 12.79
          },
          {
            "category": "01-08-2013",
            "value": 16.6962,
            "value2": 12.71
          },
          {
            "category": "02-08-2013",
            "value": 16.6004,
            "value2": 12.6
          },
          {
            "category": "03-08-2013",
            "value": 16.6004,
            "value2": 12.6
          },
          {
            "category": "04-08-2013",
            "value": 16.6004,
            "value2": 12.6
          },
          {
            "category": "05-08-2013",
            "value": 16.4288,
            "value2": 12.63
          },
          {
            "category": "06-08-2013",
            "value": 15.9137,
            "value2": 12.32
          },
          {
            "category": "07-08-2013",
            "value": 15.8323,
            "value2": 12.32
          },
          {
            "category": "08-08-2013",
            "value": 15.9118,
            "value2": 12.43
          },
          {
            "category": "09-08-2013",
            "value": 15.9118,
            "value2": 12.43
          },
          {
            "category": "10-08-2013",
            "value": 15.9118,
            "value2": 12.43
          },
          {
            "category": "11-08-2013",
            "value": 15.9118,
            "value2": 12.43
          },
          {
            "category": "12-08-2013",
            "value": 16.0505,
            "value2": 12.55
          },
          {
            "category": "13-08-2013",
            "value": 16.1893,
            "value2": 12.75
          },
          {
            "category": "14-08-2013",
            "value": 16.3349,
            "value2": 12.84
          },
          {
            "category": "15-08-2013",
            "value": 16.3349,
            "value2": 12.84
          },
          {
            "category": "16-08-2013",
            "value": 15.9794,
            "value2": 12.36
          },
          {
            "category": "17-08-2013",
            "value": 15.9794,
            "value2": 12.36
          },
          {
            "category": "18-08-2013",
            "value": 15.9786,
            "value2": 12.36
          },
          {
            "category": "19-08-2013",
            "value": 15.578,
            "value2": 12.15
          },
          {
            "category": "20-08-2013",
            "value": 15.5037,
            "value2": 12.12
          },
          {
            "category": "21-08-2013",
            "value": 15.3985,
            "value2": 11.91
          },
          {
            "category": "22-08-2013",
            "value": 15.4247,
            "value2": 12.13
          },
          {
            "category": "23-08-2013",
            "value": 15.5205,
            "value2": 12.25
          },
          {
            "category": "24-08-2013",
            "value": 15.5205,
            "value2": 12.25
          },
          {
            "category": "25-08-2013",
            "value": 15.5205,
            "value2": 12.25
          },
          {
            "category": "26-08-2013",
            "value": 15.559,
            "value2": 12.29
          },
          {
            "category": "27-08-2013",
            "value": 15.2779,
            "value2": 11.91
          },
          {
            "category": "28-08-2013",
            "value": 14.9982,
            "value2": 11.87
          },
          {
            "category": "29-08-2013",
            "value": 15.1399,
            "value2": 12.12
          },
          {
            "category": "30-08-2013",
            "value": 15.2472,
            "value2": 12.24
          },
          {
            "category": "31-08-2013",
            "value": 15.2472,
            "value2": 12.24
          },
          {
            "category": "01-09-2013",
            "value": 15.2472,
            "value2": 12.24
          },
          {
            "category": "02-09-2013",
            "value": 15.3857,
            "value2": 12.41
          },
          {
            "category": "03-09-2013",
            "value": 15.1095,
            "value2": 12.02
          },
          {
            "category": "04-09-2013",
            "value": 15.27,
            "value2": 12.23
          },
          {
            "category": "05-09-2013",
            "value": 15.6316,
            "value2": 12.51
          },
          {
            "category": "06-09-2013",
            "value": 15.7842,
            "value2": 12.68
          },
          {
            "category": "07-09-2013",
            "value": 15.7842,
            "value2": 12.68
          },
          {
            "category": "08-09-2013",
            "value": 15.7842,
            "value2": 12.68
          },
          {
            "category": "09-09-2013",
            "value": 15.7842,
            "value2": 12.68
          },
          {
            "category": "10-09-2013",
            "value": 16.1915,
            "value2": 13.08
          },
          {
            "category": "11-09-2013",
            "value": 16.3829,
            "value2": 13.16
          },
          {
            "category": "12-09-2013",
            "value": 16.4874,
            "value2": 13.03
          },
          {
            "category": "13-09-2013",
            "value": 16.5044,
            "value2": 13.04
          },
          {
            "category": "14-09-2013",
            "value": 16.5044,
            "value2": 13.04
          },
          {
            "category": "15-09-2013",
            "value": 16.5044,
            "value2": 13.04
          },
          {
            "category": "16-09-2013",
            "value": 16.5213,
            "value2": 13.02
          },
          {
            "category": "17-09-2013",
            "value": 16.456,
            "value2": 13.03
          },
          {
            "category": "18-09-2013",
            "value": 16.6935,
            "value2": 13.12
          },
          {
            "category": "19-09-2013",
            "value": 16.8425,
            "value2": 13.56
          },
          {
            "category": "20-09-2013",
            "value": 16.7204,
            "value2": 13.37
          },
          {
            "category": "21-09-2013",
            "value": 16.7204,
            "value2": 13.37
          },
          {
            "category": "22-09-2013",
            "value": 16.7204,
            "value2": 13.37
          },
          {
            "category": "23-09-2013",
            "value": 16.4905,
            "value2": 13.15
          },
          {
            "category": "24-09-2013",
            "value": 16.6153,
            "value2": 13.15
          },
          {
            "category": "25-09-2013",
            "value": 16.604,
            "value2": 13.12
          },
          {
            "category": "26-09-2013",
            "value": 16.5884,
            "value2": 13.14
          },
          {
            "category": "27-09-2013",
            "value": 16.6455,
            "value2": 13.07
          },
          {
            "category": "28-09-2013",
            "value": 16.6455,
            "value2": 13.07
          },
          {
            "category": "29-09-2013",
            "value": 16.6455,
            "value2": 13.07
          },
          {
            "category": "30-09-2013",
            "value": 16.6913,
            "value2": 12.89
          },
          {
            "category": "01-10-2013",
            "value": 16.7,
            "value2": 12.97
          },
          {
            "category": "02-10-2013",
            "value": 16.7,
            "value2": 12.97
          },
          {
            "category": "03-10-2013",
            "value": 16.8058,
            "value2": 13.23
          },
          {
            "category": "04-10-2013",
            "value": 16.9218,
            "value2": 13.24
          },
          {
            "category": "05-10-2013",
            "value": 16.9218,
            "value2": 13.24
          },
          {
            "category": "06-10-2013",
            "value": 16.9218,
            "value2": 13.24
          },
          {
            "category": "07-10-2013",
            "value": 16.9597,
            "value2": 13.25
          },
          {
            "category": "08-10-2013",
            "value": 16.983,
            "value2": 13.29
          },
          {
            "category": "09-10-2013",
            "value": 17.1132,
            "value2": 13.46
          },
          {
            "category": "10-10-2013",
            "value": 17.2467,
            "value2": 13.5
          },
          {
            "category": "11-10-2013",
            "value": 17.2365,
            "value2": 13.62
          },
          {
            "category": "12-10-2013",
            "value": 17.2365,
            "value2": 13.62
          },
          {
            "category": "13-10-2013",
            "value": 17.2365,
            "value2": 13.62
          },
          {
            "category": "14-10-2013",
            "value": 17.2434,
            "value2": 13.67
          },
          {
            "category": "15-10-2013",
            "value": 17.0675,
            "value2": 13.59
          },
          {
            "category": "16-10-2013",
            "value": 17.0675,
            "value2": 13.59
          },
          {
            "category": "17-10-2013",
            "value": 17.1822,
            "value2": 13.52
          },
          {
            "category": "18-10-2013",
            "value": 17.257,
            "value2": 13.79
          },
          {
            "category": "19-10-2013",
            "value": 17.257,
            "value2": 13.79
          },
          {
            "category": "20-10-2013",
            "value": 17.257,
            "value2": 13.79
          },
          {
            "category": "21-10-2013",
            "value": 17.3076,
            "value2": 13.86
          },
          {
            "category": "22-10-2013",
            "value": 17.4266,
            "value2": 13.88
          },
          {
            "category": "23-10-2013",
            "value": 17.4479,
            "value2": 13.84
          },
          {
            "category": "24-10-2013",
            "value": 17.3971,
            "value2": 13.81
          },
          {
            "category": "25-10-2013",
            "value": 17.4135,
            "value2": 13.75
          },
          {
            "category": "26-10-2013",
            "value": 17.4135,
            "value2": 13.75
          },
          {
            "category": "27-10-2013",
            "value": 17.4135,
            "value2": 13.75
          },
          {
            "category": "28-10-2013",
            "value": 17.3381,
            "value2": 13.65
          },
          {
            "category": "29-10-2013",
            "value": 17.4715,
            "value2": 13.89
          },
          {
            "category": "30-10-2013",
            "value": 17.5058,
            "value2": 13.95
          },
          {
            "category": "31-10-2013",
            "value": 17.7505,
            "value2": 14.07
          },
          {
            "category": "01-11-2013",
            "value": 17.778,
            "value2": 14.14
          },
          {
            "category": "02-11-2013",
            "value": 17.778,
            "value2": 14.14
          },
          {
            "category": "03-11-2013",
            "value": 17.8747,
            "value2": 14.19
          },
          {
            "category": "04-11-2013",
            "value": 17.8747,
            "value2": 14.19
          },
          {
            "category": "05-11-2013",
            "value": 18.0071,
            "value2": 14.1
          },
          {
            "category": "06-11-2013",
            "value": 18.0705,
            "value2": 14.04
          },
          {
            "category": "07-11-2013",
            "value": 17.9425,
            "value2": 13.93
          },
          {
            "category": "08-11-2013",
            "value": 17.8918,
            "value2": 13.86
          },
          {
            "category": "09-11-2013",
            "value": 17.8918,
            "value2": 13.86
          },
          {
            "category": "10-11-2013",
            "value": 17.8918,
            "value2": 13.86
          },
          {
            "category": "11-11-2013",
            "value": 17.9454,
            "value2": 13.73
          },
          {
            "category": "12-11-2013",
            "value": 17.9464,
            "value2": 13.6
          },
          {
            "category": "13-11-2013",
            "value": 17.7828,
            "value2": 13.53
          },
          {
            "category": "14-11-2013",
            "value": 17.8664,
            "value2": 13.69
          },
          {
            "category": "15-11-2013",
            "value": 17.8664,
            "value2": 13.69
          },
          {
            "category": "16-11-2013",
            "value": 17.8664,
            "value2": 13.69
          },
          {
            "category": "17-11-2013",
            "value": 17.8664,
            "value2": 13.69
          },
          {
            "category": "18-11-2013",
            "value": 18.0003,
            "value2": 13.95
          },
          {
            "category": "19-11-2013",
            "value": 18.0126,
            "value2": 13.98
          },
          {
            "category": "20-11-2013",
            "value": 17.9896,
            "value2": 13.84
          },
          {
            "category": "21-11-2013",
            "value": 17.8076,
            "value2": 13.58
          },
          {
            "category": "22-11-2013",
            "value": 17.7537,
            "value2": 13.58
          },
          {
            "category": "23-11-2013",
            "value": 17.7537,
            "value2": 13.58
          },
          {
            "category": "24-11-2013",
            "value": 17.7537,
            "value2": 13.58
          },
          {
            "category": "25-11-2013",
            "value": 17.8713,
            "value2": 13.82
          },
          {
            "category": "26-11-2013",
            "value": 17.8902,
            "value2": 13.71
          },
          {
            "category": "27-11-2013",
            "value": 18.1358,
            "value2": 13.72
          },
          {
            "category": "28-11-2013",
            "value": 18.401,
            "value2": 13.8
          },
          {
            "category": "29-11-2013",
            "value": 18.5657,
            "value2": 13.97
          },
          {
            "category": "30-11-2013",
            "value": 18.5657,
            "value2": 13.97
          },
          {
            "category": "01-12-2013",
            "value": 18.5657,
            "value2": 13.97
          },
          {
            "category": "02-12-2013",
            "value": 18.7998,
            "value2": 14.07
          },
          {
            "category": "03-12-2013",
            "value": 18.9429,
            "value2": 14.05
          },
          {
            "category": "04-12-2013",
            "value": 18.7322,
            "value2": 13.96
          },
          {
            "category": "05-12-2013",
            "value": 18.71,
            "value2": 14.1
          },
          {
            "category": "06-12-2013",
            "value": 18.7973,
            "value2": 14.15
          },
          {
            "category": "07-12-2013",
            "value": 18.7973,
            "value2": 14.15
          },
          {
            "category": "08-12-2013",
            "value": 18.7973,
            "value2": 14.15
          },
          {
            "category": "09-12-2013",
            "value": 18.6956,
            "value2": 14.33
          },
          {
            "category": "10-12-2013",
            "value": 18.6236,
            "value2": 14.27
          },
          {
            "category": "11-12-2013",
            "value": 18.7116,
            "value2": 14.22
          },
          {
            "category": "12-12-2013",
            "value": 18.7858,
            "value2": 14.09
          },
          {
            "category": "13-12-2013",
            "value": 18.6517,
            "value2": 13.93
          },
          {
            "category": "14-12-2013",
            "value": 18.6517,
            "value2": 13.93
          },
          {
            "category": "15-12-2013",
            "value": 18.6517,
            "value2": 13.93
          },
          {
            "category": "16-12-2013",
            "value": 18.5657,
            "value2": 13.93
          },
          {
            "category": "17-12-2013",
            "value": 18.6496,
            "value2": 13.91
          },
          {
            "category": "18-12-2013",
            "value": 18.8644,
            "value2": 14.09
          },
          {
            "category": "19-12-2013",
            "value": 18.7917,
            "value2": 14.01
          },
          {
            "category": "20-12-2013",
            "value": 19.1355,
            "value2": 14.24
          },
          {
            "category": "21-12-2013",
            "value": 19.1355,
            "value2": 14.24
          },
          {
            "category": "22-12-2013",
            "value": 19.1355,
            "value2": 14.24
          },
          {
            "category": "23-12-2013",
            "value": 19.2419,
            "value2": 14.3
          },
          {
            "category": "24-12-2013",
            "value": 19.2306,
            "value2": 14.29
          },
          {
            "category": "25-12-2013",
            "value": 19.2306,
            "value2": 14.29
          },
          {
            "category": "26-12-2013",
            "value": 19.4277,
            "value2": 14.32
          },
          {
            "category": "27-12-2013",
            "value": 19.4142,
            "value2": 14.39
          },
          {
            "category": "28-12-2013",
            "value": 19.4142,
            "value2": 14.39
          },
          {
            "category": "29-12-2013",
            "value": 19.4142,
            "value2": 14.39
          },
          {
            "category": "30-12-2013",
            "value": 19.4571,
            "value2": 14.36
          },
          {
            "category": "31-12-2013",
            "value": 19.4947,
            "value2": 14.4
          },
          {
            "category": "01-01-2014",
            "value": 19.4466,
            "value2": 14.42
          },
          {
            "category": "02-01-2014",
            "value": 19.334,
            "value2": 14.21
          },
          {
            "category": "03-01-2014",
            "value": 19.2795,
            "value2": 14.22
          },
          {
            "category": "04-01-2014",
            "value": 19.2795,
            "value2": 14.22
          },
          {
            "category": "05-01-2014",
            "value": 19.2795,
            "value2": 14.22
          },
          {
            "category": "06-01-2014",
            "value": 19.335,
            "value2": 14.21
          },
          {
            "category": "07-01-2014",
            "value": 19.2973,
            "value2": 14.13
          },
          {
            "category": "08-01-2014",
            "value": 19.4626,
            "value2": 14.17
          },
          {
            "category": "09-01-2014",
            "value": 19.4236,
            "value2": 14.13
          },
          {
            "category": "10-01-2014",
            "value": 19.3242,
            "value2": 14.12
          },
          {
            "category": "11-01-2014",
            "value": 19.3242,
            "value2": 14.12
          },
          {
            "category": "12-01-2014",
            "value": 19.3242,
            "value2": 14.12
          },
          {
            "category": "13-01-2014",
            "value": 19.2172,
            "value2": 14.28
          },
          {
            "category": "14-01-2014",
            "value": 19.1403,
            "value2": 14.22
          },
          {
            "category": "15-01-2014",
            "value": 19.1527,
            "value2": 14.35
          },
          {
            "category": "16-01-2014",
            "value": 19.1057,
            "value2": 14.33
          },
          {
            "category": "17-01-2014",
            "value": 19.0485,
            "value2": 14.18
          },
          {
            "category": "18-01-2014",
            "value": 19.0485,
            "value2": 14.18
          },
          {
            "category": "19-01-2014",
            "value": 19.0485,
            "value2": 14.18
          },
          {
            "category": "20-01-2014",
            "value": 19.2099,
            "value2": 14.29
          },
          {
            "category": "21-01-2014",
            "value": 19.296,
            "value2": 14.32
          },
          {
            "category": "22-01-2014",
            "value": 19.2556,
            "value2": 14.37
          },
          {
            "category": "23-01-2014",
            "value": 19.2923,
            "value2": 14.37
          },
          {
            "category": "24-01-2014",
            "value": 19.1259,
            "value2": 14.17
          },
          {
            "category": "25-01-2014",
            "value": 19.1259,
            "value2": 14.17
          },
          {
            "category": "26-01-2014",
            "value": 19.1259,
            "value2": 14.17
          },
          {
            "category": "27-01-2014",
            "value": 18.6653,
            "value2": 13.85
          },
          {
            "category": "28-01-2014",
            "value": 18.6805,
            "value2": 13.84
          },
          {
            "category": "29-01-2014",
            "value": 18.6401,
            "value2": 13.84
          },
          {
            "category": "30-01-2014",
            "value": 18.5698,
            "value2": 13.7
          },
          {
            "category": "31-01-2014",
            "value": 18.7949,
            "value2": 13.81
          },
          {
            "category": "01-02-2014",
            "value": 18.7949,
            "value2": 13.81
          },
          {
            "category": "02-02-2014",
            "value": 18.7949,
            "value2": 13.81
          },
          {
            "category": "03-02-2014",
            "value": 18.551,
            "value2": 13.63
          },
          {
            "category": "04-02-2014",
            "value": 18.6051,
            "value2": 13.65
          },
          {
            "category": "05-02-2014",
            "value": 18.7218,
            "value2": 13.7
          },
          {
            "category": "06-02-2014",
            "value": 18.7186,
            "value2": 13.73
          },
          {
            "category": "07-02-2014",
            "value": 18.7383,
            "value2": 13.79
          },
          {
            "category": "08-02-2014",
            "value": 18.7383,
            "value2": 13.79
          },
          {
            "category": "09-02-2014",
            "value": 18.7383,
            "value2": 13.79
          },
          {
            "category": "10-02-2014",
            "value": 18.6273,
            "value2": 13.76
          },
          {
            "category": "11-02-2014",
            "value": 18.6415,
            "value2": 13.79
          },
          {
            "category": "12-02-2014",
            "value": 18.745,
            "value2": 13.82
          },
          {
            "category": "13-02-2014",
            "value": 18.8066,
            "value2": 13.64
          },
          {
            "category": "14-02-2014",
            "value": 18.8247,
            "value2": 13.72
          },
          {
            "category": "15-02-2014",
            "value": 18.8247,
            "value2": 13.72
          },
          {
            "category": "16-02-2014",
            "value": 18.8247,
            "value2": 13.72
          },
          {
            "category": "17-02-2014",
            "value": 18.8014,
            "value2": 13.76
          },
          {
            "category": "18-02-2014",
            "value": 18.9541,
            "value2": 13.88
          },
          {
            "category": "19-02-2014",
            "value": 19.0855,
            "value2": 13.94
          },
          {
            "category": "20-02-2014",
            "value": 18.9666,
            "value2": 13.84
          },
          {
            "category": "21-02-2014",
            "value": 19.1082,
            "value2": 13.96
          },
          {
            "category": "22-02-2014",
            "value": 19.1082,
            "value2": 13.96
          },
          {
            "category": "23-02-2014",
            "value": 19.1082,
            "value2": 13.96
          },
          {
            "category": "24-02-2014",
            "value": 19.2079,
            "value2": 14.02
          },
          {
            "category": "25-02-2014",
            "value": 19.3045,
            "value2": 14.06
          },
          {
            "category": "26-02-2014",
            "value": 19.5282,
            "value2": 14.12
          },
          {
            "category": "27-02-2014",
            "value": 19.5282,
            "value2": 14.12
          },
          {
            "category": "28-02-2014",
            "value": 19.7184,
            "value2": 14.21
          },
          {
            "category": "01-03-2014",
            "value": 19.7184,
            "value2": 14.21
          },
          {
            "category": "02-03-2014",
            "value": 19.7184,
            "value2": 14.21
          },
          {
            "category": "03-03-2014",
            "value": 19.8085,
            "value2": 14.11
          },
          {
            "category": "04-03-2014",
            "value": 20.0711,
            "value2": 14.28
          },
          {
            "category": "05-03-2014",
            "value": 20.1497,
            "value2": 14.35
          },
          {
            "category": "06-03-2014",
            "value": 20.3438,
            "value2": 14.53
          },
          {
            "category": "07-03-2014",
            "value": 20.3168,
            "value2": 14.73
          },
          {
            "category": "08-03-2014",
            "value": 20.3168,
            "value2": 14.73
          },
          {
            "category": "09-03-2014",
            "value": 20.3168,
            "value2": 14.73
          },
          {
            "category": "10-03-2014",
            "value": 20.4465,
            "value2": 14.77
          },
          {
            "category": "11-03-2014",
            "value": 20.4443,
            "value2": 14.72
          },
          {
            "category": "12-03-2014",
            "value": 20.5074,
            "value2": 14.72
          },
          {
            "category": "13-03-2014",
            "value": 20.4713,
            "value2": 14.67
          },
          {
            "category": "14-03-2014",
            "value": 20.5825,
            "value2": 14.67
          },
          {
            "category": "15-03-2014",
            "value": 20.5825,
            "value2": 14.67
          },
          {
            "category": "16-03-2014",
            "value": 20.5825,
            "value2": 14.67
          },
          {
            "category": "17-03-2014",
            "value": 20.5825,
            "value2": 14.67
          },
          {
            "category": "18-03-2014",
            "value": 20.7156,
            "value2": 14.74
          },
          {
            "category": "19-03-2014",
            "value": 20.9619,
            "value2": 14.77
          },
          {
            "category": "20-03-2014",
            "value": 20.8659,
            "value2": 14.69
          },
          {
            "category": "21-03-2014",
            "value": 21.0797,
            "value2": 14.73
          },
          {
            "category": "22-03-2014",
            "value": 21.1663,
            "value2": 14.75
          },
          {
            "category": "23-03-2014",
            "value": 21.1663,
            "value2": 14.75
          },
          {
            "category": "24-03-2014",
            "value": 21.0772,
            "value2": 14.9
          },
          {
            "category": "25-03-2014",
            "value": 21.2689,
            "value2": 14.93
          },
          {
            "category": "26-03-2014",
            "value": 21.2873,
            "value2": 14.97
          },
          {
            "category": "27-03-2014",
            "value": 21.4762,
            "value2": 15.07
          },
          {
            "category": "28-03-2014",
            "value": 21.6569,
            "value2": 15.22
          },
          {
            "category": "29-03-2014",
            "value": 21.6568,
            "value2": 15.22
          },
          {
            "category": "30-03-2014",
            "value": 21.6568,
            "value2": 15.22
          },
          {
            "category": "31-03-2014",
            "value": 21.7467,
            "value2": 15.3
          },
          {
            "category": "01-04-2014",
            "value": 21.6315,
            "value2": 15.32
          },
          {
            "category": "02-04-2014",
            "value": 21.6685,
            "value2": 15.44
          },
          {
            "category": "03-04-2014",
            "value": 21.6465,
            "value2": 15.39
          },
          {
            "category": "04-04-2014",
            "value": 21.3808,
            "value2": 15.34
          },
          {
            "category": "05-04-2014",
            "value": 21.3808,
            "value2": 15.34
          },
          {
            "category": "06-04-2014",
            "value": 21.3808,
            "value2": 15.34
          },
          {
            "category": "07-04-2014",
            "value": 21.2066,
            "value2": 15.33
          },
          {
            "category": "08-04-2014",
            "value": 21.2066,
            "value2": 15.33
          },
          {
            "category": "09-04-2014",
            "value": 21.382,
            "value2": 15.56
          },
          {
            "category": "10-04-2014",
            "value": 21.5596,
            "value2": 15.6
          },
          {
            "category": "11-04-2014",
            "value": 21.3681,
            "value2": 15.57
          },
          {
            "category": "12-04-2014",
            "value": 21.3681,
            "value2": 15.57
          },
          {
            "category": "13-04-2014",
            "value": 21.3681,
            "value2": 15.57
          },
          {
            "category": "14-04-2014",
            "value": 21.3681,
            "value2": 15.57
          },
          {
            "category": "15-04-2014",
            "value": 21.1748,
            "value2": 15.46
          },
          {
            "category": "16-04-2014",
            "value": 21.0871,
            "value2": 15.32
          },
          {
            "category": "17-04-2014",
            "value": 21.2371,
            "value2": 15.55
          },
          {
            "category": "18-04-2014",
            "value": 21.2371,
            "value2": 15.55
          },
          {
            "category": "19-04-2014",
            "value": 21.2371,
            "value2": 15.55
          },
          {
            "category": "20-04-2014",
            "value": 21.2371,
            "value2": 15.55
          },
          {
            "category": "21-04-2014",
            "value": 21.3707,
            "value2": 15.65
          },
          {
            "category": "22-04-2014",
            "value": 21.5472,
            "value2": 15.65
          },
          {
            "category": "23-04-2014",
            "value": 21.7271,
            "value2": 15.7
          },
          {
            "category": "24-04-2014",
            "value": 21.7271,
            "value2": 15.7
          },
          {
            "category": "25-04-2014",
            "value": 21.5656,
            "value2": 15.59
          },
          {
            "category": "26-04-2014",
            "value": 21.5656,
            "value2": 15.59
          },
          {
            "category": "27-04-2014",
            "value": 21.5656,
            "value2": 15.59
          },
          {
            "category": "28-04-2014",
            "value": 21.4891,
            "value2": 15.58
          },
          {
            "category": "29-04-2014",
            "value": 21.4195,
            "value2": 15.48
          },
          {
            "category": "30-04-2014",
            "value": 21.3713,
            "value2": 15.38
          },
          {
            "category": "01-05-2014",
            "value": 21.3307,
            "value2": 15.38
          },
          {
            "category": "02-05-2014",
            "value": 21.3074,
            "value2": 15.4
          },
          {
            "category": "03-05-2014",
            "value": 21.3074,
            "value2": 15.4
          },
          {
            "category": "04-05-2014",
            "value": 21.3074,
            "value2": 15.4
          },
          {
            "category": "05-05-2014",
            "value": 21.3773,
            "value2": 15.41
          },
          {
            "category": "06-05-2014",
            "value": 21.3492,
            "value2": 15.44
          },
          {
            "category": "07-05-2014",
            "value": 21.3294,
            "value2": 15.33
          },
          {
            "category": "08-05-2014",
            "value": 21.3893,
            "value2": 15.34
          },
          {
            "category": "09-05-2014",
            "value": 21.7012,
            "value2": 15.73
          },
          {
            "category": "10-05-2014",
            "value": 21.7016,
            "value2": 15.73
          },
          {
            "category": "11-05-2014",
            "value": 21.7016,
            "value2": 15.73
          },
          {
            "category": "12-05-2014",
            "value": 21.8392,
            "value2": 16.03
          },
          {
            "category": "13-05-2014",
            "value": 21.9658,
            "value2": 16.28
          },
          {
            "category": "14-05-2014",
            "value": 22.0026,
            "value2": 16.34
          },
          {
            "category": "15-05-2014",
            "value": 21.7149,
            "value2": 16.32
          },
          {
            "category": "16-05-2014",
            "value": 21.9009,
            "value2": 16.55
          },
          {
            "category": "17-05-2014",
            "value": 21.9009,
            "value2": 16.55
          },
          {
            "category": "18-05-2014",
            "value": 21.9009,
            "value2": 16.55
          },
          {
            "category": "19-05-2014",
            "value": 22.2562,
            "value2": 16.88
          },
          {
            "category": "20-05-2014",
            "value": 22.4183,
            "value2": 16.95
          },
          {
            "category": "21-05-2014",
            "value": 22.7091,
            "value2": 16.96
          },
          {
            "category": "22-05-2014",
            "value": 23.3062,
            "value2": 17.1
          },
          {
            "category": "23-05-2014",
            "value": 23.5719,
            "value2": 17.34
          },
          {
            "category": "24-05-2014",
            "value": 23.5719,
            "value2": 17.34
          },
          {
            "category": "25-05-2014",
            "value": 23.5719,
            "value2": 17.34
          },
          {
            "category": "26-05-2014",
            "value": 23.1546,
            "value2": 17.23
          },
          {
            "category": "27-05-2014",
            "value": 22.9392,
            "value2": 17.11
          },
          {
            "category": "28-05-2014",
            "value": 22.95,
            "value2": 17.16
          },
          {
            "category": "29-05-2014",
            "value": 22.9081,
            "value2": 16.99
          },
          {
            "category": "30-05-2014",
            "value": 22.6386,
            "value2": 17
          },
          {
            "category": "31-05-2014",
            "value": 22.6386,
            "value2": 17
          },
          {
            "category": "01-06-2014",
            "value": 22.6386,
            "value2": 17
          },
          {
            "category": "02-06-2014",
            "value": 22.8603,
            "value2": 17.32
          },
          {
            "category": "03-06-2014",
            "value": 23.1276,
            "value2": 17.47
          },
          {
            "category": "04-06-2014",
            "value": 23.0751,
            "value2": 17.52
          },
          {
            "category": "05-06-2014",
            "value": 23.2559,
            "value2": 17.71
          },
          {
            "category": "06-06-2014",
            "value": 23.3883,
            "value2": 17.98
          },
          {
            "category": "07-06-2014",
            "value": 23.3883,
            "value2": 17.98
          },
          {
            "category": "08-06-2014",
            "value": 23.3883,
            "value2": 17.98
          },
          {
            "category": "09-06-2014",
            "value": 23.5171,
            "value2": 18.19
          },
          {
            "category": "10-06-2014",
            "value": 23.4371,
            "value2": 18.18
          },
          {
            "category": "11-06-2014",
            "value": 23.2152,
            "value2": 18.05
          },
          {
            "category": "12-06-2014",
            "value": 23.4033,
            "value2": 18.12
          },
          {
            "category": "13-06-2014",
            "value": 23.1997,
            "value2": 17.79
          },
          {
            "category": "14-06-2014",
            "value": 23.1997,
            "value2": 17.79
          },
          {
            "category": "15-06-2014",
            "value": 23.1997,
            "value2": 17.79
          },
          {
            "category": "16-06-2014",
            "value": 23.2998,
            "value2": 17.8
          },
          {
            "category": "17-06-2014",
            "value": 23.3973,
            "value2": 18.05
          },
          {
            "category": "18-06-2014",
            "value": 23.4535,
            "value2": 17.88
          },
          {
            "category": "19-06-2014",
            "value": 23.3018,
            "value2": 17.82
          },
          {
            "category": "20-06-2014",
            "value": 23.3287,
            "value2": 17.75
          },
          {
            "category": "21-06-2014",
            "value": 23.3287,
            "value2": 17.75
          },
          {
            "category": "22-06-2014",
            "value": 23.3287,
            "value2": 17.75
          },
          {
            "category": "23-06-2014",
            "value": 23.4071,
            "value2": 17.74
          },
          {
            "category": "24-06-2014",
            "value": 23.7054,
            "value2": 17.97
          },
          {
            "category": "25-06-2014",
            "value": 23.6853,
            "value2": 17.97
          },
          {
            "category": "26-06-2014",
            "value": 23.9815,
            "value2": 17.83
          },
          {
            "category": "27-06-2014",
            "value": 24.1558,
            "value2": 17.88
          },
          {
            "category": "28-06-2014",
            "value": 24.1556,
            "value2": 17.88
          },
          {
            "category": "29-06-2014",
            "value": 24.1556,
            "value2": 17.88
          },
          {
            "category": "30-06-2014",
            "value": 24.4419,
            "value2": 18.13
          },
          {
            "category": "01-07-2014",
            "value": 24.5163,
            "value2": 18.22
          },
          {
            "category": "02-07-2014",
            "value": 24.9466,
            "value2": 18.44
          },
          {
            "category": "03-07-2014",
            "value": 25.1319,
            "value2": 18.42
          },
          {
            "category": "04-07-2014",
            "value": 25.2492,
            "value2": 18.52
          },
          {
            "category": "05-07-2014",
            "value": 25.2492,
            "value2": 18.52
          },
          {
            "category": "06-07-2014",
            "value": 25.2492,
            "value2": 18.52
          },
          {
            "category": "07-07-2014",
            "value": 25.1544,
            "value2": 18.59
          },
          {
            "category": "08-07-2014",
            "value": 24.6284,
            "value2": 18.1
          },
          {
            "category": "09-07-2014",
            "value": 24.53,
            "value2": 17.96
          },
          {
            "category": "10-07-2014",
            "value": 24.586,
            "value2": 17.98
          },
          {
            "category": "11-07-2014",
            "value": 24.288,
            "value2": 17.64
          },
          {
            "category": "12-07-2014",
            "value": 24.288,
            "value2": 17.64
          },
          {
            "category": "13-07-2014",
            "value": 24.288,
            "value2": 17.64
          },
          {
            "category": "14-07-2014",
            "value": 24.3014,
            "value2": 17.65
          },
          {
            "category": "15-07-2014",
            "value": 24.7352,
            "value2": 17.87
          },
          {
            "category": "16-07-2014",
            "value": 24.9114,
            "value2": 18.1
          },
          {
            "category": "17-07-2014",
            "value": 25.3528,
            "value2": 18.2
          },
          {
            "category": "18-07-2014",
            "value": 25.2872,
            "value2": 18.21
          },
          {
            "category": "19-07-2014",
            "value": 25.2872,
            "value2": 18.21
          },
          {
            "category": "20-07-2014",
            "value": 25.2872,
            "value2": 18.21
          },
          {
            "category": "21-07-2014",
            "value": 25.3034,
            "value2": 18.26
          },
          {
            "category": "22-07-2014",
            "value": 25.2229,
            "value2": 18.41
          },
          {
            "category": "23-07-2014",
            "value": 25.1327,
            "value2": 18.44
          },
          {
            "category": "24-07-2014",
            "value": 24.8447,
            "value2": 18.5
          },
          {
            "category": "25-07-2014",
            "value": 24.7581,
            "value2": 18.35
          },
          {
            "category": "26-07-2014",
            "value": 24.7581,
            "value2": 18.35
          },
          {
            "category": "27-07-2014",
            "value": 24.7581,
            "value2": 18.35
          },
          {
            "category": "28-07-2014",
            "value": 24.6317,
            "value2": 18.28
          },
          {
            "category": "29-07-2014",
            "value": 24.6317,
            "value2": 18.28
          },
          {
            "category": "30-07-2014",
            "value": 24.7141,
            "value2": 18.37
          },
          {
            "category": "31-07-2014",
            "value": 24.6464,
            "value2": 18.28
          },
          {
            "category": "01-08-2014",
            "value": 24.4677,
            "value2": 18.04
          },
          {
            "category": "02-08-2014",
            "value": 24.4677,
            "value2": 18.04
          },
          {
            "category": "03-08-2014",
            "value": 24.4677,
            "value2": 18.04
          },
          {
            "category": "04-08-2014",
            "value": 24.6146,
            "value2": 18.22
          },
          {
            "category": "05-08-2014",
            "value": 24.8048,
            "value2": 18.37
          },
          {
            "category": "06-08-2014",
            "value": 24.6938,
            "value2": 18.22
          },
          {
            "category": "07-08-2014",
            "value": 24.8006,
            "value2": 18.17
          },
          {
            "category": "08-08-2014",
            "value": 24.4584,
            "value2": 17.92
          },
          {
            "category": "09-08-2014",
            "value": 24.4584,
            "value2": 17.92
          },
          {
            "category": "10-08-2014",
            "value": 24.4584,
            "value2": 17.92
          },
          {
            "category": "11-08-2014",
            "value": 24.6572,
            "value2": 18.05
          },
          {
            "category": "12-08-2014",
            "value": 25.1536,
            "value2": 18.25
          },
          {
            "category": "13-08-2014",
            "value": 25.4412,
            "value2": 18.19
          },
          {
            "category": "14-08-2014",
            "value": 25.4919,
            "value2": 18.35
          },
          {
            "category": "15-08-2014",
            "value": 25.4919,
            "value2": 18.35
          },
          {
            "category": "16-08-2014",
            "value": 25.4919,
            "value2": 18.35
          },
          {
            "category": "17-08-2014",
            "value": 25.4919,
            "value2": 18.35
          },
          {
            "category": "18-08-2014",
            "value": 25.7119,
            "value2": 18.58
          },
          {
            "category": "19-08-2014",
            "value": 25.8001,
            "value2": 18.69
          },
          {
            "category": "20-08-2014",
            "value": 25.8546,
            "value2": 18.68
          },
          {
            "category": "21-08-2014",
            "value": 26.0869,
            "value2": 18.73
          },
          {
            "category": "22-08-2014",
            "value": 26.1427,
            "value2": 18.77
          },
          {
            "category": "23-08-2014",
            "value": 26.1427,
            "value2": 18.77
          },
          {
            "category": "24-08-2014",
            "value": 26.1427,
            "value2": 18.77
          },
          {
            "category": "25-08-2014",
            "value": 26.0857,
            "value2": 18.71
          },
          {
            "category": "26-08-2014",
            "value": 26.1971,
            "value2": 18.7
          },
          {
            "category": "27-08-2014",
            "value": 26.282,
            "value2": 18.78
          },
          {
            "category": "28-08-2014",
            "value": 26.2218,
            "value2": 18.8
          },
          {
            "category": "29-08-2014",
            "value": 26.2218,
            "value2": 18.8
          },
          {
            "category": "30-08-2014",
            "value": 26.2218,
            "value2": 18.8
          },
          {
            "category": "31-08-2014",
            "value": 26.2218,
            "value2": 18.8
          },
          {
            "category": "01-09-2014",
            "value": 26.6025,
            "value2": 19.03
          },
          {
            "category": "02-09-2014",
            "value": 26.8399,
            "value2": 19.16
          },
          {
            "category": "03-09-2014",
            "value": 27.2524,
            "value2": 19.26
          },
          {
            "category": "04-09-2014",
            "value": 27.1113,
            "value2": 19.22
          },
          {
            "category": "05-09-2014",
            "value": 27.1486,
            "value2": 19.22
          },
          {
            "category": "06-09-2014",
            "value": 27.1486,
            "value2": 19.22
          },
          {
            "category": "07-09-2014",
            "value": 27.1486,
            "value2": 19.22
          },
          {
            "category": "08-09-2014",
            "value": 27.5335,
            "value2": 19.45
          },
          {
            "category": "09-09-2014",
            "value": 27.5693,
            "value2": 19.46
          },
          {
            "category": "10-09-2014",
            "value": 27.4982,
            "value2": 19.35
          },
          {
            "category": "11-09-2014",
            "value": 27.9997,
            "value2": 19.38
          },
          {
            "category": "12-09-2014",
            "value": 28.2374,
            "value2": 19.43
          },
          {
            "category": "13-09-2014",
            "value": 28.2374,
            "value2": 19.43
          },
          {
            "category": "14-09-2014",
            "value": 28.2374,
            "value2": 19.43
          },
          {
            "category": "15-09-2014",
            "value": 28.1591,
            "value2": 19.33
          },
          {
            "category": "16-09-2014",
            "value": 27.3324,
            "value2": 18.96
          },
          {
            "category": "17-09-2014",
            "value": 27.2222,
            "value2": 19.04
          },
          {
            "category": "18-09-2014",
            "value": 27.6827,
            "value2": 19.39
          },
          {
            "category": "19-09-2014",
            "value": 27.7214,
            "value2": 19.38
          },
          {
            "category": "20-09-2014",
            "value": 27.7214,
            "value2": 19.38
          },
          {
            "category": "21-09-2014",
            "value": 27.7214,
            "value2": 19.38
          },
          {
            "category": "22-09-2014",
            "value": 27.8747,
            "value2": 19.43
          },
          {
            "category": "23-09-2014",
            "value": 27.5282,
            "value2": 19.1
          },
          {
            "category": "24-09-2014",
            "value": 27.3926,
            "value2": 19.03
          },
          {
            "category": "25-09-2014",
            "value": 26.8797,
            "value2": 18.75
          },
          {
            "category": "26-09-2014",
            "value": 27.2267,
            "value2": 18.91
          },
          {
            "category": "27-09-2014",
            "value": 27.2267,
            "value2": 18.91
          },
          {
            "category": "28-09-2014",
            "value": 27.2267,
            "value2": 18.91
          },
          {
            "category": "29-09-2014",
            "value": 27.4672,
            "value2": 18.94
          },
          {
            "category": "30-09-2014",
            "value": 27.9865,
            "value2": 18.96
          },
          {
            "category": "01-10-2014",
            "value": 27.8207,
            "value2": 18.91
          },
          {
            "category": "02-10-2014",
            "value": 27.8207,
            "value2": 18.91
          },
          {
            "category": "03-10-2014",
            "value": 27.8207,
            "value2": 18.91
          },
          {
            "category": "04-10-2014",
            "value": 27.8207,
            "value2": 18.91
          },
          {
            "category": "05-10-2014",
            "value": 27.8207,
            "value2": 18.91
          },
          {
            "category": "06-10-2014",
            "value": 27.8207,
            "value2": 18.91
          },
          {
            "category": "07-10-2014",
            "value": 27.4004,
            "value2": 18.7
          },
          {
            "category": "08-10-2014",
            "value": 27.5628,
            "value2": 18.68
          },
          {
            "category": "09-10-2014",
            "value": 27.917,
            "value2": 18.96
          },
          {
            "category": "10-10-2014",
            "value": 27.6324,
            "value2": 18.71
          },
          {
            "category": "11-10-2014",
            "value": 27.6324,
            "value2": 18.71
          },
          {
            "category": "12-10-2014",
            "value": 27.6324,
            "value2": 18.71
          },
          {
            "category": "13-10-2014",
            "value": 27.8631,
            "value2": 18.78
          },
          {
            "category": "14-10-2014",
            "value": 27.6564,
            "value2": 18.74
          },
          {
            "category": "15-10-2014",
            "value": 27.6564,
            "value2": 18.74
          },
          {
            "category": "16-10-2014",
            "value": 27.1733,
            "value2": 18.41
          },
          {
            "category": "17-10-2014",
            "value": 27.3793,
            "value2": 18.49
          },
          {
            "category": "18-10-2014",
            "value": 27.3793,
            "value2": 18.49
          },
          {
            "category": "19-10-2014",
            "value": 27.3793,
            "value2": 18.49
          },
          {
            "category": "20-10-2014",
            "value": 27.6763,
            "value2": 18.73
          },
          {
            "category": "21-10-2014",
            "value": 27.8143,
            "value2": 18.86
          },
          {
            "category": "22-10-2014",
            "value": 28.1904,
            "value2": 19.03
          },
          {
            "category": "23-10-2014",
            "value": 28.4801,
            "value2": 19.12
          },
          {
            "category": "24-10-2014",
            "value": 28.4801,
            "value2": 19.12
          },
          {
            "category": "25-10-2014",
            "value": 28.4801,
            "value2": 19.12
          },
          {
            "category": "26-10-2014",
            "value": 28.4801,
            "value2": 19.12
          },
          {
            "category": "27-10-2014",
            "value": 28.5985,
            "value2": 19.04
          },
          {
            "category": "28-10-2014",
            "value": 28.7027,
            "value2": 19.11
          },
          {
            "category": "29-10-2014",
            "value": 28.7874,
            "value2": 19.25
          },
          {
            "category": "30-10-2014",
            "value": 28.8379,
            "value2": 19.42
          },
          {
            "category": "31-10-2014",
            "value": 29.2266,
            "value2": 19.76
          },
          {
            "category": "01-11-2014",
            "value": 29.2266,
            "value2": 19.76
          },
          {
            "category": "02-11-2014",
            "value": 29.2266,
            "value2": 19.76
          },
          {
            "category": "03-11-2014",
            "value": 29.3003,
            "value2": 19.81
          },
          {
            "category": "04-11-2014",
            "value": 29.3003,
            "value2": 19.81
          },
          {
            "category": "05-11-2014",
            "value": 29.2997,
            "value2": 19.84
          },
          {
            "category": "06-11-2014",
            "value": 29.2997,
            "value2": 19.84
          },
          {
            "category": "07-11-2014",
            "value": 29.6473,
            "value2": 19.86
          },
          {
            "category": "08-11-2014",
            "value": 29.6473,
            "value2": 19.86
          },
          {
            "category": "09-11-2014",
            "value": 29.6473,
            "value2": 19.86
          },
          {
            "category": "10-11-2014",
            "value": 30.0163,
            "value2": 19.87
          },
          {
            "category": "11-11-2014",
            "value": 30.2468,
            "value2": 19.95
          },
          {
            "category": "12-11-2014",
            "value": 30.5437,
            "value2": 20.02
          },
          {
            "category": "13-11-2014",
            "value": 30.5616,
            "value2": 19.96
          },
          {
            "category": "14-11-2014",
            "value": 31.2547,
            "value2": 20.05
          },
          {
            "category": "15-11-2014",
            "value": 31.2547,
            "value2": 20.05
          },
          {
            "category": "16-11-2014",
            "value": 31.2547,
            "value2": 20.05
          },
          {
            "category": "17-11-2014",
            "value": 31.4739,
            "value2": 20.17
          },
          {
            "category": "18-11-2014",
            "value": 31.5943,
            "value2": 20.2
          },
          {
            "category": "19-11-2014",
            "value": 31.8033,
            "value2": 20.09
          },
          {
            "category": "20-11-2014",
            "value": 31.9063,
            "value2": 20.11
          },
          {
            "category": "21-11-2014",
            "value": 31.8231,
            "value2": 20.24
          },
          {
            "category": "22-11-2014",
            "value": 31.8231,
            "value2": 20.24
          },
          {
            "category": "23-11-2014",
            "value": 31.8231,
            "value2": 20.24
          },
          {
            "category": "24-11-2014",
            "value": 31.7499,
            "value2": 20.32
          },
          {
            "category": "25-11-2014",
            "value": 31.1945,
            "value2": 20.12
          },
          {
            "category": "26-11-2014",
            "value": 31.2462,
            "value2": 20.17
          },
          {
            "category": "27-11-2014",
            "value": 31.3591,
            "value2": 20.22
          },
          {
            "category": "28-11-2014",
            "value": 31.9046,
            "value2": 20.44
          },
          {
            "category": "29-11-2014",
            "value": 31.9046,
            "value2": 20.44
          },
          {
            "category": "30-11-2014",
            "value": 31.9046,
            "value2": 20.44
          },
          {
            "category": "01-12-2014",
            "value": 31.9177,
            "value2": 20.38
          },
          {
            "category": "02-12-2014",
            "value": 31.9042,
            "value2": 20.38
          },
          {
            "category": "03-12-2014",
            "value": 32.8503,
            "value2": 20.49
          },
          {
            "category": "04-12-2014",
            "value": 33.258,
            "value2": 20.56
          },
          {
            "category": "05-12-2014",
            "value": 33.2212,
            "value2": 20.5
          },
          {
            "category": "06-12-2014",
            "value": 33.2212,
            "value2": 20.5
          },
          {
            "category": "07-12-2014",
            "value": 33.2212,
            "value2": 20.5
          },
          {
            "category": "08-12-2014",
            "value": 33.0762,
            "value2": 20.27
          },
          {
            "category": "09-12-2014",
            "value": 32.7216,
            "value2": 20.01
          },
          {
            "category": "10-12-2014",
            "value": 32.7375,
            "value2": 20.09
          },
          {
            "category": "11-12-2014",
            "value": 32.6857,
            "value2": 19.94
          },
          {
            "category": "12-12-2014",
            "value": 32.4875,
            "value2": 19.75
          },
          {
            "category": "13-12-2014",
            "value": 32.4875,
            "value2": 19.75
          },
          {
            "category": "14-12-2014",
            "value": 32.4875,
            "value2": 19.75
          },
          {
            "category": "15-12-2014",
            "value": 32.4683,
            "value2": 19.71
          },
          {
            "category": "16-12-2014",
            "value": 31.6807,
            "value2": 19.29
          },
          {
            "category": "17-12-2014",
            "value": 31.4639,
            "value2": 19.19
          },
          {
            "category": "18-12-2014",
            "value": 32.5923,
            "value2": 19.58
          },
          {
            "category": "19-12-2014",
            "value": 32.4925,
            "value2": 19.7
          },
          {
            "category": "20-12-2014",
            "value": 32.4925,
            "value2": 19.7
          },
          {
            "category": "21-12-2014",
            "value": 32.4925,
            "value2": 19.7
          },
          {
            "category": "22-12-2014",
            "value": 32.8343,
            "value2": 19.92
          },
          {
            "category": "23-12-2014",
            "value": 33.376,
            "value2": 19.8
          },
          {
            "category": "24-12-2014",
            "value": 33.0111,
            "value2": 19.66
          },
          {
            "category": "25-12-2014",
            "value": 33.0111,
            "value2": 19.66
          },
          {
            "category": "26-12-2014",
            "value": 33.2463,
            "value2": 19.71
          },
          {
            "category": "27-12-2014",
            "value": 33.2463,
            "value2": 19.71
          },
          {
            "category": "28-12-2014",
            "value": 33.2463,
            "value2": 19.71
          },
          {
            "category": "29-12-2014",
            "value": 33.3566,
            "value2": 19.84
          },
          {
            "category": "30-12-2014",
            "value": 33.4228,
            "value2": 19.89
          },
          {
            "category": "31-12-2014",
            "value": 33.4937,
            "value2": 20
          },
          {
            "category": "01-01-2015",
            "value": 33.7286,
            "value2": 20.05
          },
          {
            "category": "02-01-2015",
            "value": 33.8627,
            "value2": 20.27
          },
          {
            "category": "03-01-2015",
            "value": 33.8627,
            "value2": 20.27
          },
          {
            "category": "04-01-2015",
            "value": 33.8627,
            "value2": 20.27
          },
          {
            "category": "05-01-2015",
            "value": 33.9186,
            "value2": 20.25
          },
          {
            "category": "06-01-2015",
            "value": 33.1532,
            "value2": 19.66
          },
          {
            "category": "07-01-2015",
            "value": 33.1322,
            "value2": 19.63
          },
          {
            "category": "08-01-2015",
            "value": 33.4147,
            "value2": 19.94
          },
          {
            "category": "09-01-2015",
            "value": 33.2637,
            "value2": 20.02
          },
          {
            "category": "10-01-2015",
            "value": 33.2637,
            "value2": 20.02
          },
          {
            "category": "11-01-2015",
            "value": 33.2637,
            "value2": 20.02
          },
          {
            "category": "12-01-2015",
            "value": 33.5723,
            "value2": 20.13
          },
          {
            "category": "13-01-2015",
            "value": 33.7097,
            "value2": 20.09
          },
          {
            "category": "14-01-2015",
            "value": 33.9318,
            "value2": 20.05
          },
          {
            "category": "15-01-2015",
            "value": 34.3092,
            "value2": 20.48
          },
          {
            "category": "16-01-2015",
            "value": 34.687,
            "value2": 20.55
          },
          {
            "category": "17-01-2015",
            "value": 34.687,
            "value2": 20.55
          },
          {
            "category": "18-01-2015",
            "value": 34.687,
            "value2": 20.55
          },
          {
            "category": "19-01-2015",
            "value": 34.683,
            "value2": 20.65
          },
          {
            "category": "20-01-2015",
            "value": 34.6934,
            "value2": 20.9
          },
          {
            "category": "21-01-2015",
            "value": 34.7203,
            "value2": 20.94
          },
          {
            "category": "22-01-2015",
            "value": 34.6907,
            "value2": 21.03
          },
          {
            "category": "23-01-2015",
            "value": 34.7171,
            "value2": 21.17
          },
          {
            "category": "24-01-2015",
            "value": 34.7171,
            "value2": 21.17
          },
          {
            "category": "25-01-2015",
            "value": 34.7171,
            "value2": 21.17
          },
          {
            "category": "26-01-2015",
            "value": 34.7171,
            "value2": 21.17
          },
          {
            "category": "27-01-2015",
            "value": 35.8951,
            "value2": 21.35
          },
          {
            "category": "28-01-2015",
            "value": 36.24,
            "value2": 21.38
          },
          {
            "category": "29-01-2015",
            "value": 36.0237,
            "value2": 21.41
          },
          {
            "category": "30-01-2015",
            "value": 35.8789,
            "value2": 21.17
          },
          {
            "category": "31-01-2015",
            "value": 35.8789,
            "value2": 21.17
          },
          {
            "category": "01-02-2015",
            "value": 35.8789,
            "value2": 21.17
          },
          {
            "category": "02-02-2015",
            "value": 36.2409,
            "value2": 21.19
          },
          {
            "category": "03-02-2015",
            "value": 36.0556,
            "value2": 21.09
          },
          {
            "category": "04-02-2015",
            "value": 35.6205,
            "value2": 21
          },
          {
            "category": "05-02-2015",
            "value": 35.439,
            "value2": 20.91
          },
          {
            "category": "06-02-2015",
            "value": 34.835,
            "value2": 20.77
          },
          {
            "category": "07-02-2015",
            "value": 34.835,
            "value2": 20.77
          },
          {
            "category": "08-02-2015",
            "value": 34.835,
            "value2": 20.77
          },
          {
            "category": "09-02-2015",
            "value": 33.9926,
            "value2": 20.46
          },
          {
            "category": "10-02-2015",
            "value": 34.4012,
            "value2": 20.57
          },
          {
            "category": "11-02-2015",
            "value": 35.3008,
            "value2": 20.78
          },
          {
            "category": "12-02-2015",
            "value": 35.7389,
            "value2": 21
          },
          {
            "category": "13-02-2015",
            "value": 35.8523,
            "value2": 21.19
          },
          {
            "category": "14-02-2015",
            "value": 35.8523,
            "value2": 21.19
          },
          {
            "category": "15-02-2015",
            "value": 35.8523,
            "value2": 21.19
          },
          {
            "category": "16-02-2015",
            "value": 35.8667,
            "value2": 21.19
          },
          {
            "category": "17-02-2015",
            "value": 35.8667,
            "value2": 21.19
          },
          {
            "category": "18-02-2015",
            "value": 36.557,
            "value2": 21.34
          },
          {
            "category": "19-02-2015",
            "value": 36.2694,
            "value2": 21.39
          },
          {
            "category": "20-02-2015",
            "value": 36.0946,
            "value2": 21.29
          },
          {
            "category": "21-02-2015",
            "value": 36.0946,
            "value2": 21.29
          },
          {
            "category": "22-02-2015",
            "value": 36.0946,
            "value2": 21.29
          },
          {
            "category": "23-02-2015",
            "value": 35.7729,
            "value2": 21.1
          },
          {
            "category": "24-02-2015",
            "value": 35.7965,
            "value2": 21.11
          },
          {
            "category": "25-02-2015",
            "value": 35.5786,
            "value2": 21.1
          },
          {
            "category": "26-02-2015",
            "value": 35.1273,
            "value2": 20.92
          },
          {
            "category": "27-02-2015",
            "value": 35.6785,
            "value2": 21.29
          },
          {
            "category": "28-02-2015",
            "value": 36.2871,
            "value2": 21.38
          },
          {
            "category": "01-03-2015",
            "value": 36.2903,
            "value2": 21.38
          },
          {
            "category": "02-03-2015",
            "value": 36.7278,
            "value2": 21.56
          },
          {
            "category": "03-03-2015",
            "value": 36.7449,
            "value2": 21.71
          },
          {
            "category": "04-03-2015",
            "value": 36.6448,
            "value2": 21.53
          },
          {
            "category": "05-03-2015",
            "value": 37.0525,
            "value2": 21.61
          },
          {
            "category": "06-03-2015",
            "value": 37.0525,
            "value2": 21.61
          },
          {
            "category": "07-03-2015",
            "value": 37.0525,
            "value2": 21.61
          },
          {
            "category": "08-03-2015",
            "value": 37.0525,
            "value2": 21.61
          },
          {
            "category": "09-03-2015",
            "value": 36.6776,
            "value2": 21.23
          },
          {
            "category": "10-03-2015",
            "value": 36.9126,
            "value2": 21.15
          },
          {
            "category": "11-03-2015",
            "value": 36.7994,
            "value2": 21.1
          },
          {
            "category": "12-03-2015",
            "value": 37.5557,
            "value2": 21.32
          },
          {
            "category": "13-03-2015",
            "value": 37.1576,
            "value2": 21.01
          },
          {
            "category": "14-03-2015",
            "value": 37.1576,
            "value2": 21.01
          },
          {
            "category": "15-03-2015",
            "value": 37.1576,
            "value2": 21.01
          },
          {
            "category": "16-03-2015",
            "value": 36.7777,
            "value2": 20.96
          },
          {
            "category": "17-03-2015",
            "value": 37.1214,
            "value2": 21.18
          },
          {
            "category": "18-03-2015",
            "value": 37.4716,
            "value2": 21.14
          },
          {
            "category": "19-03-2015",
            "value": 37.4549,
            "value2": 21.02
          },
          {
            "category": "20-03-2015",
            "value": 37.2579,
            "value2": 20.82
          },
          {
            "category": "21-03-2015",
            "value": 37.2579,
            "value2": 20.82
          },
          {
            "category": "22-03-2015",
            "value": 37.2579,
            "value2": 20.82
          },
          {
            "category": "23-03-2015",
            "value": 36.8043,
            "value2": 20.72
          },
          {
            "category": "24-03-2015",
            "value": 36.4648,
            "value2": 20.69
          },
          {
            "category": "25-03-2015",
            "value": 36.3817,
            "value2": 20.65
          },
          {
            "category": "26-03-2015",
            "value": 36.0996,
            "value2": 20.29
          },
          {
            "category": "27-03-2015",
            "value": 36.1516,
            "value2": 20.27
          },
          {
            "category": "28-03-2015",
            "value": 36.1515,
            "value2": 20.27
          },
          {
            "category": "29-03-2015",
            "value": 36.1515,
            "value2": 20.27
          },
          {
            "category": "30-03-2015",
            "value": 36.5313,
            "value2": 20.63
          },
          {
            "category": "31-03-2015",
            "value": 36.2632,
            "value2": 20.65
          },
          {
            "category": "01-04-2015",
            "value": 36.8362,
            "value2": 20.9
          },
          {
            "category": "02-04-2015",
            "value": 36.8362,
            "value2": 20.9
          },
          {
            "category": "03-04-2015",
            "value": 36.8362,
            "value2": 20.9
          },
          {
            "category": "04-04-2015",
            "value": 36.8362,
            "value2": 20.9
          },
          {
            "category": "05-04-2015",
            "value": 36.8362,
            "value2": 20.9
          },
          {
            "category": "06-04-2015",
            "value": 36.8511,
            "value2": 21.1
          },
          {
            "category": "07-04-2015",
            "value": 36.9716,
            "value2": 21.16
          },
          {
            "category": "08-04-2015",
            "value": 37.7334,
            "value2": 21.3
          },
          {
            "category": "09-04-2015",
            "value": 37.7571,
            "value2": 21.42
          },
          {
            "category": "10-04-2015",
            "value": 37.9077,
            "value2": 21.45
          },
          {
            "category": "11-04-2015",
            "value": 37.9077,
            "value2": 21.45
          },
          {
            "category": "12-04-2015",
            "value": 37.9077,
            "value2": 21.45
          },
          {
            "category": "13-04-2015",
            "value": 37.6316,
            "value2": 21.57
          },
          {
            "category": "14-04-2015",
            "value": 37.6316,
            "value2": 21.57
          },
          {
            "category": "15-04-2015",
            "value": 37.5472,
            "value2": 21.4
          },
          {
            "category": "16-04-2015",
            "value": 37.3186,
            "value2": 21.26
          },
          {
            "category": "17-04-2015",
            "value": 36.6703,
            "value2": 21.01
          },
          {
            "category": "18-04-2015",
            "value": 36.6703,
            "value2": 21.01
          },
          {
            "category": "19-04-2015",
            "value": 36.6703,
            "value2": 21.01
          },
          {
            "category": "20-04-2015",
            "value": 36.0566,
            "value2": 20.61
          },
          {
            "category": "21-04-2015",
            "value": 35.866,
            "value2": 20.47
          },
          {
            "category": "22-04-2015",
            "value": 35.7468,
            "value2": 20.55
          },
          {
            "category": "23-04-2015",
            "value": 35.7974,
            "value2": 20.5
          },
          {
            "category": "24-04-2015",
            "value": 35.1767,
            "value2": 20.21
          },
          {
            "category": "25-04-2015",
            "value": 35.1767,
            "value2": 20.21
          },
          {
            "category": "26-04-2015",
            "value": 35.1767,
            "value2": 20.21
          },
          {
            "category": "27-04-2015",
            "value": 34.4223,
            "value2": 19.91
          },
          {
            "category": "28-04-2015",
            "value": 35.0932,
            "value2": 20.12
          },
          {
            "category": "29-04-2015",
            "value": 35.2133,
            "value2": 20.08
          },
          {
            "category": "30-04-2015",
            "value": 35.4554,
            "value2": 19.99
          },
          {
            "category": "01-05-2015",
            "value": 35.3798,
            "value2": 19.99
          },
          {
            "category": "02-05-2015",
            "value": 35.3798,
            "value2": 19.99
          },
          {
            "category": "03-05-2015",
            "value": 35.3798,
            "value2": 19.99
          },
          {
            "category": "04-05-2015",
            "value": 35.938,
            "value2": 20.35
          },
          {
            "category": "05-05-2015",
            "value": 36.1978,
            "value2": 20.37
          },
          {
            "category": "06-05-2015",
            "value": 35.3978,
            "value2": 19.79
          },
          {
            "category": "07-05-2015",
            "value": 34.712,
            "value2": 19.6
          },
          {
            "category": "08-05-2015",
            "value": 35.4631,
            "value2": 19.94
          },
          {
            "category": "09-05-2015",
            "value": 35.4631,
            "value2": 19.94
          },
          {
            "category": "10-05-2015",
            "value": 35.4631,
            "value2": 19.94
          },
          {
            "category": "11-05-2015",
            "value": 36.248,
            "value2": 20.26
          },
          {
            "category": "12-05-2015",
            "value": 35.9706,
            "value2": 19.82
          },
          {
            "category": "13-05-2015",
            "value": 36.6826,
            "value2": 20.09
          },
          {
            "category": "14-05-2015",
            "value": 37.1669,
            "value2": 20.14
          },
          {
            "category": "15-05-2015",
            "value": 37.6171,
            "value2": 20.23
          },
          {
            "category": "16-05-2015",
            "value": 37.6171,
            "value2": 20.23
          },
          {
            "category": "17-05-2015",
            "value": 37.6171,
            "value2": 20.23
          },
          {
            "category": "18-05-2015",
            "value": 37.9891,
            "value2": 20.47
          },
          {
            "category": "19-05-2015",
            "value": 38.0575,
            "value2": 20.47
          },
          {
            "category": "20-05-2015",
            "value": 38.2332,
            "value2": 20.57
          },
          {
            "category": "21-05-2015",
            "value": 38.0858,
            "value2": 20.56
          },
          {
            "category": "22-05-2015",
            "value": 38.3799,
            "value2": 20.63
          },
          {
            "category": "23-05-2015",
            "value": 38.3799,
            "value2": 20.63
          },
          {
            "category": "24-05-2015",
            "value": 38.3799,
            "value2": 20.63
          },
          {
            "category": "25-05-2015",
            "value": 38.7044,
            "value2": 20.49
          },
          {
            "category": "26-05-2015",
            "value": 38.5449,
            "value2": 20.42
          },
          {
            "category": "27-05-2015",
            "value": 38.3559,
            "value2": 20.41
          },
          {
            "category": "28-05-2015",
            "value": 38.4564,
            "value2": 20.36
          },
          {
            "category": "29-05-2015",
            "value": 39.4741,
            "value2": 20.63
          },
          {
            "category": "30-05-2015",
            "value": 39.4741,
            "value2": 20.63
          },
          {
            "category": "31-05-2015",
            "value": 39.4741,
            "value2": 20.63
          },
          {
            "category": "01-06-2015",
            "value": 39.9365,
            "value2": 20.6
          },
          {
            "category": "02-06-2015",
            "value": 38.6384,
            "value2": 20.15
          },
          {
            "category": "03-06-2015",
            "value": 38.3066,
            "value2": 19.87
          },
          {
            "category": "04-06-2015",
            "value": 38.3083,
            "value2": 19.89
          },
          {
            "category": "05-06-2015",
            "value": 38.4654,
            "value2": 19.88
          },
          {
            "category": "06-06-2015",
            "value": 38.4654,
            "value2": 19.88
          },
          {
            "category": "07-06-2015",
            "value": 38.4654,
            "value2": 19.88
          },
          {
            "category": "08-06-2015",
            "value": 37.8841,
            "value2": 19.66
          },
          {
            "category": "09-06-2015",
            "value": 37.8509,
            "value2": 19.61
          },
          {
            "category": "10-06-2015",
            "value": 38.3103,
            "value2": 19.85
          },
          {
            "category": "11-06-2015",
            "value": 37.6307,
            "value2": 19.5
          },
          {
            "category": "12-06-2015",
            "value": 37.5525,
            "value2": 19.51
          },
          {
            "category": "13-06-2015",
            "value": 37.5525,
            "value2": 19.51
          },
          {
            "category": "14-06-2015",
            "value": 37.5525,
            "value2": 19.51
          },
          {
            "category": "15-06-2015",
            "value": 37.8234,
            "value2": 19.6
          },
          {
            "category": "16-06-2015",
            "value": 38.1892,
            "value2": 19.67
          },
          {
            "category": "17-06-2015",
            "value": 38.7768,
            "value2": 19.82
          },
          {
            "category": "18-06-2015",
            "value": 39.3294,
            "value2": 20.02
          },
          {
            "category": "19-06-2015",
            "value": 39.5559,
            "value2": 20.15
          },
          {
            "category": "20-06-2015",
            "value": 39.5558,
            "value2": 20.15
          },
          {
            "category": "21-06-2015",
            "value": 39.5558,
            "value2": 20.15
          },
          {
            "category": "22-06-2015",
            "value": 40.0282,
            "value2": 20.45
          },
          {
            "category": "23-06-2015",
            "value": 40.0045,
            "value2": 20.52
          },
          {
            "category": "24-06-2015",
            "value": 39.6743,
            "value2": 20.44
          },
          {
            "category": "25-06-2015",
            "value": 40.0914,
            "value2": 20.53
          },
          {
            "category": "26-06-2015",
            "value": 40.1499,
            "value2": 20.5
          },
          {
            "category": "27-06-2015",
            "value": 40.15,
            "value2": 20.5
          },
          {
            "category": "28-06-2015",
            "value": 40.15,
            "value2": 20.5
          },
          {
            "category": "29-06-2015",
            "value": 39.8817,
            "value2": 20.31
          },
          {
            "category": "30-06-2015",
            "value": 39.9999,
            "value2": 20.47
          },
          {
            "category": "01-07-2015",
            "value": 40.5188,
            "value2": 20.69
          },
          {
            "category": "02-07-2015",
            "value": 41.2679,
            "value2": 20.72
          },
          {
            "category": "03-07-2015",
            "value": 41.0222,
            "value2": 20.79
          },
          {
            "category": "04-07-2015",
            "value": 41.0222,
            "value2": 20.79
          },
          {
            "category": "05-07-2015",
            "value": 41.0222,
            "value2": 20.79
          },
          {
            "category": "06-07-2015",
            "value": 41.3448,
            "value2": 20.91
          },
          {
            "category": "07-07-2015",
            "value": 41.4711,
            "value2": 20.93
          },
          {
            "category": "08-07-2015",
            "value": 40.9613,
            "value2": 20.6
          },
          {
            "category": "09-07-2015",
            "value": 40.6446,
            "value2": 20.53
          },
          {
            "category": "10-07-2015",
            "value": 40.5632,
            "value2": 20.59
          },
          {
            "category": "11-07-2015",
            "value": 40.5632,
            "value2": 20.59
          },
          {
            "category": "12-07-2015",
            "value": 40.5632,
            "value2": 20.59
          },
          {
            "category": "13-07-2015",
            "value": 41.1618,
            "value2": 20.84
          },
          {
            "category": "14-07-2015",
            "value": 41.1559,
            "value2": 20.84
          },
          {
            "category": "15-07-2015",
            "value": 41.3122,
            "value2": 20.99
          },
          {
            "category": "16-07-2015",
            "value": 41.9686,
            "value2": 21.2
          },
          {
            "category": "17-07-2015",
            "value": 41.8677,
            "value2": 21.24
          },
          {
            "category": "18-07-2015",
            "value": 41.8677,
            "value2": 21.24
          },
          {
            "category": "19-07-2015",
            "value": 41.8677,
            "value2": 21.24
          },
          {
            "category": "20-07-2015",
            "value": 42.0211,
            "value2": 21.26
          },
          {
            "category": "21-07-2015",
            "value": 41.585,
            "value2": 21.02
          },
          {
            "category": "22-07-2015",
            "value": 41.8155,
            "value2": 21.25
          },
          {
            "category": "23-07-2015",
            "value": 41.5932,
            "value2": 21.21
          },
          {
            "category": "24-07-2015",
            "value": 41.4027,
            "value2": 21.04
          },
          {
            "category": "25-07-2015",
            "value": 41.4027,
            "value2": 21.04
          },
          {
            "category": "26-07-2015",
            "value": 41.4027,
            "value2": 21.04
          },
          {
            "category": "27-07-2015",
            "value": 40.9346,
            "value2": 20.7
          },
          {
            "category": "28-07-2015",
            "value": 41.0895,
            "value2": 20.64
          },
          {
            "category": "29-07-2015",
            "value": 41.3244,
            "value2": 20.75
          },
          {
            "category": "30-07-2015",
            "value": 41.5328,
            "value2": 20.89
          },
          {
            "category": "31-07-2015",
            "value": 41.4985,
            "value2": 21.15
          },
          {
            "category": "01-08-2015",
            "value": 41.4982,
            "value2": 21.15
          },
          {
            "category": "02-08-2015",
            "value": 41.4982,
            "value2": 21.15
          },
          {
            "category": "03-08-2015",
            "value": 41.7837,
            "value2": 21.22
          },
          {
            "category": "04-08-2015",
            "value": 42.4525,
            "value2": 21.25
          },
          {
            "category": "05-08-2015",
            "value": 42.7049,
            "value2": 21.39
          },
          {
            "category": "06-08-2015",
            "value": 43.0272,
            "value2": 21.44
          },
          {
            "category": "07-08-2015",
            "value": 43.3224,
            "value2": 21.39
          },
          {
            "category": "08-08-2015",
            "value": 43.3224,
            "value2": 21.39
          },
          {
            "category": "09-08-2015",
            "value": 43.3224,
            "value2": 21.39
          },
          {
            "category": "10-08-2015",
            "value": 43.0094,
            "value2": 21.3
          },
          {
            "category": "11-08-2015",
            "value": 42.8632,
            "value2": 21.13
          },
          {
            "category": "12-08-2015",
            "value": 41.8212,
            "value2": 20.78
          },
          {
            "category": "13-08-2015",
            "value": 41.7246,
            "value2": 20.76
          },
          {
            "category": "14-08-2015",
            "value": 42.6149,
            "value2": 21.16
          },
          {
            "category": "15-08-2015",
            "value": 42.6149,
            "value2": 21.16
          },
          {
            "category": "16-08-2015",
            "value": 42.6149,
            "value2": 21.16
          },
          {
            "category": "17-08-2015",
            "value": 42.5069,
            "value2": 21.11
          },
          {
            "category": "18-08-2015",
            "value": 42.6043,
            "value2": 21.13
          },
          {
            "category": "19-08-2015",
            "value": 42.7647,
            "value2": 21.19
          },
          {
            "category": "20-08-2015",
            "value": 42.2393,
            "value2": 20.88
          },
          {
            "category": "21-08-2015",
            "value": 41.8118,
            "value2": 20.7
          },
          {
            "category": "22-08-2015",
            "value": 41.8111,
            "value2": 20.7
          },
          {
            "category": "23-08-2015",
            "value": 41.8115,
            "value2": 20.7
          },
          {
            "category": "24-08-2015",
            "value": 38.4225,
            "value2": 19.32
          },
          {
            "category": "25-08-2015",
            "value": 39.1689,
            "value2": 19.57
          },
          {
            "category": "26-08-2015",
            "value": 38.9354,
            "value2": 19.39
          },
          {
            "category": "27-08-2015",
            "value": 39.7535,
            "value2": 19.82
          },
          {
            "category": "28-08-2015",
            "value": 39.8512,
            "value2": 19.92
          },
          {
            "category": "29-08-2015",
            "value": 39.8509,
            "value2": 19.92
          },
          {
            "category": "30-08-2015",
            "value": 39.8509,
            "value2": 19.92
          },
          {
            "category": "31-08-2015",
            "value": 39.6827,
            "value2": 19.85
          },
          {
            "category": "01-09-2015",
            "value": 38.636,
            "value2": 19.42
          },
          {
            "category": "02-09-2015",
            "value": 38.7769,
            "value2": 19.29
          },
          {
            "category": "03-09-2015",
            "value": 39.1537,
            "value2": 19.54
          },
          {
            "category": "04-09-2015",
            "value": 38.479,
            "value2": 19.13
          },
          {
            "category": "05-09-2015",
            "value": 38.4784,
            "value2": 19.13
          },
          {
            "category": "06-09-2015",
            "value": 38.4784,
            "value2": 19.13
          },
          {
            "category": "07-09-2015",
            "value": 37.6819,
            "value2": 18.84
          },
          {
            "category": "08-09-2015",
            "value": 37.59,
            "value2": 19.1
          },
          {
            "category": "09-09-2015",
            "value": 38.4281,
            "value2": 19.42
          },
          {
            "category": "10-09-2015",
            "value": 38.61,
            "value2": 19.38
          },
          {
            "category": "11-09-2015",
            "value": 38.7162,
            "value2": 19.39
          },
          {
            "category": "12-09-2015",
            "value": 38.7162,
            "value2": 19.39
          },
          {
            "category": "13-09-2015",
            "value": 38.7162,
            "value2": 19.39
          },
          {
            "category": "14-09-2015",
            "value": 38.9823,
            "value2": 19.61
          },
          {
            "category": "15-09-2015",
            "value": 38.8817,
            "value2": 19.49
          },
          {
            "category": "16-09-2015",
            "value": 38.101,
            "value2": 19.59
          },
          {
            "category": "17-09-2015",
            "value": 38.101,
            "value2": 19.59
          },
          {
            "category": "18-09-2015",
            "value": 37.9486,
            "value2": 19.79
          },
          {
            "category": "19-09-2015",
            "value": 37.9486,
            "value2": 19.79
          },
          {
            "category": "20-09-2015",
            "value": 37.9486,
            "value2": 19.79
          },
          {
            "category": "21-09-2015",
            "value": 38.4064,
            "value2": 19.83
          },
          {
            "category": "22-09-2015",
            "value": 38.004,
            "value2": 19.46
          },
          {
            "category": "23-09-2015",
            "value": 38.0183,
            "value2": 19.56
          },
          {
            "category": "24-09-2015",
            "value": 37.893,
            "value2": 19.6
          },
          {
            "category": "25-09-2015",
            "value": 37.8927,
            "value2": 19.6
          },
          {
            "category": "26-09-2015",
            "value": 37.8927,
            "value2": 19.6
          },
          {
            "category": "27-09-2015",
            "value": 37.8927,
            "value2": 19.6
          },
          {
            "category": "28-09-2015",
            "value": 37.5892,
            "value2": 19.46
          },
          {
            "category": "29-09-2015",
            "value": 37.7511,
            "value2": 19.54
          },
          {
            "category": "30-09-2015",
            "value": 38.1754,
            "value2": 19.81
          },
          {
            "category": "01-10-2015",
            "value": 38.2888,
            "value2": 19.84
          },
          {
            "category": "02-10-2015",
            "value": 38.2888,
            "value2": 19.84
          },
          {
            "category": "03-10-2015",
            "value": 38.2888,
            "value2": 19.84
          },
          {
            "category": "04-10-2015",
            "value": 38.2888,
            "value2": 19.84
          },
          {
            "category": "05-10-2015",
            "value": 38.8994,
            "value2": 20.23
          },
          {
            "category": "06-10-2015",
            "value": 39.0151,
            "value2": 20.33
          },
          {
            "category": "07-10-2015",
            "value": 39.0407,
            "value2": 20.38
          },
          {
            "category": "08-10-2015",
            "value": 38.8543,
            "value2": 20.26
          },
          {
            "category": "09-10-2015",
            "value": 38.6094,
            "value2": 20.36
          },
          {
            "category": "10-10-2015",
            "value": 38.6094,
            "value2": 20.36
          },
          {
            "category": "11-10-2015",
            "value": 38.6094,
            "value2": 20.36
          },
          {
            "category": "12-10-2015",
            "value": 38.438,
            "value2": 20.29
          },
          {
            "category": "13-10-2015",
            "value": 38.1884,
            "value2": 20.27
          },
          {
            "category": "14-10-2015",
            "value": 38.0283,
            "value2": 20.23
          },
          {
            "category": "15-10-2015",
            "value": 38.0728,
            "value2": 20.39
          },
          {
            "category": "16-10-2015",
            "value": 38.2543,
            "value2": 20.51
          },
          {
            "category": "17-10-2015",
            "value": 38.2543,
            "value2": 20.51
          },
          {
            "category": "18-10-2015",
            "value": 38.2543,
            "value2": 20.51
          },
          {
            "category": "19-10-2015",
            "value": 38.4757,
            "value2": 20.61
          },
          {
            "category": "20-10-2015",
            "value": 38.7786,
            "value2": 20.61
          },
          {
            "category": "21-10-2015",
            "value": 38.7304,
            "value2": 20.58
          },
          {
            "category": "22-10-2015",
            "value": 38.7304,
            "value2": 20.58
          },
          {
            "category": "23-10-2015",
            "value": 38.4299,
            "value2": 20.63
          },
          {
            "category": "24-10-2015",
            "value": 38.4287,
            "value2": 20.63
          },
          {
            "category": "25-10-2015",
            "value": 38.4287,
            "value2": 20.63
          },
          {
            "category": "26-10-2015",
            "value": 38.2702,
            "value2": 20.54
          },
          {
            "category": "27-10-2015",
            "value": 38.2349,
            "value2": 20.5
          },
          {
            "category": "28-10-2015",
            "value": 37.8724,
            "value2": 20.38
          },
          {
            "category": "29-10-2015",
            "value": 37.7174,
            "value2": 20.26
          },
          {
            "category": "30-10-2015",
            "value": 37.7777,
            "value2": 20.15
          },
          {
            "category": "31-10-2015",
            "value": 37.7777,
            "value2": 20.15
          },
          {
            "category": "01-11-2015",
            "value": 37.7778,
            "value2": 20.15
          },
          {
            "category": "02-11-2015",
            "value": 37.6425,
            "value2": 20.12
          },
          {
            "category": "03-11-2015",
            "value": 37.7671,
            "value2": 20.16
          },
          {
            "category": "04-11-2015",
            "value": 37.6323,
            "value2": 20.13
          },
          {
            "category": "05-11-2015",
            "value": 37.2567,
            "value2": 19.9
          },
          {
            "category": "06-11-2015",
            "value": 36.7676,
            "value2": 19.85
          },
          {
            "category": "07-11-2015",
            "value": 36.7676,
            "value2": 19.85
          },
          {
            "category": "08-11-2015",
            "value": 36.7676,
            "value2": 19.85
          },
          {
            "category": "09-11-2015",
            "value": 36.5296,
            "value2": 19.83
          },
          {
            "category": "10-11-2015",
            "value": 35.8149,
            "value2": 19.53
          },
          {
            "category": "11-11-2015",
            "value": 36.1219,
            "value2": 19.68
          },
          {
            "category": "12-11-2015",
            "value": 36.1219,
            "value2": 19.68
          },
          {
            "category": "13-11-2015",
            "value": 35.7169,
            "value2": 19.5
          },
          {
            "category": "14-11-2015",
            "value": 35.7169,
            "value2": 19.5
          },
          {
            "category": "15-11-2015",
            "value": 35.7169,
            "value2": 19.5
          },
          {
            "category": "16-11-2015",
            "value": 35.6921,
            "value2": 19.58
          },
          {
            "category": "17-11-2015",
            "value": 35.8664,
            "value2": 19.66
          },
          {
            "category": "18-11-2015",
            "value": 36.0172,
            "value2": 19.45
          },
          {
            "category": "19-11-2015",
            "value": 36.7438,
            "value2": 19.71
          },
          {
            "category": "20-11-2015",
            "value": 37.0187,
            "value2": 19.77
          },
          {
            "category": "21-11-2015",
            "value": 37.0193,
            "value2": 19.77
          },
          {
            "category": "22-11-2015",
            "value": 37.0193,
            "value2": 19.77
          },
          {
            "category": "23-11-2015",
            "value": 37.0124,
            "value2": 19.78
          },
          {
            "category": "24-11-2015",
            "value": 36.8156,
            "value2": 19.76
          },
          {
            "category": "25-11-2015",
            "value": 36.8156,
            "value2": 19.76
          },
          {
            "category": "26-11-2015",
            "value": 36.9123,
            "value2": 19.87
          },
          {
            "category": "27-11-2015",
            "value": 37.1139,
            "value2": 19.98
          },
          {
            "category": "28-11-2015",
            "value": 37.1117,
            "value2": 19.98
          },
          {
            "category": "29-11-2015",
            "value": 37.1117,
            "value2": 19.98
          },
          {
            "category": "30-11-2015",
            "value": 37.1217,
            "value2": 19.99
          },
          {
            "category": "01-12-2015",
            "value": 37.14,
            "value2": 20.05
          },
          {
            "category": "02-12-2015",
            "value": 37.108,
            "value2": 20.02
          },
          {
            "category": "03-12-2015",
            "value": 37.0854,
            "value2": 19.87
          },
          {
            "category": "04-12-2015",
            "value": 36.7416,
            "value2": 19.68
          },
          {
            "category": "05-12-2015",
            "value": 36.7416,
            "value2": 19.68
          },
          {
            "category": "06-12-2015",
            "value": 36.7416,
            "value2": 19.68
          },
          {
            "category": "07-12-2015",
            "value": 36.9169,
            "value2": 19.66
          },
          {
            "category": "08-12-2015",
            "value": 36.8537,
            "value2": 19.47
          },
          {
            "category": "09-12-2015",
            "value": 36.2226,
            "value2": 19.2
          },
          {
            "category": "10-12-2015",
            "value": 36.5652,
            "value2": 19.38
          },
          {
            "category": "11-12-2015",
            "value": 36.2975,
            "value2": 19.2
          },
          {
            "category": "12-12-2015",
            "value": 36.2975,
            "value2": 19.2
          },
          {
            "category": "13-12-2015",
            "value": 36.2975,
            "value2": 19.2
          },
          {
            "category": "14-12-2015",
            "value": 36.0938,
            "value2": 19.29
          },
          {
            "category": "15-12-2015",
            "value": 36.6937,
            "value2": 19.41
          },
          {
            "category": "16-12-2015",
            "value": 36.886,
            "value2": 19.52
          },
          {
            "category": "17-12-2015",
            "value": 37.1302,
            "value2": 19.78
          },
          {
            "category": "18-12-2015",
            "value": 37.0537,
            "value2": 19.64
          },
          {
            "category": "19-12-2015",
            "value": 37.0537,
            "value2": 19.64
          },
          {
            "category": "20-12-2015",
            "value": 37.0537,
            "value2": 19.64
          },
          {
            "category": "21-12-2015",
            "value": 37.1603,
            "value2": 19.79
          },
          {
            "category": "22-12-2015",
            "value": 37.1029,
            "value2": 19.71
          },
          {
            "category": "23-12-2015",
            "value": 37.3554,
            "value2": 19.87
          },
          {
            "category": "24-12-2015",
            "value": 37.4421,
            "value2": 19.89
          },
          {
            "category": "25-12-2015",
            "value": 37.4421,
            "value2": 19.89
          },
          {
            "category": "26-12-2015",
            "value": 37.4421,
            "value2": 19.89
          },
          {
            "category": "27-12-2015",
            "value": 37.4421,
            "value2": 19.89
          },
          {
            "category": "28-12-2015",
            "value": 37.5785,
            "value2": 20
          },
          {
            "category": "29-12-2015",
            "value": 37.694,
            "value2": 20.03
          },
          {
            "category": "30-12-2015",
            "value": 37.5934,
            "value2": 19.98
          },
          {
            "category": "31-12-2015",
            "value": 37.9867,
            "value2": 20.09
          },
          {
            "category": "01-01-2016",
            "value": 38.4131,
            "value2": 20.18
          },
          {
            "category": "02-01-2016",
            "value": 38.413,
            "value2": 20.18
          },
          {
            "category": "03-01-2016",
            "value": 38.4131,
            "value2": 20.18
          },
          {
            "category": "04-01-2016",
            "value": 38.1327,
            "value2": 19.82
          },
          {
            "category": "05-01-2016",
            "value": 37.9713,
            "value2": 19.85
          },
          {
            "category": "06-01-2016",
            "value": 38.1515,
            "value2": 19.75
          },
          {
            "category": "07-01-2016",
            "value": 37.2109,
            "value2": 19.26
          },
          {
            "category": "08-01-2016",
            "value": 37.6716,
            "value2": 19.4
          },
          {
            "category": "09-01-2016",
            "value": 37.6716,
            "value2": 19.4
          },
          {
            "category": "10-01-2016",
            "value": 37.6716,
            "value2": 19.4
          },
          {
            "category": "11-01-2016",
            "value": 37.356,
            "value2": 19.29
          },
          {
            "category": "12-01-2016",
            "value": 36.8431,
            "value2": 19.15
          },
          {
            "category": "13-01-2016",
            "value": 36.6675,
            "value2": 19.16
          },
          {
            "category": "14-01-2016",
            "value": 36.7461,
            "value2": 19.05
          },
          {
            "category": "15-01-2016",
            "value": 36.0463,
            "value2": 18.7
          },
          {
            "category": "16-01-2016",
            "value": 36.0463,
            "value2": 18.7
          },
          {
            "category": "17-01-2016",
            "value": 36.0463,
            "value2": 18.7
          },
          {
            "category": "18-01-2016",
            "value": 34.7063,
            "value2": 18.36
          },
          {
            "category": "19-01-2016",
            "value": 35.1795,
            "value2": 18.59
          },
          {
            "category": "20-01-2016",
            "value": 34.5877,
            "value2": 18.26
          },
          {
            "category": "21-01-2016",
            "value": 34.5269,
            "value2": 18.21
          },
          {
            "category": "22-01-2016",
            "value": 35.0509,
            "value2": 18.57
          },
          {
            "category": "23-01-2016",
            "value": 35.0509,
            "value2": 18.57
          },
          {
            "category": "24-01-2016",
            "value": 35.0509,
            "value2": 18.57
          },
          {
            "category": "25-01-2016",
            "value": 35.0817,
            "value2": 18.62
          },
          {
            "category": "26-01-2016",
            "value": 35.0817,
            "value2": 18.62
          },
          {
            "category": "27-01-2016",
            "value": 35.0634,
            "value2": 18.65
          },
          {
            "category": "28-01-2016",
            "value": 34.9859,
            "value2": 18.63
          },
          {
            "category": "29-01-2016",
            "value": 35.5094,
            "value2": 18.93
          },
          {
            "category": "30-01-2016",
            "value": 35.5101,
            "value2": 18.93
          },
          {
            "category": "31-01-2016",
            "value": 35.5101,
            "value2": 18.93
          },
          {
            "category": "01-02-2016",
            "value": 35.7151,
            "value2": 18.94
          },
          {
            "category": "02-02-2016",
            "value": 35.2271,
            "value2": 18.69
          },
          {
            "category": "03-02-2016",
            "value": 35.0717,
            "value2": 18.42
          },
          {
            "category": "04-02-2016",
            "value": 34.972,
            "value2": 18.47
          },
          {
            "category": "05-02-2016",
            "value": 35.6001,
            "value2": 18.73
          },
          {
            "category": "06-02-2016",
            "value": 35.6001,
            "value2": 18.73
          },
          {
            "category": "07-02-2016",
            "value": 35.6001,
            "value2": 18.73
          },
          {
            "category": "08-02-2016",
            "value": 35.8051,
            "value2": 18.55
          },
          {
            "category": "09-02-2016",
            "value": 35.4513,
            "value2": 18.3
          },
          {
            "category": "10-02-2016",
            "value": 34.9473,
            "value2": 18.08
          },
          {
            "category": "11-02-2016",
            "value": 33.6444,
            "value2": 17.45
          },
          {
            "category": "12-02-2016",
            "value": 33.204,
            "value2": 17.43
          },
          {
            "category": "13-02-2016",
            "value": 33.204,
            "value2": 17.43
          },
          {
            "category": "14-02-2016",
            "value": 33.204,
            "value2": 17.43
          },
          {
            "category": "15-02-2016",
            "value": 34.3662,
            "value2": 17.91
          },
          {
            "category": "16-02-2016",
            "value": 33.718,
            "value2": 17.59
          },
          {
            "category": "17-02-2016",
            "value": 33.9772,
            "value2": 17.72
          },
          {
            "category": "18-02-2016",
            "value": 33.9456,
            "value2": 17.89
          },
          {
            "category": "19-02-2016",
            "value": 33.6088,
            "value2": 17.93
          },
          {
            "category": "20-02-2016",
            "value": 33.6088,
            "value2": 17.93
          },
          {
            "category": "21-02-2016",
            "value": 33.6088,
            "value2": 17.93
          },
          {
            "category": "22-02-2016",
            "value": 33.9121,
            "value2": 18.01
          },
          {
            "category": "23-02-2016",
            "value": 33.2937,
            "value2": 17.73
          },
          {
            "category": "24-02-2016",
            "value": 33.116,
            "value2": 17.52
          },
          {
            "category": "25-02-2016",
            "value": 32.5659,
            "value2": 17.39
          },
          {
            "category": "26-02-2016",
            "value": 32.8513,
            "value2": 17.48
          },
          {
            "category": "27-02-2016",
            "value": 32.8513,
            "value2": 17.48
          },
          {
            "category": "28-02-2016",
            "value": 32.8513,
            "value2": 17.48
          },
          {
            "category": "29-02-2016",
            "value": 33.0846,
            "value2": 17.42
          },
          {
            "category": "01-03-2016",
            "value": 33.7498,
            "value2": 17.98
          },
          {
            "category": "02-03-2016",
            "value": 34.3812,
            "value2": 18.33
          },
          {
            "category": "03-03-2016",
            "value": 34.3523,
            "value2": 18.57
          },
          {
            "category": "04-03-2016",
            "value": 34.6374,
            "value2": 18.64
          },
          {
            "category": "05-03-2016",
            "value": 34.6374,
            "value2": 18.64
          },
          {
            "category": "06-03-2016",
            "value": 34.6374,
            "value2": 18.64
          },
          {
            "category": "07-03-2016",
            "value": 34.6374,
            "value2": 18.64
          },
          {
            "category": "08-03-2016",
            "value": 34.6121,
            "value2": 18.65
          },
          {
            "category": "09-03-2016",
            "value": 34.8861,
            "value2": 18.75
          },
          {
            "category": "10-03-2016",
            "value": 34.6656,
            "value2": 18.65
          },
          {
            "category": "11-03-2016",
            "value": 34.7558,
            "value2": 18.69
          },
          {
            "category": "12-03-2016",
            "value": 34.7558,
            "value2": 18.69
          },
          {
            "category": "13-03-2016",
            "value": 34.7558,
            "value2": 18.69
          },
          {
            "category": "14-03-2016",
            "value": 34.8955,
            "value2": 18.77
          },
          {
            "category": "15-03-2016",
            "value": 34.8326,
            "value2": 18.61
          },
          {
            "category": "16-03-2016",
            "value": 34.6055,
            "value2": 18.66
          },
          {
            "category": "17-03-2016",
            "value": 34.8958,
            "value2": 18.71
          },
          {
            "category": "18-03-2016",
            "value": 35.3376,
            "value2": 18.91
          },
          {
            "category": "19-03-2016",
            "value": 35.3376,
            "value2": 18.91
          },
          {
            "category": "20-03-2016",
            "value": 35.3376,
            "value2": 18.91
          },
          {
            "category": "21-03-2016",
            "value": 35.6383,
            "value2": 19.17
          },
          {
            "category": "22-03-2016",
            "value": 35.804,
            "value2": 19.22
          },
          {
            "category": "23-03-2016",
            "value": 35.9125,
            "value2": 19.23
          },
          {
            "category": "24-03-2016",
            "value": 35.9125,
            "value2": 19.23
          },
          {
            "category": "25-03-2016",
            "value": 35.9125,
            "value2": 19.23
          },
          {
            "category": "26-03-2016",
            "value": 35.9125,
            "value2": 19.23
          },
          {
            "category": "27-03-2016",
            "value": 35.9125,
            "value2": 19.23
          },
          {
            "category": "28-03-2016",
            "value": 35.5665,
            "value2": 18.97
          },
          {
            "category": "29-03-2016",
            "value": 35.7123,
            "value2": 18.93
          },
          {
            "category": "30-03-2016",
            "value": 36.1801,
            "value2": 19.27
          },
          {
            "category": "31-03-2016",
            "value": 36.6102,
            "value2": 19.32
          },
          {
            "category": "01-04-2016",
            "value": 36.5973,
            "value2": 19.31
          },
          {
            "category": "02-04-2016",
            "value": 36.5973,
            "value2": 19.31
          },
          {
            "category": "03-04-2016",
            "value": 36.5973,
            "value2": 19.31
          },
          {
            "category": "04-04-2016",
            "value": 36.6687,
            "value2": 19.41
          },
          {
            "category": "05-04-2016",
            "value": 36.2073,
            "value2": 19.06
          },
          {
            "category": "06-04-2016",
            "value": 36.4657,
            "value2": 19.12
          },
          {
            "category": "07-04-2016",
            "value": 36.3046,
            "value2": 18.98
          },
          {
            "category": "08-04-2016",
            "value": 36.5564,
            "value2": 19.03
          },
          {
            "category": "09-04-2016",
            "value": 36.5564,
            "value2": 19.03
          },
          {
            "category": "10-04-2016",
            "value": 36.5564,
            "value2": 19.03
          },
          {
            "category": "11-04-2016",
            "value": 36.9716,
            "value2": 19.27
          },
          {
            "category": "12-04-2016",
            "value": 37.2298,
            "value2": 19.39
          },
          {
            "category": "13-04-2016",
            "value": 37.722,
            "value2": 19.7
          },
          {
            "category": "14-04-2016",
            "value": 37.722,
            "value2": 19.7
          },
          {
            "category": "15-04-2016",
            "value": 37.722,
            "value2": 19.7
          },
          {
            "category": "16-04-2016",
            "value": 37.722,
            "value2": 19.7
          },
          {
            "category": "17-04-2016",
            "value": 37.722,
            "value2": 19.7
          },
          {
            "category": "18-04-2016",
            "value": 37.8526,
            "value2": 19.87
          },
          {
            "category": "19-04-2016",
            "value": 37.8526,
            "value2": 19.87
          },
          {
            "category": "20-04-2016",
            "value": 38.0561,
            "value2": 19.91
          },
          {
            "category": "21-04-2016",
            "value": 37.6919,
            "value2": 19.88
          },
          {
            "category": "22-04-2016",
            "value": 37.7709,
            "value2": 19.86
          },
          {
            "category": "23-04-2016",
            "value": 37.7709,
            "value2": 19.86
          },
          {
            "category": "24-04-2016",
            "value": 37.7709,
            "value2": 19.86
          },
          {
            "category": "25-04-2016",
            "value": 37.4865,
            "value2": 19.76
          },
          {
            "category": "26-04-2016",
            "value": 37.8296,
            "value2": 19.99
          },
          {
            "category": "27-04-2016",
            "value": 37.854,
            "value2": 20.03
          },
          {
            "category": "28-04-2016",
            "value": 37.4466,
            "value2": 19.74
          },
          {
            "category": "29-04-2016",
            "value": 37.2786,
            "value2": 19.74
          },
          {
            "category": "30-04-2016",
            "value": 37.278,
            "value2": 19.74
          },
          {
            "category": "01-05-2016",
            "value": 37.278,
            "value2": 19.74
          },
          {
            "category": "02-05-2016",
            "value": 37.4648,
            "value2": 19.72
          },
          {
            "category": "03-05-2016",
            "value": 37.3117,
            "value2": 19.59
          },
          {
            "category": "04-05-2016",
            "value": 37.0073,
            "value2": 19.43
          },
          {
            "category": "05-05-2016",
            "value": 36.9826,
            "value2": 19.49
          },
          {
            "category": "06-05-2016",
            "value": 37.6033,
            "value2": 19.49
          },
          {
            "category": "07-05-2016",
            "value": 37.6033,
            "value2": 19.49
          },
          {
            "category": "08-05-2016",
            "value": 37.6033,
            "value2": 19.49
          },
          {
            "category": "09-05-2016",
            "value": 38.0264,
            "value2": 19.79
          },
          {
            "category": "10-05-2016",
            "value": 37.9185,
            "value2": 19.83
          },
          {
            "category": "11-05-2016",
            "value": 38.099,
            "value2": 19.75
          },
          {
            "category": "12-05-2016",
            "value": 38.3063,
            "value2": 19.91
          },
          {
            "category": "13-05-2016",
            "value": 38.0262,
            "value2": 19.73
          },
          {
            "category": "14-05-2016",
            "value": 38.0262,
            "value2": 19.73
          },
          {
            "category": "15-05-2016",
            "value": 38.0262,
            "value2": 19.73
          },
          {
            "category": "16-05-2016",
            "value": 38.3849,
            "value2": 19.82
          },
          {
            "category": "17-05-2016",
            "value": 38.3795,
            "value2": 19.88
          },
          {
            "category": "18-05-2016",
            "value": 38.2683,
            "value2": 19.85
          },
          {
            "category": "19-05-2016",
            "value": 37.9715,
            "value2": 19.63
          },
          {
            "category": "20-05-2016",
            "value": 37.9031,
            "value2": 19.53
          },
          {
            "category": "21-05-2016",
            "value": 37.9031,
            "value2": 19.53
          },
          {
            "category": "22-05-2016",
            "value": 37.9031,
            "value2": 19.53
          },
          {
            "category": "23-05-2016",
            "value": 37.8507,
            "value2": 19.48
          },
          {
            "category": "24-05-2016",
            "value": 37.7196,
            "value2": 19.49
          },
          {
            "category": "25-05-2016",
            "value": 37.9053,
            "value2": 19.87
          },
          {
            "category": "26-05-2016",
            "value": 38.0412,
            "value2": 20.16
          },
          {
            "category": "27-05-2016",
            "value": 38.8346,
            "value2": 20.38
          },
          {
            "category": "28-05-2016",
            "value": 38.8346,
            "value2": 20.38
          },
          {
            "category": "29-05-2016",
            "value": 38.8346,
            "value2": 20.38
          },
          {
            "category": "30-05-2016",
            "value": 38.9955,
            "value2": 20.47
          },
          {
            "category": "31-05-2016",
            "value": 38.6618,
            "value2": 20.44
          },
          {
            "category": "01-06-2016",
            "value": 38.7361,
            "value2": 20.46
          },
          {
            "category": "02-06-2016",
            "value": 39.1152,
            "value2": 20.56
          },
          {
            "category": "03-06-2016",
            "value": 39.2231,
            "value2": 20.55
          },
          {
            "category": "04-06-2016",
            "value": 39.2231,
            "value2": 20.55
          },
          {
            "category": "05-06-2016",
            "value": 39.2231,
            "value2": 20.55
          },
          {
            "category": "06-06-2016",
            "value": 39.0598,
            "value2": 20.53
          },
          {
            "category": "07-06-2016",
            "value": 39.0808,
            "value2": 20.68
          },
          {
            "category": "08-06-2016",
            "value": 39.2337,
            "value2": 20.73
          },
          {
            "category": "09-06-2016",
            "value": 39.0944,
            "value2": 20.62
          },
          {
            "category": "10-06-2016",
            "value": 38.9685,
            "value2": 20.54
          },
          {
            "category": "11-06-2016",
            "value": 38.9685,
            "value2": 20.54
          },
          {
            "category": "12-06-2016",
            "value": 38.9685,
            "value2": 20.54
          },
          {
            "category": "13-06-2016",
            "value": 38.7322,
            "value2": 20.4
          },
          {
            "category": "14-06-2016",
            "value": 38.7704,
            "value2": 20.42
          },
          {
            "category": "15-06-2016",
            "value": 39.1364,
            "value2": 20.63
          },
          {
            "category": "16-06-2016",
            "value": 39.0427,
            "value2": 20.51
          },
          {
            "category": "17-06-2016",
            "value": 39.2639,
            "value2": 20.57
          },
          {
            "category": "18-06-2016",
            "value": 39.2639,
            "value2": 20.57
          },
          {
            "category": "19-06-2016",
            "value": 39.2639,
            "value2": 20.57
          },
          {
            "category": "20-06-2016",
            "value": 39.4619,
            "value2": 20.73
          },
          {
            "category": "21-06-2016",
            "value": 39.6102,
            "value2": 20.71
          },
          {
            "category": "22-06-2016",
            "value": 39.5567,
            "value2": 20.66
          },
          {
            "category": "23-06-2016",
            "value": 39.777,
            "value2": 20.78
          },
          {
            "category": "24-06-2016",
            "value": 39.4263,
            "value2": 20.38
          },
          {
            "category": "25-06-2016",
            "value": 39.4263,
            "value2": 20.38
          },
          {
            "category": "26-06-2016",
            "value": 39.4263,
            "value2": 20.38
          },
          {
            "category": "27-06-2016",
            "value": 39.8789,
            "value2": 20.48
          },
          {
            "category": "28-06-2016",
            "value": 40.253,
            "value2": 20.58
          },
          {
            "category": "29-06-2016",
            "value": 40.5869,
            "value2": 20.79
          },
          {
            "category": "30-06-2016",
            "value": 41.0483,
            "value2": 21.01
          },
          {
            "category": "01-07-2016",
            "value": 41.3379,
            "value2": 21.15
          },
          {
            "category": "02-07-2016",
            "value": 41.3379,
            "value2": 21.15
          },
          {
            "category": "03-07-2016",
            "value": 41.3379,
            "value2": 21.15
          },
          {
            "category": "04-07-2016",
            "value": 41.4801,
            "value2": 21.28
          },
          {
            "category": "05-07-2016",
            "value": 41.3908,
            "value2": 21.22
          },
          {
            "category": "06-07-2016",
            "value": 41.3908,
            "value2": 21.22
          },
          {
            "category": "07-07-2016",
            "value": 41.0561,
            "value2": 21.22
          },
          {
            "category": "08-07-2016",
            "value": 40.987,
            "value2": 21.19
          },
          {
            "category": "09-07-2016",
            "value": 40.987,
            "value2": 21.19
          },
          {
            "category": "10-07-2016",
            "value": 40.987,
            "value2": 21.19
          },
          {
            "category": "11-07-2016",
            "value": 41.6613,
            "value2": 21.53
          },
          {
            "category": "12-07-2016",
            "value": 41.8577,
            "value2": 21.64
          },
          {
            "category": "13-07-2016",
            "value": 41.6294,
            "value2": 21.59
          },
          {
            "category": "14-07-2016",
            "value": 42.1841,
            "value2": 21.72
          },
          {
            "category": "15-07-2016",
            "value": 42.2482,
            "value2": 21.66
          },
          {
            "category": "16-07-2016",
            "value": 42.2482,
            "value2": 21.66
          },
          {
            "category": "17-07-2016",
            "value": 42.2482,
            "value2": 21.66
          },
          {
            "category": "18-07-2016",
            "value": 42.2588,
            "value2": 21.58
          },
          {
            "category": "19-07-2016",
            "value": 42.4948,
            "value2": 21.61
          },
          {
            "category": "20-07-2016",
            "value": 43.1656,
            "value2": 21.76
          },
          {
            "category": "21-07-2016",
            "value": 43.4093,
            "value2": 21.65
          },
          {
            "category": "22-07-2016",
            "value": 43.6065,
            "value2": 21.78
          },
          {
            "category": "23-07-2016",
            "value": 43.6065,
            "value2": 21.78
          },
          {
            "category": "24-07-2016",
            "value": 43.6065,
            "value2": 21.78
          },
          {
            "category": "25-07-2016",
            "value": 44.2769,
            "value2": 22.01
          },
          {
            "category": "26-07-2016",
            "value": 44.691,
            "value2": 21.92
          },
          {
            "category": "27-07-2016",
            "value": 45.0577,
            "value2": 22
          },
          {
            "category": "28-07-2016",
            "value": 45.7927,
            "value2": 22.13
          },
          {
            "category": "29-07-2016",
            "value": 46.6538,
            "value2": 22.11
          },
          {
            "category": "30-07-2016",
            "value": 46.6538,
            "value2": 22.11
          },
          {
            "category": "31-07-2016",
            "value": 46.6525,
            "value2": 22.11
          },
          {
            "category": "01-08-2016",
            "value": 47.2414,
            "value2": 22.12
          },
          {
            "category": "02-08-2016",
            "value": 46.5112,
            "value2": 22.05
          },
          {
            "category": "03-08-2016",
            "value": 45.1836,
            "value2": 21.82
          },
          {
            "category": "04-08-2016",
            "value": 45.0828,
            "value2": 21.86
          },
          {
            "category": "05-08-2016",
            "value": 45.993,
            "value2": 22.19
          },
          {
            "category": "06-08-2016",
            "value": 45.993,
            "value2": 22.19
          },
          {
            "category": "07-08-2016",
            "value": 45.993,
            "value2": 22.19
          },
          {
            "category": "08-08-2016",
            "value": 46.6513,
            "value2": 22.31
          },
          {
            "category": "09-08-2016",
            "value": 46.3172,
            "value2": 22.22
          },
          {
            "category": "10-08-2016",
            "value": 45.5607,
            "value2": 21.95
          },
          {
            "category": "11-08-2016",
            "value": 45.7374,
            "value2": 21.98
          },
          {
            "category": "12-08-2016",
            "value": 45.7855,
            "value2": 22.14
          },
          {
            "category": "13-08-2016",
            "value": 45.7855,
            "value2": 22.14
          },
          {
            "category": "14-08-2016",
            "value": 45.7855,
            "value2": 22.14
          },
          {
            "category": "15-08-2016",
            "value": 45.7855,
            "value2": 22.14
          },
          {
            "category": "16-08-2016",
            "value": 46.0213,
            "value2": 22.13
          },
          {
            "category": "17-08-2016",
            "value": 46.0991,
            "value2": 22.14
          },
          {
            "category": "18-08-2016",
            "value": 46.0649,
            "value2": 22.29
          },
          {
            "category": "19-08-2016",
            "value": 46.2102,
            "value2": 22.31
          },
          {
            "category": "20-08-2016",
            "value": 46.2101,
            "value2": 22.31
          },
          {
            "category": "21-08-2016",
            "value": 46.2101,
            "value2": 22.31
          },
          {
            "category": "22-08-2016",
            "value": 45.8466,
            "value2": 22.22
          },
          {
            "category": "23-08-2016",
            "value": 45.4671,
            "value2": 22.2
          },
          {
            "category": "24-08-2016",
            "value": 45.8769,
            "value2": 22.29
          },
          {
            "category": "25-08-2016",
            "value": 46.0923,
            "value2": 22.17
          },
          {
            "category": "26-08-2016",
            "value": 46.6291,
            "value2": 22.14
          },
          {
            "category": "27-08-2016",
            "value": 46.6291,
            "value2": 22.14
          },
          {
            "category": "28-08-2016",
            "value": 46.6291,
            "value2": 22.14
          },
          {
            "category": "29-08-2016",
            "value": 46.8202,
            "value2": 22.21
          },
          {
            "category": "30-08-2016",
            "value": 47.4854,
            "value2": 22.52
          },
          {
            "category": "31-08-2016",
            "value": 47.6964,
            "value2": 22.61
          },
          {
            "category": "01-09-2016",
            "value": 47.7368,
            "value2": 22.55
          },
          {
            "category": "02-09-2016",
            "value": 47.9854,
            "value2": 22.64
          },
          {
            "category": "03-09-2016",
            "value": 47.9854,
            "value2": 22.64
          },
          {
            "category": "04-09-2016",
            "value": 47.9854,
            "value2": 22.64
          },
          {
            "category": "05-09-2016",
            "value": 47.9854,
            "value2": 22.64
          },
          {
            "category": "06-09-2016",
            "value": 48.5542,
            "value2": 22.98
          },
          {
            "category": "07-09-2016",
            "value": 48.5257,
            "value2": 22.97
          },
          {
            "category": "08-09-2016",
            "value": 49.0155,
            "value2": 23.08
          },
          {
            "category": "09-09-2016",
            "value": 48.7209,
            "value2": 22.88
          },
          {
            "category": "10-09-2016",
            "value": 48.7209,
            "value2": 22.88
          },
          {
            "category": "11-09-2016",
            "value": 48.7209,
            "value2": 22.88
          },
          {
            "category": "12-09-2016",
            "value": 47.5159,
            "value2": 22.42
          },
          {
            "category": "13-09-2016",
            "value": 47.5159,
            "value2": 22.42
          },
          {
            "category": "14-09-2016",
            "value": 47.6357,
            "value2": 22.53
          },
          {
            "category": "15-09-2016",
            "value": 47.5104,
            "value2": 22.54
          },
          {
            "category": "16-09-2016",
            "value": 47.7812,
            "value2": 22.64
          },
          {
            "category": "17-09-2016",
            "value": 47.7813,
            "value2": 22.64
          },
          {
            "category": "18-09-2016",
            "value": 47.7812,
            "value2": 22.64
          },
          {
            "category": "19-09-2016",
            "value": 47.946,
            "value2": 22.7
          },
          {
            "category": "20-09-2016",
            "value": 47.742,
            "value2": 22.64
          },
          {
            "category": "21-09-2016",
            "value": 47.803,
            "value2": 22.64
          },
          {
            "category": "22-09-2016",
            "value": 48.617,
            "value2": 22.89
          },
          {
            "category": "23-09-2016",
            "value": 48.4665,
            "value2": 22.84
          },
          {
            "category": "24-09-2016",
            "value": 48.4665,
            "value2": 22.84
          },
          {
            "category": "25-09-2016",
            "value": 48.4665,
            "value2": 22.84
          },
          {
            "category": "26-09-2016",
            "value": 48.4075,
            "value2": 22.62
          },
          {
            "category": "27-09-2016",
            "value": 48.1198,
            "value2": 22.59
          },
          {
            "category": "28-09-2016",
            "value": 48.6358,
            "value2": 22.73
          },
          {
            "category": "29-09-2016",
            "value": 47.2469,
            "value2": 22.19
          },
          {
            "category": "30-09-2016",
            "value": 47.6415,
            "value2": 22.37
          },
          {
            "category": "01-10-2016",
            "value": 47.6415,
            "value2": 22.37
          },
          {
            "category": "02-10-2016",
            "value": 47.6415,
            "value2": 22.37
          },
          {
            "category": "03-10-2016",
            "value": 48.597,
            "value2": 22.78
          },
          {
            "category": "04-10-2016",
            "value": 48.6997,
            "value2": 22.88
          },
          {
            "category": "05-10-2016",
            "value": 48.7936,
            "value2": 22.88
          },
          {
            "category": "06-10-2016",
            "value": 48.8518,
            "value2": 22.8
          },
          {
            "category": "07-10-2016",
            "value": 48.6,
            "value2": 22.77
          },
          {
            "category": "08-10-2016",
            "value": 48.6,
            "value2": 22.77
          },
          {
            "category": "09-10-2016",
            "value": 48.6,
            "value2": 22.77
          },
          {
            "category": "10-10-2016",
            "value": 48.5428,
            "value2": 22.78
          },
          {
            "category": "11-10-2016",
            "value": 48.5428,
            "value2": 22.78
          },
          {
            "category": "12-10-2016",
            "value": 48.5428,
            "value2": 22.78
          },
          {
            "category": "13-10-2016",
            "value": 47.8406,
            "value2": 22.44
          },
          {
            "category": "14-10-2016",
            "value": 48.1807,
            "value2": 22.51
          },
          {
            "category": "15-10-2016",
            "value": 48.1807,
            "value2": 22.51
          },
          {
            "category": "16-10-2016",
            "value": 48.1806,
            "value2": 22.51
          },
          {
            "category": "17-10-2016",
            "value": 47.9061,
            "value2": 22.34
          },
          {
            "category": "18-10-2016",
            "value": 48.7528,
            "value2": 22.72
          },
          {
            "category": "19-10-2016",
            "value": 48.6905,
            "value2": 22.71
          },
          {
            "category": "20-10-2016",
            "value": 48.7191,
            "value2": 22.81
          },
          {
            "category": "21-10-2016",
            "value": 49.0623,
            "value2": 22.82
          },
          {
            "category": "22-10-2016",
            "value": 49.0623,
            "value2": 22.82
          },
          {
            "category": "23-10-2016",
            "value": 49.0622,
            "value2": 22.82
          },
          {
            "category": "24-10-2016",
            "value": 49.2755,
            "value2": 22.87
          },
          {
            "category": "25-10-2016",
            "value": 49.3412,
            "value2": 22.83
          },
          {
            "category": "26-10-2016",
            "value": 49.1504,
            "value2": 22.64
          },
          {
            "category": "27-10-2016",
            "value": 48.5363,
            "value2": 22.57
          },
          {
            "category": "28-10-2016",
            "value": 48.7183,
            "value2": 22.68
          },
          {
            "category": "29-10-2016",
            "value": 48.7171,
            "value2": 22.68
          },
          {
            "category": "30-10-2016",
            "value": 48.8452,
            "value2": 22.73
          },
          {
            "category": "31-10-2016",
            "value": 48.8433,
            "value2": 22.73
          },
          {
            "category": "01-11-2016",
            "value": 48.6784,
            "value2": 22.7
          },
          {
            "category": "02-11-2016",
            "value": 47.583,
            "value2": 22.37
          },
          {
            "category": "03-11-2016",
            "value": 47.1305,
            "value2": 22.24
          },
          {
            "category": "04-11-2016",
            "value": 46.4934,
            "value2": 22.02
          },
          {
            "category": "05-11-2016",
            "value": 46.4928,
            "value2": 22.02
          },
          {
            "category": "06-11-2016",
            "value": 46.4928,
            "value2": 22.02
          },
          {
            "category": "07-11-2016",
            "value": 46.7263,
            "value2": 22.2
          },
          {
            "category": "08-11-2016",
            "value": 46.9666,
            "value2": 22.29
          },
          {
            "category": "09-11-2016",
            "value": 46.1561,
            "value2": 21.94
          },
          {
            "category": "10-11-2016",
            "value": 46.2945,
            "value2": 22.23
          },
          {
            "category": "11-11-2016",
            "value": 44.4934,
            "value2": 21.57
          },
          {
            "category": "12-11-2016",
            "value": 44.4934,
            "value2": 21.57
          },
          {
            "category": "13-11-2016",
            "value": 44.4934,
            "value2": 21.57
          },
          {
            "category": "14-11-2016",
            "value": 44.4934,
            "value2": 21.57
          },
          {
            "category": "15-11-2016",
            "value": 42.553,
            "value2": 20.93
          },
          {
            "category": "16-11-2016",
            "value": 43.3612,
            "value2": 20.95
          },
          {
            "category": "17-11-2016",
            "value": 42.8395,
            "value2": 20.9
          },
          {
            "category": "18-11-2016",
            "value": 43.5005,
            "value2": 20.91
          },
          {
            "category": "19-11-2016",
            "value": 43.5005,
            "value2": 20.91
          },
          {
            "category": "20-11-2016",
            "value": 43.5005,
            "value2": 20.91
          },
          {
            "category": "21-11-2016",
            "value": 41.9503,
            "value2": 20.47
          },
          {
            "category": "22-11-2016",
            "value": 42.7482,
            "value2": 20.68
          },
          {
            "category": "23-11-2016",
            "value": 42.9619,
            "value2": 20.83
          },
          {
            "category": "24-11-2016",
            "value": 42.5241,
            "value2": 20.72
          },
          {
            "category": "25-11-2016",
            "value": 43.2235,
            "value2": 21.07
          },
          {
            "category": "26-11-2016",
            "value": 43.2235,
            "value2": 21.07
          },
          {
            "category": "27-11-2016",
            "value": 43.2235,
            "value2": 21.07
          },
          {
            "category": "28-11-2016",
            "value": 43.6771,
            "value2": 21.14
          },
          {
            "category": "29-11-2016",
            "value": 44.2717,
            "value2": 21.2
          },
          {
            "category": "30-11-2016",
            "value": 44.5448,
            "value2": 21.43
          },
          {
            "category": "01-12-2016",
            "value": 43.9316,
            "value2": 21.31
          },
          {
            "category": "02-12-2016",
            "value": 43.2286,
            "value2": 21.04
          },
          {
            "category": "03-12-2016",
            "value": 43.2286,
            "value2": 21.04
          },
          {
            "category": "04-12-2016",
            "value": 43.2286,
            "value2": 21.04
          },
          {
            "category": "05-12-2016",
            "value": 43.5458,
            "value2": 21.15
          },
          {
            "category": "06-12-2016",
            "value": 43.5598,
            "value2": 21.2
          },
          {
            "category": "07-12-2016",
            "value": 43.6859,
            "value2": 21.11
          },
          {
            "category": "08-12-2016",
            "value": 44.4442,
            "value2": 21.45
          },
          {
            "category": "09-12-2016",
            "value": 44.2522,
            "value2": 21.49
          },
          {
            "category": "10-12-2016",
            "value": 44.2522,
            "value2": 21.49
          },
          {
            "category": "11-12-2016",
            "value": 44.2517,
            "value2": 21.49
          },
          {
            "category": "12-12-2016",
            "value": 43.2019,
            "value2": 21.27
          },
          {
            "category": "13-12-2016",
            "value": 43.3367,
            "value2": 21.33
          },
          {
            "category": "14-12-2016",
            "value": 42.9959,
            "value2": 21.21
          },
          {
            "category": "15-12-2016",
            "value": 42.9784,
            "value2": 21.17
          },
          {
            "category": "16-12-2016",
            "value": 43.06,
            "value2": 21.12
          },
          {
            "category": "17-12-2016",
            "value": 43.06,
            "value2": 21.12
          },
          {
            "category": "18-12-2016",
            "value": 43.06,
            "value2": 21.12
          },
          {
            "category": "19-12-2016",
            "value": 42.7334,
            "value2": 21.03
          },
          {
            "category": "20-12-2016",
            "value": 42.0866,
            "value2": 20.91
          },
          {
            "category": "21-12-2016",
            "value": 41.9664,
            "value2": 20.88
          },
          {
            "category": "22-12-2016",
            "value": 41.4907,
            "value2": 20.64
          },
          {
            "category": "23-12-2016",
            "value": 41.9387,
            "value2": 20.64
          },
          {
            "category": "24-12-2016",
            "value": 41.9385,
            "value2": 20.64
          },
          {
            "category": "25-12-2016",
            "value": 41.9385,
            "value2": 20.64
          },
          {
            "category": "26-12-2016",
            "value": 41.2952,
            "value2": 20.36
          },
          {
            "category": "27-12-2016",
            "value": 41.8187,
            "value2": 20.68
          },
          {
            "category": "28-12-2016",
            "value": 41.8628,
            "value2": 20.73
          },
          {
            "category": "29-12-2016",
            "value": 42.6367,
            "value2": 20.93
          },
          {
            "category": "30-12-2016",
            "value": 42.904,
            "value2": 21.13
          },
          {
            "category": "31-12-2016",
            "value": 42.902,
            "value2": 21.13
          },
          {
            "category": "01-01-2017",
            "value": 42.902,
            "value2": 21.13
          },
          {
            "category": "02-01-2017",
            "value": 43.4216,
            "value2": 21.2
          },
          {
            "category": "03-01-2017",
            "value": 43.871,
            "value2": 21.29
          },
          {
            "category": "04-01-2017",
            "value": 43.6038,
            "value2": 21.29
          },
          {
            "category": "05-01-2017",
            "value": 44.222,
            "value2": 21.51
          },
          {
            "category": "06-01-2017",
            "value": 43.9626,
            "value2": 21.44
          },
          {
            "category": "07-01-2017",
            "value": 43.9626,
            "value2": 21.44
          },
          {
            "category": "08-01-2017",
            "value": 43.9626,
            "value2": 21.44
          },
          {
            "category": "09-01-2017",
            "value": 43.9752,
            "value2": 21.45
          },
          {
            "category": "10-01-2017",
            "value": 44.5087,
            "value2": 21.58
          },
          {
            "category": "11-01-2017",
            "value": 45.0178,
            "value2": 21.83
          },
          {
            "category": "12-01-2017",
            "value": 44.956,
            "value2": 21.88
          },
          {
            "category": "13-01-2017",
            "value": 44.9958,
            "value2": 21.87
          },
          {
            "category": "14-01-2017",
            "value": 44.9955,
            "value2": 21.87
          },
          {
            "category": "15-01-2017",
            "value": 44.9954,
            "value2": 21.87
          },
          {
            "category": "16-01-2017",
            "value": 45.0686,
            "value2": 21.93
          },
          {
            "category": "17-01-2017",
            "value": 44.8411,
            "value2": 21.92
          },
          {
            "category": "18-01-2017",
            "value": 45.07,
            "value2": 22
          },
          {
            "category": "19-01-2017",
            "value": 45.2613,
            "value2": 22.05
          },
          {
            "category": "20-01-2017",
            "value": 44.9284,
            "value2": 21.8
          },
          {
            "category": "21-01-2017",
            "value": 44.9284,
            "value2": 21.8
          },
          {
            "category": "22-01-2017",
            "value": 44.9284,
            "value2": 21.8
          },
          {
            "category": "23-01-2017",
            "value": 45.3354,
            "value2": 21.92
          },
          {
            "category": "24-01-2017",
            "value": 45.5321,
            "value2": 22.14
          },
          {
            "category": "25-01-2017",
            "value": 46.569,
            "value2": 22.43
          },
          {
            "category": "26-01-2017",
            "value": 46.569,
            "value2": 22.43
          },
          {
            "category": "27-01-2017",
            "value": 46.6639,
            "value2": 22.56
          },
          {
            "category": "28-01-2017",
            "value": 46.6617,
            "value2": 22.56
          },
          {
            "category": "29-01-2017",
            "value": 46.6617,
            "value2": 22.56
          },
          {
            "category": "30-01-2017",
            "value": 46.5189,
            "value2": 22.54
          },
          {
            "category": "31-01-2017",
            "value": 46.1919,
            "value2": 22.33
          },
          {
            "category": "01-02-2017",
            "value": 47.2252,
            "value2": 22.74
          },
          {
            "category": "02-02-2017",
            "value": 47.7478,
            "value2": 22.84
          },
          {
            "category": "03-02-2017",
            "value": 47.5457,
            "value2": 22.9
          },
          {
            "category": "04-02-2017",
            "value": 47.5404,
            "value2": 22.9
          },
          {
            "category": "05-02-2017",
            "value": 47.5404,
            "value2": 22.9
          },
          {
            "category": "06-02-2017",
            "value": 48.2387,
            "value2": 23.08
          },
          {
            "category": "07-02-2017",
            "value": 48.1548,
            "value2": 23.01
          },
          {
            "category": "08-02-2017",
            "value": 48.4683,
            "value2": 23.05
          },
          {
            "category": "09-02-2017",
            "value": 48.603,
            "value2": 23.09
          },
          {
            "category": "10-02-2017",
            "value": 48.842,
            "value2": 23.1
          },
          {
            "category": "11-02-2017",
            "value": 48.842,
            "value2": 23.1
          },
          {
            "category": "12-02-2017",
            "value": 48.842,
            "value2": 23.1
          },
          {
            "category": "13-02-2017",
            "value": 48.8199,
            "value2": 23.09
          },
          {
            "category": "14-02-2017",
            "value": 48.3317,
            "value2": 23.03
          },
          {
            "category": "15-02-2017",
            "value": 47.7086,
            "value2": 22.8
          },
          {
            "category": "16-02-2017",
            "value": 48.2319,
            "value2": 23.01
          },
          {
            "category": "17-02-2017",
            "value": 48.3444,
            "value2": 23.13
          },
          {
            "category": "18-02-2017",
            "value": 48.3443,
            "value2": 23.13
          },
          {
            "category": "19-02-2017",
            "value": 48.3443,
            "value2": 23.13
          },
          {
            "category": "20-02-2017",
            "value": 48.8769,
            "value2": 23.29
          },
          {
            "category": "21-02-2017",
            "value": 48.9671,
            "value2": 23.4
          },
          {
            "category": "22-02-2017",
            "value": 48.4469,
            "value2": 23.39
          },
          {
            "category": "23-02-2017",
            "value": 48.8213,
            "value2": 23.41
          },
          {
            "category": "24-02-2017",
            "value": 48.8213,
            "value2": 23.41
          },
          {
            "category": "25-02-2017",
            "value": 48.8214,
            "value2": 23.41
          },
          {
            "category": "26-02-2017",
            "value": 48.8214,
            "value2": 23.41
          },
          {
            "category": "27-02-2017",
            "value": 48.6157,
            "value2": 23.34
          },
          {
            "category": "28-02-2017",
            "value": 48.3211,
            "value2": 23.35
          },
          {
            "category": "01-03-2017",
            "value": 48.2399,
            "value2": 23.49
          },
          {
            "category": "02-03-2017",
            "value": 47.6853,
            "value2": 23.29
          },
          {
            "category": "03-03-2017",
            "value": 47.8065,
            "value2": 23.32
          },
          {
            "category": "04-03-2017",
            "value": 47.8061,
            "value2": 23.32
          },
          {
            "category": "05-03-2017",
            "value": 47.8061,
            "value2": 23.32
          },
          {
            "category": "06-03-2017",
            "value": 47.9567,
            "value2": 23.46
          },
          {
            "category": "07-03-2017",
            "value": 48.0238,
            "value2": 23.44
          },
          {
            "category": "08-03-2017",
            "value": 47.8959,
            "value2": 23.36
          },
          {
            "category": "09-03-2017",
            "value": 47.8672,
            "value2": 23.36
          },
          {
            "category": "10-03-2017",
            "value": 47.8815,
            "value2": 23.36
          },
          {
            "category": "11-03-2017",
            "value": 47.8816,
            "value2": 23.36
          },
          {
            "category": "12-03-2017",
            "value": 47.8816,
            "value2": 23.36
          },
          {
            "category": "13-03-2017",
            "value": 47.8816,
            "value2": 23.36
          },
          {
            "category": "14-03-2017",
            "value": 48.3722,
            "value2": 23.74
          },
          {
            "category": "15-03-2017",
            "value": 48.8759,
            "value2": 23.8
          },
          {
            "category": "16-03-2017",
            "value": 49.3724,
            "value2": 24.03
          },
          {
            "category": "17-03-2017",
            "value": 49.2355,
            "value2": 24.04
          },
          {
            "category": "18-03-2017",
            "value": 49.2355,
            "value2": 24.04
          },
          {
            "category": "19-03-2017",
            "value": 49.2355,
            "value2": 24.04
          },
          {
            "category": "20-03-2017",
            "value": 49.3282,
            "value2": 24.01
          },
          {
            "category": "21-03-2017",
            "value": 49.2112,
            "value2": 23.97
          },
          {
            "category": "22-03-2017",
            "value": 49.0884,
            "value2": 23.74
          },
          {
            "category": "23-03-2017",
            "value": 49.5259,
            "value2": 23.91
          },
          {
            "category": "24-03-2017",
            "value": 49.6182,
            "value2": 23.97
          },
          {
            "category": "25-03-2017",
            "value": 49.6182,
            "value2": 23.97
          },
          {
            "category": "26-03-2017",
            "value": 49.6182,
            "value2": 23.97
          },
          {
            "category": "27-03-2017",
            "value": 49.6091,
            "value2": 23.86
          },
          {
            "category": "28-03-2017",
            "value": 49.8339,
            "value2": 24
          },
          {
            "category": "29-03-2017",
            "value": 49.8727,
            "value2": 24.09
          },
          {
            "category": "30-03-2017",
            "value": 49.9529,
            "value2": 24.2
          },
          {
            "category": "31-03-2017",
            "value": 50.1388,
            "value2": 24.26
          },
          {
            "category": "01-04-2017",
            "value": 50.1395,
            "value2": 24.26
          },
          {
            "category": "02-04-2017",
            "value": 50.1395,
            "value2": 24.26
          },
          {
            "category": "03-04-2017",
            "value": 50.1385,
            "value2": 24.44
          },
          {
            "category": "04-04-2017",
            "value": 50.1385,
            "value2": 24.44
          },
          {
            "category": "05-04-2017",
            "value": 50.6,
            "value2": 24.56
          },
          {
            "category": "06-04-2017",
            "value": 50.6951,
            "value2": 24.55
          },
          {
            "category": "07-04-2017",
            "value": 50.9095,
            "value2": 24.41
          },
          {
            "category": "08-04-2017",
            "value": 50.9093,
            "value2": 24.41
          },
          {
            "category": "09-04-2017",
            "value": 50.9093,
            "value2": 24.41
          },
          {
            "category": "10-04-2017",
            "value": 51.2138,
            "value2": 24.44
          },
          {
            "category": "11-04-2017",
            "value": 51.3676,
            "value2": 24.61
          },
          {
            "category": "12-04-2017",
            "value": 51.4166,
            "value2": 24.53
          },
          {
            "category": "13-04-2017",
            "value": 51.5473,
            "value2": 24.42
          },
          {
            "category": "14-04-2017",
            "value": 51.5473,
            "value2": 24.42
          },
          {
            "category": "15-04-2017",
            "value": 51.5473,
            "value2": 24.42
          },
          {
            "category": "16-04-2017",
            "value": 51.5473,
            "value2": 24.42
          },
          {
            "category": "17-04-2017",
            "value": 51.3483,
            "value2": 24.41
          },
          {
            "category": "18-04-2017",
            "value": 50.8012,
            "value2": 24.29
          },
          {
            "category": "19-04-2017",
            "value": 50.9889,
            "value2": 24.35
          },
          {
            "category": "20-04-2017",
            "value": 51.2209,
            "value2": 24.48
          },
          {
            "category": "21-04-2017",
            "value": 51.0936,
            "value2": 24.46
          },
          {
            "category": "22-04-2017",
            "value": 51.0931,
            "value2": 24.46
          },
          {
            "category": "23-04-2017",
            "value": 51.0931,
            "value2": 24.46
          },
          {
            "category": "24-04-2017",
            "value": 51.4895,
            "value2": 24.7
          },
          {
            "category": "25-04-2017",
            "value": 51.9405,
            "value2": 24.94
          },
          {
            "category": "26-04-2017",
            "value": 51.5888,
            "value2": 24.99
          },
          {
            "category": "27-04-2017",
            "value": 51.7104,
            "value2": 24.96
          },
          {
            "category": "28-04-2017",
            "value": 51.8363,
            "value2": 24.94
          },
          {
            "category": "29-04-2017",
            "value": 51.8363,
            "value2": 24.94
          },
          {
            "category": "30-04-2017",
            "value": 51.8342,
            "value2": 24.94
          },
          {
            "category": "01-05-2017",
            "value": 51.7192,
            "value2": 24.94
          },
          {
            "category": "02-05-2017",
            "value": 52.1371,
            "value2": 24.99
          },
          {
            "category": "03-05-2017",
            "value": 52.0286,
            "value2": 24.97
          },
          {
            "category": "04-05-2017",
            "value": 52.0316,
            "value2": 25.1
          },
          {
            "category": "05-05-2017",
            "value": 51.46,
            "value2": 24.87
          },
          {
            "category": "06-05-2017",
            "value": 51.46,
            "value2": 24.87
          },
          {
            "category": "07-05-2017",
            "value": 51.46,
            "value2": 24.87
          },
          {
            "category": "08-05-2017",
            "value": 51.8245,
            "value2": 24.98
          },
          {
            "category": "09-05-2017",
            "value": 52.0064,
            "value2": 25.03
          },
          {
            "category": "10-05-2017",
            "value": 52.698,
            "value2": 25.27
          },
          {
            "category": "11-05-2017",
            "value": 53.1473,
            "value2": 25.3
          },
          {
            "category": "12-05-2017",
            "value": 53.0095,
            "value2": 25.2
          },
          {
            "category": "13-05-2017",
            "value": 53.0095,
            "value2": 25.2
          },
          {
            "category": "14-05-2017",
            "value": 53.0095,
            "value2": 25.2
          },
          {
            "category": "15-05-2017",
            "value": 53.7531,
            "value2": 25.35
          },
          {
            "category": "16-05-2017",
            "value": 53.7876,
            "value2": 25.5
          },
          {
            "category": "17-05-2017",
            "value": 53.4212,
            "value2": 25.5
          },
          {
            "category": "18-05-2017",
            "value": 52.4434,
            "value2": 25.14
          },
          {
            "category": "19-05-2017",
            "value": 52.0212,
            "value2": 25.08
          },
          {
            "category": "20-05-2017",
            "value": 52.0212,
            "value2": 25.08
          },
          {
            "category": "21-05-2017",
            "value": 52.0212,
            "value2": 25.08
          },
          {
            "category": "22-05-2017",
            "value": 51.3257,
            "value2": 25.01
          },
          {
            "category": "23-05-2017",
            "value": 50.9888,
            "value2": 24.78
          },
          {
            "category": "24-05-2017",
            "value": 50.736,
            "value2": 24.62
          },
          {
            "category": "25-05-2017",
            "value": 51.6382,
            "value2": 25
          },
          {
            "category": "26-05-2017",
            "value": 52.9161,
            "value2": 25.3
          },
          {
            "category": "27-05-2017",
            "value": 52.9161,
            "value2": 25.3
          },
          {
            "category": "28-05-2017",
            "value": 52.9161,
            "value2": 25.3
          },
          {
            "category": "29-05-2017",
            "value": 52.2001,
            "value2": 25.2
          },
          {
            "category": "30-05-2017",
            "value": 52.6101,
            "value2": 25.29
          },
          {
            "category": "31-05-2017",
            "value": 52.9718,
            "value2": 25.37
          },
          {
            "category": "01-06-2017",
            "value": 53.5828,
            "value2": 25.43
          },
          {
            "category": "02-06-2017",
            "value": 53.8084,
            "value2": 25.56
          },
          {
            "category": "03-06-2017",
            "value": 53.8081,
            "value2": 25.56
          },
          {
            "category": "04-06-2017",
            "value": 53.8081,
            "value2": 25.56
          },
          {
            "category": "05-06-2017",
            "value": 53.7511,
            "value2": 25.66
          },
          {
            "category": "06-06-2017",
            "value": 53.4063,
            "value2": 25.54
          },
          {
            "category": "07-06-2017",
            "value": 53.6554,
            "value2": 25.64
          },
          {
            "category": "08-06-2017",
            "value": 54.0035,
            "value2": 25.64
          },
          {
            "category": "09-06-2017",
            "value": 54.2449,
            "value2": 25.7
          },
          {
            "category": "10-06-2017",
            "value": 54.2449,
            "value2": 25.7
          },
          {
            "category": "11-06-2017",
            "value": 54.2449,
            "value2": 25.7
          },
          {
            "category": "12-06-2017",
            "value": 53.8481,
            "value2": 25.57
          },
          {
            "category": "13-06-2017",
            "value": 53.9262,
            "value2": 25.58
          },
          {
            "category": "14-06-2017",
            "value": 54.4214,
            "value2": 25.61
          },
          {
            "category": "15-06-2017",
            "value": 54.2204,
            "value2": 25.57
          },
          {
            "category": "16-06-2017",
            "value": 54.4475,
            "value2": 25.59
          },
          {
            "category": "17-06-2017",
            "value": 54.4475,
            "value2": 25.59
          },
          {
            "category": "18-06-2017",
            "value": 54.4475,
            "value2": 25.59
          },
          {
            "category": "19-06-2017",
            "value": 54.3953,
            "value2": 25.71
          },
          {
            "category": "20-06-2017",
            "value": 54.2182,
            "value2": 25.74
          },
          {
            "category": "21-06-2017",
            "value": 54.1205,
            "value2": 25.7
          },
          {
            "category": "22-06-2017",
            "value": 53.8269,
            "value2": 25.65
          },
          {
            "category": "23-06-2017",
            "value": 53.1378,
            "value2": 25.44
          },
          {
            "category": "24-06-2017",
            "value": 53.1378,
            "value2": 25.44
          },
          {
            "category": "25-06-2017",
            "value": 53.1378,
            "value2": 25.44
          },
          {
            "category": "26-06-2017",
            "value": 53.1378,
            "value2": 25.44
          },
          {
            "category": "27-06-2017",
            "value": 52.5071,
            "value2": 25.24
          },
          {
            "category": "28-06-2017",
            "value": 52.7219,
            "value2": 25.22
          },
          {
            "category": "29-06-2017",
            "value": 52.6718,
            "value2": 25.31
          },
          {
            "category": "30-06-2017",
            "value": 52.8805,
            "value2": 25.4
          },
          {
            "category": "01-07-2017",
            "value": 52.8805,
            "value2": 25.4
          },
          {
            "category": "02-07-2017",
            "value": 52.8805,
            "value2": 25.4
          },
          {
            "category": "03-07-2017",
            "value": 53.3728,
            "value2": 25.66
          },
          {
            "category": "04-07-2017",
            "value": 53.3361,
            "value2": 25.64
          },
          {
            "category": "05-07-2017",
            "value": 53.6968,
            "value2": 25.77
          },
          {
            "category": "06-07-2017",
            "value": 53.503,
            "value2": 25.86
          },
          {
            "category": "07-07-2017",
            "value": 53.2685,
            "value2": 25.86
          },
          {
            "category": "08-07-2017",
            "value": 53.2685,
            "value2": 25.86
          },
          {
            "category": "09-07-2017",
            "value": 53.2685,
            "value2": 25.86
          },
          {
            "category": "10-07-2017",
            "value": 53.5017,
            "value2": 26.1
          },
          {
            "category": "11-07-2017",
            "value": 53.3072,
            "value2": 26.06
          },
          {
            "category": "12-07-2017",
            "value": 53.7435,
            "value2": 26.18
          },
          {
            "category": "13-07-2017",
            "value": 54.2524,
            "value2": 26.37
          },
          {
            "category": "14-07-2017",
            "value": 54.481,
            "value2": 26.37
          },
          {
            "category": "15-07-2017",
            "value": 54.481,
            "value2": 26.37
          },
          {
            "category": "16-07-2017",
            "value": 54.481,
            "value2": 26.37
          },
          {
            "category": "17-07-2017",
            "value": 54.5429,
            "value2": 26.43
          },
          {
            "category": "18-07-2017",
            "value": 54.256,
            "value2": 26.22
          },
          {
            "category": "19-07-2017",
            "value": 54.8646,
            "value2": 26.45
          },
          {
            "category": "20-07-2017",
            "value": 54.8771,
            "value2": 26.39
          },
          {
            "category": "21-07-2017",
            "value": 55.0832,
            "value2": 26.47
          },
          {
            "category": "22-07-2017",
            "value": 55.0832,
            "value2": 26.47
          },
          {
            "category": "23-07-2017",
            "value": 55.0832,
            "value2": 26.47
          },
          {
            "category": "24-07-2017",
            "value": 55.143,
            "value2": 26.58
          },
          {
            "category": "25-07-2017",
            "value": 55.323,
            "value2": 26.61
          },
          {
            "category": "26-07-2017",
            "value": 55.4484,
            "value2": 26.72
          },
          {
            "category": "27-07-2017",
            "value": 55.4991,
            "value2": 26.7
          },
          {
            "category": "28-07-2017",
            "value": 55.6216,
            "value2": 26.72
          },
          {
            "category": "29-07-2017",
            "value": 55.6216,
            "value2": 26.72
          },
          {
            "category": "30-07-2017",
            "value": 55.6216,
            "value2": 26.72
          },
          {
            "category": "31-07-2017",
            "value": 55.8375,
            "value2": 26.85
          },
          {
            "category": "01-08-2017",
            "value": 56.373,
            "value2": 26.91
          },
          {
            "category": "02-08-2017",
            "value": 56.3771,
            "value2": 26.85
          },
          {
            "category": "03-08-2017",
            "value": 56.0832,
            "value2": 26.69
          },
          {
            "category": "04-08-2017",
            "value": 56.4219,
            "value2": 26.82
          },
          {
            "category": "05-08-2017",
            "value": 56.4219,
            "value2": 26.82
          },
          {
            "category": "06-08-2017",
            "value": 56.4219,
            "value2": 26.82
          },
          {
            "category": "07-08-2017",
            "value": 56.8684,
            "value2": 26.92
          },
          {
            "category": "08-08-2017",
            "value": 56.3568,
            "value2": 26.67
          },
          {
            "category": "09-08-2017",
            "value": 55.3587,
            "value2": 26.4
          },
          {
            "category": "10-08-2017",
            "value": 54.1037,
            "value2": 25.98
          },
          {
            "category": "11-08-2017",
            "value": 54.0123,
            "value2": 25.79
          },
          {
            "category": "12-08-2017",
            "value": 54.0123,
            "value2": 25.79
          },
          {
            "category": "13-08-2017",
            "value": 54.0123,
            "value2": 25.79
          },
          {
            "category": "14-08-2017",
            "value": 54.963,
            "value2": 26.13
          },
          {
            "category": "15-08-2017",
            "value": 54.963,
            "value2": 26.13
          },
          {
            "category": "16-08-2017",
            "value": 55.6263,
            "value2": 26.41
          },
          {
            "category": "17-08-2017",
            "value": 55.4021,
            "value2": 26.44
          },
          {
            "category": "18-08-2017",
            "value": 55.3151,
            "value2": 26.29
          },
          {
            "category": "19-08-2017",
            "value": 55.3151,
            "value2": 26.29
          },
          {
            "category": "20-08-2017",
            "value": 55.3151,
            "value2": 26.29
          },
          {
            "category": "21-08-2017",
            "value": 54.8842,
            "value2": 26.03
          },
          {
            "category": "22-08-2017",
            "value": 54.8727,
            "value2": 26.01
          },
          {
            "category": "23-08-2017",
            "value": 55.3318,
            "value2": 26.27
          },
          {
            "category": "24-08-2017",
            "value": 55.2824,
            "value2": 26.34
          },
          {
            "category": "25-08-2017",
            "value": 55.2824,
            "value2": 26.34
          },
          {
            "category": "26-08-2017",
            "value": 55.2824,
            "value2": 26.34
          },
          {
            "category": "27-08-2017",
            "value": 55.2824,
            "value2": 26.34
          },
          {
            "category": "28-08-2017",
            "value": 55.9316,
            "value2": 26.54
          },
          {
            "category": "29-08-2017",
            "value": 55.2463,
            "value2": 26.25
          },
          {
            "category": "30-08-2017",
            "value": 55.97,
            "value2": 26.53
          },
          {
            "category": "31-08-2017",
            "value": 55.8462,
            "value2": 26.62
          },
          {
            "category": "01-09-2017",
            "value": 56.3217,
            "value2": 26.8
          },
          {
            "category": "02-09-2017",
            "value": 56.3128,
            "value2": 26.8
          },
          {
            "category": "03-09-2017",
            "value": 56.3128,
            "value2": 26.8
          },
          {
            "category": "04-09-2017",
            "value": 56.0877,
            "value2": 26.63
          },
          {
            "category": "05-09-2017",
            "value": 56.3077,
            "value2": 26.78
          },
          {
            "category": "06-09-2017",
            "value": 56.5479,
            "value2": 26.73
          },
          {
            "category": "07-09-2017",
            "value": 57.2864,
            "value2": 26.82
          },
          {
            "category": "08-09-2017",
            "value": 57.171,
            "value2": 26.79
          },
          {
            "category": "09-09-2017",
            "value": 57.171,
            "value2": 26.79
          },
          {
            "category": "10-09-2017",
            "value": 57.171,
            "value2": 26.79
          },
          {
            "category": "11-09-2017",
            "value": 57.5705,
            "value2": 27
          },
          {
            "category": "12-09-2017",
            "value": 58.3597,
            "value2": 27.24
          },
          {
            "category": "13-09-2017",
            "value": 57.8066,
            "value2": 27.17
          },
          {
            "category": "14-09-2017",
            "value": 57.8287,
            "value2": 27.24
          },
          {
            "category": "15-09-2017",
            "value": 57.8128,
            "value2": 27.25
          },
          {
            "category": "16-09-2017",
            "value": 57.8128,
            "value2": 27.25
          },
          {
            "category": "17-09-2017",
            "value": 57.8128,
            "value2": 27.25
          },
          {
            "category": "18-09-2017",
            "value": 58.3564,
            "value2": 27.42
          },
          {
            "category": "19-09-2017",
            "value": 58.552,
            "value2": 27.44
          },
          {
            "category": "20-09-2017",
            "value": 58.0963,
            "value2": 27.4
          },
          {
            "category": "21-09-2017",
            "value": 58.015,
            "value2": 27.31
          },
          {
            "category": "22-09-2017",
            "value": 56.8162,
            "value2": 26.78
          },
          {
            "category": "23-09-2017",
            "value": 56.8162,
            "value2": 26.78
          },
          {
            "category": "24-09-2017",
            "value": 56.8162,
            "value2": 26.78
          },
          {
            "category": "25-09-2017",
            "value": 55.7941,
            "value2": 26.47
          },
          {
            "category": "26-09-2017",
            "value": 56.1374,
            "value2": 26.52
          },
          {
            "category": "27-09-2017",
            "value": 55.1786,
            "value2": 26.11
          },
          {
            "category": "28-09-2017",
            "value": 55.3953,
            "value2": 26.23
          },
          {
            "category": "29-09-2017",
            "value": 55.8769,
            "value2": 26.35
          },
          {
            "category": "30-09-2017",
            "value": 55.7444,
            "value2": 26.35
          },
          {
            "category": "01-10-2017",
            "value": 55.7444,
            "value2": 26.35
          },
          {
            "category": "02-10-2017",
            "value": 55.7444,
            "value2": 26.35
          },
          {
            "category": "03-10-2017",
            "value": 56.1418,
            "value2": 26.55
          },
          {
            "category": "04-10-2017",
            "value": 56.5111,
            "value2": 26.69
          },
          {
            "category": "05-10-2017",
            "value": 56.377,
            "value2": 26.69
          },
          {
            "category": "06-10-2017",
            "value": 57.2007,
            "value2": 26.93
          },
          {
            "category": "07-10-2017",
            "value": 57.2007,
            "value2": 26.93
          },
          {
            "category": "08-10-2017",
            "value": 57.2007,
            "value2": 26.93
          },
          {
            "category": "09-10-2017",
            "value": 57.1952,
            "value2": 26.97
          },
          {
            "category": "10-10-2017",
            "value": 57.1776,
            "value2": 27.08
          },
          {
            "category": "11-10-2017",
            "value": 56.8491,
            "value2": 26.95
          },
          {
            "category": "12-10-2017",
            "value": 57.325,
            "value2": 27.24
          },
          {
            "category": "13-10-2017",
            "value": 57.5006,
            "value2": 27.38
          },
          {
            "category": "14-10-2017",
            "value": 57.5006,
            "value2": 27.38
          },
          {
            "category": "15-10-2017",
            "value": 57.5006,
            "value2": 27.38
          },
          {
            "category": "16-10-2017",
            "value": 57.5085,
            "value2": 27.53
          },
          {
            "category": "17-10-2017",
            "value": 57.5093,
            "value2": 27.58
          },
          {
            "category": "18-10-2017",
            "value": 57.3243,
            "value2": 27.53
          },
          {
            "category": "19-10-2017",
            "value": 56.9317,
            "value2": 27.4
          },
          {
            "category": "20-10-2017",
            "value": 56.9317,
            "value2": 27.4
          },
          {
            "category": "21-10-2017",
            "value": 56.9317,
            "value2": 27.4
          },
          {
            "category": "22-10-2017",
            "value": 56.9317,
            "value2": 27.4
          },
          {
            "category": "23-10-2017",
            "value": 57.1889,
            "value2": 27.48
          },
          {
            "category": "24-10-2017",
            "value": 57.3271,
            "value2": 27.56
          },
          {
            "category": "25-10-2017",
            "value": 56.9543,
            "value2": 27.78
          },
          {
            "category": "26-10-2017",
            "value": 57.1279,
            "value2": 27.91
          },
          {
            "category": "27-10-2017",
            "value": 57.6454,
            "value2": 27.89
          },
          {
            "category": "28-10-2017",
            "value": 57.6454,
            "value2": 27.89
          },
          {
            "category": "29-10-2017",
            "value": 57.6454,
            "value2": 27.89
          },
          {
            "category": "30-10-2017",
            "value": 58.1703,
            "value2": 28.06
          },
          {
            "category": "31-10-2017",
            "value": 58.0278,
            "value2": 28.06
          },
          {
            "category": "01-11-2017",
            "value": 57.734,
            "value2": 28.3
          },
          {
            "category": "02-11-2017",
            "value": 57.6525,
            "value2": 28.32
          },
          {
            "category": "03-11-2017",
            "value": 57.8033,
            "value2": 28.4
          },
          {
            "category": "04-11-2017",
            "value": 57.8033,
            "value2": 28.4
          },
          {
            "category": "05-11-2017",
            "value": 57.8033,
            "value2": 28.4
          },
          {
            "category": "06-11-2017",
            "value": 57.6617,
            "value2": 28.45
          },
          {
            "category": "07-11-2017",
            "value": 57.3709,
            "value2": 28.13
          },
          {
            "category": "08-11-2017",
            "value": 57.3649,
            "value2": 27.97
          },
          {
            "category": "09-11-2017",
            "value": 57.9042,
            "value2": 28.06
          },
          {
            "category": "10-11-2017",
            "value": 57.765,
            "value2": 28.07
          },
          {
            "category": "11-11-2017",
            "value": 57.7649,
            "value2": 28.07
          },
          {
            "category": "12-11-2017",
            "value": 57.7649,
            "value2": 28.07
          },
          {
            "category": "13-11-2017",
            "value": 57.7095,
            "value2": 27.86
          },
          {
            "category": "14-11-2017",
            "value": 57.6771,
            "value2": 27.76
          },
          {
            "category": "15-11-2017",
            "value": 57.4602,
            "value2": 27.52
          },
          {
            "category": "16-11-2017",
            "value": 58.309,
            "value2": 27.8
          },
          {
            "category": "17-11-2017",
            "value": 58.4507,
            "value2": 28.01
          },
          {
            "category": "18-11-2017",
            "value": 58.4507,
            "value2": 28.01
          },
          {
            "category": "19-11-2017",
            "value": 58.4507,
            "value2": 28.01
          },
          {
            "category": "20-11-2017",
            "value": 58.8939,
            "value2": 28.09
          },
          {
            "category": "21-11-2017",
            "value": 58.7441,
            "value2": 28.16
          },
          {
            "category": "22-11-2017",
            "value": 58.3774,
            "value2": 28.2
          },
          {
            "category": "23-11-2017",
            "value": 58.229,
            "value2": 28.24
          },
          {
            "category": "24-11-2017",
            "value": 58.5668,
            "value2": 28.35
          },
          {
            "category": "25-11-2017",
            "value": 58.5666,
            "value2": 28.35
          },
          {
            "category": "26-11-2017",
            "value": 58.5666,
            "value2": 28.35
          },
          {
            "category": "27-11-2017",
            "value": 58.9444,
            "value2": 28.41
          },
          {
            "category": "28-11-2017",
            "value": 58.7771,
            "value2": 28.37
          },
          {
            "category": "29-11-2017",
            "value": 58.7343,
            "value2": 28.35
          },
          {
            "category": "30-11-2017",
            "value": 58.286,
            "value2": 28.09
          },
          {
            "category": "01-12-2017",
            "value": 57.7177,
            "value2": 27.78
          },
          {
            "category": "02-12-2017",
            "value": 57.7181,
            "value2": 27.78
          },
          {
            "category": "03-12-2017",
            "value": 57.7181,
            "value2": 27.78
          },
          {
            "category": "04-12-2017",
            "value": 57.5744,
            "value2": 27.78
          },
          {
            "category": "05-12-2017",
            "value": 57.9407,
            "value2": 27.76
          },
          {
            "category": "06-12-2017",
            "value": 57.4239,
            "value2": 27.55
          },
          {
            "category": "07-12-2017",
            "value": 58.1951,
            "value2": 27.9
          },
          {
            "category": "08-12-2017",
            "value": 58.4758,
            "value2": 28.17
          },
          {
            "category": "09-12-2017",
            "value": 58.4758,
            "value2": 28.17
          },
          {
            "category": "10-12-2017",
            "value": 58.4758,
            "value2": 28.17
          },
          {
            "category": "11-12-2017",
            "value": 58.8867,
            "value2": 28.29
          },
          {
            "category": "12-12-2017",
            "value": 58.4801,
            "value2": 28.08
          },
          {
            "category": "13-12-2017",
            "value": 58.21,
            "value2": 27.9
          },
          {
            "category": "14-12-2017",
            "value": 58.2309,
            "value2": 28.01
          },
          {
            "category": "15-12-2017",
            "value": 59.0449,
            "value2": 28.27
          },
          {
            "category": "16-12-2017",
            "value": 59.0449,
            "value2": 28.27
          },
          {
            "category": "17-12-2017",
            "value": 59.0449,
            "value2": 28.27
          },
          {
            "category": "18-12-2017",
            "value": 59.6726,
            "value2": 28.41
          },
          {
            "category": "19-12-2017",
            "value": 60.4038,
            "value2": 28.69
          },
          {
            "category": "20-12-2017",
            "value": 60.2551,
            "value2": 28.7
          },
          {
            "category": "21-12-2017",
            "value": 60.5742,
            "value2": 28.77
          },
          {
            "category": "22-12-2017",
            "value": 60.7134,
            "value2": 28.89
          },
          {
            "category": "23-12-2017",
            "value": 60.7134,
            "value2": 28.89
          },
          {
            "category": "24-12-2017",
            "value": 60.7134,
            "value2": 28.89
          },
          {
            "category": "25-12-2017",
            "value": 60.7134,
            "value2": 28.89
          },
          {
            "category": "26-12-2017",
            "value": 60.9233,
            "value2": 29
          },
          {
            "category": "27-12-2017",
            "value": 60.919,
            "value2": 28.92
          },
          {
            "category": "28-12-2017",
            "value": 60.9471,
            "value2": 28.91
          },
          {
            "category": "29-12-2017",
            "value": 61.1181,
            "value2": 29.07
          },
          {
            "category": "30-12-2017",
            "value": 61.1173,
            "value2": 29.07
          },
          {
            "category": "31-12-2017",
            "value": 60.9762,
            "value2": 29.07
          },
          {
            "category": "01-01-2018",
            "value": 60.6127,
            "value2": 28.94
          },
          {
            "category": "02-01-2018",
            "value": 60.0635,
            "value2": 28.87
          },
          {
            "category": "03-01-2018",
            "value": 60.3027,
            "value2": 28.94
          },
          {
            "category": "04-01-2018",
            "value": 60.5514,
            "value2": 29.13
          },
          {
            "category": "05-01-2018",
            "value": 60.8772,
            "value2": 29.34
          },
          {
            "category": "06-01-2018",
            "value": 60.8779,
            "value2": 29.34
          },
          {
            "category": "07-01-2018",
            "value": 60.8779,
            "value2": 29.34
          },
          {
            "category": "08-01-2018",
            "value": 60.9966,
            "value2": 29.54
          },
          {
            "category": "09-01-2018",
            "value": 60.6189,
            "value2": 29.55
          },
          {
            "category": "10-01-2018",
            "value": 60.2127,
            "value2": 29.52
          },
          {
            "category": "11-01-2018",
            "value": 60.2007,
            "value2": 29.59
          },
          {
            "category": "12-01-2018",
            "value": 60.1689,
            "value2": 29.62
          },
          {
            "category": "13-01-2018",
            "value": 60.1689,
            "value2": 29.62
          },
          {
            "category": "14-01-2018",
            "value": 60.1689,
            "value2": 29.62
          },
          {
            "category": "15-01-2018",
            "value": 60.0608,
            "value2": 29.73
          },
          {
            "category": "16-01-2018",
            "value": 59.1807,
            "value2": 29.48
          },
          {
            "category": "17-01-2018",
            "value": 59.2893,
            "value2": 29.7
          },
          {
            "category": "18-01-2018",
            "value": 58.7348,
            "value2": 29.57
          },
          {
            "category": "19-01-2018",
            "value": 59.2476,
            "value2": 29.8
          },
          {
            "category": "20-01-2018",
            "value": 59.2473,
            "value2": 29.8
          },
          {
            "category": "21-01-2018",
            "value": 59.2473,
            "value2": 29.8
          },
          {
            "category": "22-01-2018",
            "value": 59.3675,
            "value2": 30.01
          },
          {
            "category": "23-01-2018",
            "value": 59.0998,
            "value2": 30.27
          },
          {
            "category": "24-01-2018",
            "value": 59.0655,
            "value2": 30.22
          },
          {
            "category": "25-01-2018",
            "value": 59.0554,
            "value2": 30.07
          },
          {
            "category": "26-01-2018",
            "value": 59.0552,
            "value2": 30.07
          },
          {
            "category": "27-01-2018",
            "value": 59.0554,
            "value2": 30.07
          },
          {
            "category": "28-01-2018",
            "value": 59.0554,
            "value2": 30.07
          },
          {
            "category": "29-01-2018",
            "value": 58.9719,
            "value2": 30.11
          },
          {
            "category": "30-01-2018",
            "value": 58.142,
            "value2": 29.88
          },
          {
            "category": "31-01-2018",
            "value": 57.8806,
            "value2": 29.75
          },
          {
            "category": "01-02-2018",
            "value": 58.6141,
            "value2": 29.72
          },
          {
            "category": "02-02-2018",
            "value": 56.5365,
            "value2": 28.86
          },
          {
            "category": "03-02-2018",
            "value": 56.5365,
            "value2": 28.86
          },
          {
            "category": "04-02-2018",
            "value": 56.5365,
            "value2": 28.86
          },
          {
            "category": "05-02-2018",
            "value": 56.1817,
            "value2": 28.68
          },
          {
            "category": "06-02-2018",
            "value": 55.8853,
            "value2": 28.19
          },
          {
            "category": "07-02-2018",
            "value": 56.174,
            "value2": 28.23
          },
          {
            "category": "08-02-2018",
            "value": 57.1173,
            "value2": 28.62
          },
          {
            "category": "09-02-2018",
            "value": 56.8543,
            "value2": 28.42
          },
          {
            "category": "10-02-2018",
            "value": 56.8543,
            "value2": 28.42
          },
          {
            "category": "11-02-2018",
            "value": 56.8543,
            "value2": 28.42
          },
          {
            "category": "12-02-2018",
            "value": 57.5843,
            "value2": 28.7
          },
          {
            "category": "13-02-2018",
            "value": 57.5843,
            "value2": 28.7
          },
          {
            "category": "14-02-2018",
            "value": 57.7823,
            "value2": 28.63
          },
          {
            "category": "15-02-2018",
            "value": 57.5441,
            "value2": 28.63
          },
          {
            "category": "16-02-2018",
            "value": 57.2639,
            "value2": 28.36
          },
          {
            "category": "17-02-2018",
            "value": 57.2639,
            "value2": 28.36
          },
          {
            "category": "18-02-2018",
            "value": 57.2639,
            "value2": 28.36
          },
          {
            "category": "19-02-2018",
            "value": 56.7134,
            "value2": 28.13
          },
          {
            "category": "20-02-2018",
            "value": 56.4554,
            "value2": 28.1
          },
          {
            "category": "21-02-2018",
            "value": 56.912,
            "value2": 28.16
          },
          {
            "category": "22-02-2018",
            "value": 56.6593,
            "value2": 28.08
          },
          {
            "category": "23-02-2018",
            "value": 57.2628,
            "value2": 28.42
          },
          {
            "category": "24-02-2018",
            "value": 57.2628,
            "value2": 28.42
          },
          {
            "category": "25-02-2018",
            "value": 57.2628,
            "value2": 28.42
          },
          {
            "category": "26-02-2018",
            "value": 58.0784,
            "value2": 28.68
          },
          {
            "category": "27-02-2018",
            "value": 57.7292,
            "value2": 28.58
          },
          {
            "category": "28-02-2018",
            "value": 57.4198,
            "value2": 28.48
          },
          {
            "category": "01-03-2018",
            "value": 57.7647,
            "value2": 28.37
          },
          {
            "category": "02-03-2018",
            "value": 57.7647,
            "value2": 28.37
          },
          {
            "category": "03-03-2018",
            "value": 57.7647,
            "value2": 28.37
          },
          {
            "category": "04-03-2018",
            "value": 57.7647,
            "value2": 28.37
          },
          {
            "category": "05-03-2018",
            "value": 57.2174,
            "value2": 28.09
          },
          {
            "category": "06-03-2018",
            "value": 56.6896,
            "value2": 27.79
          },
          {
            "category": "07-03-2018",
            "value": 56.3134,
            "value2": 27.49
          },
          {
            "category": "08-03-2018",
            "value": 56.5472,
            "value2": 27.68
          },
          {
            "category": "09-03-2018",
            "value": 56.7441,
            "value2": 27.64
          },
          {
            "category": "10-03-2018",
            "value": 56.7436,
            "value2": 27.64
          },
          {
            "category": "11-03-2018",
            "value": 56.7436,
            "value2": 27.64
          },
          {
            "category": "12-03-2018",
            "value": 57.0995,
            "value2": 28.04
          },
          {
            "category": "13-03-2018",
            "value": 57.3789,
            "value2": 28.15
          },
          {
            "category": "14-03-2018",
            "value": 57.0634,
            "value2": 28.15
          },
          {
            "category": "15-03-2018",
            "value": 56.9382,
            "value2": 28.1
          },
          {
            "category": "16-03-2018",
            "value": 55.9398,
            "value2": 27.73
          },
          {
            "category": "17-03-2018",
            "value": 55.9398,
            "value2": 27.73
          },
          {
            "category": "18-03-2018",
            "value": 55.9398,
            "value2": 27.73
          },
          {
            "category": "19-03-2018",
            "value": 54.9892,
            "value2": 27.39
          },
          {
            "category": "20-03-2018",
            "value": 55.2836,
            "value2": 27.47
          },
          {
            "category": "21-03-2018",
            "value": 55.3989,
            "value2": 27.56
          },
          {
            "category": "22-03-2018",
            "value": 55.159,
            "value2": 27.42
          },
          {
            "category": "23-03-2018",
            "value": 54.9097,
            "value2": 27.09
          },
          {
            "category": "24-03-2018",
            "value": 54.9097,
            "value2": 27.09
          },
          {
            "category": "25-03-2018",
            "value": 54.9097,
            "value2": 27.09
          },
          {
            "category": "26-03-2018",
            "value": 55.4849,
            "value2": 27.44
          },
          {
            "category": "27-03-2018",
            "value": 56.3227,
            "value2": 27.64
          },
          {
            "category": "28-03-2018",
            "value": 56.151,
            "value2": 27.46
          },
          {
            "category": "29-03-2018",
            "value": 56.151,
            "value2": 27.46
          },
          {
            "category": "30-03-2018",
            "value": 56.1501,
            "value2": 27.46
          },
          {
            "category": "31-03-2018",
            "value": 55.9809,
            "value2": 27.46
          },
          {
            "category": "01-04-2018",
            "value": 55.9808,
            "value2": 27.46
          },
          {
            "category": "02-04-2018",
            "value": 56.6615,
            "value2": 27.79
          },
          {
            "category": "03-04-2018",
            "value": 57.1859,
            "value2": 27.95
          },
          {
            "category": "04-04-2018",
            "value": 56.8441,
            "value2": 27.64
          },
          {
            "category": "05-04-2018",
            "value": 58.0822,
            "value2": 28.16
          },
          {
            "category": "06-04-2018",
            "value": 58.4464,
            "value2": 28.24
          },
          {
            "category": "07-04-2018",
            "value": 58.4464,
            "value2": 28.24
          },
          {
            "category": "08-04-2018",
            "value": 58.4464,
            "value2": 28.24
          },
          {
            "category": "09-04-2018",
            "value": 58.7052,
            "value2": 28.35
          },
          {
            "category": "10-04-2018",
            "value": 58.7064,
            "value2": 28.39
          },
          {
            "category": "11-04-2018",
            "value": 58.6343,
            "value2": 28.42
          },
          {
            "category": "12-04-2018",
            "value": 58.8064,
            "value2": 28.48
          },
          {
            "category": "13-04-2018",
            "value": 58.8919,
            "value2": 28.55
          },
          {
            "category": "14-04-2018",
            "value": 58.8919,
            "value2": 28.55
          },
          {
            "category": "15-04-2018",
            "value": 58.8919,
            "value2": 28.55
          },
          {
            "category": "16-04-2018",
            "value": 59.1279,
            "value2": 28.68
          },
          {
            "category": "17-04-2018",
            "value": 58.8832,
            "value2": 28.75
          },
          {
            "category": "18-04-2018",
            "value": 58.8054,
            "value2": 28.7
          },
          {
            "category": "19-04-2018",
            "value": 58.8845,
            "value2": 28.83
          },
          {
            "category": "20-04-2018",
            "value": 58.8323,
            "value2": 28.81
          },
          {
            "category": "21-04-2018",
            "value": 58.8305,
            "value2": 28.81
          },
          {
            "category": "22-04-2018",
            "value": 58.8305,
            "value2": 28.81
          },
          {
            "category": "23-04-2018",
            "value": 59.3743,
            "value2": 28.87
          },
          {
            "category": "24-04-2018",
            "value": 59.5439,
            "value2": 28.92
          },
          {
            "category": "25-04-2018",
            "value": 59.25,
            "value2": 28.79
          },
          {
            "category": "26-04-2018",
            "value": 59.4025,
            "value2": 28.91
          },
          {
            "category": "27-04-2018",
            "value": 59.7745,
            "value2": 29.09
          },
          {
            "category": "28-04-2018",
            "value": 59.7749,
            "value2": 29.09
          },
          {
            "category": "29-04-2018",
            "value": 59.7749,
            "value2": 29.09
          },
          {
            "category": "30-04-2018",
            "value": 59.9835,
            "value2": 29.26
          },
          {
            "category": "01-05-2018",
            "value": 59.9835,
            "value2": 29.26
          },
          {
            "category": "02-05-2018",
            "value": 59.8476,
            "value2": 29.1
          },
          {
            "category": "03-05-2018",
            "value": 59.0688,
            "value2": 28.92
          },
          {
            "category": "04-05-2018",
            "value": 58.7392,
            "value2": 28.78
          },
          {
            "category": "05-05-2018",
            "value": 58.7393,
            "value2": 28.78
          },
          {
            "category": "06-05-2018",
            "value": 58.7393,
            "value2": 28.78
          },
          {
            "category": "07-05-2018",
            "value": 59.3319,
            "value2": 29.01
          },
          {
            "category": "08-05-2018",
            "value": 59.5881,
            "value2": 29.02
          },
          {
            "category": "09-05-2018",
            "value": 59.1513,
            "value2": 29.02
          },
          {
            "category": "10-05-2018",
            "value": 58.7595,
            "value2": 28.84
          },
          {
            "category": "11-05-2018",
            "value": 59.3122,
            "value2": 29.03
          },
          {
            "category": "12-05-2018",
            "value": 59.3122,
            "value2": 29.03
          },
          {
            "category": "13-05-2018",
            "value": 59.3122,
            "value2": 29.03
          },
          {
            "category": "14-05-2018",
            "value": 58.861,
            "value2": 28.94
          },
          {
            "category": "15-05-2018",
            "value": 58.8383,
            "value2": 28.88
          },
          {
            "category": "16-05-2018",
            "value": 58.6094,
            "value2": 28.78
          },
          {
            "category": "17-05-2018",
            "value": 58.7513,
            "value2": 28.71
          },
          {
            "category": "18-05-2018",
            "value": 58.5232,
            "value2": 28.4
          },
          {
            "category": "19-05-2018",
            "value": 58.5232,
            "value2": 28.4
          },
          {
            "category": "20-05-2018",
            "value": 58.5232,
            "value2": 28.4
          },
          {
            "category": "21-05-2018",
            "value": 57.6625,
            "value2": 28.1
          },
          {
            "category": "22-05-2018",
            "value": 58.0129,
            "value2": 28.21
          },
          {
            "category": "23-05-2018",
            "value": 57.7239,
            "value2": 27.97
          },
          {
            "category": "24-05-2018",
            "value": 57.4757,
            "value2": 28.11
          },
          {
            "category": "25-05-2018",
            "value": 58.4441,
            "value2": 28.45
          },
          {
            "category": "26-05-2018",
            "value": 58.4441,
            "value2": 28.45
          },
          {
            "category": "27-05-2018",
            "value": 58.4441,
            "value2": 28.45
          },
          {
            "category": "28-05-2018",
            "value": 58.9436,
            "value2": 28.73
          },
          {
            "category": "29-05-2018",
            "value": 58.6882,
            "value2": 28.6
          },
          {
            "category": "30-05-2018",
            "value": 58.9021,
            "value2": 28.55
          },
          {
            "category": "31-05-2018",
            "value": 59.3528,
            "value2": 28.75
          },
          {
            "category": "01-06-2018",
            "value": 58.6371,
            "value2": 28.57
          },
          {
            "category": "02-06-2018",
            "value": 58.6369,
            "value2": 28.57
          },
          {
            "category": "03-06-2018",
            "value": 58.6369,
            "value2": 28.57
          },
          {
            "category": "04-06-2018",
            "value": 58.1373,
            "value2": 28.34
          },
          {
            "category": "05-06-2018",
            "value": 57.6455,
            "value2": 28.13
          },
          {
            "category": "06-06-2018",
            "value": 58.4127,
            "value2": 28.43
          },
          {
            "category": "07-06-2018",
            "value": 58.6078,
            "value2": 28.71
          },
          {
            "category": "08-06-2018",
            "value": 58.7827,
            "value2": 28.76
          },
          {
            "category": "09-06-2018",
            "value": 58.7827,
            "value2": 28.76
          },
          {
            "category": "10-06-2018",
            "value": 58.7827,
            "value2": 28.76
          },
          {
            "category": "11-06-2018",
            "value": 58.8008,
            "value2": 28.81
          },
          {
            "category": "12-06-2018",
            "value": 59.0958,
            "value2": 28.97
          },
          {
            "category": "13-06-2018",
            "value": 59.1764,
            "value2": 28.98
          },
          {
            "category": "14-06-2018",
            "value": 59.3621,
            "value2": 28.91
          },
          {
            "category": "15-06-2018",
            "value": 59.2605,
            "value2": 28.87
          },
          {
            "category": "16-06-2018",
            "value": 59.2605,
            "value2": 28.87
          },
          {
            "category": "17-06-2018",
            "value": 59.2605,
            "value2": 28.87
          },
          {
            "category": "18-06-2018",
            "value": 58.9736,
            "value2": 28.8
          },
          {
            "category": "19-06-2018",
            "value": 58.4505,
            "value2": 28.55
          },
          {
            "category": "20-06-2018",
            "value": 58.6047,
            "value2": 28.68
          },
          {
            "category": "21-06-2018",
            "value": 58.2769,
            "value2": 28.57
          },
          {
            "category": "22-06-2018",
            "value": 58.5496,
            "value2": 28.74
          },
          {
            "category": "23-06-2018",
            "value": 58.5496,
            "value2": 28.74
          },
          {
            "category": "24-06-2018",
            "value": 58.5496,
            "value2": 28.74
          },
          {
            "category": "25-06-2018",
            "value": 58.7627,
            "value2": 28.55
          },
          {
            "category": "26-06-2018",
            "value": 58.622,
            "value2": 28.54
          },
          {
            "category": "27-06-2018",
            "value": 57.9859,
            "value2": 28.23
          },
          {
            "category": "28-06-2018",
            "value": 57.1815,
            "value2": 27.96
          },
          {
            "category": "29-06-2018",
            "value": 58.1821,
            "value2": 28.33
          },
          {
            "category": "30-06-2018",
            "value": 58.0457,
            "value2": 28.33
          },
          {
            "category": "01-07-2018",
            "value": 58.0457,
            "value2": 28.33
          },
          {
            "category": "02-07-2018",
            "value": 57.7952,
            "value2": 28.18
          },
          {
            "category": "03-07-2018",
            "value": 58.0532,
            "value2": 28.31
          },
          {
            "category": "04-07-2018",
            "value": 58.1125,
            "value2": 28.44
          },
          {
            "category": "05-07-2018",
            "value": 58.0059,
            "value2": 28.38
          },
          {
            "category": "06-07-2018",
            "value": 58.4,
            "value2": 28.46
          },
          {
            "category": "07-07-2018",
            "value": 58.4,
            "value2": 28.46
          },
          {
            "category": "08-07-2018",
            "value": 58.4,
            "value2": 28.46
          },
          {
            "category": "09-07-2018",
            "value": 59.419,
            "value2": 28.72
          },
          {
            "category": "10-07-2018",
            "value": 59.4432,
            "value2": 28.97
          },
          {
            "category": "11-07-2018",
            "value": 59.2978,
            "value2": 28.92
          },
          {
            "category": "12-07-2018",
            "value": 59.3226,
            "value2": 29.05
          },
          {
            "category": "13-07-2018",
            "value": 59.1861,
            "value2": 28.94
          },
          {
            "category": "14-07-2018",
            "value": 59.1861,
            "value2": 28.94
          },
          {
            "category": "15-07-2018",
            "value": 59.1861,
            "value2": 28.94
          },
          {
            "category": "16-07-2018",
            "value": 58.5557,
            "value2": 28.59
          },
          {
            "category": "17-07-2018",
            "value": 59.4279,
            "value2": 28.87
          },
          {
            "category": "18-07-2018",
            "value": 59.2986,
            "value2": 28.71
          },
          {
            "category": "19-07-2018",
            "value": 58.8568,
            "value2": 28.64
          },
          {
            "category": "20-07-2018",
            "value": 59.6358,
            "value2": 28.79
          },
          {
            "category": "21-07-2018",
            "value": 59.6358,
            "value2": 28.79
          },
          {
            "category": "22-07-2018",
            "value": 59.6358,
            "value2": 28.79
          },
          {
            "category": "23-07-2018",
            "value": 60.309,
            "value2": 29.02
          },
          {
            "category": "24-07-2018",
            "value": 60.8579,
            "value2": 29.3
          },
          {
            "category": "25-07-2018",
            "value": 60.6502,
            "value2": 29.3
          },
          {
            "category": "26-07-2018",
            "value": 60.9175,
            "value2": 29.43
          },
          {
            "category": "27-07-2018",
            "value": 61.668,
            "value2": 29.71
          },
          {
            "category": "28-07-2018",
            "value": 61.668,
            "value2": 29.71
          },
          {
            "category": "29-07-2018",
            "value": 61.668,
            "value2": 29.71
          },
          {
            "category": "30-07-2018",
            "value": 61.4128,
            "value2": 29.83
          },
          {
            "category": "31-07-2018",
            "value": 61.5066,
            "value2": 29.93
          },
          {
            "category": "01-08-2018",
            "value": 61.7631,
            "value2": 29.93
          },
          {
            "category": "02-08-2018",
            "value": 61.2691,
            "value2": 29.75
          },
          {
            "category": "03-08-2018",
            "value": 61.7526,
            "value2": 30.03
          },
          {
            "category": "04-08-2018",
            "value": 61.7526,
            "value2": 30.03
          },
          {
            "category": "05-08-2018",
            "value": 61.7525,
            "value2": 30.03
          },
          {
            "category": "06-08-2018",
            "value": 61.6147,
            "value2": 30.12
          },
          {
            "category": "07-08-2018",
            "value": 61.7004,
            "value2": 30.09
          },
          {
            "category": "08-08-2018",
            "value": 62.2402,
            "value2": 30.21
          },
          {
            "category": "09-08-2018",
            "value": 62.3959,
            "value2": 30.31
          },
          {
            "category": "10-08-2018",
            "value": 62.6535,
            "value2": 30.16
          },
          {
            "category": "11-08-2018",
            "value": 62.6535,
            "value2": 30.16
          },
          {
            "category": "12-08-2018",
            "value": 62.6535,
            "value2": 30.16
          },
          {
            "category": "13-08-2018",
            "value": 62.2885,
            "value2": 29.97
          },
          {
            "category": "14-08-2018",
            "value": 62.947,
            "value2": 30.18
          },
          {
            "category": "15-08-2018",
            "value": 62.947,
            "value2": 30.18
          },
          {
            "category": "16-08-2018",
            "value": 62.5009,
            "value2": 30.05
          },
          {
            "category": "17-08-2018",
            "value": 62.9972,
            "value2": 30.32
          },
          {
            "category": "18-08-2018",
            "value": 62.9972,
            "value2": 30.32
          },
          {
            "category": "19-08-2018",
            "value": 62.9972,
            "value2": 30.32
          },
          {
            "category": "20-08-2018",
            "value": 63.3404,
            "value2": 30.54
          },
          {
            "category": "21-08-2018",
            "value": 63.4149,
            "value2": 30.6
          },
          {
            "category": "22-08-2018",
            "value": 63.4149,
            "value2": 30.6
          },
          {
            "category": "23-08-2018",
            "value": 63.5587,
            "value2": 30.65
          },
          {
            "category": "24-08-2018",
            "value": 63.7237,
            "value2": 30.59
          },
          {
            "category": "25-08-2018",
            "value": 63.7237,
            "value2": 30.59
          },
          {
            "category": "26-08-2018",
            "value": 63.7237,
            "value2": 30.59
          },
          {
            "category": "27-08-2018",
            "value": 64.2718,
            "value2": 30.92
          },
          {
            "category": "28-08-2018",
            "value": 64.6139,
            "value2": 31
          },
          {
            "category": "29-08-2018",
            "value": 65.0427,
            "value2": 30.96
          },
          {
            "category": "30-08-2018",
            "value": 64.9075,
            "value2": 30.96
          },
          {
            "category": "31-08-2018",
            "value": 64.5514,
            "value2": 31.02
          },
          {
            "category": "01-09-2018",
            "value": 64.5514,
            "value2": 31.02
          },
          {
            "category": "02-09-2018",
            "value": 64.5514,
            "value2": 31.02
          },
          {
            "category": "03-09-2018",
            "value": 63.8248,
            "value2": 30.81
          },
          {
            "category": "04-09-2018",
            "value": 63.0049,
            "value2": 30.44
          },
          {
            "category": "05-09-2018",
            "value": 62.3844,
            "value2": 30.31
          },
          {
            "category": "06-09-2018",
            "value": 62.4433,
            "value2": 30.47
          },
          {
            "category": "07-09-2018",
            "value": 63.2309,
            "value2": 30.66
          },
          {
            "category": "08-09-2018",
            "value": 63.2309,
            "value2": 30.66
          },
          {
            "category": "09-09-2018",
            "value": 63.2309,
            "value2": 30.66
          },
          {
            "category": "10-09-2018",
            "value": 62.1145,
            "value2": 30.26
          },
          {
            "category": "11-09-2018",
            "value": 61.6081,
            "value2": 29.83
          },
          {
            "category": "12-09-2018",
            "value": 62.0274,
            "value2": 30.02
          },
          {
            "category": "13-09-2018",
            "value": 62.0274,
            "value2": 30.02
          },
          {
            "category": "14-09-2018",
            "value": 62.9647,
            "value2": 30.41
          },
          {
            "category": "15-09-2018",
            "value": 62.9647,
            "value2": 30.41
          },
          {
            "category": "16-09-2018",
            "value": 62.9647,
            "value2": 30.41
          },
          {
            "category": "17-09-2018",
            "value": 62.1483,
            "value2": 30.12
          },
          {
            "category": "18-09-2018",
            "value": 61.2556,
            "value2": 29.81
          },
          {
            "category": "19-09-2018",
            "value": 60.6593,
            "value2": 29.66
          },
          {
            "category": "20-09-2018",
            "value": 60.6593,
            "value2": 29.66
          },
          {
            "category": "21-09-2018",
            "value": 59.8721,
            "value2": 29.28
          },
          {
            "category": "22-09-2018",
            "value": 59.8721,
            "value2": 29.28
          },
          {
            "category": "23-09-2018",
            "value": 59.8721,
            "value2": 29.28
          },
          {
            "category": "24-09-2018",
            "value": 58.5565,
            "value2": 28.71
          },
          {
            "category": "25-09-2018",
            "value": 58.8112,
            "value2": 28.9
          },
          {
            "category": "26-09-2018",
            "value": 58.8603,
            "value2": 28.89
          },
          {
            "category": "27-09-2018",
            "value": 57.7219,
            "value2": 28.58
          },
          {
            "category": "28-09-2018",
            "value": 57.3459,
            "value2": 28.3
          },
          {
            "category": "29-09-2018",
            "value": 57.3442,
            "value2": 28.3
          },
          {
            "category": "30-09-2018",
            "value": 57.2021,
            "value2": 28.3
          },
          {
            "category": "01-10-2018",
            "value": 57.2123,
            "value2": 28.47
          },
          {
            "category": "02-10-2018",
            "value": 57.2123,
            "value2": 28.47
          },
          {
            "category": "03-10-2018",
            "value": 56.3397,
            "value2": 28.14
          },
          {
            "category": "04-10-2018",
            "value": 54.387,
            "value2": 27.52
          },
          {
            "category": "05-10-2018",
            "value": 52.6609,
            "value2": 26.82
          },
          {
            "category": "06-10-2018",
            "value": 52.6609,
            "value2": 26.82
          },
          {
            "category": "07-10-2018",
            "value": 52.6609,
            "value2": 26.82
          },
          {
            "category": "08-10-2018",
            "value": 52.5627,
            "value2": 26.74
          },
          {
            "category": "09-10-2018",
            "value": 52.3852,
            "value2": 26.61
          },
          {
            "category": "10-10-2018",
            "value": 54.3163,
            "value2": 27.19
          },
          {
            "category": "11-10-2018",
            "value": 53.0916,
            "value2": 26.63
          },
          {
            "category": "12-10-2018",
            "value": 54.7393,
            "value2": 27.24
          },
          {
            "category": "13-10-2018",
            "value": 54.7376,
            "value2": 27.24
          },
          {
            "category": "14-10-2018",
            "value": 54.7376,
            "value2": 27.24
          },
          {
            "category": "15-10-2018",
            "value": 54.9601,
            "value2": 27.38
          },
          {
            "category": "16-10-2018",
            "value": 55.3858,
            "value2": 27.64
          },
          {
            "category": "17-10-2018",
            "value": 54.2349,
            "value2": 27.2
          },
          {
            "category": "18-10-2018",
            "value": 54.2349,
            "value2": 27.2
          },
          {
            "category": "19-10-2018",
            "value": 54.803,
            "value2": 26.85
          },
          {
            "category": "20-10-2018",
            "value": 54.8065,
            "value2": 26.85
          },
          {
            "category": "21-10-2018",
            "value": 54.8065,
            "value2": 26.85
          },
          {
            "category": "22-10-2018",
            "value": 53.8651,
            "value2": 26.65
          },
          {
            "category": "23-10-2018",
            "value": 53.8251,
            "value2": 26.39
          },
          {
            "category": "24-10-2018",
            "value": 54.2981,
            "value2": 26.6
          },
          {
            "category": "25-10-2018",
            "value": 54.2349,
            "value2": 26.35
          },
          {
            "category": "26-10-2018",
            "value": 54.1318,
            "value2": 26.17
          },
          {
            "category": "27-10-2018",
            "value": 54.1318,
            "value2": 26.17
          },
          {
            "category": "28-10-2018",
            "value": 54.1318,
            "value2": 26.17
          },
          {
            "category": "29-10-2018",
            "value": 54.7838,
            "value2": 26.77
          },
          {
            "category": "30-10-2018",
            "value": 54.6918,
            "value2": 26.75
          },
          {
            "category": "31-10-2018",
            "value": 55.4275,
            "value2": 27.22
          },
          {
            "category": "01-11-2018",
            "value": 55.395,
            "value2": 27.29
          },
          {
            "category": "02-11-2018",
            "value": 56.073,
            "value2": 27.67
          },
          {
            "category": "03-11-2018",
            "value": 56.073,
            "value2": 27.67
          },
          {
            "category": "04-11-2018",
            "value": 56.073,
            "value2": 27.67
          },
          {
            "category": "05-11-2018",
            "value": 55.8518,
            "value2": 27.61
          },
          {
            "category": "06-11-2018",
            "value": 55.3649,
            "value2": 27.59
          },
          {
            "category": "07-11-2018",
            "value": 55.5656,
            "value2": 27.8
          },
          {
            "category": "08-11-2018",
            "value": 55.5656,
            "value2": 27.8
          },
          {
            "category": "09-11-2018",
            "value": 56.0723,
            "value2": 27.83
          },
          {
            "category": "10-11-2018",
            "value": 56.0723,
            "value2": 27.83
          },
          {
            "category": "11-11-2018",
            "value": 56.0723,
            "value2": 27.83
          },
          {
            "category": "12-11-2018",
            "value": 55.7503,
            "value2": 27.55
          },
          {
            "category": "13-11-2018",
            "value": 56.1344,
            "value2": 27.76
          },
          {
            "category": "14-11-2018",
            "value": 55.5253,
            "value2": 27.75
          },
          {
            "category": "15-11-2018",
            "value": 55.9452,
            "value2": 27.85
          },
          {
            "category": "16-11-2018",
            "value": 56.0168,
            "value2": 27.96
          },
          {
            "category": "17-11-2018",
            "value": 56.0168,
            "value2": 27.96
          },
          {
            "category": "18-11-2018",
            "value": 56.0168,
            "value2": 27.96
          },
          {
            "category": "19-11-2018",
            "value": 56.5182,
            "value2": 28.15
          },
          {
            "category": "20-11-2018",
            "value": 56.345,
            "value2": 27.88
          },
          {
            "category": "21-11-2018",
            "value": 56.6565,
            "value2": 27.82
          },
          {
            "category": "22-11-2018",
            "value": 56.1017,
            "value2": 27.63
          },
          {
            "category": "23-11-2018",
            "value": 56.1017,
            "value2": 27.63
          },
          {
            "category": "24-11-2018",
            "value": 56.1017,
            "value2": 27.63
          },
          {
            "category": "25-11-2018",
            "value": 56.1017,
            "value2": 27.63
          },
          {
            "category": "26-11-2018",
            "value": 56.3274,
            "value2": 27.83
          },
          {
            "category": "27-11-2018",
            "value": 56.284,
            "value2": 27.94
          },
          {
            "category": "28-11-2018",
            "value": 55.9498,
            "value2": 27.97
          },
          {
            "category": "29-11-2018",
            "value": 56.6241,
            "value2": 28.25
          },
          {
            "category": "30-11-2018",
            "value": 57.0159,
            "value2": 28.32
          },
          {
            "category": "01-12-2018",
            "value": 57.0159,
            "value2": 28.32
          },
          {
            "category": "02-12-2018",
            "value": 57.0159,
            "value2": 28.32
          },
          {
            "category": "03-12-2018",
            "value": 56.8647,
            "value2": 28.4
          },
          {
            "category": "04-12-2018",
            "value": 56.7612,
            "value2": 28.37
          },
          {
            "category": "05-12-2018",
            "value": 56.1174,
            "value2": 28.09
          },
          {
            "category": "06-12-2018",
            "value": 55.0631,
            "value2": 27.63
          },
          {
            "category": "07-12-2018",
            "value": 55.6613,
            "value2": 27.81
          },
          {
            "category": "08-12-2018",
            "value": 55.6613,
            "value2": 27.81
          },
          {
            "category": "09-12-2018",
            "value": 55.6613,
            "value2": 27.81
          },
          {
            "category": "10-12-2018",
            "value": 54.734,
            "value2": 27.29
          },
          {
            "category": "11-12-2018",
            "value": 55.5115,
            "value2": 27.54
          },
          {
            "category": "12-12-2018",
            "value": 56.3734,
            "value2": 28.08
          },
          {
            "category": "13-12-2018",
            "value": 56.7038,
            "value2": 28.24
          },
          {
            "category": "14-12-2018",
            "value": 56.776,
            "value2": 28.28
          },
          {
            "category": "15-12-2018",
            "value": 56.776,
            "value2": 28.28
          },
          {
            "category": "16-12-2018",
            "value": 56.776,
            "value2": 28.28
          },
          {
            "category": "17-12-2018",
            "value": 56.8775,
            "value2": 28.49
          },
          {
            "category": "18-12-2018",
            "value": 56.7554,
            "value2": 28.56
          },
          {
            "category": "19-12-2018",
            "value": 57.2524,
            "value2": 28.77
          },
          {
            "category": "20-12-2018",
            "value": 57.4048,
            "value2": 28.74
          },
          {
            "category": "21-12-2018",
            "value": 56.883,
            "value2": 28.27
          },
          {
            "category": "22-12-2018",
            "value": 56.883,
            "value2": 28.27
          },
          {
            "category": "23-12-2018",
            "value": 56.883,
            "value2": 28.27
          },
          {
            "category": "24-12-2018",
            "value": 56.4177,
            "value2": 28.03
          },
          {
            "category": "25-12-2018",
            "value": 56.4177,
            "value2": 28.03
          },
          {
            "category": "26-12-2018",
            "value": 56.4583,
            "value2": 28.14
          },
          {
            "category": "27-12-2018",
            "value": 56.5865,
            "value2": 28.25
          },
          {
            "category": "28-12-2018",
            "value": 57.2907,
            "value2": 28.5
          },
          {
            "category": "29-12-2018",
            "value": 57.2907,
            "value2": 28.5
          },
          {
            "category": "30-12-2018",
            "value": 57.2906,
            "value2": 28.5
          },
          {
            "category": "31-12-2018",
            "value": 57.7192,
            "value2": 28.55
          },
          {
            "category": "01-01-2019",
            "value": 57.608,
            "value2": 28.64
          },
          {
            "category": "02-01-2019",
            "value": 56.7349,
            "value2": 28.34
          },
          {
            "category": "03-01-2019",
            "value": 55.9312,
            "value2": 28.06
          },
          {
            "category": "04-01-2019",
            "value": 55.9605,
            "value2": 28.18
          },
          {
            "category": "05-01-2019",
            "value": 55.9605,
            "value2": 28.18
          },
          {
            "category": "06-01-2019",
            "value": 55.9605,
            "value2": 28.18
          },
          {
            "category": "07-01-2019",
            "value": 55.7141,
            "value2": 28.25
          },
          {
            "category": "08-01-2019",
            "value": 55.9338,
            "value2": 28.32
          },
          {
            "category": "09-01-2019",
            "value": 56.0579,
            "value2": 28.41
          },
          {
            "category": "10-01-2019",
            "value": 56.1595,
            "value2": 28.39
          },
          {
            "category": "11-01-2019",
            "value": 56.0992,
            "value2": 28.32
          },
          {
            "category": "12-01-2019",
            "value": 56.0992,
            "value2": 28.32
          },
          {
            "category": "13-01-2019",
            "value": 56.0992,
            "value2": 28.32
          },
          {
            "category": "14-01-2019",
            "value": 55.8018,
            "value2": 28.17
          },
          {
            "category": "15-01-2019",
            "value": 56.198,
            "value2": 28.5
          },
          {
            "category": "16-01-2019",
            "value": 55.8896,
            "value2": 28.51
          },
          {
            "category": "17-01-2019",
            "value": 55.9523,
            "value2": 28.52
          },
          {
            "category": "18-01-2019",
            "value": 55.7135,
            "value2": 28.45
          },
          {
            "category": "19-01-2019",
            "value": 55.7135,
            "value2": 28.45
          },
          {
            "category": "20-01-2019",
            "value": 55.7135,
            "value2": 28.45
          },
          {
            "category": "21-01-2019",
            "value": 55.7599,
            "value2": 28.48
          },
          {
            "category": "22-01-2019",
            "value": 55.8533,
            "value2": 28.41
          },
          {
            "category": "23-01-2019",
            "value": 55.7114,
            "value2": 28.23
          },
          {
            "category": "24-01-2019",
            "value": 55.8948,
            "value2": 28.24
          },
          {
            "category": "25-01-2019",
            "value": 55.4185,
            "value2": 28
          },
          {
            "category": "26-01-2019",
            "value": 55.4185,
            "value2": 28
          },
          {
            "category": "27-01-2019",
            "value": 55.4185,
            "value2": 28
          },
          {
            "category": "28-01-2019",
            "value": 54.7187,
            "value2": 27.64
          },
          {
            "category": "29-01-2019",
            "value": 54.8132,
            "value2": 27.64
          },
          {
            "category": "30-01-2019",
            "value": 54.8268,
            "value2": 27.65
          },
          {
            "category": "31-01-2019",
            "value": 54.8516,
            "value2": 28.05
          },
          {
            "category": "01-02-2019",
            "value": 55.3615,
            "value2": 28.2
          },
          {
            "category": "02-02-2019",
            "value": 55.3615,
            "value2": 28.2
          },
          {
            "category": "03-02-2019",
            "value": 55.3615,
            "value2": 28.2
          },
          {
            "category": "04-02-2019",
            "value": 55.1308,
            "value2": 28.15
          },
          {
            "category": "05-02-2019",
            "value": 55.0561,
            "value2": 28.12
          },
          {
            "category": "06-02-2019",
            "value": 55.4635,
            "value2": 28.38
          },
          {
            "category": "07-02-2019",
            "value": 55.7289,
            "value2": 28.48
          },
          {
            "category": "08-02-2019",
            "value": 55.1508,
            "value2": 28.13
          },
          {
            "category": "09-02-2019",
            "value": 55.1508,
            "value2": 28.13
          },
          {
            "category": "10-02-2019",
            "value": 55.1508,
            "value2": 28.13
          },
          {
            "category": "11-02-2019",
            "value": 54.7267,
            "value2": 27.92
          },
          {
            "category": "12-02-2019",
            "value": 54.7094,
            "value2": 27.8
          },
          {
            "category": "13-02-2019",
            "value": 54.1227,
            "value2": 27.68
          },
          {
            "category": "14-02-2019",
            "value": 54.0276,
            "value2": 27.64
          },
          {
            "category": "15-02-2019",
            "value": 53.3348,
            "value2": 27.5
          },
          {
            "category": "16-02-2019",
            "value": 53.3348,
            "value2": 27.5
          },
          {
            "category": "17-02-2019",
            "value": 53.3348,
            "value2": 27.5
          },
          {
            "category": "18-02-2019",
            "value": 53.1499,
            "value2": 27.27
          },
          {
            "category": "19-02-2019",
            "value": 53.1696,
            "value2": 27.23
          },
          {
            "category": "20-02-2019",
            "value": 53.4551,
            "value2": 27.55
          },
          {
            "category": "21-02-2019",
            "value": 53.8863,
            "value2": 27.72
          },
          {
            "category": "22-02-2019",
            "value": 53.8341,
            "value2": 27.79
          },
          {
            "category": "23-02-2019",
            "value": 53.8341,
            "value2": 27.79
          },
          {
            "category": "24-02-2019",
            "value": 53.8341,
            "value2": 27.79
          },
          {
            "category": "25-02-2019",
            "value": 54.0668,
            "value2": 28
          },
          {
            "category": "26-02-2019",
            "value": 54.1713,
            "value2": 27.88
          },
          {
            "category": "27-02-2019",
            "value": 54.2088,
            "value2": 27.86
          },
          {
            "category": "28-02-2019",
            "value": 54.3183,
            "value2": 27.91
          },
          {
            "category": "01-03-2019",
            "value": 54.8827,
            "value2": 28.15
          },
          {
            "category": "02-03-2019",
            "value": 54.8827,
            "value2": 28.15
          },
          {
            "category": "03-03-2019",
            "value": 54.8827,
            "value2": 28.15
          },
          {
            "category": "04-03-2019",
            "value": 54.8827,
            "value2": 28.15
          },
          {
            "category": "05-03-2019",
            "value": 55.8066,
            "value2": 28.57
          },
          {
            "category": "06-03-2019",
            "value": 56.1773,
            "value2": 28.74
          },
          {
            "category": "07-03-2019",
            "value": 55.8395,
            "value2": 28.73
          },
          {
            "category": "08-03-2019",
            "value": 55.9259,
            "value2": 28.69
          },
          {
            "category": "09-03-2019",
            "value": 55.9259,
            "value2": 28.69
          },
          {
            "category": "10-03-2019",
            "value": 55.9259,
            "value2": 28.69
          },
          {
            "category": "11-03-2019",
            "value": 57.2906,
            "value2": 29.1
          },
          {
            "category": "12-03-2019",
            "value": 57.3688,
            "value2": 29.4
          },
          {
            "category": "13-03-2019",
            "value": 57.3649,
            "value2": 29.45
          },
          {
            "category": "14-03-2019",
            "value": 57.004,
            "value2": 29.45
          },
          {
            "category": "15-03-2019",
            "value": 57.4227,
            "value2": 29.6
          },
          {
            "category": "16-03-2019",
            "value": 57.4227,
            "value2": 29.6
          },
          {
            "category": "17-03-2019",
            "value": 57.4227,
            "value2": 29.6
          },
          {
            "category": "18-03-2019",
            "value": 57.6839,
            "value2": 29.64
          },
          {
            "category": "19-03-2019",
            "value": 57.9019,
            "value2": 29.82
          },
          {
            "category": "20-03-2019",
            "value": 57.6187,
            "value2": 29.78
          },
          {
            "category": "21-03-2019",
            "value": 57.6187,
            "value2": 29.78
          },
          {
            "category": "22-03-2019",
            "value": 57.471,
            "value2": 29.61
          },
          {
            "category": "23-03-2019",
            "value": 57.471,
            "value2": 29.61
          },
          {
            "category": "24-03-2019",
            "value": 57.471,
            "value2": 29.61
          },
          {
            "category": "25-03-2019",
            "value": 56.9067,
            "value2": 29.35
          },
          {
            "category": "26-03-2019",
            "value": 57.6728,
            "value2": 29.65
          },
          {
            "category": "27-03-2019",
            "value": 57.6679,
            "value2": 29.63
          },
          {
            "category": "28-03-2019",
            "value": 57.9962,
            "value2": 29.94
          },
          {
            "category": "29-03-2019",
            "value": 58.5951,
            "value2": 30.12
          },
          {
            "category": "30-03-2019",
            "value": 58.5946,
            "value2": 30.12
          },
          {
            "category": "31-03-2019",
            "value": 58.449,
            "value2": 30.12
          },
          {
            "category": "01-04-2019",
            "value": 58.6711,
            "value2": 30.25
          },
          {
            "category": "02-04-2019",
            "value": 58.5192,
            "value2": 30.3
          },
          {
            "category": "03-04-2019",
            "value": 57.8634,
            "value2": 30.13
          },
          {
            "category": "04-04-2019",
            "value": 57.6987,
            "value2": 30.03
          },
          {
            "category": "05-04-2019",
            "value": 58.053,
            "value2": 30.2
          },
          {
            "category": "06-04-2019",
            "value": 58.053,
            "value2": 30.2
          },
          {
            "category": "07-04-2019",
            "value": 58.053,
            "value2": 30.2
          },
          {
            "category": "08-04-2019",
            "value": 57.5939,
            "value2": 30.05
          },
          {
            "category": "09-04-2019",
            "value": 57.8556,
            "value2": 30.18
          },
          {
            "category": "10-04-2019",
            "value": 57.6921,
            "value2": 30
          },
          {
            "category": "11-04-2019",
            "value": 57.7861,
            "value2": 30.03
          },
          {
            "category": "12-04-2019",
            "value": 57.8694,
            "value2": 30.16
          },
          {
            "category": "13-04-2019",
            "value": 57.8695,
            "value2": 30.16
          },
          {
            "category": "14-04-2019",
            "value": 57.8695,
            "value2": 30.16
          },
          {
            "category": "15-04-2019",
            "value": 58.3176,
            "value2": 30.28
          },
          {
            "category": "16-04-2019",
            "value": 58.4253,
            "value2": 30.51
          },
          {
            "category": "17-04-2019",
            "value": 58.4241,
            "value2": 30.51
          },
          {
            "category": "18-04-2019",
            "value": 58.2224,
            "value2": 30.35
          },
          {
            "category": "19-04-2019",
            "value": 58.2225,
            "value2": 30.35
          },
          {
            "category": "20-04-2019",
            "value": 58.2225,
            "value2": 30.35
          },
          {
            "category": "21-04-2019",
            "value": 58.2225,
            "value2": 30.35
          },
          {
            "category": "22-04-2019",
            "value": 57.3258,
            "value2": 29.93
          },
          {
            "category": "23-04-2019",
            "value": 57.5554,
            "value2": 29.89
          },
          {
            "category": "24-04-2019",
            "value": 58.079,
            "value2": 30.2
          },
          {
            "category": "25-04-2019",
            "value": 57.7653,
            "value2": 30.03
          },
          {
            "category": "26-04-2019",
            "value": 57.9145,
            "value2": 30.19
          },
          {
            "category": "27-04-2019",
            "value": 57.9144,
            "value2": 30.19
          },
          {
            "category": "28-04-2019",
            "value": 57.9144,
            "value2": 30.19
          },
          {
            "category": "29-04-2019",
            "value": 57.9144,
            "value2": 30.19
          },
          {
            "category": "30-04-2019",
            "value": 57.687,
            "value2": 30.1
          },
          {
            "category": "01-05-2019",
            "value": 57.687,
            "value2": 30.1
          },
          {
            "category": "02-05-2019",
            "value": 57.5724,
            "value2": 30.02
          },
          {
            "category": "03-05-2019",
            "value": 57.4554,
            "value2": 29.98
          },
          {
            "category": "04-05-2019",
            "value": 57.4553,
            "value2": 29.98
          },
          {
            "category": "05-05-2019",
            "value": 57.4553,
            "value2": 29.98
          },
          {
            "category": "06-05-2019",
            "value": 57.1942,
            "value2": 29.69
          },
          {
            "category": "07-05-2019",
            "value": 56.6942,
            "value2": 29.44
          },
          {
            "category": "08-05-2019",
            "value": 55.9524,
            "value2": 29.09
          },
          {
            "category": "09-05-2019",
            "value": 55.9173,
            "value2": 28.97
          },
          {
            "category": "10-05-2019",
            "value": 55.9391,
            "value2": 28.93
          },
          {
            "category": "11-05-2019",
            "value": 55.9391,
            "value2": 28.93
          },
          {
            "category": "12-05-2019",
            "value": 55.9391,
            "value2": 28.93
          },
          {
            "category": "13-05-2019",
            "value": 55.174,
            "value2": 28.53
          },
          {
            "category": "14-05-2019",
            "value": 55.1901,
            "value2": 28.7
          },
          {
            "category": "15-05-2019",
            "value": 55.3452,
            "value2": 28.54
          },
          {
            "category": "16-05-2019",
            "value": 55.7052,
            "value2": 28.73
          },
          {
            "category": "17-05-2019",
            "value": 56.9406,
            "value2": 29.07
          },
          {
            "category": "18-05-2019",
            "value": 56.9406,
            "value2": 29.07
          },
          {
            "category": "19-05-2019",
            "value": 56.9406,
            "value2": 29.07
          },
          {
            "category": "20-05-2019",
            "value": 58.3769,
            "value2": 30.13
          },
          {
            "category": "21-05-2019",
            "value": 57.686,
            "value2": 29.84
          },
          {
            "category": "22-05-2019",
            "value": 57.7416,
            "value2": 29.93
          },
          {
            "category": "23-05-2019",
            "value": 57.4865,
            "value2": 29.79
          },
          {
            "category": "24-05-2019",
            "value": 58.3887,
            "value2": 30.32
          },
          {
            "category": "25-05-2019",
            "value": 58.3887,
            "value2": 30.32
          },
          {
            "category": "26-05-2019",
            "value": 58.3887,
            "value2": 30.32
          },
          {
            "category": "27-05-2019",
            "value": 58.5683,
            "value2": 30.58
          },
          {
            "category": "28-05-2019",
            "value": 58.3522,
            "value2": 30.64
          },
          {
            "category": "29-05-2019",
            "value": 57.9565,
            "value2": 30.43
          },
          {
            "category": "30-05-2019",
            "value": 58.0874,
            "value2": 30.62
          },
          {
            "category": "31-05-2019",
            "value": 58.2864,
            "value2": 30.57
          },
          {
            "category": "01-06-2019",
            "value": 58.2863,
            "value2": 30.57
          },
          {
            "category": "02-06-2019",
            "value": 58.2863,
            "value2": 30.57
          },
          {
            "category": "03-06-2019",
            "value": 58.6357,
            "value2": 30.94
          },
          {
            "category": "04-06-2019",
            "value": 58.3769,
            "value2": 30.82
          },
          {
            "category": "05-06-2019",
            "value": 58.3769,
            "value2": 30.82
          },
          {
            "category": "06-06-2019",
            "value": 57.8348,
            "value2": 30.35
          },
          {
            "category": "07-06-2019",
            "value": 58.1356,
            "value2": 30.38
          },
          {
            "category": "08-06-2019",
            "value": 58.1356,
            "value2": 30.38
          },
          {
            "category": "09-06-2019",
            "value": 58.1356,
            "value2": 30.38
          },
          {
            "category": "10-06-2019",
            "value": 58.2151,
            "value2": 30.47
          },
          {
            "category": "11-06-2019",
            "value": 58.3804,
            "value2": 30.6
          },
          {
            "category": "12-06-2019",
            "value": 57.865,
            "value2": 30.43
          },
          {
            "category": "13-06-2019",
            "value": 58.1542,
            "value2": 30.43
          },
          {
            "category": "14-06-2019",
            "value": 57.6278,
            "value2": 30.19
          },
          {
            "category": "15-06-2019",
            "value": 57.6278,
            "value2": 30.19
          },
          {
            "category": "16-06-2019",
            "value": 57.6278,
            "value2": 30.19
          },
          {
            "category": "17-06-2019",
            "value": 56.9054,
            "value2": 29.8
          },
          {
            "category": "18-06-2019",
            "value": 56.9139,
            "value2": 29.84
          },
          {
            "category": "19-06-2019",
            "value": 56.9518,
            "value2": 29.76
          },
          {
            "category": "20-06-2019",
            "value": 57.4536,
            "value2": 30.15
          },
          {
            "category": "21-06-2019",
            "value": 57.4419,
            "value2": 29.95
          },
          {
            "category": "22-06-2019",
            "value": 57.4419,
            "value2": 29.95
          },
          {
            "category": "23-06-2019",
            "value": 57.4419,
            "value2": 29.95
          },
          {
            "category": "24-06-2019",
            "value": 57.1302,
            "value2": 29.89
          },
          {
            "category": "25-06-2019",
            "value": 57.5767,
            "value2": 30.11
          },
          {
            "category": "26-06-2019",
            "value": 57.8686,
            "value2": 30.26
          },
          {
            "category": "27-06-2019",
            "value": 58.0061,
            "value2": 30.3
          },
          {
            "category": "28-06-2019",
            "value": 57.6345,
            "value2": 30.18
          },
          {
            "category": "29-06-2019",
            "value": 57.6342,
            "value2": 30.18
          },
          {
            "category": "30-06-2019",
            "value": 57.5017,
            "value2": 30.18
          },
          {
            "category": "01-07-2019",
            "value": 57.9007,
            "value2": 30.36
          },
          {
            "category": "02-07-2019",
            "value": 58.0004,
            "value2": 30.46
          },
          {
            "category": "03-07-2019",
            "value": 57.8044,
            "value2": 30.5
          },
          {
            "category": "04-07-2019",
            "value": 57.9139,
            "value2": 30.56
          },
          {
            "category": "05-07-2019",
            "value": 57.4475,
            "value2": 30.19
          },
          {
            "category": "06-07-2019",
            "value": 57.4475,
            "value2": 30.19
          },
          {
            "category": "07-07-2019",
            "value": 57.4475,
            "value2": 30.19
          },
          {
            "category": "08-07-2019",
            "value": 56.1589,
            "value2": 29.54
          },
          {
            "category": "09-07-2019",
            "value": 56.3211,
            "value2": 29.56
          },
          {
            "category": "10-07-2019",
            "value": 56.0892,
            "value2": 29.38
          },
          {
            "category": "11-07-2019",
            "value": 56.4428,
            "value2": 29.6
          },
          {
            "category": "12-07-2019",
            "value": 56.3391,
            "value2": 29.57
          },
          {
            "category": "13-07-2019",
            "value": 56.3391,
            "value2": 29.57
          },
          {
            "category": "14-07-2019",
            "value": 56.3391,
            "value2": 29.57
          },
          {
            "category": "15-07-2019",
            "value": 56.4679,
            "value2": 29.61
          },
          {
            "category": "16-07-2019",
            "value": 56.7411,
            "value2": 29.81
          },
          {
            "category": "17-07-2019",
            "value": 56.8202,
            "value2": 29.86
          },
          {
            "category": "18-07-2019",
            "value": 56.4068,
            "value2": 29.58
          },
          {
            "category": "19-07-2019",
            "value": 55.3705,
            "value2": 29.14
          },
          {
            "category": "20-07-2019",
            "value": 55.3705,
            "value2": 29.14
          },
          {
            "category": "21-07-2019",
            "value": 55.3705,
            "value2": 29.14
          },
          {
            "category": "22-07-2019",
            "value": 54.5682,
            "value2": 28.96
          },
          {
            "category": "23-07-2019",
            "value": 54.7754,
            "value2": 28.91
          },
          {
            "category": "24-07-2019",
            "value": 54.215,
            "value2": 28.69
          },
          {
            "category": "25-07-2019",
            "value": 54.0947,
            "value2": 28.7
          },
          {
            "category": "26-07-2019",
            "value": 54.4808,
            "value2": 28.8
          },
          {
            "category": "27-07-2019",
            "value": 54.4808,
            "value2": 28.8
          },
          {
            "category": "28-07-2019",
            "value": 54.4808,
            "value2": 28.8
          },
          {
            "category": "29-07-2019",
            "value": 54.0822,
            "value2": 28.55
          },
          {
            "category": "30-07-2019",
            "value": 53.4543,
            "value2": 28.22
          },
          {
            "category": "31-07-2019",
            "value": 53.7828,
            "value2": 28.35
          },
          {
            "category": "01-08-2019",
            "value": 53.787,
            "value2": 28.01
          },
          {
            "category": "02-08-2019",
            "value": 53.802,
            "value2": 28.05
          },
          {
            "category": "03-08-2019",
            "value": 53.802,
            "value2": 28.05
          },
          {
            "category": "04-08-2019",
            "value": 53.802,
            "value2": 28.05
          },
          {
            "category": "05-08-2019",
            "value": 52.9371,
            "value2": 27.71
          },
          {
            "category": "06-08-2019",
            "value": 53.8948,
            "value2": 27.98
          },
          {
            "category": "07-08-2019",
            "value": 53.4933,
            "value2": 27.78
          },
          {
            "category": "08-08-2019",
            "value": 53.903,
            "value2": 28.19
          },
          {
            "category": "09-08-2019",
            "value": 54.3751,
            "value2": 28.39
          },
          {
            "category": "10-08-2019",
            "value": 54.3751,
            "value2": 28.39
          },
          {
            "category": "11-08-2019",
            "value": 54.3751,
            "value2": 28.39
          },
          {
            "category": "12-08-2019",
            "value": 54.3751,
            "value2": 28.39
          },
          {
            "category": "13-08-2019",
            "value": 53.1466,
            "value2": 27.9
          },
          {
            "category": "14-08-2019",
            "value": 53.2617,
            "value2": 28.16
          },
          {
            "category": "15-08-2019",
            "value": 53.2617,
            "value2": 28.16
          },
          {
            "category": "16-08-2019",
            "value": 53.6197,
            "value2": 28.21
          },
          {
            "category": "17-08-2019",
            "value": 53.6197,
            "value2": 28.21
          },
          {
            "category": "18-08-2019",
            "value": 53.6197,
            "value2": 28.21
          },
          {
            "category": "19-08-2019",
            "value": 53.708,
            "value2": 28.25
          },
          {
            "category": "20-08-2019",
            "value": 53.7717,
            "value2": 28.14
          },
          {
            "category": "21-08-2019",
            "value": 53.4172,
            "value2": 27.86
          },
          {
            "category": "22-08-2019",
            "value": 52.7188,
            "value2": 27.41
          },
          {
            "category": "23-08-2019",
            "value": 53.1027,
            "value2": 27.63
          },
          {
            "category": "24-08-2019",
            "value": 53.1027,
            "value2": 27.63
          },
          {
            "category": "25-08-2019",
            "value": 53.1027,
            "value2": 27.63
          },
          {
            "category": "26-08-2019",
            "value": 53.9556,
            "value2": 28.18
          },
          {
            "category": "27-08-2019",
            "value": 54.291,
            "value2": 28.36
          },
          {
            "category": "28-08-2019",
            "value": 53.8739,
            "value2": 28.2
          },
          {
            "category": "29-08-2019",
            "value": 53.5849,
            "value2": 28
          },
          {
            "category": "30-08-2019",
            "value": 54.188,
            "value2": 28.23
          },
          {
            "category": "31-08-2019",
            "value": 54.0594,
            "value2": 28.23
          },
          {
            "category": "01-09-2019",
            "value": 54.0587,
            "value2": 28.23
          },
          {
            "category": "02-09-2019",
            "value": 54.0587,
            "value2": 28.23
          },
          {
            "category": "03-09-2019",
            "value": 52.9499,
            "value2": 27.67
          },
          {
            "category": "04-09-2019",
            "value": 52.963,
            "value2": 27.77
          },
          {
            "category": "05-09-2019",
            "value": 53.0861,
            "value2": 27.81
          },
          {
            "category": "06-09-2019",
            "value": 53.4872,
            "value2": 28.03
          },
          {
            "category": "07-09-2019",
            "value": 53.4872,
            "value2": 28.03
          },
          {
            "category": "08-09-2019",
            "value": 53.4872,
            "value2": 28.03
          },
          {
            "category": "09-09-2019",
            "value": 53.9153,
            "value2": 28.2
          },
          {
            "category": "10-09-2019",
            "value": 53.9153,
            "value2": 28.2
          },
          {
            "category": "11-09-2019",
            "value": 54.0632,
            "value2": 28.34
          },
          {
            "category": "12-09-2019",
            "value": 53.8494,
            "value2": 28.24
          },
          {
            "category": "13-09-2019",
            "value": 54.4732,
            "value2": 28.46
          },
          {
            "category": "14-09-2019",
            "value": 54.4732,
            "value2": 28.46
          },
          {
            "category": "15-09-2019",
            "value": 54.4732,
            "value2": 28.46
          },
          {
            "category": "16-09-2019",
            "value": 54.6589,
            "value2": 28.33
          },
          {
            "category": "17-09-2019",
            "value": 53.795,
            "value2": 27.84
          },
          {
            "category": "18-09-2019",
            "value": 54.2521,
            "value2": 27.92
          },
          {
            "category": "19-09-2019",
            "value": 53.6712,
            "value2": 27.57
          },
          {
            "category": "20-09-2019",
            "value": 57.2375,
            "value2": 29.04
          },
          {
            "category": "21-09-2019",
            "value": 57.2376,
            "value2": 29.04
          },
          {
            "category": "22-09-2019",
            "value": 57.2376,
            "value2": 29.04
          },
          {
            "category": "23-09-2019",
            "value": 59.161,
            "value2": 29.84
          },
          {
            "category": "24-09-2019",
            "value": 58.8031,
            "value2": 29.8
          },
          {
            "category": "25-09-2019",
            "value": 58.1227,
            "value2": 29.39
          },
          {
            "category": "26-09-2019",
            "value": 58.7423,
            "value2": 29.72
          },
          {
            "category": "27-09-2019",
            "value": 58.7463,
            "value2": 29.56
          },
          {
            "category": "28-09-2019",
            "value": 58.7459,
            "value2": 29.56
          },
          {
            "category": "29-09-2019",
            "value": 58.7456,
            "value2": 29.56
          },
          {
            "category": "30-09-2019",
            "value": 58.2799,
            "value2": 29.38
          },
          {
            "category": "01-10-2019",
            "value": 57.8044,
            "value2": 29.05
          },
          {
            "category": "02-10-2019",
            "value": 57.8044,
            "value2": 29.05
          },
          {
            "category": "03-10-2019",
            "value": 57.7091,
            "value2": 28.93
          },
          {
            "category": "04-10-2019",
            "value": 56.6627,
            "value2": 28.6
          },
          {
            "category": "05-10-2019",
            "value": 56.6627,
            "value2": 28.6
          },
          {
            "category": "06-10-2019",
            "value": 56.6627,
            "value2": 28.6
          },
          {
            "category": "07-10-2019",
            "value": 56.4382,
            "value2": 28.44
          },
          {
            "category": "08-10-2019",
            "value": 56.4381,
            "value2": 28.44
          },
          {
            "category": "09-10-2019",
            "value": 57.3982,
            "value2": 28.87
          },
          {
            "category": "10-10-2019",
            "value": 56.7068,
            "value2": 28.66
          },
          {
            "category": "11-10-2019",
            "value": 56.8506,
            "value2": 28.83
          },
          {
            "category": "12-10-2019",
            "value": 56.8507,
            "value2": 28.83
          },
          {
            "category": "13-10-2019",
            "value": 56.8507,
            "value2": 28.83
          },
          {
            "category": "14-10-2019",
            "value": 56.9233,
            "value2": 28.92
          },
          {
            "category": "15-10-2019",
            "value": 57.3991,
            "value2": 29.12
          },
          {
            "category": "16-10-2019",
            "value": 57.4203,
            "value2": 29.22
          },
          {
            "category": "17-10-2019",
            "value": 58.1942,
            "value2": 29.58
          },
          {
            "category": "18-10-2019",
            "value": 58.7377,
            "value2": 29.86
          },
          {
            "category": "19-10-2019",
            "value": 58.7377,
            "value2": 29.86
          },
          {
            "category": "20-10-2019",
            "value": 58.7377,
            "value2": 29.86
          },
          {
            "category": "21-10-2019",
            "value": 58.7377,
            "value2": 29.86
          },
          {
            "category": "22-10-2019",
            "value": 58.8143,
            "value2": 29.75
          },
          {
            "category": "23-10-2019",
            "value": 59.0409,
            "value2": 29.81
          },
          {
            "category": "24-10-2019",
            "value": 58.8417,
            "value2": 29.73
          },
          {
            "category": "25-10-2019",
            "value": 58.6712,
            "value2": 29.71
          },
          {
            "category": "26-10-2019",
            "value": 58.6712,
            "value2": 29.71
          },
          {
            "category": "27-10-2019",
            "value": 59.0291,
            "value2": 29.87
          },
          {
            "category": "28-10-2019",
            "value": 59.0291,
            "value2": 29.87
          },
          {
            "category": "29-10-2019",
            "value": 59.7902,
            "value2": 30.25
          },
          {
            "category": "30-10-2019",
            "value": 59.9127,
            "value2": 30.41
          },
          {
            "category": "31-10-2019",
            "value": 60.0212,
            "value2": 30.54
          },
          {
            "category": "01-11-2019",
            "value": 59.9353,
            "value2": 30.59
          },
          {
            "category": "02-11-2019",
            "value": 59.9353,
            "value2": 30.59
          },
          {
            "category": "03-11-2019",
            "value": 59.9353,
            "value2": 30.59
          },
          {
            "category": "04-11-2019",
            "value": 59.9533,
            "value2": 30.68
          },
          {
            "category": "05-11-2019",
            "value": 59.4091,
            "value2": 30.56
          },
          {
            "category": "06-11-2019",
            "value": 59.4845,
            "value2": 30.65
          },
          {
            "category": "07-11-2019",
            "value": 59.882,
            "value2": 30.79
          },
          {
            "category": "08-11-2019",
            "value": 60.0062,
            "value2": 30.52
          },
          {
            "category": "09-11-2019",
            "value": 60.0062,
            "value2": 30.52
          },
          {
            "category": "10-11-2019",
            "value": 60.0062,
            "value2": 30.52
          },
          {
            "category": "11-11-2019",
            "value": 60.0425,
            "value2": 30.56
          },
          {
            "category": "12-11-2019",
            "value": 60.0425,
            "value2": 30.56
          },
          {
            "category": "13-11-2019",
            "value": 59.712,
            "value2": 30.34
          },
          {
            "category": "14-11-2019",
            "value": 60.0592,
            "value2": 30.4
          },
          {
            "category": "15-11-2019",
            "value": 60.2239,
            "value2": 30.45
          },
          {
            "category": "16-11-2019",
            "value": 60.2239,
            "value2": 30.45
          },
          {
            "category": "17-11-2019",
            "value": 60.2239,
            "value2": 30.45
          },
          {
            "category": "18-11-2019",
            "value": 60.4742,
            "value2": 30.46
          },
          {
            "category": "19-11-2019",
            "value": 60.4197,
            "value2": 30.57
          },
          {
            "category": "20-11-2019",
            "value": 60.5472,
            "value2": 30.7
          },
          {
            "category": "21-11-2019",
            "value": 60.2681,
            "value2": 30.6
          },
          {
            "category": "22-11-2019",
            "value": 60.0164,
            "value2": 30.49
          },
          {
            "category": "23-11-2019",
            "value": 60.0164,
            "value2": 30.49
          },
          {
            "category": "24-11-2019",
            "value": 60.0164,
            "value2": 30.49
          },
          {
            "category": "25-11-2019",
            "value": 60.5808,
            "value2": 30.88
          },
          {
            "category": "26-11-2019",
            "value": 60.4255,
            "value2": 30.76
          },
          {
            "category": "27-11-2019",
            "value": 60.5381,
            "value2": 30.93
          },
          {
            "category": "28-11-2019",
            "value": 60.9572,
            "value2": 31.09
          },
          {
            "category": "29-11-2019",
            "value": 60.8074,
            "value2": 30.91
          },
          {
            "category": "30-11-2019",
            "value": 60.6682,
            "value2": 30.91
          },
          {
            "category": "01-12-2019",
            "value": 60.6682,
            "value2": 30.91
          },
          {
            "category": "02-12-2019",
            "value": 60.4162,
            "value2": 30.87
          },
          {
            "category": "03-12-2019",
            "value": 60.1129,
            "value2": 30.69
          },
          {
            "category": "04-12-2019",
            "value": 60.4226,
            "value2": 30.82
          },
          {
            "category": "05-12-2019",
            "value": 60.4786,
            "value2": 30.73
          },
          {
            "category": "06-12-2019",
            "value": 60.1036,
            "value2": 30.46
          },
          {
            "category": "07-12-2019",
            "value": 60.1036,
            "value2": 30.46
          },
          {
            "category": "08-12-2019",
            "value": 60.1036,
            "value2": 30.46
          },
          {
            "category": "09-12-2019",
            "value": 59.9407,
            "value2": 30.47
          },
          {
            "category": "10-12-2019",
            "value": 59.6467,
            "value2": 30.23
          },
          {
            "category": "11-12-2019",
            "value": 60.0444,
            "value2": 30.35
          },
          {
            "category": "12-12-2019",
            "value": 60.5065,
            "value2": 30.52
          },
          {
            "category": "13-12-2019",
            "value": 60.5774,
            "value2": 30.82
          },
          {
            "category": "14-12-2019",
            "value": 60.5774,
            "value2": 30.82
          },
          {
            "category": "15-12-2019",
            "value": 60.5774,
            "value2": 30.82
          },
          {
            "category": "16-12-2019",
            "value": 60.4034,
            "value2": 30.72
          },
          {
            "category": "17-12-2019",
            "value": 60.6801,
            "value2": 30.98
          },
          {
            "category": "18-12-2019",
            "value": 60.7234,
            "value2": 31.08
          },
          {
            "category": "19-12-2019",
            "value": 60.6932,
            "value2": 31.16
          },
          {
            "category": "20-12-2019",
            "value": 60.2995,
            "value2": 31.21
          },
          {
            "category": "21-12-2019",
            "value": 60.2995,
            "value2": 31.21
          },
          {
            "category": "22-12-2019",
            "value": 60.2995,
            "value2": 31.21
          },
          {
            "category": "23-12-2019",
            "value": 60.3623,
            "value2": 31.19
          },
          {
            "category": "24-12-2019",
            "value": 60.3416,
            "value2": 31.09
          },
          {
            "category": "25-12-2019",
            "value": 60.3416,
            "value2": 31.09
          },
          {
            "category": "26-12-2019",
            "value": 60.3528,
            "value2": 30.94
          },
          {
            "category": "27-12-2019",
            "value": 60.878,
            "value2": 31.23
          },
          {
            "category": "28-12-2019",
            "value": 60.8779,
            "value2": 31.23
          },
          {
            "category": "29-12-2019",
            "value": 60.8779,
            "value2": 31.23
          },
          {
            "category": "30-12-2019",
            "value": 61.2468,
            "value2": 31.27
          },
          {
            "category": "31-12-2019",
            "value": 60.7253,
            "value2": 31.11
          },
          {
            "category": "01-01-2020",
            "value": 60.8096,
            "value2": 31.16
          },
          {
            "category": "02-01-2020",
            "value": 61.0575,
            "value2": 31.45
          },
          {
            "category": "03-01-2020",
            "value": 60.8185,
            "value2": 31.33
          },
          {
            "category": "04-01-2020",
            "value": 60.8184,
            "value2": 31.33
          },
          {
            "category": "05-01-2020",
            "value": 60.8184,
            "value2": 31.33
          },
          {
            "category": "06-01-2020",
            "value": 59.8682,
            "value2": 30.71
          },
          {
            "category": "07-01-2020",
            "value": 60.0676,
            "value2": 30.89
          },
          {
            "category": "08-01-2020",
            "value": 60.0849,
            "value2": 30.86
          },
          {
            "category": "09-01-2020",
            "value": 60.9819,
            "value2": 31.33
          },
          {
            "category": "10-01-2020",
            "value": 61.3213,
            "value2": 31.44
          },
          {
            "category": "11-01-2020",
            "value": 61.3213,
            "value2": 31.44
          },
          {
            "category": "12-01-2020",
            "value": 61.3213,
            "value2": 31.44
          },
          {
            "category": "13-01-2020",
            "value": 61.3452,
            "value2": 31.66
          },
          {
            "category": "14-01-2020",
            "value": 61.8372,
            "value2": 31.77
          },
          {
            "category": "15-01-2020",
            "value": 62.2652,
            "value2": 31.8
          },
          {
            "category": "16-01-2020",
            "value": 63.036,
            "value2": 31.87
          },
          {
            "category": "17-01-2020",
            "value": 63.1111,
            "value2": 31.9
          },
          {
            "category": "18-01-2020",
            "value": 63.1111,
            "value2": 31.9
          },
          {
            "category": "19-01-2020",
            "value": 63.1111,
            "value2": 31.9
          },
          {
            "category": "20-01-2020",
            "value": 62.2877,
            "value2": 31.62
          },
          {
            "category": "21-01-2020",
            "value": 62.2259,
            "value2": 31.5
          },
          {
            "category": "22-01-2020",
            "value": 61.7664,
            "value2": 31.37
          },
          {
            "category": "23-01-2020",
            "value": 62.3269,
            "value2": 31.61
          },
          {
            "category": "24-01-2020",
            "value": 63.076,
            "value2": 31.8
          },
          {
            "category": "25-01-2020",
            "value": 63.076,
            "value2": 31.8
          },
          {
            "category": "26-01-2020",
            "value": 63.076,
            "value2": 31.8
          },
          {
            "category": "27-01-2020",
            "value": 62.6973,
            "value2": 31.54
          },
          {
            "category": "28-01-2020",
            "value": 62.3173,
            "value2": 31.39
          },
          {
            "category": "29-01-2020",
            "value": 62.3394,
            "value2": 31.56
          },
          {
            "category": "30-01-2020",
            "value": 61.5619,
            "value2": 31.27
          },
          {
            "category": "31-01-2020",
            "value": 61.2966,
            "value2": 31.08
          },
          {
            "category": "01-02-2020",
            "value": 59.9962,
            "value2": 30.28
          },
          {
            "category": "02-02-2020",
            "value": 59.9962,
            "value2": 30.28
          },
          {
            "category": "03-02-2020",
            "value": 60.5374,
            "value2": 30.43
          },
          {
            "category": "04-02-2020",
            "value": 61.5312,
            "value2": 31.05
          },
          {
            "category": "05-02-2020",
            "value": 61.8272,
            "value2": 31.37
          },
          {
            "category": "06-02-2020",
            "value": 61.8196,
            "value2": 31.53
          },
          {
            "category": "07-02-2020",
            "value": 61.8898,
            "value2": 31.51
          },
          {
            "category": "08-02-2020",
            "value": 61.8898,
            "value2": 31.51
          },
          {
            "category": "09-02-2020",
            "value": 61.8898,
            "value2": 31.51
          },
          {
            "category": "10-02-2020",
            "value": 61.4103,
            "value2": 31.34
          },
          {
            "category": "11-02-2020",
            "value": 61.67,
            "value2": 31.47
          },
          {
            "category": "12-02-2020",
            "value": 61.986,
            "value2": 31.63
          },
          {
            "category": "13-02-2020",
            "value": 61.9303,
            "value2": 31.57
          },
          {
            "category": "14-02-2020",
            "value": 61.4877,
            "value2": 31.39
          },
          {
            "category": "15-02-2020",
            "value": 61.4877,
            "value2": 31.39
          },
          {
            "category": "16-02-2020",
            "value": 61.4877,
            "value2": 31.39
          },
          {
            "category": "17-02-2020",
            "value": 61.4309,
            "value2": 31.17
          },
          {
            "category": "18-02-2020",
            "value": 61.317,
            "value2": 31.04
          },
          {
            "category": "19-02-2020",
            "value": 62.3592,
            "value2": 31.42
          },
          {
            "category": "20-02-2020",
            "value": 62.6115,
            "value2": 31.38
          },
          {
            "category": "21-02-2020",
            "value": 62.6265,
            "value2": 31.38
          },
          {
            "category": "22-02-2020",
            "value": 62.6265,
            "value2": 31.38
          },
          {
            "category": "23-02-2020",
            "value": 62.6265,
            "value2": 31.38
          },
          {
            "category": "24-02-2020",
            "value": 61.9881,
            "value2": 30.75
          },
          {
            "category": "25-02-2020",
            "value": 61.5307,
            "value2": 30.65
          },
          {
            "category": "26-02-2020",
            "value": 60.683,
            "value2": 30.33
          },
          {
            "category": "27-02-2020",
            "value": 60.5708,
            "value2": 30.19
          },
          {
            "category": "28-02-2020",
            "value": 58.8303,
            "value2": 29.09
          },
          {
            "category": "29-02-2020",
            "value": 58.6938,
            "value2": 29.09
          },
          {
            "category": "01-03-2020",
            "value": 58.6938,
            "value2": 29.09
          },
          {
            "category": "02-03-2020",
            "value": 58.5283,
            "value2": 28.93
          },
          {
            "category": "03-03-2020",
            "value": 59.8756,
            "value2": 29.41
          },
          {
            "category": "04-03-2020",
            "value": 59.2377,
            "value2": 29.2
          },
          {
            "category": "05-03-2020",
            "value": 59.5941,
            "value2": 29.26
          },
          {
            "category": "06-03-2020",
            "value": 58.3303,
            "value2": 28.57
          },
          {
            "category": "07-03-2020",
            "value": 58.3303,
            "value2": 28.57
          },
          {
            "category": "08-03-2020",
            "value": 58.3303,
            "value2": 28.57
          },
          {
            "category": "09-03-2020",
            "value": 56.2649,
            "value2": 27.2
          },
          {
            "category": "10-03-2020",
            "value": 56.2649,
            "value2": 27.2
          },
          {
            "category": "11-03-2020",
            "value": 55.8913,
            "value2": 27.17
          },
          {
            "category": "12-03-2020",
            "value": 51.8637,
            "value2": 24.91
          },
          {
            "category": "13-03-2020",
            "value": 53.1066,
            "value2": 25.76
          },
          {
            "category": "14-03-2020",
            "value": 53.1066,
            "value2": 25.76
          },
          {
            "category": "15-03-2020",
            "value": 53.1066,
            "value2": 25.76
          },
          {
            "category": "16-03-2020",
            "value": 49.905,
            "value2": 23.94
          },
          {
            "category": "17-03-2020",
            "value": 48.7692,
            "value2": 23.4
          },
          {
            "category": "18-03-2020",
            "value": 46.1954,
            "value2": 22.13
          },
          {
            "category": "19-03-2020",
            "value": 44.7355,
            "value2": 21.49
          },
          {
            "category": "20-03-2020",
            "value": 46.8033,
            "value2": 22.64
          },
          {
            "category": "21-03-2020",
            "value": 46.8033,
            "value2": 22.64
          },
          {
            "category": "22-03-2020",
            "value": 46.8033,
            "value2": 22.64
          },
          {
            "category": "23-03-2020",
            "value": 40.7821,
            "value2": 19.74
          },
          {
            "category": "24-03-2020",
            "value": 41.5324,
            "value2": 20.15
          },
          {
            "category": "25-03-2020",
            "value": 42.9196,
            "value2": 21.28
          },
          {
            "category": "26-03-2020",
            "value": 44.8028,
            "value2": 22.25
          },
          {
            "category": "27-03-2020",
            "value": 44.3927,
            "value2": 22.16
          },
          {
            "category": "28-03-2020",
            "value": 44.3928,
            "value2": 22.16
          },
          {
            "category": "29-03-2020",
            "value": 44.3928,
            "value2": 22.16
          },
          {
            "category": "30-03-2020",
            "value": 42.8333,
            "value2": 21.38
          },
          {
            "category": "31-03-2020",
            "value": 43.6688,
            "value2": 22.15
          },
          {
            "category": "01-04-2020",
            "value": 42.1742,
            "value2": 21.41
          },
          {
            "category": "02-04-2020",
            "value": 42.1741,
            "value2": 21.41
          },
          {
            "category": "03-04-2020",
            "value": 41.0727,
            "value2": 21.01
          },
          {
            "category": "04-04-2020",
            "value": 41.0727,
            "value2": 21.01
          },
          {
            "category": "05-04-2020",
            "value": 41.0727,
            "value2": 21.01
          },
          {
            "category": "06-04-2020",
            "value": 41.0727,
            "value2": 21.01
          },
          {
            "category": "07-04-2020",
            "value": 43.2091,
            "value2": 22.64
          },
          {
            "category": "08-04-2020",
            "value": 43.9231,
            "value2": 22.68
          },
          {
            "category": "09-04-2020",
            "value": 45.4686,
            "value2": 23.56
          },
          {
            "category": "10-04-2020",
            "value": 45.4686,
            "value2": 23.56
          },
          {
            "category": "11-04-2020",
            "value": 45.4679,
            "value2": 23.56
          },
          {
            "category": "12-04-2020",
            "value": 45.4672,
            "value2": 23.56
          },
          {
            "category": "13-04-2020",
            "value": 44.7327,
            "value2": 23.3
          },
          {
            "category": "14-04-2020",
            "value": 44.7327,
            "value2": 23.3
          },
          {
            "category": "15-04-2020",
            "value": 45.1045,
            "value2": 23.25
          },
          {
            "category": "16-04-2020",
            "value": 45.2108,
            "value2": 23.49
          },
          {
            "category": "17-04-2020",
            "value": 46.2518,
            "value2": 24.12
          },
          {
            "category": "18-04-2020",
            "value": 46.2518,
            "value2": 24.12
          },
          {
            "category": "19-04-2020",
            "value": 46.2518,
            "value2": 24.12
          },
          {
            "category": "20-04-2020",
            "value": 46.086,
            "value2": 24.12
          },
          {
            "category": "21-04-2020",
            "value": 45.0111,
            "value2": 23.41
          },
          {
            "category": "22-04-2020",
            "value": 45.353,
            "value2": 23.86
          },
          {
            "category": "23-04-2020",
            "value": 45.8728,
            "value2": 24.15
          },
          {
            "category": "24-04-2020",
            "value": 45.4519,
            "value2": 23.74
          },
          {
            "category": "25-04-2020",
            "value": 45.4519,
            "value2": 23.74
          },
          {
            "category": "26-04-2020",
            "value": 45.4519,
            "value2": 23.74
          },
          {
            "category": "27-04-2020",
            "value": 46.5898,
            "value2": 24.09
          },
          {
            "category": "28-04-2020",
            "value": 46.8058,
            "value2": 24.31
          },
          {
            "category": "29-04-2020",
            "value": 46.7784,
            "value2": 24.7
          },
          {
            "category": "30-04-2020",
            "value": 47.6594,
            "value2": 25.39
          },
          {
            "category": "01-05-2020",
            "value": 47.6594,
            "value2": 25.39
          },
          {
            "category": "02-05-2020",
            "value": 47.6594,
            "value2": 25.39
          },
          {
            "category": "03-05-2020",
            "value": 47.6594,
            "value2": 25.39
          },
          {
            "category": "04-05-2020",
            "value": 45.1736,
            "value2": 24.05
          },
          {
            "category": "05-05-2020",
            "value": 44.4118,
            "value2": 23.83
          },
          {
            "category": "06-05-2020",
            "value": 44.6679,
            "value2": 24
          },
          {
            "category": "07-05-2020",
            "value": 44.0812,
            "value2": 23.83
          },
          {
            "category": "08-05-2020",
            "value": 44.5711,
            "value2": 23.93
          },
          {
            "category": "09-05-2020",
            "value": 44.5711,
            "value2": 23.93
          },
          {
            "category": "10-05-2020",
            "value": 44.5711,
            "value2": 23.93
          },
          {
            "category": "11-05-2020",
            "value": 44.5228,
            "value2": 23.95
          },
          {
            "category": "12-05-2020",
            "value": 43.7649,
            "value2": 23.83
          },
          {
            "category": "13-05-2020",
            "value": 44.2832,
            "value2": 24.29
          },
          {
            "category": "14-05-2020",
            "value": 44.1758,
            "value2": 23.81
          },
          {
            "category": "15-05-2020",
            "value": 44.1535,
            "value2": 23.77
          },
          {
            "category": "16-05-2020",
            "value": 44.1535,
            "value2": 23.77
          },
          {
            "category": "17-05-2020",
            "value": 44.1533,
            "value2": 23.77
          },
          {
            "category": "18-05-2020",
            "value": 42.5178,
            "value2": 22.95
          },
          {
            "category": "19-05-2020",
            "value": 42.6006,
            "value2": 23.07
          },
          {
            "category": "20-05-2020",
            "value": 43.7171,
            "value2": 23.53
          },
          {
            "category": "21-05-2020",
            "value": 43.8258,
            "value2": 23.64
          },
          {
            "category": "22-05-2020",
            "value": 43.4122,
            "value2": 23.48
          },
          {
            "category": "23-05-2020",
            "value": 43.4122,
            "value2": 23.48
          },
          {
            "category": "24-05-2020",
            "value": 43.4122,
            "value2": 23.48
          },
          {
            "category": "25-05-2020",
            "value": 43.4122,
            "value2": 23.48
          },
          {
            "category": "26-05-2020",
            "value": 43.8261,
            "value2": 23.51
          },
          {
            "category": "27-05-2020",
            "value": 44.5216,
            "value2": 24.07
          },
          {
            "category": "28-05-2020",
            "value": 45.472,
            "value2": 24.5
          },
          {
            "category": "29-05-2020",
            "value": 46.0664,
            "value2": 24.8
          },
          {
            "category": "30-05-2020",
            "value": 46.0664,
            "value2": 24.8
          },
          {
            "category": "31-05-2020",
            "value": 45.9599,
            "value2": 24.8
          },
          {
            "category": "01-06-2020",
            "value": 47.3081,
            "value2": 25.42
          },
          {
            "category": "02-06-2020",
            "value": 48.3551,
            "value2": 25.8
          },
          {
            "category": "03-06-2020",
            "value": 48.9483,
            "value2": 26.01
          },
          {
            "category": "04-06-2020",
            "value": 48.5787,
            "value2": 25.96
          },
          {
            "category": "05-06-2020",
            "value": 49.2738,
            "value2": 26.31
          },
          {
            "category": "06-06-2020",
            "value": 49.2738,
            "value2": 26.31
          },
          {
            "category": "07-06-2020",
            "value": 49.2738,
            "value2": 26.31
          },
          {
            "category": "08-06-2020",
            "value": 49.3215,
            "value2": 26.39
          },
          {
            "category": "09-06-2020",
            "value": 48.9258,
            "value2": 26.12
          },
          {
            "category": "10-06-2020",
            "value": 49.2035,
            "value2": 26.3
          },
          {
            "category": "11-06-2020",
            "value": 48.3478,
            "value2": 25.79
          },
          {
            "category": "12-06-2020",
            "value": 48.4217,
            "value2": 25.98
          },
          {
            "category": "13-06-2020",
            "value": 48.4217,
            "value2": 25.98
          },
          {
            "category": "14-06-2020",
            "value": 48.4217,
            "value2": 25.98
          },
          {
            "category": "15-06-2020",
            "value": 47.5437,
            "value2": 25.63
          },
          {
            "category": "16-06-2020",
            "value": 47.8623,
            "value2": 25.83
          },
          {
            "category": "17-06-2020",
            "value": 47.8441,
            "value2": 25.81
          },
          {
            "category": "18-06-2020",
            "value": 48.4681,
            "value2": 26.25
          },
          {
            "category": "19-06-2020",
            "value": 48.9634,
            "value2": 26.64
          },
          {
            "category": "20-06-2020",
            "value": 48.9634,
            "value2": 26.64
          },
          {
            "category": "21-06-2020",
            "value": 48.9634,
            "value2": 26.64
          },
          {
            "category": "22-06-2020",
            "value": 49.9351,
            "value2": 26.9
          },
          {
            "category": "23-06-2020",
            "value": 50.5529,
            "value2": 27.32
          },
          {
            "category": "24-06-2020",
            "value": 50.151,
            "value2": 26.92
          },
          {
            "category": "25-06-2020",
            "value": 50.5007,
            "value2": 26.94
          },
          {
            "category": "26-06-2020",
            "value": 50.3527,
            "value2": 27.14
          },
          {
            "category": "27-06-2020",
            "value": 50.3527,
            "value2": 27.14
          },
          {
            "category": "28-06-2020",
            "value": 50.3527,
            "value2": 27.14
          },
          {
            "category": "29-06-2020",
            "value": 49.7318,
            "value2": 26.92
          },
          {
            "category": "30-06-2020",
            "value": 49.9342,
            "value2": 26.86
          },
          {
            "category": "01-07-2020",
            "value": 50.0624,
            "value2": 27.12
          },
          {
            "category": "02-07-2020",
            "value": 50.422,
            "value2": 27.44
          },
          {
            "category": "03-07-2020",
            "value": 50.7285,
            "value2": 27.59
          },
          {
            "category": "04-07-2020",
            "value": 50.7289,
            "value2": 27.59
          },
          {
            "category": "05-07-2020",
            "value": 50.7289,
            "value2": 27.59
          },
          {
            "category": "06-07-2020",
            "value": 51.2091,
            "value2": 27.97
          },
          {
            "category": "07-07-2020",
            "value": 51.7482,
            "value2": 28.08
          },
          {
            "category": "08-07-2020",
            "value": 51.3764,
            "value2": 27.87
          },
          {
            "category": "09-07-2020",
            "value": 51.4519,
            "value2": 28.11
          },
          {
            "category": "10-07-2020",
            "value": 51.2221,
            "value2": 27.99
          },
          {
            "category": "11-07-2020",
            "value": 51.3075,
            "value2": 27.99
          },
          {
            "category": "12-07-2020",
            "value": 51.3075,
            "value2": 27.99
          },
          {
            "category": "13-07-2020",
            "value": 51.4012,
            "value2": 28.06
          },
          {
            "category": "14-07-2020",
            "value": 50.6875,
            "value2": 27.62
          },
          {
            "category": "15-07-2020",
            "value": 50.3529,
            "value2": 27.62
          },
          {
            "category": "16-07-2020",
            "value": 51.0335,
            "value2": 27.87
          },
          {
            "category": "17-07-2020",
            "value": 51.7158,
            "value2": 28.29
          },
          {
            "category": "18-07-2020",
            "value": 51.7157,
            "value2": 28.29
          },
          {
            "category": "19-07-2020",
            "value": 51.7157,
            "value2": 28.29
          },
          {
            "category": "20-07-2020",
            "value": 52.2177,
            "value2": 28.58
          },
          {
            "category": "21-07-2020",
            "value": 52.7551,
            "value2": 28.85
          },
          {
            "category": "22-07-2020",
            "value": 52.4868,
            "value2": 28.78
          },
          {
            "category": "23-07-2020",
            "value": 53.0971,
            "value2": 28.99
          },
          {
            "category": "24-07-2020",
            "value": 52.9574,
            "value2": 28.9
          },
          {
            "category": "25-07-2020",
            "value": 52.9574,
            "value2": 28.9
          },
          {
            "category": "26-07-2020",
            "value": 52.9574,
            "value2": 28.9
          },
          {
            "category": "27-07-2020",
            "value": 52.2587,
            "value2": 28.71
          },
          {
            "category": "28-07-2020",
            "value": 52.8754,
            "value2": 29.09
          },
          {
            "category": "29-07-2020",
            "value": 53.0135,
            "value2": 28.95
          },
          {
            "category": "30-07-2020",
            "value": 52.7754,
            "value2": 28.75
          },
          {
            "category": "31-07-2020",
            "value": 52.8284,
            "value2": 28.77
          },
          {
            "category": "01-08-2020",
            "value": 52.8284,
            "value2": 28.77
          },
          {
            "category": "02-08-2020",
            "value": 52.8284,
            "value2": 28.77
          },
          {
            "category": "03-08-2020",
            "value": 52.4323,
            "value2": 28.44
          },
          {
            "category": "04-08-2020",
            "value": 53.0793,
            "value2": 28.9
          },
          {
            "category": "05-08-2020",
            "value": 53.289,
            "value2": 28.95
          },
          {
            "category": "06-08-2020",
            "value": 53.5874,
            "value2": 29.22
          },
          {
            "category": "07-08-2020",
            "value": 53.9524,
            "value2": 29.31
          },
          {
            "category": "08-08-2020",
            "value": 53.9524,
            "value2": 29.31
          },
          {
            "category": "09-08-2020",
            "value": 53.9524,
            "value2": 29.31
          },
          {
            "category": "10-08-2020",
            "value": 54.2196,
            "value2": 29.52
          },
          {
            "category": "11-08-2020",
            "value": 54.1402,
            "value2": 29.6
          },
          {
            "category": "12-08-2020",
            "value": 54.1536,
            "value2": 29.58
          },
          {
            "category": "13-08-2020",
            "value": 54.5978,
            "value2": 29.67
          },
          {
            "category": "14-08-2020",
            "value": 53.9193,
            "value2": 29.37
          },
          {
            "category": "15-08-2020",
            "value": 53.9193,
            "value2": 29.37
          },
          {
            "category": "16-08-2020",
            "value": 53.9193,
            "value2": 29.37
          },
          {
            "category": "17-08-2020",
            "value": 54.5445,
            "value2": 29.54
          },
          {
            "category": "18-08-2020",
            "value": 55.2344,
            "value2": 29.9
          },
          {
            "category": "19-08-2020",
            "value": 55.2583,
            "value2": 30.01
          },
          {
            "category": "20-08-2020",
            "value": 55.3912,
            "value2": 29.86
          },
          {
            "category": "21-08-2020",
            "value": 55.7345,
            "value2": 30.04
          },
          {
            "category": "22-08-2020",
            "value": 55.7345,
            "value2": 30.04
          },
          {
            "category": "23-08-2020",
            "value": 55.7345,
            "value2": 30.04
          },
          {
            "category": "24-08-2020",
            "value": 56.1583,
            "value2": 30.27
          },
          {
            "category": "25-08-2020",
            "value": 56.673,
            "value2": 30.31
          },
          {
            "category": "26-08-2020",
            "value": 56.9427,
            "value2": 30.49
          },
          {
            "category": "27-08-2020",
            "value": 57.0206,
            "value2": 30.55
          },
          {
            "category": "28-08-2020",
            "value": 57.5592,
            "value2": 30.73
          },
          {
            "category": "29-08-2020",
            "value": 57.5592,
            "value2": 30.73
          },
          {
            "category": "30-08-2020",
            "value": 57.5592,
            "value2": 30.73
          },
          {
            "category": "31-08-2020",
            "value": 55.3381,
            "value2": 29.89
          },
          {
            "category": "01-09-2020",
            "value": 55.8928,
            "value2": 30.12
          },
          {
            "category": "02-09-2020",
            "value": 56.4069,
            "value2": 30.36
          },
          {
            "category": "03-09-2020",
            "value": 56.4715,
            "value2": 30.41
          },
          {
            "category": "04-09-2020",
            "value": 55.6109,
            "value2": 29.91
          },
          {
            "category": "05-09-2020",
            "value": 55.6109,
            "value2": 29.91
          },
          {
            "category": "06-09-2020",
            "value": 55.6109,
            "value2": 29.91
          },
          {
            "category": "07-09-2020",
            "value": 55.4053,
            "value2": 29.91
          },
          {
            "category": "08-09-2020",
            "value": 54.7881,
            "value2": 29.76
          },
          {
            "category": "09-09-2020",
            "value": 54.7995,
            "value2": 29.64
          },
          {
            "category": "10-09-2020",
            "value": 55.2488,
            "value2": 30.06
          },
          {
            "category": "11-09-2020",
            "value": 55.568,
            "value2": 30.14
          },
          {
            "category": "12-09-2020",
            "value": 55.568,
            "value2": 30.14
          },
          {
            "category": "13-09-2020",
            "value": 55.568,
            "value2": 30.14
          },
          {
            "category": "14-09-2020",
            "value": 56.7297,
            "value2": 30.3
          },
          {
            "category": "15-09-2020",
            "value": 57.1039,
            "value2": 30.56
          },
          {
            "category": "16-09-2020",
            "value": 57.3281,
            "value2": 30.75
          },
          {
            "category": "17-09-2020",
            "value": 56.9931,
            "value2": 30.55
          },
          {
            "category": "18-09-2020",
            "value": 56.6738,
            "value2": 30.53
          },
          {
            "category": "19-09-2020",
            "value": 56.6738,
            "value2": 30.53
          },
          {
            "category": "20-09-2020",
            "value": 56.6738,
            "value2": 30.53
          },
          {
            "category": "21-09-2020",
            "value": 55.5457,
            "value2": 29.73
          },
          {
            "category": "22-09-2020",
            "value": 54.8796,
            "value2": 29.43
          },
          {
            "category": "23-09-2020",
            "value": 55.055,
            "value2": 29.36
          },
          {
            "category": "24-09-2020",
            "value": 53.9294,
            "value2": 28.56
          },
          {
            "category": "25-09-2020",
            "value": 54.8926,
            "value2": 29.24
          },
          {
            "category": "26-09-2020",
            "value": 54.8926,
            "value2": 29.24
          },
          {
            "category": "27-09-2020",
            "value": 54.8926,
            "value2": 29.24
          },
          {
            "category": "28-09-2020",
            "value": 56.1202,
            "value2": 29.79
          },
          {
            "category": "29-09-2020",
            "value": 55.9272,
            "value2": 29.76
          },
          {
            "category": "30-09-2020",
            "value": 55.9378,
            "value2": 29.82
          },
          {
            "category": "01-10-2020",
            "value": 56.3241,
            "value2": 30.2
          },
          {
            "category": "05-10-2020",
            "value": 56.1385,
            "value2": 30.38
          },
          {
            "category": "06-10-2020",
            "value": 56.3711,
            "value2": 30.72
          },
          {
            "category": "07-10-2020",
            "value": 56.421,
            "value2": 30.81
          },
          {
            "category": "08-10-2020",
            "value": 56.6669,
            "value2": 31
          },
          {
            "category": "09-10-2020",
            "value": 56.6651,
            "value2": 31.12
          },
          {
            "category": "12-10-2020",
            "value": 56.4456,
            "value2": 31.09
          },
          {
            "category": "13-10-2020",
            "value": 56.656,
            "value2": 31.07
          },
          {
            "category": "14-10-2020",
            "value": 56.7471,
            "value2": 31.15
          },
          {
            "category": "15-10-2020",
            "value": 55.717,
            "value2": 30.47
          },
          {
            "category": "16-10-2020",
            "value": 56.3623,
            "value2": 30.71
          },
          {
            "category": "19-10-2020",
            "value": 56.8068,
            "value2": 30.98
          },
          {
            "category": "20-10-2020",
            "value": 56.7309,
            "value2": 31.06
          },
          {
            "category": "21-10-2020",
            "value": 56.7334,
            "value2": 31.13
          },
          {
            "category": "22-10-2020",
            "value": 56.8924,
            "value2": 31.11
          },
          {
            "category": "23-10-2020",
            "value": 57.2368,
            "value2": 31.23
          },
          {
            "category": "26-10-2020",
            "value": 56.5218,
            "value2": 30.81
          },
          {
            "category": "27-10-2020",
            "value": 58.0632,
            "value2": 31.13
          },
          {
            "category": "28-10-2020",
            "value": 57.5238,
            "value2": 30.75
          },
          {
            "category": "29-10-2020",
            "value": 57.5165,
            "value2": 30.64
          },
          {
            "category": "30-10-2020",
            "value": 57.1731,
            "value2": 30.6
          },
          {
            "category": "31-10-2020",
            "value": 57.0385,
            "value2": 30.6
          },
          {
            "category": "02-11-2020",
            "value": 57.0685,
            "value2": 30.65
          },
          {
            "category": "03-11-2020",
            "value": 57.5568,
            "value2": 30.96
          },
          {
            "category": "04-11-2020",
            "value": 58.2801,
            "value2": 31.19
          },
          {
            "category": "05-11-2020",
            "value": 59.3337,
            "value2": 31.74
          },
          {
            "category": "06-11-2020",
            "value": 59.8545,
            "value2": 32.04
          },
          {
            "category": "09-11-2020",
            "value": 60.9328,
            "value2": 32.5
          },
          {
            "category": "10-11-2020",
            "value": 60.4341,
            "value2": 32.8
          },
          {
            "category": "11-11-2020",
            "value": 60.6949,
            "value2": 33.08
          },
          {
            "category": "12-11-2020",
            "value": 60.6392,
            "value2": 33.03
          },
          {
            "category": "13-11-2020",
            "value": 61.0514,
            "value2": 33.18
          },
          {
            "category": "14-11-2020",
            "value": 61.4205,
            "value2": 33.35
          },
          {
            "category": "16-11-2020",
            "value": 61.4201,
            "value2": 33.35
          },
          {
            "category": "17-11-2020",
            "value": 61.8133,
            "value2": 33.61
          },
          {
            "category": "18-11-2020",
            "value": 62.1061,
            "value2": 33.81
          },
          {
            "category": "19-11-2020",
            "value": 61.6981,
            "value2": 33.46
          },
          {
            "category": "20-11-2020",
            "value": 62.2222,
            "value2": 33.71
          },
          {
            "category": "23-11-2020",
            "value": 62.7569,
            "value2": 33.96
          },
          {
            "category": "24-11-2020",
            "value": 63.598,
            "value2": 34.27
          },
          {
            "category": "25-11-2020",
            "value": 62.3739,
            "value2": 33.77
          },
          {
            "category": "26-11-2020",
            "value": 62.8679,
            "value2": 34.1
          },
          {
            "category": "27-11-2020",
            "value": 63.8339,
            "value2": 34.21
          },
          {
            "category": "28-11-2020",
            "value": 63.8339,
            "value2": 34.21
          },
          {
            "category": "29-11-2020",
            "value": 63.8339,
            "value2": 34.21
          },
          {
            "category": "30-11-2020",
            "value": 63.8339,
            "value2": 34.21
          },
          {
            "category": "01-12-2020",
            "value": 64.07,
            "value2": 34.59
          },
          {
            "category": "02-12-2020",
            "value": 63.93,
            "value2": 34.68
          },
          {
            "category": "03-12-2020",
            "value": 64.06,
            "value2": 34.81
          },
          {
            "category": "04-12-2020",
            "value": 64.65,
            "value2": 35.09
          },
          {
            "category": "07-12-2020",
            "value": 65.07,
            "value2": 35.39
          },
          {
            "category": "08-12-2020",
            "value": 64.93,
            "value2": 35.44
          },
          {
            "category": "09-12-2020",
            "value": 65.29,
            "value2": 35.72
          },
          {
            "category": "10-12-2020",
            "value": 65.11,
            "value2": 35.6
          },
          {
            "category": "11-12-2020",
            "value": 65.12,
            "value2": 35.69
          },
          {
            "category": "14-12-2020",
            "value": 65.49,
            "value2": 35.83
          },
          {
            "category": "15-12-2020",
            "value": 65.89,
            "value2": 35.86
          },
          {
            "category": "16-12-2020",
            "value": 66.25,
            "value2": 36.17
          },
          {
            "category": "17-12-2020",
            "value": 66.37,
            "value2": 36.25
          },
          {
            "category": "18-12-2020",
            "value": 66.97,
            "value2": 36.29
          },
          {
            "category": "21-12-2020",
            "value": 65.08,
            "value2": 35.02
          },
          {
            "category": "22-12-2020",
            "value": 65.6,
            "value2": 35.41
          },
          {
            "category": "23-12-2020",
            "value": 66.6,
            "value2": 35.89
          },
          {
            "category": "24-12-2020",
            "value": 66.92,
            "value2": 36.22
          },
          {
            "category": "28-12-2020",
            "value": 67.5,
            "value2": 36.57
          },
          {
            "category": "29-12-2020",
            "value": 67.38,
            "value2": 36.68
          },
          {
            "category": "30-12-2020",
            "value": 67.59,
            "value2": 36.81
          },
          {
            "category": "31-12-2020",
            "value": 67.4,
            "value2": 36.84
          },
          {
            "category": "01-01-2021",
            "value": 67.97,
            "value2": 37.02
          },
          {
            "category": "04-01-2021",
            "value": 68.71,
            "value2": 37.41
          },
          {
            "category": "05-01-2021",
            "value": 69.08,
            "value2": 37.65
          },
          {
            "category": "06-01-2021",
            "value": 69.37,
            "value2": 37.56
          },
          {
            "category": "07-01-2021",
            "value": 69.52,
            "value2": 37.66
          },
          {
            "category": "08-01-2021",
            "value": 70.64,
            "value2": 38.16
          },
          {
            "category": "11-01-2021",
            "value": 70.79,
            "value2": 38.41
          },
          {
            "category": "12-01-2021",
            "value": 70.72,
            "value2": 38.59
          },
          {
            "category": "13-01-2021",
            "value": 70.74,
            "value2": 38.54
          },
          {
            "category": "14-01-2021",
            "value": 71.37,
            "value2": 38.63
          },
          {
            "category": "15-01-2021",
            "value": 70.06,
            "value2": 38.19
          },
          {
            "category": "18-01-2021",
            "value": 69.24,
            "value2": 37.67
          },
          {
            "category": "19-01-2021",
            "value": 70.4,
            "value2": 38.35
          },
          {
            "category": "20-01-2021",
            "value": 70.82,
            "value2": 38.66
          },
          {
            "category": "21-01-2021",
            "value": 70.57,
            "value2": 38.46
          },
          {
            "category": "22-01-2021",
            "value": 70.06,
            "value2": 37.95
          },
          {
            "category": "25-01-2021",
            "value": 69.4846,
            "value2": 37.59
          },
          {
            "category": "26-01-2021",
            "value": 69.4846,
            "value2": 37.59
          },
          {
            "category": "27-01-2021",
            "value": 68.7755,
            "value2": 36.97
          },
          {
            "category": "28-01-2021",
            "value": 68.4449,
            "value2": 36.65
          },
          {
            "category": "29-01-2021",
            "value": 67.9048,
            "value2": 36.19
          },
          {
            "category": "30-01-2021",
            "value": 67.9048,
            "value2": 36.19
          },
          {
            "category": "31-01-2021",
            "value": 67.7414,
            "value2": 36.19
          },
          {
            "category": "01-02-2021",
            "value": 69.8468,
            "value2": 37.7
          },
          {
            "category": "02-02-2021",
            "value": 71.5304,
            "value2": 38.59
          },
          {
            "category": "03-02-2021",
            "value": 72.3485,
            "value2": 38.99
          },
          {
            "category": "04-02-2021",
            "value": 73.1288,
            "value2": 39.34
          },
          {
            "category": "05-02-2021",
            "value": 73.2391,
            "value2": 39.31
          },
          {
            "category": "08-02-2021",
            "value": 74.1973,
            "value2": 39.84
          },
          {
            "category": "09-02-2021",
            "value": 74.242,
            "value2": 39.81
          },
          {
            "category": "10-02-2021",
            "value": 74.5497,
            "value2": 39.89
          },
          {
            "category": "11-02-2021",
            "value": 74.5703,
            "value2": 40.08
          },
          {
            "category": "12-02-2021",
            "value": 73.9227,
            "value2": 40.07
          },
          {
            "category": "15-02-2021",
            "value": 74.0483,
            "value2": 40.47
          },
          {
            "category": "16-02-2021",
            "value": 74.0952,
            "value2": 40.52
          },
          {
            "category": "17-02-2021",
            "value": 73.9935,
            "value2": 40.38
          },
          {
            "category": "18-02-2021",
            "value": 73.7332,
            "value2": 40.28
          },
          {
            "category": "19-02-2021",
            "value": 72.7789,
            "value2": 39.86
          },
          {
            "category": "22-02-2021",
            "value": 71.4448,
            "value2": 39.16
          },
          {
            "category": "23-02-2021",
            "value": 71.6922,
            "value2": 39.34
          },
          {
            "category": "24-02-2021",
            "value": 72.3702,
            "value2": 39.96
          },
          {
            "category": "25-02-2021",
            "value": 73.2406,
            "value2": 40.32
          },
          {
            "category": "26-02-2021",
            "value": 71.3323,
            "value2": 39.07
          },
          {
            "category": "27-02-2021",
            "value": 71.3323,
            "value2": 39.07
          },
          {
            "category": "28-02-2021",
            "value": 71.1777,
            "value2": 39.07
          },
          {
            "category": "01-03-2021",
            "value": 72.3337,
            "value2": 39.68
          },
          {
            "category": "02-03-2021",
            "value": 73.697,
            "value2": 40.16
          },
          {
            "category": "03-03-2021",
            "value": 74.6307,
            "value2": 40.95
          },
          {
            "category": "04-03-2021",
            "value": 74.9229,
            "value2": 40.71
          },
          {
            "category": "05-03-2021",
            "value": 73.7683,
            "value2": 40.23
          },
          {
            "category": "08-03-2021",
            "value": 74.2222,
            "value2": 40.32
          },
          {
            "category": "09-03-2021",
            "value": 74.462,
            "value2": 40.5
          },
          {
            "category": "10-03-2021",
            "value": 75.2214,
            "value2": 40.75
          },
          {
            "category": "11-03-2021",
            "value": 75.2214,
            "value2": 40.75
          },
          {
            "category": "12-03-2021",
            "value": 74.3803,
            "value2": 40.43
          },
          {
            "category": "15-03-2021",
            "value": 74.2127,
            "value2": 40.17
          },
          {
            "category": "16-03-2021",
            "value": 74.057,
            "value2": 40.19
          },
          {
            "category": "17-03-2021",
            "value": 72.5129,
            "value2": 39.57
          },
          {
            "category": "18-03-2021",
            "value": 71.7959,
            "value2": 39.1
          },
          {
            "category": "19-03-2021",
            "value": 72.2696,
            "value2": 39.57
          },
          {
            "category": "22-03-2021",
            "value": 72.4773,
            "value2": 39.65
          },
          {
            "category": "23-03-2021",
            "value": 72.7486,
            "value2": 39.9
          },
          {
            "category": "24-03-2021",
            "value": 71.4259,
            "value2": 39.2
          },
          {
            "category": "25-03-2021",
            "value": 70.5816,
            "value2": 38.55
          },
          {
            "category": "26-03-2021",
            "value": 71.5989,
            "value2": 39.04
          },
          {
            "category": "29-03-2021",
            "value": 71.5989,
            "value2": 39.04
          },
          {
            "category": "30-03-2021",
            "value": 73.0037,
            "value2": 39.8
          },
          {
            "category": "31-03-2021",
            "value": 72.5783,
            "value2": 39.56
          },
          {
            "category": "01-04-2021",
            "value": 73.2581,
            "value2": 40.1
          },
          {
            "category": "02-04-2021",
            "value": 73.2581,
            "value2": 40.1
          },
          {
            "category": "03-04-2021",
            "value": 73.2581,
            "value2": 40.1
          },
          {
            "category": "04-04-2021",
            "value": 72.544,
            "value2": 40.1
          },
          {
            "category": "05-04-2021",
            "value": 72.1657,
            "value2": 39.52
          },
          {
            "category": "06-04-2021",
            "value": 72.2249,
            "value2": 39.71
          },
          {
            "category": "07-04-2021",
            "value": 73.318,
            "value2": 40.1
          },
          {
            "category": "08-04-2021",
            "value": 73.7204,
            "value2": 40.3
          },
          {
            "category": "09-04-2021",
            "value": 73.7774,
            "value2": 40.26
          },
          {
            "category": "12-04-2021",
            "value": 71.3006,
            "value2": 38.63
          },
          {
            "category": "13-04-2021",
            "value": 71.817,
            "value2": 39.17
          },
          {
            "category": "14-04-2021",
            "value": 71.8164,
            "value2": 39.17
          },
          {
            "category": "15-04-2021",
            "value": 71.762,
            "value2": 39.33
          },
          {
            "category": "16-04-2021",
            "value": 72.3482,
            "value2": 39.52
          },
          {
            "category": "19-04-2021",
            "value": 71.3518,
            "value2": 38.81
          },
          {
            "category": "20-04-2021",
            "value": 70.6938,
            "value2": 38.74
          },
          {
            "category": "21-04-2021",
            "value": 70.6938,
            "value2": 38.74
          },
          {
            "category": "22-04-2021",
            "value": 70.8474,
            "value2": 38.98
          },
          {
            "category": "23-04-2021",
            "value": 70.7238,
            "value2": 38.9
          },
          {
            "category": "26-04-2021",
            "value": 71.4143,
            "value2": 39.26
          },
          {
            "category": "27-04-2021",
            "value": 72.096,
            "value2": 39.72
          },
          {
            "category": "28-04-2021",
            "value": 72.7945,
            "value2": 40.21
          },
          {
            "category": "29-04-2021",
            "value": 72.5095,
            "value2": 40.27
          },
          {
            "category": "30-04-2021",
            "value": 71.9633,
            "value2": 39.75
          },
          {
            "category": "03-05-2021",
            "value": 71.8528,
            "value2": 39.84
          },
          {
            "category": "04-05-2021",
            "value": 71.4745,
            "value2": 39.54
          },
          {
            "category": "05-05-2021",
            "value": 71.9586,
            "value2": 39.9
          },
          {
            "category": "06-05-2021",
            "value": 72.5132,
            "value2": 40.18
          },
          {
            "category": "07-05-2021",
            "value": 72.2423,
            "value2": 40.38
          },
          {
            "category": "10-05-2021",
            "value": 72.7643,
            "value2": 40.73
          },
          {
            "category": "11-05-2021",
            "value": 73.5084,
            "value2": 40.65
          },
          {
            "category": "12-05-2021",
            "value": 72.7874,
            "value2": 40.31
          },
          {
            "category": "13-05-2021",
            "value": 72.7874,
            "value2": 40.31
          },
          {
            "category": "14-05-2021",
            "value": 72.4768,
            "value2": 40.1
          },
          {
            "category": "17-05-2021",
            "value": 73.2371,
            "value2": 40.75
          },
          {
            "category": "18-05-2021",
            "value": 74.7863,
            "value2": 41.28
          },
          {
            "category": "19-05-2021",
            "value": 75.4609,
            "value2": 41.19
          },
          {
            "category": "20-05-2021",
            "value": 74.7796,
            "value2": 40.95
          },
          {
            "category": "21-05-2021",
            "value": 75.3216,
            "value2": 41.53
          },
          {
            "category": "24-05-2021",
            "value": 75.8597,
            "value2": 41.67
          },
          {
            "category": "25-05-2021",
            "value": 76.377,
            "value2": 41.68
          },
          {
            "category": "26-05-2021",
            "value": 76.6018,
            "value2": 41.87
          },
          {
            "category": "27-05-2021",
            "value": 76.96,
            "value2": 42.01
          },
          {
            "category": "28-05-2021",
            "value": 76.5422,
            "value2": 42.16
          },
          {
            "category": "31-05-2021",
            "value": 76.8039,
            "value2": 42.56
          },
          {
            "category": "01-06-2021",
            "value": 76.6488,
            "value2": 42.52
          },
          {
            "category": "02-06-2021",
            "value": 77.2592,
            "value2": 42.73
          },
          {
            "category": "03-06-2021",
            "value": 78.6036,
            "value2": 43.07
          },
          {
            "category": "04-06-2021",
            "value": 78.5148,
            "value2": 43.12
          },
          {
            "category": "07-06-2021",
            "value": 78.908,
            "value2": 43.39
          },
          {
            "category": "08-06-2021",
            "value": 78.9287,
            "value2": 43.45
          },
          {
            "category": "09-06-2021",
            "value": 78.1243,
            "value2": 43.13
          },
          {
            "category": "10-06-2021",
            "value": 78.8931,
            "value2": 43.55
          },
          {
            "category": "11-06-2021",
            "value": 78.8614,
            "value2": 43.71
          },
          {
            "category": "14-06-2021",
            "value": 78.5601,
            "value2": 43.69
          },
          {
            "category": "15-06-2021",
            "value": 78.9034,
            "value2": 43.86
          },
          {
            "category": "16-06-2021",
            "value": 78.3949,
            "value2": 43.55
          },
          {
            "category": "17-06-2021",
            "value": 77.9152,
            "value2": 43.28
          },
          {
            "category": "18-06-2021",
            "value": 77.3192,
            "value2": 43.16
          },
          {
            "category": "21-06-2021",
            "value": 77.4353,
            "value2": 43.38
          },
          {
            "category": "22-06-2021",
            "value": 77.6424,
            "value2": 43.51
          },
          {
            "category": "23-06-2021",
            "value": 77.7309,
            "value2": 43.29
          },
          {
            "category": "24-06-2021",
            "value": 77.8865,
            "value2": 43.43
          },
          {
            "category": "25-06-2021",
            "value": 78.8671,
            "value2": 43.66
          },
          {
            "category": "28-06-2021",
            "value": 78.9971,
            "value2": 43.64
          },
          {
            "category": "29-06-2021",
            "value": 78.7275,
            "value2": 43.49
          },
          {
            "category": "30-06-2021",
            "value": 78.4691,
            "value2": 43.47
          },
          {
            "category": "01-07-2021",
            "value": 78.3684,
            "value2": 43.4
          },
          {
            "category": "02-07-2021",
            "value": 78.4731,
            "value2": 43.54
          },
          {
            "category": "03-07-2021",
            "value": 78.4731,
            "value2": 43.54
          },
          {
            "category": "04-07-2021",
            "value": 78.4731,
            "value2": 43.54
          },
          {
            "category": "05-07-2021",
            "value": 78.9191,
            "value2": 43.83
          },
          {
            "category": "06-07-2021",
            "value": 78.7892,
            "value2": 43.79
          },
          {
            "category": "07-07-2021",
            "value": 79.3764,
            "value2": 43.96
          },
          {
            "category": "08-07-2021",
            "value": 79.2074,
            "value2": 43.65
          },
          {
            "category": "09-07-2021",
            "value": 79.4414,
            "value2": 43.65
          },
          {
            "category": "10-07-2021",
            "value": 79.4414,
            "value2": 43.65
          },
          {
            "category": "11-07-2021",
            "value": 79.4414,
            "value2": 43.65
          },
          {
            "category": "12-07-2021",
            "value": 79.8444,
            "value2": 43.72
          },
          {
            "category": "13-07-2021",
            "value": 79.9694,
            "value2": 43.97
          },
          {
            "category": "14-07-2021",
            "value": 80.5157,
            "value2": 44.07
          },
          {
            "category": "15-07-2021",
            "value": 81.4895,
            "value2": 44.25
          },
          {
            "category": "16-07-2021",
            "value": 81.308,
            "value2": 44.32
          },
          {
            "category": "17-07-2021",
            "value": 81.3079,
            "value2": 44.32
          },
          {
            "category": "18-07-2021",
            "value": 81.311,
            "value2": 44.32
          },
          {
            "category": "19-07-2021",
            "value": 80.7026,
            "value2": 43.93
          },
          {
            "category": "20-07-2021",
            "value": 80.2627,
            "value2": 43.53
          },
          {
            "category": "21-07-2021",
            "value": 80.2627,
            "value2": 43.53
          },
          {
            "category": "22-07-2021",
            "value": 81.7475,
            "value2": 44.1
          },
          {
            "category": "23-07-2021",
            "value": 81.9765,
            "value2": 44.17
          },
          {
            "category": "24-07-2021",
            "value": 81.9765,
            "value2": 44.17
          },
          {
            "category": "25-07-2021",
            "value": 81.9765,
            "value2": 44.17
          },
          {
            "category": "26-07-2021",
            "value": 81.9939,
            "value2": 44.13
          },
          {
            "category": "27-07-2021",
            "value": 81.5051,
            "value2": 43.93
          },
          {
            "category": "28-07-2021",
            "value": 81.0677,
            "value2": 43.84
          },
          {
            "category": "29-07-2021",
            "value": 81.5373,
            "value2": 44.07
          },
          {
            "category": "30-07-2021",
            "value": 81.9298,
            "value2": 44.13
          },
          {
            "category": "31-07-2021",
            "value": 81.7425,
            "value2": 44.13
          },
          {
            "category": "01-08-2021",
            "value": 81.7449,
            "value2": 44.13
          },
          {
            "category": "02-08-2021",
            "value": 82.1028,
            "value2": 44.51
          },
          {
            "category": "03-08-2021",
            "value": 82.8377,
            "value2": 45.02
          },
          {
            "category": "04-08-2021",
            "value": 83.0158,
            "value2": 45.13
          },
          {
            "category": "05-08-2021",
            "value": 83.2283,
            "value2": 45.17
          },
          {
            "category": "06-08-2021",
            "value": 83.1702,
            "value2": 45.09
          },
          {
            "category": "07-08-2021",
            "value": 83.1702,
            "value2": 45.09
          },
          {
            "category": "08-08-2021",
            "value": 83.1702,
            "value2": 45.09
          },
          {
            "category": "09-08-2021",
            "value": 83.1651,
            "value2": 45.02
          },
          {
            "category": "10-08-2021",
            "value": 83.0798,
            "value2": 44.88
          },
          {
            "category": "11-08-2021",
            "value": 82.9839,
            "value2": 44.83
          },
          {
            "category": "12-08-2021",
            "value": 83.6479,
            "value2": 45.15
          },
          {
            "category": "13-08-2021",
            "value": 83.7129,
            "value2": 45.44
          },
          {
            "category": "14-08-2021",
            "value": 83.7129,
            "value2": 45.44
          },
          {
            "category": "15-08-2021",
            "value": 83.7146,
            "value2": 45.44
          },
          {
            "category": "16-08-2021",
            "value": 82.9914,
            "value2": 45.47
          },
          {
            "category": "17-08-2021",
            "value": 83.7228,
            "value2": 45.61
          },
          {
            "category": "18-08-2021",
            "value": 83.4708,
            "value2": 45.54
          },
          {
            "category": "19-08-2021",
            "value": 83.4708,
            "value2": 45.54
          },
          {
            "category": "20-08-2021",
            "value": 82.4131,
            "value2": 45.05
          },
          {
            "category": "21-08-2021",
            "value": 82.4131,
            "value2": 45.05
          },
          {
            "category": "22-08-2021",
            "value": 82.4131,
            "value2": 45.05
          },
          {
            "category": "23-08-2021",
            "value": 82.3529,
            "value2": 45.01
          },
          {
            "category": "24-08-2021",
            "value": 82.3293,
            "value2": 45.45
          },
          {
            "category": "25-08-2021",
            "value": 82.1384,
            "value2": 45.55
          },
          {
            "category": "26-08-2021",
            "value": 82.1772,
            "value2": 45.58
          },
          {
            "category": "27-08-2021",
            "value": 82.7355,
            "value2": 45.86
          },
          {
            "category": "28-08-2021",
            "value": 82.7355,
            "value2": 45.86
          },
          {
            "category": "29-08-2021",
            "value": 82.7355,
            "value2": 45.86
          },
          {
            "category": "30-08-2021",
            "value": 83.5846,
            "value2": 46.53
          },
          {
            "category": "31-08-2021",
            "value": 84.3439,
            "value2": 47.04
          },
          {
            "category": "01-09-2021",
            "value": 84.443,
            "value2": 47.03
          },
          {
            "category": "02-09-2021",
            "value": 85.5143,
            "value2": 47.46
          },
          {
            "category": "03-09-2021",
            "value": 86.4999,
            "value2": 47.7
          },
          {
            "category": "04-09-2021",
            "value": 86.4999,
            "value2": 47.7
          },
          {
            "category": "05-09-2021",
            "value": 86.4999,
            "value2": 47.7
          },
          {
            "category": "06-09-2021",
            "value": 86.4901,
            "value2": 47.84
          },
          {
            "category": "07-09-2021",
            "value": 86.4214,
            "value2": 47.77
          },
          {
            "category": "08-09-2021",
            "value": 86.677,
            "value2": 47.85
          },
          {
            "category": "09-09-2021",
            "value": 86.826,
            "value2": 47.95
          },
          {
            "category": "10-09-2021",
            "value": 86.826,
            "value2": 47.95
          },
          {
            "category": "11-09-2021",
            "value": 86.826,
            "value2": 47.95
          },
          {
            "category": "12-09-2021",
            "value": 86.826,
            "value2": 47.95
          },
          {
            "category": "13-09-2021",
            "value": 86.935,
            "value2": 47.99
          },
          {
            "category": "14-09-2021",
            "value": 87.6924,
            "value2": 48.16
          },
          {
            "category": "15-09-2021",
            "value": 88.6921,
            "value2": 48.55
          },
          {
            "category": "16-09-2021",
            "value": 89.3279,
            "value2": 48.81
          },
          {
            "category": "17-09-2021",
            "value": 88.9815,
            "value2": 48.57
          },
          {
            "category": "18-09-2021",
            "value": 88.9816,
            "value2": 48.57
          },
          {
            "category": "19-09-2021",
            "value": 88.9816,
            "value2": 48.57
          },
          {
            "category": "20-09-2021",
            "value": 87.6381,
            "value2": 47.92
          },
          {
            "category": "21-09-2021",
            "value": 88.4572,
            "value2": 48.3
          },
          {
            "category": "22-09-2021",
            "value": 88.909,
            "value2": 48.46
          },
          {
            "category": "23-09-2021",
            "value": 89.921,
            "value2": 49.14
          },
          {
            "category": "24-09-2021",
            "value": 89.717,
            "value2": 49.09
          },
          {
            "category": "25-09-2021",
            "value": 89.717,
            "value2": 49.09
          },
          {
            "category": "26-09-2021",
            "value": 89.717,
            "value2": 49.09
          },
          {
            "category": "27-09-2021",
            "value": 89.2027,
            "value2": 49.1
          },
          {
            "category": "28-09-2021",
            "value": 88.2019,
            "value2": 48.79
          },
          {
            "category": "29-09-2021",
            "value": 88.243,
            "value2": 48.77
          },
          {
            "category": "30-09-2021",
            "value": 87.6915,
            "value2": 48.64
          },
          {
            "category": "01-10-2021",
            "value": 87.228,
            "value2": 48.51
          },
          {
            "category": "02-10-2021",
            "value": 87.228,
            "value2": 48.51
          },
          {
            "category": "03-10-2021",
            "value": 87.228,
            "value2": 48.51
          },
          {
            "category": "04-10-2021",
            "value": 87.7939,
            "value2": 49.06
          },
          {
            "category": "05-10-2021",
            "value": 88.1854,
            "value2": 49.39
          },
          {
            "category": "06-10-2021",
            "value": 87.4051,
            "value2": 48.92
          },
          {
            "category": "07-10-2021",
            "value": 88.9336,
            "value2": 49.42
          },
          {
            "category": "08-10-2021",
            "value": 89.0663,
            "value2": 49.65
          },
          {
            "category": "09-10-2021",
            "value": 89.0663,
            "value2": 49.65
          },
          {
            "category": "10-10-2021",
            "value": 89.0663,
            "value2": 49.65
          },
          {
            "category": "11-10-2021",
            "value": 89.4307,
            "value2": 49.86
          },
          {
            "category": "12-10-2021",
            "value": 89.4211,
            "value2": 50.04
          },
          {
            "category": "13-10-2021",
            "value": 89.753,
            "value2": 50.53
          },
          {
            "category": "14-10-2021",
            "value": 90.4059,
            "value2": 50.97
          },
          {
            "category": "15-10-2021",
            "value": 90.4059,
            "value2": 50.97
          },
          {
            "category": "16-10-2021",
            "value": 90.4059,
            "value2": 50.97
          },
          {
            "category": "17-10-2021",
            "value": 90.4059,
            "value2": 50.97
          },
          {
            "category": "18-10-2021",
            "value": 90.8938,
            "value2": 51.35
          },
          {
            "category": "19-10-2021",
            "value": 91.0264,
            "value2": 50.93
          },
          {
            "category": "20-10-2021",
            "value": 89.5288,
            "value2": 50.33
          },
          {
            "category": "21-10-2021",
            "value": 89.4266,
            "value2": 50.08
          },
          {
            "category": "22-10-2021",
            "value": 89.1737,
            "value2": 49.79
          },
          {
            "category": "23-10-2021",
            "value": 89.1737,
            "value2": 49.79
          },
          {
            "category": "24-10-2021",
            "value": 89.1736,
            "value2": 49.79
          },
          {
            "category": "25-10-2021",
            "value": 89.0624,
            "value2": 49.58
          },
          {
            "category": "26-10-2021",
            "value": 89.8051,
            "value2": 50.14
          },
          {
            "category": "27-10-2021",
            "value": 90.2382,
            "value2": 50.08
          },
          {
            "category": "28-10-2021",
            "value": 88.6359,
            "value2": 49.13
          },
          {
            "category": "29-10-2021",
            "value": 88.6667,
            "value2": 48.79
          },
          {
            "category": "30-10-2021",
            "value": 88.6667,
            "value2": 48.79
          },
          {
            "category": "31-10-2021",
            "value": 88.4606,
            "value2": 48.79
          },
          {
            "category": "01-11-2021",
            "value": 90.0148,
            "value2": 49.48
          },
          {
            "category": "02-11-2021",
            "value": 89.8395,
            "value2": 49.52
          },
          {
            "category": "03-11-2021",
            "value": 89.693,
            "value2": 49.42
          },
          {
            "category": "04-11-2021",
            "value": 90.3166,
            "value2": 49.72
          },
          {
            "category": "05-11-2021",
            "value": 90.3166,
            "value2": 49.72
          },
          {
            "category": "06-11-2021",
            "value": 90.3166,
            "value2": 49.72
          },
          {
            "category": "07-11-2021",
            "value": 90.3166,
            "value2": 49.72
          },
          {
            "category": "08-11-2021",
            "value": 91.411,
            "value2": 50.18
          },
          {
            "category": "09-11-2021",
            "value": 92.112,
            "value2": 50.25
          },
          {
            "category": "10-11-2021",
            "value": 91.8061,
            "value2": 50.19
          },
          {
            "category": "11-11-2021",
            "value": 90.8034,
            "value2": 49.86
          },
          {
            "category": "12-11-2021",
            "value": 91.6404,
            "value2": 50.4
          },
          {
            "category": "13-11-2021",
            "value": 91.6404,
            "value2": 50.4
          },
          {
            "category": "14-11-2021",
            "value": 91.6404,
            "value2": 50.4
          },
          {
            "category": "15-11-2021",
            "value": 91.5679,
            "value2": 50.42
          },
          {
            "category": "16-11-2021",
            "value": 91.2329,
            "value2": 50.2
          },
          {
            "category": "17-11-2021",
            "value": 91.403,
            "value2": 49.98
          },
          {
            "category": "18-11-2021",
            "value": 90.3147,
            "value2": 49.49
          },
          {
            "category": "19-11-2021",
            "value": 90.3147,
            "value2": 49.49
          },
          {
            "category": "20-11-2021",
            "value": 90.3147,
            "value2": 49.49
          },
          {
            "category": "21-11-2021",
            "value": 90.3147,
            "value2": 49.49
          },
          {
            "category": "22-11-2021",
            "value": 88.1065,
            "value2": 48.44
          },
          {
            "category": "23-11-2021",
            "value": 89.1006,
            "value2": 48.86
          },
          {
            "category": "24-11-2021",
            "value": 88.7267,
            "value2": 48.66
          },
          {
            "category": "25-11-2021",
            "value": 89.196,
            "value2": 48.99
          },
          {
            "category": "26-11-2021",
            "value": 86.9172,
            "value2": 47.54
          },
          {
            "category": "27-11-2021",
            "value": 86.9172,
            "value2": 47.54
          },
          {
            "category": "28-11-2021",
            "value": 86.9172,
            "value2": 47.54
          },
          {
            "category": "29-11-2021",
            "value": 86.8823,
            "value2": 47.39
          },
          {
            "category": "30-11-2021",
            "value": 86.828,
            "value2": 47.37
          },
          {
            "category": "01-12-2021",
            "value": 87.7046,
            "value2": 47.81
          },
          {
            "category": "02-12-2021",
            "value": 88.6799,
            "value2": 48.41
          },
          {
            "category": "03-12-2021",
            "value": 88.4511,
            "value2": 48.02
          },
          {
            "category": "04-12-2021",
            "value": 88.4511,
            "value2": 48.02
          },
          {
            "category": "05-12-2021",
            "value": 88.4511,
            "value2": 48.02
          },
          {
            "category": "06-12-2021",
            "value": 86.9851,
            "value2": 47.25
          },
          {
            "category": "07-12-2021",
            "value": 88.2208,
            "value2": 47.95
          },
          {
            "category": "08-12-2021",
            "value": 89.151,
            "value2": 48.72
          },
          {
            "category": "09-12-2021",
            "value": 89.2845,
            "value2": 48.89
          },
          {
            "category": "10-12-2021",
            "value": 89.6336,
            "value2": 48.98
          },
          {
            "category": "11-12-2021",
            "value": 89.6336,
            "value2": 48.98
          },
          {
            "category": "12-12-2021",
            "value": 89.6336,
            "value2": 48.98
          },
          {
            "category": "13-12-2021",
            "value": 89.5943,
            "value2": 48.69
          },
          {
            "category": "14-12-2021",
            "value": 89.372,
            "value2": 48.61
          },
          {
            "category": "15-12-2021",
            "value": 88.8119,
            "value2": 48.31
          },
          {
            "category": "16-12-2021",
            "value": 88.8719,
            "value2": 48.27
          },
          {
            "category": "17-12-2021",
            "value": 87.5972,
            "value2": 47.43
          },
          {
            "category": "18-12-2021",
            "value": 87.5972,
            "value2": 47.43
          },
          {
            "category": "19-12-2021",
            "value": 87.5972,
            "value2": 47.43
          },
          {
            "category": "20-12-2021",
            "value": 85.2689,
            "value2": 46.23
          },
          {
            "category": "21-12-2021",
            "value": 86.4505,
            "value2": 46.74
          },
          {
            "category": "22-12-2021",
            "value": 87.687,
            "value2": 47.33
          },
          {
            "category": "23-12-2021",
            "value": 88.3997,
            "value2": 47.68
          },
          {
            "category": "24-12-2021",
            "value": 87.934,
            "value2": 47.42
          },
          {
            "category": "25-12-2021",
            "value": 87.9341,
            "value2": 47.42
          },
          {
            "category": "26-12-2021",
            "value": 87.9341,
            "value2": 47.42
          },
          {
            "category": "27-12-2021",
            "value": 88.7269,
            "value2": 47.62
          },
          {
            "category": "28-12-2021",
            "value": 89.4191,
            "value2": 48.07
          },
          {
            "category": "29-12-2021",
            "value": 89.4564,
            "value2": 48.06
          },
          {
            "category": "30-12-2021",
            "value": 89.4296,
            "value2": 47.99
          },
          {
            "category": "31-12-2021",
            "value": 90.0204,
            "value2": 48.49
          },
          {
            "category": "01-01-2022",
            "value": 90.0212,
            "value2": 48.49
          },
          {
            "category": "02-01-2022",
            "value": 90.0212,
            "value2": 48.49
          },
          {
            "category": "03-01-2022",
            "value": 91.348,
            "value2": 49.16
          },
          {
            "category": "04-01-2022",
            "value": 92.3521,
            "value2": 49.52
          },
          {
            "category": "05-01-2022",
            "value": 92.5542,
            "value2": 49.78
          },
          {
            "category": "06-01-2022",
            "value": 92.3593,
            "value2": 49.45
          },
          {
            "category": "07-01-2022",
            "value": 93.0804,
            "value2": 49.64
          },
          {
            "category": "08-01-2022",
            "value": 93.0804,
            "value2": 49.64
          },
          {
            "category": "09-01-2022",
            "value": 93.0804,
            "value2": 49.64
          },
          {
            "category": "10-01-2022",
            "value": 93.2363,
            "value2": 50.13
          },
          {
            "category": "11-01-2022",
            "value": 93.1296,
            "value2": 50.25
          },
          {
            "category": "12-01-2022",
            "value": 93.5444,
            "value2": 50.69
          },
          {
            "category": "13-01-2022",
            "value": 93.6309,
            "value2": 50.86
          },
          {
            "category": "14-01-2022",
            "value": 93.7836,
            "value2": 50.88
          },
          {
            "category": "15-01-2022",
            "value": 93.7836,
            "value2": 50.88
          },
          {
            "category": "16-01-2022",
            "value": 93.7836,
            "value2": 50.88
          },
          {
            "category": "17-01-2022",
            "value": 93.83,
            "value2": 51.04
          },
          {
            "category": "18-01-2022",
            "value": 92.3083,
            "value2": 50.39
          },
          {
            "category": "19-01-2022",
            "value": 91.1138,
            "value2": 50.06
          },
          {
            "category": "20-01-2022",
            "value": 90.7199,
            "value2": 49.71
          },
          {
            "category": "21-01-2022",
            "value": 88.5583,
            "value2": 49.12
          },
          {
            "category": "22-01-2022",
            "value": 88.5583,
            "value2": 49.12
          },
          {
            "category": "23-01-2022",
            "value": 88.5583,
            "value2": 49.12
          },
          {
            "category": "24-01-2022",
            "value": 85.7423,
            "value2": 47.61
          },
          {
            "category": "25-01-2022",
            "value": 86.6266,
            "value2": 48.01
          },
          {
            "category": "26-01-2022",
            "value": 86.6264,
            "value2": 48.01
          },
          {
            "category": "27-01-2022",
            "value": 85.6736,
            "value2": 47.52
          },
          {
            "category": "28-01-2022",
            "value": 86.1182,
            "value2": 47.65
          },
          {
            "category": "29-01-2022",
            "value": 86.1182,
            "value2": 47.65
          },
          {
            "category": "30-01-2022",
            "value": 86.1182,
            "value2": 47.65
          },
          {
            "category": "31-01-2022",
            "value": 87.2185,
            "value2": 48.31
          },
          {
            "category": "01-02-2022",
            "value": 87.8478,
            "value2": 48.94
          },
          {
            "category": "02-02-2022",
            "value": 88.7097,
            "value2": 49.5
          },
          {
            "category": "03-02-2022",
            "value": 87.7573,
            "value2": 48.99
          },
          {
            "category": "04-02-2022",
            "value": 87.0974,
            "value2": 48.83
          },
          {
            "category": "05-02-2022",
            "value": 87.0974,
            "value2": 48.83
          },
          {
            "category": "06-02-2022",
            "value": 87.0974,
            "value2": 48.83
          },
          {
            "category": "07-02-2022",
            "value": 85.6433,
            "value2": 48.15
          },
          {
            "category": "08-02-2022",
            "value": 85.1742,
            "value2": 48.1
          },
          {
            "category": "09-02-2022",
            "value": 86.4039,
            "value2": 48.63
          },
          {
            "category": "10-02-2022",
            "value": 86.6861,
            "value2": 48.93
          },
          {
            "category": "11-02-2022",
            "value": 84.9963,
            "value2": 48.23
          },
          {
            "category": "12-02-2022",
            "value": 84.9963,
            "value2": 48.23
          },
          {
            "category": "13-02-2022",
            "value": 84.9963,
            "value2": 48.23
          },
          {
            "category": "14-02-2022",
            "value": 82.6634,
            "value2": 46.67
          },
          {
            "category": "15-02-2022",
            "value": 84.7545,
            "value2": 47.98
          },
          {
            "category": "16-02-2022",
            "value": 84.5316,
            "value2": 47.95
          },
          {
            "category": "17-02-2022",
            "value": 84.341,
            "value2": 47.89
          },
          {
            "category": "18-02-2022",
            "value": 83.9013,
            "value2": 47.7
          },
          {
            "category": "19-02-2022",
            "value": 83.9013,
            "value2": 47.7
          },
          {
            "category": "20-02-2022",
            "value": 83.9013,
            "value2": 47.7
          },
          {
            "category": "21-02-2022",
            "value": 83.1758,
            "value2": 47.34
          },
          {
            "category": "22-02-2022",
            "value": 82.7604,
            "value2": 47
          },
          {
            "category": "23-02-2022",
            "value": 82.9821,
            "value2": 47.06
          },
          {
            "category": "24-02-2022",
            "value": 79.8337,
            "value2": 44.68
          },
          {
            "category": "25-02-2022",
            "value": 81.9268,
            "value2": 46.02
          },
          {
            "category": "26-02-2022",
            "value": 81.9268,
            "value2": 46.02
          },
          {
            "category": "27-02-2022",
            "value": 81.9268,
            "value2": 46.02
          },
          {
            "category": "28-02-2022",
            "value": 82.4956,
            "value2": 46.4
          },
          {
            "category": "01-03-2022",
            "value": 82.4973,
            "value2": 46.4
          },
          {
            "category": "02-03-2022",
            "value": 81.8766,
            "value2": 46.05
          },
          {
            "category": "03-03-2022",
            "value": 81.5157,
            "value2": 45.84
          },
          {
            "category": "04-03-2022",
            "value": 80.388,
            "value2": 45.07
          },
          {
            "category": "05-03-2022",
            "value": 80.388,
            "value2": 45.07
          },
          {
            "category": "06-03-2022",
            "value": 80.388,
            "value2": 45.07
          },
          {
            "category": "07-03-2022",
            "value": 78.7177,
            "value2": 43.99
          },
          {
            "category": "08-03-2022",
            "value": 79.6713,
            "value2": 44.45
          },
          {
            "category": "09-03-2022",
            "value": 80.6198,
            "value2": 45.35
          },
          {
            "category": "10-03-2022",
            "value": 81.5334,
            "value2": 46.01
          },
          {
            "category": "11-03-2022",
            "value": 82.0005,
            "value2": 46.17
          },
          {
            "category": "12-03-2022",
            "value": 82.0005,
            "value2": 46.17
          },
          {
            "category": "13-03-2022",
            "value": 82.0005,
            "value2": 46.17
          },
          {
            "category": "14-03-2022",
            "value": 82.4479,
            "value2": 46.61
          },
          {
            "category": "15-03-2022",
            "value": 81.3077,
            "value2": 46.1
          },
          {
            "category": "16-03-2022",
            "value": 82.5536,
            "value2": 46.96
          },
          {
            "category": "17-03-2022",
            "value": 83.744,
            "value2": 47.75
          },
          {
            "category": "18-03-2022",
            "value": 83.744,
            "value2": 47.75
          },
          {
            "category": "19-03-2022",
            "value": 83.744,
            "value2": 47.75
          },
          {
            "category": "20-03-2022",
            "value": 83.744,
            "value2": 47.75
          },
          {
            "category": "21-03-2022",
            "value": 82.9186,
            "value2": 47.37
          },
          {
            "category": "22-03-2022",
            "value": 83.5122,
            "value2": 47.77
          },
          {
            "category": "23-03-2022",
            "value": 83.5369,
            "value2": 47.66
          },
          {
            "category": "24-03-2022",
            "value": 83.0179,
            "value2": 47.69
          },
          {
            "category": "25-03-2022",
            "value": 82.3394,
            "value2": 47.55
          },
          {
            "category": "26-03-2022",
            "value": 82.3394,
            "value2": 47.55
          },
          {
            "category": "27-03-2022",
            "value": 82.3394,
            "value2": 47.55
          },
          {
            "category": "28-03-2022",
            "value": 82.1163,
            "value2": 47.64
          },
          {
            "category": "29-03-2022",
            "value": 83.0439,
            "value2": 47.94
          },
          {
            "category": "30-03-2022",
            "value": 84.103,
            "value2": 48.38
          },
          {
            "category": "31-03-2022",
            "value": 84.0067,
            "value2": 48.37
          },
          {
            "category": "01-04-2022",
            "value": 84.7593,
            "value2": 49
          },
          {
            "category": "02-04-2022",
            "value": 84.7593,
            "value2": 49
          },
          {
            "category": "03-04-2022",
            "value": 84.7593,
            "value2": 49
          },
          {
            "category": "04-04-2022",
            "value": 85.7475,
            "value2": 49.97
          },
          {
            "category": "05-04-2022",
            "value": 86.0327,
            "value2": 50
          },
          {
            "category": "06-04-2022",
            "value": 85.7967,
            "value2": 49.79
          },
          {
            "category": "07-04-2022",
            "value": 85.0824,
            "value2": 49.38
          },
          {
            "category": "08-04-2022",
            "value": 85.9006,
            "value2": 49.84
          },
          {
            "category": "09-04-2022",
            "value": 85.9006,
            "value2": 49.84
          },
          {
            "category": "10-04-2022",
            "value": 85.9006,
            "value2": 49.84
          },
          {
            "category": "11-04-2022",
            "value": 85.6542,
            "value2": 49.79
          },
          {
            "category": "12-04-2022",
            "value": 84.6563,
            "value2": 49.27
          },
          {
            "category": "13-04-2022",
            "value": 84.5473,
            "value2": 49.19
          },
          {
            "category": "14-04-2022",
            "value": 84.5466,
            "value2": 49.19
          },
          {
            "category": "15-04-2022",
            "value": 84.5456,
            "value2": 49.19
          },
          {
            "category": "16-04-2022",
            "value": 84.5447,
            "value2": 49.19
          },
          {
            "category": "17-04-2022",
            "value": 84.5441,
            "value2": 49.19
          },
          {
            "category": "18-04-2022",
            "value": 83.283,
            "value2": 48.5
          },
          {
            "category": "19-04-2022",
            "value": 82.1832,
            "value2": 47.86
          },
          {
            "category": "20-04-2022",
            "value": 82.4411,
            "value2": 48.27
          },
          {
            "category": "21-04-2022",
            "value": 83.6168,
            "value2": 48.93
          },
          {
            "category": "22-04-2022",
            "value": 82.5274,
            "value2": 48.42
          },
          {
            "category": "23-04-2022",
            "value": 82.5274,
            "value2": 48.42
          },
          {
            "category": "24-04-2022",
            "value": 82.5274,
            "value2": 48.42
          },
          {
            "category": "25-04-2022",
            "value": 81.2521,
            "value2": 47.72
          },
          {
            "category": "26-04-2022",
            "value": 82.0109,
            "value2": 48.45
          },
          {
            "category": "27-04-2022",
            "value": 81.4141,
            "value2": 48
          },
          {
            "category": "28-04-2022",
            "value": 82.4009,
            "value2": 48.52
          },
          {
            "category": "29-04-2022",
            "value": 81.8126,
            "value2": 48.09
          },
          {
            "category": "30-04-2022",
            "value": 81.6267,
            "value2": 48.09
          },
          {
            "category": "01-05-2022",
            "value": 81.6265,
            "value2": 48.09
          },
          {
            "category": "02-05-2022",
            "value": 80.6186,
            "value2": 47.94
          },
          {
            "category": "03-05-2022",
            "value": 80.6186,
            "value2": 47.94
          },
          {
            "category": "04-05-2022",
            "value": 79.1913,
            "value2": 46.82
          },
          {
            "category": "05-05-2022",
            "value": 79.7449,
            "value2": 46.85
          },
          {
            "category": "06-05-2022",
            "value": 78.1339,
            "value2": 46.05
          },
          {
            "category": "07-05-2022",
            "value": 78.1339,
            "value2": 46.05
          },
          {
            "category": "08-05-2022",
            "value": 78.1339,
            "value2": 46.05
          },
          {
            "category": "09-05-2022",
            "value": 77.2073,
            "value2": 45.56
          },
          {
            "category": "10-05-2022",
            "value": 76.3633,
            "value2": 45.11
          },
          {
            "category": "11-05-2022",
            "value": 75.8803,
            "value2": 44.84
          },
          {
            "category": "12-05-2022",
            "value": 74.2673,
            "value2": 43.87
          },
          {
            "category": "13-05-2022",
            "value": 74.401,
            "value2": 43.87
          },
          {
            "category": "14-05-2022",
            "value": 74.401,
            "value2": 43.87
          },
          {
            "category": "15-05-2022",
            "value": 74.401,
            "value2": 43.87
          },
          {
            "category": "16-05-2022",
            "value": 75.2471,
            "value2": 44.19
          },
          {
            "category": "17-05-2022",
            "value": 77.0288,
            "value2": 45.35
          },
          {
            "category": "18-05-2022",
            "value": 77.0216,
            "value2": 45.32
          },
          {
            "category": "19-05-2022",
            "value": 74.8363,
            "value2": 44.14
          },
          {
            "category": "20-05-2022",
            "value": 76.3017,
            "value2": 45.26
          },
          {
            "category": "21-05-2022",
            "value": 76.3017,
            "value2": 45.26
          },
          {
            "category": "22-05-2022",
            "value": 76.3017,
            "value2": 45.26
          },
          {
            "category": "23-05-2022",
            "value": 76.4513,
            "value2": 45.07
          },
          {
            "category": "24-05-2022",
            "value": 76.0024,
            "value2": 44.78
          },
          {
            "category": "25-05-2022",
            "value": 75.051,
            "value2": 44.29
          },
          {
            "category": "26-05-2022",
            "value": 75.9582,
            "value2": 44.77
          },
          {
            "category": "27-05-2022",
            "value": 77.251,
            "value2": 45.26
          },
          {
            "category": "28-05-2022",
            "value": 77.251,
            "value2": 45.26
          },
          {
            "category": "29-05-2022",
            "value": 77.251,
            "value2": 45.26
          },
          {
            "category": "30-05-2022",
            "value": 78.3615,
            "value2": 46.17
          },
          {
            "category": "31-05-2022",
            "value": 78.0061,
            "value2": 46.09
          },
          {
            "category": "01-06-2022",
            "value": 77.9624,
            "value2": 45.96
          },
          {
            "category": "02-06-2022",
            "value": 78.1952,
            "value2": 46.23
          },
          {
            "category": "03-06-2022",
            "value": 77.4759,
            "value2": 45.93
          },
          {
            "category": "04-06-2022",
            "value": 77.4759,
            "value2": 45.93
          },
          {
            "category": "05-06-2022",
            "value": 77.4759,
            "value2": 45.93
          },
          {
            "category": "06-06-2022",
            "value": 77.2268,
            "value2": 45.86
          },
          {
            "category": "07-06-2022",
            "value": 76.5089,
            "value2": 45.48
          },
          {
            "category": "08-06-2022",
            "value": 76.3294,
            "value2": 45.33
          },
          {
            "category": "09-06-2022",
            "value": 77.0361,
            "value2": 45.63
          },
          {
            "category": "10-06-2022",
            "value": 76.1663,
            "value2": 44.99
          },
          {
            "category": "11-06-2022",
            "value": 76.1663,
            "value2": 44.99
          },
          {
            "category": "12-06-2022",
            "value": 76.1663,
            "value2": 44.99
          },
          {
            "category": "13-06-2022",
            "value": 74.1008,
            "value2": 43.8
          },
          {
            "category": "14-06-2022",
            "value": 73.9425,
            "value2": 43.7
          },
          {
            "category": "15-06-2022",
            "value": 74.2108,
            "value2": 43.71
          },
          {
            "category": "16-06-2022",
            "value": 72.3567,
            "value2": 42.76
          },
          {
            "category": "17-06-2022",
            "value": 71.7646,
            "value2": 42.47
          },
          {
            "category": "18-06-2022",
            "value": 71.7646,
            "value2": 42.47
          },
          {
            "category": "19-06-2022",
            "value": 71.7646,
            "value2": 42.47
          },
          {
            "category": "20-06-2022",
            "value": 71.4008,
            "value2": 42.31
          },
          {
            "category": "21-06-2022",
            "value": 72.8198,
            "value2": 43.27
          },
          {
            "category": "22-06-2022",
            "value": 71.7167,
            "value2": 42.64
          },
          {
            "category": "23-06-2022",
            "value": 72.7644,
            "value2": 43.07
          },
          {
            "category": "24-06-2022",
            "value": 73.3252,
            "value2": 43.54
          },
          {
            "category": "25-06-2022",
            "value": 73.3251,
            "value2": 43.54
          },
          {
            "category": "26-06-2022",
            "value": 73.3251,
            "value2": 43.54
          },
          {
            "category": "27-06-2022",
            "value": 73.9846,
            "value2": 43.93
          },
          {
            "category": "28-06-2022",
            "value": 73.8073,
            "value2": 44.01
          },
          {
            "category": "29-06-2022",
            "value": 73.5921,
            "value2": 43.86
          },
          {
            "category": "30-06-2022",
            "value": 73.1101,
            "value2": 43.75
          },
          {
            "category": "01-07-2022",
            "value": 73.4706,
            "value2": 43.79
          },
          {
            "category": "02-07-2022",
            "value": 73.4706,
            "value2": 43.79
          },
          {
            "category": "03-07-2022",
            "value": 73.4706,
            "value2": 43.79
          },
          {
            "category": "04-07-2022",
            "value": 73.997,
            "value2": 44.05
          },
          {
            "category": "05-07-2022",
            "value": 73.883,
            "value2": 44.01
          },
          {
            "category": "06-07-2022",
            "value": 75.2796,
            "value2": 44.58
          },
          {
            "category": "07-07-2022",
            "value": 76.3919,
            "value2": 45.01
          },
          {
            "category": "08-07-2022",
            "value": 76.5679,
            "value2": 45.22
          },
          {
            "category": "09-07-2022",
            "value": 76.5679,
            "value2": 45.22
          },
          {
            "category": "10-07-2022",
            "value": 76.5679,
            "value2": 45.22
          },
          {
            "category": "11-07-2022",
            "value": 76.8344,
            "value2": 45.41
          },
          {
            "category": "12-07-2022",
            "value": 76.1363,
            "value2": 45.07
          },
          {
            "category": "13-07-2022",
            "value": 76.4104,
            "value2": 44.89
          },
          {
            "category": "14-07-2022",
            "value": 76.3748,
            "value2": 44.83
          },
          {
            "category": "15-07-2022",
            "value": 76.949,
            "value2": 45.12
          },
          {
            "category": "16-07-2022",
            "value": 76.949,
            "value2": 45.12
          },
          {
            "category": "17-07-2022",
            "value": 76.949,
            "value2": 45.12
          },
          {
            "category": "18-07-2022",
            "value": 78.2152,
            "value2": 45.75
          },
          {
            "category": "19-07-2022",
            "value": 78.5298,
            "value2": 45.97
          },
          {
            "category": "20-07-2022",
            "value": 79.0975,
            "value2": 46.33
          },
          {
            "category": "21-07-2022",
            "value": 79.5475,
            "value2": 46.72
          },
          {
            "category": "22-07-2022",
            "value": 79.9674,
            "value2": 46.91
          },
          {
            "category": "23-07-2022",
            "value": 79.9674,
            "value2": 46.91
          },
          {
            "category": "24-07-2022",
            "value": 79.9674,
            "value2": 46.91
          },
          {
            "category": "25-07-2022",
            "value": 79.6127,
            "value2": 46.73
          },
          {
            "category": "26-07-2022",
            "value": 78.5583,
            "value2": 46.28
          },
          {
            "category": "27-07-2022",
            "value": 79.1821,
            "value2": 46.7
          },
          {
            "category": "28-07-2022",
            "value": 80.2023,
            "value2": 47.38
          },
          {
            "category": "29-07-2022",
            "value": 81.1353,
            "value2": 48.02
          },
          {
            "category": "30-07-2022",
            "value": 81.1353,
            "value2": 48.02
          },
          {
            "category": "31-07-2022",
            "value": 80.9584,
            "value2": 48.02
          },
          {
            "category": "01-08-2022",
            "value": 81.4663,
            "value2": 48.62
          },
          {
            "category": "02-08-2022",
            "value": 81.6007,
            "value2": 48.7
          },
          {
            "category": "03-08-2022",
            "value": 80.9748,
            "value2": 48.73
          },
          {
            "category": "04-08-2022",
            "value": 81.3013,
            "value2": 48.78
          },
          {
            "category": "05-08-2022",
            "value": 81.172,
            "value2": 48.8
          },
          {
            "category": "06-08-2022",
            "value": 81.172,
            "value2": 48.8
          },
          {
            "category": "07-08-2022",
            "value": 81.172,
            "value2": 48.8
          },
          {
            "category": "08-08-2022",
            "value": 81.4835,
            "value2": 49.14
          },
          {
            "category": "09-08-2022",
            "value": 81.4835,
            "value2": 49.14
          },
          {
            "category": "10-08-2022",
            "value": 81.0629,
            "value2": 49.14
          },
          {
            "category": "11-08-2022",
            "value": 82.0348,
            "value2": 49.53
          },
          {
            "category": "12-08-2022",
            "value": 82.2825,
            "value2": 49.68
          },
          {
            "category": "13-08-2022",
            "value": 82.2825,
            "value2": 49.68
          },
          {
            "category": "14-08-2022",
            "value": 82.2825,
            "value2": 49.68
          },
          {
            "category": "15-08-2022",
            "value": 82.2825,
            "value2": 49.68
          },
          {
            "category": "16-08-2022",
            "value": 83.173,
            "value2": 50.13
          },
          {
            "category": "17-08-2022",
            "value": 83.6382,
            "value2": 50.47
          },
          {
            "category": "18-08-2022",
            "value": 84.2104,
            "value2": 50.57
          },
          {
            "category": "19-08-2022",
            "value": 83.3548,
            "value2": 50.04
          },
          {
            "category": "20-08-2022",
            "value": 83.3548,
            "value2": 50.04
          },
          {
            "category": "21-08-2022",
            "value": 83.3548,
            "value2": 50.04
          },
          {
            "category": "22-08-2022",
            "value": 81.763,
            "value2": 49.28
          },
          {
            "category": "23-08-2022",
            "value": 82.5048,
            "value2": 49.57
          },
          {
            "category": "24-08-2022",
            "value": 82.8513,
            "value2": 49.71
          },
          {
            "category": "25-08-2022",
            "value": 83.1105,
            "value2": 49.58
          },
          {
            "category": "26-08-2022",
            "value": 83.1105,
            "value2": 49.72
          },
          {
            "category": "27-08-2022",
            "value": 81.8632,
            "value2": 49.72
          },
          {
            "category": "28-08-2022",
            "value": 82.8285,
            "value2": 49.72
          },
          {
            "category": "29-08-2022",
            "value": 83.1101,
            "value2": 49.17
          },
          {
            "category": "30-08-2022",
            "value": 83.8412,
            "value2": 50.33
          },
          {
            "category": "31-08-2022",
            "value": 83.6534,
            "value2": 50.33
          },
          {
            "category": "01-09-2022",
            "value": 83.3569,
            "value2": 49.95
          },
          {
            "category": "02-09-2022",
            "value": 83.2203,
            "value2": 49.91
          },
          {
            "category": "03-09-2022",
            "value": 83.2203,
            "value2": 49.91
          },
          {
            "category": "04-09-2022",
            "value": 83.2203,
            "value2": 49.91
          },
          {
            "category": "05-09-2022",
            "value": 83.4502,
            "value2": 50.21
          },
          {
            "category": "06-09-2022",
            "value": 83.3009,
            "value2": 50.28
          },
          {
            "category": "07-09-2022",
            "value": 83.4922,
            "value2": 50.3
          },
          {
            "category": "08-09-2022",
            "value": 84.1733,
            "value2": 50.69
          },
          {
            "category": "09-09-2022",
            "value": 84.6273,
            "value2": 50.78
          },
          {
            "category": "10-09-2022",
            "value": 84.6273,
            "value2": 50.78
          },
          {
            "category": "11-09-2022",
            "value": 84.6273,
            "value2": 50.78
          },
          {
            "category": "12-09-2022",
            "value": 84.9934,
            "value2": 51.14
          },
          {
            "category": "13-09-2022",
            "value": 85.3043,
            "value2": 51.45
          },
          {
            "category": "14-09-2022",
            "value": 85.0806,
            "value2": 51.32
          },
          {
            "category": "15-09-2022",
            "value": 85.0395,
            "value2": 51.16
          },
          {
            "category": "16-09-2022",
            "value": 82.9941,
            "value2": 50.07
          },
          {
            "category": "17-09-2022",
            "value": 82.9941,
            "value2": 50.07
          },
          {
            "category": "18-09-2022",
            "value": 82.9941,
            "value2": 50.07
          },
          {
            "category": "19-09-2022",
            "value": 82.9831,
            "value2": 50.21
          },
          {
            "category": "20-09-2022",
            "value": 84.1348,
            "value2": 50.79
          },
          {
            "category": "21-09-2022",
            "value": 83.7792,
            "value2": 50.44
          },
          {
            "category": "22-09-2022",
            "value": 83.9502,
            "value2": 50.37
          },
          {
            "category": "23-09-2022",
            "value": 82.6845,
            "value2": 49.46
          },
          {
            "category": "24-09-2022",
            "value": 82.6845,
            "value2": 49.46
          },
          {
            "category": "25-09-2022",
            "value": 82.6845,
            "value2": 49.46
          },
          {
            "category": "26-09-2022",
            "value": 80.9721,
            "value2": 48.36
          },
          {
            "category": "27-09-2022",
            "value": 80.6784,
            "value2": 48.37
          },
          {
            "category": "28-09-2022",
            "value": 80.3585,
            "value2": 48.01
          },
          {
            "category": "29-09-2022",
            "value": 80.3704,
            "value2": 47.96
          },
          {
            "category": "30-09-2022",
            "value": 81.1517,
            "value2": 48.71
          },
          {
            "category": "01-10-2022",
            "value": 81.1517,
            "value2": 48.71
          },
          {
            "category": "02-10-2022",
            "value": 81.1517,
            "value2": 48.71
          },
          {
            "category": "03-10-2022",
            "value": 79.8551,
            "value2": 48.08
          },
          {
            "category": "04-10-2022",
            "value": 81.7103,
            "value2": 49.15
          },
          {
            "category": "05-10-2022",
            "value": 81.7103,
            "value2": 49.15
          },
          {
            "category": "06-10-2022",
            "value": 82.2447,
            "value2": 49.44
          },
          {
            "category": "07-10-2022",
            "value": 81.9045,
            "value2": 49.38
          },
          {
            "category": "08-10-2022",
            "value": 81.9045,
            "value2": 49.38
          },
          {
            "category": "09-10-2022",
            "value": 81.9045,
            "value2": 49.38
          },
          {
            "category": "10-10-2022",
            "value": 81.4371,
            "value2": 49.1
          },
          {
            "category": "11-10-2022",
            "value": 80.3418,
            "value2": 48.34
          },
          {
            "category": "12-10-2022",
            "value": 80.6263,
            "value2": 48.7
          },
          {
            "category": "13-10-2022",
            "value": 79.9831,
            "value2": 48.38
          },
          {
            "category": "14-10-2022",
            "value": 80.2842,
            "value2": 48.66
          },
          {
            "category": "15-10-2022",
            "value": 80.2842,
            "value2": 48.66
          },
          {
            "category": "16-10-2022",
            "value": 80.2842,
            "value2": 48.66
          },
          {
            "category": "17-10-2022",
            "value": 80.8085,
            "value2": 48.97
          },
          {
            "category": "18-10-2022",
            "value": 81.8495,
            "value2": 49.45
          },
          {
            "category": "19-10-2022",
            "value": 81.9772,
            "value2": 49.49
          },
          {
            "category": "20-10-2022",
            "value": 82.2129,
            "value2": 49.64
          },
          {
            "category": "21-10-2022",
            "value": 82.2045,
            "value2": 49.55
          },
          {
            "category": "22-10-2022",
            "value": 82.2045,
            "value2": 49.55
          },
          {
            "category": "23-10-2022",
            "value": 82.2045,
            "value2": 49.55
          },
          {
            "category": "24-10-2022",
            "value": 82.7383,
            "value2": 49.94
          },
          {
            "category": "25-10-2022",
            "value": 82.8757,
            "value2": 49.8
          },
          {
            "category": "26-10-2022",
            "value": 82.8757,
            "value2": 49.8
          },
          {
            "category": "27-10-2022",
            "value": 82.4562,
            "value2": 50.1
          },
          {
            "category": "28-10-2022",
            "value": 82.0532,
            "value2": 50.1
          },
          {
            "category": "29-10-2022",
            "value": 82.0532,
            "value2": 50.1
          },
          {
            "category": "30-10-2022",
            "value": 82.0532,
            "value2": 50.1
          },
          {
            "category": "31-10-2022",
            "value": 82.6499,
            "value2": 50.7
          },
          {
            "category": "01-11-2022",
            "value": 83.2681,
            "value2": 51.1
          },
          {
            "category": "02-11-2022",
            "value": 83.3083,
            "value2": 50.96
          },
          {
            "category": "03-11-2022",
            "value": 82.7728,
            "value2": 50.93
          },
          {
            "category": "04-11-2022",
            "value": 82.6105,
            "value2": 51.08
          },
          {
            "category": "05-11-2022",
            "value": 82.6105,
            "value2": 51.08
          },
          {
            "category": "06-11-2022",
            "value": 82.6105,
            "value2": 51.08
          },
          {
            "category": "07-11-2022",
            "value": 82.9651,
            "value2": 51.36
          },
          {
            "category": "08-11-2022",
            "value": 82.9651,
            "value2": 51.36
          },
          {
            "category": "09-11-2022",
            "value": 82.8727,
            "value2": 51.21
          },
          {
            "category": "10-11-2022",
            "value": 82.2483,
            "value2": 50.8
          },
          {
            "category": "11-11-2022",
            "value": 82.2648,
            "value2": 51.48
          },
          {
            "category": "12-11-2022",
            "value": 82.2648,
            "value2": 51.48
          },
          {
            "category": "13-11-2022",
            "value": 82.2648,
            "value2": 51.48
          },
          {
            "category": "14-11-2022",
            "value": 81.7586,
            "value2": 51.48
          },
          {
            "category": "15-11-2022",
            "value": 81.8824,
            "value2": 51.63
          },
          {
            "category": "16-11-2022",
            "value": 81.7696,
            "value2": 51.52
          },
          {
            "category": "17-11-2022",
            "value": 81.493,
            "value2": 51.3
          },
          {
            "category": "18-11-2022",
            "value": 81.349,
            "value2": 51.15
          },
          {
            "category": "19-11-2022",
            "value": 81.349,
            "value2": 51.15
          },
          {
            "category": "20-11-2022",
            "value": 81.349,
            "value2": 51.15
          },
          {
            "category": "21-11-2022",
            "value": 80.9903,
            "value2": 50.84
          },
          {
            "category": "22-11-2022",
            "value": 81.1787,
            "value2": 51.02
          },
          {
            "category": "23-11-2022",
            "value": 81.362,
            "value2": 51.11
          },
          {
            "category": "24-11-2022",
            "value": 81.9263,
            "value2": 51.59
          },
          {
            "category": "25-11-2022",
            "value": 82.2178,
            "value2": 51.72
          },
          {
            "category": "26-11-2022",
            "value": 82.2178,
            "value2": 51.72
          },
          {
            "category": "27-11-2022",
            "value": 82.2178,
            "value2": 51.72
          },
          {
            "category": "28-11-2022",
            "value": 82.5795,
            "value2": 51.92
          },
          {
            "category": "29-11-2022",
            "value": 82.6246,
            "value2": 52.01
          },
          {
            "category": "30-11-2022",
            "value": 82.8314,
            "value2": 52.46
          },
          {
            "category": "01-12-2022",
            "value": 83.3909,
            "value2": 52.64
          },
          {
            "category": "02-12-2022",
            "value": 83.5104,
            "value2": 52.5
          },
          {
            "category": "03-12-2022",
            "value": 83.5104,
            "value2": 52.5
          },
          {
            "category": "04-12-2022",
            "value": 83.5104,
            "value2": 52.5
          },
          {
            "category": "05-12-2022",
            "value": 83.5124,
            "value2": 52.51
          },
          {
            "category": "06-12-2022",
            "value": 82.7887,
            "value2": 52.36
          },
          {
            "category": "07-12-2022",
            "value": 82.3617,
            "value2": 52.17
          },
          {
            "category": "08-12-2022",
            "value": 82.6317,
            "value2": 52.33
          },
          {
            "category": "09-12-2022",
            "value": 82.2505,
            "value2": 52
          },
          {
            "category": "10-12-2022",
            "value": 82.2505,
            "value2": 52
          },
          {
            "category": "11-12-2022",
            "value": 82.2505,
            "value2": 52
          },
          {
            "category": "12-12-2022",
            "value": 82.0167,
            "value2": 52.03
          },
          {
            "category": "13-12-2022",
            "value": 82.5027,
            "value2": 52.27
          },
          {
            "category": "14-12-2022",
            "value": 82.8296,
            "value2": 52.48
          },
          {
            "category": "15-12-2022",
            "value": 81.8142,
            "value2": 51.88
          },
          {
            "category": "16-12-2022",
            "value": 80.8913,
            "value2": 51.4
          },
          {
            "category": "17-12-2022",
            "value": 80.8913,
            "value2": 51.4
          },
          {
            "category": "18-12-2022",
            "value": 80.8913,
            "value2": 51.4
          },
          {
            "category": "19-12-2022",
            "value": 81.4043,
            "value2": 51.79
          },
          {
            "category": "20-12-2022",
            "value": 81.1652,
            "value2": 51.69
          },
          {
            "category": "21-12-2022",
            "value": 80.3257,
            "value2": 51.05
          },
          {
            "category": "22-12-2022",
            "value": 79.8599,
            "value2": 50.72
          },
          {
            "category": "23-12-2022",
            "value": 77.8213,
            "value2": 49.46
          },
          {
            "category": "24-12-2022",
            "value": 77.8213,
            "value2": 49.46
          },
          {
            "category": "25-12-2022",
            "value": 77.8213,
            "value2": 49.46
          },
          {
            "category": "26-12-2022",
            "value": 78.9991,
            "value2": 50.28
          },
          {
            "category": "27-12-2022",
            "value": 79.3823,
            "value2": 50.68
          },
          {
            "category": "28-12-2022",
            "value": 79.2715,
            "value2": 50.74
          },
          {
            "category": "29-12-2022",
            "value": 79.6289,
            "value2": 50.92
          },
          {
            "category": "30-12-2022",
            "value": 79.3148,
            "value2": 50.8
          },
          {
            "category": "31-12-2022",
            "value": 79.1265,
            "value2": 50.8
          },
          {
            "category": "01-01-2023",
            "value": 79.1319,
            "value2": 50.8
          },
          {
            "category": "02-01-2023",
            "value": 79.2727,
            "value2": 51.04
          },
          {
            "category": "03-01-2023",
            "value": 79.482,
            "value2": 51.16
          },
          {
            "category": "04-01-2023",
            "value": 79.0641,
            "value2": 50.61
          },
          {
            "category": "05-01-2023",
            "value": 79.0795,
            "value2": 50.58
          },
          {
            "category": "06-01-2023",
            "value": 78.5041,
            "value2": 50.21
          },
          {
            "category": "07-01-2023",
            "value": 78.5041,
            "value2": 50.21
          },
          {
            "category": "08-01-2023",
            "value": 78.5041,
            "value2": 50.21
          },
          {
            "category": "09-01-2023",
            "value": 79.2455,
            "value2": 50.8
          },
          {
            "category": "10-01-2023",
            "value": 78.7645,
            "value2": 50.38
          },
          {
            "category": "11-01-2023",
            "value": 78.9394,
            "value2": 50.31
          },
          {
            "category": "12-01-2023",
            "value": 78.6149,
            "value2": 50.24
          },
          {
            "category": "13-01-2023",
            "value": 78.3966,
            "value2": 50.45
          },
          {
            "category": "14-01-2023",
            "value": 78.3966,
            "value2": 50.45
          },
          {
            "category": "15-01-2023",
            "value": 78.3966,
            "value2": 50.45
          },
          {
            "category": "16-01-2023",
            "value": 78.2596,
            "value2": 50.33
          },
          {
            "category": "17-01-2023",
            "value": 78.252,
            "value2": 50.63
          },
          {
            "category": "18-01-2023",
            "value": 78.6873,
            "value2": 50.86
          },
          {
            "category": "19-01-2023",
            "value": 78.3011,
            "value2": 50.72
          },
          {
            "category": "20-01-2023",
            "value": 77.7191,
            "value2": 50.48
          },
          {
            "category": "21-01-2023",
            "value": 77.7191,
            "value2": 50.48
          },
          {
            "category": "22-01-2023",
            "value": 77.7191,
            "value2": 50.48
          },
          {
            "category": "23-01-2023",
            "value": 78.1523,
            "value2": 50.66
          },
          {
            "category": "24-01-2023",
            "value": 78.065,
            "value2": 50.61
          },
          {
            "category": "25-01-2023",
            "value": 76.9212,
            "value2": 49.9
          },
          {
            "category": "26-01-2023",
            "value": 76.9212,
            "value2": 49.9
          },
          {
            "category": "27-01-2023",
            "value": 75.9635,
            "value2": 48.92
          },
          {
            "category": "28-01-2023",
            "value": 75.9635,
            "value2": 48.92
          },
          {
            "category": "29-01-2023",
            "value": 75.9635,
            "value2": 48.92
          },
          {
            "category": "30-01-2023",
            "value": 76.2405,
            "value2": 48.84
          },
          {
            "category": "31-01-2023",
            "value": 76.743,
            "value2": 49.11
          },
          {
            "category": "01-02-2023",
            "value": 76.2511,
            "value2": 48.8
          },
          {
            "category": "02-02-2023",
            "value": 76.6568,
            "value2": 48.78
          },
          {
            "category": "03-02-2023",
            "value": 77.0233,
            "value2": 49.2
          },
          {
            "category": "04-02-2023",
            "value": 77.0233,
            "value2": 49.2
          },
          {
            "category": "05-02-2023",
            "value": 77.0233,
            "value2": 49.2
          },
          {
            "category": "06-02-2023",
            "value": 76.5906,
            "value2": 49.1
          },
          {
            "category": "07-02-2023",
            "value": 76.8052,
            "value2": 48.99
          },
          {
            "category": "08-02-2023",
            "value": 77.4848,
            "value2": 49.41
          },
          {
            "category": "09-02-2023",
            "value": 77.4579,
            "value2": 49.41
          },
          {
            "category": "10-02-2023",
            "value": 77.5862,
            "value2": 49.36
          },
          {
            "category": "11-02-2023",
            "value": 77.5861,
            "value2": 49.36
          },
          {
            "category": "12-02-2023",
            "value": 77.5861,
            "value2": 49.36
          },
          {
            "category": "13-02-2023",
            "value": 77.2859,
            "value2": 48.98
          },
          {
            "category": "14-02-2023",
            "value": 77.2874,
            "value2": 49.21
          },
          {
            "category": "15-02-2023",
            "value": 77.7628,
            "value2": 49.45
          },
          {
            "category": "16-02-2023",
            "value": 78.3161,
            "value2": 49.61
          },
          {
            "category": "17-02-2023",
            "value": 77.7909,
            "value2": 49.35
          },
          {
            "category": "18-02-2023",
            "value": 77.7909,
            "value2": 49.35
          },
          {
            "category": "19-02-2023",
            "value": 77.7909,
            "value2": 49.35
          },
          {
            "category": "20-02-2023",
            "value": 77.6511,
            "value2": 49.14
          },
          {
            "category": "21-02-2023",
            "value": 77.5152,
            "value2": 49.07
          },
          {
            "category": "22-02-2023",
            "value": 76.7787,
            "value2": 48.36
          },
          {
            "category": "23-02-2023",
            "value": 76.8033,
            "value2": 48.26
          },
          {
            "category": "24-02-2023",
            "value": 76.4567,
            "value2": 48.14
          },
          {
            "category": "25-02-2023",
            "value": 76.4567,
            "value2": 48.14
          },
          {
            "category": "26-02-2023",
            "value": 76.4567,
            "value2": 48.14
          },
          {
            "category": "27-02-2023",
            "value": 76.407,
            "value2": 47.86
          },
          {
            "category": "28-02-2023",
            "value": 76.2188,
            "value2": 47.76
          },
          {
            "category": "01-03-2023",
            "value": 76.8139,
            "value2": 48.24
          },
          {
            "category": "02-03-2023",
            "value": 76.2544,
            "value2": 47.99
          },
          {
            "category": "03-03-2023",
            "value": 76.8942,
            "value2": 48.61
          },
          {
            "category": "04-03-2023",
            "value": 76.8942,
            "value2": 48.61
          },
          {
            "category": "05-03-2023",
            "value": 76.8942,
            "value2": 48.61
          },
          {
            "category": "06-03-2023",
            "value": 77.1777,
            "value2": 48.96
          },
          {
            "category": "07-03-2023",
            "value": 77.1777,
            "value2": 48.96
          },
          {
            "category": "08-03-2023",
            "value": 77.3699,
            "value2": 49.1
          },
          {
            "category": "09-03-2023",
            "value": 76.5499,
            "value2": 48.73
          },
          {
            "category": "10-03-2023",
            "value": 75.9502,
            "value2": 48.31
          },
          {
            "category": "11-03-2023",
            "value": 75.9502,
            "value2": 48.31
          },
          {
            "category": "12-03-2023",
            "value": 75.9502,
            "value2": 48.31
          },
          {
            "category": "13-03-2023",
            "value": 74.8142,
            "value2": 47.58
          },
          {
            "category": "14-03-2023",
            "value": 74.4178,
            "value2": 47.27
          },
          {
            "category": "15-03-2023",
            "value": 74.4185,
            "value2": 47.13
          },
          {
            "category": "16-03-2023",
            "value": 74.3835,
            "value2": 47.18
          },
          {
            "category": "17-03-2023",
            "value": 74.7567,
            "value2": 47.47
          },
          {
            "category": "18-03-2023",
            "value": 74.7567,
            "value2": 47.47
          },
          {
            "category": "19-03-2023",
            "value": 74.7567,
            "value2": 47.47
          },
          {
            "category": "20-03-2023",
            "value": 74.1274,
            "value2": 47.13
          },
          {
            "category": "21-03-2023",
            "value": 74.39,
            "value2": 47.44
          },
          {
            "category": "22-03-2023",
            "value": 74.776,
            "value2": 47.6
          },
          {
            "category": "23-03-2023",
            "value": 74.4126,
            "value2": 47.41
          },
          {
            "category": "24-03-2023",
            "value": 74.0311,
            "value2": 47.01
          },
          {
            "category": "25-03-2023",
            "value": 74.0311,
            "value2": 47.01
          },
          {
            "category": "26-03-2023",
            "value": 74.0311,
            "value2": 47.01
          },
          {
            "category": "27-03-2023",
            "value": 73.7745,
            "value2": 46.95
          },
          {
            "category": "28-03-2023",
            "value": 73.422,
            "value2": 46.78
          },
          {
            "category": "29-03-2023",
            "value": 74.1127,
            "value2": 47.23
          },
          {
            "category": "30-03-2023",
            "value": 74.1127,
            "value2": 47.23
          },
          {
            "category": "31-03-2023",
            "value": 74.8625,
            "value2": 47.93
          },
          {
            "category": "01-04-2023",
            "value": 74.8645,
            "value2": 47.93
          },
          {
            "category": "02-04-2023",
            "value": 74.8645,
            "value2": 47.93
          },
          {
            "category": "03-04-2023",
            "value": 75.2719,
            "value2": 48.07
          },
          {
            "category": "04-04-2023",
            "value": 75.2719,
            "value2": 48.07
          },
          {
            "category": "05-04-2023",
            "value": 75.6906,
            "value2": 48.42
          },
          {
            "category": "06-04-2023",
            "value": 75.5809,
            "value2": 48.59
          },
          {
            "category": "07-04-2023",
            "value": 75.5809,
            "value2": 48.59
          },
          {
            "category": "08-04-2023",
            "value": 75.5809,
            "value2": 48.59
          },
          {
            "category": "09-04-2023",
            "value": 75.6528,
            "value2": 48.59
          },
          {
            "category": "10-04-2023",
            "value": 76.2649,
            "value2": 48.7
          },
          {
            "category": "11-04-2023",
            "value": 76.704,
            "value2": 48.96
          },
          {
            "category": "12-04-2023",
            "value": 76.9081,
            "value2": 49.2
          },
          {
            "category": "13-04-2023",
            "value": 76.9081,
            "value2": 49.25
          },
          {
            "category": "14-04-2023",
            "value": 76.9081,
            "value2": 49.25
          },
          {
            "category": "15-04-2023",
            "value": 76.9081,
            "value2": 49.25
          },
          {
            "category": "16-04-2023",
            "value": 76.7694,
            "value2": 49.25
          },
          {
            "category": "17-04-2023",
            "value": 76.9399,
            "value2": 49.07
          },
          {
            "category": "18-04-2023",
            "value": 76.9553,
            "value2": 49.05
          },
          {
            "category": "19-04-2023",
            "value": 75.5809,
            "value2": 48.92
          },
          {
            "category": "20-04-2023",
            "value": 77.0187,
            "value2": 48.93
          },
          {
            "category": "21-04-2023",
            "value": 76.9228,
            "value2": 48.88
          },
          {
            "category": "22-04-2023",
            "value": 76.9228,
            "value2": 48.88
          },
          {
            "category": "23-04-2023",
            "value": 76.9228,
            "value2": 48.88
          },
          {
            "category": "24-04-2023",
            "value": 77.4287,
            "value2": 49.16
          },
          {
            "category": "25-04-2023",
            "value": 77.4266,
            "value2": 49.24
          },
          {
            "category": "26-04-2023",
            "value": 77.4905,
            "value2": 49.38
          },
          {
            "category": "27-04-2023",
            "value": 78.1817,
            "value2": 49.65
          },
          {
            "category": "28-04-2023",
            "value": 79.1504,
            "value2": 50.13
          },
          {
            "category": "29-04-2023",
            "value": 79.1504,
            "value2": 50.13
          },
          {
            "category": "30-04-2023",
            "value": 78.9857,
            "value2": 50.13
          },
          {
            "category": "01-05-2023",
            "value": 78.9888,
            "value2": 50.13
          },
          {
            "category": "02-05-2023",
            "value": 79.4343,
            "value2": 50.41
          },
          {
            "category": "03-05-2023",
            "value": 79.059,
            "value2": 50.32
          },
          {
            "category": "04-05-2023",
            "value": 79.4161,
            "value2": 50.74
          },
          {
            "category": "05-05-2023",
            "value": 79.239,
            "value2": 50.33
          },
          {
            "category": "06-05-2023",
            "value": 79.239,
            "value2": 50.33
          },
          {
            "category": "07-05-2023",
            "value": 79.239,
            "value2": 50.33
          },
          {
            "category": "08-05-2023",
            "value": 80.0057,
            "value2": 50.82
          },
          {
            "category": "09-05-2023",
            "value": 79.8535,
            "value2": 50.79
          },
          {
            "category": "10-05-2023",
            "value": 80.1847,
            "value2": 50.95
          },
          {
            "category": "11-05-2023",
            "value": 80.1228,
            "value2": 51
          },
          {
            "category": "12-05-2023",
            "value": 80.1033,
            "value2": 50.99
          },
          {
            "category": "13-05-2023",
            "value": 80.1033,
            "value2": 50.99
          },
          {
            "category": "14-05-2023",
            "value": 80.1033,
            "value2": 50.99
          },
          {
            "category": "15-05-2023",
            "value": 80.597,
            "value2": 51.22
          },
          {
            "category": "16-05-2023",
            "value": 80.4354,
            "value2": 51.09
          },
          {
            "category": "17-05-2023",
            "value": 80.1129,
            "value2": 50.88
          },
          {
            "category": "18-05-2023",
            "value": 79.6484,
            "value2": 50.66
          },
          {
            "category": "19-05-2023",
            "value": 79.3107,
            "value2": 50.81
          },
          {
            "category": "20-05-2023",
            "value": 79.3107,
            "value2": 50.81
          },
          {
            "category": "21-05-2023",
            "value": 79.3107,
            "value2": 50.81
          },
          {
            "category": "22-05-2023",
            "value": 79.438,
            "value2": 51.13
          },
          {
            "category": "23-05-2023",
            "value": 79.6091,
            "value2": 51.25
          },
          {
            "category": "24-05-2023",
            "value": 79.4025,
            "value2": 51.17
          },
          {
            "category": "25-05-2023",
            "value": 79.6146,
            "value2": 51.3
          },
          {
            "category": "26-05-2023",
            "value": 79.8971,
            "value2": 51.77
          },
          {
            "category": "27-05-2023",
            "value": 79.8971,
            "value2": 51.77
          },
          {
            "category": "28-05-2023",
            "value": 79.8971,
            "value2": 51.77
          },
          {
            "category": "29-05-2023",
            "value": 80.0682,
            "value2": 52.01
          },
          {
            "category": "30-05-2023",
            "value": 80.4003,
            "value2": 52.1
          },
          {
            "category": "31-05-2023",
            "value": 80.4862,
            "value2": 52.02
          },
          {
            "category": "01-06-2023",
            "value": 80.101,
            "value2": 51.98
          },
          {
            "category": "02-06-2023",
            "value": 80.3399,
            "value2": 52.18
          },
          {
            "category": "03-06-2023",
            "value": 80.3399,
            "value2": 52.18
          },
          {
            "category": "04-06-2023",
            "value": 80.3399,
            "value2": 52.18
          },
          {
            "category": "05-06-2023",
            "value": 80.416,
            "value2": 52.35
          },
          {
            "category": "06-06-2023",
            "value": 80.5798,
            "value2": 52.41
          },
          {
            "category": "07-06-2023",
            "value": 80.9717,
            "value2": 52.82
          },
          {
            "category": "08-06-2023",
            "value": 80.4786,
            "value2": 52.54
          },
          {
            "category": "09-06-2023",
            "value": 79.8647,
            "value2": 52.4
          },
          {
            "category": "10-06-2023",
            "value": 79.8647,
            "value2": 52.4
          },
          {
            "category": "11-06-2023",
            "value": 79.8647,
            "value2": 52.4
          },
          {
            "category": "12-06-2023",
            "value": 79.9596,
            "value2": 52.57
          },
          {
            "category": "13-06-2023",
            "value": 80.2711,
            "value2": 52.97
          },
          {
            "category": "14-06-2023",
            "value": 79.978,
            "value2": 53.08
          },
          {
            "category": "15-06-2023",
            "value": 80.1326,
            "value2": 53
          },
          {
            "category": "16-06-2023",
            "value": 80.6235,
            "value2": 53.41
          },
          {
            "category": "17-06-2023",
            "value": 80.6235,
            "value2": 53.41
          },
          {
            "category": "18-06-2023",
            "value": 80.6235,
            "value2": 53.41
          },
          {
            "category": "19-06-2023",
            "value": 80.4621,
            "value2": 53.28
          },
          {
            "category": "20-06-2023",
            "value": 80.9681,
            "value2": 53.46
          },
          {
            "category": "21-06-2023",
            "value": 80.9992,
            "value2": 53.61
          },
          {
            "category": "22-06-2023",
            "value": 80.4023,
            "value2": 53.28
          },
          {
            "category": "23-06-2023",
            "value": 79.723,
            "value2": 52.86
          },
          {
            "category": "24-06-2023",
            "value": 79.723,
            "value2": 52.86
          },
          {
            "category": "25-06-2023",
            "value": 79.723,
            "value2": 52.86
          },
          {
            "category": "26-06-2023",
            "value": 79.9633,
            "value2": 53.04
          },
          {
            "category": "27-06-2023",
            "value": 80.6347,
            "value2": 53.36
          },
          {
            "category": "28-06-2023",
            "value": 80.8477,
            "value2": 53.73
          },
          {
            "category": "29-06-2023",
            "value": 80.8477,
            "value2": 53.73
          },
          {
            "category": "30-06-2023",
            "value": 81.3507,
            "value2": 54.25
          },
          {
            "category": "01-07-2023",
            "value": 81.3499,
            "value2": 54.25
          },
          {
            "category": "02-07-2023",
            "value": 81.3499,
            "value2": 54.25
          },
          {
            "category": "03-07-2023",
            "value": 81.5438,
            "value2": 54.56
          },
          {
            "category": "04-07-2023",
            "value": 81.1739,
            "value2": 54.66
          },
          {
            "category": "05-07-2023",
            "value": 81.9694,
            "value2": 54.8
          },
          {
            "category": "06-07-2023",
            "value": 81.9061,
            "value2": 55.12
          },
          {
            "category": "07-07-2023",
            "value": 81.1019,
            "value2": 54.7
          },
          {
            "category": "08-07-2023",
            "value": 81.1019,
            "value2": 54.7
          },
          {
            "category": "09-07-2023",
            "value": 81.1019,
            "value2": 54.7
          },
          {
            "category": "10-07-2023",
            "value": 80.8921,
            "value2": 54.67
          },
          {
            "category": "11-07-2023",
            "value": 81.6885,
            "value2": 54.98
          },
          {
            "category": "12-07-2023",
            "value": 82.2703,
            "value2": 54.95
          },
          {
            "category": "13-07-2023",
            "value": 82.382,
            "value2": 54.92
          },
          {
            "category": "14-07-2023",
            "value": 83.3474,
            "value2": 55.39
          },
          {
            "category": "15-07-2023",
            "value": 83.3474,
            "value2": 55.39
          },
          {
            "category": "16-07-2023",
            "value": 83.3474,
            "value2": 55.39
          },
          {
            "category": "17-07-2023",
            "value": 83.2453,
            "value2": 55.74
          },
          {
            "category": "18-07-2023",
            "value": 82.6385,
            "value2": 55.75
          },
          {
            "category": "19-07-2023",
            "value": 83.0699,
            "value2": 56.01
          },
          {
            "category": "20-07-2023",
            "value": 83.2695,
            "value2": 56.3
          },
          {
            "category": "21-07-2023",
            "value": 83.142,
            "value2": 55.84
          },
          {
            "category": "22-07-2023",
            "value": 83.142,
            "value2": 55.84
          },
          {
            "category": "23-07-2023",
            "value": 83.142,
            "value2": 55.84
          },
          {
            "category": "24-07-2023",
            "value": 82.991,
            "value2": 55.72
          },
          {
            "category": "25-07-2023",
            "value": 82.8402,
            "value2": 55.82
          },
          {
            "category": "26-07-2023",
            "value": 83.5392,
            "value2": 56.09
          },
          {
            "category": "27-07-2023",
            "value": 83.1625,
            "value2": 55.92
          },
          {
            "category": "28-07-2023",
            "value": 83.3158,
            "value2": 56.02
          },
          {
            "category": "29-07-2023",
            "value": 83.3158,
            "value2": 56.02
          },
          {
            "category": "30-07-2023",
            "value": 83.3158,
            "value2": 56.02
          },
          {
            "category": "31-07-2023",
            "value": 83.5934,
            "value2": 56.39
          },
          {
            "category": "01-08-2023",
            "value": 83.6118,
            "value2": 56.36
          },
          {
            "category": "02-08-2023",
            "value": 82.3706,
            "value2": 55.74
          },
          {
            "category": "03-08-2023",
            "value": 82.2092,
            "value2": 55.47
          },
          {
            "category": "04-08-2023",
            "value": 83.4963,
            "value2": 55.85
          },
          {
            "category": "05-08-2023",
            "value": 83.4963,
            "value2": 55.85
          },
          {
            "category": "06-08-2023",
            "value": 83.4963,
            "value2": 55.85
          },
          {
            "category": "07-08-2023",
            "value": 84.2573,
            "value2": 56.06
          },
          {
            "category": "08-08-2023",
            "value": 84.4539,
            "value2": 56.04
          },
          {
            "category": "09-08-2023",
            "value": 85.0258,
            "value2": 56.24
          },
          {
            "category": "10-08-2023",
            "value": 85.1881,
            "value2": 56.04
          },
          {
            "category": "11-08-2023",
            "value": 85.0162,
            "value2": 55.79
          },
          {
            "category": "12-08-2023",
            "value": 85.0162,
            "value2": 55.79
          },
          {
            "category": "13-08-2023",
            "value": 85.0162,
            "value2": 55.79
          },
          {
            "category": "14-08-2023",
            "value": 84.888,
            "value2": 55.69
          },
          {
            "category": "15-08-2023",
            "value": 84.8886,
            "value2": 55.69
          },
          {
            "category": "16-08-2023",
            "value": 85.4519,
            "value2": 55.79
          },
          {
            "category": "17-08-2023",
            "value": 85.0073,
            "value2": 55.61
          },
          {
            "category": "18-08-2023",
            "value": 84.7654,
            "value2": 55.47
          },
          {
            "category": "19-08-2023",
            "value": 84.7654,
            "value2": 55.47
          },
          {
            "category": "20-08-2023",
            "value": 84.7654,
            "value2": 55.47
          },
          {
            "category": "21-08-2023",
            "value": 85.1786,
            "value2": 55.8
          },
          {
            "category": "22-08-2023",
            "value": 85.9572,
            "value2": 55.98
          },
          {
            "category": "23-08-2023",
            "value": 86.5929,
            "value2": 56.13
          },
          {
            "category": "24-08-2023",
            "value": 86.6093,
            "value2": 56.06
          },
          {
            "category": "25-08-2023",
            "value": 86.0943,
            "value2": 55.71
          },
          {
            "category": "26-08-2023",
            "value": 86.0943,
            "value2": 55.71
          },
          {
            "category": "27-08-2023",
            "value": 86.0943,
            "value2": 55.71
          },
          {
            "category": "28-08-2023",
            "value": 86.7643,
            "value2": 55.88
          },
          {
            "category": "29-08-2023",
            "value": 86.963,
            "value2": 56.06
          },
          {
            "category": "30-08-2023",
            "value": 87.6928,
            "value2": 56.2
          },
          {
            "category": "31-08-2023",
            "value": 87.7261,
            "value2": 56.04
          },
          {
            "category": "01-09-2023",
            "value": 88.4463,
            "value2": 56.52
          },
          {
            "category": "02-09-2023",
            "value": 88.4463,
            "value2": 56.52
          },
          {
            "category": "03-09-2023",
            "value": 88.4463,
            "value2": 56.52
          },
          {
            "category": "04-09-2023",
            "value": 88.9784,
            "value2": 56.85
          },
          {
            "category": "05-09-2023",
            "value": 89.2567,
            "value2": 57.1
          },
          {
            "category": "06-09-2023",
            "value": 89.2609,
            "value2": 57.2
          },
          {
            "category": "07-09-2023",
            "value": 89.8782,
            "value2": 57.52
          },
          {
            "category": "08-09-2023",
            "value": 90.7494,
            "value2": 57.86
          },
          {
            "category": "09-09-2023",
            "value": 90.7494,
            "value2": 57.86
          },
          {
            "category": "10-09-2023",
            "value": 90.7494,
            "value2": 57.86
          },
          {
            "category": "11-09-2023",
            "value": 92.0127,
            "value2": 58.4
          },
          {
            "category": "12-09-2023",
            "value": 89.4411,
            "value2": 57.78
          },
          {
            "category": "13-09-2023",
            "value": 89.3831,
            "value2": 57.99
          },
          {
            "category": "14-09-2023",
            "value": 90.27,
            "value2": 58.25
          },
          {
            "category": "15-09-2023",
            "value": 90.7712,
            "value2": 58.47
          },
          {
            "category": "16-09-2023",
            "value": 90.7712,
            "value2": 58.47
          },
          {
            "category": "17-09-2023",
            "value": 90.7712,
            "value2": 58.47
          },
          {
            "category": "18-09-2023",
            "value": 90.5836,
            "value2": 58.28
          },
          {
            "category": "19-09-2023",
            "value": 90.5836,
            "value2": 58.28
          },
          {
            "category": "20-09-2023",
            "value": 89.8167,
            "value2": 57.75
          },
          {
            "category": "21-09-2023",
            "value": 88.9773,
            "value2": 57.26
          },
          {
            "category": "22-09-2023",
            "value": 89.3432,
            "value2": 57.11
          },
          {
            "category": "23-09-2023",
            "value": 89.3432,
            "value2": 57.11
          },
          {
            "category": "24-09-2023",
            "value": 89.3432,
            "value2": 57.11
          },
          {
            "category": "25-09-2023",
            "value": 89.1424,
            "value2": 57.15
          },
          {
            "category": "26-09-2023",
            "value": 89.3829,
            "value2": 57.15
          },
          {
            "category": "27-09-2023",
            "value": 90.0865,
            "value2": 57.37
          },
          {
            "category": "28-09-2023",
            "value": 88.9281,
            "value2": 56.82
          },
          {
            "category": "29-09-2023",
            "value": 89.7325,
            "value2": 57.22
          },
          {
            "category": "30-09-2023",
            "value": 89.5364,
            "value2": 57.22
          },
          {
            "category": "01-10-2023",
            "value": 89.5371,
            "value2": 57.22
          },
          {
            "category": "02-10-2023",
            "value": 89.5371,
            "value2": 57.22
          },
          {
            "category": "03-10-2023",
            "value": 89.6559,
            "value2": 57.08
          },
          {
            "category": "04-10-2023",
            "value": 88.4692,
            "value2": 56.66
          },
          {
            "category": "05-10-2023",
            "value": 89.6636,
            "value2": 56.9
          },
          {
            "category": "06-10-2023",
            "value": 90.545,
            "value2": 57.21
          },
          {
            "category": "07-10-2023",
            "value": 90.545,
            "value2": 57.21
          },
          {
            "category": "08-10-2023",
            "value": 90.545,
            "value2": 57.21
          },
          {
            "category": "09-10-2023",
            "value": 89.7068,
            "value2": 56.65
          },
          {
            "category": "10-10-2023",
            "value": 90.6861,
            "value2": 57.24
          },
          {
            "category": "11-10-2023",
            "value": 91.4318,
            "value2": 57.58
          },
          {
            "category": "12-10-2023",
            "value": 91.4814,
            "value2": 57.63
          },
          {
            "category": "13-10-2023",
            "value": 91.4564,
            "value2": 57.54
          },
          {
            "category": "14-10-2023",
            "value": 91.4564,
            "value2": 57.54
          },
          {
            "category": "15-10-2023",
            "value": 91.4564,
            "value2": 57.54
          },
          {
            "category": "16-10-2023",
            "value": 91.6942,
            "value2": 57.53
          },
          {
            "category": "17-10-2023",
            "value": 92.2777,
            "value2": 57.79
          },
          {
            "category": "18-10-2023",
            "value": 91.5183,
            "value2": 57.37
          },
          {
            "category": "19-10-2023",
            "value": 91.7836,
            "value2": 57.25
          },
          {
            "category": "20-10-2023",
            "value": 91.6019,
            "value2": 56.93
          },
          {
            "category": "21-10-2023",
            "value": 91.6019,
            "value2": 56.93
          },
          {
            "category": "22-10-2023",
            "value": 91.6019,
            "value2": 56.93
          },
          {
            "category": "23-10-2023",
            "value": 89.5779,
            "value2": 55.85
          },
          {
            "category": "24-10-2023",
            "value": 89.5778,
            "value2": 55.85
          },
          {
            "category": "25-10-2023",
            "value": 89.2612,
            "value2": 55.47
          },
          {
            "category": "26-10-2023",
            "value": 88.5401,
            "value2": 54.78
          },
          {
            "category": "27-10-2023",
            "value": 89.918,
            "value2": 55.47
          },
          {
            "category": "28-10-2023",
            "value": 89.918,
            "value2": 55.47
          },
          {
            "category": "29-10-2023",
            "value": 89.918,
            "value2": 55.47
          },
          {
            "category": "30-10-2023",
            "value": 89.8369,
            "value2": 55.67
          },
          {
            "category": "31-10-2023",
            "value": 89.3238,
            "value2": 55.58
          },
          {
            "category": "01-11-2023",
            "value": 88.8196,
            "value2": 55.36
          },
          {
            "category": "02-11-2023",
            "value": 89.7542,
            "value2": 55.89
          },
          {
            "category": "03-11-2023",
            "value": 90.8239,
            "value2": 56.26
          },
          {
            "category": "04-11-2023",
            "value": 90.8239,
            "value2": 56.26
          },
          {
            "category": "05-11-2023",
            "value": 90.8239,
            "value2": 56.26
          },
          {
            "category": "06-11-2023",
            "value": 91.9881,
            "value2": 56.79
          },
          {
            "category": "07-11-2023",
            "value": 92.2218,
            "value2": 56.87
          },
          {
            "category": "08-11-2023",
            "value": 93.2834,
            "value2": 57.09
          },
          {
            "category": "09-11-2023",
            "value": 92.8123,
            "value2": 56.98
          },
          {
            "category": "10-11-2023",
            "value": 93.1881,
            "value2": 57.09
          },
          {
            "category": "11-11-2023",
            "value": 93.1881,
            "value2": 57.09
          },
          {
            "category": "12-11-2023",
            "value": 94.1494,
            "value2": 57.43
          },
          {
            "category": "13-11-2023",
            "value": 94.4145,
            "value2": 57.28
          },
          {
            "category": "14-11-2023",
            "value": 94.4144,
            "value2": 57.28
          },
          {
            "category": "15-11-2023",
            "value": 95.513,
            "value2": 57.91
          },
          {
            "category": "16-11-2023",
            "value": 95.8842,
            "value2": 58.2
          },
          {
            "category": "17-11-2023",
            "value": 95.9395,
            "value2": 58.22
          },
          {
            "category": "18-11-2023",
            "value": 95.9395,
            "value2": 58.22
          },
          {
            "category": "19-11-2023",
            "value": 95.9395,
            "value2": 58.22
          },
          {
            "category": "20-11-2023",
            "value": 95.4343,
            "value2": 58.14
          },
          {
            "category": "21-11-2023",
            "value": 95.0449,
            "value2": 58.32
          },
          {
            "category": "22-11-2023",
            "value": 94.8221,
            "value2": 58.33
          },
          {
            "category": "23-11-2023",
            "value": 95.0488,
            "value2": 58.37
          },
          {
            "category": "24-11-2023",
            "value": 95.3674,
            "value2": 58.38
          },
          {
            "category": "25-11-2023",
            "value": 95.3674,
            "value2": 58.38
          },
          {
            "category": "26-11-2023",
            "value": 95.3674,
            "value2": 58.38
          },
          {
            "category": "27-11-2023",
            "value": 95.3674,
            "value2": 58.38
          },
          {
            "category": "28-11-2023",
            "value": 96.097,
            "value2": 58.7
          },
          {
            "category": "29-11-2023",
            "value": 96.8827,
            "value2": 59.22
          },
          {
            "category": "30-11-2023",
            "value": 97.6941,
            "value2": 59.51
          },
          {
            "category": "01-12-2023",
            "value": 98.1431,
            "value2": 59.92
          },
          {
            "category": "02-12-2023",
            "value": 98.1431,
            "value2": 59.92
          },
          {
            "category": "03-12-2023",
            "value": 98.1431,
            "value2": 59.92
          },
          {
            "category": "04-12-2023",
            "value": 99.1905,
            "value2": 61.02
          },
          {
            "category": "05-12-2023",
            "value": 98.853,
            "value2": 61.5
          },
          {
            "category": "06-12-2023",
            "value": 99.6924,
            "value2": 61.8
          },
          {
            "category": "07-12-2023",
            "value": 99.3098,
            "value2": 61.9
          },
          {
            "category": "08-12-2023",
            "value": 98.6495,
            "value2": 61.89
          },
          {
            "category": "09-12-2023",
            "value": 98.6495,
            "value2": 61.89
          },
          {
            "category": "10-12-2023",
            "value": 98.6495,
            "value2": 61.89
          },
          {
            "category": "11-12-2023",
            "value": 98.6877,
            "value2": 62.11
          },
          {
            "category": "12-12-2023",
            "value": 97.7288,
            "value2": 61.83
          },
          {
            "category": "13-12-2023",
            "value": 98.5135,
            "value2": 62.04
          },
          {
            "category": "14-12-2023",
            "value": 99.9065,
            "value2": 62.77
          },
          {
            "category": "15-12-2023",
            "value": 100.38,
            "value2": 63.33
          },
          {
            "category": "16-12-2023",
            "value": 100.38,
            "value2": 63.33
          },
          {
            "category": "17-12-2023",
            "value": 100.38,
            "value2": 63.33
          },
          {
            "category": "18-12-2023",
            "value": 101.018,
            "value2": 63.36
          },
          {
            "category": "19-12-2023",
            "value": 100.864,
            "value2": 63.41
          },
          {
            "category": "20-12-2023",
            "value": 98.058,
            "value2": 62.12
          },
          {
            "category": "21-12-2023",
            "value": 99.3704,
            "value2": 62.67
          },
          {
            "category": "22-12-2023",
            "value": 100.406,
            "value2": 63.03
          },
          {
            "category": "23-12-2023",
            "value": 100.406,
            "value2": 63.03
          },
          {
            "category": "24-12-2023",
            "value": 100.406,
            "value2": 63.03
          },
          {
            "category": "25-12-2023",
            "value": 100.406,
            "value2": 63.03
          },
          {
            "category": "26-12-2023",
            "value": 100.85,
            "value2": 63.36
          },
          {
            "category": "27-12-2023",
            "value": 101.294,
            "value2": 63.83
          },
          {
            "category": "28-12-2023",
            "value": 101.48,
            "value2": 64.19
          },
          {
            "category": "29-12-2023",
            "value": 101.867,
            "value2": 64.29
          },
          {
            "category": "30-12-2023",
            "value": 101.866,
            "value2": 64.29
          },
          {
            "category": "31-12-2023",
            "value": 101.642,
            "value2": 64.29
          },
          {
            "category": "01-01-2024",
            "value": 102.038,
            "value2": 64.43
          },
          {
            "category": "02-01-2024",
            "value": 101.855,
            "value2": 64.25
          },
          {
            "category": "03-01-2024",
            "value": 101.759,
            "value2": 64.08
          },
          {
            "category": "04-01-2024",
            "value": 103.217,
            "value2": 64.65
          },
          {
            "category": "05-01-2024",
            "value": 103.634,
            "value2": 64.81
          },
          {
            "category": "06-01-2024",
            "value": 103.634,
            "value2": 64.81
          },
          {
            "category": "07-01-2024",
            "value": 103.634,
            "value2": 64.81
          },
          {
            "category": "08-01-2024",
            "value": 103.152,
            "value2": 64.23
          },
          {
            "category": "09-01-2024",
            "value": 103.638,
            "value2": 64.38
          },
          {
            "category": "10-01-2024",
            "value": 104.064,
            "value2": 64.58
          },
          {
            "category": "11-01-2024",
            "value": 105.326,
            "value2": 64.79
          },
          {
            "category": "12-01-2024",
            "value": 105.826,
            "value2": 65.33
          },
          {
            "category": "13-01-2024",
            "value": 105.826,
            "value2": 65.33
          },
          {
            "category": "14-01-2024",
            "value": 105.826,
            "value2": 65.33
          },
          {
            "category": "15-01-2024",
            "value": 105.844,
            "value2": 65.8
          },
          {
            "category": "16-01-2024",
            "value": 104.963,
            "value2": 65.6
          },
          {
            "category": "17-01-2024",
            "value": 103.955,
            "value2": 64.47
          },
          {
            "category": "18-01-2024",
            "value": 104.388,
            "value2": 64.23
          },
          {
            "category": "19-01-2024",
            "value": 105.94,
            "value2": 64.88
          },
          {
            "category": "20-01-2024",
            "value": 105.611,
            "value2": 64.87
          },
          {
            "category": "21-01-2024",
            "value": 105.611,
            "value2": 64.87
          },
          {
            "category": "22-01-2024",
            "value": 105.61,
            "value2": 64.87
          },
          {
            "category": "23-01-2024",
            "value": 102.179,
            "value2": 63.57
          },
          {
            "category": "24-01-2024",
            "value": 103.612,
            "value2": 64.38
          },
          {
            "category": "25-01-2024",
            "value": 103.606,
            "value2": 64.19
          },
          {
            "category": "26-01-2024",
            "value": 103.605,
            "value2": 64.19
          },
          {
            "category": "27-01-2024",
            "value": 103.605,
            "value2": 64.19
          },
          {
            "category": "28-01-2024",
            "value": 103.605,
            "value2": 64.19
          },
          {
            "category": "29-01-2024",
            "value": 104.757,
            "value2": 65.25
          },
          {
            "category": "30-01-2024",
            "value": 105.018,
            "value2": 64.78
          },
          {
            "category": "31-01-2024",
            "value": 106.41,
            "value2": 65.52
          },
          {
            "category": "01-02-2024",
            "value": 105.517,
            "value2": 65.42
          },
          {
            "category": "02-02-2024",
            "value": 105.605,
            "value2": 65.88
          },
          {
            "category": "03-02-2024",
            "value": 105.605,
            "value2": 65.88
          },
          {
            "category": "04-02-2024",
            "value": 105.605,
            "value2": 65.88
          },
          {
            "category": "05-02-2024",
            "value": 104.885,
            "value2": 65.67
          },
          {
            "category": "06-02-2024",
            "value": 104.713,
            "value2": 66.29
          },
          {
            "category": "07-02-2024",
            "value": 105.311,
            "value2": 66.53
          },
          {
            "category": "08-02-2024",
            "value": 104.878,
            "value2": 66.17
          },
          {
            "category": "09-02-2024",
            "value": 104.717,
            "value2": 66.07
          },
          {
            "category": "10-02-2024",
            "value": 104.717,
            "value2": 66.07
          },
          {
            "category": "11-02-2024",
            "value": 104.717,
            "value2": 66.07
          },
          {
            "category": "12-02-2024",
            "value": 103.239,
            "value2": 65.14
          },
          {
            "category": "13-02-2024",
            "value": 103.259,
            "value2": 65.51
          },
          {
            "category": "14-02-2024",
            "value": 103.669,
            "value2": 65.97
          },
          {
            "category": "15-02-2024",
            "value": 105.037,
            "value2": 66.37
          },
          {
            "category": "16-02-2024",
            "value": 105.858,
            "value2": 66.8
          },
          {
            "category": "17-02-2024",
            "value": 105.858,
            "value2": 66.8
          },
          {
            "category": "18-02-2024",
            "value": 105.858,
            "value2": 66.8
          },
          {
            "category": "19-02-2024",
            "value": 105.813,
            "value2": 67.05
          },
          {
            "category": "20-02-2024",
            "value": 105.777,
            "value2": 67.16
          },
          {
            "category": "21-02-2024",
            "value": 105.204,
            "value2": 66.69
          },
          {
            "category": "22-02-2024",
            "value": 106.165,
            "value2": 67.22
          },
          {
            "category": "23-02-2024",
            "value": 107.325,
            "value2": 67.32
          },
          {
            "category": "24-02-2024",
            "value": 107.325,
            "value2": 67.32
          },
          {
            "category": "25-02-2024",
            "value": 107.325,
            "value2": 67.32
          },
          {
            "category": "26-02-2024",
            "value": 106.914,
            "value2": 67.13
          },
          {
            "category": "27-02-2024",
            "value": 106.879,
            "value2": 67.24
          },
          {
            "category": "28-02-2024",
            "value": 105.187,
            "value2": 66.32
          },
          {
            "category": "29-02-2024",
            "value": 105.567,
            "value2": 66.61
          },
          {
            "category": "01-03-2024",
            "value": 106.252,
            "value2": 67.45
          },
          {
            "category": "02-03-2024",
            "value": 106.762,
            "value2": 67.67
          },
          {
            "category": "03-03-2024",
            "value": 106.762,
            "value2": 67.67
          },
          {
            "category": "04-03-2024",
            "value": 107.076,
            "value2": 67.7
          },
          {
            "category": "05-03-2024",
            "value": 106.004,
            "value2": 67.55
          },
          {
            "category": "06-03-2024",
            "value": 104.907,
            "value2": 67.54
          },
          {
            "category": "07-03-2024",
            "value": 105.507,
            "value2": 67.73
          },
          {
            "category": "08-03-2024",
            "value": 105.507,
            "value2": 67.73
          },
          {
            "category": "09-03-2024",
            "value": 105.507,
            "value2": 67.73
          },
          {
            "category": "10-03-2024",
            "value": 105.507,
            "value2": 67.73
          },
          {
            "category": "11-03-2024",
            "value": 103.886,
            "value2": 67.24
          },
          {
            "category": "12-03-2024",
            "value": 102.234,
            "value2": 66.87
          },
          {
            "category": "13-03-2024",
            "value": 98.1015,
            "value2": 65.14
          },
          {
            "category": "14-03-2024",
            "value": 99.4887,
            "value2": 66.05
          },
          {
            "category": "15-03-2024",
            "value": 99.4101,
            "value2": 65.74
          },
          {
            "category": "16-03-2024",
            "value": 99.4097,
            "value2": 65.74
          },
          {
            "category": "17-03-2024",
            "value": 99.4097,
            "value2": 65.74
          },
          {
            "category": "18-03-2024",
            "value": 99.4041,
            "value2": 65.8
          },
          {
            "category": "19-03-2024",
            "value": 98.199,
            "value2": 65.03
          },
          {
            "category": "20-03-2024",
            "value": 99.2045,
            "value2": 65.11
          },
          {
            "category": "21-03-2024",
            "value": 101.964,
            "value2": 65.97
          },
          {
            "category": "22-03-2024",
            "value": 102.933,
            "value2": 66.29
          },
          {
            "category": "23-03-2024",
            "value": 102.933,
            "value2": 66.29
          },
          {
            "category": "24-03-2024",
            "value": 102.933,
            "value2": 66.29
          },
          {
            "category": "25-03-2024",
            "value": 102.933,
            "value2": 66.29
          },
          {
            "category": "26-03-2024",
            "value": 104.135,
            "value2": 66.32
          },
          {
            "category": "27-03-2024",
            "value": 105.609,
            "value2": 66.59
          },
          {
            "category": "28-03-2024",
            "value": 106.436,
            "value2": 67.18
          },
          {
            "category": "29-03-2024",
            "value": 106.436,
            "value2": 67.18
          },
          {
            "category": "30-03-2024",
            "value": 106.436,
            "value2": 67.18
          },
          {
            "category": "31-03-2024",
            "value": 105.97,
            "value2": 67.18
          },
          {
            "category": "01-04-2024",
            "value": 107.534,
            "value2": 67.91
          },
          {
            "category": "02-04-2024",
            "value": 108.725,
            "value2": 68.16
          },
          {
            "category": "03-04-2024",
            "value": 109.641,
            "value2": 68.28
          },
          {
            "category": "04-04-2024",
            "value": 109.924,
            "value2": 68.47
          },
          {
            "category": "05-04-2024",
            "value": 110.058,
            "value2": 68.61
          },
          {
            "category": "06-04-2024",
            "value": 110.058,
            "value2": 68.61
          },
          {
            "category": "07-04-2024",
            "value": 110.058,
            "value2": 68.61
          },
          {
            "category": "08-04-2024",
            "value": 110.455,
            "value2": 68.96
          },
          {
            "category": "09-04-2024",
            "value": 109.827,
            "value2": 68.87
          },
          {
            "category": "10-04-2024",
            "value": 110.839,
            "value2": 69.28
          },
          {
            "category": "11-04-2024",
            "value": 110.839,
            "value2": 69.28
          },
          {
            "category": "12-04-2024",
            "value": 110.127,
            "value2": 68.71
          },
          {
            "category": "13-04-2024",
            "value": 110.127,
            "value2": 68.71
          },
          {
            "category": "14-04-2024",
            "value": 110.127,
            "value2": 68.71
          },
          {
            "category": "15-04-2024",
            "value": 108.472,
            "value2": 67.86
          },
          {
            "category": "16-04-2024",
            "value": 108.067,
            "value2": 67.66
          },
          {
            "category": "17-04-2024",
            "value": 108.067,
            "value2": 67.66
          },
          {
            "category": "18-04-2024",
            "value": 107.825,
            "value2": 67.3
          },
          {
            "category": "19-04-2024",
            "value": 107.824,
            "value2": 67.51
          },
          {
            "category": "20-04-2024",
            "value": 107.822,
            "value2": 67.51
          },
          {
            "category": "21-04-2024",
            "value": 107.822,
            "value2": 67.51
          },
          {
            "category": "22-04-2024",
            "value": 108.785,
            "value2": 68.12
          },
          {
            "category": "23-04-2024",
            "value": 110.103,
            "value2": 68.33
          },
          {
            "category": "24-04-2024",
            "value": 110.177,
            "value2": 68.59
          },
          {
            "category": "25-04-2024",
            "value": 111.354,
            "value2": 69.04
          },
          {
            "category": "26-04-2024",
            "value": 111.849,
            "value2": 68.93
          },
          {
            "category": "27-04-2024",
            "value": 111.849,
            "value2": 68.93
          },
          {
            "category": "28-04-2024",
            "value": 111.848,
            "value2": 68.93
          },
          {
            "category": "29-04-2024",
            "value": 110.851,
            "value2": 69.48
          },
          {
            "category": "30-04-2024",
            "value": 110.856,
            "value2": 69.49
          },
          {
            "category": "01-05-2024",
            "value": 110.856,
            "value2": 69.49
          },
          {
            "category": "02-05-2024",
            "value": 111.49,
            "value2": 69.78
          },
          {
            "category": "03-05-2024",
            "value": 111.302,
            "value2": 69.36
          },
          {
            "category": "04-05-2024",
            "value": 111.302,
            "value2": 69.36
          },
          {
            "category": "05-05-2024",
            "value": 111.302,
            "value2": 69.36
          },
          {
            "category": "06-05-2024",
            "value": 110.196,
            "value2": 69.07
          },
          {
            "category": "07-05-2024",
            "value": 107.984,
            "value2": 68.34
          },
          {
            "category": "08-05-2024",
            "value": 108.481,
            "value2": 68.58
          },
          {
            "category": "09-05-2024",
            "value": 106.033,
            "value2": 67.38
          },
          {
            "category": "10-05-2024",
            "value": 107.03,
            "value2": 67.87
          },
          {
            "category": "11-05-2024",
            "value": 107.03,
            "value2": 67.87
          },
          {
            "category": "12-05-2024",
            "value": 107.03,
            "value2": 67.87
          },
          {
            "category": "13-05-2024",
            "value": 106.785,
            "value2": 68.03
          },
          {
            "category": "14-05-2024",
            "value": 108.304,
            "value2": 68.58
          },
          {
            "category": "15-05-2024",
            "value": 109.501,
            "value2": 68.75
          },
          {
            "category": "16-05-2024",
            "value": 110.284,
            "value2": 69.4
          },
          {
            "category": "17-05-2024",
            "value": 111.019,
            "value2": 69.84
          },
          {
            "category": "18-05-2024",
            "value": 111.821,
            "value2": 70.08
          },
          {
            "category": "19-05-2024",
            "value": 111.821,
            "value2": 70.08
          },
          {
            "category": "20-05-2024",
            "value": 111.821,
            "value2": 70.08
          },
          {
            "category": "21-05-2024",
            "value": 110.979,
            "value2": 70.24
          },
          {
            "category": "22-05-2024",
            "value": 110.297,
            "value2": 70.45
          },
          {
            "category": "23-05-2024",
            "value": 110.938,
            "value2": 71.28
          },
          {
            "category": "24-05-2024",
            "value": 111.177,
            "value2": 71.27
          },
          {
            "category": "25-05-2024",
            "value": 111.176,
            "value2": 71.27
          },
          {
            "category": "26-05-2024",
            "value": 111.176,
            "value2": 71.27
          },
          {
            "category": "27-05-2024",
            "value": 111.427,
            "value2": 71.3
          },
          {
            "category": "28-05-2024",
            "value": 109.672,
            "value2": 70.96
          },
          {
            "category": "29-05-2024",
            "value": 109.266,
            "value2": 70.54
          },
          {
            "category": "30-05-2024",
            "value": 107.839,
            "value2": 69.83
          },
          {
            "category": "31-05-2024",
            "value": 108.435,
            "value2": 70.07
          },
          {
            "category": "01-06-2024",
            "value": 108.434,
            "value2": 70.07
          },
          {
            "category": "02-06-2024",
            "value": 108.434,
            "value2": 70.07
          },
          {
            "category": "03-06-2024",
            "value": 112.224,
            "value2": 72.39
          },
          {
            "category": "04-06-2024",
            "value": 102.489,
            "value2": 67.55
          },
          {
            "category": "05-06-2024",
            "value": 106.267,
            "value2": 69.98
          },
          {
            "category": "06-06-2024",
            "value": 109.531,
            "value2": 71.02
          },
          {
            "category": "07-06-2024",
            "value": 111.269,
            "value2": 72.33
          },
          {
            "category": "08-06-2024",
            "value": 111.267,
            "value2": 72.33
          },
          {
            "category": "09-06-2024",
            "value": 111.267,
            "value2": 72.33
          },
          {
            "category": "10-06-2024",
            "value": 111.359,
            "value2": 72.46
          },
          {
            "category": "11-06-2024",
            "value": 112.675,
            "value2": 72.62
          },
          {
            "category": "12-06-2024",
            "value": 112.98,
            "value2": 72.97
          },
          {
            "category": "13-06-2024",
            "value": 114.203,
            "value2": 73.35
          },
          {
            "category": "14-06-2024",
            "value": 115.731,
            "value2": 73.82
          },
          {
            "category": "15-06-2024",
            "value": 115.73,
            "value2": 73.82
          },
          {
            "category": "16-06-2024",
            "value": 115.73,
            "value2": 73.82
          },
          {
            "category": "17-06-2024",
            "value": 115.73,
            "value2": 73.82
          },
          {
            "category": "18-06-2024",
            "value": 117.264,
            "value2": 74.2
          },
          {
            "category": "19-06-2024",
            "value": 115.724,
            "value2": 73.86
          },
          {
            "category": "20-06-2024",
            "value": 116.325,
            "value2": 74.14
          },
          {
            "category": "21-06-2024",
            "value": 116.386,
            "value2": 73.89
          },
          {
            "category": "22-06-2024",
            "value": 116.386,
            "value2": 73.89
          },
          {
            "category": "23-06-2024",
            "value": 116.386,
            "value2": 73.89
          },
          {
            "category": "24-06-2024",
            "value": 117.277,
            "value2": 74.09
          },
          {
            "category": "25-06-2024",
            "value": 117.067,
            "value2": 74.36
          },
          {
            "category": "26-06-2024",
            "value": 117.124,
            "value2": 74.59
          },
          {
            "category": "27-06-2024",
            "value": 117.36,
            "value2": 74.93
          },
          {
            "category": "28-06-2024",
            "value": 118.06,
            "value2": 75.01
          },
          {
            "category": "29-06-2024",
            "value": 118.06,
            "value2": 75.01
          },
          {
            "category": "30-06-2024",
            "value": 117.808,
            "value2": 75.01
          },
          {
            "category": "01-07-2024",
            "value": 118.674,
            "value2": 75.56
          },
          {
            "category": "02-07-2024",
            "value": 117.516,
            "value2": 75.41
          },
          {
            "category": "03-07-2024",
            "value": 118.15,
            "value2": 75.98
          },
          {
            "category": "04-07-2024",
            "value": 118.389,
            "value2": 76.2
          },
          {
            "category": "05-07-2024",
            "value": 120.15,
            "value2": 76.46
          },
          {
            "category": "06-07-2024",
            "value": 120.15,
            "value2": 76.46
          },
          {
            "category": "07-07-2024",
            "value": 120.15,
            "value2": 76.46
          },
          {
            "category": "08-07-2024",
            "value": 120.664,
            "value2": 76.39
          },
          {
            "category": "09-07-2024",
            "value": 119.912,
            "value2": 76.74
          },
          {
            "category": "10-07-2024",
            "value": 119.349,
            "value2": 76.45
          },
          {
            "category": "11-07-2024",
            "value": 120.243,
            "value2": 76.56
          },
          {
            "category": "12-07-2024",
            "value": 120.818,
            "value2": 76.85
          },
          {
            "category": "13-07-2024",
            "value": 120.818,
            "value2": 76.85
          },
          {
            "category": "14-07-2024",
            "value": 120.818,
            "value2": 76.85
          },
          {
            "category": "15-07-2024",
            "value": 121.315,
            "value2": 77.22
          },
          {
            "category": "16-07-2024",
            "value": 121.229,
            "value2": 77.21
          },
          {
            "category": "17-07-2024",
            "value": 121.229,
            "value2": 77.21
          },
          {
            "category": "18-07-2024",
            "value": 119.893,
            "value2": 77.23
          },
          {
            "category": "19-07-2024",
            "value": 117.894,
            "value2": 76.06
          },
          {
            "category": "20-07-2024",
            "value": 117.893,
            "value2": 76.06
          },
          {
            "category": "21-07-2024",
            "value": 117.893,
            "value2": 76.06
          },
          {
            "category": "22-07-2024",
            "value": 118.634,
            "value2": 76.36
          },
          {
            "category": "23-07-2024",
            "value": 118.629,
            "value2": 76.11
          },
          {
            "category": "24-07-2024",
            "value": 120.624,
            "value2": 76.29
          },
          {
            "category": "25-07-2024",
            "value": 120.216,
            "value2": 76.28
          },
          {
            "category": "26-07-2024",
            "value": 121.862,
            "value2": 77.54
          },
          {
            "category": "27-07-2024",
            "value": 121.862,
            "value2": 77.54
          },
          {
            "category": "28-07-2024",
            "value": 121.861,
            "value2": 77.54
          },
          {
            "category": "29-07-2024",
            "value": 123.346,
            "value2": 77.9
          },
          {
            "category": "30-07-2024",
            "value": 123.791,
            "value2": 78.05
          },
          {
            "category": "31-07-2024",
            "value": 124.122,
            "value2": 78.34
          },
          {
            "category": "01-08-2024",
            "value": 123.172,
            "value2": 78.26
          },
          {
            "category": "02-08-2024",
            "value": 122.567,
            "value2": 77.47
          },
          {
            "category": "03-08-2024",
            "value": 122.567,
            "value2": 77.47
          },
          {
            "category": "04-08-2024",
            "value": 122.567,
            "value2": 77.47
          },
          {
            "category": "05-08-2024",
            "value": 117.713,
            "value2": 75.08
          },
          {
            "category": "06-08-2024",
            "value": 116.398,
            "value2": 74.76
          },
          {
            "category": "07-08-2024",
            "value": 119.085,
            "value2": 76.16
          },
          {
            "category": "08-08-2024",
            "value": 118.785,
            "value2": 75.65
          },
          {
            "category": "09-08-2024",
            "value": 120.694,
            "value2": 76.38
          },
          {
            "category": "10-08-2024",
            "value": 120.694,
            "value2": 76.38
          },
          {
            "category": "11-08-2024",
            "value": 120.694,
            "value2": 76.38
          },
          {
            "category": "12-08-2024",
            "value": 122.199,
            "value2": 76.39
          },
          {
            "category": "13-08-2024",
            "value": 121,
            "value2": 75.67
          },
          {
            "category": "14-08-2024",
            "value": 119.547,
            "value2": 75.57
          },
          {
            "category": "15-08-2024",
            "value": 119.547,
            "value2": 75.57
          },
          {
            "category": "16-08-2024",
            "value": 122.205,
            "value2": 76.86
          },
          {
            "category": "17-08-2024",
            "value": 122.205,
            "value2": 76.86
          },
          {
            "category": "18-08-2024",
            "value": 122.205,
            "value2": 76.86
          },
          {
            "category": "19-08-2024",
            "value": 123.024,
            "value2": 77.14
          },
          {
            "category": "20-08-2024",
            "value": 124.445,
            "value2": 77.62
          },
          {
            "category": "21-08-2024",
            "value": 125.021,
            "value2": 77.91
          },
          {
            "category": "22-08-2024",
            "value": 126.323,
            "value2": 78.14
          },
          {
            "category": "23-08-2024",
            "value": 126.15,
            "value2": 78.06
          },
          {
            "category": "24-08-2024",
            "value": 126.149,
            "value2": 78.06
          },
          {
            "category": "25-08-2024",
            "value": 126.148,
            "value2": 78.06
          },
          {
            "category": "26-08-2024",
            "value": 127.03,
            "value2": 78.53
          },
          {
            "category": "27-08-2024",
            "value": 127.41,
            "value2": 78.66
          },
          {
            "category": "28-08-2024",
            "value": 127.617,
            "value2": 78.71
          },
          {
            "category": "29-08-2024",
            "value": 127.018,
            "value2": 78.76
          },
          {
            "category": "30-08-2024",
            "value": 127.271,
            "value2": 79.1
          },
          {
            "category": "31-08-2024",
            "value": 126.986,
            "value2": 79.1
          },
          {
            "category": "01-09-2024",
            "value": 126.986,
            "value2": 79.1
          },
          {
            "category": "02-09-2024",
            "value": 126.686,
            "value2": 79.19
          },
          {
            "category": "03-09-2024",
            "value": 126.642,
            "value2": 79.28
          },
          {
            "category": "04-09-2024",
            "value": 126.98,
            "value2": 79.15
          },
          {
            "category": "05-09-2024",
            "value": 128.043,
            "value2": 79.18
          },
          {
            "category": "06-09-2024",
            "value": 127.093,
            "value2": 78.24
          },
          {
            "category": "07-09-2024",
            "value": 127.092,
            "value2": 78.24
          },
          {
            "category": "08-09-2024",
            "value": 127.091,
            "value2": 78.24
          },
          {
            "category": "09-09-2024",
            "value": 127.535,
            "value2": 78.34
          },
          {
            "category": "10-09-2024",
            "value": 128.71,
            "value2": 78.8
          },
          {
            "category": "11-09-2024",
            "value": 128.344,
            "value2": 78.44
          },
          {
            "category": "12-09-2024",
            "value": 131.234,
            "value2": 79.67
          },
          {
            "category": "13-09-2024",
            "value": 132.266,
            "value2": 79.75
          },
          {
            "category": "14-09-2024",
            "value": 132.266,
            "value2": 79.75
          },
          {
            "category": "15-09-2024",
            "value": 132.266,
            "value2": 79.75
          },
          {
            "category": "16-09-2024",
            "value": 133.378,
            "value2": 79.84
          },
          {
            "category": "17-09-2024",
            "value": 132.581,
            "value2": 79.85
          },
          {
            "category": "18-09-2024",
            "value": 133.147,
            "value2": 79.56
          },
          {
            "category": "19-09-2024",
            "value": 131.513,
            "value2": 79.45
          },
          {
            "category": "20-09-2024",
            "value": 133.911,
            "value2": 80.6
          },
          {
            "category": "21-09-2024",
            "value": 133.908,
            "value2": 80.6
          },
          {
            "category": "22-09-2024",
            "value": 133.908,
            "value2": 80.6
          },
          {
            "category": "23-09-2024",
            "value": 135.663,
            "value2": 81.18
          },
          {
            "category": "24-09-2024",
            "value": 135.588,
            "value2": 81.21
          },
          {
            "category": "25-09-2024",
            "value": 134.144,
            "value2": 81.14
          },
          {
            "category": "26-09-2024",
            "value": 133.3,
            "value2": 81.58
          },
          {
            "category": "27-09-2024",
            "value": 132.625,
            "value2": 81.56
          },
          {
            "category": "28-09-2024",
            "value": 132.625,
            "value2": 81.56
          },
          {
            "category": "29-09-2024",
            "value": 132.623,
            "value2": 81.56
          },
          {
            "category": "30-09-2024",
            "value": 132.353,
            "value2": 80.75
          },
          {
            "category": "01-10-2024",
            "value": 133.144,
            "value2": 80.83
          },
          {
            "category": "02-10-2024",
            "value": 133.144,
            "value2": 80.83
          },
          {
            "category": "03-10-2024",
            "value": 130.29,
            "value2": 79.11
          },
          {
            "category": "04-10-2024",
            "value": 128.557,
            "value2": 78.35
          },
          {
            "category": "05-10-2024",
            "value": 128.557,
            "value2": 78.35
          },
          {
            "category": "06-10-2024",
            "value": 128.557,
            "value2": 78.35
          },
          {
            "category": "07-10-2024",
            "value": 125.463,
            "value2": 77.2
          },
          {
            "category": "08-10-2024",
            "value": 128.796,
            "value2": 78.29
          },
          {
            "category": "09-10-2024",
            "value": 130.347,
            "value2": 78.58
          },
          {
            "category": "10-10-2024",
            "value": 131.338,
            "value2": 78.56
          },
          {
            "category": "11-10-2024",
            "value": 132.295,
            "value2": 78.58
          },
          {
            "category": "12-10-2024",
            "value": 132.294,
            "value2": 78.58
          },
          {
            "category": "13-10-2024",
            "value": 132.294,
            "value2": 78.58
          },
          {
            "category": "14-10-2024",
            "value": 133.407,
            "value2": 78.95
          },
          {
            "category": "15-10-2024",
            "value": 134.52,
            "value2": 78.98
          },
          {
            "category": "16-10-2024",
            "value": 134.101,
            "value2": 78.77
          },
          {
            "category": "17-10-2024",
            "value": 130.864,
            "value2": 77.82
          },
          {
            "category": "18-10-2024",
            "value": 131.104,
            "value2": 78
          },
          {
            "category": "19-10-2024",
            "value": 131.103,
            "value2": 78
          },
          {
            "category": "20-10-2024",
            "value": 131.103,
            "value2": 78
          },
          {
            "category": "21-10-2024",
            "value": 129.883,
            "value2": 77.44
          },
          {
            "category": "22-10-2024",
            "value": 125.981,
            "value2": 75.97
          },
          {
            "category": "23-10-2024",
            "value": 126.629,
            "value2": 76.04
          },
          {
            "category": "24-10-2024",
            "value": 126.009,
            "value2": 75.88
          },
          {
            "category": "25-10-2024",
            "value": 123.205,
            "value2": 74.91
          },
          {
            "category": "26-10-2024",
            "value": 123.204,
            "value2": 74.91
          },
          {
            "category": "27-10-2024",
            "value": 123.204,
            "value2": 74.91
          },
          {
            "category": "28-10-2024",
            "value": 123.92,
            "value2": 75.42
          },
          {
            "category": "29-10-2024",
            "value": 124.806,
            "value2": 75.92
          },
          {
            "category": "30-10-2024",
            "value": 124.881,
            "value2": 75.76
          },
          {
            "category": "31-10-2024",
            "value": 124.983,
            "value2": 75.54
          },
          {
            "category": "01-11-2024",
            "value": 126.139,
            "value2": 75.99
          },
          {
            "category": "02-11-2024",
            "value": 126.139,
            "value2": 75.99
          },
          {
            "category": "03-11-2024",
            "value": 126.139,
            "value2": 75.99
          },
          {
            "category": "04-11-2024",
            "value": 123.861,
            "value2": 74.99
          },
          {
            "category": "05-11-2024",
            "value": 125.314,
            "value2": 75.5
          },
          {
            "category": "06-11-2024",
            "value": 128.252,
            "value2": 76.69
          },
          {
            "category": "07-11-2024",
            "value": 127.427,
            "value2": 75.92
          },
          {
            "category": "08-11-2024",
            "value": 125.37,
            "value2": 75.42
          },
          {
            "category": "09-11-2024",
            "value": 125.37,
            "value2": 75.42
          },
          {
            "category": "10-11-2024",
            "value": 125.369,
            "value2": 75.42
          },
          {
            "category": "11-11-2024",
            "value": 124.556,
            "value2": 75.24
          },
          {
            "category": "12-11-2024",
            "value": 122.965,
            "value2": 74.36
          },
          {
            "category": "13-11-2024",
            "value": 119.339,
            "value2": 73.03
          },
          {
            "category": "14-11-2024",
            "value": 120.335,
            "value2": 73.18
          },
          {
            "category": "15-11-2024",
            "value": 120.335,
            "value2": 73.18
          },
          {
            "category": "16-11-2024",
            "value": 120.335,
            "value2": 73.18
          },
          {
            "category": "17-11-2024",
            "value": 120.335,
            "value2": 73.18
          },
          {
            "category": "18-11-2024",
            "value": 121.068,
            "value2": 72.96
          },
          {
            "category": "19-11-2024",
            "value": 122.187,
            "value2": 73.3
          },
          {
            "category": "20-11-2024",
            "value": 122.187,
            "value2": 73.3
          },
          {
            "category": "21-11-2024",
            "value": 121.596,
            "value2": 72.69
          },
          {
            "category": "22-11-2024",
            "value": 122.443,
            "value2": 74.06
          },
          {
            "category": "23-11-2024",
            "value": 122.442,
            "value2": 74.06
          },
          {
            "category": "24-11-2024",
            "value": 122.442,
            "value2": 74.06
          },
          {
            "category": "25-11-2024",
            "value": 124.101,
            "value2": 75.16
          },
          {
            "category": "26-11-2024",
            "value": 124.48,
            "value2": 75.17
          },
          {
            "category": "27-11-2024",
            "value": 125.484,
            "value2": 75.59
          },
          {
            "category": "28-11-2024",
            "value": 125.516,
            "value2": 75.01
          },
          {
            "category": "29-11-2024",
            "value": 126.371,
            "value2": 75.58
          },
          {
            "category": "30-11-2024",
            "value": 126.109,
            "value2": 75.58
          }
        ],
        "4": {},
        "5": {}
      },
      "chartSeries": {
        "1": [
          {
            "Strategy": "Motilal Oswal Value Migration Strategy",
            "Index": "BSE 500 TRI"
          }
        ],
        "2": {},
        "3": [
          {
            "Strategy": "Motilal Oswal Next Trillion Dollar Opportunity",
            "Index": "BSE 500 TRI"
          }
        ],
        "4": {},
        "5": {}
      },
      "ChartCAGR": {
        "1": "604.07",
        "2": {},
        "3": "126.11",
        "4": {},
        "5": {}
      },
      "AsOnDt": {
        "1": "30-11-2024",
        "2": {},
        "3": "30-11-2024",
        "4": {},
        "5": {}
      }
    }
  },
  "isSecureResponseReqd": false
}

let PMSFundCategory = {
  "data": {
    "success": true,
    "msg": "Data Found Successfully",
    "data": [
      {
        "schid": 1,
        "amccode": "127",
        "amcname": "Motilal Oswal Mutual Fund",
        "schcode": "value",
        "schname": "Motilal Oswal Value Migration Strategy",
        "strategytype": "open ended",
        "dtofinc": "2003-02-18",
        "subscription": "open",
        "redemption": "daily",
        "valuationpoint": "daily",
        "benchmark": "bse 500 tri",
        "StrategyDesc": "The Strategy aims to benefit from the long term compounding effect on investments done in good businesses run by great business managers for superior wealth creation.",
        "StrategyIcon": "https://pms.motilaloswalmf.com/CMS/assets/uploads/PreLoginImages/MotilalOswalValueMigrationStrategy.png",
        "schnameURL": "motilal-oswal-value-migration-strategy"
      },
      {
        "schid": 2,
        "amccode": "127",
        "amcname": "Motilal Oswal Mutual Fund",
        "schcode": "ntdop",
        "schname": "Motilal Oswal Next Trillion Dollar Opportunity",
        "strategytype": "open ended",
        "dtofinc": "2007-08-03",
        "subscription": "open",
        "redemption": "daily",
        "valuationpoint": "daily",
        "benchmark": "bse 500 tri",
        "StrategyDesc": "The Strategy aims to deliver superior returns by investing in stocks from sectors that can benefit from the Next Trillion Dollar GDP growth. It aims to predominantly invest in Small and Mid Cap with a focus on identifying potential winners that would participate in successive phases of GDP growth.",
        "StrategyIcon": "https://pms.motilaloswalmf.com/CMS/assets/uploads/PreLoginImages/MotilalOswalNextTrillionDollarOpportunity.png",
        "schnameURL": "motilal-oswal-next-trillion-dollar-opportunity"
      },
      {
        "schid": 6,
        "amccode": "127",
        "amcname": "Motilal Oswal Mutual Fund",
        "schcode": "FMS",
        "schname": "Motilal Oswal Mid to Mega Strategy",
        "strategytype": "open ended",
        "dtofinc": "2017-12-18",
        "subscription": "open",
        "redemption": "daily",
        "valuationpoint": "annual",
        "benchmark": "bse 500 tri",
        "StrategyDesc": "The Strategy aims to achieve long term capital appreciation by primarily investing in equity & equity related across market capitalization.",
        "StrategyIcon": "https://pms.motilaloswalmf.com/CMS/assets/uploads/PreLoginImages/MotilalOswalMidtoMegaStrategy.png",
        "schnameURL": "motilal-oswal-mid-to-mega-strategy"
      },
      {
        "schid": 7,
        "amccode": "127",
        "amcname": "Motilal Oswal Mutual Fund",
        "schcode": "FOP",
        "schname": "Motilal Oswal Founders Portfolio",
        "strategytype": "open ended",
        "dtofinc": "2023-03-16",
        "subscription": "open",
        "redemption": "daily",
        "valuationpoint": "daily",
        "benchmark": "bse 500 tri",
        "StrategyDesc": "The fund predominantly aims to invest in Indian businesses where the founders' stake would be more than 26%. The fund aims to invest in businesses whose owners have a long horizon and have displayed quality of governance and adaptability through optimal usage and distribution of capital.",
        "StrategyIcon": "https://pms.motilaloswalmf.com/CMS/assets/uploads/PreLoginImages/MotilalOswalFoundersPortfolio.png",
        "schnameURL": "motilal-oswal-founders-portfolio"
      },
      {
        "schid": 8,
        "amccode": "127",
        "amcname": "Motilal Oswal Mutual Fund",
        "schcode": "PMIGS",
        "schname": "Motilal Oswal India Growth Fund",
        "strategytype": "open ended",
        "dtofinc": "2023-11-01",
        "subscription": "open",
        "redemption": "daily",
        "valuationpoint": "daily",
        "benchmark": "bse 500 tri",
        "StrategyDesc": "The Strategy aims to benefit from the long term compounding effect on investments done in good businesses run by great business managers for superior wealth creation.",
        "StrategyIcon": "https://pms.motilaloswalmf.com/CMS/assets/uploads/PreLoginImages/MotilalOswalIndiaGrowthFund.png",
        "schnameURL": "motilal-oswal-india-growth-fund"
      }
    ]
  },
  "isSecureResponseReqd": false
}



let PMSHomePage = {
  "data": {
    "success": true,
    "msg": "Data Found Successfully",
    "Banner": "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_23102021040712844038.jpeg",
    "BannerMobile": "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_23102021040712844038.jpeg",
    "BannerSlider": [
      "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_23102021040712844038.jpeg"
    ],
    "BannerMobileSlider": [
      "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_23102021040712844038.jpeg"
    ],
    "NewBannerSlider": [
      {
        "image": "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_30122021084327821677.jpg",
        "title": "",
        "link": "https://pms.motilaloswalmf.com/PMSListing",
        "order": 2
      },
      {
        "image": "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_10062024113439849163.jpg",
        "title": "",
        "link": "",
        "order": 2
      }
    ],
    "NewBannerMobileSlider": [
      {
        "image": "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_30122021084342356501.jpg",
        "title": "",
        "link": "https://pms.motilaloswalmf.com/PMSListing",
        "order": 2
      },
      {
        "image": "https://pms.motilaloswalmf.com/CMS/assets/uploads/aboutus/banner_02072024104609241070.jpg",
        "title": "",
        "link": "",
        "order": 2
      }
    ],
    "section_1": [
      {
        "contentId": 15,
        "contentpageid": 23,
        "sectionno": 1,
        "title": "21+ Years",
        "desc1": "Of Consistent Performance",
        "desc2": "This card will flip on hover to show more details of the key statistics mentioned. We can show two- three sentences of data over here.",
        "image": null,
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": "2024-10-07T11:21:14.000Z",
        "updatedBy": "admin@admin.com"
      },
      {
        "contentId": 16,
        "contentpageid": 23,
        "sectionno": 1,
        "title": "11,000+",
        "desc1": "Unique Investors",
        "desc2": "This card will flip on hover to show more details of the key statistics mentioned. We can show two- three sentences of data over here.",
        "image": null,
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": "2024-10-07T11:53:38.000Z",
        "updatedBy": "admin@admin.com"
      },
      {
        "contentId": 17,
        "contentpageid": 23,
        "sectionno": 1,
        "title": "15,000+ Crores",
        "desc1": "Of Asset Under Management",
        "desc2": "This card will flip on hover to show more details of the key statistics mentioned. We can show two- three sentences of data over here.",
        "image": null,
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": "2024-10-07T12:02:08.000Z",
        "updatedBy": "admin@admin.com"
      },
      {
        "contentId": 18,
        "contentpageid": 23,
        "sectionno": 1,
        "title": "3 Decades",
        "desc1": "Legacy in Equity",
        "desc2": "This card will flip on hover to show more details of the key statistics mentioned. We can show two- three sentences of data over here.",
        "image": null,
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": "2024-10-07T12:02:20.000Z",
        "updatedBy": "admin@admin.com"
      }
    ],
    "section_2": [
      {
        "contentId": 19,
        "contentpageid": 23,
        "sectionno": 2,
        "title": "PMS Strategies",
        "desc1": "",
        "desc2": "",
        "image": null,
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": "2022-05-20T04:34:00.000Z",
        "updatedBy": "admin@admin.com"
      }
    ],
    "section_3": [
      {
        "contentId": 20,
        "contentpageid": 23,
        "sectionno": 3,
        "title": "Experienced Leadership & Management",
        "desc1": "",
        "desc2": "",
        "image": "",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": null,
        "updatedBy": null
      },
      {
        "contentId": 21,
        "contentpageid": 23,
        "sectionno": 3,
        "title": "Raamdeo Agarwal",
        "desc1": "Chairman",
        "desc2": "Raamdeo Agrawal is the Co-Founder and Joint Managing Director of Motilal Oswal Financial Services Limited (MOFSL). As Chairman of Motilal Oswal Asset Management Company, he has been instrumental in evolving the investment management philosophy and framework.",
        "image": "4e236-raamdeo-agrawal.jpeg",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": "2022-01-07T01:20:04.000Z",
        "updatedBy": "admin@admin.com"
      }
    ],
    "section_4": [
      {
        "contentId": 22,
        "contentpageid": 23,
        "sectionno": 4,
        "title": "Performance",
        "desc1": "",
        "desc2": "",
        "image": "",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": null,
        "updatedBy": null
      }
    ],
    "section_5": [
      {
        "contentId": 23,
        "contentpageid": 23,
        "sectionno": 5,
        "title": "Key Benifits",
        "desc1": "",
        "desc2": "",
        "image": "",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": null,
        "updatedBy": null
      },
      {
        "contentId": 24,
        "contentpageid": 23,
        "sectionno": 5,
        "title": "Transparency ",
        "desc1": "in portfolio holdings, transactions, and expenses.",
        "desc2": "",
        "image": "",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": null,
        "updatedBy": null
      },
      {
        "contentId": 25,
        "contentpageid": 23,
        "sectionno": 5,
        "title": "Segregated portfolios",
        "desc1": "to ensure that individual investor behavior doesn't impact other's portfolio.",
        "desc2": "",
        "image": "",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": null,
        "updatedBy": null
      },
      {
        "contentId": 26,
        "contentpageid": 23,
        "sectionno": 5,
        "title": "Dedicated RMs",
        "desc1": "for your account and centralized team of service coordinators for hassle-free servicing",
        "desc2": "",
        "image": "",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": null,
        "updatedBy": null
      }
    ],
    "section_6": [
      {
        "contentId": 27,
        "contentpageid": 23,
        "sectionno": 6,
        "title": "Still have some questions about PMS?",
        "desc1": "",
        "desc2": "",
        "image": "",
        "isactive": 1,
        "createdDate": "2020-10-17T02:18:51.000Z",
        "createdBy": "admin@admin.com",
        "updatedDate": null,
        "updatedBy": null
      }
    ]
  },
  "isSecureResponseReqd": false
}

const getPMSHomePageData = async () => {
  return PMSHomePage.data;
  try {
    let Alldata = {};
    let payload = { "api_name": "PMSHomePage" };
    let resData = await api.PMSHomePage(payload);
    if (resData.data.success) {
      Alldata = resData.data;
    }
    return Alldata;
  } catch (e) {
    throw e;
  }
}

const getPMSCategoryData = async () => {
  return PMSFundCategory.data;
  try {
    let Alldata = {};
    let strategyPayload = { "api_name": "PMSFundCategory" };
    let resData = await api.PMSCategory(strategyPayload);
    if (resData.data.success) {
      Alldata = resData.data;
    }
    return Alldata;
  } catch (e) {
    throw e;
  }
}

const getPMSGraphData = async () => {
  return PMSGraphData.data.data;
  try {
    let Alldata = null;
    let graphPayload = { "api_name": "PMSGraphData" };
    let resData = await api.PMSGraphData(graphPayload);
    if (resData.data.success) {
      Alldata = resData.data.data;
    }
    return Alldata;
  } catch (e) {
    throw e;
  }
}

const PMShomepage = (props) => {
  let apiData = props.apiData;
  let strategyData = props.strategyApiData;
  console.log("=============>", props);

  // let imageURl = "";
  // if (Object.keys(apiData).length > 0) {
  //   if (isMobile) {
  //     imageURl = apiData.BannerMobile;
  //   } else {
  //     imageURl = apiData.Banner
  //   }
  // }

  let [graphApiData, setGraphApiData] = useState(props.graphData);

  // extra api call from client side commented
  /* useEffect(() => {
      let payload = {
          "api_name": "PMSGraphData"
      }

      api.PMSGraphData(payload).then(res => {
          if (res.data.success) {
              let getData = res.data.data;
              setGraphApiData(getData)

          }
      }).catch(error => {
          // console.log(error, 'errorCatch')
      })
  }, [])*/

  // if(graphApiData.length===0){
  //     return <Loader/>
  // }


  let data = [
    {
      "faqid": 1,
      "tags": "5,7,8",
      "question": "What is Portfolio Management Services (PMS)?",
      "answer": "<p>Portfolio Management Service is a tailor made professional service offered to cater the investments objective of different investor classes. The Investment solutions provided by PMS cater to a niche segment of clients. The clients can be Individuals or Institutions entities with high net worth. In simple words, a portfolio management service provides professional management of your investments to create wealth.</p>\n",
      "isactive": 1,
      "createdDate": "2020-08-14T08:16:25.000Z",
      "createdBy": "",
      "updatedDate": null,
      "updatedBy": null
    }, {
      "faqid": 2,
      "tags": "1,6,8",
      "question": "Will it help me on my tax status?",
      "answer": "<p>We provide each client an audited tax statement of his portfolio annually. This can be used for calculating your tax liability and hence forth filing returns. However, we advice all our clients to consult their tax consultant before filing of their tax returns.</p>\n",
      "isactive": 1,
      "createdDate": "2020-08-15T02:01:21.000Z",
      "createdBy": "",
      "updatedDate": "2020-08-15T02:18:44.000Z",
      "updatedBy": "admin@admin.com"
    }
  ];

  let dummyapiData = {
    "success": true,
    "msg": "Data Found Successfully",
    "Banner": "https://mf.moamc.com/CMS/assets/uploads/aboutus/4a0a0-minimalinv-banner-desktop.jpg|banner_11102021051519769537.jpg|banner_11102021051530824843.jpg",
    "BannerMobile": "https://mf.moamc.com/CMS/assets/uploads/aboutus/c15ac-minimalinv-banner-mob-1-.jpg",
    "BannerSlider": [
      "https://mf.moamc.com/CMS/assets/uploads/aboutus/4a0a0-minimalinv-banner-desktop.jpg",
      "https://mf.moamc.com/CMS/assets/uploads/aboutus/banner_11102021051519769537.jpg",
      "https://mf.moamc.com/CMS/assets/uploads/aboutus/banner_11102021051530824843.jpg"
    ],
    "BannerMobileSlider": [
      "https://mf.moamc.com/CMS/assets/uploads/aboutus/c15ac-minimalinv-banner-mob-1-.jpg"
    ],
    "NewBannerSlider": [
      {
        "image": "https://mf.moamc.com/CMS/assets/uploads/aboutus/banner_13022023123726177308.jpg",
        "title": "",
        "link": "https://pms.motilaloswalmf.com/our-strategies\t",
        "order": 1
      }
    ],
    "NewBannerMobileSlider": [
      {
        "image": "https://mf.moamc.com/CMS/assets/uploads/aboutus/banner_03122021084859542089.jpg"
      }
    ],
    "section_1": [
      "{contentId: 15, contentpageid: 23, createdBy: \"admi…}",
      "{contentId: 16, contentpageid: 23, createdBy: \"admi…}",
      "{contentId: 17, contentpageid: 23, createdBy: \"admi…}",
      "{contentId: 18, contentpageid: 23, createdBy: \"admi…}"
    ],
    "section_2": [
      "{contentId: 19, contentpageid: 23, createdBy: \"admi…}"
    ],
    "section_3": [
      "{contentId: 20, contentpageid: 23, createdBy: \"admi…}",
      "{contentId: 21, contentpageid: 23, createdBy: \"admi…}"
    ],
    "section_4": [
      "{contentId: 22, contentpageid: 23, createdBy: \"admi…}"
    ],
    "section_5": [
      "{contentId: 23, contentpageid: 23, createdBy: \"admi…}",
      "{contentId: 24, contentpageid: 23, createdBy: \"admi…}",
      "{contentId: 25, contentpageid: 23, createdBy: \"admi…}",
      "{contentId: 26, contentpageid: 23, createdBy: \"admi…}"
    ],
    "section_6": [
      "{contentId: 27, contentpageid: 23, createdBy: \"admi…}"
    ]
  };



  //assign dummyapiData if project is running in local and no banner data available in db.
  let bannerData = apiData; //; apiData
  console.log("apiData=====>", graphApiData);

  return (
    <React.Fragment>
      {/* {Object.keys(apiData).length > 0 ? */}
      <Aux>
        {
          bannerData.NewBannerMobileSlider.length > 0
            ?
            <BannerWrapper
              data={bannerData}
            />
            : null
        }
        <div style={{ clear: "both" }}></div>
        <Container class="whympms">
          <div className="col-md-12 text-center">
            <Heading title="Why Motilal Oswal Portfolio Management Services?" class="bold" />
          </div>
          <div className="row">
            {apiData?.section_1?.map((item, index) =>
              <div key={index} className="col-6 col-md-6 col-lg-6">
                <div className="col-md-12 flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 mobile-pad0">
                          <div className="img-responsive flip-icon">
                            <NextImage
                              image={SECTION_1_IMAGES[index]}
                              alt={'pms1'}
                              width={126}
                              height={98}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-8">
                          <h2 className="flip-heading">{item.title}</h2>
                          <p className="flip-subHeading">{item.desc1}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flip-card-back text-center">
                      <p className="back-txt">This card will flip on hover to show more details of the key statistics mentioned. We can show two- three sentences of data over here.</p>
                      <div className="col-md-12">
                        <a href="" className="information-button">Know more
                          <Image image="../assets/images/landing/rightarrow.svg" class="" style={{ height: 'auto', width: 'auto' }} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
        </Container>
        <Section>
          <Container>
            <div className="col-md-12 text-center">
              <HeadingH2 title="PMS Strategies" class="bold" />
            </div>
            <div className="col-md-12 padd0">
              {strategyData !== null ?
                <PMSBox min={3} max={3} sliderBox={true} apiData={strategyData.data} /> : null}
            </div>
          </Container>
        </Section>
        <Container class="leaderrasec">
          <div className="col-md-12 text-center">
            <HeadingH3 title="Experienced Leadership & Management" class="bold" />
          </div>
          <div className="row justify-content-md-center">
            <div className="col-md-10">
              <Leader />
            </div>
          </div>
        </Container>
        {/* <Section>
          <Container>
            <div className="col-md-12 text-center">
              <HeadingH3 title="Performance" class="bold" />
            </div>
            <div className="col-md-12">
              {graphApiData !== null ?
                <Performance apiresp={graphApiData} /> : null}
            </div>
          </Container>
        </Section> */}
        <Container class="keybenefitssec">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeadingH3 title="Key Benefits" class="bold" />
            </div>
          </div>
          <div className="col-md-12">
            <Keypms />
          </div>
        </Container>
        <Section class="Avatar pmstree passiveBottm faqpmssec">
          <Container>
            <div className="row">
              <div className="col-md-12 text-center">
                <HeadingH3
                  class="text-center bold"
                  title="Still have some questions about PMS?"
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <Accord data={data} />
                <div className="col-md-12 padd0 text-right">
                  <ViewAll url="/faq?key=pms" />
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </Aux>
      {/* : <NoFount msg="Data not found" />
      } */}
    </React.Fragment>
  );
}

export async function getServerSideProps({ req, res }) {
  // Router.reload("/");
  try {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
    );
    let values = await Promise.all([getPMSHomePageData(),
    getPMSCategoryData(), getPMSGraphData()]);
    let [apiData, strategyApiData, graphData] = values;
    console.log("$$$$$$$$$$$$$$$$$$$$$", values)
    return {
      props: {
        apiData: apiData, strategyApiData: strategyApiData,
        graphData: graphData, statusCode: 200
      }
    }
  }
  catch (error) {
    console.log('Error', error);
    return {
      props: {
        apiData: {}, strategyApiData: {},
        graphData: null, statusCode: 503
      }
    }
  }
}

export default PMShomepage;