
import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Head from 'next/head'
import { useRouter } from "next/router";
import Axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import '/assets/css/fonts.css';
import '../styles/styles.css';
import '../styles/contact.css';
import { getUserMachineIp } from "../helper/methods";
import { osName } from 'react-device-detect';
import PreLoginApi from "../services/preLoginApi";
import AppApi from "../services/appApi";

import useSeo from "../components/hooks/useSeo";

let api = new PreLoginApi();
let appApi = new AppApi();

let delay = process.env.NEXT_PUBLIC_SCRIPT_LOADING_DELAY;

const loadIframe = () => {
  let noScriptTag = document.getElementById('body-no-script-tag');
  let iframeTag = document.createElement('iframe');
  iframeTag.src = "https://www.googletagmanager.com/ns.html?id=GTM-NPBHR7";
  iframeTag.height = 0;
  iframeTag.width = 0;
  iframeTag.style.display = "none";
  iframeTag.style.visibility = "hidden";
  noScriptTag?.appendChild(iframeTag);
}

const loadGtm = () => {
  const gtmScript = document.createElement('script');
  gtmScript.type = "text/javascript";
  gtmScript.innerHTML = `(function(w,d,s,l,i){w[l] = w[l] || []; w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NPBHR7');`;
  document.head.appendChild(gtmScript);
}

const loadGtag1 = () => {
  const gtagScript = document.createElement('script');
  // gtagScript.setAttribute('async', true);
  gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=UA-61001832-1";
  document.head.appendChild(gtagScript);
}

const loadGtag2 = () => {
  const gtagScript2 = document.createElement('script');
  gtagScript2.innerHTML = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-61001832-1');`;

  document.head.appendChild(gtagScript2);
}

// const loadGtag3 = () => {
//   let gtagScript3 = document.createElement('script');
//   gtagScript3.type = "text/javascript";
//   gtagScript3.innerHTML = `
// (function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k
// in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')


// Moengage = moe({
// app_id:"${app_id}",                    
// debug_logs: ${allow_debugging}
// });
// `;
//   document.head.appendChild(gtagScript3);
// };

function MyApp({ Component, pageProps, footerData }) {

  const router = useRouter();
  let { query } = router;

  const { title, pdesc, sdesc, keyData } = useSeo(footerData);
  var appSetting = '%7B%22openMode%22%3A%7B%22text%22%3A%22Talk%20to%20Alfred%22%2C%22height%22%3A450%2C%22width%22%3A320%7D%2C%22closeMode%22%3A%7B%22text%22%3A%22Talk%20to%20Alfred%22%2C%22width%22%3A300%2C%22displayType%22%3A%22circular%22%7D%2C%22common%22%3A%7B%22logo%22%3A%22//www.buildquickbots.com/widget/bots/fb23e46f7f61166dd84cb5e73857556d/04dcc76493f0d61f11f47ab34e9ae180/logo.png%22%2C%22imgDispType%22%3A%22rectangular%22%2C%22bgColor%22%3A%22%231A294E%22%2C%22fontSize%22%3A18%2C%22fontColor%22%3A%22%23FFFFFF%22%2C%22textColor%22%3A%22%23565656%22%7D%2C%22content%22%3A%7B%22bot%22%3A%22//www.buildquickbots.com/widget/bots/fb23e46f7f61166dd84cb5e73857556d/04dcc76493f0d61f11f47ab34e9ae180/bot.png%22%2C%22botBubbleColor%22%3A%22%23eeeeee%22%2C%22botFontColor%22%3A%22%231A294E%22%2C%22user%22%3A%22https%3A//www.gupshup.io/images/botwidget/ic_user.png%22%2C%22userBubbleColor%22%3A%22%231A294E%22%2C%22userFontColor%22%3A%22%23FFFFFF%22%2C%22pageTitle%22%3A%22Chat%20Bot%22%2C%22fontSize%22%3A18%2C%22fontFamily%22%3A%22Roboto%22%7D%2C%22config%22%3A%7B%22persistenceMenu%22%3A%22%22%2C%22perMenuImg%22%3A%22%22%2C%22attachment%22%3A%22No%22%2C%22widgetType%22%3A%22Text%22%2C%22msgEnc%22%3A%22No%22%2C%22webView%22%3A%22Yes%22%2C%22RDStatus%22%3A%22No%22%2C%22isResponsive%22%3A%22No%22%2C%22allowHtmlFromBot%22%3A%22Yes%22%7D%7D';
  var sessionID = "";
  var userID = "";
  var Role = "C";
  var token = "";
  var baseurl = process.env.NEXT_PUBLIC_BASEURL_CHATBOT_SDK
  var botType = process.env.NEXT_PUBLIC_CHATBOT_TYPE

  const [state, setstate] = useState({
    appSetting: appSetting, sessionID: sessionID, userID: userID
    , Role: Role, token: token, baseurl: baseurl
  })

  const getSessionId = async () => {
    let body = {
      "machineip": await getUserMachineIp(),
      "screensize": window.screen.width + ' X ' + window.screen.height,
      "osname": osName
    }

    appApi.initApi(body).then(res => {
      let data = res.data;
      setstate({ ...state, sessionID: data.appId });
    });
  }

  useEffect(() => {
    setTimeout(() => getSessionId(), 500);
    setTimeout(() => loadIframe(), delay);
    setTimeout(() => loadGtm(), delay);
    setTimeout(() => loadGtag1(), delay);
    // setTimeout(()=> loadGtag2(), delay);
  }, [])

  useEffect(() => {
    if (state.sessionID !== "") {

      let url = `https://www.gupshup.io/developer/bot/${botType}/setCreds?userid=${state.userID}&role=${state.Role}&sessionid=${(state.sessionID).toUpperCase()}&token=${state.token}&baseurl=${state.baseurl}`

      fetch(url, {
        method: "POST",
        headers: {
          "Authorization": "81cee6b3-f6fa-4e61-86ae-991b36c59954",
          "Accept": "application/x-www-form-urlencoded"
        }
      }).then(response => response.json()).then(data => {
        botUI();
      }).catch((error) => {
        console.error('Error:', error);
      });
      botUI();
      let scriptkey = process.env.NEXT_PUBLIC_CHATBOT_SCRIPT_KEY
      let scriptbrand = botType
      let scriptId = "gs-sdk"
      let scriptcallback = "tcAsyncInit"
      let scriptsrc = "https://www.buildquickbots.com/botwidget/v3/demo/static/js/sdk.js?v=3"
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = scriptsrc;
      script.setAttribute('brand', scriptbrand);
      script.setAttribute('key', scriptkey);
      script.setAttribute('callback', scriptcallback);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, [state.sessionID])

  function botUI() {
    window.tcAsyncInit = function (e) {

      var obj = {
        roomid: state.sessionID,
        sender: {
          id: state.sessionID,
          name: "Me"
        },
        recipient: {
          id: state.sessionID,
          name: "Bot"
        },
        message: {
          text: "Hello",
          type: "text"
        }
      };
      var initObj = {

        botkey: process.env.NEXT_PUBLIC_CHATBOT_SCRIPT_KEY,
        appUISetting: state.appSetting,
        version: 'v3'
      }

      initObj.userObj = obj;
      e.init(initObj);
    };
  }

  let headerFlag = false;
  if (Object.keys(query).length > 0) {
    if (query.hasOwnProperty("view")) {
      let getVal = query["view"];
      if (getVal === "app") {
        headerFlag = true;
      }
    }
  }

  return (
    <React.Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="minimum-scale=1 width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={pdesc} />
        <meta name="keywords" content={keyData} />

        {/* <meta name="description" content="Move a step closer towards financial stability with Motilal Oswal Mutual Fund. Experience difference with the best portfolio management services" /> */}

        <link rel="canonical" href="https://pms.motilaloswalmf.com/" />
        {/* <!-- Google Tag Manager --> */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NPBHR7');`,
          }}
      >
      </script> */}
        {/* <!-- End Google Tag Manager --> */}

        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-61001832-1"></script>
        <script dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-61001832-1');`,
        }}>
        </script> */}

        {/* <title>Mutual Funds | Best Mutual Fund Investment in India - Motilal Oswal Mutual Funds</title> */}
        {/* <title>Portfolio Management Service In India - Motilal Oswal Mutual Fund</title> */}
        <title>{title}</title>

      </Head>
      {!headerFlag ? <Header /> : null}
      <Component {...pageProps} />
      {!headerFlag ? <Footer /> : null}
    </React.Fragment>
  )


}
let SEODetails = {
  "data": {
    "success": 1,
    "msg": "SEO Details found successfully",
    "data": {
      "Mutual Fund": {
        "overview": {
          "contentPageId": 2,
          "PageURL": "https://www.motilaloswalmf.com/about-us/overview",
          "title": "Asset Management Company - Motilal Oswal Mutual Fund",
          "metaContent": "A diverse asset management company, Motilal Oswal Mutual Fund helps clients achieve their objectives in a world that is changing quickly.",
          "shortDesc": "NA",
          "keywords": "mutual funds, online mutual funds, mutual funds india, investment options, asset management company, saving schemes, equity investments, investment schemes, investment fund, mutual fund services, asset management company,moamc, motilal oswal, passive funds , active funds",
          "metaTags": "NA"
        },
        "people": {
          "contentPageId": 4,
          "PageURL": "https://www.motilaloswalmf.com/about-us/people",
          "title": "People",
          "metaContent": "People Page Description will appear from here",
          "shortDesc": "People page short Desc.",
          "keywords": "People, etc",
          "metaTags": "Meta tags foe People page"
        },
        "mf-home": {
          "contentPageId": 1,
          "PageURL": "https://www.motilaloswalmf.com/",
          "title": "Mutual Funds Investment- Buy and Invest in Mutual Fund Online",
          "metaContent": "Explore mutual funds managed by Motilal Oswal Mutual Fund for a secure financial future. Start investing today and watch your wealth grow.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "historical-nav": {
          "contentPageId": 31,
          "PageURL": "https://www.motilaloswalmf.com/nav/historical-nav",
          "title": "Historical NAV Prices of Motilal Oswal Mutual funds",
          "metaContent": "Check out historical NAV prices of Motilal Oswal Equity Fund Compare NAV of a scheme for two dates Select Mutual Fund",
          "shortDesc": "Check out historical NAV prices of Motilal Oswal  Equity Fund Compare NAV of a scheme for two dates Select Mutual Fund",
          "keywords": "Historical NAV Prices for Mutual Funds, Motilal Oswal mutual fund NAV",
          "metaTags": "NA"
        },
        "mutual-funds": {
          "contentPageId": 5,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds",
          "title": "Mutual Funds Performance | Top Performing Mutual Funds to Invest in India",
          "metaContent": "Check out our top performing Mutual Funds to invest in India. Track the performance of equity mutual fund schemes and Portfolio Management Services.",
          "shortDesc": "Check out our top performing Mutual Funds to invest in India. Track the performance of equity mutual fund schemes and Portfolio Management Services.",
          "keywords": "Mutual Funds Performance, Top Performing Mutual Funds to Invest in India",
          "metaTags": "NA"
        },
        "historical-dividend": {
          "contentPageId": 32,
          "PageURL": "https://www.motilaloswalmf.com/nav/historical-dividend",
          "title": "Mutual Fund Dividends History of Motilal Oswal Mutual funds",
          "metaContent": "Get the historical dividend payouts of Motilal Oswal Equity Fund according to record date, dividend individual rate dividend non individual rate",
          "shortDesc": "Get the historical dividend payouts of Motilal Oswal Equity Fund according to record date, dividend individual rate dividend non individual rate",
          "keywords": "Mutual Fund Dividends History, Motilal Oswal Focused Dynamic Equity Fund",
          "metaTags": "NA"
        },
        "latest-nav": {
          "contentPageId": 30,
          "PageURL": "https://www.motilaloswalmf.com/nav/latest-nav",
          "title": "Net Asset Value - Motilal Oswal Mutual Fund",
          "metaContent": "Stay regularly updated with the latest NAV changes with Motilal Oswal Mutual Fund. Get the most recent information on mutual fund net asset values here!",
          "shortDesc": "Check out Motilal Oswal lates Mutual Fund  NAV Get the latest and the updated NAV for all the mutual funds online",
          "keywords": "Latest Mutual Fund NAV, Motilal Oswal Mutual Fund Schemes",
          "metaTags": "NA"
        },
        "goal-investment-calculator": {
          "contentPageId": 59,
          "PageURL": "https://www.motilaloswalmf.com/tools/goal-investment-calculator",
          "title": "Goal Calculator - Calculate Goal Online With Financial Goal Calculator",
          "metaContent": "Financial Goal Calculator - Calculate, plan & track your investment with our goal investment calculator online at Motilal Oswal Mutual Fund, Invest now!",
          "shortDesc": "NA",
          "keywords": "mutual fund goal calculator - 210, financial goal calculator - 320, goal calculator - 720",
          "metaTags": "NA"
        },
        "sip-return-calculator": {
          "contentPageId": 58,
          "PageURL": "https://www.motilaloswalmf.com/tools/sip-return-calculator",
          "title": "Mutual Fund Calculator: SIP Calculator Online | Motilal Oswal MF",
          "metaContent": "The Mutual Fund Calculator - Calculate the rough estimate of SIP returns on Motilal Oswal MF SIP Calculator. Click here to know more, Invest Now!",
          "shortDesc": "",
          "keywords": "mutual fund calculator, sip calculator, mutual fund return calculator, sip return calculator",
          "metaTags": null
        },
        "compounding-magic": {
          "contentPageId": 57,
          "PageURL": "https://www.motilaloswalmf.com/tools/compounding-magic",
          "title": "Compound Interest Calculator- Calculate Compound Interest Online|Motilal Oswal MF",
          "metaContent": "Calculate compound interest online with Motilal Oswal Mutual Fund. Calculate & compare compound interest, monthly savings, pension & more. Click here to know more. Invest Now!",
          "shortDesc": "",
          "keywords": "compound interest calculator (3,01,000), compound calculator (33,100), daily compound interest caculator (9,900), compound interest formula calculator (5400), monthly compound interest calculator (3,600), calculate compound interest online (3600)",
          "metaTags": null
        },
        "total-expense-ratio": {
          "contentPageId": 33,
          "PageURL": "https://www.motilaloswalmf.com/download/total-expense-ratio",
          "title": "Total Expense Ratio for Mutual Funds | Motilal Oswal Mutual Fund",
          "metaContent": "The total expense ratio, or TER, helps the investor measure the total cost of the mutual fund. Find out the Total Expense Ratio (TER) for all the mutual funds and make an informed decision.",
          "shortDesc": "The total expense ratio, or TER, helps the investor measure the total cost of the mutual fund.  Find out the Total Expense Ratio (TER) for all the mutual f",
          "keywords": "Total Expense Ratio or TER for Mutual Funds",
          "metaTags": "NA"
        },
        "status-of-redressal": {
          "contentPageId": 35,
          "PageURL": "https://www.motilaloswalmf.com/download/status-of-redressal",
          "title": "Status of Redressal Downloads - Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Read our Status of Redressal Brochure. It contains information on what we will do with your complaint, your legal rights and options you have for resolving your complaint.",
          "shortDesc": "Read our Status of Redressal Brochure. It contains information on what we will do with your complaint, your legal rights and options you have for resolving",
          "keywords": "Status of Redressal, Investor Complaint, Motilal Oswal Mutual Funds",
          "metaTags": "NA"
        },
        "sid": {
          "contentPageId": 36,
          "PageURL": "https://www.motilaloswalmf.com/download/sid",
          "title": "Scheme Information Document Downloads - Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Check out the Scheme Information Document sets forth concisely the information about the scheme, before investing.",
          "shortDesc": "Check out the Scheme Information Document sets forth concisely the information about the scheme, before investing.",
          "keywords": "Scheme Information Document, Open Ended Mutual Fund Scheme",
          "metaTags": "NA"
        },
        "sai": {
          "contentPageId": 37,
          "PageURL": "https://www.motilaloswalmf.com/download/sai",
          "title": "Statement of Additional Information Downloads -Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Read this Statement of Additional Information (SAI) which contains details of Motilal Oswal mutual fund schemes, its constitution and certain tax, legal and general information.",
          "shortDesc": "Read this Statement of Additional Information (SAI) which contains details of Motilal Oswal mutual fund schemes, its constitution and certain tax, legal an",
          "keywords": "Statement of Additional Information, Mutual Funds in India",
          "metaTags": "NA"
        },
        "regulatory-update": {
          "contentPageId": 38,
          "PageURL": "https://www.motilaloswalmf.com/download/regulatory-updates",
          "title": "Regulatory Updates Downloads -Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Check out Regulatory updates of Motilal Oswal mutual fund schemes, disclosure of AAUM, common KIM cum application form of equity and debt schemes and proxy voting report.",
          "shortDesc": "Check out Regulatory updates of Motilal Oswal mutual fund schemes, disclosure of AAUM, common KIM cum application form of equity and debt schemes and proxy",
          "keywords": "Regulatory Updates, Motilal Oswal Mutual Funds",
          "metaTags": "NA"
        },
        "financials": {
          "contentPageId": 40,
          "PageURL": "https://www.motilaloswalmf.com/download/financials",
          "title": "Download Mutual Fund Financial Statements -Motilal Oswal Mutual Fund Schemes",
          "metaContent": "0",
          "shortDesc": "0",
          "keywords": "AMC Financials Report, Open Ended Mutual Fund Schemes",
          "metaTags": "NA"
        },
        "fact-sheets": {
          "contentPageId": 41,
          "PageURL": "https://www.motilaloswalmf.com/download/factsheets",
          "title": "Download Mutual Fund Fact Sheets - Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Check out latest factsheet of Motilal Oswal open ended mutual fund schemes, in order to keep a tab on all your investments with us.",
          "shortDesc": "Check out latest factsheet of Motilal Oswal open ended mutual fund schemes, in order to keep a tab on all your investments with us.",
          "keywords": "Open Ended Mutual Fund Scheme India, Motilal Oswal Latest Factsheet",
          "metaTags": "NA"
        },
        "addendums": {
          "contentPageId": 42,
          "PageURL": "https://www.motilaloswalmf.com/download/addendums",
          "title": "Download Notice cum Addendum- Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Read the Notice cum Addendums, as it forms an integral part of the Scheme Information Document (SID) / Key Information Memorandum (KIM) /Statement of Additional Information (SAI) of the schemes of Motilal Oswal mutual fund as amended from time to time.",
          "shortDesc": "Read the Notice cum Addendums, as it forms an integral part of the Scheme Information Document (SID) / Key Information Memorandum (KIM) /Statement of Addit",
          "keywords": "Open Ended Mutual Fund Scheme, Notice cum Addendum",
          "metaTags": "NA"
        },
        "month-end-portfolio": {
          "contentPageId": 43,
          "PageURL": "https://www.motilaloswalmf.com/download/month-end-portfolio",
          "title": "Download Month End Portfolio -Motilal Oswal Mutual Fund Schemes",
          "metaContent": "0",
          "shortDesc": "0",
          "keywords": "Mutual Fund Schemes in India, Month End Portfolio",
          "metaTags": "NA"
        },
        "leaflets-brouchers": {
          "contentPageId": 44,
          "PageURL": "https://www.motilaloswalmf.com/download/leaflets-brouchers",
          "title": "Download Mutual Fund Leaflet/Brochure - Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Read more about the Motilal Oswal equity funds, investment philosophy & systematic investment plan leaflets and brochures.",
          "shortDesc": "Read more about the Motilal Oswal equity funds, investment philosophy &amp; systematic investment plan leaflets and brochures.",
          "keywords": "Mutual Fund Schemes in India, Motilal Oswal Funds",
          "metaTags": "NA"
        },
        "unclaimed-dividend/redemption": {
          "contentPageId": 46,
          "PageURL": "https://www.motilaloswalmf.com/download/unclaimed-dividend-redemption",
          "title": "Unclaimed Dividend or Redemption- Motilal Oswal Mutual Fund Schemes",
          "metaContent": "Check your Unclaimed Redemptions or Unclaimed Dividends, which are those amounts that are processed and released but not encashed by/credited to the bank account of the unit holders of the schemes of Motilal Oswal mutual funds.",
          "shortDesc": "Check your Unclaimed Redemptions or Unclaimed Dividends, which are those amounts that are processed and released but not encashed by/credited to the bank a",
          "keywords": "Check your Unclaimed Redemptions/Dividends, Best Mutual Fund Schemes",
          "metaTags": "NA"
        },
        "motilal-oswal-ultra-short-term-fund": {
          "contentPageId": 11,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-ultra-short-term-fund",
          "title": "Invest Online In Motilal Oswal Ultra Short Term Fund",
          "metaContent": "By providing returns possible while maintaining reasonable levels of risk and liquidity, this fund that suits your risk tolerance and expected returns.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-liquid-fund": {
          "contentPageId": 10,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-liquid-fund",
          "title": "Invest Online In Liquid Funds With  Motilal Oswal Mutual Funds\n",
          "metaContent": "Motilal Oswal Liquid Funds - Yield greater liquidity and optimal returns for the investors using a portfolio of money market investments.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-flexi-cap-fund": {
          "contentPageId": 8,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-flexi-cap-fund",
          "title": "Invest Online In Flexi Cap Fund  With Motilal Oswal Mutual Funds",
          "metaContent": "Motilal Oswal Flexi Cap Fund is an Equity Fund that offers investors an ideal mix of equities with minimal risks. Invest in Flexi Cap to gain in long term returns.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-midcap-fund": {
          "contentPageId": 7,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-midcap-fund",
          "title": "Invest Online In Motilal Oswal Midcap Fund",
          "metaContent": "Invest in Motilal Oswal Mutual Fund -Motilal Oswal Midcap Fund and benefit from India's growth story. Experience a diversified investment experience into midcap stocks.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-long-term-equity-fund": {
          "contentPageId": 19,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-long-term-equity-fund",
          "title": "Invest Online In Motilal Oswal Long Term Equity Fund\n",
          "metaContent": "Save tax and reap the benefit on 80 by investing in Motilal Oswal Long Term Equity Fund. Check out the complete details, Invest now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "forms": {
          "contentPageId": 39,
          "PageURL": "https://www.motilaloswalmf.com/download/forms",
          "title": "Downloads - Motilal Oswal Mutual Fund",
          "metaContent": "Download - Download all your investment related documents with an ease of a click at Motilal Oswal Mutual Fund",
          "shortDesc": "Find all the latest information of Motilal Oswal mutual fund schemes by downloading these documents, before investing.",
          "keywords": "Latest Equity Market Updates, Motilal Oswal Mutual Funds",
          "metaTags": "NA"
        },
        "presentations": {
          "contentPageId": 45,
          "PageURL": "https://www.motilaloswalmf.com/download/presentations",
          "title": "Download Mutual Fund Presentation - Motilal Oswal Mutual Fund",
          "metaContent": "View and download the presentations of Motilal Oswal mutual fund and understand the fund management style in detail.",
          "shortDesc": "View and download the presentations of Motilal Oswal mutual fund and understand the fund management style in detail.",
          "keywords": "Motilal Oswal Mutual Fund Presentations",
          "metaTags": "NA"
        },
        "motilal-oswal-large-and-midcap-fund": {
          "contentPageId": 9,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-large-and-midcap-fund",
          "title": "Invest Online In Large and Mid Cap Fund with Motilal Oswal Mutual Fund",
          "metaContent": "Discover the Large and Mid Cap Fund by Motilal Oswal: Unlock the potential of diverse markets with Motilal Oswal Large and Mid cap fund. Achieve balanced growth and stability",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-bank-index-fund": {
          "contentPageId": 21,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-bank-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty Bank Index Fund",
          "metaContent": "Motilal Oswal Mutual Fund - Invest in the top Index fund online. Invest across our diversified range of mutual fund, pms, index fund, etf and more.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "compare-your-portfolio": {
          "contentPageId": 56,
          "PageURL": "https://www.motilaloswalmf.com/tools/compare-your-portfolio",
          "title": "NA",
          "metaContent": "Compare our Portfolio tool will help you compare your equity portfolio with our PMS &Mutual Fund products thus you can evaluate the performance of your equity portfolio.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nasdaq-100-fund-of-fund": {
          "contentPageId": 13,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nasdaq-100-fund-of-fund",
          "title": "Invest Online In Motilal Oswal Nasdaq 100 Fund of Fund",
          "metaContent": "Invest in Motilal Oswal Nasdaq 100 Fund Of Fund and get exposure to 100 largest non-financial companies listed on the Nasdaq Stock Market. We offer mutual fund, pms, and more",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-msci-eafe-top-100-select-index-fund": {
          "contentPageId": 22,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-msci-eafe-top-100-select-index-fund",
          "title": "Invest Online In Motilal Oswal Msci-Eafe-Top-100 Select Index Fund",
          "metaContent": "Generate a long term capital appreciation by invest in the international stocks with Motilal Oswal Mutual Funds. Click to know more.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "kyc-&-redressal-of-complaints": {
          "contentPageId": 53,
          "PageURL": "https://www.motilaloswalmf.com/download/kyc-and-redressal-of-complaints",
          "title": "KYC & Redressal of Complaints - Motilal Oswal Mutual Funds",
          "metaContent": "NA",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-multi-asset-fund": {
          "contentPageId": 12,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-multi-asset-fund",
          "title": "Invest Online In Motilal Oswal Multi Asset Fund Direct Growth - NAV\n",
          "metaContent": "Motilal Oswal Multi Asset Fund - Invest in equities such as index funds, ETFs, gold and silver ETFs, stocks, debt, and more to achieve long-term capital appreciation.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-500-index-fund": {
          "contentPageId": 23,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-500-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty 500 Index Fund",
          "metaContent": "Invest in the long term investment horizon of large, mid, and small cap stocks across Indian market. Experience higher returns on your investments, click to know more.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-focused-fund": {
          "contentPageId": 6,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-focused-fund",
          "title": "Invest Online In Motilal Oswal Focused Fund",
          "metaContent": "The Scheme is to achieve long term capital appreciation by investing in upto 30 companies with long term sustainable competitive advantage and growth potential.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-equity-hybrid-fund": {
          "contentPageId": 18,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-equity-hybrid-fund",
          "title": "Invest Online In Equity Hybrid Fund",
          "metaContent": "Motilal Oswal Equity Hybrid Fund is an Equity Fund for investors who want exposure to both equity and debt. Check the latest NAV, performance, etc of Hybrid Funds here.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-balance-advantage-fund": {
          "contentPageId": 17,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-dynamic-fund",
          "title": "Invest Online In Motilal Oswal Balance Advantage Fund",
          "metaContent": "Motilal Oswal Balance Advantage Fund - Invest in equity & instruments related to it issued by REITs & InvITs and generate a long term capital appreciation",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-smallcap-250-index-fund": {
          "contentPageId": 25,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-smallcap-250-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty-Smallcap 250 Index Fund",
          "metaContent": "Motilal Oswal Mutual Fund - Invest in the Indian Smallcap companies. We offer mutual fund, pms, etf, and more. Click to know more!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-low-volatility-index-fund": {
          "contentPageId": 28,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-bse-low-volatility-index-fund",
          "title": "Invest Online In Motilal Oswal S&P-Bse-Low Volatility Index Fund",
          "metaContent": "Yield returns that, subject to tracking error, correspond, before expenses, to the total returns of the securities represented by the S&P BSE Low Volatility Total Return Index.",
          "shortDesc": "NA",
          "keywords": "international fund, european stocks, non- us stocks",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-500-index-fund": {
          "contentPageId": 20,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-s&p-500-index-fund",
          "title": "Invest in Motilal Oswal S&P 500 Index Fund | Diversify and Grow Your Investments",
          "metaContent": "Explore the Motilal Oswal S&P 500 Index Fund for diversified investing in top U.S. companies. Secure your financial future with this trusted fund managed by Motilal Oswal.Start investing today for a secure financial future and explore global opportunities with the S&P 500's performance and stability.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-200-momentum-30-index-fund": {
          "contentPageId": 29,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-200-momentum-30-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty-200-Momentum 30 Index Fund",
          "metaContent": "Invest between medium to long term investment allocation horizon with a long term wealth creation view with Motilal Oswal Mutual Fund. Click now to know more!.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-midcap-150-index-fund": {
          "contentPageId": 24,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-midcap-150-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty-Midcap 150 Index Fund",
          "metaContent": "Motilal Oswal Mutual Fund - Invest in the Indian Midcap companies. We offer mutual fund, pms, etf, and more. Click to know more!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-next-50-index-fund": {
          "contentPageId": 27,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-next-50-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty-Next 50 Index Fund",
          "metaContent": "Motilal Oswal Nifty-Next 50 Index Fund - The Scheme seeks an investment return that is congruent with the Nifty Next 50 Index performance, subject to tracking error.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-50-index-fund": {
          "contentPageId": 26,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-50-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty 50 Index Fund",
          "metaContent": "Invest in India's top 50 largest companies with Motilal Oswal Mutual Funds. Make your investment related decision quick & easy. Click to know more",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "policies": {
          "contentPageId": 51,
          "PageURL": "https://www.motilaloswalmf.com/download/policies",
          "title": "Disclaimers & Privacy policy",
          "metaContent": "At MOAMC, we respect investors/customers/clients right to privacy. Read on to know about the disclaimer and privacy policy here!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-gold-and-silver-etfs-fund-of-funds": {
          "contentPageId": 101,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-gold-and-silver-etfs-fund-of-funds",
          "title": "Invest Online In Motilal Oswal Gold and Silver ETFs Fund of Funds",
          "metaContent": "Generate investing returns with gold ETFs & silver ETFs online with Motilal Oswal Mutual Fund. We offer mutual funds, pms, index fund, aif, and etf. Invest now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-enhanced-value-index-fund": {
          "contentPageId": 102,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-bse-enhanced-value-index-fund",
          "title": "Invest Online In Motilal Oswal S&P-Bse-Enhanced Value Index Fund",
          "metaContent": "Generate higher returns with a viewpoint of investing in a long-term investment horizon. Experience a diversified investment with Motilal Oswal S&P-Bse-Enhanced Value Index Fund",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-quality-index-fund": {
          "contentPageId": 103,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-bse-quality-index-fund",
          "title": "Invest Online In Motilal Oswal S&P-Bse Quality Index Fund",
          "metaContent": "Generate greater returns by adopting a long-term investment strategy. Motilal Oswal S&P-Bse Quality Index Fund offers various investment opportunities.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-financials-ex-bank-30-index-fund": {
          "contentPageId": 104,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-bse-financials-ex-bank-30-index-fund",
          "title": "Invest Online In Motilal Oswal S&P-Bse-Financials-Ex-Bank 30 Index Fund",
          "metaContent": "Motilal Oswal Mutual Fund - Invest with a long-term wealth creation view in Motilal Oswal S&P-Bse-Financials-Ex-Bank 30 Index Fund. Click to know more. Invest now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-asset-allocation-passive-fund-of-fund-aggressive": {
          "contentPageId": 15,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-asset-allocation-passive-fund-of-fund-aggressive",
          "title": "Invest Online In Motilal Oswal Asset Allocation Passive Fund of Fund Aggressive",
          "metaContent": "Generate long term wealth with Motilal Oswal Mutual Fund by investing in passive funds such as ETF/Index funds of equity and equity related instruments",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-asset-allocation-passive-fund-of-fund-conservative": {
          "contentPageId": 14,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-asset-allocation-passive-fund-of-fund-conservative",
          "title": "Invest Online In Motilal Oswal Asset Allocation Passive Fund of Fund Conservative",
          "metaContent": "Invest with Motilal Oswal Mutual Fund with a diverse selection of multi-asset strategies, an emphasis on equities, based on the principles of asset allocation. ",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-5-year-g-sec-fund-of-fund": {
          "contentPageId": 16,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-5-year-g-sec-fund-of-fund",
          "title": "Invest Online In Motilal Oswal 5 Year G Sec Fund Of Fund",
          "metaContent": "Experience higher returns by investing in units of Motilal Oswal 5 Year G-Sec ETF. Invest online with Motilal Oswal Mutual Fund, We offer Mutual fund, pms, index fund, & more.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-microcap-250-index-fund": {
          "contentPageId": 125,
          "PageURL": "https://motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-microcap-250-index-fund",
          "title": "India's First Microcap Mutual Fund - Nifty Microcap 250 | Motilal Oswal MF",
          "metaContent": "Invest in Nifty Microcap 250, India's first microcap mutual fund by Motilal Oswal Mutal Fund. Explore the potential of microcap stocks and grow your portfolio. Invest Now!",
          "shortDesc": "NA",
          "keywords": "nifty microcap 250, microcap, microcap mutual fund",
          "metaTags": "NA"
        },
        "investment-philosophy": {
          "contentPageId": 3,
          "PageURL": "https://www.motilaloswalmf.com/about-us/investment-philosophy",
          "title": "Motilal Oswal Mutual Fund Investment Philosophy - High Quality High Growth Portfolios",
          "metaContent": "Invest with confidence in our high-quality, high-growth portfolios, meticulously crafted through our rigorous QGLP investment philosophy and unwavering commitment to risk management.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-small-cap-fund": {
          "contentPageId": 126,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-small-cap-fund",
          "title": "Motilal Oswal Small Cap Fund : Think Big, Start Small ",
          "metaContent": "Motilal Oswal Small Cap Fund is an equity mutual fund designed to capitalize on the potential of small-cap companies. Invest in High-Quality High-Growth Small Cap Fund",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-large-cap-fund": {
          "contentPageId": 127,
          "PageURL": "https://mf.moamc.com/mutual-funds/motilal-oswal-large-cap-fund",
          "title": "Motilal Oswal Large Cap Fund: High Quality High Growth",
          "metaContent": "Motilal Oswal Large Cap Fund is an open-ended equity scheme that helps you invest in stocks of Large Cap Companies. Invest in large cap fund direct growth today for better returns.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-multi-cap-fund": {
          "contentPageId": 128,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-multil-cap-fund",
          "title": "Invest Online In Motilal Oswal Multicap Fund",
          "metaContent": "Explore Motilal Oswal Multi-Cap Fund – Discover the potential of Multi-Cap Fund with a dynamic blend of large, mid and small caps.Click here to invest online in Multicap today!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-india-defence-index-fund": {
          "contentPageId": 129,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-india-defence-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty India Defence Index fund",
          "metaContent": "Explore Motilal Oswal Nifty India Defence Index fund -Invest in a fund that aims to leverage the growing potential of the defence sector.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-manufacturing-fund": {
          "contentPageId": 130,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-manufacturing-fund",
          "title": "Invest Online In Motilal Oswal Manufacturing Fund",
          "metaContent": "Invest in Motilal Oswal Manufacturing Fund and get a diversified portfolio of high-growth manufacturing companies, providing long-term capital appreciation. Click here to invest online in NFO today!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-business-cycle-fund": {
          "contentPageId": 131,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-business-cycle-fund",
          "title": "Invest Online In Motilal Oswal Business Cycle Fund",
          "metaContent": "Invest in Motilal Oswal Business Cycle Fund and capitalize on economic cycles. Targeting high-growth sectors, our expert management aims to spot the right sectors during a business cycle. Invest now and aim to create a high quality portfolio! Click here to invest online in NFO today!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-500-momentum-50-index-fund": {
          "contentPageId": 133,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-nifty-500-momentum-50-index-fund",
          "title": "Invest Online In Motilal Oswal Nifty 500 Momentum 50 Index Fund",
          "metaContent": "Invest in Motilal Oswal Nifty 500 Momentum 50 Index Fund which tracks the top 50 Nifty 500 stocks based on 6 and 12 month momentum, adjusted for volatility and market cap. Click to invest in NFO online today!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-digital-india-fund": {
          "contentPageId": 134,
          "PageURL": "https://www.motilaloswalmf.com/mutual-funds/motilal-oswal-digital-india-fund",
          "title": "NFO: Invest in India's Digital Growth | Motilal Oswal Digital India Fund NFO 2024",
          "metaContent": "Join the Motilal Oswal Digital India Fund NFO and tap into India's rapidly growing digital",
          "shortDesc": "NA",
          "keywords": "Motilal Oswal Digital India Fund,New Fund OLer (NFO) 2024,Best mutual funds in India,Invest in Digital India,Growth funds India 2024,Digital economy investments,India technology sector mutual funds,Telecommunication and IT mutual fund,Emerging markets funds,Top mutual funds in India",
          "metaTags": "NA"
        }
      },
      "Index Fund": {
        "index-fund": {
          "contentPageId": 60,
          "PageURL": "https://www.motilaloswalmf.com/index-fund",
          "title": "Invest In The Best Index Funds Online At Motilal Oswal Mutual Fund",
          "metaContent": "Index Funds - What are Index Funds? Find out with Motilal Oswal Mutual Fund. Invest across our diverse range of investment options✔Mutual Funds✔ETF✔AIF✔Index Funds✔PMS",
          "shortDesc": "NA",
          "keywords": "index funds, passive funds, nifty, nifty 50",
          "metaTags": "NA"
        }
      },
      "Investor Education": {
        "blog": {
          "contentPageId": 80,
          "PageURL": "https://www.motilaloswalmf.com/investor-education/blog",
          "title": "Blog",
          "metaContent": "blog Page Description will appear from here",
          "shortDesc": "blog page short Desc.",
          "keywords": "blog, etc",
          "metaTags": "Meta tags foe blog page"
        },
        "wealth-creation-thoughts": {
          "contentPageId": 84,
          "PageURL": "https://www.motilaloswalmf.com/investor-education/wealth-creation-thoughts",
          "title": "NA",
          "metaContent": "Check out these Top 30 Inspirational Equity Investing and Stock Market Quotes by famous successful equity investors and traders of the stock market to take smart decision.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "elss": {
          "contentPageId": 123,
          "PageURL": "https://www.motilaloswalmf.com/investor_education/elss",
          "title": "ELSS Mutual Funds Investment Scheme in India | Motilal Oswal MF",
          "metaContent": "ELSS Mutual Funds: Invest in Motilal Oswal ELSS funds and save taxes. Know more about the benefits and process of investing in ELSS Fund. Start investing now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "indexfundshainabhai": {
          "contentPageId": 124,
          "PageURL": "https://mf.moamc.com/investor-education/indexfundshainabhai",
          "title": "Index Funds Hai Na Bhai | Simplify investing with Index Funds | Motilal Oswal Mutual fund",
          "metaContent": "Gain benefits in the long-term with Index funds online at Motilal Oswal Mutual Fund.  Experience low cost, diversified portfolio, market exposure, and more.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        }
      },
      "ETF": {
        "etf": {
          "contentPageId": 61,
          "PageURL": "https://www.motilaloswalmf.com/etf",
          "title": "Invest In Exchange Traded Fund (ETF) With Motilal Oswal Mutual Fund.",
          "metaContent": "ETFs - Invest in ETF Funds With Motilal Oswal Mutual Fund. We offer investing options across ✔Mutual Funds✔ETF✔AIF✔Index Funds✔PMS, Invest now with Motilal Oswal MF!",
          "shortDesc": "NA",
          "keywords": "etf, exchange traded fund, nfo",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-200-momentum-30-etf": {
          "contentPageId": 62,
          "PageURL": "https://www.motilaloswalmf.com/etf/motilal-oswal-nifty-200-momentum-30-etf",
          "title": "Invest Online Motilal Oswal Nifty 200 Momentum 30 ETF",
          "metaContent": "Motilal Oswal Nifty 200 Momentum 30 ETF - Invest in the basket of top performing companies in India with Motilal Oswal Mutual Fund. Invest now!",
          "shortDesc": "Motilal Oswal Nifty 200 Momentum 30 ETF - Invest in the basket of top performing companies in India with Motilal Oswal Mutual Fund. Invest now!",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-5-yr-benchmark-g-sec-etf": {
          "contentPageId": 63,
          "PageURL": "https://www.motilaloswalmf.com/etf/motilal-oswal-nifty-5-yr-benchmark-g-sec-etf",
          "title": "Invest Online in Nifty 5 Year Benchmark G-Sec-ETF With Motilal Oswal Mutual Fund",
          "metaContent": "Motilal Oswal Nifty 5 YR Benchmark G Sec ETF - Invest in a short to medium term horizon with a virtually risk free debt exposure. Invest now with Motilal Oswal Mutual Fund",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-midcap-100-etf": {
          "contentPageId": 64,
          "PageURL": "https://www.motilaloswalmf.com/etf/motilal-oswal-nifty-midcap-100-etf",
          "title": "Invest Online in Motilal Oswal Nifty Midcap 100 ETF",
          "metaContent": "Motilal Oswal Nifty Midcap 100 ETF - Find the latest stock updates, real time market information, and more online with Motilal Oswal Mutual Fund, Invest now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nasdaq-100-etf": {
          "contentPageId": 65,
          "PageURL": "https://www.motilaloswalmf.com/etf/motilal-oswal-nasdaq-100-etf",
          "title": "Invest Online in Motilal Oswal Nasdaq 100 ETF",
          "metaContent": "Motilal Oswal Nasdaq 100 ETF - get expsorure to 100 largest non-financial companies listed on the nasdaq stock market online at Motilal Oswal Mutual Fund, Invest now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nifty-50-etf": {
          "contentPageId": 66,
          "PageURL": "https://www.motilaloswalmf.com/etf/motilal-oswal-nifty-50-etf",
          "title": "Invest Online in Motilal Oswal Nifty 50 ETF",
          "metaContent": "Motilal Oswal Nifty 50 ETF - Create wealth with a long term horizon and stay update with the latest financial updates with Motilal Oswal Mutual Fund. Invest now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-nasdaq-q-50-etf": {
          "contentPageId": 67,
          "PageURL": "https://www.motilaloswalmf.com/etf/motilal-oswal-nasdaq-q-50-etf",
          "title": "Invest Online in Motilal Oswal Nasdaq Q 50 ETF",
          "metaContent": "Motilal Oswal Nasdaq Q 50 ETF - Invest across IT, healthcare, communication services, consumer discretionary, and industrials online at Motilal Oswal Mutual Fund",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-low-volatility-etf": {
          "contentPageId": 119,
          "PageURL": "https://protect-eu.mimecast.com/s/sdpKCmyjGcvOBGAFlT5gT?domain=motilaloswalmf.com",
          "title": "Invest in S&P BSE Low Volatility ETF Online With Motilal Oswal Mutual Fund",
          "metaContent": "Motilal Oswal S&P BSE Low Volatility ETF - Get the latest stock price, historical stock data, stock performance, and more. To invest click now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-enhanced-value-etf": {
          "contentPageId": 120,
          "PageURL": "https://protect-eu.mimecast.com/s/tl-TCnOk8Tn8AQKfrEyqO?domain=motilaloswalmf.com",
          "title": "Invest in S&P BSE Enhanced Value ETF Online With Motilal Oswal Mutual Fund",
          "metaContent": "Motilal Oswal S&P BSE Enhanced Value ETF - Stay updated with real time market information, stock quotes, and other financial announcements. Invest now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-quality-etf": {
          "contentPageId": 121,
          "PageURL": "https://protect-eu.mimecast.com/s/gJCYCoyl7ckOjJBc0IDzU?domain=motilaloswalmf.com",
          "title": "Invest Online in Motilal Oswal S&P BSE Quality ETF Online",
          "metaContent": "Motilal Oswal S&P BSE Quality ETF - Invest with long term wealth creation point of view in a long term horizon. Invest in S&P BSE Quality ETF now , Click now!",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        },
        "motilal-oswal-s&p-bse-healthcare-etf": {
          "contentPageId": 122,
          "PageURL": "https://protect-eu.mimecast.com/s/R_XZCp2m7T5Y1Pvh67YZ2?domain=motilaloswalmf.com",
          "title": "Invest Online in Motilal Oswal S&P Bse Healthcare ETF",
          "metaContent": "Motilal Oswal S&P Bse Healthcare ETF - Invest across large cap, mid cap, and small cap stocks. Give your portfolio a smart diversification with Motilal Oswal Mutual Fund.",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        }
      },
      "PMS": {
        "pms-home": {
          "contentPageId": 68,
          "PageURL": "https://pms.motilaloswalmf.com/",
          "title": "Portfolio Management Service In India - Motilal Oswal Mutual Fund",
          "metaContent": "Move a step closer towards financial stability with Motilal Oswal Mutual Fund. Experience difference with the best portfolio management services",
          "shortDesc": "NA",
          "keywords": "NA",
          "metaTags": "NA"
        }
      },
      "AIF": {
        "aif-home": {
          "contentPageId": 111,
          "PageURL": "https://protect-eu.mimecast.com/s/KbIfC7X0OcLD49QF27150?domain=aif.motilaloswalmf.com",
          "title": "Invest In Alternate Investment Fund (AIF) With Motilal Oswal Mutual Fund",
          "metaContent": "Invest In Alternate Investment Fund Online By Motilal Oswal Mutual Fund. To know more about aif investment visit our page. Invest in✔Mutual Funds✔ETF✔AIF✔Index Funds✔PMS",
          "shortDesc": "NA",
          "keywords": "buy mutual funds online, online mutual fund, invest in mutual funds online, mutual fund investment online, online mutual fund investment, invest online in mutual funds, mutual funds investment in India, motilal oswal mutual funds online",
          "metaTags": "NA"
        }
      }
    }
  },
  "isSecureResponseReqd": false
}
MyApp.getInitialProps = async ({ Component, ctx }) => {
  try {
    let payload = {
      api_name: "SEODetails",
    };
    const footerRes = await api.SEODetails(payload); //await fetch('https://jsonplaceholder.typicode.com/todos');
    let footerData = {};
    footerData = SEODetails
    // footerData = footerRes;

    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { footerData, pageProps };
  } catch (e) {
    return { footerData: {}, pageProps: {} }
  }
};

export default MyApp;