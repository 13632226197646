import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';

const loginButtonStyle = {
  default: "tw-capitalize tw-text-white tw-font-semibold hover:tw-underline tw-pl-5 tw-pt-0 tw-pb-0 tw-pr-0 tw-text-[14px] tw-leading-6 desktop:tw-text-sm",
  outlined: "tw-capitalize tw-font-medium tw-text-sm tw-px-4 tw-py-1 tw-text-white tw-border tw-border-solid tw-border-white tw-rounded-full",
  outlinedDark: "tw-capitalize tw-font-medium tw-text-sm tw-px-4 tw-py-1 tw-text-black tw-border tw-border-solid tw-border-black tw-rounded-full"
}

// variant = "default" | "outlined"
const LoginDropdown = ({ variant = "default" }) => {
  const [clickedLogin, setclickedLogin] = useState(null);
  const router = useRouter();
  const open = Boolean(clickedLogin);

  const handleOpenMenu = (event) => {
    setclickedLogin(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setclickedLogin(null);
  };

  const handleLoginRedirect = (path) => {
    handleCloseMenu();
    router.push(path);
  };

  return (
    <>
      <button
        aria-controls={open ? 'login-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        className={loginButtonStyle[variant]}
      >
        Login
      </button>

      {/* Dropdown menu */}
      <Menu
        id="login-menu"
        anchorEl={clickedLogin}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'login-button',
        }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiButtonBase-root.MuiMenuItem-root': {
            padding: '16px 64px 16px 0',
            margin: '0 24px'
          }
        }}
      >
        {/* Investor Login option */}
        <MenuItem onClick={() => handleLoginRedirect('/pms/login')} className='custom-border'>
          <ListItemIcon className='tw-text-black'>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography className='tw-text-sm tw-font-medium'>Investor Login</Typography>
        </MenuItem>

        {/* Partner Login option */}
        <MenuItem onClick={() => handleLoginRedirect(`${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}investonline/dist`)}>
          <ListItemIcon className='tw-text-black'>
            <AssignmentIndIcon fontSize="small" />
          </ListItemIcon>
          <Typography className='tw-text-sm tw-font-medium'>Partner Login</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LoginDropdown;
